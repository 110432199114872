import { gql } from "@apollo/client";

export const CREATE_COLLECTION = gql`
  mutation CreateCollection($input: CreateProductsCollectionInput!) {
    createCollection(input: $input) {
      createdAt
      deletedAt
      id
      menus {
        id
        isRoot
        translationData {
          id
          languageCode
          name
          updatedAt
          slug
          description
          createdAt
        }
      }
      media {
        id
        createdAt
        updatedAt
        deletedAt
        original_name
        file_name
        path
        host
        full_url
        type
        alt
      }
      productsCount
      updatedAt
      translationData {
        createdAt
        description
        id
        languageCode
        name
        slug
        updatedAt
      }
    }
  }
`;

export const EDIT_COLLECTION = gql`
  mutation UpdateCollection($input: UpdateProductsCollectionInput!) {
    updateCollection(input: $input) {
      id
      deletedAt
      createdAt
      updatedAt
      translationData {
        createdAt
        description
        id
        languageCode
        name
        slug
        updatedAt
      }
      productsCount
      menus {
        id
        isRoot
        translationData {
          createdAt
          id
          description
          languageCode
          name
          slug
          updatedAt
        }
      }
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation RemoveCollection($removeCollectionId: Int!) {
    removeCollection(id: $removeCollectionId)
  }
`;
