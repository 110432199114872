import { gql } from '@apollo/client';

export const CREATE_ROLE = gql`
  mutation CreateRole($createRoleInput: CreateRoleInput!) {
    createRole(createRoleInput: $createRoleInput) {
      id
      name
      description
      permissions {
        id
        groupName
        name
        action
        description
      }
    }
  }
`;



export const EDIT_ROLE = gql`
mutation UpdateRole($updateRoleInput: UpdateRoleInput!) {
  updateRole(updateRoleInput: $updateRoleInput) {
    id
    description
    name
    permissions {
      id
      groupName
      name
      action
      description
    }
  }
}
`;


export const DELETE_ROLE = gql`
  mutation RemoveRole($removeRoleId: Int!) {
    removeRole(id: $removeRoleId) {
      id
    }
  }
`;
