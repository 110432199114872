import React from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";

//Material UI
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CsvIcon } from "../Svg/MainIcons";

import TextSnippetTwoToneIcon from '@mui/icons-material/TextSnippetTwoTone';

import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';
import { useStateContext } from "../../contexts/ContextProvider";

const Handle = SortableHandle(({ tabIndex }) => (
  <div tabIndex={tabIndex} className="h-36 w-full"></div>
));

const SortableItem = SortableElement(
  ({ value, shouldUseDragHandle, indexImage, handleDeleteImage, single }) => {
    const { t, i18n } = useTranslation();
    
  const { bodyStyles } = useStateContext();

    return (
      <>
        {value?.imageUrl?.type === "text/csv" ? (
          <div
            className={`relative  object-cover border w-full min-h-[100px]
              rounded-lg overflow-hidden flex items-center justify-center 
          ${single ? "w-full" : "w-full"}
        `}
          >
            <div
              className={`absolute top-0   ${
                i18n.language === "ar" ? "left-0" : "right-0"
              }`}
              style={{ zIndex: "999" }}
              onClick={() => handleDeleteImage(indexImage)}
            >
              <IconButton>
                <ClearIcon />
              </IconButton>
            </div>
            {shouldUseDragHandle && (
              <div
                className={`absolute top-0  h-36 w-full cursor-move ${
                  i18n.language === "ar" ? "right-0" : "left-0"
                }`}
                style={{ cursor: "grab" }}
              >
                <Handle />
              </div>
            )}

            <div className="flex flex-col gap-0 my-2">
              <div className="max-w-[120px] flex justify-center">
                {/* <CsvIcon color="green" /> */}
                <TextSnippetTwoToneIcon style={{ fontSize: 60 }}  />
              </div>
              <div className="flex justify-center items-center text-sm font-Roboto font-bold"
              
              >
                {value?.imageUrl?.name}
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`relative  aspect-square	object-cover border 
             rounded-lg overflow-hidden max-w-[250px]
          ${single ? "w-full" : "w-[45px] md:w-[100px]"}
        `}
          >
            <div
              className={`absolute top-0   ${
                i18n.language === "ar" ? "left-0" : "right-0"
              }`}
              style={{ zIndex: "999" }}
              onClick={() => handleDeleteImage(indexImage)}
            >
              <IconButton>
                <ClearIcon />
              </IconButton>
            </div>
            {shouldUseDragHandle && (
              <div
                className={`absolute top-0  h-36 w-full cursor-move ${
                  i18n.language === "ar" ? "right-0" : "left-0"
                }`}
                style={{ cursor: "grab" }}
              >
                <Handle />
              </div>
            )}

            <img
              src={
                typeof value?.imageUrl === "object"
                  ? URL.createObjectURL(value?.imageUrl)
                  : value?.imageUrl
              }
              alt={value?.imageUrl?.name}
              className=" object-cover border h-full w-full"
            />
          </div>
        )}
      </>
    );
  }
);

const SortableList = SortableContainer((props) => {
  const { items, handleDeleteImage, loadingUpload, ...restProps } = props;
  return (
    <div className="flex gap-3 flex-wrap w-full ">
      {items.map((item, index) => (
        <SortableItem
          key={`item-${item.id}`}
          indexImage={index}
          index={index}
          value={item}
          {...restProps}
          handleDeleteImage={handleDeleteImage}
          single={props?.single}
        />
      ))}
      {loadingUpload &&
        [...Array(loadingUpload).keys()]?.map((item) => (
          <div className="relative h-36">
            <Skeleton variant="rectangle" height={144} />
          </div>
        ))}
    </div>
  );
});

export default function DragFiles({
  images,
  setImages,
  handleDeleteImage,
  loadingUpload,
  single,
}) {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newOrderImages = arrayMove(images, oldIndex, newIndex);
    setImages(newOrderImages?.map((item) => item.imageUrl));
  };
  const styleContainer = {
    padding: 4,
    overflow: "hidden",
    border: "2px dashed #eeeeee",
    borderStyle: "dashed",
    minHeight: "9rem",
    borderRadius: 6,
  };

  return (
    <div className="w-full">
      <SortableList
        shouldUseDragHandle={true}
        useDragHandle
        axis="xy"
        items={images}
        onSortEnd={onSortEnd}
        handleDeleteImage={handleDeleteImage}
        loadingUpload={loadingUpload}
        single={single}
      />
    </div>
  );
}
