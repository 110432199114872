import React from 'react';
import ModalDialog from '../../ModalDialog';
import { useTranslation } from 'react-i18next';

const DeleteMany = ({ data, dataPage, setOpen, action }) => {
  const { t } = useTranslation();
  return (
    <ModalDialog
      open={data?.deleteAllModal}
      setOpen={setOpen}
      title={t(dataPage?.deleteAllInstanceTitle)}
      cancelTitle={t('Cancel')}
      cancel={() => setOpen()}
      actionTitle={t(dataPage?.deleteAllInstanceActionTitle)}
      action={() => action()}
      loadingAction={data?.loadingDeleteAll}
      className='p-0 m-0'
    >
      <div className='rounded-md custom-border-color p-1 shadow-custom md:width-600 space-y-3 font-urbanist'>
        <p className='font-semibold '>
          {t(dataPage.deleteAllInstanceDescription)}
          <span className='font-bold'>
            {' '}
            {data?.selectedMany?.join(' - ').toString()}{' '}
          </span>
        </p>
      </div>
    </ModalDialog>
  );
};

export default DeleteMany;
