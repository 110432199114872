import React, { useEffect, useState } from 'react';
import Pagination from '../Pagination';
import DataGrid from '../DataGrid';
import LoadingSpinner from '../LoadingSpinner';
import { useStateContext } from '../../contexts/ContextProvider';
import { useSearchParams } from 'react-router-dom';

const TableWrapper = (props) => {
    const [searchParams] = useSearchParams();

    const { bodyStyles } = useStateContext();

    const [update, setUpdate] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        setPage(searchParams.get('page'));
    }, [searchParams]);

    return (
        <div className="w-full">
            <div
                className="mobileScrollBar my-5 mb-8 flex w-full flex-col  items-start justify-start overflow-x-scroll px-0 pb-2 "
                style={{
                    color: bodyStyles?.text ? bodyStyles?.text : '#fff',
                }}
            >
                <div className="relative w-full ">
                    {props.loadingDeletItem && (
                        <div className="absolute flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.05)] ">
                            <LoadingSpinner size="50px" />
                        </div>
                    )}
                    <DataGrid
                        data={props.data}
                        fildesDis={props.fildesDis}
                        checkAll={props.checkAll}
                        selectAll={props.selectAll}
                        productSelected={props.productSelected}
                        setProductSelected={props.setProductSelected}
                        setOpenModalDelete={props.setOpenModalDelete}
                        checked={props.checked}
                        updateChecks={props.updateChecks}
                        deletAllSelected={props.deletAllSelected}
                        exportAllSelected={props.exportAllSelected}
                    />
                </div>
            </div>
            <div className="mt-5 flex w-full justify-center md:mt-10">
                {props.instancesCount && (
                    <Pagination
                        count={props.instancesCount ? props.instancesCount : 0}
                        pagesize={props.pageSize}
                        setPage={(e) => {
                            setUpdate(update);
                            if (props.localPagenation) {
                                props.setPage(e + 1);
                            }
                        }}
                        page={
                            props.localPagenation
                                ? props.page - 1
                                : page
                                ? +page - 1
                                : 0
                        }
                        showPages={2}
                        localPagenation={props.localPagenation}
                    />
                )}
            </div>
        </div>
    );
};

export default TableWrapper;
