import React, { useState } from "react";
import frLocale from "date-fns/locale/fr";
import ruLocale from "date-fns/locale/ru";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

//store
import { useStateContext } from "../contexts/ContextProvider";
import BasicInput from "./Inputs/BasicInput";
const localeMap = {
  en: enLocale,
  fr: frLocale,
  ru: ruLocale,
  de: deLocale,
};

const maskMap = {
  fr: "__/__/____",
  en: "__/__/____",
  ru: "__.__.____",
  de: "__.__.____",
};

export default function LocalizedDatePicker({
  value,
  onChange,
  label,
  minDate,
  error,
  helperText,
  disabled,
}) {
  const { bodyStyles } = useStateContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
      <div style={{ width: "100%" }}>
        <DateTimePicker
          value={value}
          onChange={onChange}
          minDate={minDate}
          disabled={disabled}
          PaperProps={{
            borderColor: "red",

            sx: {
              background: bodyStyles.optionsBackground,
              color: bodyStyles?.text,

              "& .MuiPickersDay-root": {
                "&.Mui-selected": {
                  backgroundColor: bodyStyles?.primary,
                },
                background: bodyStyles.optionsBackground,
                color: bodyStyles?.text,
              },
              "& .MuiTypography-root": {
                color: bodyStyles?.text,
                fontWeight: "700",
              },

              "& .MuiSvgIcon-root": {
                color: bodyStyles?.text,
              },

              "& .muiltr-x9nfkm": {
                color: bodyStyles?.text,
              },

              "& .muiltr-1flhz3h": {
                color: bodyStyles?.text,
              },

              //date selected
              "& .muiltr-7lip4c": {
                //Center
                backgroundColor: bodyStyles?.primary,
              },

              "& .muiltr-12ha4i7": {
                //Line
                backgroundColor: bodyStyles?.primary,
              },

              "& .muiltr-a1rc6s": {
                //Line
                backgroundColor: bodyStyles?.primary,
              },

              "& .muiltr-118whkv": {
                //Line
                backgroundColor: bodyStyles?.primary,
              },

              "& .Mui-selected": {
                backgroundColor: bodyStyles?.primary,
              },
            },
          }}
          renderInput={(params) => (
            <BasicInput
              {...params}
              label={label}
              error={error}
              helperText={helperText}
              disabled={disabled}
              sx={{
                width: "100%",
                // border : "1px solid red",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: disabled ? bodyStyles?.inputTextDisabled : "",
                  
                },
                "& .muiltr-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled " : {
                  borderColor: disabled ? bodyStyles?.inputTextDisabled : bodyStyles?.inputBorder,
                  border : disabled && ` 1px solid ${bodyStyles?.inputTextDisabled}` 


                },

                input: {
                  "&:-webkit-autofill": {
                    WebkitBoxShadow: `0 0 0 100px ${bodyStyles?.background} inset`,
                    WebkitTextFillColor: bodyStyles?.inputText,
                  },
                },
        
                color: bodyStyles?.inputText,
        
                "& label": {
                  top: value ? "0px" : "-2px",
                  paddingRight: "4px",
                  backgroundColor: bodyStyles.background,
                  color: bodyStyles?.inputSubText,
                },
        
                "& label.Mui-focused": {
                  color: bodyStyles?.text,
                  backgroundColor: bodyStyles.background,
                  paddingRight: "4px",
                  top: "0",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: bodyStyles?.inputText,
                  paddingRight: "4px",
                },
                "& .MuiOutlinedInput-root": {
                  display: "flex",
                  color: bodyStyles?.inputText,
                  alignItems: "center",
                  borderColor: disabled ? bodyStyles?.inputTextDisabled : bodyStyles?.inputBorder,

        
                  "& fieldset": {
                    borderColor: disabled ? bodyStyles?.inputTextDisabled : bodyStyles?.inputBorder,

                    
                  },
                  "&:hover fieldset": {
                    borderColor: disabled ? "": bodyStyles?.inputBorder,
                    display: "flex",
                    alignItems: "center",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: bodyStyles?.inputBorderSelected,
                  },
                },
                "& .MuiSvgIcon-root": {
                  color:  disabled ? bodyStyles?.inputTextDisabled : bodyStyles?.inputSubText,
                },

              }}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  );
}
