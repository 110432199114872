import { gql } from '@apollo/client';

export const EDIT_MEDIA = gql`
  mutation UpdateMedia($updateMediaInput: UpdateMediaInput!) {
    updateMedia(updateMediaInput: $updateMediaInput) {
      id
      full_url
      file_name
      original_name
      alt
    }
  }
`;
