import React, { useContext, useState } from 'react';
import Tabs from '../../TabsLanguages';
import { storeContext } from '../../../global/store';
import BasicInput from '../../Inputs/BasicInput';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import DragImages from '../../DragImages';
import DragToUpload from '../../DragToUpload';
import Editor from '../../Editor/Editor';
import { GET_ALL_CATEGORIES } from '../../../graphql/query/categories.query';
import { useQuery } from '@apollo/client';
import BasicSelecAutoComplete from '../../Inputs/BasicSelecAutoComplete';

const CreateReferenceForm = ({
  fields,
  setFields,
  formik,
  images,
  setImages,
}) => {
  const { store } = useContext(storeContext);

  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();

  const [loadingUpload, setLoadingUpload] = useState(null);
  const [uploadMore, setUploadMore] = useState(false);
  const [openAssetsModal, setOpenAssetsModal] = useState(false);

  const [searchCategory, setSearchCategory] = useState('');

  const handleDeleteImage = (i) => {
    setImages(images.filter((image, index) => index !== i));
  };

  // console.log('images In refernces', images)

  return (
    <form id='add-product-form' className=' font-Inter relative mb-[50px] '>
      <div className='flex flex-col gap-y-3 py-3'>
        <div>
          <div className='flex gap-5 flex-col md:flex-row  '>
            <div className='w-[100%]  space-y-6 '>
              <div className=' space-y-2  rounded-lg'>
                <Tabs availableLanguages={store.availableLanguages}>
                  {store.availableLanguages?.map((item, indexLanguages) => (
                    <div
                      key={indexLanguages}
                      className='space-y-6 font-urbanist '
                    >
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-10 px-2 sm:px-0'>
                        <div className='space-y-4 col-span-2  '>
                          <div
                            className='font-Inter text-xl pt-7 pb-5 uppercase font-bold flex items-center justify-start'
                            style={{
                              color: bodyStyles.subTitle,
                            }}
                          >
                            <span>{t('Reference informations')}</span>
                          </div>
                          <div className='flex flex-col  gap-6 '>
                            <BasicInput
                              label={t('Customer Full Name')}
                              placeholder={t('Name')}
                              value={fields?.customerFullName}
                              onChange={(e) => {
                                setFields((prevState) => ({
                                  ...prevState,
                                  customerFullName: e.target.value,
                                }));
                              }}
                              error={
                                formik?.touched?.customerFullName &&
                                Boolean(formik?.errors?.customerFullName)
                              }
                              helperText={
                                formik?.touched?.customerFullName &&
                                formik?.errors?.customerFullName
                              }
                            />

                            <BasicInput
                              label={t('Company')}
                              placeholder={t('Company')}
                              value={fields?.company}
                              onChange={(e) => {
                                setFields((prevState) => ({
                                  ...prevState,
                                  company: e.target.value,
                                }));
                              }}
                              error={
                                formik?.touched?.company &&
                                Boolean(formik?.errors?.company)
                              }
                              helperText={
                                formik?.touched?.company &&
                                formik?.errors?.company
                              }
                            />

                            <BasicInput
                              label={t('Description')}
                              placeholder='Tristique viverra risus mauris nunc arcu suspendisse id. Condimentum morbi arcu lectus et sagittis. Sit et, justo tristique vel vestibulum. Sit morbi tristique sed nec scelerisque aliquam convallis quis. Ipsum aenean a libero augue ultrices nam consequat adipiscing leo. Hac vel augue fermentum pharetra, arcu cras. Urna ut nec integer dignissim nibh est, nunc feugiat. Bibendum in elementum leo fringilla venenatis nunc ac. '
                              rows={4}
                              value={fields?.description}
                              onChange={(e) => {
                                setFields((prevState) => ({
                                  ...prevState,
                                  description: e.target.value,
                                }));
                              }}
                              error={
                                formik?.touched?.description &&
                                Boolean(formik?.errors?.description)
                              }
                              helperText={
                                formik?.touched?.description &&
                                formik?.errors?.description
                              }
                            />

                            <BasicInput
                              label={t('Short Description')}
                              placeholder='Tristique viverra risus mauris nunc arcu suspendisse id. Condimentum morbi arcu lectus et sagittis. Sit et, justo tristique vel vestibulum. Sit morbi tristique sed nec scelerisque aliquam convallis quis. Ipsum aenean a libero augue ultrices nam consequat adipiscing leo. Hac vel augue fermentum pharetra, arcu cras. Urna ut nec integer dignissim nibh est, nunc feugiat. Bibendum in elementum leo fringilla venenatis nunc ac. '
                              rows={2}
                              value={fields?.subDescription}
                              onChange={(e) => {
                                setFields((prevState) => ({
                                  ...prevState,
                                  subDescription: e.target.value,
                                }));
                              }}
                              error={
                                formik?.touched?.subDescription &&
                                Boolean(formik?.errors?.subDescription)
                              }
                              helperText={
                                formik?.touched?.subDescription &&
                                formik?.errors?.subDescription
                              }
                            />
                          </div>

                          <div
                            className='font-Inter text-sm pt-4 pb-4 uppercase font-bold flex items-center justify-start'
                            style={{
                              color: bodyStyles.subTitle,
                            }}
                          >
                            <span>{`${t('Media')}`}</span>
                          </div>
                          <div>
                            <div className='flex gap-1 flex-wrap'>
                              {(images?.length > 0 || loadingUpload) && (
                                <DragImages
                                  images={images.map((item, index) => ({
                                    id: `${index}`,
                                    imageUrl: item,
                                  }))}
                                  setImages={setImages}
                                  handleDeleteImage={handleDeleteImage}
                                  loadingUpload={loadingUpload}
                                />
                              )}

                              {((!loadingUpload && images?.length === 0) ||
                                uploadMore) && (
                                <DragToUpload
                                  setImages={setImages}
                                  images={images}
                                  setLoadingUpload={setLoadingUpload}
                                  loadingUpload={loadingUpload}
                                  setUploadMore={setUploadMore}
                                  uploadMore={uploadMore}
                                  // text={t("Accepts images, videos, or 3D models")}
                                  button01={t('Add file')}
                                  // button02={t("select assets")}
                                  height='140px'
                                  accept={{
                                    'image/*': ['.jpeg', '.png', '.svg'],
                                  }}
                                  setOpenUrlModal={setOpenAssetsModal}
                                  error={formik.errors.images}
                                />
                              )}
                            </div>

                            <span className='text-[#d32f2f] text-sm px-1'>
                              {formik.errors.images}
                            </span>
                          </div>
                        </div>

                        <div className='space-y-4 col-span-2  md:col-span-1 w-full '>
                          <div
                            className='font-Inter text-xl pt-7 pb-5 uppercase font-bold flex items-center justify-start'
                            style={{
                              color: bodyStyles.subTitle,
                            }}
                          >
                            <span>{t('Reference Location')}</span>
                          </div>

                          <BasicInput
                            label={t('Location')}
                            placeholder={t('Location')}
                            value={fields?.location}
                            onChange={(e) => {
                              setFields((prevState) => ({
                                ...prevState,
                                location: e.target.value,
                              }));
                            }}
                            error={
                              formik?.touched?.location &&
                              Boolean(formik?.errors?.location)
                            }
                            helperText={
                              formik?.touched?.location &&
                              formik?.errors?.location
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateReferenceForm;
