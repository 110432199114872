import React, { useState } from 'react';
import ModalDialog from '../../ModalDialog';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStateContext } from '../../../contexts/ContextProvider';
import { handleEditOrderPaymentStatus } from '../../../services/order';
import { useDispatch, useSelector } from 'react-redux';
import { openModelEditPayment } from '../../../store/features/order/orderSlicer';

const EditPaymentStatusModal = ({ data }) => {
  const { t } = useTranslation();

  const dataState = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const { bodyStyles } = useStateContext();
  const [paymentStatus, setPaymentStatus] = useState('SUCCESS');

  const addInstanceSchema = Yup.object().shape({});

  const addInstanceFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      paymentStatus: paymentStatus,
      checkout: data?.checkout?.id,
      order: data,
    },
    validationSchema: addInstanceSchema,
    onSubmit: async (values) => {
      handleEditOrderPaymentStatus(values);

      dispatch(handleEditOrderPaymentStatus(values, (res) => {}));
    },
  });

  return (
    <div>
      <ModalDialog
        open={dataState.editPaymentModal}
        setOpen={() => dispatch(openModelEditPayment())}
        title={t('Payment Details')}
        cancelTitle={t('Cancel')}
        cancel={() => dispatch(openModelEditPayment())}
        actionTitle={t('Confirm')}
        action={() => addInstanceFormik.handleSubmit()}
        loadingAction={dataState.loadingEditPayment}
      >
        <form
          id='add-discount-modal'
          className='flex gap-5 flex-col md:w-[550px]'
        >
          <div className='w-[98%]'>
            <div
              className='font-Inter pt-2 pb-4 mt-1 font-bold flex items-center justify-start'
              style={{
                color: bodyStyles.text,
              }}
            >
              <span>{`${t(
                'Are you sure you want to confirm this payment with the following amount : '
              )} ${data?.subTotal} ${data?.currencyCode} `}</span>
            </div>
          </div>
        </form>
      </ModalDialog>
    </div>
  );
};
export default EditPaymentStatusModal;
