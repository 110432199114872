import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';
import PageModal from '../PageModal';
import usePageState from '../../hooks/layout.hook';
import { pagesData } from '../../pages_data/pages_data';
import MainSettings from './MainSettings';
import { delay } from '../../utils/helpers';
import { useStateContext } from '../../contexts/ContextProvider';
// import { useHistory } from 'react-router-dom';

const SettingModal = ({ children, page }) => {
  const navigate = useNavigate();

  const { openSettingModel, setOpenSettingModal } = useStateContext();

  useEffect(() => {
    delay(0).then(async () => {
      setOpenSettingModal(true);
    });
  }, []);

  let data = pagesData.setting.main;
  const { t, i18n } = useTranslation();
  const {
    status,
    search,
    filter,
    sortBy,
    order,
    tabs,
    tabSelect,
    sortOptions,
    handleTabChange,
    setSearch,
    setFilter,
    setSortBy,
    setStatus,
    setOrder,
  } = usePageState(data?.sort, data?.defaultSortBy, data?.tabs);

  const cloesSettings = () => {
    setOpenSettingModal(false);
    navigate(
      localStorage.getItem('previouspath')
        ? localStorage.getItem('previouspath')
        : '/'
    );
  };
  return (
    <div>
      <PageModal
        tabSelect={tabSelect}
        tabs={tabs}
        handleTabChange={handleTabChange}
        search={search}
        setSearch={setSearch}
        filter={filter}
        setFilter={setFilter}
        sortOptions={sortOptions}
        sortBy={sortBy}
        setSortBy={setSortBy}
        status={status}
        pageName={t(data?.pageName)}
        addSectionLabel={t(data?.addSectionLabel)}
        addSection={data?.addSection}
        addSectionUrl={data?.addSectionUrl}
        filterSection={data?.filterSection}
        searchButton={data?.searchButton}
        filterButton={data?.filterButton}
      ></PageModal>
      <div className='p-4 pt-0  pb-0'>
        <div
          className={`w-full fixed z-[999] duration-100  px-1 pt-1 h-screen ${
            openSettingModel ? 'bottom-0' : '-bottom-[100%] '
          }  ${i18n.language === 'ar' ? 'right-0' : 'left-0'}`}
        >
          <MainSettings cloesSettings={cloesSettings} page={page}>
            {children}
          </MainSettings>
        </div>
      </div>
    </div>
  );
};

export default SettingModal;
