import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImportModal from '../../Modals/ImportModal';
import { useFormik } from 'formik';
import ImportCSVModal from '../../Upload/ImportCSVModal';
import * as Yup from 'yup';

const Import = ({ data, dataPage, setOpen, action }) => {
  const { t } = useTranslation();
  const [overrideExistingFiles, setOverrideExistingFiles] = useState(false);
  const [files, setFiles] = useState([]);
  const [loadingUpload, setLoadingUpload] = useState(null);
  const [uploadMore, setUploadMore] = useState(false);


  const handleDeleteFile = (i) => {
    setFiles(files.filter((file, index) => index !== i));
  };

  const importDataSchema = Yup.object().shape({
    files: Yup.array()
      .min(1, 'This Field is required')
      .required('This Field is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      files: files,
      overrideExistingFiles: overrideExistingFiles,
    },
    validationSchema: importDataSchema,
    onSubmit: async (values) => {
      importFiles(values);
    },
  });

  const importFiles = async (values) => {
    if (files.length > 0) {
      action(values);
      setFiles([]);
    }
  };

  return (
    <div>
      <ImportCSVModal
        open={data.importModal}
        setOpen={setOpen}
        title={t(dataPage.importInstanceModelTitle)}
        description={t(dataPage.importInstanceModelDescription)}
        overrideExistingFiles={overrideExistingFiles}
        setOverrideExistingFiles={setOverrideExistingFiles}
        overrideLable={dataPage.importOverrideLable}
        files={files}
        setFiles={setFiles}
        deleteFiles={handleDeleteFile}
        setLoadingUpload={setLoadingUpload}
        loadingUpload={loadingUpload}
        setUploadMore={setUploadMore}
        uploadMore={uploadMore}
        action={() => formik.handleSubmit()}
        loadingAction={data.loadingImport}
        reqErrors={formik.errors}
      />
    </div>
  );
};

export default Import;
