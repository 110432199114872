import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useStateContext } from '../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import { countriesDetails } from '../utils/countriesDetails';

function TabPanel(props) {
  const { children, value, index, small, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  children,
  availableLanguages,
  small,
  error,
  fixed,
  setSelected,
}) {
  const [value, setValue] = React.useState(0);
  const { t, i18n } = useTranslation();

  const { bodyStyles } = useStateContext();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelected && setSelected(newValue);
  };

  useEffect(() => {
    if (error?.translationData) {
      const index = availableLanguages.findIndex((object) => {
        return object.code === Object.keys(error?.translationData)[0];
      });
      if (index >= 0) {
        setValue(index);
      }
    }
  }, [error]);

  return (
    <Box sx={{ width: '100%' }}>
      <div>
        {availableLanguages?.length > 1 && (
          <Box
            className={`${fixed ? 'sticky -top-6 shadow-sm z-[100] pb-3' : ''}`}
            style={{
              backgroundColor: bodyStyles?.background,
            }}
            sx={{
              '& MuiTabs-flexContainer': {
                // bgcolor: "red",
              },
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: bodyStyles?.primary,
                },
              }}
            >
              {availableLanguages?.map((item, index) => (
                <Tab
                  key={index}
                  label={t(item?.name)}
                  className='font-urbanist  text-mouseGray font-semibold '
                  style={{
                    minHeight: small ? 'auto' : '40px',
                    marginTop: '5px',
                  }}
                  icon={
                    <div>
                      <img
                        src={`https://flagcdn.com/w20/${
                          countriesDetails
                            .find(
                              (cn) =>
                                cn.language?.code?.toLowerCase() ===
                                  item?.code?.toLowerCase() || ''
                            )
                            ?.code?.toLowerCase()
                            ? countriesDetails
                                .find(
                                  (cn) =>
                                    cn.language?.code?.toLowerCase() ===
                                      item?.code?.toLowerCase() || ''
                                )
                                ?.code?.toLowerCase()
                            : ''
                        }.png`}
                        className={`block mx-2 w-6 ${
                          value !== index ? ' opacity-50' : ''
                        }`}
                      />
                    </div>
                  }
                  iconPosition='end'
                  {...a11yProps(index)}
                  sx={{
                    fontSize: small ? '11px' : '',
                    color: bodyStyles?.subTitle,
                    '&:hover': {
                      color: bodyStyles?.title,

                      opacity: 1,
                      icon: bodyStyles?.title,
                    },
                    '&.Mui-selected': {
                      color: bodyStyles?.title,
                    },
                    '&.Mui-focusVisible': {
                      backgroundColor: bodyStyles?.title,
                    },
                  }}
                />
              ))}
            </Tabs>
          </Box>
        )}

        {availableLanguages?.map((item, index) => (
          <TabPanel
            key={index}
            value={value}
            index={index}
            className='text-[7px] '
          >
            {children[index]}
          </TabPanel>
        ))}
      </div>
    </Box>
  );
}
