import { gql } from "@apollo/client";

export const GET_ALL_PERMISSIONS = gql`
  query Permissions($groupName: PermissionGroup) {
    permissions(groupName: $groupName) {
      id
      name
      groupName
      createdAt
      action
      subject
      updatedAt
      description
    }
  }
`;

export const GET_USER_PERMISSIONS = gql`
  query UserPermissions($userId: Int!) {
    userPermissions(userId: $userId) {
      id
      name
      groupName
      description
    }
  }
`;
