import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalDialog from '../../ModalDialog';
import BasicInput from '../../Inputs/BasicInput';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { initInstanceState } from '../../../utils/initStates';
import { handleCreateCustomer } from '../../../services/customer';
import { useDispatch, useSelector } from 'react-redux';
import { openModelCreate } from '../../../store/features/customer/customerSlicer';

const AddCustomerModal = ({ setFields: setPrevFields, fields: prevFields }) => {
  const { t } = useTranslation();

  const data = useSelector((state) => state.customer);
  const dispatch = useDispatch();

  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const [instanceState, setInstanceState] = useState(initInstanceState);

  const addInstanceSchema = Yup.object().shape({
    firstName: Yup.string().required('This Field is required'),
    lastName: Yup.string().required('This Field is required'),
    email: Yup.string().required('This Field is required'),
    phone: Yup.string().required('This Field is required'),
  });

  const addInstanceFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: fields.firstName,
      lastName: fields.lastName,
      email: fields.email,
      phone: fields.phone,
    },
    validationSchema: addInstanceSchema,
    onSubmit: async (values) => {
      dispatch(
        handleCreateCustomer(values, (res) => {
          if (res?.data?.createCustomer) {
            setPrevFields({
              ...prevFields,
              customers: res?.data?.createCustomer
                ? [res?.data?.createCustomer]
                : [],
            });
          }
          dispatch(openModelCreate());
          setFields({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
          });
        })
      );
    },
  });

  return (
    <div>
      <p
        onClick={() => dispatch(openModelCreate())}
        className='cursor-pointer text-[#358DEB] hover:underline'
      >
        {t('New Customer ?')}
      </p>

      <ModalDialog
        open={data?.createModal}
        setOpen={(state) => dispatch(openModelCreate())}
        title={t('Create New Customer')}
        cancelTitle={t('Cancel')}
        cancel={() => dispatch(openModelCreate())}
        actionTitle={t('Create Customer')}
        action={() => addInstanceFormik.handleSubmit()}
        loadingAction={data?.loadingCreate}
      >
        <form
          id='add-discount-modal'
          className='flex gap-5 flex-col md:w-[550px] py-2'
        >
          <div className='flex gap-6 flex-col  items-start '>
            <BasicInput
              label={t('First Name')}
              placeholder={t('Lee')}
              value={fields.firstName}
              onChange={(e) =>
                setFields({
                  ...fields,
                  firstName: e.target.value,
                })
              }
              error={
                addInstanceFormik.touched.firstName &&
                Boolean(addInstanceFormik.errors.firstName)
              }
              helperText={
                addInstanceFormik.touched.firstName &&
                addInstanceFormik.errors.firstName
              }
            />

            <BasicInput
              label={t('Last Name')}
              placeholder={t('Austin')}
              value={fields.lastName}
              onChange={(e) =>
                setFields({
                  ...fields,
                  lastName: e.target.value,
                })
              }
              error={
                addInstanceFormik.touched.lastName &&
                Boolean(addInstanceFormik.errors.lastName)
              }
              helperText={
                addInstanceFormik.touched.lastName &&
                addInstanceFormik.errors.lastName
              }
            />

            <BasicInput
              label={t('email')}
              placeholder={t('Austin@gmail.com')}
              value={fields.email}
              onChange={(e) =>
                setFields({
                  ...fields,
                  email: e.target.value,
                })
              }
              error={
                addInstanceFormik.touched.email &&
                Boolean(addInstanceFormik.errors.email)
              }
              helperText={
                addInstanceFormik.touched.email &&
                addInstanceFormik.errors.email
              }
            />

            <BasicInput
              label={t('Phone Number')}
              placeholder={t('0777964798')}
              value={fields.phone}
              onChange={(e) =>
                setFields({
                  ...fields,
                  phone: e.target.value,
                })
              }
              error={
                addInstanceFormik.touched.phone &&
                Boolean(addInstanceFormik.errors.phone)
              }
              helperText={
                addInstanceFormik.touched.phone &&
                addInstanceFormik.errors.phone
              }
            />
          </div>
        </form>
      </ModalDialog>
    </div>
  );
};

export default AddCustomerModal;
