import { gql } from "@apollo/client";

export const GET_ALL_BRANDS = gql`
  query Brands(
    $offset: Int
    $limit: Int
    $where: FilterBrandsArgs
    $join: BrandRelationsArgs
  ) {
    brands(offset: $offset, limit: $limit, where: $where, join: $join) {
      nodes {
        media {
          id
          createdAt
          updatedAt
          deletedAt
          original_name
          file_name
          path
          host
          full_url
          type
          alt
        }
        id
        deletedAt
        createdAt
        translationData {
          createdAt
          description
          id
          languageCode
          name
          slug
          updatedAt
        }
        updatedAt
      }
      hasNextPage
      totalCount
      
    }
  }
`;

export const GET_BRAND = gql`
  query Brand($brandId: Int!) {
    brand(id: $brandId) {
      id
      translationData {
        description
        id
        languageCode
        name
        slug
        updatedAt
      }
      media {
        id
        createdAt
        updatedAt
        deletedAt
        original_name
        file_name
        path
        host
        full_url
        type
        alt
      }
      updatedAt
    }
  }
`;
