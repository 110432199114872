import { client } from '../global/apolloClient';
import {
  EditPaymentFail,
  EditPaymentStart,
  EditPaymentSuccess,
  EditShippedFail,
  EditShippedStart,
  EditShippedSuccess,
  EditShippingFail,
  EditShippingStart,
  EditShippingSuccess,
  createOrderFail,
  createOrderStart,
  createOrderSuccess,
  deleteOrderFail,
  deleteOrderStart,
  deleteOrderSuccess,
  deleteOrdersFail,
  deleteOrdersStart,
  deleteOrdersSuccess,
  editOrderFail,
  editOrderStart,
  editOrderSuccess,
  fetchOrdersFail,
  fetchOrdersStart,
  fetchOrdersSuccess,
  importFail,
  importStart,
  importSuccess,
  removeAll,
} from '../store/features/order/orderSlicer';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation } from './executeMutation';
import { uploadImage } from './upload';
import {
  CREATE_ORDER,
  DELETE_ORDER,
  EDIT_ORDER_PAYMENT_STATUS,
  EDIT_ORDER_SHIPPING_STATUS,
} from '../graphql/mutation/order.mutation';
import { GET_ALL_ORDERS, GET_ORDER } from '../graphql/query/orders.query';
import { createCheckout } from './checkout';
import { v4 as uuidv4 } from 'uuid';
import { createDiscount } from './discount';
import { editShippingLine } from './shippingLine';
import { constants } from '../constants';

const token = getTheTokenFromStorage();

export const createOrder = async (values, accessToken) => {
  return await executeMutation(
    CREATE_ORDER,
    [GET_ALL_ORDERS, GET_ORDER],
    values,
    accessToken
  );
};

export const editOrder = async (values, accessToken) => {
  // return await executeMutation(
  //   EDIT_MENU,
  //   [GET_ALL_MENUS, GET_MENU],
  //   values,
  //   accessToken
  // );
};

// ****************************************************

export const editOrderShippingStatus = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: EDIT_ORDER_SHIPPING_STATUS,
      variables: {
        ...values,
      },
      context: { accessToken },
      refetchQueries: [GET_ALL_ORDERS, GET_ORDER],
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const editOrderPaymentStatus = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: EDIT_ORDER_PAYMENT_STATUS,
      variables: {
        ...values,
      },
      context: { accessToken },
      refetchQueries: [GET_ALL_ORDERS, GET_ORDER],
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

// ****************************************************

export const deleteOrder = async (values, accessToken) => {
  return await executeMutation(
    DELETE_ORDER,
    [GET_ALL_ORDERS, GET_ORDER],
    values,
    accessToken
  );
};

export const deleteManyOrders = async (values, accessToken) => {
  return await executeMutation(
    DELETE_ORDER,
    [GET_ALL_ORDERS, GET_ORDER],
    values,
    accessToken
  );
};

export const fetchOrders = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchOrdersStart());
      const { data } = await client.query({
        query: GET_ALL_ORDERS,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchOrdersSuccess(data));
    } catch (error) {
      dispatch(fetchOrdersFail(error));
    }
  };
};

export const handleDeleteOrder = (id) => {
  return async (dispatch) => {
    dispatch(deleteOrderStart());
    const dataSent = {
      removeOrderId: parseInt(id),
    };
    try {
      let res = await deleteOrder(dataSent, token);
      if (res?.data?.removeOrder) {
        dispatch(deleteOrderSuccess(id));
        dispatch(showSuccessMessage('Order deleted successfully'));
      } else {
        dispatch(deleteOrderFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteOrderFail(error));
    }
  };
};

export const handleDeleteManyOrder = (ids) => {
  return async (dispatch) => {
    dispatch(deleteOrdersStart());
    const dataSent = {
      removeOrderId: ids.map((id) => parseInt(id)),
    };
    try {
      let res = await deleteManyOrders(dataSent, token);
      if (res?.data?.removeOrders) {
        dispatch(deleteOrdersSuccess(ids));
        dispatch(showSuccessMessage('Orders deleted successfully'));
      } else {
        dispatch(deleteOrdersFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteOrdersFail(error));
    }
  };
};

export const handleEditOrder = (values, callback) => {
  return async (dispatch) => {
    dispatch(editOrderStart());

    let imageSent = values.featuredAsset[0]
      ? await uploadImage(values.featuredAsset[0])
      : null;

    let translationDataSent = values.translationData
      ? Object.keys(values.translationData).map((key) => {
          return {
            name: values.translationData[key].name,
            description: values.translationData[key].description,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      input: {
        id: values.id,
        isRoot: values.isRoot,
        parent: values.parent?.id ? +values.parent.id : null,
        translationData: translationDataSent,
        collections: values.collections.map(function (obj) {
          return +obj.id;
        }),
        media: imageSent?.data?.id ? +imageSent?.data?.id : null,
      },
    };

    try {
      let res = await editOrder(dataSent, token);
      if (res?.data?.updateOrder) {
        dispatch(editOrderSuccess(res?.data?.updateOrder));
        dispatch(
          showSuccessMessage('this Order has been successfully updated')
        );

        if (callback) {
          callback(res?.data?.updateOrder.id);
        }
      } else {
        dispatch(editOrderFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(editOrderFail(error));
      dispatch(showErrorMessage(error));
    }
  };
};

export const handleImportOrder = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyOrders(dataSent, token);
      if (res?.data?.removeOrders) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Orders Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteOrdersFail(error));
      });
    }
  };
};

export const handleExportOrder = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyOrders(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllOrders = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyOrders(dataSent, token);
    } catch (error) {}
  };
};

export const handleCreateOrder = (values, callback) => {
  return async (dispatch) => {
    dispatch(createOrderStart());

    let productsSent = values.products.map((product) => {
      return {
        variant: +product.id,
        quantity: product.quantity ? +product.quantity : 1,
      };
    });

    const newShippingAddress = {
      city: values?.shippingAddress[0].city
        ? values?.shippingAddress[0].city
        : '',
      apartment: values?.shippingAddress[0].apartment
        ? values?.shippingAddress[0].apartment
        : '',
      country: +values?.shippingAddress[0].country?.id
        ? +values?.shippingAddress[0].country?.id
        : 1,
      phoneNumber: values?.shippingAddress[0].phoneNumber
        ? values?.shippingAddress[0].phoneNumber
        : '',
      customer: +values?.customers[0]?.id,
      fullName: values?.shippingAddress[0].fullName,
      isDefaultBillingAddress: false,
      isDefaultShippingAddress: true,
      province: values?.shippingAddress[0].city,
      streetLine1: values?.shippingAddress[0].streetLine1
        ? values?.shippingAddress[0].streetLine1
        : '',
    };

    const dataSent = {
      createOrderInput: {
        appliedCouponCode: values.discountOrderModal.discountValue
          ? await createOrderDiscount(values?.discountOrderModal)
          : null,
        order_lines: productsSent,
        currencyCode: values?.currencyCode,
        taxZoneId: 1,
        customer: +values?.customers[0]?.id,
        cart: null,
        shippingLine: {
          shippingAddress: newShippingAddress,
          shippingAsBilling: true,
          shippingMethod:
            values.shippingMethod != 'pick up' ? +values.shippingMethod : null,
        },

        postalCode: values.postalCode,
        status: 'COMPLETED',
      },
    };

    try {
      let resOrder = await createOrder(dataSent, token);

      let dataCheckoutSent = {
        createCheckoutInput: {
          orderId: +resOrder?.data?.createOrder?.id,
          paymentMethod: values?.paymentMethode ? 'cash' : 'card',
          email: values?.customers[0].email,
        },
      };

      let resCheckout = await createCheckout(dataCheckoutSent, token);

      if (resCheckout?.data) {
        dispatch(createOrderSuccess(resOrder?.data?.createOrder));
        dispatch(
          showSuccessMessage('this Order has been successfully created')
        );

        if (callback) {
          callback(resOrder?.data?.createOrder.id);
        }
      } else {
        dispatch(createOrderFail(resOrder || resCheckout));
        dispatch(showErrorMessage(resOrder || resCheckout));
      }
    } catch (error) {
      dispatch(createOrderFail(error));
      dispatch(showErrorMessage(error));
    }
  };
};

const createOrderDiscount = async (discountOrderModal) => {
  let dataSent = {
    createDiscount: {
      couponCode: uuidv4(),
      name: discountOrderModal?.reason ? discountOrderModal?.reason : '',
      max_usage: 2,
      perCustomerUsageLimit: 1,
      percentage:
        discountOrderModal?.discountType == 'percentage'
          ? +discountOrderModal.discountValue
          : 0,
      fixedAmount:
        discountOrderModal?.discountType == 'amount'
          ? +discountOrderModal.discountValue
          : 0,
      startsAt: new Date(),
      endsAt: new Date(Date.now() + 3600 * 1000 * 24),
      minimumPurchaseAmount: 0,
      status: 'ACTIVE',
    },
  };

  let resDiscount = await createDiscount(dataSent, token);

  return resDiscount?.data?.createDiscount?.couponCode
    ? resDiscount?.data?.createDiscount?.couponCode
    : '';
};

export const handleEditOrderPaymentStatus = (values, callback) => {
  return async (dispatch) => {
    dispatch(EditPaymentStart());

    const dataSent = {
      updateCheckoutInput: {
        id: +values?.checkout?.id,
        orderId: +values.order?.id,
        paymentStatus: values?.paymentStatus,
        // paymentMethod: "COD",
      },
    };

    try {
      let res = await editOrderPaymentStatus(dataSent, token);

      if (res?.data) {
        dispatch(EditPaymentSuccess(res));
        dispatch(
          showSuccessMessage(
            'this payment Status on this order has been successfully updated'
          )
        );
      } else {
        dispatch(EditPaymentFail(res));
        dispatch(showErrorMessage(res));
      }

      callback && callback(res);
    } catch (error) {
      dispatch(EditPaymentFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleEditOrderShippingStatus = (values, callback) => {
  return async (dispatch) => {
    dispatch(EditShippingStart());

    const dataSent = {
      input:
        values?.data?.shippingStatus === 'NOT_PICKED'
          ? 'READY_TO_PICK'
          : values?.data?.shippingStatus === 'READY_TO_PICK'
          ? 'PICKED'
          : values?.data?.shippingStatus === 'NOT_SHIPPED' && 'SHIPPED',
      updateOrderShippingStatusId: +values?.data?.id,
    };

    try {
      let res = await editOrderShippingStatus(dataSent, token);

      if (res?.data) {
        dispatch(EditShippingSuccess(res));
        dispatch(
          showSuccessMessage(
            values?.data?.shippingStatus === 'NOT_PICKED'
              ? `order is ready to be picked!
            An email has been sent successfully to the Client`
              : 'this Shipping Status  has been successfully updated'
          )
        );
      } else {
        dispatch(EditShippingFail(res));
        dispatch(showErrorMessage(res));
      }

      callback && callback(res);
    } catch (error) {
      dispatch(EditShippingFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleEditOrderShippedStatus = (values, callback) => {
  return async (dispatch) => {
    dispatch(EditShippedStart());

    const dataSent = {
      input:
        values?.withTrackingNumber == 'withTrackingNumebr'
          ? {
              id: +values?.data?.shippingLine?.id,
              trackingNumber: values.trackingNumber,
              shippingCompanyName: values.shippingCompany,
            }
          : {
              id: +values?.data?.shippingLine?.id,
              trackingNumber: '',
              shippingCompanyName: '',
            },
    };

    try {
      let res = await editShippingLine(dataSent, token);

      if (res?.data) {
        dispatch(EditShippedSuccess(res));
        dispatch(
          showSuccessMessage(
            'this Shipping Line Status on this order has been successfully updated'
          )
        );
      } else {
        dispatch(EditShippedFail(res));
        dispatch(showErrorMessage(res));
      }

      callback && callback(res);
    } catch (error) {
      dispatch(EditShippingFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};
