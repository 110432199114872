import React, { useState, useMemo, useEffect } from 'react';
import { storeContext } from './store';
import {
  getAccessWithRefresh,
  getTheRefresh,
  getTheTokenFromStorage,
} from '../services/auth';
import { storeTheToken } from '../services/auth';
import jwt from 'jwt-decode';
import { useQuery } from '@apollo/client';
import { languages_list } from '../utils/languages';
import { getCurrencyCharacter } from '../utils/currency';
import { GET_USER_PERMISSIONS } from '../graphql/query/permissions.query';
import { fetchPermissions } from '../services/permission';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../services/setting';

const StoreProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [store, setStore] = useState({
    isLogged: false,
    isLoading: true,
    user: null,
    token: null,
    currencyStore: { currencyCode: '', symbol: '' },
    preferredLanguage: 'en',
    availableLanguages: ['en'],
    loadingPermissions: false,
    usePermissions: false,
    permissions: [],
    logo: '',
    dashboardName: '',
  });

  const [open, setOpen] = useState({
    users: false,
    products: true,
    collections: false,
    categories: false,
    orders: false,
    client: false,
  });

  const settingState = useSelector((state) => state.setting);

  const { data: unSeen, mutate: mutateNotification } = {};

  const {
    loading: loadingPermissions,
    error: errorPermissions,
    data: permissionsData,
  } = useQuery(GET_USER_PERMISSIONS, {
    variables: {
      userId: store?.user?.sub,
    },
    skip: store?.user?.sub ? false : true,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (settingState.data) {
      updateStoreSetting(settingState.data);
    }
  }, [settingState.data]);

  const updateStoreSetting = (storeSetting) => {
    if (storeSetting?.setting_general?.available_language) {
      setStore((prevState) => ({
        ...prevState,
        preferredLanguage: storeSetting?.setting_general?.language_code,
        availableLanguages: storeSetting?.setting_general?.available_language
          ?.length
          ? storeSetting?.setting_general?.available_language.map((item) => {
              return {
                name: languages_list?.find((l) => l.code == item).name,
                code: languages_list?.find((l) => l.code == item).code,
              };
            })
          : [],
        currencyStore: {
          currencyCode: storeSetting?.setting_general?.currency_code,
          symbol:
            getCurrencyCharacter(
              storeSetting?.setting_general?.currency_code
            ) || storeSetting?.setting_general?.currency_code,
        },
        logo: storeSetting?.logo,
        dashboardName: storeSetting?.setting_general?.store_name,
      }));
    }
  };

  const getToken = async () => {
    const token = getTheTokenFromStorage();
    const refrech = getTheRefresh();

    try {
      const user = jwt(token);
      const exp = new Date(user.exp * 1000);
      const now = new Date();
      const isLogged = now < exp;
      if (isLogged) {
        dispatch(fetchSettings());
        setStore((prevState) => ({
          ...prevState,
          isLogged,
          token,
          user,
          isLoading: false,
        }));

        return token;
      } else {
        let dataSent = {
          input: {
            refresh_token: refrech,
          },
        };

        const res = await getAccessWithRefresh(dataSent, token);
        dispatch(fetchSettings());
        const Newuser = jwt(res?.data?.generateAccessToken?.access_token);

        if (res?.data?.generateAccessToken?.access_token) {
          storeTheToken(res?.data?.generateAccessToken?.access_token, refrech);
          setStore((prevState) => ({
            ...prevState,
            isLogged,
            token: res?.data?.generateAccessToken?.access_token,
            Newuser,
            isLoading: false,
          }));

          return token;
        } else {
          setStore((prevState) => ({
            ...prevState,
            isLogged: false,
            user: null,
            token: null,
            isLoading: false,
          }));
        }
      }
    } catch (error) {
      setStore({
        ...store,
        isLogged: false,
        user: null,
        token: null,
        isLoading: false,
      });
      return error?.message;
    }
  };

  useEffect(() => {
    getToken();
  }, [store?.token]);

  useEffect(() => {
    if (permissionsData?.userPermissions) {
      setStore((prevState) => ({
        ...prevState,
        permissions: permissionsData?.userPermissions,
      }));
    }
  }, [permissionsData?.userPermissions]);

  useEffect(() => {
    if (store.token) {
      dispatch(fetchPermissions({}));
      // dispatch(fetchRolles({}))
    }
  }, [store.token]);

  useEffect(() => {
    setStore((prevState) => ({
      ...prevState,
      loadingPermissions: loadingPermissions,
    }));
  }, [loadingPermissions]);

  const storeValue = useMemo(
    () => ({
      store,
      setStore,
      open,
      setOpen,
      unSeen,
      mutateNotification,
      getToken,
    }),
    [store, setStore, open, setOpen, unSeen, mutateNotification, getToken]
  );

  return (
    <storeContext.Provider value={storeValue}>{children}</storeContext.Provider>
  );
};

export default StoreProvider;
