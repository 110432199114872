import { gql } from "@apollo/client";
 
export const CREATE_TAG = gql`
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      id
      createdAt
      deletedAt
      translationData {
        slug
        name
        updatedAt
        description
        languageCode
        id
        createdAt
      }
      updatedAt
    }
  }
`;

export const EDIT_TAG = gql`
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      createdAt
      deletedAt
      id
      translationData {
        description
        createdAt
        id
        languageCode
        slug
        name
        updatedAt
      }
      updatedAt
    }
  }
`;

export const DELETE_TAG = gql`
  mutation RemoveTag($removeTagId: Int!) {
    removeTag(id: $removeTagId)
  }
`;
