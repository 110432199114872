import React, { useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { delay } from '../utils/helpers';
import { clearMessages } from '../store/features/snackbar/snackbarSlice';
const ModalDialogMessage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const snackbar = useSelector((state) => state.snackbar);
  const [init, setInit] = useState(false);

  const [open, setOpen] = useState(
    snackbar.successModalMessage || snackbar.errorModalMessage 
  );

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (snackbar.successModalMessage || snackbar.errorModalMessage) {
      snackbar.position ? setInit(snackbar.position) : setInit(false);
      snackbar.successModalMessage
        ? setSuccessMessage(snackbar.successModalMessage)
        : setErrorMessage(snackbar.errorModalMessage);
      setOpen(true);
      delay(2000).then(async () => {
        dispatch(clearMessages());
        setOpen(false);
      });
      delay(2500).then(async () => {
        setSuccessMessage('');
        setErrorMessage('');
      });
    }
  }, [snackbar]);

  return (
    <div
      className={`absolute top-10 w-[95%] border-[1px]  
      border-green  z-[12] flex items-center 
      justify-center text-green text-lg font-semibold duration-[1s] uppercase p-2 pt-4 rounded-md
        ${i18n.language === 'ar' ? 'ml-4' : 'mr-4'} 
        ${open ? 'top-14 ' : '-top-28'}
        
        ${
          errorMessage
            ? 'bg-[#fdeded] text-[#5f2120]'
            : 'bg-[#e5fde5] text-[#1e4620]'
        }
    `}
    >
      <div
        onClick={() => setOpen(false)}
        className={`absolute top-[2px]  ${
          i18n.language === 'ar' ? 'left-[2px]' : 'right-[2px]'
        }`}
      >
        <IconButton>
          <ClearIcon className='text-green' />
        </IconButton>
      </div>
      <div>
        {successMessage ? (
          t(successMessage)
        ) : (
          <div>
            <span className='font-[600] px-2'>{t('ERROR')} :</span>{' '}
            {t(errorMessage)}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalDialogMessage;

