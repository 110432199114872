export const initInstanceState = {
    loadingCreateInstance: false,
    createInstance: false,
    errorCreatInstance: false,

    loadingDeleteInstance: false,
    deleteInstance: false,
    errorDeleteInstance: false,

    uploadMore: false,
    loadingUpload: null,
    message: "",
    id: "",

  };
