import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { CircularProgress } from '@mui/material';
import BasicInput from '../../../Inputs/BasicInput';
import { useTranslation } from 'react-i18next';
import RtlConversion from '../../../RtlConversion';
import ModalDialog from '../../../ModalDialog';
import { useDispatch } from 'react-redux';
import { handleEditSeetingWithData } from '../../../../services/setting';

const PaypalPaymeny = ({ data, loading }) => {
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const [loadingCretae, setLoadingCreate] = useState({
    paypal: false,
  });

  const [fields, setFields] = useState({
    publicKey: '',
    secretKey: '',
  });
  const addPaypaleKeys = Yup.object().shape({
    publicKey: Yup.string().min(2, 'Enter a valid Key'),
    // .matches(/(pk)_(test|live)_[A-Za-z0-9]/, "Enter a valid Key")
    // .required(t("This Field is required")),
    secretKey: Yup.string().min(2, 'Enter a valid Key'),
    // .matches(/(sk)_(test|live)_[A-Za-z0-9]/, "Enter a valid Key")
    // .required(t("This Field is required")),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      publicKey: fields?.publicKey,
      secretKey: fields?.secretKey,
    },
    validationSchema: addPaypaleKeys,
    onSubmit: async (values) => {
      handleCreatePayment(values);
    },
  });

  const handleCreatePayment = async (values) => {
    setLoadingCreate({ ...loadingCretae, paypal: true });

    const dataSent = {
      updateSettingInput: {
        setting_online: {
          paypal_pk: values.publicKey,
          paypal_sk: values.secretKey,
        },
      },
    };

    dispatch(
      handleEditSeetingWithData(dataSent, (res) => {
        setLoadingCreate({ ...loadingCretae, paypal: false });
        setOpen(false);
      })
    );
  };
  return (
    <div className='w-full flex flex-col items-start justify-start '>
      <ModalDialog
        open={open}
        setOpen={setOpen}
        title={
          data?.setting_payment?.paypal_pk
            ? t('edit PayPal Payment')
            : t('Add PayPal Payment')
        }
        cancelTitle={t('Cancel')}
        cancel={() => setOpen(false)}
        actionTitle={
          data?.setting_payment?.paypal_pk
            ? t('save Payment')
            : t('Add Payment')
        }
        action={() => formik.handleSubmit()}
        className='p-0 m-0'
        loadingAction={loadingCretae?.paypal}
      >
        <div className='flex flex-col font-Inter gap-4 p-0 m-0   '>
          <div className='flex flex-col w-full max-w-[580px]'>
            <RtlConversion rtl={i18n.language === 'ar' ? true : false}>
              <div className='flex flex-col items-center justify-center w-full  gap-y-4'>
                <BasicInput
                  label={t('PayPal Client Id')}
                  // placeholder=""
                  value={fields?.publicKey}
                  onChange={(e) => {
                    setFields({
                      ...fields,
                      publicKey: e.target.value,
                    });
                  }}
                  error={
                    formik.touched.publicKey && Boolean(formik.errors.publicKey)
                  }
                  helperText={
                    formik.touched.publicKey && formik.errors.publicKey
                  }
                  InputProps={{
                    style: { fontSize: '12px' },
                  }}
                />
                <BasicInput
                  label={t('PayPal Secret key')}
                  // placeholder=""
                  value={fields?.secretKey}
                  onChange={(e) => {
                    setFields({
                      ...fields,
                      secretKey: e.target.value,
                    });
                  }}
                  error={
                    formik.touched.secretKey && Boolean(formik.errors.secretKey)
                  }
                  helperText={
                    formik.touched.secretKey && formik.errors.secretKey
                  }
                  InputProps={{
                    style: { fontSize: '12px' },
                  }}
                />{' '}
              </div>
            </RtlConversion>
          </div>
        </div>
      </ModalDialog>

      <h1
        style={{
          color: bodyStyles?.title || '#808080',
        }}
        className='text-[#808080] text-[19px] font-[600] uppercase'
      >
        {t('PayPal')}
      </h1>

      <div className='w-full mt-3'>
        {/* <img src="/images/stripeplus.png" className="max-w-[450px] " /> */}
        <img
          src='/icons/PayPal.svg'
          alt='PayPal'
          className=' block cursor-pointer'
        />
      </div>
      <div className='flex items-center justify-start w-full py-6 text-lg max-w-[600px]'>
        <span
          style={{
            color: bodyStyles?.subTitle ? bodyStyles?.subTitle : '#888888',
          }}
          className='text-[#888888]'
        >
          {t(
            'Accept PayPal as a payment method using a “Checkout with PayPal” Button on your store.'
          )}
          {/* <span className="text-[#358DEB] cursor-pointer underline px-1 font-bold">
              {t("see more.")}
            </span> */}
        </span>
      </div>

      <div className='px-2'>
        {loading && (
          <CircularProgress
            style={{
              width: 15,
              height: 15,
              color: bodyStyles?.text,
            }}
          />
        )}
        {data?.setting_payment?.paypal_pk ? (
          <div className='flex '>
            {t('PayPal pk')}{' '}
            <div className='px-2 font-bold  flex'>
              <span>{data?.setting_payment?.paypal_pk.slice(0, 10)}</span>
              <p className='pt-1'>***************</p>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      <div className='flex items-start justify-start w-full mt-4'>
        <button
          type='button'
          // onClick={() => formik.submitForm()}
          onClick={() => setOpen(true)}
          className='bg-white text-[#414141] text-base rounded-lg px-8 py-3 flex flex-row items-center justify-center gap-x-4 border border-black'
        >
          <span>
            {/* {t("Add Stripe Payment")} */}
            {data?.setting_payment?.paypal_pk
              ? t('edit PayPal Payment')
              : t('Add PayPal Payment')}
          </span>
        </button>
      </div>
    </div>
  );
};

export default PaypalPaymeny;
