import React from "react";
import BasicInput from "../../../Inputs/BasicInput";
import { MailIcon, PhoneIcon } from "../../../Svg/MainIcons";
import FiledButton from "../../../Buttons/FiledButton";
import BasicButton from "../../../Buttons/BasicButton";
import { useTranslation } from "react-i18next";
import RtlConversion from "../../../RtlConversion";
import IconPlusText from "./IconPlusText";

const ContactInformation = ({
  edit,
  setEdit,
  contactInformation,
  setContactInformation,
  formik,
  onSubmit,
  loading,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="border-t-1 border-color p-4 lg:p-7 font-Inter">
      <div className="w-full flex justify-between text-sm">
        <p className="uppercase text-[#808080]">{t("Contact information")}</p>
        <p
          className="text-[#358DEB] cursor-pointer"
          onClick={() => setEdit(!edit)}
        >
          {t("Edit")}
        </p>
      </div>

      {edit ? (
        <RtlConversion rtl={i18n.language === "ar" ? true : false}>
          <div>
            <div className="flex flex-row gap-5 py-4 flex-wrap justify-between">
              <div className="w-full md:w-[49%] ">
                <BasicInput
                  label={t("Email")}
                  placeholder="contact@example.com"
                  value={contactInformation?.email}
                  onChange={(e) =>
                    setContactInformation({
                      ...contactInformation,
                      email: e.target.value,
                    })
                  }
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>

              <div className="w-full md:w-[49%] ">
                <BasicInput
                  label={t("Phone Number")}
                  placeholder="07 77777777"
                  value={contactInformation?.phone}
                  onChange={(e) =>
                    setContactInformation({
                      ...contactInformation,
                      phone: e.target.value,
                    })
                  }
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </div>
            </div>
            <div
              className=" items-center
           flex justify-between pt-8"
            >
              <div className="flex gap-2  justify-end w-full">
                <BasicButton
                  text={t("Cancel")}
                  onClick={() => setEdit(false)}
                  textSmall
                />
                {loading ? (
                  <FiledButton type="submit" loading />
                ) : (
                  <FiledButton
                    text={t("Save")}
                    onClick={() => onSubmit()}
                    minWidth="90px"
                  />
                )}
              </div>
            </div>
          </div>
        </RtlConversion>
      ) : (
        <>
          <div className="py-4 lg:py-8 grid grid-cols-1 md:grid-cols-2 gap-4">
            <IconPlusText
              icon={<MailIcon />}
              title={contactInformation?.email}
              detail={t("Smartinia Shop uses to contact you")}
            />
            <IconPlusText
              icon={<PhoneIcon />}
              title={contactInformation?.phone}
              detail={t("Smartinia Shop uses to call you")}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ContactInformation;
