import { gql } from "@apollo/client";

export const CREATE_DISCOUNT = gql`
  mutation CreateDiscount($createDiscount: CreateDiscountInput!) {
    createDiscount(createDiscount: $createDiscount) {
      id
      couponCode
    }
  } 
`;

export const EDIT_DISCOUNT = gql`
  mutation UpdateDiscount($updateDiscount: UpdateDiscountInputType!) {
    updateDiscount(updateDiscount: $updateDiscount) {
      id
      max_usage
      name
      perCustomerUsageLimit
      percentage
      remaining
      startsAt
      status
      updatedAt
      endsAt
      fixedAmount
      createdAt
      couponCode
      minimumPurchaseAmount
    }
  }
`;

export const DELETE_DISCOUNT = gql`
  mutation RemoveDiscount($removeDiscountId: [Int!]!) {
    removeDiscount(id: $removeDiscountId)
  }
`;
