import React, { useState } from 'react';
import BasicInput from '../../../Inputs/BasicInput';
import CustomButton from '../../../Buttons/CustomButton';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { SaveIcon } from '../../../Svg/MainIcons';
import { useTranslation } from 'react-i18next';
import RtlConversion from '../../../RtlConversion';
import { CircularProgress } from '@mui/material';
import {
  getTheTokenFromStorage,
  resetPassword,
} from '../../../../services/auth';
import {
  showErrorSettingMessage,
  showSuccessSettingMessage,
} from '../../../../store/features/snackbar/snackbarSlice';
import { useDispatch } from 'react-redux';

const Password = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  const token = getTheTokenFromStorage();

  const [loading, setLoading] = useState({
    passWord: false,
  });

  const [fields, setFields] = useState({
    current: '',
    newPassWord: '',
    confirmNewPassWord: '',
  });

  const resetPasswordValidation = Yup.object().shape({
    current: Yup.string()
      .required(t('This Field is required'))
      .min(1, t('password to short, should be at least 8 characters long')),
    newPassWord: Yup.string().required(t('This Field is required')),
    confirmNewPassWord: Yup.string()
      .required(t('This Field is required'))
      .oneOf([Yup.ref('newPassWord'), null], 'Passwords must match'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      current: fields?.current,
      newPassWord: fields?.newPassWord,
      confirmNewPassWord: fields?.confirmNewPassWord,
    },
    validationSchema: resetPasswordValidation,
    onSubmit: async (values) => {
      updatePassword(values);
    },
  });

  const updatePassword = async (values) => {
    setLoading({ ...loading, passWord: true });

    const dataSent = {
      input: {
        currentPassword: values?.current,
        newPassword: values?.newPassWord,
      },
    };

    const res = await resetPassword(dataSent, token);

    res.data
      ? dispatch(
          showSuccessSettingMessage('Password has been successfully updated')
        )
      : dispatch(showErrorSettingMessage(res));
    setLoading({ ...loading, profileInformation: false });
  };

  return (
    <RtlConversion rtl={i18n.language === 'ar' ? true : false}>
      <div className='flex w-full flex-col items-start justify-start '>
        <h1
          style={{ color: bodyStyles?.title ? bodyStyles?.title : '#808080' }}
          className='text-[19px] font-[600] uppercase text-[#808080]'
        >
          {t('Reset password')}
        </h1>
        <div className='flex w-full flex-row items-start justify-center'>
          <div className='flex w-full flex-col items-center justify-center gap-y-4  py-8'>
            <div className='grid w-full grid-cols-1 gap-x-20 gap-y-4 md:grid-cols-2 lg:grid-cols-3'>
              <BasicInput
                label={t('Current')}
                type='password'
                placeholder=''
                value={fields?.current}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    current: e.target.value,
                  });
                }}
                error={formik.touched.current && Boolean(formik.errors.current)}
                helperText={formik.touched.current && formik.errors.current}
                inputProps={{
                  style: { fontSize: '12px' },
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
              <BasicInput
                label={t('New Password')}
                type='password'
                placeholder='******'
                value={fields?.newPassWord}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    newPassWord: e.target.value,
                  });
                }}
                error={
                  formik.touched.newPassWord &&
                  Boolean(formik.errors.newPassWord)
                }
                helperText={
                  formik.touched.newPassWord && formik.errors.newPassWord
                }
                InputProps={{
                  style: { fontSize: '12px' },
                }}
              />
              <BasicInput
                label={t('Confirm New Password')}
                type='password'
                placeholder='******'
                value={fields?.confirmNewPassWord}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    confirmNewPassWord: e.target.value,
                  });
                }}
                error={
                  formik.touched.confirmNewPassWord &&
                  Boolean(formik.errors.confirmNewPassWord)
                }
                helperText={
                  formik.touched.confirmNewPassWord &&
                  formik.errors.confirmNewPassWord
                }
                InputProps={{
                  style: { fontSize: '12px' },
                }}
              />
            </div>
          </div>
        </div>
        <div className='flex w-full items-end justify-end'>
          <CustomButton
            onClick={() => formik.submitForm()}
            className=' flex flex-row items-center justify-center gap-x-4 rounded-lg px-8 py-3 text-base'
          >
            <SaveIcon color={bodyStyles?.filedButtonText} />
            <span style={{ color: bodyStyles?.filedButtonText }}>
              {loading?.passWord ? (
                <CircularProgress
                  style={{
                    width: 15,
                    height: 15,
                    color: bodyStyles?.filedButtonText,
                  }}
                />
              ) : (
                t('Change Password')
              )}
            </span>
          </CustomButton>
        </div>
      </div>
    </RtlConversion>
  );
};

export default Password;
