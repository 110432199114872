import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingDelete: false,
  loadingDeleteAll: false,
  loadingCreate: false,
  loadingEdit: false,
  loadingImport: false,
  data: null,
  error: null,
  errorFetch: null,
  success: null,
  totalCount: 0,
  selectedOne: null,
  selectedMany: [],
  isSelectedMany: false,
  deleteModal: false,
  deleteAllModal: false,
  createModal: false,
  createAllModal: false,
  editModal: false,
  editAllModal: false,
  exportModal: false,
  exportSelectedModal: false,
  importModal: false,
};

export const zoneSlice = createSlice({
  name: 'zone',
  initialState,
  reducers: {
    // fetchInstance
    fetchZonesStart: (state) => {
      state.loading = true;
    },
    fetchZonesSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload?.zones;
      state.totalCount = action.payload?.totalCount;
      state.errorFetch = false;
    },
    fetchZonesFail: (state, action) => {
      state.loading = false;
      state.data = null;
      state.errorFetch = action.payload;
    },

    // createInstance
    createZoneStart: (state) => {
      state.loadingCreate = true;
    },
    createZoneSuccess: (state, action) => {
      state.loadingCreate = false;
      state.data.unshift(action.payload);
      state.success = 'Zone created successfully.';
    },
    createZoneFail: (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    },

    // editInstance
    editZoneStart: (state) => {
      state.loadingEdit = true;
    },
    editZoneSuccess: (state, action) => {
      state.loadingEdit = false;
      state.data = state.data.map((zone) =>
        zone.id === action.payload.id ? action.payload : zone
      );
      state.success = 'Zone updated successfully.';
    },
    editZoneFail: (state, action) => {
      state.loadingEdit = false;
      state.error = action.payload;
    },

    // delete Instance
    deleteZoneStart: (state) => {
      state.loadingDelete = true;
    },
    deleteZoneSuccess: (state, action) => {
      state.loadingDelete = false;
      state.data = state.data.filter((zone) => zone.id !== action.payload);
      state.success = 'Zone deleted successfully';
      state.deleteModal = false;
    },
    deleteZoneFail: (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
      state.deleteModal = false;
    },

    // delete Many Instances
    deleteZonesStart: (state) => {
      state.loadingDeleteAll = true;
    },
    deleteZonesSuccess: (state, action) => {
      state.loadingDeleteAll = false;
      const deletedIds = action.payload;
      // state.data = state.data.filter((zone) => !deletedIds.includes(zone.id));
      state.success = 'Zones deleted successfully';
      state.deleteAllModal = false;
    },
    deleteZonesFail: (state, action) => {
      state.loadingDeleteAll = false;
      state.error = action.payload;
      state.deleteAllModal = false;
    },

    // selectInstance

    selectOne: (state, action) => {
      state.selectedOne = action.payload;
    },

    selectAll: (state, action) => {
      if (state.isSelectedMany) {
        state.selectedMany = [];
        state.isSelectedMany = false;
      } else {
        state?.data?.map((item) => {
          if (!state.selectedMany.includes(item.id)) {
            state.selectedMany = [...state.selectedMany, item.id];
          }
        });
        state.isSelectedMany = true;
      }
    },

    removeAll: (state, action) => {
      state.selectedMany = [];
      state.isSelectedMany = false;
    },

    updateSelected: (state, action) => {
      if (state.selectedMany.includes(action.payload)) {
        state.selectedMany = state.selectedMany.filter((item) => {
          return item !== action.payload;
        });
      } else {
        state.selectedMany = [...state.selectedMany, action.payload];
      }
    },

    // DELETEiNSTANCE

    openModelDelete: (state, action) => {
      state.selectedOne = action.payload;
      state.deleteModal = !state.deleteModal;
    },

    openModelDeleteAll: (state, action) => {
      state.deleteAllModal = !state.deleteAllModal;
    },

    openModelCreate: (state, action) => {
      state.createModal = !state.createModal;
    },

    openModelEdit: (state, action) => {
      state.selectedOne = action.payload;
      state.editModal = !state.editModal;
    },
    // ExportSelected

    openModeleExportAll: (state, action) => {
      state.exportModal = true;
    },

    openModeleExportSelected: (state, action) => {
      state.exportSelectedModal = true;
    },

    // Import

    openModeleImport: (state, action) => {
      state.importModal = !state.importModal;
    },

    importStart: (state) => {
      state.loadingImport = true;
    },
    importSuccess: (state, action) => {
      state.loadingImport = false;
      state.success = 'Zones Imported successfully';
      state.importModal = false;
    },
    importFail: (state, action) => {
      state.loadingImport = false;
      state.error = action.payload;
      state.importModal = false;
    },

    // CLEAR
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  fetchZonesStart,
  fetchZonesSuccess,
  fetchZonesFail,
  createZoneStart,
  createZoneSuccess,
  createZoneFail,
  editZoneStart,
  openModelEdit,
  openModelCreate,
  editZoneSuccess,
  editZoneFail,
  deleteZoneStart,
  deleteZoneSuccess,
  deleteZoneFail,
  clearMessages,
  updateSelected,
  selectAll,
  selectOne,
  openModelDelete,
  openModelDeleteAll,
  openModeleExportAll,
  openModeleExportSelected,
  deleteZonesStart,
  deleteZonesSuccess,
  deleteZonesFail,
  openModeleImport,
  importStart,
  importSuccess,
  importFail,
  removeAll,
} = zoneSlice.actions;

export default zoneSlice.reducer;
