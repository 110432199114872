import React, { Fragment, Component, useState } from "react";

import Editor from "react-simple-code-editor";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";
import RtlConversion from "./RtlConversion";
import { useTranslation } from "react-i18next";

const styles = {
  root: {
    boxSizing: "border-box",
    borderRadius: "4px",
    fontFamily: '"Dank Mono", "Fira Code", monospace',
    ...theme.plain,
  },
};

const EditorExample = ({ code, setCode }) => {
  const { t, i18n } = useTranslation();

  const onValueChange = (code) => {
    setCode(code);
  };

  const highlight = (code) => (
    <Highlight {...defaultProps} theme={theme} code={code} language="jsx">
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Fragment>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })} key={i}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </Fragment>
      )}
    </Highlight>
  );
  return (
    <Editor
      value={code}
      onValueChange={onValueChange}
      highlight={highlight}
      padding={10}
      style={styles.root}
    />
  );
};

export default EditorExample;
