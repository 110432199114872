import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const usePageState = (sortOptions, defaultSortBy, tabsOptions) => {
  const location = useLocation();
  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const [order, setOrder] = useState({});
  const [params, setParams] = useState([]);

  
  const tabs = tabsOptions;
  const [tabSelect, setTabSelect] = useState(
    tabsOptions.length ? tabsOptions[0].value : null
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = Array.from(searchParams.entries());
    setParams(params);
    setStatus(searchParams.get('status'));
  }, [location]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = Array.from(searchParams.entries());
    setParams([]);
    setStatus(null);
    setSearch('');
    setFilter('');
    setSortBy(defaultSortBy);
    setTabSelect(tabsOptions.length ? tabsOptions[0].value : null);
  }, [location.pathname]);

  useEffect(() => {
    const newOrder = {};
    params.forEach((item) => {
      if (item[0] === 'order') {
        const [field, direction] = item[1]?.split('-') ?? [];
        if (field && direction) {
          newOrder[field] = direction;
        }
      }
    });
    setOrder(newOrder);
  }, [params]);

  const handleTabChange = (event, newValue) => {
    setTabSelect(newValue);
  };

  return {
    status,
    search,
    filter,
    sortBy,
    order,
    tabs,
    tabSelect,
    sortOptions,
    handleTabChange,
    setSearch,
    setFilter,
    setSortBy,
    setStatus,
    setOrder,
  };
};

export default usePageState;
