import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingDelete: false,
  loadingDeleteAll: false,
  loadingCreate: false,
  loadingEdit: false,
  loadingImport: false,
  data: null,
  error: null,
  errorFetch: null,
  success: null,
  totalCount: 0,
  selectedOne: null,
  selectedMany: [],
  isSelectedMany: false,
  deleteModal: false,
  deleteAllModal: false,
  createModal: false,
  createAllModal: false,
  editModal: false,
  editAllModal: false,
  exportModal: false,
  exportSelectedModal: false,
  importModal: false,
};

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    // fetchInstance
    fetchContactsStart: (state) => {
      state.loading = true;
    },
    fetchContactsSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload?.contacts?.nodes;
      state.totalCount = action.payload?.contacts?.totalCount;
      state.errorFetch = false;
    },
    fetchContactsFail: (state, action) => {
      state.loading = false;
      state.data = null;
      state.errorFetch = action.payload;
    },

    // createInstance
    createContactStart: (state) => {
      state.loadingCreate = true;
    },
    createContactSuccess: (state, action) => {
      state.loadingCreate = false;
      state.data.unshift(action.payload);
      state.success = 'Contact created successfully.';
    },
    createContactFail: (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    },

    // editInstance
    editContactStart: (state) => {
      state.loadingEdit = true;
    },
    editContactSuccess: (state, action) => {
      state.loadingEdit = false;
      state.data = state.data.map((contact) =>
        contact.id === action.payload.id ? action.payload : contact
      );
      state.success = 'Contact updated successfully.';
    }, 
    editContactFail: (state, action) => {
      state.loadingEdit = false;
      state.error = action.payload;
    },

    deleteContactStart: (state) => {
      state.loadingDelete = true;
    },

    markMessageUsRead: (state, action) => {
      state.data = state?.data?.map((item) => {
        if (item.id === action.payload) {
          return {
            ...item,
            read: true,
          };
        }
        return item;
      });
    },
    deleteContactSuccess: (state, action) => {
      state.loadingDelete = false;
      state.data = state.data.filter(
        (contact) => contact.id !== action.payload
      );
      state.success = 'Contact deleted successfully';
      state.deleteModal = false;
    },
    deleteContactFail: (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
      state.deleteModal = false;
    },

    // delete Many Instances
    deleteContactsStart: (state) => {
      state.loadingDeleteAll = true;
    },
    deleteContactsSuccess: (state, action) => {
      state.loadingDeleteAll = false;
      const deletedIds = action.payload;
      // state.data = state.data.filter((contact) => !deletedIds.includes(contact.id));
      state.success = 'Contacts deleted successfully';
      state.deleteAllModal = false;
    },
    deleteContactsFail: (state, action) => {
      state.loadingDeleteAll = false;
      state.error = action.payload;
      state.deleteAllModal = false;
    },

    // selectInstance

    selectOne: (state, action) => {
      state.selectedOne = action.payload;
    },

    selectAll: (state, action) => {
      if (state.isSelectedMany) {
        state.selectedMany = [];
        state.isSelectedMany = false;
      } else {
        // state?.data?.map((item) => {
        //   if (!state.selectedMany.includes(item.id)) {
        //     state.selectedMany = [...state.selectedMany, item.id];
        //   }
        // });
        state.isSelectedMany = true;
      }
    },

    removeAll: (state, action) => {
      state.selectedMany = [];
      state.isSelectedMany = false;
    },

    updateSelected: (state, action) => {
      if (state.selectedMany.includes(action.payload)) {
        state.selectedMany = state.selectedMany.filter((item) => {
          return item !== action.payload;
        });
      } else {
        state.selectedMany = [...state.selectedMany, action.payload];
      }
    },

    // DELETEiNSTANCE

    openModelDelete: (state, action) => {
      state.selectedOne = action.payload;
      state.deleteModal = !state.deleteModal;
    },

    openModelDeleteAll: (state, action) => {
      state.deleteAllModal = !state.deleteAllModal;
    },

    openModelCreate: (state, action) => {
      state.createModal = !state.createModal;
    },

    openModelEdit: (state, action) => {
      state.selectedOne = action.payload;
      state.editModal = !state.editModal;
    },
    // ExportSelected

    openModeleExportAll: (state, action) => {
      state.exportModal = true;
    },

    openModeleExportSelected: (state, action) => {
      state.exportSelectedModal = true;
    },

    // Import

    openModeleImport: (state, action) => {
      state.importModal = !state.importModal;
    },

    importStart: (state) => {
      state.loadingImport = true;
    },
    importSuccess: (state, action) => {
      state.loadingImport = false;
      state.success = 'Contacts Imported successfully';
      state.importModal = false;
    },
    importFail: (state, action) => {
      state.loadingImport = false;
      state.error = action.payload;
      state.importModal = false;
    },

    // CLEAR
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  fetchContactsStart,
  fetchContactsSuccess,
  fetchContactsFail,
  createContactStart,
  createContactSuccess,
  createContactFail,
  editContactStart,
  openModelEdit,
  openModelCreate,
  editContactSuccess,
  editContactFail,
  deleteContactStart,
  deleteContactSuccess,
  deleteContactFail,
  clearMessages,
  updateSelected,
  selectAll,
  selectOne,
  openModelDelete,
  openModelDeleteAll,
  openModeleExportAll,
  openModeleExportSelected,
  deleteContactsStart,
  deleteContactsSuccess,
  deleteContactsFail,
  openModeleImport,
  importStart,
  importSuccess,
  importFail,
  removeAll,
  markMessageUsRead,
} = contactSlice.actions;

export default contactSlice.reducer;
