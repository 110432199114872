import { delay } from "../utils/helpers";

export const createInstance = ({
  setInstanceState,
  res,
  successAction,
  successActionBefore,
  successMessage,
  errorAction,
  errorActionBefore,
  errorMeesage,
}) => {


  if (res) {
    setInstanceState((prev) => {
      return {
        ...prev,
        createInstance: true,
        loadingCreateInstance: false,
        message: successMessage,
      };
    });
    successActionBefore && successActionBefore();

    delay(2000).then(async () => {
      setInstanceState((prev) => {
        return { ...prev, createInstance: false };
      });

      successAction && successAction();
    });
  } else {
    
    setInstanceState((prev) => {
      return {
        ...prev,
        errorCreatInstance: true,
        loadingCreateInstance: false,
        message: errorMeesage,
      };
    });

    errorActionBefore && errorActionBefore();

    delay(3000).then(async () => {
      setInstanceState((prev) => {
        return { ...prev, errorCreatInstance: false };
      });
      errorAction && errorAction();
    });
  }
};

export const deleteInstance = ({
  setInstanceState,
  res,
  successAction,
  successActionBefore,
  successMessage,
  errorAction,
  errorActionBefore,
  errorMeesage,
}) => {
  if (res) {
    setInstanceState((prev) => {
      return {
        ...prev,
        createInstance: true,
        loadingDeleteInstance: false,
        message: successMessage,
      };
    });

    successActionBefore && successActionBefore();

    delay(2000).then(async () => {
      setInstanceState((prev) => {
        return { ...prev, createInstance: false };
      });

      successAction && successAction();
    });
  } else {

    setInstanceState((prev) => {
      return {
        ...prev,
        errorCreatInstance: true,
        loadingDeleteInstance: false,
        message: errorMeesage,
      };
    });

    errorActionBefore && errorActionBefore();

    delay(3000).then(async () => {
      setInstanceState((prev) => {
        return { ...prev, errorCreatInstance: false };
      });
      errorAction && errorAction();
    });
  }
};
