import React, { useEffect } from 'react';
import FetchPageSetting from '../../../fetchPages/FetchPageSetting';
import { pagesSettingsData } from '../../../../pages_data/pages_data';
import { useDispatch } from 'react-redux';
import { fetchZones } from '../../../../services/zone';

const ShippingMethod = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchZones({
        country: true,
        name: '',
      })
    );
  }, []);

  return (
    <div className='flex w-full'>
      <FetchPageSetting
        data={pagesSettingsData?.shippingMethod?.main}
        headerStikyPostion={0}
      />
    </div>
  );
};

export default ShippingMethod;
