import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingDelete: false,
  loadingDeleteAll: false,
  loadingCreate: false,
  loadingEdit: false,
  loadingImport: false,
  data: [],
  error: null,
  errorFetch: null,
  success: null,
  totalCount: 0,
  selectedOne: null,
  selectedMany: [],
  isSelectedMany: false,
  deleteModal: false,
  deleteAllModal: false,
  createModal: false,
  createAllModal: false,
  editModal: false,
  editAllModal: false,
  exportModal: false,
  exportSelectedModal: false,
  importModal: false,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // fetchInstance
    fetchCustomersStart: (state) => {
      state.loading = true;
    },
    fetchCustomersSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload?.Customers?.nodes;
      state.totalCount = action.payload?.Customers?.totalCount;
      state.errorFetch = false;
    },
    fetchCustomersFail: (state, action) => {
      state.loading = false;
      state.data = [];
      state.errorFetch = action.payload;
    },

    // createInstance
    createCustomerStart: (state) => {
      state.loadingCreate = true;
    },
    createCustomerSuccess: (state, action) => {
      state.loadingCreate = false;
      state.data.unshift(action.payload);
      state.success = 'Customer created successfully.';
    },
    createCustomerFail: (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    },

    // editInstance
    editCustomerStart: (state) => {
      state.loadingEdit = true;
    },
    editCustomerSuccess: (state, action) => {
      state.loadingEdit = false;
      state.data = state.data.map((customer) =>
        customer.id === action.payload.id ? action.payload : customer
      );
      state.success = 'Customer updated successfully.';
    },
    editCustomerFail: (state, action) => {
      state.loadingEdit = false;
      state.error = action.payload;
    },

    // delete Instance
    deleteCustomerStart: (state) => {
      state.loadingDelete = true;
    },
    deleteCustomerSuccess: (state, action) => {
      state.loadingDelete = false;
      state.data = state.data.filter(
        (customer) => customer.id !== action.payload
      );
      state.success = 'Customer deleted successfully';
      state.deleteModal = false;
    },
    deleteCustomerFail: (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
      state.deleteModal = false;
    },

    // delete Many Instances
    deleteCustomersStart: (state) => {
      state.loadingDeleteAll = true;
    },
    deleteCustomersSuccess: (state, action) => {
      state.loadingDeleteAll = false;
      const deletedIds = action.payload;
      state.data = state.data.filter(
        (customer) => !deletedIds.includes(customer.id)
      );
      state.success = 'Customers deleted successfully';
      state.deleteAllModal = false;
    },
    deleteCustomersFail: (state, action) => {
      state.loadingDeleteAll = false;
      state.error = action.payload;
      state.deleteAllModal = false;
    },

    // selectInstance

    selectOne: (state, action) => {
      state.selectedOne = action.payload;
    },

    selectAll: (state, action) => {
      if (state.isSelectedMany) {
        state.selectedMany = [];
        state.isSelectedMany = false;
      } else {
        state?.data?.map((item) => {
          if (!state.selectedMany.includes(item.id)) {
            state.selectedMany = [...state.selectedMany, item.id];
          }
        });
        state.isSelectedMany = true;
      }
    },

    removeAll: (state, action) => {
      state.selectedMany = [];
      state.isSelectedMany = false;
    },

    updateSelected: (state, action) => {
      if (state.selectedMany.includes(action.payload)) {
        state.selectedMany = state.selectedMany.filter((item) => {
          return item !== action.payload;
        });
      } else {
        state.selectedMany = [...state.selectedMany, action.payload];
      }
    },

    // DELETEiNSTANCE

    openModelDelete: (state, action) => {
      state.selectedOne = action.payload;
      state.deleteModal = !state.deleteModal;
    },

    openModelDeleteAll: (state, action) => {
      state.deleteAllModal = !state.deleteAllModal;
    },

    openModelCreate: (state, action) => {
      state.createModal = !state.createModal;
    },

    openModelEdit: (state, action) => {
      state.selectedOne = action.payload;
      state.editModal = !state.editModal;
    },
    // ExportSelected

    openModeleExportAll: (state, action) => {
      state.exportModal = true;
    },

    openModeleExportSelected: (state, action) => {
      state.exportSelectedModal = true;
    },

    // Import

    openModeleImport: (state, action) => {
      state.importModal = !state.importModal;
    },

    importStart: (state) => {
      state.loadingImport = true;
    },
    importSuccess: (state, action) => {
      state.loadingImport = false;
      state.success = 'Customers Imported successfully';
      state.importModal = false;
    },
    importFail: (state, action) => {
      state.loadingImport = false;
      state.error = action.payload;
      state.importModal = false;
    },

    // CLEAR
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  fetchCustomersStart,
  fetchCustomersSuccess,
  fetchCustomersFail,
  createCustomerStart,
  createCustomerSuccess,
  createCustomerFail,
  editCustomerStart,
  editCustomerSuccess,
  editCustomerFail,
  deleteCustomerStart,
  deleteCustomerSuccess,
  deleteCustomerFail,
  clearMessages,
  updateSelected,
  selectAll,
  selectOne,
  openModelDelete,
  openModelDeleteAll,
  openModeleExportAll,
  openModeleExportSelected,
  deleteCustomersStart,
  deleteCustomersSuccess,
  deleteCustomersFail,
  openModeleImport,
  openModelCreate,
  openModelEdit,
  importStart,
  importSuccess,
  importFail,
  removeAll,
} = customerSlice.actions;

export default customerSlice.reducer;
