import { gql } from '@apollo/client';

export const GET_ALL_ROLES = gql`
  query Roles($search: String, $offset: Int!, $limit: Int!) {
    roles(search: $search, offset: $offset, limit: $limit) {
      hasNextPage
      totalCount
      nodes {
        id
        name
        description
        createdAt
        permissions {
          description
          groupName
          id
          subject
          name
          updatedAt
          createdAt
          action
        }
        updatedAt
      }
    }
  }
`;
