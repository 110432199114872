import React, { useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { delay } from '../../utils/helpers';

// const SuccessModal = ({ open, setOpen, message, init }) => {
const SuccessModal = () => {
  const { t, i18n } = useTranslation();
  const snackbar = useSelector((state) => state.snackbar);

  const [init, setInit] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (snackbar.successMessage || snackbar.errorMessage) {
      snackbar.successMessage
        ? setSuccessMessage(snackbar.successMessage)
        : setErrorMessage(snackbar.errorMessage);
      setOpen(true);
      delay(2000).then(async () => {
        setOpen(false);
      });
      delay(3000).then(async () => {
        setSuccessMessage('');
        setErrorMessage('');
      });
    }
  }, [snackbar]);

  return (
    <div
      className={`fixed border-[1px] fill-available  border-green z-[5] flex items-center 
      justify-center text-green text-lg font-semibold duration-[1s] uppercase p-2 pt-4 rounded-md
        ${i18n.language === 'ar' ? 'ml-4' : 'mr-4'} 
        ${open ? 'top-[90px] md:top-[70px]' : '-top-[150px]'}
        ${init ? 'h-[50px]' : 'h-[100px]'}
        ${
          !init
            ? open
              ? 'top-[90px] md:top-[70px]'
              : '-top-[150px]'
            : open
            ? 'top-[40px] md:top-[40px]'
            : '-top-[150px]'
        }
        ${
          successMessage
            ? 'bg-[#e5fde5] text-[#1e4620]'
            : 'bg-[#fdeded] text-[#5f2120]'
        }
    `}
    >
      <div
        onClick={() => setOpen(false)}
        className={`absolute top-[2px]  ${
          i18n.language === 'ar' ? 'left-[2px]' : 'right-[2px]'
        }`}
      >
        <IconButton>
          <ClearIcon className='text-green' />
        </IconButton>
      </div>
      <div>
        {successMessage ? (
          t(successMessage)
        ) : (
          <div>
            <span className='font-[600] px-2'>{t('ERROR')} :</span>{' '}
            {t(errorMessage)}
          </div>
        )}
      </div>
    </div>
  );
};

export default SuccessModal;
