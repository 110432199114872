import { useState } from 'react';
import { useFormik } from 'formik';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { initInstanceState } from '../../utils/initStates';
import BottomBanner from '../../components/Actions/BottomBanner';
import PageModal from '../../components/PageModal';
import ModalNotFound from '../../components/MessageModals/ModalNotFound';
import { useDispatch, useSelector } from 'react-redux';
import { GET_PAGE } from '../../graphql/query/pages.query';
import * as Yup from 'yup';
import { handleEditPlanning } from '../../services/planning';
import EditPlanningForm from '../../components/Planning/updatePlanning/EditPlanningForm';


const EditService = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.planning);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const params = useParams();
  const [instanceState, setInstanceState] = useState(initInstanceState);

  const [images, setImages] = useState([]);

  const [fields, setFields] = useState({
    name: '',
    htmlBody: '',
    description: '',
    type: 'PLANNING',
    categories: [],
  });

  const {
    loading: loadingInstance,
    error: errorInstance,
    refetch: refetchInstance,
    data: instanceData,
  } = useQuery(GET_PAGE, {
    variables: {
      pageId: instanceState.id,
    },
    skip: instanceState.id ? false : true,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setInstanceState((prev) => {
      return { ...prev, id: +params?.id };
    });
  }, [params]);

  useEffect(() => {
    if (instanceData?.page) {
    
        setFields((prev) => {
          return {
            ...prev,
            name: instanceData?.page?.name,
            htmlBody: instanceData?.page?.htmlBody,
            description: instanceData?.page?.description,
            categories: instanceData?.page?.categories || [],
          };
        });
  
        setImages(instanceData?.page?.media || []);
    }
  }, [instanceData]);

  const addInstanceSchema = Yup.object().shape({
    name: Yup.string().required(t('This Field is required')),
    description: Yup.string(),
    htmlBody: Yup.string(),
    type: Yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id : instanceState?.id,
      name: fields?.name,
      description: fields?.description,
      htmlBody: fields?.htmlBody,
      type: fields?.type,
      categories : fields?.categories,
      images: images,
    },
    validationSchema: addInstanceSchema,
    onSubmit: async (values) => {
      dispatch(
        handleEditPlanning(values, (res) => {})
      );
    },
  });


  return (
    <PageModal
      pageName={t('Edit Planning')}
      pagePrev={`/planning`}
      loading={loadingInstance}
    >
      <div
        className={`${
          instanceData?.page ? 'opacity-100' : 'opacity-0 hidden'
        }`}
      >
        <BottomBanner
          btn01={t('Cancel')}
          btn01Action={() => navigate(`/planning`)}
          btn02={t('Save Planning')}
          btn02Action={() => formik.handleSubmit()}
          loading={data?.loadingEdit}
        />
      </div>

 

      {instanceData && !errorInstance ? (
        <EditPlanningForm
          fields={fields}
          setFields={setFields}
          formik={formik}
          images={images}
          setImages={setImages}
          instanceData={instanceData}
        />
      ) : (
        <ModalNotFound
          message='Item Not Found.'
          returnMessage='Return to planning page'
          action={() => navigate(`/planning`)}
        />
      )}
    </PageModal>
  );
};
export default EditService;
