import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { generalData } from '../utils/general';

export const uploadImage = async (image) => {
  const formData = new FormData();

  let compressFile = await resizeFile(image);
  formData.append('image', compressFile);
  formData.append('alt', image.alt || '');

  for (const [name, value] of formData.entries()) {
    console.log('formData ==>', name, value);
  }

  try {
    const response = await axios({
      method: 'post',
      url: `https://${generalData.backendApi}/upload`,
      data: formData,
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const uploadImageOriginal = async (image) => {
  const formData = new FormData();
  formData.append('image', image);
  try {
    const response = await axios({
      method: 'post',
      url: `https://${generalData.backendApi}/upload`,
      data: formData,
    });
    return response;
  } catch (error) {
    return null;
  }
};

// export const resizeFile = async (file) =>
//   new Promise((resolve) => {
//     Resizer.imageFileResizer(
//       file,
//       1024,
//       1024,
//       "webp",
//       80,
//       0,
//       (uri) => {
//         resolve(uri);
//       },
//       "file"
//     );
//   });

export const resizeFile = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      Resizer.imageFileResizer(
        file,
        1024,
        1024,
        'webp',
        80,
        0,
        (resizedFile) => {
          const modifiedFile = new File([resizedFile], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          modifiedFile.alt = file.alt || '';
          modifiedFile.path = file.path || '';
          resolve(modifiedFile);
        },
        'file'
      );
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
