import React, { useCallback, useMemo } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

//Services

const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  borderRadius: 4,
  color: "#bdbdbd",
  borderWidth: "2px",
  borderColor: "#808080",
  borderStyle: "dashed",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function Basic({
  setImages,
  images,
  setLoadingUpload,
  setUploadMore,
  uploadMore,
  single,
  text,
  button01,
  button02,
  actionButton02,
  accept,
  setOpenUrlModal,
  height,
  error,
}) {
  const { bodyStyles } = useStateContext();
  const { t, i18n } = useTranslation();

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles) {
      setUploadMore(false);
      setLoadingUpload(acceptedFiles?.length);
      setImages((current) => [...current, ...acceptedFiles]);

      setLoadingUpload(null);
      single ? setUploadMore(false) : setUploadMore(true);
    }
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, multiple: single ? false : true, accept: accept });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div {...getRootProps({ style })}>
      <div
        className="flex flex-grow  justify-center items-center rounded-md "
        style={{
          borderColor: error? "red" : bodyStyles?.subTitle,
          height: height ? height : "140px",
        }}
      >
        <div
          className="flex flex-col gap-3"
          style={{
            color: bodyStyles.subText,
          }}
        >
          <div className="flex justify-around gap-4  items-center flex-wrap">
            <div
              className="bg-[#CEE2FF] text-[#0E4DA4] px-4
           py-1.5 rounded-md text-sm cursor-pointer"
            >
              <input {...getInputProps()} />
              {button01}
            </div>

            {button02 && (
              <div
                className="text-[#0E4DA4] px-0 rounded-sm border-b-[2px]  text-sm
                          border-[#0E4DA4] pb-0 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  actionButton02 && actionButton02()
                }}
              >
                {t(button02)}
              </div>
            )}
          </div>

          {text && (
            <div className={``}>
              <span className="text-sm ">{text}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
