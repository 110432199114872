import { gql } from "@apollo/client";

export const CREATE_BRAND = gql`
  mutation CreateBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      id
    } 
  }
`;

export const EDIT_BRAND = gql`
  mutation UpdateBrand($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      createdAt
      deletedAt
      id
      translationData {
        name
        languageCode
        id
        description
        createdAt
        slug
        updatedAt
      }
      media {
        id
        createdAt
        updatedAt
        deletedAt
        original_name
        file_name
        path
        host
        full_url
        type
        alt
      }
      updatedAt
    }
  }
`;

export const DELETE_BRAND = gql`
  mutation RemoveBrand($removeBrandId: Int!) {
    removeBrand(id: $removeBrandId)
  }
`;
