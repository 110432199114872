import { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { delay } from '../../utils/helpers';
import { GET_PRODUCT } from '../../graphql/query/products.query';
import { useEffect } from 'react';
import { initInstanceState } from '../../utils/initStates';
import { useProductValidation } from '../../validations/product.validation';
import { generalData } from '../../utils/general';
import { storeContext } from '../../global/store';
import { handleDeleteProduct, handleEditProduct } from '../../services/product';
import BottomBanner from '../../components/Actions/BottomBanner';
import PageModal from '../../components/PageModal';
import ModalNotFound from '../../components/MessageModals/ModalNotFound';
import ModalDialog from '../../components/ModalDialog';
// import EditProductForm from '../../components/Products/updateProduct/EditProductForm';
import slugify from 'slugify';
import AddAssestsModal from '../../components/Products/AddAssestsModal';
import BasicButtonWithIcon from '../../components/Buttons/BasicButtonWithIcon';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { openModelDelete } from '../../store/features/product/productSlicer';
import * as Yup from 'yup';
import EditProductForm from '../../components/Productsv2/updateProduct/EditProductForm';

const EditProduct = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.product);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const params = useParams();
  const [instanceState, setInstanceState] = useState(initInstanceState);

  const [images, setImages] = useState([]);

  const [fields, setFields] = useState({
    name: '',
    htmlBody: '',
    description: '',
    categories: [],
  });

  const {
    loading: loadingInstance,
    error: errorInstance,
    refetch: refetchInstance,
    data: instanceData,
  } = useQuery(GET_PRODUCT, {
    variables: {
      productId: instanceState.id,
    },
    skip: instanceState.id ? false : true,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setInstanceState((prev) => {
      return { ...prev, id: +params?.id };
    });
  }, [params]);

  useEffect(() => {
    if (instanceData?.product) {
      setFields((prev) => {
        return {
          ...prev,
          name: instanceData?.product?.name,
          htmlBody: instanceData?.product?.body,
          categories: instanceData?.product?.categories,
        };
      });

      setImages(instanceData?.product?.media || []);
    }
  }, [instanceData]);

  const addInstanceSchema = Yup.object().shape({
    name: Yup.string().required(t('This Field is required')),
    description: Yup.string(),
    htmlBody: Yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: instanceState?.id,
      name: fields?.name,
      description: fields?.description,
      htmlBody: fields?.htmlBody,
      type: fields?.type,
      categories: fields?.categories,
      images: images,
    },
    validationSchema: addInstanceSchema,
    onSubmit: async (values) => {
      dispatch(handleEditProduct(values, (res) => {}));
    },
  });

  return (
    <PageModal
      pageName={t('Edit Product')}
      pagePrev={`/products`}
      loading={loadingInstance}
    >
      <div
        className={`${instanceState?.id ? 'opacity-100' : 'opacity-0 hidden'}`}
      >
        <BottomBanner
          btn01={t('Cancel')}
          btn01Action={() => navigate(`/products`)}
          btn02={t('Save Product')}
          btn02Action={() => formik.handleSubmit()}
          loading={data?.loadingEdit}
        />
      </div>

      {instanceData && !errorInstance ? (
        <EditProductForm
          fields={fields}
          setFields={setFields}
          formik={formik}
          images={images}
          setImages={setImages}
          instanceData={instanceData}
        />
      ) : (
        <ModalNotFound
          message='Item Not Found.'
          returnMessage='Return to Products page'
          action={() => navigate(`/products`)}
        />
      )}
    </PageModal>
  );
};
export default EditProduct;
