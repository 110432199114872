import { useContext, useEffect, useState } from 'react';
import { InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_COLLECTION } from '../../graphql/query/collections.query';
import { useCollectionValidation } from '../../validations/collection.validation';
import { initInstanceState } from '../../utils/initStates';
import DragImages from '../../components/DragImages';
import DragToUpload from '../../components/DragToUpload';
import Tabs from '../../components/TabsLanguages';
import BasicInput from '../../components/Inputs/BasicInput';
import BottomBanner from '../../components/Actions/BottomBanner';
import PageModal from '../../components/PageModal';
import ModalNotFound from '../../components/MessageModals/ModalNotFound';
import { storeContext } from '../../global/store';
import { handleEditCollection } from '../../services/collection';
import { useDispatch, useSelector } from 'react-redux';

const EditCollection = () => {
  const { store } = useContext(storeContext);
  const params = useParams();
  
  const dispatch = useDispatch();
  const data = useSelector((state) => state.collection);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  const [instanceState, setInstanceState] = useState(initInstanceState);
  const { editCollectionSchema } = useCollectionValidation();



  const [fields, setFields] = useState({
    menus: [],
  });
  const [translationData, setTranslationData] = useState({
    [store.preferredLanguage]: { name: '', description: '' },
  });

  useEffect(() => {
    setTranslationData({
      [store.preferredLanguage]: { name: '', description: '' },
    });
  }, [store.preferredLanguage]);

  const [featuredAsset, setFeaturedAsset] = useState([]);

  useEffect(() => {
    setInstanceState((prev) => {
      return { ...prev, id: +params?.id };
    });
  }, [params]);

  const {
    loading: loadingInstance,
    error: errorInstance,
    refetch: refetchInstance,
    data: instanceData,
  } = useQuery(GET_COLLECTION, {
    variables: {
      collectionId: instanceState.id,
    },
    skip: instanceState.id ? false : true,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (instanceData?.collection) {
      setInstanceState((prev) => {
        return { ...prev, existe: true };
      });

      let prevTranslationData = {};
      instanceData?.collection?.translationData.map((item) => {
        prevTranslationData = {
          ...prevTranslationData,
          [item?.languageCode.toLowerCase()]: item,
        };
      });
      setTranslationData(prevTranslationData);

      if (instanceData?.collection?.media) {
        setFeaturedAsset([instanceData?.collection?.media]);
      } else {
        setFeaturedAsset([]);
      }

      setFields({
        menus: instanceData?.collection?.menus,
      });
    }
  }, [instanceData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      featuredAsset: featuredAsset,
      translationData: translationData,
      id: instanceState.id,
      menus: fields.menus,
    },
    validationSchema: editCollectionSchema,
    onSubmit: async (values) => {
      dispatch(handleEditCollection(values, () => {}));
    },
  });


  return (
    <PageModal
      pageName={t('Edit Collection')}
      pagePrev={`/collections`}
      loading={loadingInstance}
    >
      <div
        className={`${
          instanceState?.existe ? 'opacity-100' : 'opacity-0'
        } duration-700`}
      >
        <BottomBanner
          btn01='Cancel'
          btn01Action={() => navigate(`/collections`)}
          btn02='Edit Collection'
          btn02Action={() => formik.handleSubmit()}
          loading={data.loadingEdit}
        />
      </div>

      {instanceData && !errorInstance ? (
        <form
          id='collection-edit-form'
          className=' font-Inter relative mb-[50px] '
        >
          <div className='flex flex-col gap-y-3 py-3'>
            <div>
              <div className='flex gap-5 flex-col md:flex-row  '>
                <div className='w-[100%]  space-y-6 '>
                  <div className=' space-y-2  rounded-lg'>
                    <Tabs availableLanguages={store?.availableLanguages}>
                      {store?.availableLanguages?.map((item, index) => (
                        <div key={index} className='space-y-6 font-urbanist '>
                          <div className='grid grid-cols-1 md:grid-cols-4 gap-10 px-2 sm:px-0'>
                            <div className='space-y-4 col-span-3  '>
                              <div
                                className='font-Inter text-xl pt-7 pb-5
                       uppercase font-bold flex items-center justify-start'
                                style={{
                                  color: bodyStyles.subTitle,
                                }}
                              >
                                <span>{t('Collection informations')}</span>
                              </div>

                              <BasicInput
                                label={t('Title')}
                                placeholder='Home appliance'
                                value={translationData[item.code]?.name}
                                onChange={(e) => {
                                  setTranslationData({
                                    ...translationData,
                                    [item?.code]: {
                                      ...translationData[item?.code],
                                      name: e.target.value,
                                    },
                                  });
                                }}
                                error={
                                  item?.code === store.preferredLanguage &&
                                  formik?.touched?.translationData &&
                                  Boolean(
                                    formik?.errors?.translationData &&
                                      formik?.errors?.translationData[
                                        store.preferredLanguage
                                      ]?.name
                                  )
                                }
                                helperText={
                                  item?.code === store.preferredLanguage &&
                                  formik?.touched?.translationData &&
                                  formik?.errors?.translationData &&
                                  formik?.errors?.translationData[
                                    store.preferredLanguage
                                  ]?.name
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      fontSize='small'
                                      position='end'
                                      sx={{ color: bodyStyles?.inputText }}
                                    >
                                      <span className='text-xs'>
                                        {item?.code?.toUpperCase()}
                                      </span>
                                    </InputAdornment>
                                  ),
                                  style: { fontSize: '12px' },
                                }}
                              />

                              <BasicInput
                                label={t('Description')}
                                placeholder='Tristique viverra risus mauris nunc arcu suspendisse id. Condimentum morbi arcu lectus et sagittis. Sit et, justo tristique vel vestibulum. Sit morbi tristique sed nec scelerisque aliquam convallis quis. Ipsum aenean a libero augue ultrices nam consequat adipiscing leo. Hac vel augue fermentum pharetra, arcu cras. Urna ut nec integer dignissim nibh est, nunc feugiat. Bibendum in elementum leo fringilla venenatis nunc ac. '
                                rows={6}
                                value={translationData[item.code]?.description}
                                onChange={(e) => {
                                  setTranslationData({
                                    ...translationData,
                                    [item?.code]: {
                                      ...translationData[item?.code],
                                      description: e.target.value,
                                    },
                                  });
                                }}
                                error={
                                  item?.code === store.preferredLanguage &&
                                  formik?.touched?.translationData &&
                                  Boolean(
                                    formik?.errors?.translationData &&
                                      formik?.errors?.translationData[
                                        store.preferredLanguage
                                      ]?.description
                                  )
                                }
                                helperText={
                                  item?.code === store.preferredLanguage &&
                                  formik?.touched?.translationData &&
                                  formik?.errors?.translationData &&
                                  formik?.errors?.translationData[
                                    store.preferredLanguage
                                  ]?.description
                                }
                              />

                              {/* <BasicSelecAutoComplete
                                multiple
                                label={t("Menus")}
                                placeholder="Search for Menu"
                                options={
                                  MenusData?.menus?.nodes
                                    ? MenusData?.menus?.nodes
                                    : []
                                }
                                getOptionLabel={(option) =>
                                  defaultDatat(
                                    option?.translationData,
                                    i18n.language,
                                    "name"
                                  )
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option?.id === (value?.id || null)
                                }
                                value={fields?.menus}
                                freeSolo
                                onChange={(event, newValue) => {
                                  setFields({
                                    ...fields,
                                    menus: newValue,
                                  });
                                }}
                                error={
                                  formik.touched.collections &&
                                  Boolean(formik.errors.collections)
                                }
                                helperText={
                                  formik.touched.collections &&
                                  formik.errors.collections
                                }
                              /> */}
                            </div>
                            <div className='space-y-4 col-span-2 md:col-span-1 w-full  '>
                              <div
                                className='font-Inter text-xl pt-8 pb-5
                          uppercase font-bold flex items-center justify-start'
                                style={{
                                  color: bodyStyles.subTitle,
                                }}
                              >
                                <span>{t('Collection image')}</span>
                              </div>

                              <div>
                                <div className='flex gap-1 flex-wrap'>
                                  {(featuredAsset.length > 0 ||
                                    instanceState.loadingUpload) && (
                                    <DragImages
                                      images={featuredAsset.map(
                                        (item, index) => ({
                                          id: `${index}`,
                                          imageUrl: item,
                                        })
                                      )}
                                      setImages={setFeaturedAsset}
                                      handleDeleteImage={(i) =>
                                        setFeaturedAsset(
                                          featuredAsset.filter(
                                            (image, index) => index !== i
                                          )
                                        )
                                      }
                                      loadingUpload={
                                        instanceState.loadingUpload
                                      }
                                      single
                                    />
                                  )}

                                  {((!instanceState.loadingUpload &&
                                    featuredAsset?.length === 0) ||
                                    instanceState.uploadMore) && (
                                    <DragToUpload
                                      setImages={setFeaturedAsset}
                                      images={featuredAsset}
                                      setLoadingUpload={(num) =>
                                        setInstanceState((prev) => {
                                          return {
                                            ...prev,
                                            loadingUpload: num,
                                          };
                                        })
                                      }
                                      loadingUpload={
                                        instanceState.loadingUpload
                                      }
                                      setUploadMore={(status) => {
                                        setInstanceState((prev) => {
                                          return {
                                            ...prev,
                                            uploadMore: status,
                                          };
                                        });
                                      }}
                                      uploadMore={instanceState.uploadMore}
                                      single
                                      button01={t('Add file')}
                                      // text="Accepts CSV files"
                                      // button02={t("add from URL")}
                                    />
                                  )}
                                </div>
                                <span className='text-[#d32f2f] text-sm px-1'>
                                  {formik.errors.featuredAsset}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <ModalNotFound
          message='Item Not Found.'
          returnMessage='Return to Collections page'
          action={() => navigate(`/collections`)}
        />
      )}
    </PageModal>
  );
};

export default EditCollection;
