import React from "react";
const IconPlusText = ({ icon, title, detail }) => {
  return (
    <div className="w-full flex items-center gap-x-8 ">
      <div className="rounded-full w-10 h-10 md:w-20 md:h-20 bg-[#F2F2F2] flex justify-center items-center">
        <p className="scale-[50%] md:scale-[100%] ">{icon}</p>
      </div>
      <div className="space-y-1">
        <p className="font-semibold text-sm md:text-lg ">{title}</p>
        <p className="text-[#888888] text-xs">{detail}</p>
      </div>
    </div>
  );
};
export default IconPlusText;
