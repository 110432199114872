import React from 'react';
import SettingModal from '../../../components/Setting/SettingModal';

const Account = () => {
  return (
    <SettingModal page="Account" />
  );
};

export default Account;
