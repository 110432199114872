import * as Yup from "yup";
import { storeContext } from "../global/store";
import { useContext } from "react";


const useBrandValidation = () => {
  const {store} = useContext(storeContext);

  const addBrandSchema = Yup.object().shape({
    featuredAsset: Yup.array(),
    // .min(1, "You must at least add an image of the product")
    // .required("This Field is required"),
    languages: Yup.object().shape({
      [store.preferredLanguage]: Yup.object().shape({
        name: Yup.string().required("This Field is required"),
        description: Yup.string(),
        // .required("This Field is required"),
      }),
    }),
  });

  const editBrandSchema = Yup.object().shape({
    featuredAsset: Yup.array(),
    // .min(1, "You must at least add an image of the product")
    // .required("This Field is required"),
    translationData: Yup.object().shape({
      [store.preferredLanguage]: Yup.object().shape({
        name: Yup.string().required("This Field is required"),
        description: Yup.string(),
        // .required("This Field is required"),
      }),
    }),
  });

  return {
    addBrandSchema,
    editBrandSchema
  };
};

export { useBrandValidation };
