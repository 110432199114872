import * as Yup from "yup";
import { ref } from "yup";
import i18n from 'i18next';

const discountValidation = () => {

  const addDiscountSchema = Yup.object().shape({
    discountMethod: Yup.string().required(i18n.t("This Field is required")),
    discountCode: Yup.string()
      .min(7, i18n.t("Please enter more than 8 character"))
      .when("discountMethod", {
        is: "discount code",
        then: Yup.string().required(i18n.t("This Field is required")),
      }),
    automaticDiscount: Yup.string()
      .min(2, i18n.t("Please enter more than 3 character"))
      .when("discountMethod", {
        is: "automatic discount",
        then: Yup.string().required(i18n.t("This Field is required")),
      }),

    valueMethod: Yup.string().required(i18n.t("This Field is required")),

    percentage: Yup.string().when("valueMethod", {
      is: "percentage",
      then: Yup.string().required(i18n.t("This Field is required")),
    }),

    fixedAmount: Yup.string().when("valueMethod", {
      is: "fixedAmount",
      then: Yup.string().required(i18n.t("This Field is required")),
    }),

    //MAXIMUM DISCOUNT USES
    limitNumberUses: Yup.boolean(),

    numberUses: Yup.string().when("limitNumberUses", {
      is: true,
      then: Yup.string().required(i18n.t("This Field is required")),
    }),

    limitOneUse: Yup.boolean().typeError(i18n.t("Please enter boolean type")),

    //status
    status: Yup.boolean().typeError(i18n.t("Please enter boolean type")),

    //Date
    startDate: Yup.date()
      .typeError(i18n.t("this date in not valid"))
      .required(i18n.t("this date in not valid")),

    endDate: Yup.date()
      .typeError(i18n.t("this date in not valid"))
      .min(ref("startDate"), i18n.t("End Date must be later than start Date"))
      .required(i18n.t("This Field is required")),
  });


  const editDiscountSchema = Yup.object().shape({
    discountMethod: Yup.string().required(i18n.t("This Field is required")),
    discountCode: Yup.string()
      .min(7, i18n.t("Please enter more than 8 character"))
      .when("discountMethod", {
        is: "discount code",
        then: Yup.string().required(i18n.t("This Field is required")),
      }),
    automaticDiscount: Yup.string()
      .min(2, i18n.t("Please enter more than 3 character"))
      .when("discountMethod", {
        is: "automatic discount",
        then: Yup.string().required(i18n.t("This Field is required")),
      }),

    //VALUE

    valueMethod: Yup.string().required(i18n.t("This Field is required")),

    percentage: Yup.string().when("valueMethod", {
      is: "percentage",
      then: Yup.string().required(i18n.t("This Field is required")),
    }),

    fixedAmount: Yup.string().when("valueMethod", {
      is: "fixedAmount",
      then: Yup.string().required(i18n.t("This Field is required")),
    }),

    //MAXIMUM DISCOUNT USES
    limitNumberUses: Yup.boolean(),

    numberUses: Yup.string().when("limitNumberUses", {
      is: true,
      then: Yup.string().required(i18n.t("This Field is required")),
    }),

    limitOneUse: Yup.boolean().typeError(i18n.t("Please enter boolean type")),

    //status
    status: Yup.boolean().typeError(i18n.t("Please enter boolean type")),

    //Date
    startDate: Yup.date()
      .typeError(i18n.t("this date in not valid"))
      // .min(new Date(), `Date must be later than ${new Date()}`)
      .required(i18n.t("this date in not valid")),

      endDate: Yup.date()
      .typeError(i18n.t("this date in not valid"))
      .min(ref("startDate"), i18n.t("End Date must be later than start Date"))
      .required(i18n.t("This Field is required")),
  });

  return {
    addDiscountSchema,
    editDiscountSchema
  };
};

export { discountValidation };
