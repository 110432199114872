import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../contexts/ContextProvider';
// import { useStateContext } from "../contexts/ContextProvider";

const ModalNoDataFound = ({ message, returnMessage, action }) => {
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();

  return (
    <div className='w-full min-h-[60vh] flex justify-center items-center flex-col '>
      <img
        src='/images/no-data.svg'
        alt='Item Not Found'
        className='max-h-[370px] '
      />

      <div className='text-lg font-Inter flex flex-col justify-center items-center gap-2'>
        <p className='font-Inter '>
          {' '}
          {message ? t(message) : ''}{' '}
        </p>
      </div>
    </div>
  );
};

export default ModalNoDataFound;
