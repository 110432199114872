import { configureStore } from '@reduxjs/toolkit';
import menuSlicer from './features/menu/menuSlicer';
import snackbarSlice from './features/snackbar/snackbarSlice';
import orderSlicer from './features/order/orderSlicer';
import checkoutSlicer from './features/checkout/checkoutSlicer';
import productSlicer from './features/product/productSlicer';
import variantSlicer from './features/variant/variantSlicer';
import collectionSlicer from './features/collection/collectionSlicer';
import brandSlicer from './features/brand/brandSlicer';
import tagSlicer from './features/tag/tagSlicer';
import discountSlicer from './features/discount/discountSlicer';
import customerSlicer from './features/customer/customerSlicer';
import addressSlicer from './features/address/addressSlicer';
import paymentSlicer from './features/payment/paymentSlicer';
import userSlicer from './features/user/userSlicer';
import permissionSlicer from './features/permission/permissionSlicer';
import rolleSlicer from './features/rolle/rolleSlicer';
import settingSlicer from './features/setting/settingSlicer';
import shippingMethodeSlicer from './features/shippingMethode/shippingMethodeSlicer';
import zoneSlicer from './features/zone/zoneSlicer';
import countrySlicer from './features/country/countrySlicer';
import categorySlicer from './features/category/categorySlicer';
import pageSlicer from './features/page/pageSlicer';
import serviceSlicer from './features/service/serviceSlicer';
import planningSlicer from './features/planning/planningSlicer';
import referenceSlicer from './features/reference/referenceSlicer';
import contactSlicer from './features/contact/contactSlicer';
import mediaSlicer from './features/media/mediaSlicer';

export default configureStore({
  reducer: {
    menu: menuSlicer,
    snackbar: snackbarSlice,
    order: orderSlicer,
    checkout: checkoutSlicer,
    product: productSlicer,
    variant: variantSlicer,
    collection: collectionSlicer,
    brand : brandSlicer, 
    tag : tagSlicer,
    discount : discountSlicer,
    customer : customerSlicer,
    address : addressSlicer,
    payment : paymentSlicer,
    user : userSlicer,
    permission : permissionSlicer,
    rolle : rolleSlicer,
    setting : settingSlicer,
    shippingMethode : shippingMethodeSlicer,
    zone : zoneSlicer,
    country : countrySlicer,
    category : categorySlicer,
    page : pageSlicer,
    service : serviceSlicer,
    planning : planningSlicer,
    reference : referenceSlicer,
    contact : contactSlicer,
    media : mediaSlicer 
  },
});
