import { constants } from '../constants';
import { client } from '../global/apolloClient';
import {
  CREATE_PAGE,
  DELETE_MANY_PAGE,
  DELETE_PAGE,
  EDIT_PAGE,
} from '../graphql/mutation/pages.mutation';
import { GET_ALL_PAGES, GET_PAGE } from '../graphql/query/pages.query';

import {
  createPlanningFail,
  createPlanningStart,
  createPlanningSuccess,
  deletePlanningFail,
  deletePlanningStart,
  deletePlanningSuccess,
  deletePlanningsFail,
  deletePlanningsStart,
  deletePlanningsSuccess,
  editPlanningFail,
  editPlanningStart,
  editPlanningSuccess,
  fetchPlanningsFail,
  fetchPlanningsStart,
  fetchPlanningsSuccess,
  importFail,
  importStart,
  importSuccess,
  removeAll,
} from '../store/features/planning/planningSlicer';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation, resteCache } from './executeMutation';
import { uploadImage } from './upload';

const token = getTheTokenFromStorage();

export const createPlanning = async (values, accessToken) => {
  return await executeMutation(
    CREATE_PAGE,
    [GET_ALL_PAGES, GET_PAGE],
    values,
    accessToken
  );
};

export const editPlanning = async (values, accessToken) => {
  return await executeMutation(
    EDIT_PAGE,
    [GET_ALL_PAGES],
    values,
    accessToken
  );
};

export const deletePlanning = async (values, accessToken) => {
  return await executeMutation(
    DELETE_PAGE,
    [GET_ALL_PAGES, GET_PAGE],
    values,
    accessToken 
  );
};

export const deleteManyPlanning = async (values, accessToken) => {
  return await executeMutation(
    DELETE_MANY_PAGE,
    [GET_ALL_PAGES, GET_PAGE],
    values,
    accessToken
  );
};

export const fetchPlannings = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchPlanningsStart());
      const { data } = await client.query({
        query: GET_ALL_PAGES,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchPlanningsSuccess(data));
    } catch (error) {
      dispatch(fetchPlanningsFail(error));
    }
  };
};

export const handleDeletePlanning = (id) => {
  return async (dispatch) => {
    dispatch(deletePlanningStart());
    const dataSent = {
      removePlanningId: parseInt(id),
    };
    try {
      let res = await deletePlanning(dataSent, token);
      if (res?.data?.removePlanning) {
        dispatch(deletePlanningSuccess(id));
        dispatch(showSuccessMessage('Planning deleted successfully'));
      } else {
        dispatch(deletePlanningFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deletePlanningFail(error));
    }
  };
};

export const handleDeleteManyPlanning = (ids) => {
  return async (dispatch) => {
    dispatch(deletePlanningsStart());
    const dataSent = {
      ids: ids.map((id) => +id)
    };
    try {
      let res = await deleteManyPlanning(dataSent, token);
      if (res?.data) {
        dispatch(deletePlanningsSuccess(ids));
        dispatch(showSuccessMessage('Plannings deleted successfully'));
        resteCache('pages');
      } else {
        dispatch(deletePlanningsFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deletePlanningsFail(error));
    }
  };
};

export const handleCreatePlanning = (values, callback) => {
  return async (dispatch) => {
    dispatch(createPlanningStart());

    const allImages = values.images?.length
      ? values.images.map(async (image) => {
          if (image.id) {
            return +image?.id;
          } else {
            let res = await uploadImage(image);
            if (res?.data?.id) {
              return +res?.data?.id;
            }
          }
        })
      : [];

    const dataSent = {
      createPageInput: {
        name: values?.name,
        description: values?.description,
        htmlBody: values?.htmlBody,
        type: values?.type,
        categories: values.categories.map(function (obj) {
          return +obj.id;
        }),
        media: await Promise.all(allImages)
          .then((responses) => {
            return responses;
          })
          .then((r) => r.filter((v) => v)),
      },
    };

    try {
      const res = await createPlanning(dataSent, token);
      if (res?.data?.createPage) {
        dispatch(createPlanningSuccess(res?.data?.createPage));
        dispatch(
          showSuccessMessage('this Planning has been successfully created')
        );
        resteCache('pages');
      } else {
        dispatch(createPlanningFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(createPlanningFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleEditPlanning = (values, callback) => {
  return async (dispatch) => {
    dispatch(editPlanningStart());

    const allImages = values.images?.length
      ? values.images.map(async (image) => {
          if (image.id) {
            return +image?.id;
          } else {
            let res = await uploadImage(image);
            if (res?.data?.id) {
              return +res?.data?.id;
            }
          }
        })
      : [];

    const dataSent = {
      updatePageInput: {
        id: +values?.id,
        name: values?.name,
        description: values?.description,
        htmlBody: values?.htmlBody,
        type: values?.type,
        categories: values.categories.map(function (obj) {
          return +obj.id;
        }),
        media: await Promise.all(allImages)
          .then((responses) => {
            return responses;
          })
          .then((r) => r.filter((v) => v)),
      },
    };

    try {
      const res = await editPlanning(dataSent, token);
      if (res?.data?.updatePage) {
        dispatch(editPlanningSuccess(res?.data?.updatePage));
        dispatch(
          showSuccessMessage('this Planning has been successfully updated')
        );
        resteCache('pages');
      } else {
        dispatch(editPlanningFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(editPlanningFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleImportPlannings = (data) => {
  console.log('data send planning ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyPlanning(dataSent, token);
      if (res?.data?.removePlannings) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Plannings Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deletePlanningsFail(error));
      });
    }
  };
};

export const handleExportPlannings = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyPlanning(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllPlannings = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyPlanning(dataSent, token);
    } catch (error) {}
  };
};
