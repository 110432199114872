import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { CircularProgress } from '@mui/material';
import { SaveIcon } from '../../../Svg/MainIcons';
import CustomButton from '../../../Buttons/CustomButton';
import { useTranslation } from 'react-i18next';
import CustomerAccounts from './CustomerAccounts';
import CustomerContactMethod from './CustomerContactMethod';
import CustomerInformation from './CustomerInformation';
import { useDispatch, useSelector } from 'react-redux';
import { handleEditSeetingWithData } from '../../../../services/setting';

const Checkout = () => {
  const settingState = useSelector((state) => state.setting);
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const [loading, setLoading] = useState({
    checkoutSettings: false,
  });

  const { bodyStyles } = useStateContext();

  const [fields, setFields] = useState({
    // useAccounts: true,
    accountsAreOptional: false,
    accountsAreRequired: false,

    phoneNumberOrEmail: true,
    EmailUique: false,

    showLinkToDownloadShopApp: false,

    requiredLastName: true,
    requiredFullName: false,

    includeCompanyName: true,
    includeCompanyNameOptionel: false,

    includeAddress02: true,
    includeAddress02Optionel: false,

    includeShippingAddress: true,
    includeShippingAddressOptionel: false,
  });

  useEffect(() => {
    if (settingState.data?.setting_checkout) {
      setFields((prevState) => ({
        ...prevState,
        // useAccounts: settingState.data?.setting_checkout?.customer_accounts,
        accountsAreOptional: settingState.data?.setting_checkout
          ?.customer_accounts
          ? false
          : true,
        accountsAreRequired: settingState.data?.setting_checkout
          ?.customer_accounts
          ? true
          : false,

        phoneNumberOrEmail: settingState.data?.setting_checkout?.phone_number
          ? true
          : false,
        EmailUique: settingState.data?.setting_checkout?.email ? true : false,

        showLinkToDownloadShopApp: settingState.data?.setting_checkout
          ?.shop_app_link
          ? true
          : false,

        requiredLastName: settingState.data?.setting_checkout?.last_name
          ? true
          : false,
        requiredFullName: settingState.data?.setting_checkout?.last_first
          ? true
          : false,

        includeCompanyName: settingState.data?.setting_checkout?.company_name
          ? true
          : false,
        includeCompanyNameOptionel: settingState.data?.setting_checkout
          ?.company_name
          ? false
          : true,

        includeAddress02: settingState.data?.setting_checkout?.address_line
          ? true
          : false,
        includeAddress02Optionel: settingState.data?.setting_checkout
          ?.address_line
          ? false
          : true,

        includeShippingAddress: settingState.data?.setting_checkout
          ?.shipping_address
          ? true
          : false,
        includeShippingAddressOptionel: settingState.data?.setting_checkout
          ?.shipping_address
          ? false
          : true,
      }));
    }
  }, [settingState.data]);

  const addShippingSetting = Yup.object().shape({
    // useAccounts: Yup.boolean(),
    // deliverabilityRate: Yup.string().required(t("This Field is required")),
    // conversionType: Yup.string().required(t("This Field is required")),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      accountsAreOptional: fields?.accountsAreOptional,
      accountsAreRequired: fields?.accountsAreRequired,

      phoneNumberOrEmail: fields?.phoneNumberOrEmail,
      EmailUique: fields?.EmailUique,

      showLinkToDownloadShopApp: fields?.showLinkToDownloadShopApp,

      requiredLastName: fields?.requiredLastName,
      requiredFullName: fields?.requiredFullName,

      includeCompanyName: fields?.includeCompanyName,
      includeCompanyNameOptionel: fields?.includeCompanyNameOptionel,

      includeAddress02: fields?.includeAddress02,
      includeAddress02Optionel: fields?.includeAddress02Optionel,

      includeShippingAddress: fields?.includeShippingAddress,
      includeShippingAddressOptionel: fields?.includeShippingAddressOptionel,
    },
    validationSchema: addShippingSetting,
    onSubmit: async (values) => {
      addOrUpdateShippingSettings(values);
    },
  });

  const addOrUpdateShippingSettings = async (values) => {
    setLoading({ ...loading, checkoutSettings: true });

    const dataSent = {
      updateSettingInput: {
        setting_checkout: {
          address_line: values.includeAddress02,
          company_name: values.includeCompanyName,
          customer_accounts: values.accountsAreRequired,
          email: values.EmailUique,
          phone_number: values.phoneNumberOrEmail,
          last_first: values.requiredFullName,
          last_name: values.requiredLastName,
          shipping_address: values.includeShippingAddress,
          shop_app_link: values.showLinkToDownloadShopApp,
        },
      },
    };

    dispatch(
      handleEditSeetingWithData(dataSent, (res) => {
        setLoading({ ...loading, checkoutSettings: false });
      })
    );
  };

  return (
    <div className=''>
      <div className='space-y-16 font-urbanist pt-6 md:pt-10 pb-12 md:pb-16 px-6 border-b border-[#D9D9D9]'>
        <CustomerAccounts fields={fields} setFields={setFields} />
      </div>
      <div className='space-y-16 font-urbanist pt-6 md:pt-10 pb-12 md:pb-16 px-6 border-b border-[#D9D9D9]'>
        <CustomerContactMethod fields={fields} setFields={setFields} />
      </div>
      <div className='space-y-16 font-urbanist pt-6 md:pt-10 pb-12 md:pb-16 px-6'>
        <CustomerInformation fields={fields} setFields={setFields} />
      </div>
      <div className='flex items-end justify-end w-full mt-10'>
        <CustomButton
          className=' text-base rounded-lg px-8 py-3 flex flex-row items-center justify-center gap-x-4'
          onClick={() => formik.submitForm()}
        >
          <SaveIcon color={bodyStyles?.filedButtonText} />
          <span style={{ color: bodyStyles?.filedButtonText }}>
            {loading?.checkoutSettings ? (
              <CircularProgress
                style={{
                  width: 15,
                  height: 15,
                  color: bodyStyles?.filedButtonText,
                }}
              />
            ) : (
              t('Save')
            )}
          </span>
        </CustomButton>
      </div>
    </div>
  );
};

export default Checkout;
