import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { delay } from '../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import BottomBanner from '../../components/Actions/BottomBanner';
import PageModal from '../../components/PageModal';
import * as Yup from 'yup';
import CreateReferenceForm from '../../components/Reference/createReference/CreateReferenceForm';
import { handleCreateReference } from '../../services/reference';

const AddReference = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.reference);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [images, setImages] = useState([]);

  const [fields, setFields] = useState({
    company : "",
    customerFullName: '',
    description: '',
    location: '',
    subDescription: '',
  });

  const addInstanceSchema = Yup.object().shape({
    customerFullName: Yup.string().required(t('This Field is required')),
    description: Yup.string(),
    location: Yup.string(),
    subDescription: Yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customerFullName: fields?.customerFullName,
      description: fields?.description,
      location: fields?.location,
      subDescription: fields?.subDescription,
      company: fields?.company,
      images: images,
    },
    validationSchema: addInstanceSchema,
    onSubmit: async (values) => {
      dispatch(
        handleCreateReference(values, (res) => {
          if (res?.data?.createReference?.id) {
            delay(1000).then(async () => {
              navigate(`/references/edit/${res?.data?.createReference?.id}`);
            });
          }
        })
      );
    },
  });

  return (
    <PageModal pageName={t('Add Reference')} pagePrev={`/references`}>
      <BottomBanner
        btn01='Cancel'
        btn01Action={() => navigate(`/references`)}
        btn02='Add Reference'
        btn02Action={() => formik.handleSubmit()}
        loading={data?.loadingCreate}
      />

      <CreateReferenceForm
        fields={fields}
        setFields={setFields}
        formik={formik}
        images={images}
        setImages={setImages}
      />
    </PageModal>
  );
};

export default AddReference;
