import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query Nodes($offset: Int, $limit: Int, $type: UserRolesEnum) {
    users(offset: $offset, limit: $limit, type: $type) {
      nodes {
        assignments {
          role {
            id
            name
            permissions {
              id
              groupName
              name
              description
            }
          }
        }
        customer {
          email
          firstName
          id
          lastName
          phoneNumber
        }
        email
        id
        type
      }
      hasNextPage
      totalCount
    }
  }
`;

export const GET_COUNT_USERS = gql`
  query Query(
    $likeName: likeName
    $options: options
    $where: UserRestrictionsInput
    $period: period
  ) {
    countUsers(
      likeName: $likeName
      options: $options
      where: $where
      period: $period
    )
  }
`;

export const GET_USER = gql`
  query User($userId: String!) {
    user(id: $userId) {
      id
      phoneNumber
      dossiers {
        id
        firstName
        lastName
        phoneNumber
        willaya
        dateOfBirth
        functionEmp
        salary
      }
      isStaff
      isSuperuser
      isActive
      createdAt
    }
  }
`;

export const GET_USER_BY_TOKEN = gql`
query GetUserAccount {
    getUserAccount {
      id
      email
      isVerified
      type
      confirm
      assignments {
        role {
          id
          name
        }
      }
      customer {
        email
        firstName
        id
        lastName
        phoneNumber
      }
    }
  }
`;

// mutation GetUserAccount {
//   getUserAccount {
//     id
//     email
//     isVerified
//     type
//     confirm
//     assignments {
//       role {
//         id
//         name
//       }
//     }
//     customer {
//       email
//       firstName
//       id
//       lastName
//       phoneNumber
//     }
//   }
// }
