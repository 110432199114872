import React from "react";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton } from "@mui/material";

const ErrorModal = ({ open, setOpen, message, modal, init }) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`fixed border-[1px] fill-available 
    border-green  bg-[#fdeded] text-[#5f2120] z-[5] flex items-center 
    justify-center text-green text-lg font-semibold duration-[1s] uppercase p-2 pt-4 rounded-md
    ${i18n.language === "ar" ? "ml-4" : "mr-4"} 
    ${open ? "top-[90px] md:top-[70px]" : "-top-[150px]"}
    ${init ? "h-[50px]" : "h-[100px]"}

    ${
      !init
        ? open
          ? "top-[90px] md:top-[70px]"
          : "-top-[150px]"
        : open
        ? "top-[40px] md:top-[40px]"
        : "-top-[150px]"
    }
    `}
    >
      <div
        onClick={() => setOpen(false)}
        className={`absolute top-[2px]  ${
          i18n.language === "ar" ? "left-[2px]" : "right-[2px]"
        }`}
      >
        <IconButton>
          <ClearIcon className="text-green" />
        </IconButton>
      </div>
      <span className="font-[600] px-2">{t("ERROR")} :</span> {t(message)}
    </div>
  );
};

export default ErrorModal;
