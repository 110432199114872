import React from 'react';
import SettingModal from '../../../components/Setting/SettingModal';

const Checkout = () => {
  return (
    <SettingModal page="Checkout" />
  );
};

export default Checkout;
