import React from 'react';
import { defaultDatat } from '../../../utils/helpers';
import i18n from 'i18next';
import { useStateContext } from '../../../contexts/ContextProvider';

const VariantData = ({ item }) => {
  const { bodyStyles } = useStateContext();

  // console.log('item', item)

  return (
    <div className='flex gap-1'>
      <div className='W-[20%]'>
        <div
          className='flex items-center justify-center p-[1px] 
           h-[45px] w-[45px] '
        >
          <img
            src={
              item?.media && item?.media?.full_url
                ? item?.media?.full_url
                : item?.product?.media[0]?.full_url
                ? item?.product?.media[0]?.full_url
                : '/images/empty-modal.png'
            }
            className=' block rounded-md w-8 object-contain'
          />
        </div>
      </div>
      <div className={`flex justify-center flex-col text-md `}>
        <h2
          className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold
        '
          title='Varint Name'
        >
          {`${defaultDatat(
            item?.product?.translationData,
            i18n.language,
            'name'
          )}
            ${item?.options?.length > 0 ? '(' : ''}
            ${
              item?.options?.length > 0
                ? item?.options?.map((opt) => {
                    return (
                      defaultDatat(
                        opt?.translationData,
                        i18n.language,
                        'name'
                      ) + ' '
                    );
                  })
                : ''
            }
          ${item?.options?.length > 0 ? ')' : ''}
             `}{' '}
        </h2>
        <span
          className='font-Roboto text-main_tex text-sm text-[#5D5D5D] line-clamp-2 '
          style={{
            color: bodyStyles?.subText,
          }}
          title='Product Name'
        >
          {/* {item?.product?.translationData?.find(
            (x) => x.languageCode?.toLowerCase() === i18n.language.toLowerCase()
          )?.name
            ? item?.product?.translationData?.find(
                (x) =>
                  x.languageCode?.toLowerCase() === i18n.language.toLowerCase()
              )?.name
            : item?.product?.translationData?.find(
                (x) => x.languageCode?.toLowerCase() === 'en'
              )?.name} */}
        </span>
      </div>
    </div>
  );
};

export default VariantData;
