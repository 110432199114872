import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation DashboardLogin($input: LoginUserInput!) {
    dashboardLogin(input: $input) {
      refreshToken
      accessToken
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      message
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation GenerateAccessToken($input: RefreshTokenInput!) {
    generateAccessToken(input: $input) {
      access_token
    }
  }
`;

export const CREATE_STAFF = gql`
  mutation CreateAccount($input: CreateCustomerInput!) {
    createAccount(input: $input) {
      email
      id
      isActive
      message
    }
  }
`;

export const UPDATE_STAFF = gql`
  mutation CreateAccount($input: CreateCustomerInput!) {
    createAccount(input: $input) {
      email
      message
    }
  }
`;


