import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingDelete: false,
  loadingDeleteAll: false,
  loadingCreate: false,
  loadingEdit: false,
  loadingImport: false,
  data: [],
  error: null,
  errorFetch: null,
  success: null,
  totalCount: 0,
  selectedOne: null,
  selectedMany: [],
  isSelectedMany: false,
  deleteModal: false,
  deleteAllModal: false,
  createModal: false,
  createAllModal: false,
  editModal: false,
  editAllModal: false,
  exportModal: false,
  exportSelectedModal: false,
  importModal: false,
};

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    // fetchInstance
    fetchTagsStart: (state) => {
      state.loading = true;
    },
    fetchTagsSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload?.tags?.nodes;
      state.totalCount = action.payload?.tags?.totalCount;
      state.errorFetch = false;
    },
    fetchTagsFail: (state, action) => {
      state.loading = false;
      state.data = [];
      state.errorFetch = action.payload;
    },

    // createInstance
    createTagStart: (state) => {
      state.loadingCreate = true;
    },
    createTagSuccess: (state, action) => {
      state.loadingCreate = false;
      state.data.unshift(action.payload);
      state.success = 'Tag created successfully.';
    },
    createTagFail: (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    },

    // editInstance
    editTagStart: (state) => {
      state.loadingEdit = true;
    },
    editTagSuccess: (state, action) => {
      state.loadingEdit = false;
      state.data = state.data.map((tag) =>
        tag.id === action.payload.id ? action.payload : tag
      );
      state.success = 'Tag updated successfully.';
    },
    editTagFail: (state, action) => {
      state.loadingEdit = false;
      state.error = action.payload;
    },

    // delete Instance
    deleteTagStart: (state) => {
      state.loadingDelete = true;
    },
    deleteTagSuccess: (state, action) => {
      state.loadingDelete = false;
      state.data = state.data.filter((tag) => tag.id !== action.payload);
      state.success = 'Tag deleted successfully';
      state.deleteModal = false;
    },
    deleteTagFail: (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
      state.deleteModal = false;
    },

    // delete Many Instances
    deleteTagsStart: (state) => {
      state.loadingDeleteAll = true;
    },
    deleteTagsSuccess: (state, action) => {
      state.loadingDeleteAll = false;
      const deletedIds = action.payload;
      state.data = state.data.filter((tag) => !deletedIds.includes(tag.id));
      state.success = 'Tags deleted successfully';
      state.deleteAllModal = false;
    },
    deleteTagsFail: (state, action) => {
      state.loadingDeleteAll = false;
      state.error = action.payload;
      state.deleteAllModal = false;
    },

    // selectInstance

    selectOne: (state, action) => {
      state.selectedOne = action.payload;
    },

    selectAll: (state, action) => {
      if (state.isSelectedMany) {
        state.selectedMany = [];
        state.isSelectedMany = false;
      } else {
        state?.data?.map((item) => {
          if (!state.selectedMany.includes(item.id)) {
            state.selectedMany = [...state.selectedMany, item.id];
          }
        });
        state.isSelectedMany = true;
      }
    },

    removeAll: (state, action) => {
      state.selectedMany = [];
      state.isSelectedMany = false;
    },

    updateSelected: (state, action) => {
      if (state.selectedMany.includes(action.payload)) {
        state.selectedMany = state.selectedMany.filter((item) => {
          return item !== action.payload;
        });
      } else {
        state.selectedMany = [...state.selectedMany, action.payload];
      }
    },

    // DELETEiNSTANCE

    openModelDelete: (state, action) => {
      state.selectedOne = action.payload;
      state.deleteModal = !state.deleteModal;
    },

    openModelDeleteAll: (state, action) => {
      state.deleteAllModal = !state.deleteAllModal;
    },


    openModelCreate: (state, action) => {
      state.createModal = !state.createModal;
    },

    openModelEdit: (state, action) => {
      state.selectedOne = action.payload;
      state.editModal = !state.editModal;
    },
    // ExportSelected

    openModeleExportAll: (state, action) => {
      state.exportModal = true;
    },

    openModeleExportSelected: (state, action) => {
      state.exportSelectedModal = true;
    },

    // Import

    openModeleImport: (state, action) => {
      state.importModal = !state.importModal;
    },

    importStart: (state) => {
      state.loadingImport = true;
    },
    importSuccess: (state, action) => {
      state.loadingImport = false;
      state.success = 'Tags Imported successfully';
      state.importModal = false;
    },
    importFail: (state, action) => {
      state.loadingImport = false;
      state.error = action.payload;
      state.importModal = false;
    },

    // CLEAR
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  fetchTagsStart,
  fetchTagsSuccess,
  fetchTagsFail,
  createTagStart,
  createTagSuccess,
  createTagFail,
  editTagStart,
  openModelEdit,
  openModelCreate,
  editTagSuccess,
  editTagFail,
  deleteTagStart,
  deleteTagSuccess,
  deleteTagFail,
  clearMessages,
  updateSelected,
  selectAll,
  selectOne,
  openModelDelete,
  openModelDeleteAll,
  openModeleExportAll,
  openModeleExportSelected,
  deleteTagsStart,
  deleteTagsSuccess,
  deleteTagsFail,
  openModeleImport,
  importStart,
  importSuccess,
  importFail,
  removeAll,
} = tagSlice.actions;

export default tagSlice.reducer;
