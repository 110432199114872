import { gql } from "@apollo/client";

export const GET_ALL_SHIPPINGMETHODES = gql`
query ShippingMethods($shippingMethodRelations: ShippingMethodRelationArgs!) {
  shippingMethods(shippingMethodRelations: $shippingMethodRelations) {
    id
    deletedAt
    default_price
    currency_code
    createdAt
    zone {
      id
      currencyCode
      createdAt
      updatedAt
      translationData {
        createdAt
        description
        id
        languageCode
        name
        slug
        updatedAt
      }
      countries {
        code
        continent
        createdAt
        id
        isEnabled
        translationData {
          createdAt
          description
          id
          languageCode
          name
          updatedAt
        }
        updatedAt
      }
    }
    weight_limit
    updatedAt
    translationData {
      id
      description
      createdAt
      languageCode
      name
      rateName
      slug
      updatedAt
    }
  }
}
`;
