import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { defaultDatat } from "../../../utils/helpers";
import ModalDialog from "../../ModalDialog";
import BasicInput from "../../Inputs/BasicInput";
import { Autocomplete, Box  } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getTheTokenFromStorage } from "../../../services/auth";
import { useQuery } from "@apollo/client";
import { GET_ALL_COUNTRIES } from "../../../graphql/query/countries.query";
import { citiesAustriaWittZipCode } from "../../../utils/citiesAustriaWittZipCode";
import { createAddress, handleCreateAddress } from "../../../services/address";
import BasicSelecAutoComplete from "../../Inputs/BasicSelecAutoComplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { openModelCreate } from "../../../store/features/address/addressSlicer";

const AddShippingAdressModal = ({
  user,
  setFields: setPrevFields,
  fields: prevFields,
}) => {
  const { t, i18n } = useTranslation();

  const data = useSelector((state) => state.address);
  const dispatch = useDispatch();

  const {
    loading: loadingCountries,
    error: errorCountries,
    data: countriesData,
    refetch: refetchCustomers,
  } = useQuery(GET_ALL_COUNTRIES, {
    variables: {
      continent: "",
      code: "",
    },
    fetchPolicy: "cache-and-network",
  });

  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    address: "",
    apartment: "",
    postalCode: "",
    city: "",
    country: null,
    phone: "",
    email: "",
    state: null,
  });

  useEffect(() => {
    setFields({
      ...fields,
      country: {
        code: "AT",
        createdAt: "2023-02-13T15:37:22.408Z",
        id: "234",
        isEnabled: true,
        updatedAt: "2023-02-13T15:37:22.408Z",
        translationData: [
          {
            id: "234",
            description: "",
            createdAt: "2023-02-13T15:37:22.408Z",
            slug: "Austria",
            name: "Austria",
            languageCode: "en",
            updatedAt: "2023-02-13T15:37:22.408Z",
            __typename: "CountryData",
          },
        ],
        continent: "EU",
        __typename: "Country",
      },
    });
  }, []);

  const addInstanceSchema = Yup.object().shape({
    firstName: Yup.string().required(t("This Field is required")),
    lastName: Yup.string().required(t("This Field is required")),

    address: Yup.string().required(t("This Field is required")),
    apartment: Yup.string().required(t("This Field is required")),

    country: Yup.object().required(t("This Field is required")),

    phone: Yup.string().required(t("This Field is required")),
    email: Yup.string(),

    state: Yup.object()
      .shape({
        state: Yup.string().required("This Field is required"),
      })
      .nullable()
      .required("This Field is required"),

    city: Yup.string().nullable().required("This Field is required"),

    postalCode: Yup.number()
      .typeError("Must be a number")
      .test("len", "Must be exactly 4 digits", (val) => {
        return val?.toString()?.length === 4;
      })
      .test(
        "test-name",
        "This zip code is not valid for this state",
        function (value) {
          if (
            value &&
            !citiesAustriaWittZipCode
              ?.find((item) => item?.state_code === fields?.state?.state_code)
              ?.zipcode?.some((code) => code?.zipcode === value?.toString())
          ) {
            return false;
          }
          return true;
        }
      )
      .required("This field is required"),
  });

  const addInstanceFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: fields.firstName,
      lastName: fields.lastName,
      address: fields.address,
      apartment: fields.apartment,
      postalCode: fields.postalCode,
      city: fields.city,
      country: fields.country,
      state: fields.state,
      email: fields.email,
      phone: fields.phone,
      user : user
    },
    validationSchema: addInstanceSchema,
    onSubmit: async (values) => {
      dispatch(
        handleCreateAddress(values, (res) => {
          if (res?.data?.createAddress) {
            setPrevFields({
              ...prevFields,
              shippingAddress: res?.data?.createAddress
                ? [res?.data?.createAddress]
                : [],
            });
          }
          dispatch(openModelCreate());
          setFields({
            firstName: "",
            lastName: "",
            address: "",
            apartment: "",
            postalCode: "",
            city: "",
            country: null,
            phone: "",
            email: "",
          });
        })
      );
    },
  });


  return (
    <div>
      <p
        onClick={() => dispatch(openModelCreate())}
        className="cursor-pointer text-[#358DEB] hover:underline"
      >
      
        {`${t("New Shipping Address")} ${
          user?.firstName ? t('for') + ` `+ user?.firstName : ""
        } ?`}
      </p>

      <ModalDialog
        open={data?.createModal}
        setOpen={(state) => dispatch(openModelCreate())}
        title={
          t("Create New Shipping Address") +
          `${user?.firstName ? t('for') + ` `+ user?.firstName : ""}`
        }
        cancelTitle={t("Cancel")}
        cancel={() =>dispatch(openModelCreate())}
        actionTitle={t("Create Shipping Address")}
        action={() => addInstanceFormik.handleSubmit()}
        loadingAction={data.loadingCreate}
      >
        <form
          id="add-discount-modal"
          className="flex gap-5 flex-col md:w-[550px] py-2"
        >
          <div className="flex gap-6 flex-col  items-start ">
            <div className="flex gap-4 flex-col md:flex-row w-full">
              <BasicInput
                label={t("First Name")}
                placeholder={t("Lee")}
                value={fields.firstName}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    firstName: e.target.value,
                  })
                }
                error={
                  addInstanceFormik.touched.firstName &&
                  Boolean(addInstanceFormik.errors.firstName)
                }
                helperText={
                  addInstanceFormik.touched.firstName &&
                  addInstanceFormik.errors.firstName
                }
              />

              <BasicInput
                label={t("Last Name")}
                placeholder={t("Austin")}
                value={fields.lastName}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    lastName: e.target.value,
                  })
                }
                error={
                  addInstanceFormik.touched.lastName &&
                  Boolean(addInstanceFormik.errors.lastName)
                }
                helperText={
                  addInstanceFormik.touched.lastName &&
                  addInstanceFormik.errors.lastName
                }
              />
            </div>

            <BasicInput
              label={t("address")}
              placeholder={t("9-11 Pl. du Colonel Fabien, 75010 Paris, France")}
              value={fields.address}
              onChange={(e) =>
                setFields({
                  ...fields,
                  address: e.target.value,
                })
              }
              error={
                addInstanceFormik.touched.address &&
                Boolean(addInstanceFormik.errors.address)
              }
              helperText={
                addInstanceFormik.touched.address &&
                addInstanceFormik.errors.address
              }
            />

            <BasicInput
              label={t("Apartement")}
              placeholder={t("Apartement, suite, ect")}
              value={fields.apartment}
              onChange={(e) =>
                setFields({
                  ...fields,
                  apartment: e.target.value,
                })
              }
              error={
                addInstanceFormik.touched.apartment &&
                Boolean(addInstanceFormik.errors.apartment)
              }
              helperText={
                addInstanceFormik.touched.apartment &&
                addInstanceFormik.errors.apartment
              }
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                autoComplete: "off",
              }}
            />

            <div className="w-full min-w-[45%]">
              <BasicSelecAutoComplete
                single
                label={t("Country")}
                placeholder={t("Search for Country")}
                options={
                  // countriesWithCode
                  countriesData?.countries ? countriesData?.countries : []
                }
                getOptionLabel={(option) =>
                  defaultDatat(option?.translationData, i18n.language, "name")
                }
                // getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) =>
                  option?.code === (value?.code || null)
                }
                value={fields.country}
                disabled={true}
              
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {defaultDatat(
                      option?.translationData,
                      i18n.language,
                      "name"
                    )}{" "}
                    ({option.code})
                  </Box>
                )}
              />
            </div>
            {/* </div> */}

            <div className="w-full">
              {citiesAustriaWittZipCode ? (
                <Autocomplete
                  fullWidth
                  options={citiesAustriaWittZipCode}
                  getOptionLabel={(option) =>
                    option?.state ? option?.state : ""
                  }
                  onChange={(e, value, reason) => {
                    setFields({ ...fields, state: value, city: null });
                    // setShippingMethod(null);
                  }}
                  value={fields?.state}
                  isOptionEqualToValue={(option, value) => {
                    return option?.state_code === value?.state_code;
                  }}
                  popupIcon={<KeyboardArrowDownIcon sx={{ fontSize: 16 }} />}
                  renderInput={(params) => (
                    <BasicInput
                      {...params}
                      fullWidth
                      label={t("State")}
                      placeholder={"Zustand"}
                      autoComplete="off"
                      error={
                        addInstanceFormik.touched.state &&
                        Boolean(addInstanceFormik.errors.state)
                      }
                      helperText={
                        <span className="w-full text-redAktion font-MyriadPro text-sm">
                          {addInstanceFormik.touched.state &&
                            addInstanceFormik.errors.state}
                        </span>
                      }
                      InputProps={{
                        ...params?.InputProps,
                        autoComplete: "off",
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              ) : (
                <BasicInput
                  fullWidth
                  placeholder={"Zustand"}
                  label={t("State")}
                  size="small"
                  variant="standard"
                  disabled={true}
                  error={
                    addInstanceFormik.touched.state &&
                    Boolean(addInstanceFormik.errors.state)
                  }
                  helperText={
                    <span className="w-full text-redAktion font-MyriadPro text-sm">
                      {addInstanceFormik.touched.state &&
                        addInstanceFormik.errors.state}
                    </span>
                  }
                  autoComplete="off"
                  InputProps={{
                    disableUnderline: true,
                    autoComplete: "off",
                  }}
                />
              )}
            </div>

            <div className="w-full">
              {citiesAustriaWittZipCode?.find(
                (item) => item?.state_code === fields?.state?.state_code
              )?.cities?.length > 0 ? (
                <Autocomplete
                  fullWidth
                  freeSolo
                  options={
                    citiesAustriaWittZipCode?.find(
                      (item) => item?.state_code === fields?.state?.state_code
                    )?.cities
                  }
                  getOptionLabel={(option) => (option ? option : "")}
                  onChange={(e, value, reason) => {
                    setFields({ ...fields, city: value });
                  }}
                  onChangeCapture={(e) => {
                    setFields({ ...fields, city: e.target.value });
                  }}
                  value={fields?.city}
                  isOptionEqualToValue={(option, value) =>
                    option === value ? value : null
                  }
                  popupIcon={<KeyboardArrowDownIcon sx={{ fontSize: 16 }} />}
                  renderInput={(params) => (
                    <BasicInput
                      {...params}
                      label={t("City")}
                      fullWidth
                      placeholder={"STADT"}
                      error={
                        addInstanceFormik.touched.city &&
                        Boolean(addInstanceFormik.errors.city)
                      }
                      helperText={
                        <span className="w-full text-redAktion font-MyriadPro text-sm">
                          {addInstanceFormik.touched.city &&
                            addInstanceFormik.errors.city}
                        </span>
                      }
                    />
                  )}
                />
              ) : (
                <BasicInput
                  fullWidth
                  label={t("City")}
                  placeholder={"STADT"}
                  disabled={true}
                  error={
                    addInstanceFormik.touched.city &&
                    Boolean(addInstanceFormik.errors.city)
                  }
                  helperText={
                    <span className="w-full text-redAktion font-MyriadPro text-sm">
                      {addInstanceFormik.touched.city &&
                        addInstanceFormik.errors.city}
                    </span>
                  }
                />
              )}
            </div>

            <BasicInput
              label={t("Postal Code")}
              placeholder={t("3001")}
              value={fields.postalCode}
              onChange={(e) =>
                setFields({
                  ...fields,
                  postalCode: e.target.value,
                })
              }
              error={
                addInstanceFormik.touched.postalCode &&
                Boolean(addInstanceFormik.errors.postalCode)
              }
              helperText={
                addInstanceFormik.touched.postalCode &&
                addInstanceFormik.errors.postalCode
              }
              autoComplete="off"
              inputProps={{
                autoComplete: "off",
              }}
            />

            <BasicInput
              label={t("Phone Number")}
              placeholder={t("0777964798")}
              value={fields.phone}
              onChange={(e) =>
                setFields({
                  ...fields,
                  phone: e.target.value,
                })
              }
              error={
                addInstanceFormik.touched.phone &&
                Boolean(addInstanceFormik.errors.phone)
              }
              helperText={
                addInstanceFormik.touched.phone &&
                addInstanceFormik.errors.phone
              }
            />
          </div>
        </form>
      </ModalDialog>
    </div>
  );
};
export default AddShippingAdressModal;
