import { gql } from "@apollo/client";

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      id
      firstName
      lastName
      phoneNumber
      updatedAt
      email
      createdAt
    } 
  }
`;

export const EDIT_CUSTOMER = gql`
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      createdAt
      email
      firstName
      id
      lastName
      phoneNumber
      updatedAt
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation RemoveCustomer($removeCustomerId: Int!) {
    removeCustomer(id: $removeCustomerId) {
      id
    }
  }
`;
