import { gql } from "@apollo/client";

export const GET_ALL_VARIANTS = gql`
query Variants($join: VariantRelationsArgs, $where: FilterVariantsArgs, $limit: Int, $offset: Int) {
  variants(join: $join, where: $where, limit: $limit, offset: $offset) {
    nodes {
      id
      defaultOf {
        id
        createdAt
        updatedAt
        deletedAt
        translationData {
          id
          createdAt
          updatedAt
          name
          slug
          description
          languageCode
        }
        options {
          id
          createdAt
          updatedAt
          values {
            translationData {
              id
              createdAt
              updatedAt
              name
              slug
              description
              languageCode
            
            }
            id
          }
          translationData {
            id
            createdAt
            updatedAt
            name
            slug
            description
            languageCode
          }
        }
        isEnabled
        collections {
          id
          createdAt
          updatedAt
          deletedAt
          translationData {
            id
            createdAt
            updatedAt
            name
            slug
            description
            languageCode
          }
          productsCount
        }
        brand {
          id
          createdAt
          updatedAt
          deletedAt
          translationData {
            id
            createdAt
            updatedAt
            name
            slug
            description
            languageCode
          }
        }
        media {
          id
          createdAt
          updatedAt
          deletedAt
          original_name
          file_name
          path
          host
          full_url
          type
          alt
        }
      }
      media {
        alt
        createdAt
        deletedAt
        file_name
        host
        full_url
        id
        original_name
        type
        path
        updatedAt
      }
      product {
        id
        media {
          alt
          createdAt
          deletedAt
          file_name
          host
          full_url
          id
          original_name
          type
          path
          updatedAt
        }
        isEnabled
        deletedAt
        translationData {
          updatedAt
          slug
          name
          languageCode
          id
          description
          createdAt
        }
        collections {
          id
          translationData {
            description
            createdAt
            id
            languageCode
            slug
            name
            updatedAt
          }
        }
        tags {
          id
          translationData {
            createdAt
            description
            id
            languageCode
            name
            slug
            updatedAt
          }
        }
      }
      isEnabled
      deletedAt
      currencyCode
      createdAt
      pricing
      sku
      stockAllocated
      stockOnHand
      updatedAt
      outOfStockThreshold
      options {
        createdAt
        id
        translationData {
          id
          description
          createdAt
          languageCode
          name
          slug
          updatedAt
        }
        group {
          createdAt
          id
          translationData {
            createdAt
            description
            id
            languageCode
            name
            slug
            updatedAt
          }
        }
      }
    }
    totalCount
    hasNextPage
  }
}
`;
