import { constants } from '../constants';
import { client } from '../global/apolloClient';
import { GET_ALL_COUNTRIES } from '../graphql/query/countries.query';
import {
  fetchCountriesFail,
  fetchCountriesStart,
  fetchCountriesSuccess,
} from '../store/features/country/countrySlicer';

export const fetchCountries = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCountriesStart());
      const { data } = await client.query({
        query: GET_ALL_COUNTRIES,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchCountriesSuccess(data));
    } catch (error) {
      dispatch(fetchCountriesFail(error));
    }
  };
};
