import { constants } from '../constants';
import { client } from '../global/apolloClient';
import {
  CREATE_SHIPPING_METHODE,
  DELETE_SHIPPING_METHODE,
  EDIT_SHIPPING_METHODE,
} from '../graphql/mutation/shippingMethod.mutation';
import { GET_ALL_SHIPPINGMETHODES } from '../graphql/query/shippingMethods.query';
import {
  createShippingMethodeFail,
  createShippingMethodeStart,
  createShippingMethodeSuccess,
  deleteShippingMethodeFail,
  deleteShippingMethodeStart,
  deleteShippingMethodeSuccess,
  deleteShippingMethodesFail,
  deleteShippingMethodesStart,
  deleteShippingMethodesSuccess,
  editShippingMethodeFail,
  editShippingMethodeStart,
  editShippingMethodeSuccess,
  fetchShippingMethodesFail,
  fetchShippingMethodesStart,
  fetchShippingMethodesSuccess,
  importFail,
  importStart,
  importSuccess,
} from '../store/features/shippingMethode/shippingMethodeSlicer';
import {
  showErrorMessage,
  showErrorSettingMessage,
  showSuccessMessage,
  showSuccessSettingMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation, resteCache } from './executeMutation';

const token = getTheTokenFromStorage();

export const createShippingMethode = async (values, accessToken) => {
  return await executeMutation(
    CREATE_SHIPPING_METHODE,
    [GET_ALL_SHIPPINGMETHODES],
    values,
    accessToken
  );
};

export const updateShippingMethode = async (values, accessToken) => {
  return await executeMutation(
    EDIT_SHIPPING_METHODE,
    [GET_ALL_SHIPPINGMETHODES],
    values,
    accessToken
  );
};

export const deleteShippingMethode = async (values, accessToken) => {
  return await executeMutation(
    DELETE_SHIPPING_METHODE,
    [GET_ALL_SHIPPINGMETHODES],
    values,
    accessToken
  );
};

export const deleteManyShippingMethode = async (values, accessToken) => {
  return await executeMutation(
    DELETE_SHIPPING_METHODE,
    [GET_ALL_SHIPPINGMETHODES],
    values,
    accessToken
  );
};

export const fetchShippingMethodes = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchShippingMethodesStart());
      const { data } = await client.query({
        query: GET_ALL_SHIPPINGMETHODES,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchShippingMethodesSuccess(data));
    } catch (error) {
      dispatch(fetchShippingMethodesFail(error));
    }
  };
};

export const handleDeleteShippingMethode = (id) => {
  return async (dispatch) => {
    dispatch(deleteShippingMethodeStart());
    const dataSent = {
      removeShippingMethodId: parseInt(id),
    };
    try {
      let res = await deleteShippingMethode(dataSent, token);
      if (res?.data?.removeShippingMethod) {
        dispatch(deleteShippingMethodeSuccess(id));
        dispatch(
          showSuccessSettingMessage('ShippingMethode deleted successfully')
        );
        resteCache('shippingMethods');
      } else {
        dispatch(deleteShippingMethodeFail(res));
        dispatch(showErrorSettingMessage(res));
      }
    } catch (error) {
      dispatch(showErrorSettingMessage(error));
      dispatch(deleteShippingMethodeFail(error));
    }
  };
};

export const handleDeleteManyShippingMethode = (ids) => {
  return async (dispatch) => {
    dispatch(deleteShippingMethodesStart());
    const dataSent = {
      removeShippingMethodId: ids.map((id) => parseInt(id)),
    };
    try {
      let res = await deleteManyShippingMethode(dataSent, token);
      if (res?.data?.removeShippingMethodes) {
        dispatch(deleteShippingMethodesSuccess(ids));
        dispatch(showSuccessMessage('ShippingMethodes deleted successfully'));
      } else {
        dispatch(deleteShippingMethodesFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteShippingMethodesFail(error));
    }
  };
};

export const handleCreateShippingMethode = (values, callback) => {
  return async (dispatch) => {
    dispatch(createShippingMethodeStart());

    let translationDataSent = values.translationData
      ? Object.keys(values.translationData).reduce((result, key) => {
          if (key) {
            result.push({
              name: values.translationData[key].name,
              description: values.translationData[key].description,
              rateName: values.translationData[key].rateName,
              languageCode: key,
            });
          }
          return result;
        }, [])
      : [];
    const dataSent = {
      input: {
        translationData: translationDataSent,
        zone: values?.zones[0]?.id ? +values?.zones[0]?.id : null,
        currency_code: values?.store,
        default_price: values.price,
      },
    };

    try {
      let res = await createShippingMethode(dataSent, token);
      if (res?.data?.createShippingMethod) {
        dispatch(
          createShippingMethodeSuccess({
            ...res?.data?.createShippingMethod,
            zone: values?.zones[0],
          })
        );
        dispatch(
          showSuccessSettingMessage(
            'this ShippingMethode has been successfully created'
          )
        );
        resteCache('shippingMethods');
      } else {
        dispatch(createShippingMethodeFail(res));
        dispatch(showErrorSettingMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(createShippingMethodeFail(error));
      dispatch(showErrorSettingMessage(error));
      callback && callback(error);
    }
  };
};

export const handleEditShippingMethode = (values, callback) => {
  return async (dispatch) => {
    dispatch(editShippingMethodeStart());

    let translationDataSent = values.translationData
      ? Object.keys(values.translationData).reduce((result, key) => {
          if (key) {
            result.push({
              name: values.translationData[key].name,
              description: values.translationData[key].description,
              rateName: values.translationData[key].rateName,
              languageCode: key,
            });
          }
          return result;
        }, [])
      : [];

    const dataSent = {
      input: {
        id: +values?.id,
        zone: values?.zones[0]?.id ? +values?.zones[0]?.id : null,
        currency_code: values?.currencyStore,
        default_price: values.price,
        translationData: translationDataSent,
      },
    };

    try {
      let res = await updateShippingMethode(dataSent, token);
      if (res?.data?.updateShippingMethod) {
        dispatch(
          editShippingMethodeSuccess({
            ...res?.data?.updateShippingMethod,
            zone: values?.zones[0],
          })
        );
        dispatch(
          showSuccessSettingMessage(
            'this ShippingMethode has been successfully updated'
          )
        );
        resteCache('shippingMethods');

      } else {
        dispatch(editShippingMethodeFail(res));
        dispatch(showErrorSettingMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(editShippingMethodeFail(error));
      dispatch(showErrorSettingMessage(error));
      callback && callback(error);
    }
  };
};

export const handleImportShippingMethodes = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyShippingMethode(dataSent, token);
      if (res?.data?.removeShippingMethodes) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('ShippingMethodes Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteShippingMethodesFail(error));
      });
    }
  };
};

export const handleExportShippingMethodes = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyShippingMethode(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllShippingMethodes = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyShippingMethode(dataSent, token);
    } catch (error) {}
  };
};
