import { gql } from '@apollo/client';

export const GET_ALL_CONTACTS = gql`
  query Contacts(
    $offset: Int!
    $limit: Int!
    $where: FilterContactsArgs
    $sortBy: ContactSortBy
  ) {
    contacts(offset: $offset, limit: $limit, where: $where, sortBy: $sortBy) {
      nodes {
        id
        email
        company
        message
        name
        phone
        read
      }
      hasNextPage
      totalCount
    }
  }
`;

export const GET_UNREAD_MESSAGES = gql`
  query Query {
    totalUnreadContacts
  }
`;
