import { gql } from '@apollo/client';

export const GET_ALL_REFERENCES = gql`
  query Nodes($offset: Int!, $limit: Int!, $customerFullName: String!) {
    references(
      offset: $offset
      limit: $limit
      customerFullName: $customerFullName
    ) {
      nodes {
        id
        customerFullName
        description
        location
        media {
          id
          original_name
          full_url
          file_name
          __typename
          alt
          host
          path
        }
        subDescription
      }
      totalCount
      hasNextPage
    }
  }
`;

export const GET_REFERENCE = gql`
  query Reference($referenceId: Int!) {
    reference(id: $referenceId) {
      customerFullName
      description
      id
      location
      media {
        id
        original_name
        full_url
        file_name
        __typename
        alt
        host
        path
      }
      subDescription
      __typename
      company
      createdAt
    }
  }
`;
