import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingDelete: false,
  loadingDeleteAll: false,
  loadingCreate: false,
  loadingEdit: false,
  loadingImport: false,
  data: [],
  error: null,
  errorFetch: null,
  success: null,
  totalCount: 0,
  selectedOne: null,
  selectedMany: [],
  isSelectedMany: false,
  deleteModal: false,
  deleteAllModal: false,
  createModal: false,
  createAllModal: false,
  editModal: false,
  editAllModal: false,
  exportModal: false,
  exportSelectedModal: false,
  importModal: false,
};

export const collectionSlice = createSlice({
  name: 'collection',
  initialState,
  reducers: {
    // fetchInstance
    fetchCollectionsStart: (state) => {
      state.loading = true;
    },
    fetchCollectionsSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload?.collections?.nodes;
      state.totalCount = action.payload?.collections?.totalCount;
      state.errorFetch = false;
    },
    fetchCollectionsFail: (state, action) => {
      state.loading = false;
      state.data = [];
      state.errorFetch = action.payload;
    },

    // createInstance
    createCollectionStart: (state) => {
      state.loadingCreate = true;
    },
    createCollectionSuccess: (state, action) => {
      state.loadingCreate = false;
      state.data.unshift(action.payload);
      state.success = 'Collection created successfully.';
    },
    createCollectionFail: (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    },

    // editInstance
    editCollectionStart: (state) => {
      state.loadingEdit = true;
    },
    editCollectionSuccess: (state, action) => {
      state.loadingEdit = false;
      state.data = state.data.map((collection) =>
        collection.id === action.payload.id ? action.payload : collection
      );
      state.success = 'Collection updated successfully.';
    },
    editCollectionFail: (state, action) => {
      state.loadingEdit = false;
      state.error = action.payload;
    },

    // delete Instance
    deleteCollectionStart: (state) => {
      state.loadingDelete = true;
    },
    deleteCollectionSuccess: (state, action) => {
      state.loadingDelete = false;
      state.data = state.data.filter((collection) => collection.id !== action.payload);
      state.success = 'Collection deleted successfully';
      state.deleteModal = false;
    },
    deleteCollectionFail: (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
      state.deleteModal = false;
    },

    // delete Many Instances
    deleteCollectionsStart: (state) => {
      state.loadingDeleteAll = true;
    },
    deleteCollectionsSuccess: (state, action) => {
      state.loadingDeleteAll = false;
      const deletedIds = action.payload;
      state.data = state.data.filter((collection) => !deletedIds.includes(collection.id));
      state.success = 'Collections deleted successfully';
      state.deleteAllModal = false;
    },
    deleteCollectionsFail: (state, action) => {
      state.loadingDeleteAll = false;
      state.error = action.payload;
      state.deleteAllModal = false;
    },

    // selectInstance

    selectOne: (state, action) => {
      state.selectedOne = action.payload;
    },

    selectAll: (state, action) => {
      if (state.isSelectedMany) {
        state.selectedMany = [];
        state.isSelectedMany = false;
      } else {
        state?.data?.map((item) => {
          if (!state.selectedMany.includes(item.id)) {
            state.selectedMany = [...state.selectedMany, item.id];
          }
        });
        state.isSelectedMany = true;
      }
    },

    removeAll: (state, action) => {
      state.selectedMany = [];
      state.isSelectedMany = false;
    },

    updateSelected: (state, action) => {
      if (state.selectedMany.includes(action.payload)) {
        state.selectedMany = state.selectedMany.filter((item) => {
          return item !== action.payload;
        });
      } else {
        state.selectedMany = [...state.selectedMany, action.payload];
      }
    },

    // DELETEiNSTANCE

    openModelDelete: (state, action) => {
      state.selectedOne = action.payload;
      state.deleteModal = !state.deleteModal;
    },

    openModelDeleteAll: (state, action) => {
      state.deleteAllModal = !state.deleteAllModal;
    },

    // ExportSelected

    openModeleExportAll: (state, action) => {
      state.exportModal = true;
    },

    openModeleExportSelected: (state, action) => {
      state.exportSelectedModal = true;
    },

    // Import

    openModeleImport: (state, action) => {
      state.importModal = !state.importModal;
    },

    importStart: (state) => {
      state.loadingImport = true;
    },
    importSuccess: (state, action) => {
      state.loadingImport = false;
      state.success = 'Collections Imported successfully';
      state.importModal = false;
    },
    importFail: (state, action) => {
      state.loadingImport = false;
      state.error = action.payload;
      state.importModal = false;
    },

    // CLEAR
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  fetchCollectionsStart,
  fetchCollectionsSuccess,
  fetchCollectionsFail,
  createCollectionStart,
  createCollectionSuccess,
  createCollectionFail,
  editCollectionStart,
  editCollectionSuccess,
  editCollectionFail,
  deleteCollectionStart,
  deleteCollectionSuccess,
  deleteCollectionFail,
  clearMessages,
  updateSelected,
  selectAll,
  selectOne,
  openModelDelete,
  openModelDeleteAll,
  openModeleExportAll,
  openModeleExportSelected,
  deleteCollectionsStart,
  deleteCollectionsSuccess,
  deleteCollectionsFail,
  openModeleImport,
  importStart,
  importSuccess,
  importFail,
  removeAll,
} = collectionSlice.actions;

export default collectionSlice.reducer;
