import { gql } from '@apollo/client';

export const CREATE_PAGE = gql`
  mutation CreatePage($createPageInput: CreatePageInput!) {
    createPage(createPageInput: $createPageInput) {
      createdAt
      deletedAt
      description
      htmlBody
      id
      media {
        id
        original_name
        full_url
      }
      type
    }
  }
`;

export const EDIT_PAGE = gql`
  mutation UpdatePage($updatePageInput: UpdatePageInput!) {
    updatePage(updatePageInput: $updatePageInput) {
      id
      createdAt
      updatedAt
      deletedAt
      description
      htmlBody
      type
      media {
        alt
        id
        full_url
      }
    }
  }
`;

export const DELETE_PAGE = gql`
  mutation RemovePage($removePageId: Int!) {
    removePage(id: $removePageId) {
      id
    }
  }
`;

export const DELETE_MANY_PAGE = gql`
  mutation RemovePage($ids: [Int!]!) {
    removePage(ids: $ids)
  }
`;
