import './App.css';
import { useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Analytics, Home } from './pages';
import { useStateContext } from './contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import PrivateRoute from './components/Hoc/PrivateRoute';
import Login from './pages/Login';
import AddProduct from './pages/Products/AddProduct';
import EditProduct from './pages/Products/EditProduct';
import AddCollection from './pages/Collections/AddCollection';
import EditCollection from './pages/Collections/EditCollection';
import AddCustomer from './pages/Customers/AddCustomer';
import EditCustomer from './pages/Customers/EditCustomer';
import Order from './pages/Orders/Order';
import AddOrder from './pages/Orders/AddOrder';
import Live from './pages/Live';
import AddBrand from './pages/Brands/AddBrand';
import EditBrand from './pages/Brands/EditBrand';
import AddMenu from './pages/Menus/AddMenu';
import EditMenu from './pages/Menus/EditMenu';
import AddDiscount from './pages/Discounts/new/AddDiscount';
import EditDiscountNew from './pages/Discounts/edit/EditDiscountNew';
import AbandonedCheckout from './pages/AbandonedCheckout/AbandonedCheckout';
import {
  accountSettings,
  analyticsPermissions,
  brandPermissions,
  checkoutPermissions,
  collectionPermissions,
  customerPermissions,
  discountPermissions,
  emailSetting,
  generalSettings,
  homePermissions,
  menuPermissions,
  onlineSetting,
  orderPermissions,
  paymentSetting,
  productPermissions,
  roleSetting,
  shippingMethodSettings,
  zoneSetting,
} from './utils/permissions';
import { generalData } from './utils/general';
import { pagesData } from './pages_data/pages_data';
import FetchPage from './components/fetchPages/FetchPage';
import Testt from './testt';
import Account from './pages/Setting/Account.js/Account';
import General from './pages/Setting/General/General';
import Roles from './pages/Setting/Roles/Roles';
import Online from './pages/Setting/Online/Online';
import Payment from './pages/Setting/Payment/Payment';
import EmailSetting from './pages/Setting/EmailSetting/EmailSetting';
import ShippingMethode from './pages/Setting/ShippingMethode/ShippingMethode';
import Zone from './pages/Setting/Zone/Zone';
import Checkout from './pages/Setting/Checkout/Checkout';
import { Helmet } from 'react-helmet-async';
import NotFound from './pages/404';
import Pages from './pages/Pages';
import { storeContext } from './global/store';
import AddService from './pages/Service/AddService';
import EditService from './pages/Service/EditService';
import AddPlanning from './pages/Planning/AddPlanning';
import EditPlanning from './pages/Planning/EditPlanning';
import AddReference from './pages/Reference/AddReference';
import EditReference from './pages/Reference/EditReference';
import EditCategory from './pages/Categories/EditCategory';
import AddCategory from './pages/Categories/AddCategory';

const App = () => {
  const { t, i18n } = useTranslation();
  const { store } = useContext(storeContext);

  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    setSideBarColorsStord,
    setBodyStylesStore,
    bodyStyles,
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');

    const currentSideBarColors = JSON.parse(
      localStorage.getItem('sideBarColors')
    );

    const currentBodyStyles = JSON.parse(localStorage.getItem('bodyStyles'));

    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
    if (currentSideBarColors) {
      setSideBarColorsStord(currentSideBarColors);
    }

    if (currentBodyStyles) {
      setBodyStylesStore(currentBodyStyles);
    }
  }, []);

  const settingPages = [
    {
      path: '/settings/general',
      page: <General />,
      permissions: [generalSettings['generalss-settings:list']],
      display: generalData?.pages?.generalSetting,
    },

    {
      path: '/settings/account',
      page: <Account />,
      permissions: [accountSettings['account-settings:list']],
      display: generalData?.pages?.accountSetting,
    },
    {
      path: '/settings/roles',
      page: <Roles />,
      permissions: [roleSetting['role-settings:list']],
      display: generalData?.pages?.roleSetting,
    },
    {
      path: '/settings/online',
      page: <Online />,
      permissions: [onlineSetting['online-settings:list']],
      display: generalData?.pages?.onlineSetting,
    },
    {
      path: '/settings/email-setting',
      page: <EmailSetting />,
      permissions: [emailSetting['email-setting:list']],
      display: generalData?.pages?.emailSetting,
    },
    {
      path: '/settings/payment',
      page: <Payment />,
      permissions: [paymentSetting['payment-settings:list']],
      display: generalData?.pages?.paymentSetting,
    },

    {
      path: '/settings/shipping-methode',
      page: <ShippingMethode />,
      permissions: [emailSetting['shipping-method:list']],
      display: generalData?.pages?.shippingMethodeSetting,
    },
    {
      path: '/settings/zones',
      page: <Zone />,
      permissions: [zoneSetting['zone:list']],
      display: generalData?.pages?.zoneSetting,
    },
    {
      path: '/settings/checkout',
      page: <Checkout />,
      permissions: [checkoutPermissions['checkout:view']],
      display: generalData?.pages?.checkoutSetting,
    },
  ];

  return (
    <div
      style={{
        direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
        background: bodyStyles?.background,
        color: bodyStyles?.text,
      }}
      className={currentMode === 'Dark ' ? 'dark min-h-screen' : 'min-h-screen'}
    >
      <Helmet>
        <title>{store?.dashboardName || generalData.name}</title>
        <link
          rel='icon'
          type='image/png'
          href={store?.logo || generalData.logo}
        />
      </Helmet>
      <BrowserRouter>
        <Routes>
          {/* Main Pages */}

          {Object.keys(pagesData).map((key, index) => {
            if (pagesData[key].main.display) {
              return (
                <Route
                  path={pagesData[key].main.path}
                  key={`page=${pagesData[key]?.main?.pageName}-${index}`}
                  element={
                    <PrivateRoute
                      permissions={pagesData[key].main.permissions}
                      key={index}
                    >
                      <FetchPage data={pagesData[key].main} />
                    </PrivateRoute>
                  }
                />
              );
            }
            return null;
          })}

          {/* Setting Pages */}

          {settingPages?.map(
            (item, index) =>
              item?.display && (
                <Route
                  path={item?.path}
                  key={index}
                  element={
                    <PrivateRoute
                      permissions={item?.permissions}
                      skipPermissions
                    >
                      {item?.page}
                    </PrivateRoute>
                  }
                />
              )
          )}

          {/* Custom Pages */}
          <Route
            path='/pages'
            element={
              <PrivateRoute permissions={[homePermissions['home:list']]}>
                <Pages />
              </PrivateRoute>
            }
          />

          {/* Custom Pages */}
          <Route
            path='/'
            element={
              <PrivateRoute permissions={[homePermissions['home:list']]}>
                <Home />
              </PrivateRoute>
            }
          />

          <Route
            path='/analytics'
            element={
              <PrivateRoute
                permissions={[analyticsPermissions['analytics:list']]}
              >
                <Analytics />
              </PrivateRoute>
            }
          />

          <Route
            path='/live'
            element={
              <PrivateRoute permissions={[homePermissions['home:list']]}>
                <Live />
              </PrivateRoute>
            }
          />

          <Route
            path='/orders/add'
            element={
              <PrivateRoute permissions={[orderPermissions['order:create']]}>
                <AddOrder />
              </PrivateRoute>
            }
          />

          <Route
            path='/orders/order/:id'
            element={
              <PrivateRoute permissions={[orderPermissions['order:view']]}>
                <Order />
              </PrivateRoute>
            }
          />

          {/* Abandoned Checkouts PAGES */}

          <Route
            path='/abandoned-checkouts/:id'
            element={
              <PrivateRoute
                permissions={[checkoutPermissions['checkout:view']]}
              >
                <AbandonedCheckout />
              </PrivateRoute>
            }
          />

          {/* PRODUCT PAGES */}

          <Route
            path='/products/add'
            element={
              <PrivateRoute
                permissions={[productPermissions['product:create']]}
              >
                <AddProduct />
              </PrivateRoute>
            }
          />
          <Route
            path='/products/edit/:id'
            element={
              <PrivateRoute permissions={[productPermissions['product:view']]}>
                <EditProduct />
              </PrivateRoute>
            }
          />

          {/* SERVICE PAGES */}

          <Route
            path='/services/add'
            element={
              <PrivateRoute
                permissions={[productPermissions['product:create']]}
              >
                <AddService />
              </PrivateRoute>
            }
          />
          <Route
            path='/services/edit/:id'
            element={
              <PrivateRoute permissions={[productPermissions['product:view']]}>
                <EditService />
              </PrivateRoute>
            }
          />

          <Route
            path='/planning/add'
            element={
              <PrivateRoute
                permissions={[productPermissions['product:create']]}
              >
                <AddPlanning />
              </PrivateRoute>
            }
          />
          <Route
            path='/planning/edit/:id'
            element={
              <PrivateRoute permissions={[productPermissions['product:view']]}>
                <EditPlanning />
              </PrivateRoute>
            }
          />

          <Route
            path='/references/add'
            element={
              <PrivateRoute
                permissions={[productPermissions['product:create']]}
              >
                <AddReference />
              </PrivateRoute>
            }
          />
          <Route
            path='/references/edit/:id'
            element={
              <PrivateRoute permissions={[productPermissions['product:view']]}>
                <EditReference />
              </PrivateRoute>
            }
          />

          <Route
            path='/categories/add'
            element={
              <PrivateRoute
                permissions={[productPermissions['product:create']]}
              >
                <AddCategory />
              </PrivateRoute>
            }
          />
          <Route
            path='/categories/edit/:id'
            element={
              <PrivateRoute permissions={[productPermissions['product:view']]}>
                <EditCategory />
              </PrivateRoute>
            }
          />

          {/*MENUS PAGES */}

          <Route
            path='/menus/add'
            element={
              <PrivateRoute permissions={[menuPermissions['menu:create']]}>
                <AddMenu />
              </PrivateRoute>
            }
          />

          <Route
            path='/menus/edit/:id'
            element={
              <PrivateRoute permissions={[menuPermissions['menu:view']]}>
                <EditMenu />
              </PrivateRoute>
            }
          />

          {/*COLLECTIONS PAGES */}

          <Route
            path='/collections/add'
            element={
              <PrivateRoute
                permissions={[collectionPermissions['collection:create']]}
              >
                <AddCollection />
              </PrivateRoute>
            }
          />

          <Route
            path='/collections/edit/:id'
            element={
              <PrivateRoute
                permissions={[collectionPermissions['collection:view']]}
              >
                <EditCollection />
              </PrivateRoute>
            }
          />

          {/*BRAND PAGES */}

          <Route
            path='/brands/add'
            element={
              <PrivateRoute permissions={[brandPermissions['brand:create']]}>
                <AddBrand />
              </PrivateRoute>
            }
          />

          <Route
            path='/brands/edit/:id'
            element={
              <PrivateRoute permissions={[brandPermissions['brand:edit']]}>
                <EditBrand />
              </PrivateRoute>
            }
          />

          {/*DISCOUNTS PAGES */}

          <Route
            path='/discounts/add'
            element={
              <PrivateRoute
                permissions={[discountPermissions['discount:create']]}
              >
                <AddDiscount />
              </PrivateRoute>
            }
          />

          <Route
            path='/discounts/edit/:id'
            element={
              <PrivateRoute
                permissions={[discountPermissions['discount:view']]}
              >
                <EditDiscountNew />
              </PrivateRoute>
            }
          />

          {/*CUSTOMERS PAGES */}

          <Route
            path='/customers/add'
            element={
              <PrivateRoute
                permissions={[customerPermissions['customer:create']]}
              >
                <AddCustomer />
              </PrivateRoute>
            }
          />
          <Route
            path='/customers/edit/:id'
            element={
              <PrivateRoute
                permissions={[customerPermissions['customer:view']]}
              >
                <EditCustomer />
              </PrivateRoute>
            }
          />

          <Route path='/login' element={<Login />} />
          <Route path='/ttttty' element={<Testt />} />

          <Route path='*' element={<NotFound />} />

          {/*REPORTS PAGES */}

          {/* <Route
            path="/reports"
            element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }
          />

          <Route
            path="/reports/:id"
            element={
              <PrivateRoute>
                <Report />
              </PrivateRoute>
            }
          /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
