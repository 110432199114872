import React, { useContext, useState } from 'react';
import { Collapse, Radio, FormControlLabel, Skeleton } from '@mui/material';
import parse from 'html-react-parser';
import { useQuery } from '@apollo/client';
import { GET_ALL_SHIPPINGMETHODES } from '../../../graphql/query/shippingMethods.query';
import { storeContext } from '../../../global/store';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import { defaultDatat } from '../../../utils/helpers';

const ShippingMethodes = ({ city, shippingMethod, setShippingMethod }) => {
  const { store } = useContext(storeContext);
  const { bodyStyles } = useStateContext();
  const {t, i18n} = useTranslation()

  const {
    loading: loadingShippingMethods,
    data,
    error,
  } = useQuery(GET_ALL_SHIPPINGMETHODES, {
    variables: {
      shippingMethodRelations: {
        shippingLines: true,
      },
    },
  });


  return (
    <div className='w-full mx-auto flex flex-col items-start justify-start gap-y-6 relative pb-4'>
      <div
        className={`${
          city
            ? 'hidden'
            : 'absolute w-full h-full bg-[rgba(255,255,255,.5)] z-[7]'
        } `}
      ></div>
      <div className='flex flex-col items-center justify-end gap-y-4 w-full bg-white'>
        {loadingShippingMethods ? (
          <div className='text-sm  mt-4 space-y-2 h-full overflow-auto  w-full '>
            {[...Array(2).keys()]?.map((item, index) => (
              <div
                key={index}
                className={`flex flex-row justify-between items-center py-3 border border-[#e6e6e6] cursor-pointer text-xs sm:text-sm rounded h-[64px]`}
              >
                <div className='flex flex-row items-center justify-start w-full'>
                  <div className='w-[38px] h-[38px] flex items-center justify-center'>
                    <Skeleton
                      variant='rectangular'
                      width={'20px'}
                      height={'20px'}
                      sx={{ borderRadius: '9999px' }}
                    />
                  </div>
                  <Skeleton
                    variant='text'
                    width={'50%'}
                    sx={{ fontSize: '16px' }}
                  />
                </div>

                <div className=' text-black text-[14px] z-[6]  font-medium'>
                  <Skeleton
                    variant='text'
                    width={'15px'}
                    sx={{ fontSize: '16px' }}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : data?.shippingMethods?.length > 0 ? (
          <div className='text-sm mt-4 space-y-2 h-full overflow-auto  w-full px-1 '>
            {city === 'Wien' ? (
              <div>
                <button
                  type='button'
                  disabled={city !== 'Wien'}
                  title={t('Pick up from the store"')}
                  className={`flex flex-row justify-between items-center px-4 py-3 border ${
                    city !== 'Wien' ? 'cursor-default' : 'cursor-pointer'
                  } text-xs sm:text-sm rounded w-full`}
                  style={{
                    color: bodyStyles?.text,
                    borderColor: bodyStyles?.text,
                  }}
                  onClick={() => {
                    setShippingMethod('pick up');
                  }}
                >
                  <FormControlLabel
                    checked={shippingMethod === 'pick up' ? true : false}
                    control={
                      <Radio
                        disabled={city !== 'Wien'}
                        size='small'
                        sx={{
                          '&.Mui-checked': {
                            color: '#232323',
                          },
                        }}
                      />
                    }
                    sx={{
                      span: {
                        fontSize: '14px',
                      },
                    }}
                    label={
                      <span className={`font-MyriadPro `}>
                        {t("Pick up from the store")}
                      </span>
                    }
                  />
                  <div className={`text-[14px] z-[6]  font-medium`}>
                    <span>{t("Free")}</span>
                  </div>
                </button>
                <Collapse in={shippingMethod === 'pick up'}>
                  <div className='bg-light-grey flex flex-col p-4 space-y-1 '>
                    <div className='space-x-2'>
                      <span className='text-primary-color font-semibold '>
                        {t("Shipping costs")} :
                      </span>
                      <span className=' font-MyriadPro'>
                        0 {store?.currencyStore?.symbol}
                      </span>
                    </div>
                    <p className=' font-MyriadPro'> {t("Pick up from the store")} </p>
                  </div>
                </Collapse>
              </div>
            ) : null}
            {data?.shippingMethods?.map((item, index) => (
              <div key={index}>
                <button
                  type='button'
                  className={`flex flex-row justify-between items-center px-4 py-3 border
                  cursor-pointer text-xs sm:text-sm rounded w-full`}
                  style={{
                    color: bodyStyles?.text,
                    borderColor: bodyStyles?.text,
                  }}
                  onClick={() => {
                    setShippingMethod(item?.id);
                  }}
                >
                  <FormControlLabel
                    key={item.id}
                    checked={shippingMethod === item?.id ? true : false}
                    control={
                      <Radio
                        size='small'
                        sx={{
                          '&.Mui-checked': {
                            color: '#232323',
                          },
                        }}
                      />
                    }
                    sx={{
                      span: {
                        fontSize: '14px',
                      },
                    }}
                    label={
                      <span className={`font-MyriadPro `}>
                          {defaultDatat(
                          item?.translationData,
                          i18n.language,
                          "name"
                        )}
                      </span>
                    }
                  />
                  <div
                    className={`  text-[14px]  z-[6]  font-medium
                    }`}
                  >
                    <span>
                      {`${
                        item?.default_price > 0
                          ? store?.currencyStore?.symbol
                          : ''
                      } ${
                        item?.default_price > 0 ? item?.default_price : t("Free")
                      }`}
                    </span>
                  </div>
                </button>
                <Collapse in={shippingMethod === item?.id}>
                  <div className='bg-light-grey flex flex-col p-4 space-y-1 '>
                    <div className='space-x-2'>
                      <span className='font-semibold '>{`${t("Shipping costs")} :`}</span>
                      <span className=' font-MyriadPro'>
                        {store?.currencyStore?.symbol} {item?.default_price}
                      </span>
                    </div>
                    <p className=' font-MyriadPro'>

                      {
                        defaultDatat(
                          item?.translationData,
                          i18n.language,
                          "description"
                        )
                      }
                    </p>
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        ) : (
          <div className='w-full h-80 flex justify-center items-center'>
            <p className='text-2xl font-semibold '>
              {`${t("No shipping methods found")} !`}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShippingMethodes;
