import React, { useContext } from 'react';
import Tabs from '../../TabsLanguages';
import { storeContext } from '../../../global/store';
import BasicInput from '../../Inputs/BasicInput';
import { FormControl } from '@mui/material';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import BasicSelectMui from '../../Inputs/BasicSelectMui';

const EditCategoryForm = ({ fields, setFields, formik }) => {
  const { store } = useContext(storeContext);
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();


  return (
    <form id='add-product-form' className=' font-Inter relative mb-[50px] '>
      <div className='flex flex-col gap-y-3 py-3'>
        <div>
          <div className='flex gap-5 flex-col md:flex-row  '>
            <div className='w-[100%]  space-y-6 '>
              <div className=' space-y-2  rounded-lg'>
                <Tabs availableLanguages={store.availableLanguages}>
                  {store.availableLanguages?.map((item, indexLanguages) => (
                    <div
                      key={indexLanguages}
                      className='space-y-6 font-urbanist '
                    >
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-10 px-2 sm:px-0'>
                        <div className='space-y-4 col-span-2  '>
                          <div
                            className='font-Inter text-xl pt-7 pb-5 uppercase font-bold flex items-center justify-start'
                            style={{
                              color: bodyStyles.subTitle,
                            }}
                          >
                            <span>{t('Category informations')}</span>
                          </div>
                          <div className='flex flex-col  gap-6 '>
                            <BasicInput
                              label={t('Category Name')}
                              placeholder={t('best products')}
                              value={fields?.name}
                              onChange={(e) => {
                                setFields((prevState) => ({
                                  ...prevState,
                                  name: e.target.value,
                                }));
                              }}
                              error={
                                formik?.touched?.name &&
                                Boolean(
                                  formik?.errors?.name 
                                )
                              }
                              helperText={
                                formik?.touched?.name &&
                                formik?.errors?.name
                              }

                            />

                            <BasicInput
                              label={t('Description')}
                              placeholder='Tristique viverra risus mauris nunc arcu suspendisse id. Condimentum morbi arcu lectus et sagittis. Sit et, justo tristique vel vestibulum. Sit morbi tristique sed nec scelerisque aliquam convallis quis. Ipsum aenean a libero augue ultrices nam consequat adipiscing leo. Hac vel augue fermentum pharetra, arcu cras. Urna ut nec integer dignissim nibh est, nunc feugiat. Bibendum in elementum leo fringilla venenatis nunc ac. '
                              rows={4}
                              value={
                                fields?.description
                              }
                              onChange={(e) => {
                                setFields((prevState) => ({
                                  ...prevState,
                                  description: e.target.value,
                                }));
                              }}
                              error={
                                formik?.touched?.description &&
                                Boolean(
                                  formik?.errors?.description 
                                )
                              }
                              helperText={
                                formik?.touched?.description &&
                                formik?.errors?.description
                              }
                            />
                          </div>
                        </div>

                        <div className='space-y-4 col-span-2  md:col-span-1 w-full '>
                          <div
                            className='font-Inter text-xl pt-7 pb-5 uppercase font-bold flex items-center justify-start'
                            style={{
                              color: bodyStyles.subTitle,
                            }}
                          >
                            <span>{t('Category Type')}</span>
                          </div>

                          <FormControl fullWidth>
                            <BasicSelectMui
                              type='text'
                              name='isAvailable'
                              id='product-type-select'
                              label={t('Type')}
                              value={formik.values.type}
                              onChange={(e) =>
                                setFields({
                                  ...fields,
                                  type: e.target.value,
                                })
                              }
                              error={
                                formik.touched.type &&
                                Boolean(formik.errors.type)
                              }
                              options={[
                                { value: 'PRODUCT', label: t('PRODUCT')},
                                { value: 'SERVICE', label: t('SERVICE')},
                                { value: 'PLANNING', label: t('PLANNING')},
                              ]}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditCategoryForm;
