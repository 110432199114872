import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { SaveIcon } from '../../../Svg/MainIcons';
import { CircularProgress } from '@mui/material';
import { useStateContext } from '../../../../contexts/ContextProvider';
import CustomButton from '../../../Buttons/CustomButton';
import { useEffect } from 'react';
import { countriesWithCode } from '../../../../utils/countries';
import { useTranslation } from 'react-i18next';
import { handleEditSeetingWithData } from '../../../../services/setting';
import { languages_list } from '../../../../utils/languages';
import BasicInformation from './BasicInformation';
import Address from './Address';
import ContactInformation from './ContactInformation';
import StoreCurrency from './StoreCurrency';
import Language from './Language';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImageOriginal } from '../../../../services/upload';

const General = ({}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const settingState = useSelector((state) => state.setting);

  const [errorMsg, setErrorMsg] = useState('');
  const [openErrorMsg, setOpenErrorMsg] = useState(false);

  const [featuredAsset, setFeaturedAsset] = useState([]);

  const [editbasicInformation, setEditbasicInformation] = useState(false);
  const [loading, setLoading] = useState({
    basicInformation: false,
    addressInformation: false,
    contactInformation: false,
    generalSetting: false,
  });
  const [basicInformation, setBasicInformation] = useState({
    storeName: '',
    legalNameOfCompany: '',
    storeIndustry: '',
  });

  const [generalStoreSetting, setGeneralStoreSetting] = useState({
    currency: 'USD',
  });

  const [editAddressInformation, setEditAddressInformation] = useState(false);
  const [addressInformation, setAddressInformation] = useState({
    country: null,
    address: '',
    apartment: '',
    postalCode: '',
    city: '',
  });

  const [contactInformation, setContactInformation] = useState({
    email: '',
    phone: '',
  });

  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [preferredLanguage, setPreferredLanguage] = useState('');

  useEffect(() => {
    setAvailableLanguages(
      settingState.data?.setting_general?.available_language?.length
        ? settingState.data?.setting_general?.available_language.map((item) => {
            return {
              name: languages_list?.find((l) => l.code == item).name,
              code: languages_list?.find((l) => l.code == item).code,
            };
          })
        : []
    );

    setPreferredLanguage(
      settingState.data?.setting_general?.language_code
        ? settingState.data?.setting_general?.language_code
        : ''
    );

    setGeneralStoreSetting({
      currency: settingState.data?.setting_general?.currency_code
        ? settingState.data?.setting_general?.currency_code
        : '',
    });
  }, [settingState.data]);

  useEffect(() => {
    if (settingState.data?.setting_general) {
      setBasicInformation((prevState) => ({
        ...prevState,
        storeName: settingState.data?.setting_general?.store_name,
        legalNameOfCompany:
          settingState.data?.setting_general?.store_name_legal,
        storeIndustry: settingState.data?.setting_general?.store_industry,
      }));

      setAddressInformation((prevState) => ({
        ...prevState,
        country: countriesWithCode.find(
          (e) => e.name === settingState.data?.setting_general?.country
        )
          ? countriesWithCode.find(
              (e) => e.name === settingState.data?.setting_general?.country
            )
          : null,
        address: settingState.data?.setting_general?.address,
        apartment: settingState.data?.setting_general?.apartment,
        city: settingState.data?.setting_general?.city,
        postalCode: settingState.data?.setting_general?.postal_code,
      }));

      setContactInformation((prevState) => ({
        ...prevState,
        email: settingState.data?.setting_general?.email,
        phone: settingState.data?.setting_general?.phone_number,
      }));

      if (settingState.data?.logo) {
        setFeaturedAsset([settingState.data?.logo]);
      } else {
        setFeaturedAsset([]);
      }
    }
  }, [settingState.data]);

  const [editContactInformation, setEditContactInformation] = useState(false);

  const addBasicInformationSchema = Yup.object().shape({
    storeName: Yup.string().required(t('This Field is required')),
    legalNameOfCompany: Yup.string().required(t('This Field is required')),
    storeIndustry: Yup.string().required(t('This Field is required')),
  });

  const basicInformationSchemaFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      storeName: basicInformation.storeName,
      legalNameOfCompany: basicInformation.legalNameOfCompany,
      storeIndustry: basicInformation.storeIndustry,
      featuredAsset: featuredAsset,
    },
    validationSchema: addBasicInformationSchema,
    onSubmit: async (values) => {
      setLoading({ ...loading, basicInformation: true });

      let imageSent = values.featuredAsset[0]
        ? values.featuredAsset[0].path
          ? await uploadImageOriginal(values.featuredAsset[0])
          : values.featuredAsset[0]
        : null;

      const dataSent = {
        updateSettingInput: {
          logo: imageSent?.data?.full_url
            ? imageSent?.data?.full_url
            : imageSent
            ? imageSent
            : '',
          setting_general: {
            store_name: values.storeName,
            store_name_legal: values.legalNameOfCompany,
            store_industry: values.storeIndustry,
          },
        },
      };

      dispatch(
        handleEditSeetingWithData(dataSent, (res) => {
          setLoading({ ...loading, basicInformation: false });
        })
      );
    },
  });

  const addAddressInformationSchema = Yup.object().shape({
    country: Yup.object().required(t('This Field is required')),
    address: Yup.string().required(t('This Field is required')),
    apartment: Yup.string().required(t('This Field is required')),
    postalCode: Yup.string().required(t('This Field is required')),
    city: Yup.string().required(t('This Field is required')),
  });

  const addAddressInformationSchemaFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      country: addressInformation.country,
      address: addressInformation.address,
      apartment: addressInformation.apartment,
      postalCode: addressInformation.postalCode,
      city: addressInformation.city,
    },
    validationSchema: addAddressInformationSchema,
    onSubmit: async (values) => {
      setLoading({ ...loading, addressInformation: true });

      const dataSent = {
        updateSettingInput: {
          setting_general: {
            country: values.country?.name ? values.country?.name : '',
            address: values.address,
            apartment: values.apartment,
            city: values.city,
            postal_code: values.postalCode,
          },
        },
      };

      dispatch(
        handleEditSeetingWithData(dataSent, (res) => {
          setLoading({ ...loading, addressInformation: false });
        })
      );
    },
  });

  const addContactInformationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('Invalid email'))
      .required(t('This Field is required')),
    phone: Yup.string().required(t('This Field is required')),
  });

  const contactInformationSchemaFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: contactInformation.email,
      phone: contactInformation.phone,
    },
    validationSchema: addContactInformationSchema,
    onSubmit: async (values) => {
      setLoading({ ...loading, contactInformation: true });

      const dataSent = {
        updateSettingInput: {
          setting_general: {
            email: values.email,
            phone_number: values.phone,
          },
        },
      };

      dispatch(
        handleEditSeetingWithData(dataSent, (res) => {
          setLoading({ ...loading, contactInformation: false });
        })
      );
    },
  });

  const saveGeneralSetting = Yup.object().shape({});

  const saveGeneralSettingFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      currency: generalStoreSetting.currency,
      availableLanguages: availableLanguages,
      preferredLanguage: preferredLanguage,
    },
    validationSchema: saveGeneralSetting,
    onSubmit: async (values) => {
      setLoading({ ...loading, generalSetting: true });

      const dataSent = {
        updateSettingInput: {
          setting_general: {
            available_language: values.availableLanguages?.length
              ? values.availableLanguages?.map((item) => {
                  return item.code;
                })
              : [],
            language_code: values.preferredLanguage
              ? values.preferredLanguage
              : values.availableLanguages[0]?.code
              ? values.availableLanguages[0]?.code
              : 'en',
            currency_code: values.currency,
          },
        },
      };

      dispatch(
        handleEditSeetingWithData(dataSent, (res) => {
          setLoading({ ...loading, generalSetting: false });
        })
      );
    },
  });

  const [storeIndustryOptions, setStoreIndustryOptions] = useState([
    'Beauty',
    'Clothing',
    'Development & web services',
    'Electronics',
    'Furniture',
    'Handcrafts',
    'Jewelry',
    'Painting',
    'Photograph',
    'Restaurants',
    'Groceriers',
    'Other food & drink',
    'Sports',
    'Toys',
    'Services',
    'Virtual services',
    'Other',
  ]);
  useEffect(() => {
    if (Object.keys(basicInformationSchemaFormik.errors)?.length > 0) {
      setErrorMsg(`${t('Please fill in all required fields')}`);
      setOpenErrorMsg(true);
    } else {
      setOpenErrorMsg(false);
    }
  }, [basicInformationSchemaFormik.errors]);

  useEffect(() => {
    if (Object.keys(addAddressInformationSchemaFormik.errors)?.length > 0) {
      setErrorMsg(`${t('Please fill in all required fields')}`);
      setOpenErrorMsg(true);
    } else {
      setOpenErrorMsg(false);
    }
  }, [addAddressInformationSchemaFormik.errors]);

  const { bodyStyles } = useStateContext();
  return (
    <div>
      {/* Error Message */}
      <div className={`px-6  ${openErrorMsg ? '' : 'hidden'}`}>
        <div
          className='flex flex-row items-center
         justify-between w-full bg-[#EB5757] px-6 py-6 my-6
          mb-2
         '
        >
          <div className='flex flex-row items-center justify-center gap-x-6'>
            <img
              src='/icons/Info_square.svg'
              alt='Google'
              className=' block cursor-pointer'
            />
            <div className='text-white font-[400] text-[12px]'>
              <span className='font-[600]'>{t('ERROR')}:</span> {errorMsg}
            </div>
          </div>
          <img
            src='/icons/close_white.svg'
            alt='Google'
            className=' block cursor-pointer'
          />
        </div>
      </div>

      <div className='w-full py-4 md:py-8'>
        <BasicInformation
          edit={editbasicInformation}
          setEdit={setEditbasicInformation}
          basicInformation={basicInformation}
          setBasicInformation={setBasicInformation}
          storeIndustryOptions={storeIndustryOptions}
          formik={basicInformationSchemaFormik}
          onSubmit={basicInformationSchemaFormik.handleSubmit}
          loading={loading.basicInformation}
          featuredAsset={featuredAsset}
          setFeaturedAsset={setFeaturedAsset}
          logo={settingState?.data?.logo}
        />
        <Address
          edit={editAddressInformation}
          setEdit={setEditAddressInformation}
          addressInformation={addressInformation}
          setAddressInformation={setAddressInformation}
          formik={addAddressInformationSchemaFormik}
          onSubmit={addAddressInformationSchemaFormik.handleSubmit}
          loading={loading.addressInformation}
        />
        <ContactInformation
          edit={editContactInformation}
          setEdit={setEditContactInformation}
          contactInformation={contactInformation}
          setContactInformation={setContactInformation}
          formik={contactInformationSchemaFormik}
          onSubmit={contactInformationSchemaFormik.handleSubmit}
          loading={loading.contactInformation}
        />
        <StoreCurrency
          generalStoreSetting={generalStoreSetting}
          setGeneralStoreSetting={setGeneralStoreSetting}
          data={settingState?.data}
        />
        <Language
          availableLanguages={availableLanguages}
          setAvailableLanguages={setAvailableLanguages}
          preferredLanguage={preferredLanguage}
          setPreferredLanguage={setPreferredLanguage}
          data={settingState?.data}
        />
      </div>
      <div className='flex items-end justify-end w-full px-4 py-3 md:py-6'>
        <CustomButton
          className=' text-base rounded-lg px-8 py-3 flex flex-row items-center justify-center gap-x-4'
          onClick={saveGeneralSettingFormik.handleSubmit}
        >
          <SaveIcon color={bodyStyles?.filedButtonText} />
          <span style={{ color: bodyStyles?.filedButtonText }}>
            {loading.generalSetting ? (
              <CircularProgress
                style={{
                  width: 15,
                  height: 15,
                  color: bodyStyles?.filedButtonText,
                }}
              />
            ) : (
              t('Save')
            )}
          </span>
        </CustomButton>
      </div>
    </div>
  );
};

export default General;
