import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingDelete: false,
  loadingDeleteAll: false,
  loadingCreate: false,
  loadingEdit: false,
  loadingImport: false,
  data: [],
  error: null,
  errorFetch: null,
  success: null,
  totalCount: 0,
  selectedOne: null,
  selectedMany: [],
  isSelectedMany: false,
  deleteModal: false,
  deleteAllModal: false,
  createModal: false,
  createAllModal: false,
  editModal: false,
  editAllModal: false,
  exportModal: false,
  exportSelectedModal: false,
  importModal: false,
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    // fetchInstance
    fetchMenusStart: (state) => {
      state.loading = true;
    },
    fetchMenusSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload?.menus?.nodes;
      state.totalCount = action.payload?.menus?.totalCount;
      state.errorFetch = false;
    },
    fetchMenusFail: (state, action) => {
      state.loading = false;
      state.data = [];
      state.errorFetch = action.payload;
    },

    // createInstance
    createMenuStart: (state) => {
      state.loadingCreate = true;
    },
    createMenuSuccess: (state, action) => {
      state.loadingCreate = false;
      state.data.unshift(action.payload);
      state.success = 'Menu created successfully.';
    },
    createMenuFail: (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    },

    // editInstance
    editMenuStart: (state) => {
      state.loadingEdit = true;
    },
    editMenuSuccess: (state, action) => {
      state.loadingEdit = false;
      state.data = state.data.map((menu) =>
        menu.id === action.payload.id ? action.payload : menu
      );
      state.success = 'Menu updated successfully.';
    },
    editMenuFail: (state, action) => {
      state.loadingEdit = false;
      state.error = action.payload;
    },

    // delete Instance
    deleteMenuStart: (state) => {
      state.loadingDelete = true;
    },
    deleteMenuSuccess: (state, action) => {
      state.loadingDelete = false;
      state.data = state.data.filter((menu) => menu.id !== action.payload);
      state.success = 'Menu deleted successfully';
      state.deleteModal = false;
    },
    deleteMenuFail: (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
      state.deleteModal = false;
    },

    // delete Many Instances
    deleteMenusStart: (state) => {
      state.loadingDeleteAll = true;
    },
    deleteMenusSuccess: (state, action) => {
      state.loadingDeleteAll = false;
      const deletedIds = action.payload;
      state.data = state.data.filter((menu) => !deletedIds.includes(menu.id));
      state.success = 'Menus deleted successfully';
      state.deleteAllModal = false;
    },
    deleteMenusFail: (state, action) => {
      state.loadingDeleteAll = false;
      state.error = action.payload;
      state.deleteAllModal = false;
    },

    // selectInstance

    selectOne: (state, action) => {
      state.selectedOne = action.payload;
    },

    selectAll: (state, action) => {
      if (state.isSelectedMany) {
        state.selectedMany = [];
        state.isSelectedMany = false;
      } else {
        state?.data?.map((item) => {
          if (!state.selectedMany.includes(item.id)) {
            state.selectedMany = [...state.selectedMany, item.id];
          }
        });
        state.isSelectedMany = true;
      }
    },

    removeAll: (state, action) => {
      state.selectedMany = [];
      state.isSelectedMany = false;
    },

    updateSelected: (state, action) => {
      if (state.selectedMany.includes(action.payload)) {
        state.selectedMany = state.selectedMany.filter((item) => {
          return item !== action.payload;
        });
      } else {
        state.selectedMany = [...state.selectedMany, action.payload];
      }
    },

    // DELETEiNSTANCE

    openModelDelete: (state, action) => {
      state.selectedOne = action.payload;
      state.deleteModal = !state.deleteModal;
    },

    openModelDeleteAll: (state, action) => {
      state.deleteAllModal = !state.deleteAllModal;
    },

    // ExportSelected

    openModeleExportAll: (state, action) => {
      state.exportModal = true;
    },

    openModeleExportSelected: (state, action) => {
      state.exportSelectedModal = true;
    },

    // Import

    openModeleImport: (state, action) => {
      state.importModal = !state.importModal;
    },

    importStart: (state) => {
      state.loadingImport = true;
    },
    importSuccess: (state, action) => {
      state.loadingImport = false;
      state.success = 'Menus Imported successfully';
      state.importModal = false;
    },
    importFail: (state, action) => {
      state.loadingImport = false;
      state.error = action.payload;
      state.importModal = false;
    },

    // CLEAR
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  fetchMenusStart,
  fetchMenusSuccess,
  fetchMenusFail,
  createMenuStart,
  createMenuSuccess,
  createMenuFail,
  editMenuStart,
  editMenuSuccess,
  editMenuFail,
  deleteMenuStart,
  deleteMenuSuccess,
  deleteMenuFail,
  clearMessages,
  updateSelected,
  selectAll,
  selectOne,
  openModelDelete,
  openModelDeleteAll,
  openModeleExportAll,
  openModeleExportSelected,
  deleteMenusStart,
  deleteMenusSuccess,
  deleteMenusFail,
  openModeleImport,
  importStart,
  importSuccess,
  importFail,
  removeAll,
} = menuSlice.actions;

export default menuSlice.reducer;
