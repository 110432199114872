import { gql } from '@apollo/client';

export const GET_ALL_TAGS = gql`
  query Query(
    $offset: Int
    $limit: Int
    $where: FilterTagsArgs
    $join: TagRelationsArgs
  ) {
    tags(offset: $offset, limit: $limit, where: $where, join: $join) {
      totalCount
      nodes {
        updatedAt
        translationData {
          id
          createdAt
          description
          languageCode
          name
          slug
          updatedAt
        }
        id
        deletedAt
        createdAt
      }
    }
  }
`;

export const GET_TAG = gql`
  query Tag($tagId: Int!) {
    tag(id: $tagId) {
      id
      translationData {
        id
        languageCode
        name
        description
      }
    }
  }
`;
