import React from 'react';
import i18n from 'i18next';
import { defaultDatat } from '../../../utils/helpers';

const ImageWithTitle = ({item}) => {
  return (
    <div className='flex gap-1'>
      <div className='W-[20%]'>
        <div className='flex items-center justify-center   h-[45px] w-[45px] p-[1px] '>
          <img
            src={
              item?.media?.full_url
                ? item?.media?.full_url
                : '/images/empty-modal.png'
            }
            alt='Logo'
            className={`block w-full h-full rounded-md object-contain ${
              item?.media?.full_url
                ? ' p-1 drop-shadow-lg backdrop-sepia bg-white/25'
                : ''
            }`}
          />
        </div>
      </div>
      <div className={`flex justify-center flex-col text-md `}>
        <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold '>
          {defaultDatat(item?.translationData, i18n.language, 'name')}
        </h2>
      </div>
    </div>
  );
};

export default ImageWithTitle;
