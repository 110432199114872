import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_ORDERS } from '../../graphql/query/orders.query';
import { useTranslation } from 'react-i18next';
import TableSection from '../TableSection/TableSection';
import { getCurrencyCharacter } from '../../utils/currency';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { ArrowRightIcon } from '../Svg/MainIcons';
import { GET_ALL_CHECKOUTS } from '../../graphql/query/checkouts.query';

const DataGridCheckouts = ({ idCustomer }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();

  const [search, setSearch] = useState('');

  const [instancesData, setInstancesData] = useState([]);
  const [instancesCount, setInstancesCount] = useState(1);
  const [instanceSelected, setInstanceSelected] = useState(null);

  const [checked, setChecked] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  /* State */
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);

  // const { loading, error, data, refetch } = useQuery(GET_ALL_ORDERS, {
  //   variables: {
  //     where: {
  //       customer: +idCustomer,
  //       status: [],
  //     },
  //     offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
  //     limit: pageSize,
  //   },
  //   fetchPolicy: 'cache-first',
  //   skip: idCustomer ? false : true,
  // });

  const { loading, error, data, refetch } = useQuery(GET_ALL_CHECKOUTS, {
    variables: {
      where: {
        customer: {
          id: +idCustomer,
        },
        paymentStatus: "PENDING",
      },
      offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
      limit: pageSize,
      sortBy: {
        createdAt: "DESC",
      },
    },
    fetchPolicy: "cache-first",
    skip: idCustomer ? false : true,
  });


  useEffect(() => {
    if (data?.orders?.nodes) {
      setInstancesData(data?.orders?.nodes);
    }
  }, [data]);

  useEffect(() => {
    setInstancesCount(data?.orders?.totalCount ? data?.orders?.totalCount : 1);
  }, [data]);

  const tableData = [
    // { title: "new", key: "new", width: "5%" },
    { title: t('Order'), key: 'id', width: '15%' },
    { title: t('Date'), key: 'createdAt', width: '20%', type: 'date' },
    {
      title: t('Customer'),
      key: 'userName',
      width: '20%',
      elemnt: function (item) {
        return (
          <div className='flex items-start justify-start '>
            {item.customer
              ? `${item?.customer?.firstName} ${item?.customer?.lastName}`
              : '-'}
          </div>
        );
      },
    },
    {
      title: t('Status'),
      key: 'status',
      width: '20%',
      elemnt: function (item) {
        return <StatusFiled item={item} />;
      },
    },
    {
      title: t('Total'),
      key: 'Total',
      width: '15%',
      elemnt: function (item) {
        return (
          <div className='flex items-start justify-start '>
            {item?.subTotal?.toFixed(2)}
            {item.subTotal ? (
              <span className='px-1 font-bold'>
                {item.currencyCode
                  ? getCurrencyCharacter(item.currencyCode)
                  : ''}
              </span>
            ) : (
              ''
            )}
          </div>
        );
      },
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      elemnt: function (item) {
        return (
          <ActionFiled
            item={item}
            instanceSelected={instanceSelected}
            setInstanceSelected={setInstanceSelected}
            // setOpenModalDelete={setOpenModalDelete}
          />
        );
      },
    },
  ];

  return (
    <div className='w-full flex items-center justify-center'>
      <div className='flex w-full flex-col items-center justify-center pb-[30px]'>
        <div className='flex w-full flex-col items-center justify-center font-Inter '>
          <TableSection
            deleteInstanceTitle='Delete Staff'
            deleteInstanceActionTitle='Delete Staff'
            deleteInstanceDescription='Are you sure you want to delete this Staff with id'
            deleteAllInstanceTitle='Delete Staff accounts'
            deleteAllInstanceActionTitle='Delete Staff accounts'
            deleteAllInstanceDescription='Are you sure you want to delete Staff accounts with IDS :  '
            loading={loading}
            error={error}
            errorMessage='Somthing went wrong. Please try again'
            errorReturnMessage='Return to Home'
            data={instancesData}
            fildesDis={tableData}
            checkAll={checkAll}
            instanceSelected={instanceSelected}
            setInstanceSelected={setInstanceSelected}
            checkedRef={(item) => item.customer.id}
            checked={checked}
            setChecked={setChecked}
            setCheckAll={setCheckAll}
            search={search}
            dataNotFoundMessage='No Data Found'
            dataNotFoundReturnMessage='Return to Home'
            emptyDataDescription="There are no pending orders associated with this user as of now.
             This could imply that the user is a new customer who hasn't had a chance to place an order yet."
            instancesCount={instancesCount}
            pageSize={pageSize}
           
            setPage={setPage}
            page={page}
            localPagenation
          />
        </div>
      </div>
    </div>
  );
};

export default DataGridCheckouts;


const ActionFiled = ({ item }) => {
  const navigate = useNavigate();

  const { bodyStyles } = useStateContext();
  const { t, i18n } = useTranslation();

  return (
    <div className='flex gap-2 justify-end'>
      <div
        className={`flex cursor-pointer
        ${i18n.language === 'ar' && 'rotate-180 '}
        `}
        onClick={() => navigate(`/orders/order/${item?.id}`)}
      >
        <ArrowRightIcon color={bodyStyles?.text} />
      </div>
    </div>
  );
};

const StatusFiled = ({ item }) => {
  return (
    <>
      {item?.status?.toLowerCase() == 'completed' ? (
        <div
          className='py-1 px-1 max-w-[80px] bg-[#2e7d32] bg-opacity-20 text-[#2e7d32] 
          text-xs text-center  rounded-full font-Roboto '
        >
          {item?.status}
        </div>
      ) : item?.status?.toLowerCase() == 'archived' ? (
        <div
          className='py-1 px-1 max-w-[80px] bg-[#209BBB] bg-opacity-20 text-[#209BBB] 
          text-xs text-center  rounded-full font-Roboto '
        >
          {item?.status}
        </div>
      ) : item?.status?.toLowerCase() == 'pending' ? (
        <div
          className='py-1 px-1 max-w-[80px] bg-[#FF9F10] bg-opacity-20 text-[#FF9F10] 
          text-xs text-center  rounded-full font-Roboto '
        >
          {item?.status}
        </div>
      ) : (
        <div
          className='py-1 px-2 max-w-[80px] bg-[#EB5757] bg-opacity-20 text-[#EB5757] 
      text-xs text-center  rounded-full font-Roboto '
        >
          {item?.status}
        </div>
      )}
    </>
  );
};
