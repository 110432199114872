import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Themes } from '../utils/thems';

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const { t, i18n } = useTranslation();

  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState('#03C9D7');
  const [currentMode, setCurrentMode] = useState('Light');
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [update, setUpdate] = useState(false);
  const [selectedTabSetting, setSelectedTabSetting] = useState('General');

  const [openSettingModel, setOpenSettingModal] = useState(false);

  const [sideBarColors, setSideBarColors] = useState({
    text: '#FFFFFF',
    subText: '#BBBBBB',
    bacground: '#000000',
    font: 'font-Inter',
  });

  const toggleSettingModal = () => {
    setOpenSettingModal(!openSettingModel);
  };

  // const [bodyStyles, setBodyStyles] = useState(defaultBodyStyles);
  const [bodyStyles, setBodyStyles] = useState(Themes[0].styles);

  const resetTheme = () => {
    setBodyStylesStore(Themes[0].styles);
    setBodyStyles(Themes[0].styles);
  };

  const setBodyStylesStore = (styles) => {
    setBodyStyles({ ...bodyStyles, ...styles });
    localStorage.setItem('bodyStyles', JSON.stringify(styles));
    setUpdate(!update);
  };

  const setSideBarColorsStord = (colors) => {
    setUpdate(!update);
    setSideBarColors(colors);
    localStorage.setItem('sideBarColors', JSON.stringify(colors));
  };

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem('themeMode', e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem('colorMode', color);
  };

  const handleClick = (clicked) =>
    setIsClicked({ ...initialState, [clicked]: true });

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider
      value={{
        currentColor,
        currentMode,
        activeMenu,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
        selectedTabSetting,
        setSelectedTabSetting,
        sideBarColors,
        setSideBarColorsStord,
        bodyStyles,
        setBodyStylesStore,
        resetTheme,
        update,

        openSettingModel,
        setOpenSettingModal,
        toggleSettingModal,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
