import { gql } from '@apollo/client';

export const GET_ALL_CATEGORIES = gql`
  query Categories($offset: Int!, $limit: Int!, $name: String!) {
    categories(offset: $offset, limit: $limit, name: $name) {
      nodes {
        id
        deletedAt
        name
        type
        description
      }
      hasNextPage
      totalCount
    } 
  }
`;

export const GET_CATEGORY = gql`
  query Category($categoryId: Int!) {
    category(id: $categoryId) {
      createdAt
      deletedAt
      description
      id
      name
      type
      updatedAt
    }
  }
`;
