import React, { useEffect, useState } from 'react';
import SkeletonTable from '../SkeletonTable';
import ModalFetchError from '../MessageModals/ModalFetchError';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { checkParamsFilterExists } from '../../utils/helpers';
import { ImportContainer } from './Sections/ImportContainer';
import { useDispatch } from 'react-redux';
import { constants } from '../../constants';
import ModalNoDataFound from '../MessageModals/ModalNoDataFound';
import ImportExport from './Sections/ImportExport';
import DeleteMany from './Actions/DeleteMany';
import Import from './Actions/Import';
import DataGrid from './DataGrid';
import Pagination from '../Pagination';
import DeleteOne from './Actions/DeleteOne';
import BasicButton from '../Buttons/BasicButton';
import FiledButton from '../Buttons/FiledButton';
import { useStateContext } from '../../contexts/ContextProvider';

const DataGridContainer = ({
  dataPage,
  data,
  ModalFetchErrorAction,
  ModalFetchErrorMessage,
  search,
  addInstanceAction,
  rows,
  selectAll,
  updateSelected,
  openModelCreate,
  openModelEdit,
  openModelDelete,
  openModelDeleteAll,
  handleDelete,
  handleDeleteMany,
  handleExportFiles,
  openModeleImport,
  handleExportAllFiles,
  handleImportFiles,
  handleUpdateMany,
  page,
  setPage,
  components,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { bodyStyles } = useStateContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState([]);

  useEffect(() => {
    setParams([]);
    for (let entry of searchParams.entries()) {
      setParams((prev) => [...prev, entry]);
    }
  }, [searchParams]);

  const [update, setUpdate] = useState(false);
  // const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(searchParams.get('page'));
  }, [searchParams]);

  // variant test  =====================>

  const [newData, setNewData] = useState(data.data);

  useEffect(() => {
    if (data?.data?.length) {
      setNewData(data.data);
    }
  }, [data.data]);

  const updateNewData = (e, item, filed, type = 'number') => {
    setNewData((prevState) => {
      const newState = prevState.map((obj, chIndex) => {
        if (item.id === obj.id) {
          return {
            ...obj,
            [filed]: type === 'number' ? +e.target.value : e.target.value,
            save: false,
          };
        }
        return obj;
      });
      return newState;
    });
  };

  function findDifference(arr1, arr2, propToExclude) {
    const differenceArray = [];

    arr1.forEach((obj1) => {
      let found = false;

      arr2.forEach((obj2) => {
        if (
          JSON.stringify(
            Object.keys(obj1)
              .filter((key) => key !== propToExclude)
              .reduce((acc, key) => {
                acc[key] = obj1[key];
                return acc;
              }, {})
          ) ===
          JSON.stringify(
            Object.keys(obj2)
              .filter((key) => key !== propToExclude)
              .reduce((acc, key) => {
                acc[key] = obj2[key];
                return acc;
              }, {})
          )
        ) {
          found = true;
        }
      });

      if (!found) {
        differenceArray.push(obj1);
      }
    });

    arr2.forEach((obj1) => {
      let found = false;

      arr1.forEach((obj2) => {
        if (
          JSON.stringify(
            Object.keys(obj1)
              .filter((key) => key !== propToExclude)
              .reduce((acc, key) => {
                acc[key] = obj1[key];
                return acc;
              }, {})
          ) ===
          JSON.stringify(
            Object.keys(obj2)
              .filter((key) => key !== propToExclude)
              .reduce((acc, key) => {
                acc[key] = obj2[key];
                return acc;
              }, {})
          )
        ) {
          found = true;
        }
      });

      if (!found) {
        differenceArray.push(obj1);
      }
    });

    return differenceArray;
  }

  const handleOpenModalDelte = (item) => {
    dispatch(openModelDelete(item));
  };

  const handleOpenModalEdit = (item) => {
    dispatch(openModelEdit(item));
  };

  // openModelCreate

  const renderData = () => {
    if (data.loading) {
      return (
        <div className='w-full'>
          <SkeletonTable />
        </div>
      );
    }

    if (data.errorFetch) {
      return (
        <div className='w-full'>
          <ModalFetchError
            message={dataPage?.error}
            returnMessage={
              dataPage?.ModalFetchErrorMessage
                ? t(dataPage?.ModalFetchErrorMessage)
                : t('Go back')
            }
            action={() =>
              dataPage?.ModalFetchErrorAction
                ? dataPage?.ModalFetchErrorAction()
                : navigate(-1)
            }
            errorStatus={
              data?.errorFetch?.networkError?.statusCode
                ? data?.errorFetch?.networkError?.statusCode
                : data?.errorFetch?.graphQLErrors
                ? data?.errorFetch?.graphQLErrors[0]?.extensions?.response
                    ?.statusCode ||
                  data?.errorFetch?.graphQLErrors[0]?.extensions?.exception
                    ?.status
                : 500
            }
          />
        </div>
      );
    }

    if (
      data?.data?.length === 0 &&
      (!search || !checkParamsFilterExists(params))
    ) {
      return <ModalNoDataFound message={data.emptyDataTitle} />;
    }

    if (
      data?.data?.length === 0 &&
      (search || checkParamsFilterExists(params))
    ) {
      return (
        <ImportContainer
          emptyDataDescription={dataPage?.emptyDataDescription}
          importInstanceMessage={dataPage.importInstanceMessage}
          addInstanceMessage={dataPage.addInstanceMessage}
          importInstanceAction={() => dispatch(openModeleImport())}
          addInstanceAction={() => addInstanceAction()}
        />
      );
    }

    return (
      <div className='flex flex-col items-center justify-center w-full '>
        <div className='w-full overflow-scroll px-1'>
          <DataGrid
            data={data.data}
            fildesDis={rows(
              dataPage,
              updateNewData,
              handleOpenModalDelte,
              handleOpenModalEdit
            )}
            checked={data.selectedMany}
            checkAll={data.isSelectedMany}
            selectAll={() => dispatch(selectAll())}
            updateChecks={(id) => dispatch(updateSelected(id))}
            productSelected={data.selectedOne}
            deletAllSelected={() => dispatch(openModelDeleteAll())}
            exportAllSelected={() =>
              dispatch(handleExportFiles(data.selectedMany))
            }
            deleteManyButton={dataPage?.deleteMany}
            exportManyButton={dataPage.exportMany}
          />
        </div>

        <div className='mt-5 flex w-full justify-center md:mt-10'>
          {data.totalCount && (
            <Pagination
              count={data.totalCount ? data.totalCount : 0}
              pagesize={
                dataPage?.pageSize ? dataPage?.pageSize : constants.PAGE_SIZE
              }
              setPage={(e) => {
                // setUpdate(update);
                if (dataPage.localPagenation) {
                  setPage(e + 1);
                }
              }}
              page={dataPage.localPagenation ? page - 1 : page ? +page - 1 : 0}
              showPages={2}
              localPagenation={dataPage?.localPagenation}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className='w-full pb-4'>
      {/* TODO: reafctor this section in custom component : bottom banner*/}
      <div className='relative w-full'>
        <div
          className={`fixed z-[10]  flex  py-2 px-3  mt-1 border-t justify-end fill-available duration-500
                    ${
                      newData?.length &&
                      data?.data?.length &&
                      findDifference(newData, data.data, 'save').length
                        ? 'bottom-0'
                        : '-bottom-[100px] '
                    }
                     `}
          style={{
            backgroundColor: bodyStyles.background,
            borderColor: bodyStyles?.inputBorder,
            width: '-webkit-fill-available',
          }}
        >
          <div className='flex gap-2 h-[35px] '>
            <BasicButton
              text={dataPage?.updateAllCancelTitle}
              onClick={() => {
                setNewData(data.data);
              }}
              textSmall
            />
            {data.loadingEditMany ? (
              <FiledButton loading />
            ) : (
              <FiledButton
                text={dataPage?.updateAllActionTitle}
                minWidth='95px'
                onClick={() => {
                  dispatch(handleUpdateMany(newData));
                }}
              />
            )}
          </div>
        </div>
      </div>

      {components?.length &&
        components?.map((component, index) => (
          <div key={index}>{component}</div>
        ))}

      <ImportExport
        openImportModal={() => dispatch(openModeleImport())}
        exportData={() => dispatch(handleExportAllFiles())}
        dataPage={dataPage}
      />

      <DeleteOne
        data={data}
        dataPage={dataPage}
        setOpen={() => dispatch(openModelDelete())}
        action={() => dispatch(handleDelete(data?.selectedOne?.id))}
      />

      <DeleteMany
        data={data}
        dataPage={dataPage}
        setOpen={() => dispatch(openModelDeleteAll())}
        action={() => dispatch(handleDeleteMany(data?.selectedMany))}
      />

      <Import
        data={data}
        setOpen={() => dispatch(openModeleImport())}
        action={(file) => dispatch(handleImportFiles(file))}
        dataPage={dataPage}
      />

      {renderData()}
    </div>
  );
};

export default DataGridContainer;
