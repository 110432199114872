import { Checkbox, Tooltip } from "@mui/material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../contexts/ContextProvider";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import CheckBox from "./Inputs/CheckBox";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DataGrid = ({
  data,
  fildesDis,
  checkAll,
  selectAll,
  productSelected,
  setProductSelected,
  setOpenModalDelete,
  checked,
  updateChecks,
  actionFiled,
  deletAllSelected,
  exportAllSelected,
}) => {
  const { bodyStyles } = useStateContext();
  const { t, i18n } = useTranslation();

  return (
    <div className="flex w-full  flex-col m-0 p-0 ">
      {checked?.length > 0 && (
        <div className="flex font-Inter text-xs w-full mb-2 px-5 h-8  ">
          <div
            className="border flex justify-center items-center px-2 h-full"
            style={{
              color: bodyStyles?.text ? bodyStyles?.text : "#fff",
              borderColor: bodyStyles?.subText,
            }}
          >
            {checked?.length} element selected
          </div>
          <div
            className="border flex justify-center items-center px-2 
              cursor-pointer h-full
              "
            style={{
              color: bodyStyles?.text ? bodyStyles?.text : "#fff",
              borderColor: bodyStyles?.subText,
            }}
          >
            <Menu
              as="div"
              className={`relative inline-block  ${
                i18n.language === "ar" ? "text-right" : "text-left"
              }`}
            >
              <div>
                <Menu.Button
                  className="inline-flex justify-center items-center w-full rounded-md 
                        font-medium text-xs focus:outline-none"
                >
                  Actions
                  <ChevronDownIcon
                    className={` h-5 w-5 ${
                      i18n.language === "ar" ? "-ml-1 mr-2" : "-mr-1 ml-2"
                    }`}
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={`origin-top-right absolute right-0 mt-2 w-[130px] z-[999999]
                      rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
                        focus:outline-none
                        
                        ${
                          i18n.language === "ar"
                            ? ""
                            : "transform translate-x-[82px]"
                        }
                        `}
                  style={{
                    backgroundColor: bodyStyles?.dropDownBackground,
                    color: bodyStyles?.dropDowntext,
                    boxShadow: `1px 2px 4px 1px ${bodyStyles?.dropDownShadow}`,
                    border: `1px solid ${bodyStyles?.dropDownBorder}`,
                  }}
                >
                  <div className="py-1">
                    {deletAllSelected && (
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            className={classNames(
                              " px-4 py-2 text-sm flex gap-3 items-center cursor-pointer"
                            )}
                            style={{
                              backgroundColor: active
                                ? bodyStyles.dropDownBackgroundActive
                                : bodyStyles?.dropDownBackground,
                              color: active
                                ? bodyStyles.dropDowntextActive
                                : bodyStyles?.dropDowntext,
                            }}
                            onClick={() => {
                              deletAllSelected();
                            }}
                          >
                            Delete selected
                          </a>
                        )}
                      </Menu.Item>
                    )}
                      {exportAllSelected && (
                          <Menu.Item>

                          {({ active }) => (
                            <a
                              className={classNames(
                                " px-4 py-2 text-sm flex gap-3 items-center cursor-pointer"
                              )}
                              style={{
                                backgroundColor: active
                                  ? bodyStyles.dropDownBackgroundActive
                                  : bodyStyles?.dropDownBackground,
                                color: active
                                  ? bodyStyles.dropDowntextActive
                                  : bodyStyles?.dropDowntext,
                              }}
                              onClick={() => {
                                exportAllSelected();
                              }}
                            >
                              Export selected
                            </a>
                          )}
                        </Menu.Item>
                      )}
                  
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      )}
      <table
        className=" min-w-[400px] w-full  rounded-lg overflow-hidden
        shadow-sm md:shadow-md 
        "
        style={{
          color: bodyStyles?.text,
          borderColor: bodyStyles?.dataGridborder,
          backgroundColor: bodyStyles?.dataGrid,
        }}
      >
        <thead style={{ background: bodyStyles?.dataGridHeader }}>
          <tr
            className="flex flex-row w-full items-center justify-between h-[40px]
                      font-semibold text-sm px-3 sticky top-[89px] "
          >
            {fildesDis?.map((item, index) => (
              <th
                className={`px-1 ${
                  i18n.language == "ar" ? "text-right" : "text-left"
                } `}
                style={{
                  width: item?.width,
                }}
                key={index}
              >
                {item?.key == "check" ? (
                  <CheckBox
                    checked={checkAll ? true : false}
                    onClick={() => selectAll()}
                    // label={overrideLable}
                    color={bodyStyles?.subText}
                  />
                ) : (
                  <span className="min-w-[130px]">{item.title}</span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 &&
            data?.map((item, index) => (
              <Cell
                key={index}
                item={item}
                indexPass={index}
                productSelected={productSelected}
                setProductSelected={setProductSelected}
                setOpenModalDelete={setOpenModalDelete}
                checked={checked}
                updateChecks={updateChecks}
                fildesDis={fildesDis}
                actionFiled={actionFiled}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataGrid;

const Cell = ({
  item,
  productSelected,
  setProductSelected,
  setOpenModalDelete,
  checked,
  updateChecks,
  fildesDis,
  actionFiled: ActionFiled,
  indexPass,
}) => {
  const { bodyStyles } = useStateContext();

  const { t, i18n } = useTranslation();

  const dateFormatter = (date) => {
    var newDate = new Date(date);

    return newDate.toDateString();
  };

  return (
    <tr
      className="flex flex-row w-full items-center justify-between min-h-[40px]
   font-normal text-[#808080] text-xs border-t px-3 relative"
      style={{
        color: bodyStyles?.text,
        borderColor: bodyStyles?.dataGridborder,
      }}
    >

      {item.new && (
        // <div className="absolute top-1 left-1 w-2.5 h-2.5 bg-[#2e7d32] rounded-full"></div>
        <Tooltip
        sx={{}}
        title={
          <span className="text-sm text-bold">
            New
          </span>
        }
      >
      <div className="absolute top-1 left-1 w-2.5 h-2.5 bg-[#2e7d32] rounded-full animate-pulse duration-[5s]"></div>
      </Tooltip>
      )}

      {fildesDis?.map((itemFiled, index) => (
        <td
          className={` px-1 line-clamp-2  text-xs font-semibold break-words 
            ${i18n.language == "ar" ? "text-right" : "text-left"} `}
          style={{
            width: itemFiled?.width,
          }}
          key={index}
        >
          {itemFiled?.elemnt ? (
            itemFiled?.elemnt(item, indexPass)
          ) : itemFiled.key == "check" ? (
            <CheckBox
              checked={checked.includes(item?.id) ? true : false}
              onClick={() => updateChecks(item?.id)}
              // label={overrideLable}
              color={bodyStyles?.subText}
            />
          ) : (
            <span className="min-w-[150px]">
              {!(itemFiled.type === "date")
                ? item?.[itemFiled.key]
                : dateFormatter(item?.[itemFiled.key])}
            </span>
          )}
        </td>
      ))}
    </tr>
  );
};
