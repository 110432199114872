import { constants } from '../constants';
import { client } from '../global/apolloClient';
import { EDIT_SETTING } from '../graphql/mutation/setting.mutation';
import { GET_ALL_SETTING } from '../graphql/query/setting.query';

import {
  createSettingFail,
  createSettingStart,
  createSettingSuccess,
  deleteSettingFail,
  deleteSettingStart,
  deleteSettingSuccess,
  deleteSettingsFail,
  deleteSettingsStart,
  deleteSettingsSuccess,
  editSettingFail,
  editSettingStart,
  editSettingSuccess,
  fetchSettingsFail,
  fetchSettingsStart,
  fetchSettingsSuccess,
  importFail,
  importStart,
  importSuccess,
} from '../store/features/setting/settingSlicer';
import {
  showErrorMessage,
  showErrorSettingMessage,
  showSuccessMessage,
  showSuccessSettingMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import {
  executeMutation,
  resetCacheWithRefresh,
  resteCache,
} from './executeMutation';

const token = getTheTokenFromStorage();

// export const createSetting = async (values, accessToken) => {
//   return await executeMutation(
//     CREATE,
//     [GET_ALL_ROLES],
//     values,
//     accessToken
//   );
// };

export const updateSetting = async (values, accessToken) => {
  return await executeMutation(
    EDIT_SETTING,
    [GET_ALL_SETTING],
    values,
    accessToken
  );
};

// export const deleteSetting = async (values, accessToken) => {
//   return await executeMutation(
//     DELETE_ROLE,
//     [GET_ALL_ROLES],
//     values,
//     accessToken
//   );
// };

// export const deleteManySetting = async (values, accessToken) => {
//   return await executeMutation(
//     DELETE_ROLE,
//     [GET_ALL_ROLES],
//     values,
//     accessToken
//   );
// };

export const fetchSettings = (variables, reset) => {
  return async (dispatch) => {
    try {
      dispatch(fetchSettingsStart());
      const { data } = await client.query({
        query: GET_ALL_SETTING,
        variables,
        fetchPolicy: reset ? 'network-only' : constants?.FETCH_POLICY,
      });
      dispatch(fetchSettingsSuccess(data));
    } catch (error) {
      dispatch(fetchSettingsFail(error));
    }
  };
};

// export const handleDeleteSetting = (id) => {
//   return async (dispatch) => {
//     dispatch(deleteSettingStart());
//     const dataSent = {
//       removeSettingId: parseInt(id),
//     };
//     try {
//       let res = await deleteSetting(dataSent, token);
//       if (res?.data?.removeSetting) {
//         dispatch(deleteSettingSuccess(id));
//         dispatch(showSuccessSettingMessage('Setting deleted successfully'));
//         resteCache('settings');
//       } else {
//         dispatch(deleteSettingFail(res));
//         dispatch(showErrorSettingMessage(res));
//       }
//     } catch (error) {
//       dispatch(showErrorSettingMessage(error));
//       dispatch(deleteSettingFail(error));
//     }
//   };
// };

// export const handleDeleteManySetting = (ids) => {
//   return async (dispatch) => {
//     dispatch(deleteSettingsStart());
//     const dataSent = {
//       removeSettingId: ids.map((id) => parseInt(id)),
//     };
//     try {
//       let res = await deleteManySetting(dataSent, token);
//       if (res?.data?.removeSettings) {
//         dispatch(deleteSettingsSuccess(ids));
//         dispatch(showSuccessMessage('Settings deleted successfully'));
//       } else {
//         dispatch(deleteSettingsFail(res));
//         dispatch(showErrorMessage(res));
//       }
//     } catch (error) {
//       dispatch(showErrorMessage(error));
//       dispatch(deleteSettingsFail(error));
//     }
//   };
// };

// export const handleCreateSetting = (values, callback) => {
//   return async (dispatch) => {
//     dispatch(createSettingStart());

//     let truePermissionsList = [];
//     values.settingsDesc.forEach((permission) => {
//       permission.permissions.forEach((p) => {
//         if (p.status) {
//           truePermissionsList.push(+p.id);
//         }
//       });
//     });

//     const dataSent = {
//       createSettingInput: {
//         name: values.name,
//         description: values.description,
//         permissions: truePermissionsList,
//       },
//     };

//     try {
//       let res = await createSetting(dataSent, token);
//       if (res?.data?.createSetting) {
//         dispatch(createSettingSuccess(res?.data?.createSetting));
//         dispatch(
//           showSuccessSettingMessage('this Setting has been successfully created')
//         );
//         resteCache('settings');
//       } else {
//         dispatch(createSettingFail(res));
//         dispatch(showErrorSettingMessage(res));
//       }
//       callback && callback(res);
//     } catch (error) {
//       dispatch(createSettingFail(error));
//       dispatch(showErrorSettingMessage(error));
//       callback && callback(error);
//     }
//   };
// };

export const handleEditSeetingWithData = (dataSent, callback) => {
  return async (dispatch) => {
    dispatch(editSettingStart());
    try {
      let res = await updateSetting(dataSent, token);
      if (res?.data?.updateSetting) {
        dispatch(editSettingSuccess(res?.data?.updateSetting));
        dispatch(
          showSuccessSettingMessage(
            'this Setting has been successfully updated'
          )
        );
        resteCache('setting');
        dispatch(fetchSettings(null, true));
      } else {
        dispatch(editSettingFail(res));
        dispatch(showErrorSettingMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(editSettingFail(error));
      dispatch(showErrorSettingMessage(error));
      callback && callback(error);
    }
  };
};

export const handleEditSetting = (values, callback) => {
  return async (dispatch) => {
    dispatch(editSettingStart());

    const dataSent = {
      updateSettingInput: {
        // id: +values.id,
        // name: values.name,
        // description: values.description,
        // permissions: truePermissionsList,
      },
    };

    try {
      let res = await updateSetting(dataSent, token);
      if (res?.data?.updateSetting) {
        dispatch(editSettingSuccess(res?.data?.updateSetting));
        dispatch(
          showSuccessSettingMessage(
            'this Setting has been successfully updated'
          )
        );
      } else {
        dispatch(editSettingFail(res));
        dispatch(showErrorSettingMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(editSettingFail(error));
      dispatch(showErrorSettingMessage(error));
      callback && callback(error);
    }
  };
};

// export const handleImportSettings = (data) => {
//   console.log('data send service ==>', data);
//   return async (dispatch) => {
//     dispatch(importStart());
//     const dataSent = {
//       file: 'File',
//     };
//     try {
//       let res = await deleteManySetting(dataSent, token);
//       if (res?.data?.removeSettings) {
//         dispatch(importSuccess());
//         dispatch(showSuccessMessage('Settings Import Seuccefully'));
//       } else {
//         delay(1000).then(async () => {
//           dispatch(importFail(res));
//           dispatch(showErrorMessage(res));
//         });
//       }
//     } catch (error) {
//       delay(1000).then(async () => {
//         dispatch(importFail(error));
//         dispatch(deleteSettingsFail(error));
//       });
//     }
//   };
// };

// export const handleExportSettings = (ids) => {
//   console.log('handleExportFiles :', ids);
//   return async (dispatch) => {
//     // dispatch(importStart());
//     const dataSent = {
//       file: 'File',
//     };
//     try {
//       let res = await deleteManySetting(dataSent, token);
//       // dispatch(removeAll())
//     } catch (error) {
//       // dispatch(removeAll())
//     }
//   };
// };

// export const handleExportAllSettings = () => {
//   console.log('handleExportAllFiles =>');
//   return async (dispatch) => {
//     // dispatch(importStart());
//     const dataSent = {
//       file: 'File',
//     };
//     try {
//       let res = await deleteManySetting(dataSent, token);
//     } catch (error) {}
//   };
// };

// export const updateSetting = async (values, accessToken) => {
//   try {
//     const result = await client.mutate({
//       mutation: EDIT_SETTING,
//       variables: {
//         ...values,
//       },
//       context: { accessToken },
//       refetchQueries: [GET_ALL_SETTING],
//     });

//     return result;
//   } catch (error) {
//     return error.message;
//   }
// };
