import { gql } from "@apollo/client";

export const CREATE_ZONE = gql`
  mutation CreateZone($createZoneInput: CreateZoneInput!) {
    createZone(createZoneInput: $createZoneInput) {
      countries {
        code
        continent
        createdAt
        id
        isEnabled
        updatedAt
      }
      createdAt
      id
      updatedAt
      translationData {
        id
        createdAt
        updatedAt
        name
        slug
        description
        languageCode
      }
    }
  }
`;

export const EDIT_ZONE = gql`
  mutation UpdateZone($updateZoneInput: UpdateZoneInput!) {
    updateZone(updateZoneInput: $updateZoneInput) {
      countries {
        id
        continent
        code
        createdAt
        isEnabled
        translationData {
          id
          createdAt
          updatedAt
          name
          slug
          description
          languageCode
        }
        updatedAt
      }
      id
      updatedAt
      createdAt
      translationData {
        id
        createdAt
        updatedAt
        name
        slug
        description
        languageCode
      }
    }
  }
`;

export const DELETE_ZONE = gql`
  mutation RemoveZone($removeZoneId: Float!) {
    removeZone(id: $removeZoneId)
  }
`;
