import React from 'react';
import ModalDialog from '../../ModalDialog';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { handleSendRecovryEmail } from '../../../services/checkout';
import { useDispatch, useSelector } from 'react-redux';
import { openModelRecoveryEmail } from '../../../store/features/checkout/checkoutSlicer';

const SendRecoveryEmail = ({ id }) => {
  const { t } = useTranslation();

  const data = useSelector((state) => state.checkout);
  const dispatch = useDispatch();

  const addInstanceSchema = Yup.object().shape({});

  const addInstanceFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: id,
    },
    validationSchema: addInstanceSchema,
    onSubmit: async (values) => {
      dispatch(handleSendRecovryEmail(values, (res) => {}));
    },
  });

  return (
    <div>
      <ModalDialog
        open={data?.sendRecoveryEmailModal}
        setOpen={() => dispatch(openModelRecoveryEmail())}
        title={t('Send Recovery Email')}
        cancelTitle={t('Cancel')}
        cancel={() => dispatch(openModelRecoveryEmail())}
        actionTitle={t('confirm')}
        action={() => addInstanceFormik.handleSubmit()}
        loadingAction={data?.loadingSendRecoveryEmail}
      >
        <div
          id='add-discount-modal'
          className='flex gap-5 flex-col md:w-[550px]'
        >
          <p className='font-semibold '>
            {t(
              'Are you sure you want to send a recovery email for this abandoned checkout ?'
            )}
          </p>
        </div>
      </ModalDialog>
    </div>
  );
};

export default SendRecoveryEmail;
