import { gql } from '@apollo/client';

export const GET_ALL_SETTING = gql`
  query Query {
    setting {
      createdAt
      deletedAt
      id
      logo
      setting_account {
        bio
        createdAt
        deletedAt
        email
        id
        last_name
        phone_number
        name
        updatedAt
        website
      }
      setting_checkout {
        address_line
        company_name
        createdAt
        customer_accounts
        deletedAt
        email
        id
        last_first
        last_name
        phone_number
        shipping_address
        updatedAt
        shop_app_link
      }
      setting_general {
        available_language
        currency_code
        language_code
        address
        apartment
        city
        country
        createdAt
        deletedAt
        email
        id
        phone_number
        postal_code
        store_industry
        store_name
        store_name_legal
        updatedAt
      }
      setting_online {
        analytics_script_head
        createdAt
        deletedAt
        fb_access_token
        fb_conversion_type
        fb_deliverability_rate
        fb_pixel
        google_conversion_type
        google_deliverability_rate
        google_track_id
        snapchat_conversion_type
        id
        snapchat_deliverability_rate
        snapchat_pixel
        tiktok_conversion_type
        tiktok_deliverability_rate
        tiktok_pixel
        updatedAt
        measurement_id
      }
      setting_payment {
        createdAt
        deletedAt
        id
        paypal_pk
        stripe_pk
        paypal_sk
        stripe_sk
        updatedAt
      }
      setting_email {
        awsses_key
        awsses_secret
        createdAt
        deletedAt
        from_email
        postmark_key
        sendgrid_key
        updatedAt
        id
      }
      updatedAt
    }
  }
`;
