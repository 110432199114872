import React from "react";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../contexts/ContextProvider";
// import { useStateContext } from "../contexts/ContextProvider";

const ModalNotFound = ({ message, returnMessage, action }) => {
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();


  return (
    <div className="w-full min-h-[70vh] flex justify-center items-center flex-col ">
      <img
        src="/images/item-not-found.svg"
        alt="Item Not Found"
        className="max-h-[400px] "
      />

      <div className="text-lg font-Inter flex flex-col justify-center items-center gap-1">
        <p className="">{t(message)} </p>
        <button
          className="font-bold text-base capitalize
           cursor-pointer py-2 px-5 shadow-lg rounded-md hover:shadow-lg hover:drop-shadow-md duration-300 "
          style={{
            color: "#fff",
            background: bodyStyles.primary,
          }}
          onClick={action}
        >
          {" "}
          {t(returnMessage)}
        </button>
      </div>
    </div>
  );
};

export default ModalNotFound;
