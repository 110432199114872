import React, { useState } from 'react';
import ModalDialog from '../../ModalDialog';
import { initInstanceState } from '../../../utils/initStates';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStateContext } from '../../../contexts/ContextProvider';
import CheckBox from '../../Inputs/CheckBox';
import { useDispatch, useSelector } from 'react-redux';
import { openModelEditShipped, openModelEditShipping } from '../../../store/features/order/orderSlicer';
import { handleEditOrderShippingStatus } from '../../../services/order';

const EditShippingStatusModal = ({ data }) => {
  const dispatch = useDispatch();
  const dataState = useSelector((state) => state.order);
  const { t } = useTranslation();
  const [instanceState, setInstanceState] = useState(initInstanceState);
  const { bodyStyles } = useStateContext();
  const [shippingStatus, setShippingStatus] = useState('SHIPPED');

  const [withTrackingNumber, setWithTrackingNumber] = useState(false);

  const addInstanceSchema = Yup.object().shape({
    withTrackingNumber: Yup.boolean().oneOf(
      [
        data?.shippingLine?.trackingNumber ||
        !['NOT_SHIPPED', 'SHIPPED'].includes(data?.shippingStatus)
          ? false
          : true,
      ],
      'this fild is required'
    ),
  });

  const addInstanceFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      shippingStatus: shippingStatus,
      withTrackingNumber: withTrackingNumber,
      data: data,
    },
    validationSchema: addInstanceSchema,
    onSubmit: async (values) => {
      dispatch(
        handleEditOrderShippingStatus(values, (res) => {
          if (res?.data) {
          }
        })
      );
    },
  });

  // const addInstance = async (values) => {
  //   setInstanceState((prev) => {
  //     return { ...prev, loadingCreateInstance: true };
  //   });

  //   const dataSent = {
  //     input:
  //       data?.shippingStatus === "NOT_PICKED"
  //         ? "READY_TO_PICK"
  //         : data?.shippingStatus === "READY_TO_PICK"
  //         ? "PICKED"
  //         : data?.shippingStatus === "NOT_SHIPPED" && "SHIPPED",
  //     updateOrderShippingStatusId: +data?.id,
  //   };

  //   let res = await editOrderShippingStatus(dataSent, token);

  //   createInstance({
  //     setInstanceState: setInstanceState,
  //     res: res?.data,
  //     successActionBefore: () => {
  //       setOpen(false);

  //       prevSetInstanceState((prev) => {
  //         return {
  //           ...prev,
  //           createInstance: true,
  //           message:
  //             data?.shippingStatus === "NOT_PICKED"
  //               ? `order is ready to be picked!
  //                 An email has been sent successfully to the Client`
  //               : "this Shipping Status  has been successfully updated",
  //         };
  //       });

  //       delay(2000).then(async () => {
  //         prevSetInstanceState((prev) => {
  //           return { ...prev, createInstance: false };
  //         });
  //       });
  //     },
  //     successMessage:
  //       data?.shippingStatus === "NOT_PICKED"
  //         ? "order is ready to be picked! An email has been sent successfully to the Client"
  //         : "this Shipping Status has been successfully updated",
  //     errorActionBefore: () => {
  //       setOpen(false);

  //       prevSetInstanceState((prev) => {
  //         return {
  //           ...prev,
  //           errorCreatInstance: true,
  //           loadingCreateInstance: false,
  //           message: res,
  //         };
  //       });

  //       delay(3000).then(async () => {
  //         prevSetInstanceState((prev) => {
  //           return { ...prev, errorCreatInstance: false };
  //         });
  //       });
  //     },
  //     errorMeesage: res,
  //   });
  // };

  return (
    <div>
      <ModalDialog
        open={dataState?.editShippingModal}
        setOpen={() => dispatch(openModelEditShipping())}
        title={t('Shipping Details')}
        cancelTitle={t('Cancel')}
        cancel={() => dispatch(openModelEditShipping())}
        actionTitle={t('confirm')}
        action={() => addInstanceFormik.handleSubmit()}
        loadingAction={dataState.loadingEditShipping}
      >
        <form
          id='add-discount-modal'
          className='flex gap-5 flex-col md:w-[550px]'
        >
          <div className='w-[98%]'>
            <div
              className='font-Inter pt-2 pb-4 mt-1
                                       font-bold flex items-center justify-start'
              style={{
                color: bodyStyles.text,
              }}
            >
              <span>
                {data?.shippingStatus === 'NOT_PICKED'
                  ? t('Are you sure this order is ready to be picked up')
                  : data?.shippingStatus === 'READY_TO_PICK'
                  ? t('Are you sure you want to confirm Pick up for this order')
                  : data?.shippingStatus === 'NOT_SHIPPED' &&
                    `${t(
                      'Are you sure you want to confirm Shipping for this order'
                    )} ${
                      data?.shippingLine?.trackingNumber
                        ? `${t('with tracking number')} : ${
                            data?.shippingLine?.trackingNumber
                          }`
                        : ''
                    }`}
              </span>
            </div>

            {['NOT_SHIPPED', 'SHIPPED'].includes(data?.shippingStatus) &&
              !data?.shippingLine?.trackingNumber && (
                <div className='mb-2'>
                  <CheckBox
                    checked={withTrackingNumber ? true : false}
                    onClick={() => setWithTrackingNumber(!withTrackingNumber)}
                    label={t('Shipped Without Tracking Number')}
                    size='17'
                  />
                </div>
              )}

            {addInstanceFormik?.errors?.withTrackingNumber && (
              <span className='text-[#d32f2f] text-sm '>
                {t(
                  "Please check the 'Shipped Without Tracking Number', or you can update it by adding a tracking number in the"
                )}
                <span
                  className='px-[5px] text-lg underline cursor-pointer'
                  style={{
                    color: bodyStyles?.subText,
                  }}
                  onClick={() => dispatch(openModelEditShipped())}
                >
                  {t('shipping details')}
                </span>
              </span>
            )}
          </div>
        </form>
      </ModalDialog>
    </div>
  );
};

export default EditShippingStatusModal;
