import { gql } from "@apollo/client";

export const CREATE_MENU = gql`
  mutation CreateMenu($input: CreateMenuInput!) {
    createMenu(input: $input) {
      id
      translationData {
        name
        slug
        updatedAt
      }

      media {
        id
        createdAt
        updatedAt
        deletedAt
        original_name
        file_name
        path
        host
        full_url
        type
        alt
      }
      isRoot
      updatedAt
      createdAt
      deletedAt
    }
  }
`;

export const EDIT_MENU = gql`
  mutation UpdateMenu($input: UpdateMenuInput!) {
    updateMenu(input: $input) {
      children {
        id
        deletedAt
        createdAt
        isRoot
        translationData {
          updatedAt
          slug
          name
          languageCode
          id
          createdAt
          description
        }
        updatedAt
      }

      media {
        id
        createdAt
        updatedAt
        deletedAt
        original_name
        file_name
        path
        host
        full_url
        type
        alt
      }

      deletedAt
      id
      isRoot
      translationData {
        updatedAt
        name
        slug
        languageCode
        id
        description
        createdAt
      }
      updatedAt
      parent {
        createdAt
        id
        deletedAt
        isRoot
        updatedAt
        translationData {
          createdAt
          description
          id
          languageCode
          name
          slug
          updatedAt
        }
      }
      createdAt
    }
  }
`;

export const DELETE_MENU = gql`
  mutation RemoveMenu($removeMenuId: Int!) {
    removeMenu(id: $removeMenuId)
  }
`;
