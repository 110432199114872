import * as Yup from "yup";
import { useContext } from "react";
import { storeContext } from "../global/store";

const useRoleValidation = () => {
  const { store } = useContext(storeContext);

  const addRolechema = Yup.object().shape({
    name: Yup.string().required("This Field is required"),
    description: Yup.string()
    // .required("This Field is required"),
    // description: Yup.string().required("This Field is required"),
  });

  const editRoleSchema = Yup.object().shape({
    name: Yup.string().required("This Field is required"),
    description: Yup.string()
    // .required("This Field is required"),
  });

  return {
    addRolechema,
    editRoleSchema,
  };
};

export { useRoleValidation };
