import { useState } from 'react';
import ChooseProvider from './ChooseProvider';
import StripePaymeny from './StripePaymeny';
import PaypalPaymeny from './PaypalPaymeny';
import ManualPaymentMethods from './ManualPaymentMethods';
import { useSelector } from 'react-redux';

const Payment = () => {
  const [openChooseProvider, setOpenChooseProvider] = useState(false);
  const settingState = useSelector((state) => state.setting);

  return (
    <>
      {openChooseProvider ? (
        <ChooseProvider
          openChooseProvider={openChooseProvider}
          setOpenChooseProvider={setOpenChooseProvider}
        />
      ) : (
        <div className=''>
          {/* <div className="space-y-16 font-urbanist pt-6 md:pt-10 pb-12 md:pb-16 px-6 border-b border-[#D9D9D9]">
            <StripeContainer />
          </div> */}

          <div className='space-y-16 font-urbanist pt-6 md:pt-10 pb-12 md:pb-16 px-6 border-b border-[#D9D9D9]'>
            <StripePaymeny
              data={settingState?.data}
              loading={settingState?.loading}
            />
          </div>

          <div className='space-y-16 font-urbanist pt-6 md:pt-10 pb-12 md:pb-16 px-6 border-b border-[#D9D9D9]'>
            <PaypalPaymeny
              data={settingState?.data}
              loading={settingState?.loading}
            />
          </div>

          {/* <div className="space-y-16 font-urbanist pt-6 md:pt-10 pb-12 md:pb-16 px-6 border-b border-[#D9D9D9]">
            <PaymentProviders
              openChooseProvider={openChooseProvider}
              setOpenChooseProvider={setOpenChooseProvider}
            />
          </div>
          */}
          {/* <div className="space-y-16 font-urbanist pt-6 md:pt-10 pb-12 md:pb-16 px-6 border-b border-[#D9D9D9]">
            <SupportedPaymentMethods />
          </div> */}
          <div className='space-y-16 font-urbanist pt-6 md:pt-10 pb-12 md:pb-16 px-6'>
            <ManualPaymentMethods />
          </div>
          {/* <div className="flex items-end justify-end w-full mt-10">
            <CustomButton className=" text-base rounded-lg px-8 py-3 flex flex-row items-center justify-center gap-x-4">
              <SaveIcon color={bodyStyles?.filedButtonText} />
              <span style={{ color: bodyStyles?.filedButtonText }}>
                {t("Save")}
              </span>
            </CustomButton>
          </div> */}
        </div>
      )}
    </>
  );
};

export default Payment;
