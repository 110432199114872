import { gql } from "@apollo/client";

export const CREATE_PRODUCT = gql`
mutation CreateProduct($input: CreateProductInput!) {
  createProduct(input: $input) {
    body
    categories {
      type
      id
      name
      description
    }
    id
    name
    media {
      file_name
      id
      full_url
    }
  }
}
`;

export const ADD_PRODUCT_INFORMATION = gql`
  mutation CreateProductInformation($input: CreateProductInformationInput!) {
    createProductInformation(input: $input) {
      id
      product {
        id
      }
      language
      name
      description
    }
  }
`;

export const EDIT_PRODUCT_INFORMATION = gql`
  mutation UpdateProductInformation(
    $input: UpdateProductInformationInput!
    $updateProductInformationId: String
  ) {
    updateProductInformation(input: $input, id: $updateProductInformationId) {
      id
      product {
        id
      }
      language
      name
      description
    }
  }
`;

export const EDIT_PRODUCT = gql`
mutation UpdateProduct($updateProductId: Float!, $updateProductInput: UpdateProductInput!) {
  updateProduct(id: $updateProductId, updateProductInput: $updateProductInput) {
    id
    createdAt
    updatedAt
    deletedAt
    name
    body
    media {
      full_url
      id
    }
    categories {
      id
      type
      name
    }
  }
}
`;


export const DELETE_PRODUCT = gql`
mutation RemoveProducts($input: DeleteProductInput!) {
  removeProducts(input: $input)
}
`;
