import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../contexts/ContextProvider';
import { storeContext } from '../../../global/store';
import { delay } from '../../../utils/helpers';
import ModalDialog from '../../ModalDialog';
import BasicSelectMui from '../../Inputs/BasicSelectMui';
import BasicInput from '../../Inputs/BasicInput';
import { InputAdornment } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDiscountStart,
  createDiscountSuccess,
  openModelCreate,
} from '../../../store/features/discount/discountSlicer';
import { showSuccessMessage } from '../../../store/features/snackbar/snackbarSlice';

const DiscountModal = ({
  discountOrderModal,
  setDiscountOrderModal,
}) => {
  const data = useSelector((state) => state.discount);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  const { store } = useContext(storeContext);

  const [discountOrder, setDiscountOrder] = useState({
    discountType: 'amount',
    discountValue: 0,
    reason: '',
  });


  const addDiscountSchema = Yup.object().shape({
    discountType: Yup.string().required('This Field is required'),
    discountValue: Yup.string().required('This Field is required'),
    reason: Yup.string().required('This Field is required'),
  });

  const addDiscountFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      discountType: discountOrder.discountType,
      discountValue: discountOrder.discountValue,
      reason: discountOrder.reason,
    },
    validationSchema: addDiscountSchema,
    onSubmit: async (values) => {
      addDiscount(values);
    },
  });

  const addDiscount = async (values) => {
    dispatch(createDiscountStart());

    delay(1000).then(async () => {
      setDiscountOrderModal((prev) => {
        return {
          ...discountOrderModal,
          ...discountOrder,
        };
      });

      dispatch(createDiscountSuccess());

      dispatch(
        showSuccessMessage('this Discount has been successfully created')
      );

      dispatch(openModelCreate());
    });
  };
  return (
    <div>
      <p
        onClick={() => dispatch(openModelCreate())}
        className='cursor-pointer text-[#358DEB] hover:underline'
      >
        {t('Add discount')}
      </p>

      <ModalDialog
        open={data?.createModal}
        setOpen={(state) => dispatch(openModelCreate())
        }
        title={t('Add discount')}
        cancelTitle={t('Cancel')}
        cancel={() => dispatch(openModelCreate())
        }
        actionTitle={t('Apply')}
        action={() => addDiscountFormik.handleSubmit()}
        loadingAction={data.loadingCreate}
      >
        <form
          id='add-discount-modal'
          className='flex gap-5 flex-col md:w-[550px]'
        >
          <div className='flex gap-8 flex-col md:flex-row items-start '>
            <BasicSelectMui
              label={t('Discount type')}
              name='DiscountType'
              id='discountType'
              value={discountOrder.discountType}
              onChange={(e) => {
                setDiscountOrder({
                  ...discountOrder,
                  discountType: e.target.value,
                });
              }}
              options={[
                { value: 'amount', label: 'Amount' },
                { value: 'percentage', label: 'Percentage' },
              ]}
            />

            <BasicInput
              priceFormat
              label={t('Discount value')}
              placeholder={t('DZD 0.00')}
              value={discountOrder?.discountValue}
              onChange={(e) =>
                setDiscountOrder({
                  ...discountOrder,
                  discountValue: e.target.value,
                })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    fontSize='small'
                    position='end'
                    sx={{ color: bodyStyles?.inputText }}
                  >
                    <span className='text-xs'>
                      {discountOrder.discountType === 'amount'
                        ? store?.currencyStore?.symbol
                        : '%'}
                    </span>
                  </InputAdornment>
                ),
                style: { fontSize: '12px' },
              }}
              error={
                addDiscountFormik.touched.discountValue &&
                Boolean(addDiscountFormik.errors.discountValue)
              }
              helperText={
                addDiscountFormik.touched.discountValue &&
                addDiscountFormik.errors.discountValue
              }
            />
          </div>

          <div className='space-y-1'>
            <label>{t('Reason')}</label>
            <BasicInput
              type='text'
              name='reason'
              id='reason'
              value={discountOrder.reason}
              onChange={(e) =>
                setDiscountOrder({
                  ...discountOrder,
                  reason: e.target.value,
                })
              }
              error={
                addDiscountFormik.touched.reason &&
                Boolean(addDiscountFormik.errors.reason)
              }
              helperText={
                addDiscountFormik.touched.reason &&
                addDiscountFormik.errors.reason
              }
            />
            <p className='font-thin text-sm '>
              {t('Your customers can see this reason')}
            </p>
          </div>
        </form>
      </ModalDialog>
    </div>
  );
};

export default DiscountModal;
