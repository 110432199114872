import React from "react";
import { FormControl } from "@mui/material";
import BasicSelectMui from "../../../Inputs/BasicSelectMui";
import BasicSelecAutoComplete from "../../../Inputs/BasicSelecAutoComplete";
import { useTranslation } from "react-i18next";
import RtlConversion from "../../../RtlConversion";
import { languages_list } from "../../../../utils/languages";

const Language = ({
    availableLanguages,
    setAvailableLanguages,
    preferredLanguage,
    setPreferredLanguage,
    data,
  }) => {
    const { t, i18n } = useTranslation();
  
    return (
      <div className="border-t-1 border-color p-4 lg:p-7 font-Inter ">
        <div className="w-full flex justify-start text-sm">
          <p className="uppercase text-[#808080]">{t("Store languages")}</p>
        </div>
        <p className="text-[#888888] text-sm pt-3 lg:pt-6 ">
          {t("Your store products will displayed in")}
        </p>
        <div className="py-4 lg:py-8 grid grid-cols-1 md:grid-cols-2 gap-4">
          <RtlConversion rtl={i18n.language === "ar" ? true : false}>
            <div className="flex flex-col w-full gap-5">
              <FormControl fullWidth>
                <BasicSelectMui
                  type="text"
                  name="language"
                  id="language"
                  label={t("preferred Language")}
                  value={preferredLanguage}
                  onChange={(e) => setPreferredLanguage(e.target.value)}
                  options={
                    data?.setting_general?.available_language?.length
                      ? data?.setting_general?.available_language?.map(
                          (item) => {
                            return {
                              value: item,
                              label: languages_list?.find((l) => l.code == item)
                                .name,
                            };
                          }
                        )
                      : []
                  }
                />
              </FormControl>
  
              <FormControl fullWidth>
                <BasicSelecAutoComplete
                  fullWidth
                  multiple
                  label={t("available Languages")}
                  placeholder={t("Search for Language")}
                  options={languages_list ? languages_list : []}
                  getOptionLabel={
                    (option) => option.name
                  }
                  isOptionEqualToValue={(option, value) =>
                    option?.code === (value?.code || null)
                  }
                  value={availableLanguages}
                  freeSolo
                  onChange={(event, newValue) => {
                    setAvailableLanguages(newValue);
                  }}
                />
              </FormControl>
            </div>
          </RtlConversion>
        </div>
      </div>
    );
  };
  

export default Language