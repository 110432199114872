import React, { useState, useEffect, useContext } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { storeContext } from '../../global/store';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
  GeneralIcon,
  UserIcon,
  OnlineIcon,
  PaymentIcon,
  CheckoutIcon,
  UsersIcon,
} from '../Svg/NavIcons';
import { useTranslation } from 'react-i18next';
import { generalData } from '../../utils/general';
import {
  accountSettings,
  checkoutSetting,
  emailSetting,
  generalSettings,
  hasPermission,
  onlineSetting,
  paymentSetting,
  roleSetting,
  shippingMethodSettings,
  zoneSetting,
} from '../../utils/permissions';
import SnackbarModal from './SnackbarModal';
import { useLocation, useNavigate } from 'react-router-dom';

const LayoutSettings = ({ children }) => {
  const { t, i18n } = useTranslation();
  const {
    setCurrentColor,
    setCurrentMode,
    bodyStyles,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div
      style={{
        background: bodyStyles?.background ? bodyStyles?.background : '#000',
        color: bodyStyles?.text ? bodyStyles?.text : '#fff',
      }}
    >
      <div className='relative flex'>
        <div className='w-[50px] md:w-[280px]'>
          <Sidebar
            openSetting={themeSettings}
            setOpenSetting={setThemeSettings}
          />
        </div>

        <div
          style={{ backgroundColor: bodyStyles.background }}
          className='z-[9999] h-[91.5vh]  w-full overflow-auto'
        >
          <SnackbarModal />

          <div className='py-4'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default LayoutSettings;

const Sidebar = () => {
  const { t } = useTranslation();
  const { bodyStyles } = useStateContext();

  const {
    store,
    setStore,
    unSeen,
    open: openSection,
  } = useContext(storeContext);

  const activeLink =
    'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2';
  const normalLink =
    'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';

  return (
    <div
      style={{
        backgroundColor: bodyStyles?.background
          ? bodyStyles?.background
          : '#ffffff',
      }}
      className={`h-screen w-[50px] overflow-auto bg-white pb-10 text-black md:w-[280px] md:overflow-hidden md:hover:overflow-auto  `}
    >
      <div
        className={`flex h-full flex-col justify-between border-x border-[#C4C4C4] `}
        style={{ overflowY: 'overlay', zIndex: 999 }}
      >
        <div>
          <div className=' sticky top-0 z-[999] hidden py-4 px-8 text-center text-black md:block'>
            <div
              className={`flex cursor-pointer flex-col items-start justify-start gap-2`}
            >
              <span
                style={{
                  color: bodyStyles?.title ? bodyStyles?.title : '#414141',
                }}
                className='text-2xl font-semibold text-[#414141] '
              >
                {generalData.storeName}
              </span>
              <span
                style={{
                  color: bodyStyles?.subTitle
                    ? bodyStyles?.subTitle
                    : '#888888',
                }}
                className=' text-[12px] text-[#888888] underline '
              >
                {generalData.storepath}
              </span>
            </div>
          </div>
          <div className='mt-10 flex w-full  flex-col items-start justify-center md:mt-0 '>
            <Management unSeen={unSeen} color={'#000000'} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Management = ({ unSeen, color }) => {
  const { t } = useTranslation();
  const { store } = useContext(storeContext);
  const { bodyStyles } = useStateContext();
  const location = useLocation();

  const [sections, setSections] = useState([
    {
      title: 'General',
      value: 'General',
      key: 'General',
      path: 'general',
      icon: <GeneralIcon color={bodyStyles.text} />,
      permissions: [generalSettings['general-settings:list']],
      display: generalData?.pages?.generalSetting,
    },

    {
      title: 'Account',
      value: 'Account',
      key: 'Account',
      path: 'account',
      icon: <UserIcon color={bodyStyles.text} />,
      permissions: [accountSettings['account-settings:list']],
      display: generalData?.pages?.accountSetting,
    },

    {
      title: 'Roles',
      value: 'Roles',
      key: 'Roles',
      path: 'roles',
      icon: <UsersIcon color={bodyStyles.text} />,
      permissions: [roleSetting['role-settings:list']],
      display: generalData?.pages?.roleSetting,
    },

    {
      title: 'Online',
      value: 'Online',
      key: 'Online',
      path: 'online',
      icon: <OnlineIcon color={bodyStyles.text} />,
      permissions: [onlineSetting['online-settings:list']],
      display: generalData?.pages?.onlineSetting,
    },

    {
      title: 'Payment',
      value: 'Payment',
      key: 'Payment',
      path: 'payment',
      icon: <PaymentIcon color={bodyStyles.text} />,
      permissions: [paymentSetting['payment-settings:list']],
      display: generalData?.pages?.paymentSetting,
    },

    {
      title: 'Email Setting',
      value: 'EmailSetting',
      key: 'EmailSetting',
      path: 'email-setting',
      icon: <MailOutlineIcon color={bodyStyles.text} />,
      permissions: [emailSetting['email-setting:list']],
      display: generalData?.pages?.emailSetting,
    },

    {
      title: 'shipping Methode',
      value: 'shippingMethode',
      key: 'shippingMethode',
      path: 'shipping-methode',
      icon: <LocalShippingOutlinedIcon color={bodyStyles.text} />,
      permissions: [shippingMethodSettings['shipping-method:list']],
      display: generalData?.pages?.shippingMethodeSetting,
    },

    {
      title: 'zones',
      value: 'zones',
      key: 'zones',
      path: 'zones',
      icon: <PublicOutlinedIcon color={bodyStyles.text} />,
      permissions: [zoneSetting['zone:list']],
      display: generalData?.pages?.zoneSetting,
    },

    {
      title: 'Checkout',
      value: 'Checkout',
      key: 'Checkout',
      path: 'checkout',
      icon: <CheckoutIcon color={bodyStyles.text} />,
      permissions: [checkoutSetting['checkout-settings:list']],
      display: generalData?.pages?.checkoutSetting,
    },
  ]);

  return (
    <div className='flex  w-full flex-col justify-between pb-6 '>
      <div className=' flex flex-col gap-y-2 '>
        {sections.map(
          (item, index) =>
            item.display && (
              <>
                {item?.permissions?.some((per) =>
                  hasPermission(store?.permissions, per, store.usePermissions)
                ) && (
                  <SectionNonCollapse
                    title={t(item?.title)}
                    value={item?.value}
                    selected={location?.pathname === `/settings/${item.path}`}
                    path={item.path}
                    key={index}
                  >
                    {item?.icon}
                  </SectionNonCollapse>
                )}
              </>
            )
        )}
      </div>
    </div>
  );
};

const SectionNonCollapse = ({ title, selected, path, children }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { bodyStyles, selectedTabSetting, setSelectedTabSetting } =
    useStateContext();

  return (
    <div>
      <div style={{ width: '100%', borderRadius: 4 }}>
        <div
          onClick={() => {
            navigate(`/settings/${path}`);
          }}
          className={`flex w-full 
          cursor-pointer items-center justify-between bg-clip-padding text-sm
         font-semibold hover:bg-[rgba(190,190,190,.5)] `}
        >
          <div
            className={`flex flex-row items-start justify-center py-2 px-2 md:justify-start md:px-8 ${
              selected
                ? 'border-l-2 border-[#414141] bg-[rgba(190,190,190,.5)]'
                : ''
            } w-full `}
          >
            <div
              title={t(title)}
              className={`flex gap-x-4   ${
                i18n.language === 'ar' ? 'text-right' : 'text-left'
              }
              font-Inter text-[12px] uppercase
              ${
                selected ? 'text-mainSideBar_text ' : 'text-mainSideBar_text '
              } font-urbanist text-base font-medium`}
            >
              {children}
              <span
                className='hidden md:block'
                style={{ color: bodyStyles?.text }}
              >
                {title}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
