import React from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const BasicButton = ({
  icon,
  bgColor,
  color,
  borderColor,
  bgHoverColor,
  size,
  text,
  borderRadius,
  width,
  type,
  onClick,
  form,
  loading,
  fullWidth,
  fullHeight,
  minHeight,
  minWidth,
}) => {
  const { bodyStyles } = useStateContext();
  const { t } = useTranslation();

  return (
    <button
      type={type}
      form={form}
      onClick={onClick}
      className={` rounded-md  px-4  flex items-center justify-center 
      font-semibold border-[1.5px] min-h-8 py-2 cursor-pointer
      ${fullWidth ? "w-full" : ""}
      ${fullHeight ? "h-full" : ""}
      text-sm capitalize  
    `}
      style={{
        borderColor: borderColor ? borderColor : bodyStyles?.text,
        color: color ? color : bodyStyles?.text,
        minHeight: minHeight ? minHeight : "",
        minWidth: minWidth ? minWidth : "",
      }}
      disabled={loading ? true : false}
    >
      {loading ? (
        <CircularProgress
          style={{
            width: 15,
            height: 15,
            color: bodyStyles?.text,
          }}
        />
      ) : (
        `${icon ? icon : ""} ${t(text)} `
      )}
    </button>
  );
};

export default BasicButton;
