import { gql } from "@apollo/client";

export const GET_ALL_CHECKOUTS = gql`
  query Checkouts(
    $where: FilterCheckoutsArgs
    $join: CheckoutRelationsArgs
    $limit: Int
    $offset: Int
    $sortBy: SortByFilterArgs

  ) {
    checkouts(where: $where, join: $join, limit: $limit, offset: $offset, sortBy: $sortBy) {
      nodes {
        id
        email
        deletedAt
        createdAt
        updatedAt
        total
        phoneNumber
        paymentStatus
        paymentMethod
        order {
          orderLine {
            id
          }
          order_code
          status
          subTotal
          updatedAt
          canceled_at
          currencyCode
          discount {
            max_usage
            name
            perCustomerUsageLimit
            remaining
            percentage
            status
            startsAt
            id
            fixedAmount
            endsAt
            couponCode
            createdAt
            updatedAt
          }
        }
      }
      totalCount
      hasNextPage
    }
  }
`;

export const GET_CHECKOUT = gql`
query Checkout($join: CheckoutRelationsArgs!, $checkoutId: Int!) {
  checkout(join: $join, id: $checkoutId) {
    id
    email
    deletedAt
    createdAt
    paymentMethod
    paymentStatus
    phoneNumber
    total
    updatedAt
    recoveryEmailSentAt
    order {
      cart {
        id
        currencyCode
        totalPrice
        isArchived
        cartItems {
          createdAt
          id
          quantity
          updatedAt
          variant {
            compareAtPrice
            currencyCode
            createdAt
            deletedAt
            id
            isEnabled
            media {
              id
              createdAt
              updatedAt
              deletedAt
              original_name
              file_name
              path
              host
              full_url
              type
              alt
              __typename
            }
            options {
              id
              createdAt
              updatedAt
              translationData {
                id
                createdAt
                updatedAt
                name
                slug
                description
                languageCode
                __typename
              }
              group {
                id
                createdAt
                updatedAt
                translationData {
                  id
                  createdAt
                  updatedAt
                  name
                  slug
                  description
                  languageCode
                  __typename
                }
                __typename
              }
              __typename
            }
            pricing
            sku
            stockAllocated
            stockOnHand
            product {
              id
              translationData {
                id
                createdAt
                updatedAt
                name
                slug
                description
                languageCode
                __typename
              }
              media {
                id
                createdAt
                updatedAt
                deletedAt
                original_name
                file_name
                path
                host
                full_url
                type
                alt
                __typename
              }
              __typename
            }
            defaultOf {
              id
              media {
                id
                createdAt
                updatedAt
                deletedAt
                original_name
                file_name
                path
                host
                full_url
                type
                alt
                __typename
              }
              translationData {
                id
                createdAt
                updatedAt
                name
                slug
                description
                languageCode
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      id
      discount {
        max_usage
        name
        perCustomerUsageLimit
        remaining
        percentage
        status
        startsAt
        id
        fixedAmount
        endsAt
        couponCode
        createdAt
        updatedAt
        __typename
      }
      customer {
        createdAt
        email
        id
        firstName
        lastName
        phoneNumber
        updatedAt
        __typename
      }
      order_code
      status
      subTotal
      updatedAt
      canceled_at
      createdAt
      currencyCode
      orderLine {
        createdAt
        id
        price
        quantity
        updatedAt
        __typename
        variant {
          id
          media {
            full_url
            original_name
          }
          options {
            group {
              id
              translationData {
                languageCode
                name
                slug
                description
              }
              values {
                id
                translationData {
                  languageCode
                  name
                  slug
                }
              }
            }
            id
            translationData {
              languageCode
              name
              id
            }
          }
          pricing
          product {
            id
            media {
              full_url
              id
            }
            translationData {
              id
              languageCode
              name
            }
          }
        }
      }
      shippingLine {
        id
        deletedAt
        createdAt
        shippingCost
        updatedAt
        shippingMethod {
          createdAt
          currency_code
          default_price
          deletedAt
          id
          translationData {
            createdAt
            description
            id
            languageCode
            name
            rateName
            slug
            __typename
          }
          weight_limit
          updatedAt
          __typename
        }
        billingAddress {
          apartment
          city
          company
          country {
            id
            translationData {
              languageCode
              name
              slug
              id
              description
              __typename
            }
            createdAt
            __typename
          }
          createdAt
          deletedAt
          fullName
          id
          isDefaultBillingAddress
          isDefaultShippingAddress
          phoneNumber
          postalCode
          province
          streetLine1
          streetLine2
          updatedAt
          __typename
        }
        shippingAddress {
          city
          apartment
          company
          country {
            id
            translationData {
              id
              languageCode
              name
              slug
              description
              __typename
            }
            __typename
          }
          createdAt
          deletedAt
          fullName
          id
          isDefaultBillingAddress
          isDefaultShippingAddress
          phoneNumber
          postalCode
          province
          streetLine1
          streetLine2
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
`;
