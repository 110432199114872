import * as React from "react";

export const FilterIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21 7H11"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      r={2}
      transform="matrix(-1 0 0 1 5 7)"
      stroke={props.color}
      strokeWidth={1.5}
    />
    <path
      d="M3 17h10"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={19} cy={17} r={2} stroke={props.color} strokeWidth={1.5} />
  </svg>
);

export const PlusIcon = (props) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect
      x={1}
      y={1}
      width={20}
      height={20}
      rx={5}
      stroke="#6F767E"
      strokeWidth={1.5}
    />
    <path
      d="M8 11h6M11 8v6"
      stroke="#6F767E"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TrashIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M5.05 8.734C4.207 7.608 5.01 6 6.419 6h11.164c1.409 0 2.212 1.608 1.367 2.734A4.747 4.747 0 0 0 18 11.582V18a4 4 0 0 1-4 4h-4a4 4 0 0 1-4-4v-6.418a4.747 4.747 0 0 0-.95-2.848Z"
      stroke={props.color}
      strokeWidth={1.5}
    />
    <path
      d="M14 17v-6M10 17v-6"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m16 6-.544-1.632A2 2 0 0 0 13.559 3h-3.117a2 2 0 0 0-1.898 1.368L8 6"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);

export const EditIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.038 2.72a2.475 2.475 0 0 0-3.492.001L9.09 10.158a3.35 3.35 0 0 0-.982 2.231l-.106 2.513A2.017 2.017 0 0 0 10.02 17h2.472c.908 0 1.777-.365 2.41-1.013l7.396-7.579a2.455 2.455 0 0 0-.022-3.459L20.038 2.72Zm-2.54.95a1.125 1.125 0 0 1 1.588 0l2.238 2.229c.435.432.44 1.133.01 1.572L19.85 8.988 16.005 5.16l1.494-1.49Zm-2.445 2.44-5.01 4.997a2.01 2.01 0 0 0-.59 1.339l-.105 2.512c-.017.382.29.7.672.7h2.472c.545 0 1.066-.22 1.447-.608l4.97-5.1-3.856-3.84Z"
      fill="#2B3F6C"
    />
    <path
      d="M21.75 13a.75.75 0 0 0-1.5 0h1.5ZM12 4.75a.75.75 0 0 0 0-1.5v1.5Zm4 16.5H8v1.5h8v-1.5ZM3.75 17V9h-1.5v8h1.5Zm16.5-4v4h1.5v-4h-1.5ZM8 4.75h4v-1.5H8v1.5Zm0 16.5A4.25 4.25 0 0 1 3.75 17h-1.5A5.75 5.75 0 0 0 8 22.75v-1.5Zm8 1.5A5.75 5.75 0 0 0 21.75 17h-1.5A4.25 4.25 0 0 1 16 21.25v1.5ZM3.75 9A4.25 4.25 0 0 1 8 4.75v-1.5A5.75 5.75 0 0 0 2.25 9h1.5Z"
      fill="#2B3F6C"
    />
  </svg>
);

export const MoreIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M7.05078 13.2998C7.74114 13.2998 8.30078 12.7402 8.30078 12.0498C8.30078 11.3594 7.74114 10.7998 7.05078 10.7998C6.36043 10.7998 5.80078 11.3594 5.80078 12.0498C5.80078 12.7402 6.36043 13.2998 7.05078 13.2998Z"
      fill="black"
    />
    <path
      d="M12.0508 13.2998C12.7411 13.2998 13.3008 12.7402 13.3008 12.0498C13.3008 11.3594 12.7411 10.7998 12.0508 10.7998C11.3604 10.7998 10.8008 11.3594 10.8008 12.0498C10.8008 12.7402 11.3604 13.2998 12.0508 13.2998Z"
      fill="black"
    />
    <path
      d="M17.0508 13.2998C17.7411 13.2998 18.3008 12.7402 18.3008 12.0498C18.3008 11.3594 17.7411 10.7998 17.0508 10.7998C16.3604 10.7998 15.8008 11.3594 15.8008 12.0498C15.8008 12.7402 16.3604 13.2998 17.0508 13.2998Z"
      fill="black"
    />
  </svg>
);

export const CsvIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    {...props}
    fill={props.color}>
    <path d="M499.677 426.489a8.017 8.017 0 0 0 8.017-8.017V84.977c0-2.1-.862-4.183-2.347-5.668l-76.96-76.96A8.113 8.113 0 0 0 422.716 0H106.324C92.473 0 81.205 11.268 81.205 25.119v9.086H12.261c-6.987 0-10.615 8.738-5.669 13.685l62.741 62.741-62.741 62.74c-4.946 4.947-1.319 13.685 5.669 13.685h68.944v299.825c0 13.851 11.268 25.119 25.119 25.119h376.251c13.851 0 25.119-11.268 25.119-25.119v-34.205a8.017 8.017 0 0 0-8.017-8.017 8.017 8.017 0 0 0-8.017 8.017v34.205c0 5.01-4.076 9.086-9.086 9.086h-376.25c-5.01 0-9.086-4.076-9.086-9.086V187.056h51.841c4.428 0 8.017-3.589 8.017-8.017s-3.588-8.017-8.017-8.017H31.615l54.724-54.724a8.016 8.016 0 0 0 0-11.337L31.615 50.238h348.88v120.785H183.284c-4.428 0-8.017 3.589-8.017 8.017s3.588 8.017 8.017 8.017h205.228a8.017 8.017 0 0 0 8.017-8.017V42.221a8.017 8.017 0 0 0-8.017-8.017H97.238v-9.086c0-5.01 4.076-9.086 9.086-9.086H414.7v51.841c0 13.851 11.268 25.119 25.119 25.119h51.841V418.47a8.016 8.016 0 0 0 8.017 8.019zM439.819 76.96c-5.01 0-9.086-4.076-9.086-9.086V27.37l49.589 49.59h-40.503z" />
    <path d="M191.835 128.267h-17.637V92.994h17.637c4.428 0 8.017-3.589 8.017-8.017s-3.588-8.017-8.017-8.017h-25.653a8.017 8.017 0 0 0-8.017 8.017v51.307a8.017 8.017 0 0 0 8.017 8.017h25.653c4.428 0 8.017-3.589 8.017-8.017s-3.588-8.017-8.017-8.017zM243.142 102.614h-17.637v-9.62h17.637c4.428 0 8.017-3.589 8.017-8.017s-3.588-8.017-8.017-8.017h-25.653a8.017 8.017 0 0 0-8.017 8.017v25.653a8.017 8.017 0 0 0 8.017 8.017h17.637v9.62h-17.637c-4.428 0-8.017 3.589-8.017 8.017s3.588 8.017 8.017 8.017h25.653a8.017 8.017 0 0 0 8.017-8.017V110.63a8.016 8.016 0 0 0-8.017-8.016zM305.536 77.372c-4.145-1.382-8.76.925-10.141 5.071l-9.497 28.49-9.497-28.49a8.016 8.016 0 0 0-10.141-5.071 8.016 8.016 0 0 0-5.07 10.141l17.102 51.307a8.015 8.015 0 0 0 15.21 0l17.102-51.307c1.382-4.145-.924-8.759-5.068-10.141zM414.165 213.779H174.733c-9.136 0-16.568 7.432-16.568 16.568v222.33c0 9.136 7.432 16.568 16.568 16.568h239.432c9.136 0 16.568-7.432 16.568-16.568v-222.33c0-9.136-7.432-16.568-16.568-16.568zm.535 238.897c0 .295-.24.534-.534.534H174.733a.535.535 0 0 1-.534-.534v-222.33c0-.295.24-.534.534-.534h239.432c.294 0 .534.239.534.534v222.33z" />
    <path d="M243.142 256.534h-34.205c-4.428 0-8.017 3.589-8.017 8.017s3.588 8.017 8.017 8.017h34.205c4.428 0 8.017-3.589 8.017-8.017s-3.588-8.017-8.017-8.017zM379.961 256.534H277.347c-4.428 0-8.017 3.589-8.017 8.017s3.588 8.017 8.017 8.017h102.614c4.428 0 8.017-3.589 8.017-8.017s-3.589-8.017-8.017-8.017zM243.142 307.841h-34.205c-4.428 0-8.017 3.589-8.017 8.017s3.588 8.017 8.017 8.017h34.205c4.428 0 8.017-3.589 8.017-8.017s-3.588-8.017-8.017-8.017zM379.961 307.841H277.347c-4.428 0-8.017 3.589-8.017 8.017s3.588 8.017 8.017 8.017h102.614c4.428 0 8.017-3.589 8.017-8.017s-3.589-8.017-8.017-8.017zM243.142 359.148h-34.205a8.017 8.017 0 0 0-8.017 8.017 8.017 8.017 0 0 0 8.017 8.017h34.205a8.017 8.017 0 0 0 8.017-8.017 8.017 8.017 0 0 0-8.017-8.017zM303 359.148h-25.653a8.017 8.017 0 0 0-8.017 8.017 8.017 8.017 0 0 0 8.017 8.017H303a8.017 8.017 0 0 0 8.017-8.017 8.017 8.017 0 0 0-8.017-8.017zM243.142 410.455h-34.205a8.017 8.017 0 0 0-8.017 8.017 8.017 8.017 0 0 0 8.017 8.017h34.205a8.017 8.017 0 0 0 8.017-8.017 8.016 8.016 0 0 0-8.017-8.017zM303 410.455h-25.653a8.017 8.017 0 0 0-8.017 8.017 8.017 8.017 0 0 0 8.017 8.017H303a8.017 8.017 0 0 0 8.017-8.017 8.016 8.016 0 0 0-8.017-8.017zM369.018 392.818l17.101-20.522a8.019 8.019 0 0 0-1.026-11.291 8.02 8.02 0 0 0-11.291 1.026l-15.219 18.263-15.219-18.263a8.018 8.018 0 0 0-12.317 10.265l17.101 20.522-17.101 20.522c-2.573 3.087-2.422 7.759.357 10.667 3.263 3.413 8.937 3.225 11.96-.402l15.219-18.263 15.219 18.263c3.023 3.628 8.697 3.815 11.96.402 2.78-2.907 2.93-7.578.357-10.667l-17.101-20.522z" />
  </svg>
);

export const NotificationIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M4 14.885h-.75H4Zm2.733 3.198.103-.742-.103.742Zm10.186 0-.103-.742.103.742ZM4.466 13.187l-.644-.384.644.384Zm2.023-5.1a5.337 5.337 0 0 1 5.337-5.337v-1.5A6.837 6.837 0 0 0 4.99 8.087h1.5Zm0 2.093V8.087h-1.5v2.093h1.5ZM4.75 14.885c0-.481.132-.93.36-1.314l-1.288-.768a4.054 4.054 0 0 0-.572 2.082h1.5Zm2.086 2.455c-1.2-.166-2.086-1.209-2.086-2.455h-1.5c0 1.96 1.404 3.667 3.38 3.941l.206-1.486Zm4.99.432c-1.492 0-3.42-.214-4.99-.431l-.206 1.485c1.583.22 3.595.446 5.196.446v-1.5Zm4.99-.431c-1.57.217-3.498.431-4.99.431v1.5c1.6 0 3.614-.226 5.196-.446l-.206-1.486Zm2.086-2.456c0 1.246-.886 2.29-2.086 2.455l.206 1.486c1.976-.274 3.38-1.981 3.38-3.94h-1.5Zm-.36-1.314c.228.384.36.833.36 1.314h1.5c0-.76-.209-1.472-.572-2.082l-1.288.768Zm-1.379-5.484v2.093h1.5V8.087h-1.5ZM11.826 2.75a5.337 5.337 0 0 1 5.337 5.337h1.5a6.837 6.837 0 0 0-6.837-6.837v1.5Zm8.004 10.053c-.309-.518-.59-.933-.82-1.396-.222-.444-.347-.84-.347-1.227h-1.5c0 .712.23 1.348.504 1.897.264.529.62 1.067.875 1.494l1.288-.768ZM4.99 10.18c0 .387-.125.783-.347 1.227-.231.463-.512.878-.82 1.396l1.288.768c.254-.427.61-.965.874-1.494.274-.55.504-1.185.504-1.897h-1.5Z"
      fill={props.color}
    />
    <path
      d="M14 20.834A2.606 2.606 0 0 1 11.826 22a2.606 2.606 0 0 1-2.174-1.166"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);

export const UserIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle
      r={4}
      transform="matrix(-1 0 0 1 12 7)"
      stroke={props.color}
      strokeWidth={1.5}
    />
    <path
      d="M5 16.935c0-.86.54-1.628 1.351-1.917a16.794 16.794 0 0 1 11.298 0A2.036 2.036 0 0 1 19 16.934v1.315c0 1.188-1.052 2.1-2.227 1.932l-.955-.136a27.002 27.002 0 0 0-7.636 0l-.955.136A1.951 1.951 0 0 1 5 18.25v-1.315Z"
      stroke={props.color}
      strokeWidth={1.5}
    />
  </svg>
);

export const ArrowRightIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m9 19 5.33-6.22a1.2 1.2 0 0 0 0-1.56L9 5"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);

export const ArrowLeftIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m8 8-3.293 3.293a1 1 0 0 0 0 1.414L8 16m-3-4h14"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);

export const InformationIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect
      x={2}
      y={2}
      width={20}
      height={20}
      rx={5}
      stroke={props.color}
      strokeWidth={1.5}
    />
    <path
      d="M12.5 17v-6M10.5 11h2M12.5 8V7"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BagIcon = (props) => (
  <svg
    width="44"
    height="40"
    viewBox="0 0 44 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 12.8574C2 10.6483 3.79086 8.85742 6 8.85742H38C40.2091 8.85742 42 10.6483 42 12.8574V16.1669C42 17.9334 40.8413 19.4907 39.1494 19.9983L24.2988 24.4534C22.7993 24.9033 21.2007 24.9033 19.7012 24.4534L4.85061 19.9983C3.15867 19.4907 2 17.9334 2 16.1669V12.8574Z"
      stroke="black"
      strokeWidth="3"
    />
    <path
      d="M22 19.1434L22 15.7148"
      stroke="black"
      strokeWidth="3"
     strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.66406 19.1426L3.66406 29.9996C3.66406 34.4179 7.24579 37.9996 11.6641 37.9996H32.3307C36.749 37.9996 40.3307 34.4179 40.3307 29.9996V19.1426"
      stroke="black"
      strokeWidth="3"
    />
    <path
      d="M28.6693 8.8571V6C28.6693 3.79086 26.8784 2 24.6693 2H19.3359C17.1268 2 15.3359 3.79086 15.3359 6L15.3359 8.8571"
      stroke="black"
      strokeWidth="3"
    />
  </svg>
);

export const PhoneIcon = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.1241 36.4366C30.2155 40.3452 20.2056 36.6726 11.7665 28.2335C3.32745 19.7944 -0.345218 9.78455 3.56341 5.8759L6.13694 3.30237C7.91359 1.52571 10.8411 1.57268 12.6757 3.40726L16.6618 7.39344C18.4964 9.22803 18.5434 12.1555 16.7667 13.9322L16.214 14.4849C15.2547 15.4442 15.1609 16.9916 16.0522 18.0717C16.9119 19.1136 17.8388 20.1513 18.8438 21.1562C19.8487 22.1612 20.8864 23.0881 21.9283 23.9478C23.0084 24.8391 24.5558 24.7453 25.5151 23.786L26.0678 23.2333C27.8445 21.4566 30.772 21.5036 32.6066 23.3381L36.5927 27.3243C38.4273 29.1589 38.4743 32.0864 36.6976 33.8631L34.1241 36.4366Z"
      stroke="black"
      strokeWidth="3"
    />
  </svg>
);
export const MailIcon = (props) => (
  <svg
    width="44"
    height="36"
    viewBox="0 0 44 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="2"
      y="2"
      width="40"
      height="32"
      rx="10"
      stroke="black"
      strokeWidth="3"
    />
    <path
      d="M10 12L19.6 19.2C21.0222 20.2667 22.9778 20.2667 24.4 19.2L34 12"
      stroke="black"
      strokeWidth="3"
     strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SaveIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M2 6C2 3.79086 3.79086 2 6 2H12H14.7574C15.553 2 16.3161 2.31607 16.8787 2.87868L21.1213 7.12132C21.6839 7.68393 22 8.44699 22 9.24264V12V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6Z"
      stroke={props?.color}
      strokeWidth="1.5"
    />
    <path
      d="M6 15C6 13.8954 6.89543 13 8 13H16C17.1046 13 18 13.8954 18 15V22H6V15Z"
      stroke={props?.color}
      strokeWidth="1.5"
    />
    <path
      d="M8 2V6C8 6.55228 8.44772 7 9 7H15C15.5523 7 16 6.55228 16 6V2"
      stroke={props?.color}
      strokeWidth="1.5"
    />
  </svg>
);

export const CloseIcon = (props) => (
  <svg
    width={25}
    // height={25}
    fill="none"
    version="1.1"
    viewBox="0 0 31.921 36.45"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    >
    <path
      d="m.879 17.121 16-16M.879.879l16 16"
      stroke={props?.color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);



export const SearchIcon = (props) => (
  <svg
    width={21}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={9}
      cy={9}
      r={8}
      stroke={props?.color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m14.5 14.958 5 5"
      stroke={props?.color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);


export const Restart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 489.645 489.645"
    // style={{
    //   enableBackground: "new 0 0 489.645 489.645",
    // }}
    xmlSpace="preserve"
    {...props}
  >
    <path 
     stroke={props?.color}
    d="M460.656 132.911c-58.7-122.1-212.2-166.5-331.8-104.1-9.4 5.2-13.5 16.6-8.3 27 5.2 9.4 16.6 13.5 27 8.3 99.9-52 227.4-14.9 276.7 86.3 65.4 134.3-19 236.7-87.4 274.6-93.1 51.7-211.2 17.4-267.6-70.7l69.3 14.5c10.4 2.1 21.8-4.2 23.9-15.6 2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25 16.6-23.9 22.9l15.6 123.8c1 10.4 9.4 17.7 19.8 17.7 12.8 0 20.8-12.5 19.8-23.9l-6-50.5c57.4 70.8 170.3 131.2 307.4 68.2 58.1-30 191.5-147.7 103.9-329.6z" />
  </svg>
)



