import { constants } from '../constants';
import { client } from '../global/apolloClient';
import {
  CREATE_PRODUCT,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  ADD_PRODUCT_INFORMATION,
  EDIT_PRODUCT_INFORMATION,
} from '../graphql/mutation/product.mutation';
import {
  GET_COUNT_PRODUCTS,
  GET_PRODUCT,
  GET_ALL_PRODUCTS,
} from '../graphql/query/products.query';
import {
  createProductFail,
  createProductStart,
  createProductSuccess,
  deleteProductFail,
  deleteProductStart,
  deleteProductSuccess,
  deleteProductsFail,
  deleteProductsStart,
  deleteProductsSuccess,
  editProductFail,
  editProductStart,
  editProductSuccess,
  fetchProductsFail,
  fetchProductsStart,
  fetchProductsSuccess,
  importFail,
  importStart,
  importSuccess,
} from '../store/features/product/productSlicer';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation, resteCache } from './executeMutation';
import { uploadImage } from './upload';
import { createManyVariant } from './variant';
const token = getTheTokenFromStorage();

export const createProduct = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: CREATE_PRODUCT,
      variables: {
        ...values,
      },
      context: { accessToken },
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const getProduct = async (values, accessToken) => {
  try {
    //product
    const result = await client.query({
      query: GET_PRODUCT,
      variables: {
        ...values,
      },
      context: { accessToken },
    });

    return result;
  } catch (error) {
    return error.message;
  }
};
export const getProductsCount = async (values) => {
  try {
    //product
    const result = await client.query({
      query: GET_COUNT_PRODUCTS,
      variables: {
        ...values,
      },
      fetchPolicy: 'no-cache',
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const addProductInformation = async (values, accessToken) => {
  try {
    //product
    const result = await client.mutate({
      mutation: ADD_PRODUCT_INFORMATION,
      variables: {
        ...values,
      },
      context: { accessToken },
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const editProductInformation = async (values, accessToken) => {
  try {
    //product
    const result = await client.mutate({
      mutation: EDIT_PRODUCT_INFORMATION,
      variables: {
        ...values,
      },
      context: { accessToken },
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const editProduct = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: EDIT_PRODUCT,
      variables: {
        ...values,
      },
      context: { accessToken },
      refetchQueries: [GET_ALL_PRODUCTS, GET_COUNT_PRODUCTS],
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const deleteProduct = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: DELETE_PRODUCT,
      variables: {
        ...values,
      },
      context: { accessToken },
      refetchQueries: [GET_ALL_PRODUCTS],
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const deleteManyProducts = async (values, accessToken) => {
  return await executeMutation(
    DELETE_PRODUCT,
    [GET_ALL_PRODUCTS, GET_PRODUCT],
    values,
    accessToken
  );
};

export const fetchProducts = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchProductsStart());
      const { data } = await client.query({
        query: GET_ALL_PRODUCTS,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchProductsSuccess(data));
    } catch (error) {
      dispatch(fetchProductsFail(error));
    }
  };
};

export const handleDeleteProduct = (id, callback) => {
  return async (dispatch) => {
    dispatch(deleteProductStart());
    const dataSent = {
      input: {
        removeProductId: parseInt(id),
      },
    };
    try {
      let res = await deleteProduct(dataSent, token);
      if (res?.data?.removeProduct) {
        dispatch(deleteProductSuccess(id));
        dispatch(showSuccessMessage('Product deleted successfully'));
      } else {
        dispatch(deleteProductFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteProductFail(error));
      callback && callback(error);
    }
  };
};

export const handleDeleteManyProduct = (ids, callback) => {
  return async (dispatch) => {
    dispatch(deleteProductsStart());
    const dataSent = {
      input: {
        products: ids.map((id) => +id),
      },
    };
    try {
      let res = await deleteProduct(dataSent, token);
      if (res?.data) {
        dispatch(deleteProductsSuccess(ids));
        dispatch(showSuccessMessage('Products deleted successfully'));
      } else {
        dispatch(deleteProductsFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteProductsFail(error));
      callback && callback(error);
    }
  };
};

export const handleCreateProduct = (values, callback) => {
  return async (dispatch) => {
    dispatch(createProductStart());

    const allImages = values.images?.length
      ? values.images.map(async (image) => {
          if (image.id) {
            return +image?.id;
          } else {
            let res = await uploadImage(image);
            if (res?.data?.id) {
              return +res?.data?.id;
            }
          }
        })
      : [];

    const dataSent = {
      input: {
        name: values?.name,
        body: values?.htmlBody,
        categories: values.categories.map(function (obj) {
          return +obj.id;
        }),
        media: await Promise.all(allImages)
          .then((responses) => {
            return responses;
          })
          .then((r) => r.filter((v) => v)),
      },
    };
    try {
      const res = await createProduct(dataSent, token);
      if (res?.data?.createProduct) {
        dispatch(createProductSuccess(res?.data?.createProduct));
        dispatch(
          showSuccessMessage('this Product has been successfully created')
        );
        resteCache('products');
      } else {
        dispatch(createProductFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(createProductFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleEditProduct = (values, callback) => {
  return async (dispatch) => {
    dispatch(editProductStart());

    const allImages = values.images?.length
      ? values.images.map(async (image) => {
          if (image.id) {
            return +image?.id;
          } else {
            let res = await uploadImage(image);
            if (res?.data?.id) {
              return +res?.data?.id;
            }
          }
        })
      : [];

    const dataSent = {
      updateProductId: values.id,
      updateProductInput: {
        name: values?.name,
        body: values?.htmlBody,
        categories: values.categories.map(function (obj) {
          return +obj.id;
        }),
        media: await Promise.all(allImages)
          .then((responses) => {
            return responses;
          })
          .then((r) => r.filter((v) => v)),
      },
    };

    try {
      let res = await editProduct(dataSent, token);
      if (res?.data) {
        dispatch(editProductSuccess(res?.data));
        dispatch(
          showSuccessMessage('this Product has been successfully updated')
        );
        resteCache('products');
      } else {
        dispatch(editProductFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(editProductFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleImportProduct = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyProducts(dataSent, token);
      if (res?.data?.removeProducts) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Products Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteProductsFail(error));
      });
    }
  };
};

export const handleExportProduct = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyProducts(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllProducts = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyProducts(dataSent, token);
    } catch (error) {}
  };
};
