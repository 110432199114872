import { gql } from "@apollo/client";

export const CREATE_SHIPPING_METHODE = gql`
  mutation CreateShippingMethod($input: CreateShippingMethodInput!) {
    createShippingMethod(input: $input) {
      id
      default_price
      deletedAt
      createdAt
      translationData {
        id
        createdAt
        updatedAt
        name
        description
        languageCode
        rateName
      }
      updatedAt
      weight_limit
    }
  }
`;

export const EDIT_SHIPPING_METHODE = gql`
  mutation UpdateShippingMethod($input: UpdateShippingMethodInput!) {
    updateShippingMethod(input: $input) {
      createdAt
      currency_code
      default_price
      deletedAt
      id
      translationData {
        description
        id
        languageCode
        name
      }
      updatedAt
      weight_limit
    }
  }
`;

export const EDIT_SHIPPING_LINE = gql`
  mutation UpdateShippingLine($input: UpdateShippingLineInput!) {
    updateShippingLine(input: $input) {
      id
      trackingNumber
    }
  }
`;

export const DELETE_SHIPPING_METHODE = gql`
  mutation RemoveShippingMethod($removeShippingMethodId: Int!) {
    removeShippingMethod(id: $removeShippingMethodId)
  }
`;
