import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { constants } from '../constants';

const usePage = (search) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const [page, setPage] = useState(
    +searchParams.get('page') ? +searchParams.get('page') : 1
  );
  const [pageSize, setPageSize] = useState(constants.PAGE_SIZE);

  useEffect(() => {
    setPage(+searchParams.get('page'));
  }, [searchParams]);


  useEffect(() => {
    if (search) {
      setPageSize(constants.PAGE_SIZE);
      setPage(1);
    }
  }, [search]);

  useEffect(() => {
    if (!searchParams.get('page')) {
      setPage(1);
    }
  }, [location.pathname]);

  return { page, setPage, pageSize, setPageSize };
};

export default usePage;
