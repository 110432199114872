import { gql } from "@apollo/client";

export const CREATE_ORDER = gql`
  mutation Mutation($createOrderInput: CreateOrderInput!) {
    createOrder(createOrderInput: $createOrderInput) {
      id
      customer {
        id
      }
      currencyCode
      createdAt
      status
      updatedAt
      subTotal
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation RemoveOrder($removeOrderId: [Int!]!) {
    removeOrder(id: $removeOrderId)
  }
`;

export const EDIT_ORDER_SHIPPING_STATUS = gql`
  mutation UpdateOrderShippingStatus(
    $input: ShippingStatus!
    $updateOrderShippingStatusId: Int!
  ) {
    updateOrderShippingStatus(input: $input, id: $updateOrderShippingStatusId) {
      id
      shippingStatus
    }
  }
`;

export const EDIT_ORDER_PAYMENT_STATUS = gql`
  mutation UpdateCheckout($updateCheckoutInput: UpdateCheckoutInput!) {
    updateCheckout(updateCheckoutInput: $updateCheckoutInput) {
      id
      orderId
      paymentStatus
    }
  }
`;
