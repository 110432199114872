import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { useSelector } from 'react-redux';
import { memo, useEffect, useState } from 'react';
import { regroupWithContinent } from './helper';
import SearchGrid from '../../../Filter/SearchGrid';
import CheckBox from '../../../Inputs/CheckBox';
import { getCountryContinent } from '../../../../utils/countriesDetails';
import { defaultDatat } from '../../../../utils/helpers';

const CountriesSection = memo(({ checked, setChecked }) => {
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  const { country: countriesState } = useSelector((state) => state);
  const [countriesWithcontinent, setCountriesWithcontinent] = useState([]);
  const [searchCountires, setSearchCountires] = useState('');
  const [continentsChecked, setContinentsChecked] = useState([]);

  useEffect(() => {
    if (countriesState.data) {
      let newCoun = regroupWithContinent(countriesState.data, searchCountires);
      setCountriesWithcontinent(newCoun);
    }
  }, [searchCountires, countriesState.data]);

  const updateChecks = (id) => {
    if (checked?.includes(id)) {
      setChecked((current) => current.filter((item) => item !== id));
    } else {
      setChecked((current) => [...current, id]);
    }
  };

  const updateContinentsChecks = (id) => {
    if (continentsChecked.includes(id)) {
      setContinentsChecked((current) => current.filter((item) => item !== id));
      countriesWithcontinent.forEach((item) => {
        if (item.region === id) {
          item.countries.forEach((cou) => {
            setChecked((current) =>
              current.filter((item) => item !== cou.code)
            );
          });
        }
      });
    } else {
      setContinentsChecked((current) => [...current, id]);
      countriesWithcontinent.forEach((item) => {
        if (item.region === id) {
          item.countries.forEach((cou) => {
            if (!checked.includes(cou.code)) {
              setChecked((current) => [...current, cou.code]);
            }
          });
        }
      });
    }
  };

  return (
    <div className='border px-2 pt-2 pb-0 rounded-md mt-2'>
      <div className='sticky top-0 bg-white h-[40px]'>
        <SearchGrid
          placeholder={t('search for countries')}
          value={searchCountires}
          onChange={(e) => setSearchCountires(e.target.value)}
        />
      </div>

      <div className='overflow-scroll flex flex-col gap-3 px-2 relative mt-4 h-[240px]'>
        {countriesWithcontinent.map((coun, index) => (
          <div className='flex flex-col gap-1' key={`${index}-${coun.region}`}>
            <div className='flex items-center text-xl font-semibold py-1 border-b'>
              <CheckBox
                checked={continentsChecked.includes(coun.region)}
                onClick={() => updateContinentsChecks(coun.region)}
                color={bodyStyles?.subText}
                size='25px'
              />
              {getCountryContinent(coun.region)}
            </div>

            <div className='flex flex-col gap-1 px-6 py-2 text-sm'>
              {coun?.countries?.map((item, index) => (
                <div
                  key={item.code}
                  className='flex gap-2 items-center border-b pb-2'
                >
                  <div className='flex gab-1 items-center'>
                    <CheckBox
                      checked={checked.includes(item?.code)}
                      onClick={() => updateChecks(item?.code)}
                      color={bodyStyles?.subText}
                      size='sm'
                    />
                    <img
                      loading='lazy'
                      src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
                      alt=''
                      className='w-[30px] h-[30px] object-contain'
                    />
                  </div>
                  <div className='font-Inter font-semibold'>
                    {defaultDatat(item?.translationData, i18n.language, 'name')}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default CountriesSection;
