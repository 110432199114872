import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation SignUpAdmin($input: CreateUserInput!) {
    signUpAdmin(input: $input) {
      access_token
      refresh_token
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUserData($input: UpdateUserData!) {
    updateUserData(input: $input) {
      id
      email
      customer {
        email
        firstName
        lastName
        id
        phoneNumber
      }
    }
  }
`;

export const UPDATE_STAFF = gql`
  mutation UpdateStaffData($input: UpdateCustomerInput!) {
    updateStaffData(input: $input) {
      id
      email
      customer {
        email
        firstName
        id
        lastName
        phoneNumber
      }
    }
  }
`;

export const SET_ASSIGNMENTS = gql`
  mutation SetAssignments($assignRolesInput: AssignRolesInput!) {
    setAssignments(assignRolesInput: $assignRolesInput) {
      assignments {
        role {
          id
          name
        }
      }
      id
      email
      customer {
        email
        firstName
        id
        lastName
        phoneNumber
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation BlockStaffAccount($blockStaffAccountId: Int!) {
    blockStaffAccount(id: $blockStaffAccountId)
  }
`;
