import React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';

//Material UI
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CsvIcon } from './Svg/MainIcons';
import { useState } from 'react';
// import EditNoteIcon from '@mui/icons-material/EditNote';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DescriptionModal from './Upload/DescriptionModal';

const Handle = SortableHandle(({ tabIndex }) => (
  <div tabIndex={tabIndex} className='h-36 w-full'></div>
));

const SortableItem = SortableElement(
  ({
    value,
    shouldUseDragHandle,
    indexImage,
    images,
    setImages,
    handleDeleteImage,
    single,
  }) => {
    const { i18n } = useTranslation();

    const [description, setDescription] = useState(
      value.imageUrl?.alt ? value.imageUrl?.alt : ''
    );
    const [open, setOpen] = useState(false);

    // console.log('value', value);

    return (
      <>
        {value?.imageUrl?.type === 'text/csv' ? (
          <div
            className={`relative  object-cover border w-full min-h-[120px]
              rounded-lg overflow-hidden flex items-center justify-center 
          ${single ? 'w-full' : 'w-full'}
        `}
          >
            <div
              className={`absolute top-0   ${
                i18n.language === 'ar' ? 'left-0' : 'right-0'
              }`}
              style={{ zIndex: '999' }}
              onClick={() => handleDeleteImage(indexImage)}
            >
              <IconButton>
                <ClearIcon />
              </IconButton>
            </div>
            {shouldUseDragHandle && (
              <div
                className={`absolute top-0  h-36 w-full cursor-move ${
                  i18n.language === 'ar' ? 'right-0' : 'left-0'
                }`}
                style={{ cursor: 'grab' }}
              >
                <Handle />
              </div>
            )}

            <div className='flex flex-col gap-2 my-2 bg-[#eee]'>
              <div className='max-w-[120px] flex justify-center'>
                <CsvIcon color='green' />
              </div>
              <div className='flex justify-center items-center  bg-[#eee]'>
                {value?.imageUrl?.name}
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`relative  aspect-square	object-cover border group
             rounded-lg overflow-hidden max-w-[400px] bg-[#eee]
          ${single ? 'w-full' : 'w-[85px] md:w-[120px]'}
        `}
          > 
            <div
              className={`absolute top-10 left-1 transition-all rounded max-w-[90%] text-ellipsis
             bg-gray-800 p-2 text-[0.64rem] text-white px-1.5 m-1 opacity-95
              ${value.imageUrl?.alt ? 'scale-100' : 'scale-0'} group-hover:scale-100 `}
              onClick={() => setOpen(true)}
            >
              {value.imageUrl?.alt ? value.imageUrl?.alt: 'No descripton !'}
            </div>

            <div
              className={`absolute top-1 z-[4]  bg-[rgba(255,255,255,.3)] hover:bg-[rgba(255,255,255,.5)] duration-300 rounded-full p-0 w-8 h-8 flex justify-center  ${
                i18n.language === 'ar' ? 'left-1' : 'right-1'
              }`}
              onClick={() => handleDeleteImage(indexImage)}
            >
              <IconButton>
                <ClearIcon />
              </IconButton>
            </div>

            {open && (
              <DescriptionModal
                id={value?.imageUrl?.id}
                description={description}
                open={open}
                setOpen={setOpen}
                value={value}
                images={images}
                setImages={setImages}
              />
            )}

            <div
              className={`absolute bottom-1.5 z-[4]  
               duration-300 rounded-full p-0 w-8 h-8 flex justify-center hover:scale-105 ${
                 i18n.language === 'ar' ? 'left-1' : 'right-1'
               }`}
              onClick={() => setOpen(true)}
            >
              <IconButton
                sx={{
                  color: 'black',
                  backgroundColor: '#fff',
                  width: '30px',
                  height: '30px',
                  ':hover': {
                    backgroundColor: '#ffffffec',
                  },
                }}
                aria-label='add to shopping cart'
              >
                <ModeEditIcon />
              </IconButton>
            </div>

            {shouldUseDragHandle && (
              <div
                className={`absolute top-0  h-[120px] w-full cursor-move ${
                  i18n.language === 'ar' ? 'right-0' : 'left-0'
                }`}
                style={{ cursor: 'grab' }}
              >
                <Handle />
              </div>
            )}

            <img
              src={
                typeof value?.imageUrl === 'string'
                  ? value?.imageUrl
                  : value?.imageUrl?.full_url
                  ? value?.imageUrl?.full_url
                  : URL.createObjectURL(value?.imageUrl)
                // ""
                //  value?.imageUrl ? URL.createObjectURL(value?.imageUrl) : ""
              }
              className={`object-contain border h-full w-full
              ${single ? 'object-contain ' : 'object-cover'}
              `}
            />
          </div>
        )}
      </>
    );
  }
);

const SortableList = SortableContainer((props) => {
  const {
    items,
    handleDeleteImage,
    loadingUpload,
    images,
    setImages,
    ...restProps
  } = props;
  return (
    <div className='flex gap-3 flex-wrap w-full '>
      {items.map((item, index) => (
        <SortableItem
          key={`item-${item.id ? item?.id : index}`}
          indexImage={index}
          index={index}
          value={item}
          handleDeleteImage={handleDeleteImage}
          single={props?.single}
          setImages={setImages}
          images={images}
          {...restProps}
        />
      ))}
      {loadingUpload &&
        [...Array(loadingUpload).keys()]?.map((item, index) => (
          <div className='relative h-[250px]' key={index}>
            <Skeleton variant='rectangle' height={144} />
          </div>
        ))}
    </div>
  );
});

export default function App({
  images,
  setImages,
  handleDeleteImage,
  loadingUpload,
  single,
}) {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newOrderImages = arrayMove(images, oldIndex, newIndex);
    setImages(newOrderImages?.map((item) => item.imageUrl));
  };
  const styleContainer = {
    padding: 4,
    overflow: 'hidden',
    border: '2px dashed #eeeeee',
    borderStyle: 'dashed',
    minHeight: '9rem',
    borderRadius: 6,
  };

  return (
    <div className='w-full '>
      <SortableList
        shouldUseDragHandle={true}
        useDragHandle
        axis='xy'
        items={images}
        onSortEnd={onSortEnd}
        handleDeleteImage={handleDeleteImage}
        loadingUpload={loadingUpload}
        single={single}
        images={images}
        setImages={setImages}
      />
    </div>
  );
}
