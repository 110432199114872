import React from 'react';
import BasicButtonWithIcon from '../../Buttons/BasicButtonWithIcon';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const ImportExport = ({
  openImportModal,
  exportData,
  dataPage
}) => {
  return (
    <div className='flex gap-2 items-center sm:pb-2.5 justify-end pt-3 pb-2'>
      {dataPage.importData && (
        <div className='inline-flex rounded-md shadow-sm'>
          <BasicButtonWithIcon
            title='Import'
            icon={<FileUploadIcon />}
            position='self'
            onClick={() => openImportModal()}
          />
        </div>
      )}

      {dataPage.exportData && (
        <div className='inline-flex rounded-md shadow-sm'>
          <BasicButtonWithIcon
            title='Export'
            icon={<FileDownloadIcon />}
            position='self'
            onClick={() => exportData()}
          />
        </div>
      )}
    </div>
  );
};

export default ImportExport;
