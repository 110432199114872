import { gql } from "@apollo/client";

export const GET_ALL_ZONES = gql`
  query Zones($name: String!, $country: Boolean!) {
    zones(name: $name, country: $country) {
      id
      createdAt
      translationData {
        id
        createdAt
        updatedAt
        name
        slug
        description
        languageCode
      }
      updatedAt
      countries {
        code
        continent
        createdAt
        id
        isEnabled
        updatedAt
        translationData {
          createdAt
          description
          id
          languageCode
          slug
          name
          updatedAt
        }
      }
    }
  }
`;
