import { gql } from "@apollo/client";

export const GET_ALL_DISCOUNTS = gql`
query Discounts($offset: Int, $limit: Int, $join: DiscountRelations, $where: DiscountFilterArgs) {
  discounts(offset: $offset, limit: $limit, join: $join, where: $where) {
    hasNextPage
    nodes {
      id
      createdAt
      updatedAt
      couponCode
      startsAt
      endsAt
      perCustomerUsageLimit
      name
      fixedAmount
      status
      percentage
      max_usage
      minimumPurchaseAmount 
      remaining
    }
    totalCount
  }
}
`;

export const GET_DISCOUNT = gql`
  query Discount($discountId: Int!) {
    discount(id: $discountId) {
      couponCode
      createdAt
      endsAt
      fixedAmount
      id
      max_usage
      name
      perCustomerUsageLimit
      startsAt
      percentage
      status
      updatedAt
      minimumPurchaseAmount
    }
  }
`;
