import React, { useContext } from "react";
import DataGrid from "../../DataGrid";
import BasicInput from "../../Inputs/BasicInput";
import { useStateContext } from "../../../contexts/ContextProvider";
import { defaultDatat } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { storeContext } from "../../../global/store";

const ProductOrderDataGrid = ({ products }) => {
    const { t, i18n } = useTranslation();
    const { bodyStyles } = useStateContext();
    const { store } = useContext(storeContext);

  const tableData = [
    {
      title: "",
      key: "Image",
      width: "15%",
      elemnt: function (orderLine) {
        return (
          <div className="col-span-2 flex items-start gap-x-3 py-1 ">
            <div
              style={{
                borderColor: bodyStyles?.borderColor,
              }}
              className=" w-14 h-14 border rounded p-1 flex justify-center items-center"
            >
              <img
                className=" block w-full h-full rounded-md"
                src={
                  orderLine?.variant?.media?.full_url
                    ? orderLine?.variant?.media
                        ?.full_url
                    : orderLine?.variant?.product
                        ?.media[0]?.full_url
                    ? orderLine?.variant?.product
                        ?.media[0]?.full_url
                    : "/images/empty-modal.png"
                }
                alt="product"
              />
            </div>
          </div>
        );
      },
    },
    {
      title: t("Product name"),
      key: "Product name",
      width: "40%",
      elemnt: function (orderLine) {
        return (
          <div className="flex gap-1 flex-col">
            <p className=" text-sm ">
              {orderLine?.variant?.product
                ?.translationData
                ? defaultDatat(
                    orderLine?.variant?.product
                      ?.translationData,
                    i18n.language,
                    "name"
                  )
                : "__"}
            </p>
            <div className="flex items-center gap-[1px] text-xs">
              {orderLine?.variant?.options?.length >
                0 && (
                <div>
                  (
                  {orderLine?.variant?.options?.map(
                    (opt, index) => (
                      <span className="" key={index}>
                        {defaultDatat(
                          opt?.translationData,
                          i18n.language,
                          "name"
                        )}
                        {orderLine?.variant?.options
                          ?.length !=
                          index + 1 && (
                          <span className="px-1">
                            /
                          </span>
                        )}
                      </span>
                    )
                  )}
                  )
                </div>
              )}
            </div>
          </div>
        );
      },
    },

    {
      title: "SKU",
      key: "sku",
      width: "30%",
      elemnt: function (orderLine) {
        return (
          <div className="py-1 break-all">
            {orderLine?.variant?.sku
              ? orderLine?.variant?.sku
              : "-"}
          </div>
        );
      },
    },

    {
      title: t("Price"),
      key: "price",
      width: "30%",
      elemnt: function (orderLine) {
        return (
          <div className="col-span-2 flex items-start gap-x-3 py-1">
            {orderLine?.price
              ? orderLine?.price
              : orderLine?.variant?.pricing
              ? orderLine?.variant?.pricing
              : ""}
          </div>
        );
      },
    },

    {
      title: t("Quantity"),
      key: "quantity",
      width: "30%",
      elemnt: function (orderLine) {
        return (
          <div className="col-span-2 flex items-start gap-x-3 py-1">
            {orderLine?.quantity
              ? orderLine?.quantity
              : 0}
          </div>
        );
      },
    },

    {
      title: `${t("Total")} (${store?.currencyStore?.currencyCode}) `,
      key: "total",
      width: "25%",
      elemnt: function (variant) {
        return (
          <div className="text-bold">
            <BasicInput
              value={(variant.quantity
                ? (variant.quantity
                    ? variant.quantity
                    : 1) *
                  (variant.price
                    ? variant.price
                    : variant?.variant?.pricing
                    ? variant?.variant?.pricing
                    : 0)
                : variant.price
                ? variant.price
                : 0
              )
            
                .toFixed(2)}
              onChange={(e) => {}}
              disabled
              disabledColor=""
              size="small"
              priceFormatSepration="."
              borderNone
              variant="standard"
              InputProps={{
                disableUnderline: true,
                inputProps: {
                  style: {
                    fontSize: "1.2rem",
                  },
                },
              }}
            />
          </div>
        );
      },
    },

  ];
  return <DataGrid data={products} fildesDis={tableData} />;
};

export default ProductOrderDataGrid; 
