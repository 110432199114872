import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { defaultDatat } from '../../utils/helpers';
import PageModal from '../../components/PageModal';
import { useQuery } from '@apollo/client';
import { initInstanceState } from '../../utils/initStates';
import { GET_ORDER } from '../../graphql/query/orders.query';
import ModalNotFound from '../../components/MessageModals/ModalNotFound';
import ProductOrderDataGrid from '../../components/Orders/Order/ProductOrderDataGrid';
import CustomerCard from '../../components/Orders/Order/CustomerCard';
import ShippingCard from '../../components/Orders/Order/ShippingCard';
import PaymnetCard from '../../components/Orders/Order/PaymnetCard';
import PaymentDetails from '../../components/Orders/Order/PaymentDetails';
import { generalData } from '../../utils/general';
import { getInvoice } from '../../services/invoice';
import { Document, Page } from 'react-pdf';
import workerSrc from 'pdfjs-dist/build/pdf.worker.entry';
import { IconButton, Modal, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import BasicButtonWithIcon from '../../components/Buttons/BasicButtonWithIcon';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import PrintIcon from '@mui/icons-material/Print';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { openModelEditShipping } from '../../store/features/order/orderSlicer';
import { useDispatch } from 'react-redux';

const Order = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  const dispatch = useDispatch();
  const [instanceState, setInstanceState] = useState(initInstanceState);
  const [discountOrderModal, setDiscountOrderModal] = useState({
    discountType: 'amount',
    discountValue: 0,
    reason: '',
  });
  const [invoice, setInvoice] = useState({
    pdfString: '',
    blopPdfString: '',
    isPdfFullScreen: '',
    isLoding: true,
    errorFetch: false,
  });

  const [shippingOrderModal, setShippingtOrderModal] = useState({
    shippingType: 'free',
    shippingRate: '',
    shippingValue: 0,
  });

  const [products, setProducts] = useState([]);

  useEffect(() => {
    setInstanceState((prev) => {
      return { ...prev, id: +params?.id };
    });
  }, [params]);

  const {
    loading: loadingInstance,
    error: errorInstance,
    refetch: refetchInstance,
    data: instanceData,
  } = useQuery(GET_ORDER, {
    variables: {
      orderId: instanceState.id,
      join: {
        shippingLine: true,
        orderLine: {
          order: true,
          variant: {
            media: true,
            product: true,
            options: true,
            stockMovement: true,
          },
        },
        discount: true,
        customer: true,
      },
    },
    skip: instanceState.id ? false : true,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (instanceData?.order) {
      setProducts(
        instanceData?.order?.orderLine ? instanceData?.order?.orderLine : []
      );

      setDiscountOrderModal((prev) => ({
        ...prev,
        discountType: instanceData?.order?.discount?.fixedAmount
          ? 'amount'
          : 'percentage',
        discountValue: instanceData?.order?.discount?.fixedAmount
          ? instanceData?.order?.discount?.fixedAmount
          : instanceData?.order?.discount?.percentage,
      }));

      setShippingtOrderModal((prev) => ({
        ...prev,
        shippingType: instanceData?.order?.shippingLine?.shippingCost
          ? defaultDatat(
              instanceData?.order?.shippingMethod?.translationData,
              i18n.language,
              'name'
            )
          : 'free',
        shippingValue: instanceData?.order?.shippingLine?.shippingCost
          ? instanceData?.order?.shippingLine?.shippingCost
          : 0,
      }));
    }
  }, [instanceData]);

  useEffect(async () => {
    if (instanceData?.order?.checkout?.id) {
      setInvoice((prevState) => ({
        ...prevState,
        isLoding: true,
      }));

      let response = await getInvoice(instanceData?.order?.id);
      if (response.data) {
        const reader = new FileReader();

        setInvoice((prevState) => ({
          ...prevState,
          isLoding: false,
          blopPdfString: response.data,
        }));

        reader.onloadend = () => {
          setInvoice((prevState) => ({
            ...prevState,
            pdfString: reader.result,
          }));
        };
        reader.readAsDataURL(response.data);
      } else {
        setInvoice((prevState) => ({
          ...prevState,
          isLoding: false,
        }));
      }
    }
  }, [instanceData]);

  const handlePdfFullScreen = () => {
    setInvoice({ ...invoice, isPdfFullScreen: true });
  };

  const handlePdfClose = () => {
    setInvoice({ ...invoice, isPdfFullScreen: false });
  };

  const handlePdfDownload = () => {
    const link = document.createElement('a');
    link.href = invoice?.pdfString;
    link.download = 'Invoice.pdf';
    link.click();
  };

  const handlePdfPrint = async () => {
    const url = URL.createObjectURL(invoice?.blopPdfString);
    const printWindow = window.open(url);
    printWindow.print();
  };

  const handlePdfNewTap = async () => {
    const url = URL.createObjectURL(invoice?.blopPdfString);
    window.open(url);
  };

  return (
    <PageModal
      pageName={`${t('Order')} #${instanceState.id}`}
      pagePrev={`/orders`}
      loading={loadingInstance}
      component={
        <div className='flex gap-2 items-center pb-1.5'>
          <div className='inline-flex rounded-md shadow-sm'>
            <BasicButtonWithIcon
              title='preview Checkout'
              icon={<VisibilityIcon />}
              position='start'
              onClick={() =>
                window.open(
                  `${generalData.clientSideUrl}/checkout/${instanceData?.order?.checkout?.id}`
                )
              }
            />

            <BasicButtonWithIcon
              title='View Invoice'
              icon={<ReceiptIcon />}
              position='center'
              onClick={handlePdfFullScreen}
              loading={invoice.isLoding}
            />

            <BasicButtonWithIcon
              title='Download'
              icon={<DownloadForOfflineIcon />}
              position='end'
              onClick={handlePdfDownload}
              loading={invoice.isLoding}
            />
          </div>
        </div>
      }
    >
      {invoice?.isPdfFullScreen && invoice?.pdfString && (
        <FullScreenPdfViewer
          open={invoice?.isPdfFullScreen}
          pdfString={invoice?.pdfString}
          onClose={handlePdfClose}
          onDownload={handlePdfDownload}
          onPrint={handlePdfPrint}
          onNew={handlePdfNewTap}
        />
      )}
      {instanceData && !errorInstance ? (
        <form id='form-order-create' className=' font-Inter relative mb-[50px]'>
          <div className='flex gap-5 flex-col md:flex-row  '>
            <div className='w-[100%]  space-y-6 pb-10 lg:pb-20 '>
              <div className=' space-y-2  rounded-lg  lg:px-8 '>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-10 lg:gap-20 px-2 sm:px-0 '>
                  <div className=' w-full flex flex-col gap-6 pt-4 md:col-span-2  '>
                    <div className='flex flex-col gap-4'>
                      <div
                        className='font-Inter text-xl uppercase font-bold flex items-center justify-start'
                        style={{
                          color: bodyStyles.subTitle,
                        }}
                      >
                        <span>{t('Products')}</span>
                      </div>

                      {products?.length > 0 && (
                        <div
                          className='flex flex-col items-start justify-start w-full overflow-x-scroll px-0 pb-2 mobileScrollBar mb-8 '
                          style={{
                            color: bodyStyles?.text ? bodyStyles?.text : '#fff',
                          }}
                        >
                          <ProductOrderDataGrid products={products} />
                        </div>
                      )}

                      <PaymentDetails
                        discountOrderModal={discountOrderModal}
                        shippingOrderModal={shippingOrderModal}
                        order={instanceData?.order}
                      />
                    </div>
                  </div>

                  <div className=' w-full flex flex-col gap-6 pt-4  md:col-span-1 '>
                    {!instanceData?.order?.shippingLine?.shippingMethod &&
                      instanceData?.order?.shippingStatus === 'NOT_PICKED' && (
                        <div className='flex flex-col gap-4'>
                          <div className='flex justify-end  gap-4'>
                            <BasicButtonWithIcon
                              title='Ready to pick up'
                              icon={<Inventory2Icon />}
                              position='self'
                              onClick={() => dispatch(openModelEditShipping())}
                            />
                          </div>
                        </div>
                      )}

                    <PaymnetCard order={instanceData?.order} />

                    <ShippingCard
                      order={instanceData?.order}
                    />

                    <CustomerCard data={instanceData?.order?.customer} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <ModalNotFound
          message='Item Not Found.'
          returnMessage='Return to Orders page'
          action={() => navigate(`/orders`)}
        />
      )}
    </PageModal>
  );
};

export default Order;

const FullScreenPdfViewer = ({
  pdfString,
  onClose,
  open,
  onDownload,
  onPrint,
  onNew,
}) => {
  const { t } = useTranslation();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <div className='fixed inset-0 z-50 flex justify-center w-full'>
        <div
          className='absolute inset-0 bg-black opacity-60'
          onClick={onClose}
        ></div>
        <div className='max-w-[600px] w-full h-full p-2'>
          <div className='w-full h-full relative'>
            <Document file={pdfString} workerSrc={workerSrc}>
              <Page
                pageNumber={1}
                width={windowSize.width < 600 ? windowSize.width : 600}
              />
            </Document>
            <div className='w-full h-[50px] flex justify-center items-center absolute -bottom-1'>
              <div className='bg-gray-50 w-[200px] '>
                <div className='flex justify-center items-center'>
                  <Tooltip title={t('New Tap')}>
                    <IconButton className='hover:bg-gray-200' onClick={onNew}>
                      <OpenInNewIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={t('Close')}>
                    <IconButton className='hover:bg-gray-200' onClick={onClose}>
                      <FullscreenExitIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={t('Download PDF')}>
                    <IconButton
                      className='hover:bg-gray-200'
                      onClick={onDownload}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('Print PDF')}>
                    <IconButton className='hover:bg-gray-200' onClick={onPrint}>
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>

          <div className=' absolute top-0 right-0 m-4 rounded-full bg-[rgba(0,0,0,.3)] group  duration-500'>
            <IconButton className='hover:bg-gray-200 ' onClick={onClose}>
              <CloseIcon className='text-[#eee] group-hover:text-[#fff] ' />
            </IconButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
