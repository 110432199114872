import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { Jodit } from 'jodit';
import { uploadImage } from '../../services/upload';
import { useTranslation } from 'react-i18next';
import TemplatesModal from '../Templates/TemplatesModal';
import { generalData } from '../../utils/general';

const insertImage = (editor, url) => {
  const image = editor.selection.j.createInside.element('img');
  image.setAttribute('src', url);
  editor.selection.insertNode(image);
};

const imageUpload = (editor) => {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async function () {
    const imageFile = input.files ? input.files[0] : null;
    if (!imageFile) {
      return;
    }
    // if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
    //   return;
    // }
    const formData = new FormData();
    formData.append('file', imageFile);
    let res = await uploadImage(imageFile);
    if (res?.data?.id) {
      insertImage(editor, res?.data?.full_url);
      return res?.data?.full_url;
    }
  };
};

const uploadImageButton = () => {
  Jodit.defaultOptions.controls.uploadImage = {
    name: 'Upload image to Cloudinary',
    iconURL: '/icons/image-upload-svgrepo-com.svg',
    // 'https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png',
    exec: (editor) => {
      imageUpload(editor);
    },
  };
};

const uploadTableButton = () => {
  Jodit.defaultOptions.controls.insertTable = {
    name: 'Insert Table',
    iconURL: '/icons/customTable.svg',
    // 'https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png',
    exec: (editor) => {
      insertTableWithImage(editor);
    },
  };
};



const insertTableWithImage = (editor) => {
  const tableHtml = `
    <table style="border-collapse: collapse; width: 100%;">
      <tbody>
        <tr>
          <td style="width: 100%; background-color: rgb(255, 255, 255); border-color: rgb(0, 0, 0);" colspan="4">
            <img src="https://cdn.smartinia.com/lwz-staged//ddc9673e8f205709d410659360d3dd25.webp" width="197" height="51" style="display: block; margin-left: auto; margin-right: auto;">
          </td>
        </tr>
        <tr>
          <td style="width: 24.5833%; border-color: rgb(0, 0, 0); text-align: center;">Lorem</td>
          <td style="width: 27.0787%; border-color: rgb(0, 0, 0); text-align: center;">Lorem</td>
          <td style="width: 22.5505%; border-color: rgb(0, 0, 0); text-align: center;">Lorem</td>
          <td style="width: 25.8165%; border-color: rgb(0, 0, 0); text-align: center;">Lorem</td>
        </tr>
      </tbody>
    </table>
  `;
  editor.selection.insertHTML(tableHtml);
};

const Editor = ({
  placeholder,
  value,

  onChange,
  minHeight,
  selectChange,
  selectTable
}) => {
  const editor = useRef(null);
  const [isSetupButton, setIsSetupButton] = useState(false);



  const { t, i18n } = useTranslation();
  const [openTemplatesModal, setOpenTemplatesModal] = useState(false);

  useEffect(() => {
    uploadImageButton();
    uploadTableButton();
    setIsSetupButton(true);
  }, []);

  useEffect(() => {
    Jodit.defaultOptions.controls.openTemplatesModal = {
      name: 'Open Templates Modal',
      iconURL: '/icons/web-design.png',
      exec: (editor) => {
        setOpenTemplatesModal(true);
      },
    };
    setIsSetupButton(true);
  }, []);

  const allButtons = [
    selectChange ? 'openTemplatesModal' : '',
    selectChange ? '|' : '',
    // selectTable? 'insertTable' : '',
    // selectTable ? '|' : '',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    'font',
    'fontsize',
    'brush',
    'paragraph',
    '|',
    // 'uploadImage',
    // 'copytype',
    'image',
    'video',
    'table',
    'link',
    '|',
    'align',
    'undo',
    'redo',
    '|',
    'fullsize',
    'preview',
  ];

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || 'Start typings...',
      direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
      sizeLG: 700,
      sizeMD: 700,
      sizeSM: 400,
      sizeSM: 400,

      uploader: {
        url: `https://${generalData.backendApi}/upload`,

        prepareData: function (formData) {
          var file = formData.getAll('files[0]')[0];
          formData.delete('files[0]');
          formData.append('image', file);
          formData.delete('path');
          formData.delete('source');
          return formData;
        },

        isSuccess: function (resp) {
          return !resp.error;
        },

        getMessage: function (resp) {
          return resp.msgs.join('\n');
        },

        process: function (resp) {
          return {
            files: resp.files || [],
            path: resp.path,
            baseurl: resp.full_url,
            error: resp.error,
            msg: resp.msg,
          };
        },
        defaultHandlerSuccess: function (data, resp) {
          // this.s.insertImage(data.baseurl);
          this.selection.insertImage(data.baseurl);
        },

        error: function (e) {
          this.e.fire('errorMessage', [e.getMessage(), 'error', 4000]);
        },
      },

      buttons: allButtons.filter((button) => button !== ''),
      buttonsXS: allButtons.filter((button) => button !== ''),
      events: {
        preview: () => {
          const content = editor.value; // Get the current editor content
          // Perform the preview logic using the content
          // console.log('Preview:', content);
        },
      },

      autofocus: false,
      tabIndex: 1,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      // defaultActionOnPaste: 'insert_clear_html',
      beautyHTML: false,
      minHeight: minHeight ? minHeight : 240,
      plugins: {
        paste: {
          insertFromWord: false,
          processPaste: false,
        },
      },
    }),
    [placeholder, isSetupButton, i18n.language]
  );

  const selectTemplate = (item) => {
    selectChange && selectChange(item);
    setOpenTemplatesModal(false);
  };

  return (
    <div>
      <TemplatesModal
        open={openTemplatesModal}
        setOpen={setOpenTemplatesModal}
        action={(item) => selectTemplate(item)}
      />

      <JoditEditor
        ref={editor}
        value={value}
        config={config}
        tabIndex={1} // tabIndex of textarea
        onBlur={onChange} // preferred to use only this option to update the content for performance reasons
        onChange={onChange}
      />
    </div>
  );
};

export default Editor;