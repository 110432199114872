import { constants } from '../constants';
import { client } from '../global/apolloClient';
import {
  CREATE_COLLECTION,
  DELETE_COLLECTION,
  EDIT_COLLECTION,
} from '../graphql/mutation/collection.mutation';
import {
  GET_ALL_COLLECTIONS,
  GET_COLLECTION,
} from '../graphql/query/collections.query';
import {
  createCollectionFail,
  createCollectionStart,
  createCollectionSuccess,
  deleteCollectionFail,
  deleteCollectionStart,
  deleteCollectionSuccess,
  deleteCollectionsFail,
  deleteCollectionsStart,
  deleteCollectionsSuccess,
  editCollectionFail,
  editCollectionStart,
  editCollectionSuccess,
  fetchCollectionsFail,
  fetchCollectionsStart,
  fetchCollectionsSuccess,
  importFail,
  importStart,
  importSuccess,
  removeAll,
} from '../store/features/collection/collectionSlicer';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation } from './executeMutation';
import { uploadImage } from './upload';

const token = getTheTokenFromStorage();

export const createCollection = async (values, accessToken) => {
  return await executeMutation(
    CREATE_COLLECTION,
    [GET_ALL_COLLECTIONS, GET_COLLECTION],
    values,
    accessToken
  );
};

export const editCollection = async (values, accessToken) => {
  return await executeMutation(
    EDIT_COLLECTION,
    [GET_ALL_COLLECTIONS, GET_COLLECTION],
    values,
    accessToken
  );
};

export const deleteCollection = async (values, accessToken) => {
  return await executeMutation(
    DELETE_COLLECTION,
    [GET_ALL_COLLECTIONS, GET_COLLECTION],
    values,
    accessToken
  );
};

export const deleteManyCollection = async (values, accessToken) => {
  return await executeMutation(
    DELETE_COLLECTION,
    [GET_ALL_COLLECTIONS, GET_COLLECTION],
    values,
    accessToken
  );
};

export const fetchCollections = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCollectionsStart());
      const { data } = await client.query({
        query: GET_ALL_COLLECTIONS,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchCollectionsSuccess(data));
    } catch (error) {
      dispatch(fetchCollectionsFail(error));
    }
  };
};

export const handleDeleteCollection = (id) => {
  return async (dispatch) => {
    dispatch(deleteCollectionStart());
    const dataSent = {
      removeCollectionId: parseInt(id),
    };
    try {
      let res = await deleteCollection(dataSent, token);
      if (res?.data?.removeCollection) {
        dispatch(deleteCollectionSuccess(id));
        dispatch(showSuccessMessage('Collection deleted successfully'));
      } else {
        dispatch(deleteCollectionFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteCollectionFail(error));
    }
  };
};

export const handleDeleteManyCollection = (ids) => {
  return async (dispatch) => {
    dispatch(deleteCollectionsStart());
    const dataSent = {
      removeCollectionId: ids.map((id) => parseInt(id)),
    };
    try {
      let res = await deleteManyCollection(dataSent, token);
      if (res?.data?.removeCollections) {
        dispatch(deleteCollectionsSuccess(ids));
        dispatch(showSuccessMessage('Collections deleted successfully'));
      } else {
        dispatch(deleteCollectionsFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteCollectionsFail(error));
    }
  };
};

export const handleCreateCollection = (values, callback) => {
  return async (dispatch) => {
    dispatch(createCollectionStart());

    let imageSent = values.featuredAsset[0]
      ? await uploadImage(values.featuredAsset[0])
      : null;

    let translationDataSent = values.languages
      ? Object.keys(values.languages).map((key) => {
          return {
            name: values.languages[key].name,
            description: values.languages[key].description,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      input: {
        translationData: translationDataSent,
        media: imageSent?.data?.id ? +imageSent?.data?.id : null,
      },
    };

    try {
      const res = await createCollection(dataSent, token);
      if (res?.data?.createCollection) {
        dispatch(createCollectionSuccess(res?.data?.createCollection));
        dispatch(
          showSuccessMessage('this Collection has been successfully created')
        );
      } else {
        dispatch(createCollectionFail(res));
        dispatch(showErrorMessage(res));
      }

      callback && callback(res);
    } catch (error) {
      dispatch(createCollectionFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};


export const handleEditCollection = (values, callback) => {
  return async (dispatch) => {
    dispatch(editCollectionStart());

    let imageSent = values.featuredAsset[0]
      ? values.featuredAsset[0].id
        ? +values.featuredAsset[0].id
        : await uploadImage(values.featuredAsset[0])
      : null;

    let translationDataSent = values.translationData
      ? Object.keys(values.translationData).map((key) => {
          return {
            name: values.translationData[key].name,
            description: values.translationData[key].description,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      input: {
        id: +values.id,

        translationData: translationDataSent,
        media: imageSent?.data?.id
          ? +imageSent?.data?.id
          : imageSent
          ? imageSent
          : null,
      },
    };

    try {
      const res = await editCollection(dataSent, token);
      if (res?.data?.updateCollection) {
        dispatch(editCollectionSuccess(res?.data?.updateCollection));
        dispatch(
          showSuccessMessage('this Collection has been successfully updated')
        );
      } else {
        dispatch(editCollectionFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(editCollectionFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleImportCollections = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyCollection(dataSent, token);
      if (res?.data?.removeCollections) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Collections Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteCollectionsFail(error));
      });
    }
  };
};

export const handleExportCollections = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyCollection(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllCollections = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyCollection(dataSent, token);
    } catch (error) {}
  };
};
