import { useStateContext } from "../contexts/ContextProvider";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TabsType = ({ tabSelect, tabs, onChange }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const {t} = useTranslation()


  const updateStatus = (e, value) => {
    e.preventDefault();
    let newQueryParameters = new URLSearchParams(location.search);
    newQueryParameters.delete("page")
    newQueryParameters.set("status", value);
    setSearchParams(newQueryParameters);
  };

  const { bodyStyles } = useStateContext();

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      minWidth: 0,
      [theme.breakpoints.up("sm")]: {
        minWidth: 0,
      },
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      color: bodyStyles?.subTitle,
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: bodyStyles?.title,
        opacity: 1,
      },
      "&.Mui-selected": {
        color: bodyStyles?.title,
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&.Mui-focusVisible": {
        backgroundColor: bodyStyles?.title,
      },
    })
  );

  return (
    <Tabs
      value={tabSelect}
      onChange={onChange}
      sx={{
        "& .MuiTabs-indicator": {
          backgroundColor: bodyStyles?.primary,
        },
        "& .muiltr-jpln7h-MuiTabs-scroller": {
          height: "41px",
        },
      }}
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      aria-label="scrollable-status-check"
    >
      {tabs?.length > 0 &&
        tabs?.map((item, index) => (
          <AntTab
            value={item.value}
            label={t(item.label)}
            onClick={(e) => {
              updateStatus(e, item.value);
            }}
            key={item.value}
          />
        ))}
    </Tabs>
  );
};

export default TabsType;
