import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useTranslation } from "react-i18next";

const StepLine = ({ data, minY, lineTitle, Unit }) => {
  const { t, i18n } = useTranslation();

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <div className="flex gap-6 w-full flex-wrap">
        {payload.map((entry, index) => (
          <div className="flex gap-2 items-center" key={index}>
            <div
              className="w-[25px] h-[9px] mt-0"
              style={{
                backgroundColor: entry.color,
              }}
            ></div>
            <div className="font-[500] ">{entry.value}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer className={`m-0 p-0 `}>
        <ComposedChart
          width={500}
          height={400}
          data={data}
          className={` 
          ${i18n.language === "ar" ? "-mr-5" : "-ml-5"}
          `}
          orientation={i18n.language === "ar" ? "right" : "left"}
        >
          <CartesianGrid stroke="#f5f5f5" />

          <XAxis
            dataKey="name"
            tickLine={false}
            reversed={i18n.language === "ar" ? true : false}
            // spacing={10}
          />
          {/* <YAxis domain={[-200, 'dataMax']}/> */}
          <YAxis
            domain={[minY, "dataMax"]}
            tickLine={false}
            orientation={i18n.language === "ar" ? "right" : "left"}
            style={{ direction: "ltr" }}
            padding={{ bottom: 10, top:30 }}
            label={{ value: Unit, angle: 0, position: 'insideTop',  top:10 }}
          />
          <Tooltip />

          <Legend
            iconType="wye"
            iconSize={22}
            // width={250}
            align="rect"
            wrapperStyle={{
              paddingLeft: i18n.language !== "ar" && "12%",
              paddingRight: i18n.language === "ar" && "12%",
              paddingTop: "10px",
            }}
            content={renderLegend}
          />

          <Line
            dataKey="product1"
            strokeWidth={3}
            type="step"
            dot={{
              stroke: "#165BAA",
              strokeWidth: 1,
              r: 3.5,
              strokeDasharray: "",
              fill: "#165BAA",
            }}
            stroke="#165BAA"
            fill="#165BAA"
            name={lineTitle}
            reversed={true}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StepLine;
