export const templates = {
  home: [
    {
      name: 'home 000A',
      id: 'home-000A',
      content: `
      <div>
      <style type="text/css">
        .grid {
          display: grid;
          grid-template-columns: 1fr;
          margin-top: 2.75rem;
          margin-bottom: 3.5rem;
        }
        .imageDiv {
          max-width: 200px;
        }
        .title {
          font-size: 1.875rem;
          font-weight: 700;
        }
        .subTitle {
          font-size: 1.25rem;
          font-weight: 600;
        }
        .content {
          font-size: 1rem;
        }
        @media (min-width: 640px) {
          .title {
            font-size: 2.25rem;
          }
          .subTitle {
            font-size: 1.5rem;
          }
        }
        @media (min-width: 420px) {
          .grid {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            margin-top: 4rem;
            margin-bottom: 3.5rem;
          }
        }
        @media (min-width: 768px) {
          .title {
            font-size: 2.5rem;
          }
          .subTitle {
            font-size: 1.875rem;
          }
          .content {
            font-size: 1.125rem;
          }
        }
        @media (min-width: 1024px) {
          .grid {
            grid-template-columns: repeat(4, minmax(0, 1fr));
            margin-top: 4.375rem;
            margin-bottom: 4rem;
          }
          .title {
            font-size: 4rem;
          }
          .subTitle {
            font-size: 2.25rem;
          }
          .content {
            font-size: 1.25rem;
          }
        }
      </style>
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-left: 4px;
        "
      >
        <p
          class="subTitle"
          style="margin-top: 2.75rem; color: #eb1f25; text-align: center"
        >
          Wir begrüssen Sie recht herzlich bei uns!
        </p>
        <div
          class="content"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-opacity: 0.7;
            margin-top: 2rem;
            width: 100%;
            gap: 0.25rem;
            text-align: center;
          "
        >
          <p>
            Auf den folgenden Seiten geben wir Ihnen gerne einen kleinen Überblick
            über unsere Aktivitäten!
          </p>
          <div
            class="grid"
            style="
              width: 100%;
              gap: 1rem;
              max-width: 845px;
              margin-left: auto;
              margin-right: auto;
            "
          >
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="
                    color: transparent;
                    border-radius: 0.25rem;
                    aspect-ratio: 1.5;
                  "
                  src="https://cdn.smartinia.com/lwz//08e85cdc9bb2d113a75fad6dffe59215.jpg"
                  width="200"
                  height="133"
                />
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="
                    color: transparent;
                    border-radius: 0.25rem;
                    aspect-ratio: 1.5;
                  "
                  src="https://cdn.smartinia.com/lwz//1694349dbeea9e58d0cc1ffca59d758f.jpg"
                  width="200"
                  height="133"
                />
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="
                    color: transparent;
                    border-radius: 0.25rem;
                    aspect-ratio: 1.5;
                  "
                  src="https://cdn.smartinia.com/lwz//f20a131d0dd87e989df61b8a2a350b5d.jpg"
                  width="200"
                  height="133"
                />
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="
                    color: transparent;
                    border-radius: 0.25rem;
                    aspect-ratio: 1.5;
                  "
                  src="https://cdn.smartinia.com/lwz//cbf8b9964f3e87c6069620c208396526.jpg"
                  width="200"
                  height="133"
                />
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="
                    color: transparent;
                    border-radius: 0.25rem;
                    aspect-ratio: 1.5;
                  "
                  src="https://cdn.smartinia.com/lwz//90c3246481066749cbf14f12b1c4c63f.jpg"
                  width="200"
                  height="133"
                />
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="
                    color: transparent;
                    border-radius: 0.25rem;
                    aspect-ratio: 1.5;
                  "
                  src="https://cdn.smartinia.com/lwz//d629410081f7ae9ed14aac3063792b9d.jpg"
                  width="200"
                  height="133"
                />
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="
                    color: transparent;
                    border-radius: 0.25rem;
                    aspect-ratio: 1.5;
                  "
                  src="https://cdn.smartinia.com/lwz//5f42a519c63a7b3d86d4b7a8b1ecbe55.jpg"
                  width="200"
                  height="133"
                />
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="
                    color: transparent;
                    border-radius: 0.25rem;
                    aspect-ratio: 1.5;
                  "
                  src="https://cdn.smartinia.com/lwz//ff9647fbd9fc9d879c722605050da05b.jpg"
                  width="200"
                  height="133"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      `,
    },
  ],
  about: [
    {
      name: 'about 000A',
      id: 'about-000A',
      content: `
      <div>
      <style type="text/css">
        .grid {
          display: grid;
          grid-template-columns: 1fr;
          margin-top: 2.75rem;
          margin-bottom: 3.5rem;
        }
        .imageDiv {
          max-width: 300px;
        }
        .title {
          font-size: 1.875rem;
          font-weight: 700;
        }
        .subTitle {
          font-size: 1.25rem;
          font-weight: 600;
        }
        .content {
          font-size: 1rem;
        }
        @media (min-width: 640px) {
          .title {
            font-size: 2.25rem;
          }
          .subTitle {
            font-size: 1.5rem;
          }
        }
        @media (min-width: 768px) {
          .grid {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            margin-top: 4rem;
            margin-bottom: 3.5rem;
          }
          .imageDiv {
            max-width: 342px;
          }
          .title {
            font-size: 2.5rem;
          }
          .subTitle {
            font-size: 1.875rem;
          }
          .content {
            font-size: 1.125rem;
          }
        }
        @media (min-width: 1024px) {
          .grid {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            margin-top: 4.375rem;
            margin-bottom: 4rem;
          }
          .title {
            font-size: 4rem;
          }
          .subTitle {
            font-size: 2.25rem;
          }
          .content {
            font-size: 1.25rem;
          }
        }
      </style>
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-left: 4px;
        "
      >
        <span class="title"> Wir 端ber uns</span>
        <p class="subTitle" style="margin-top: 2.75rem">
          Wir stehen Ihnen mit Rat &amp; Tatgerne zur Verf端gung!
        </p>
        <div
          class="content"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-opacity: 0.7;
            margin-top: 2rem;
            width: 100%;
            gap: 0.25rem;
            text-align: center;
          "
        >
          <p>
            Unser kleines Team von kompetenten Ingenieuren, das sich vor allem auf
            die Themen
          </p>
          <p>
            Trapezblecharbeiten und Dachisolierungen spezialisiert hat, bietet neben
            deren Erstellung auch
          </p>
          <p>
            Gesamtpakete mit Dach, Wand, Lichtkuppeln und Lichtb端nder sowie T端ren
            und Tore an.
          </p>
          <div
            class="grid"
            style="
              width: 100%;
              gap: 1rem;
              max-width: 1061px;
              margin-left: auto;
              margin-right: auto;
            "
          >
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="https://cdn.smartinia.com/lwz-staged//b547e84e9af9cafeb11dee8f2d04b662.webp"
                  width="342"
                  height="195"
                />
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent; border-radius: 0.5rem; aspect-ratio: 1.753;"
                  src="https://cdn.smartinia.com/lwz//ce3d4a3df0789d70739d582ac000eeb2.jpg"
                  width="342"
                  height="195"
                />
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="https://cdn.smartinia.com/lwz-staged//b03f91da27ce43c6eb1427b834025c48.webp"
                  width="342"
                  height="195"
                />
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="https://cdn.smartinia.com/lwz-staged//e2e64a1bcbd2237fe9253e9f3d4e8bdf.webp"
                  width="342"
                  height="195"
                />
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="https://cdn.smartinia.com/lwz-staged//49e2c43ff54f5408b9fe9b5264083df3.webp"
                  width="342"
                  height="195"
                />
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="https://cdn.smartinia.com/lwz-staged//f4b242cb814235136c0f5df0e7330978.webp"
                  width="342"
                  height="195"
                />
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="https://cdn.smartinia.com/lwz-staged//7c318eed02b38f68c5cd2142c33a8fbc.webp"
                  width="342"
                  height="195"
                />
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="https://cdn.smartinia.com/lwz-staged//9b2007ce3f65a552a7abaea18326726c.webp"
                  width="342"
                  height="195"
                />
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <div class="imageDiv">
                <img
                  alt="logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent; border-radius: 0.5rem; aspect-ratio: 1.753;"
                  src="https://cdn.smartinia.com/lwz//a750af4d3a9e98f75e60b1e0258cc29a.jpg"
                  width="342"
                  height="195"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      `,
    },

    {
      name: 'about 01',
      id: 'about-01',
      content: `
      <section style="background-color: #f5f5f5; padding: 30px;">
        <h2 style="font-size: 36px; color: #333; text-align: center; margin-bottom: 20px;">About Us</h2>
        <div style="font-size: 18px; color: #555; line-height: 1.6;">
          <p style="margin-bottom: 20px;">Welcome to [Your Company Name]! We are a passionate team dedicated to providing [your product/service] to customers all over the world.</p>
          
          <p style="margin-bottom: 20px;">At [Your Company Name], we strive for excellence in everything we do. Our mission is to deliver high-quality [product/service] that exceeds our customers' expectations. We believe in innovation, creativity, and continuous improvement to stay at the forefront of our industry.</p>
          
          <p style="margin-bottom: 20px;">Our team consists of talented professionals who are experts in their respective fields. From our skilled designers and developers to our dedicated customer support team, we work together to ensure a seamless experience for our customers.</p>
          
          <p style="margin-bottom: 20px;">Customer satisfaction is at the heart of our business. We value your feedback and continuously strive to enhance our products and services based on your needs. We are committed to providing exceptional customer support, addressing any questions or concerns promptly and effectively.</p>
          
          <p style="margin-bottom: 20px;">In addition to our commitment to our customers, we also prioritize sustainability and social responsibility. We take steps to minimize our environmental impact and contribute positively to the communities we serve.</p>
          
          <p style="margin-bottom: 20px;">Whether you're a long-time customer or new to [Your Company Name], we appreciate your support and trust in our brand. We are excited to embark on this journey with you and look forward to serving you with our top-notch [product/service].</p>
          
          <p style="margin-bottom: 20px;">Thank you for choosing [Your Company Name]. If you have any questions or would like to learn more about us, please don't hesitate to reach out to our team. We are here to assist you!</p>
        </div>
      </section>

      `,
    },

    {
      name: 'about 001',
      id: 'about-001',
      content: `
      <section style="background-color: #f8f8f8; padding: 50px;">
        <h2 style="font-size: 42px; color: #333; text-align: center; margin-bottom: 30px;">Discover Our Story</h2>
        <div style="font-size: 20px; color: #555; line-height: 1.6; text-align: center;">
          <p style="margin-bottom: 30px;">Welcome to [Your Company Name], where imagination meets innovation! We are a team of visionaries, dreamers, and doers who believe in pushing boundaries and creating extraordinary experiences.</p>
          
          <p style="margin-bottom: 30px;">At [Your Company Name], we believe in the power of technology to transform lives and shape the future. Our mission is to inspire and empower individuals to unleash their full potential through our cutting-edge [product/service].</p>
          
          <p style="margin-bottom: 30px;">From our humble beginnings as a small startup, we have grown into a global phenomenon. Our journey has been fueled by a relentless passion for excellence and a commitment to delivering unrivaled quality.</p>
          
          <p style="margin-bottom: 30px;">What sets us apart is our unwavering dedication to innovation. We are constantly pushing the boundaries of what is possible, harnessing the latest technologies and trends to create products that redefine the industry.</p>
          
          <p style="margin-bottom: 30px;">But it's not just about technology; it's about the impact we make on people's lives. We strive to create experiences that inspire, entertain, and connect individuals across the globe.</p>
          
          <p style="margin-bottom: 30px;">Our team is a diverse mix of talented individuals from various backgrounds, united by a shared passion for creativity and problem-solving. Together, we foster a culture of collaboration, where ideas are born and dreams become reality.</p>
          
          <p style="margin-bottom: 30px;">Beyond our commitment to innovation, we also care deeply about sustainability and social responsibility. We actively seek ways to minimize our ecological footprint and contribute positively to the communities we serve.</p>
          
          <p style="margin-bottom: 30px;">We are grateful to our customers and partners who have joined us on this incredible journey. Your support and trust drive us to continuously raise the bar and deliver exceptional experiences.</p>
          
          <p style="margin-bottom: 30px;">Thank you for being a part of our story. We invite you to explore our world of imagination, where limitless possibilities await.</p>
        </div>
      </section>
      `,
    },

    {
      name: 'about 0001',
      id: 'about-0001',
      content: `
      <section style="background-color: #f8f8f8; padding: 50px;">
        <h2 style="font-size: 42px; color: #333; text-align: center; margin-bottom: 30px;">Welcome to [Your Company Name]</h2>
        <div style="font-size: 20px; color: #555; line-height: 1.6; text-align: center;">
          <p style="margin-bottom: 30px;">At [Your Company Name], we are more than just a business – we are a community of innovators, dreamers, and creators. Our journey began [number of years] years ago with a simple yet powerful vision: to revolutionize the world through [product/service].</p>
          
          <p style="margin-bottom: 30px;">Today, we stand at the forefront of [industry/sector], shaping the way [industry-specific impact] is achieved. Our team of [number of employees] talented individuals works tirelessly to push the boundaries of what is possible, always striving to deliver exceptional [product/service] that exceed expectations.</p>
          
          <p style="margin-bottom: 30px;">What drives us is our unwavering commitment to [core value/mission]. We believe in the power of [specific value/mission] to [positive impact], and it is this belief that fuels our relentless pursuit of excellence.</p>
          
          <p style="margin-bottom: 30px;">From our state-of-the-art facilities, we harness the latest advancements in technology, [mention specific technologies/tools], to bring our innovative ideas to life. We take pride in our ability to transform concepts into reality, creating solutions that inspire, empower, and transform lives.</p>
          
          <p style="margin-bottom: 30px;">But our impact goes beyond [industry/sector]. We are committed to making a positive difference in the world. Through [specific initiatives], we actively contribute to [social/environmental cause], striving to create a more [description of desired future].</p>
          
          <p style="margin-bottom: 30px;">Our success is rooted in the collaborative spirit that defines our team. We believe in the power of diverse perspectives, and we foster an inclusive environment where everyone's voice is heard and valued. Together, we celebrate creativity, embrace challenges, and continuously learn and grow.</p>
          
          <p style="margin-bottom: 30px;">We are proud to have served [number of customers] customers worldwide, and we are humbled by the trust they have placed in us. Our customers inspire us to raise the bar higher and to deliver not just products but exceptional experiences that make a lasting impact.</p>
          
          <p style="margin-bottom: 30px;">As we look to the future, our passion for [industry/sector] and our commitment to [core value/mission] remain unwavering. We are excited to embark on new ventures, explore uncharted territories, and continue shaping the [industry/sector] landscape.</p>
          
          <p style="margin-bottom: 30px;">Thank you for joining us on this remarkable journey. Together, let's build a future where [desired future state] becomes a reality.</p>
        </div>
      </section>

      `,
    },
    {
      name: 'about 02',
      id: 'about-02',
      content: `
       <div>
       <section style="background-color: #f8f8f8;">
       <div style="max-width: 800px; margin: 0 auto;">
         <h1 style="text-align: center; font-size: 36px; color: #333; margin-bottom: 30px;">Unleash Your Potential</h1>
     
         <div style="display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; margin-bottom: 40px; gap: 30px;">
           <div style="flex: 1; max-width: 100%;">
             <p style="text-align: justify; font-size: 18px; line-height: 1.6; color: #555;">Welcome to our world of endless possibilities. We are a team of passionate individuals dedicated to helping you unlock your true potential and achieve greatness in all areas of life.</p>
             <p style="text-align: justify; font-size: 18px; line-height: 1.6; color: #555;">Through our innovative products, transformative coaching programs, and vibrant community, we provide you with the tools, guidance, and support needed to embark on a remarkable journey of self-discovery and personal growth.</p>
             <p style="text-align: justify; font-size: 18px; line-height: 1.6; color: #555;">It's time to break free from limitations, overcome obstacles, and embrace your authentic self. Together, let's push boundaries, redefine success, and create a life that's truly extraordinary.</p>
           </div>
           <div style="flex: 1; text-align: center;">
             <img src="https://images.pexels.com/photos/5940716/pexels-photo-5940716.jpeg?auto=compress&cs=tinysrgb&w=800&h=600&dpr=1" alt="Unleash Your Potential Image" style="max-width: 100%; height: auto; border-radius: 10px;">
     
             <div style="text-align: center; margin-top: 20px;">
               <p style="font-size: 20px; line-height: 1.6; color: #555;">Are you ready to embark on a transformative journey?</p>
               <a href="#" style="display: inline-block; padding: 12px 30px; background-color: #333; color: #fff; font-size: 18px; text-decoration: none; border-radius: 30px; margin-top: 20px;">Get Started</a>
             </div>
           </div>
         </div>
       </div>
     </section>
     
     <style>
       @media screen and (max-width: 768px) {
         section > div {
           max-width: 100%;
         }
         section > div > div {
           flex-basis: 100%;
           max-width: 100%;
         }
       }
     
       @media screen and (max-width: 480px) {
        
         section > div {
           padding: 20px;
         }
         section h1 {
           font-size: 30px;
           margin-bottom: 20px;
         }
         section > div > div {
           margin-bottom: 20px;
         }
         section > div > div:last-child {
           margin-bottom: 0;
         }
       </div>
      `,
    },

    {
      name: 'about 03',
      id: 'about-03',
      content: `
        <section style="background-color: #222; padding: 60px 0; color: #fff; text-align: center;">
          <div style="max-width: 800px; margin: 0 auto;">
            <h1 style="font-size: 36px; margin-bottom: 40px;">Ignite Your Passion</h1>
      
            <div style="display: flex; flex-wrap: wrap; justify-content: center; gap: 40px;">
              <div style="flex: 1; max-width: 400px;">
                <p style="font-size: 20px; line-height: 1.6;">Welcome to a world where possibilities come alive. We are here to fuel your dreams, awaken your potential, and ignite your passion for living a remarkable life.</p>
                <p style="font-size: 20px; line-height: 1.6;">Through our innovative approach, cutting-edge solutions, and unwavering support, we empower you to break free from limitations, embrace authenticity, and pursue your wildest aspirations.</p>
                <p style="font-size: 20px; line-height: 1.6;">Together, let's embark on a transformative journey of self-discovery, growth, and extraordinary achievements. Unleash your inner fire and create a legacy that inspires generations to come.</p>
              </div>
              <div style="flex: 1; max-width: 400px;">
                <p style="font-size: 20px; line-height: 1.6;">Join our vibrant community of dreamers, innovators, and change-makers. Together, we form a tapestry of boundless potential, united by a shared vision of making a profound impact in the world.</p>
                <p style="font-size: 20px; line-height: 1.6;">With our transformative programs, mentorship, and unparalleled resources, you'll unleash your unique talents, discover new horizons, and shape a future filled with purpose, abundance, and fulfillment.</p>
                <p style="font-size: 20px; line-height: 1.6;">The time is now. Embrace the extraordinary and let your passion burn brightly. Are you ready to set the world ablaze?</p>
              </div>
            </div>
      
            <div style="margin-top: 60px;">
              <a href="#" style="display: inline-block; padding: 16px 40px; background-color: #ff5f6d; color: #fff; font-size: 24px; text-decoration: none; border-radius: 40px;">Ignite Your Journey</a>
            </div>
          </div>
        </section>
      `,
    },
    {
      name: 'about 04',
      id: 'about-04',
      name: 'about-06',
      content: `
        <section style="background-color: #f9f9f9; padding: 80px 0; text-align: center;">
          <div style="max-width: 800px; margin: 0 auto;">
            <h1 style="font-size: 42px; color: #333; margin-bottom: 40px;">Unleash Your Potential</h1>
      
            <div style="display: flex; flex-wrap: wrap; justify-content: center; align-items: center; gap: 40px;">
              <div style="flex: 1;">
                <p style="font-size: 20px; line-height: 1.6; color: #555;">Welcome to a world of endless possibilities. We are a collective of dreamers, creators, and change-makers dedicated to helping you unlock your true potential and embrace a life of purpose and fulfillment.</p>
                <p style="font-size: 20px; line-height: 1.6; color: #555;">With our innovative programs, transformative experiences, and supportive community, we empower you to break free from limitations, explore your passions, and create a legacy that inspires.</p>
                <p style="font-size: 20px; line-height: 1.6; color: #555;">Together, let's embark on a remarkable journey of self-discovery, growth, and meaningful connections. It's time to unleash your unique gifts, ignite your passions, and make a positive impact in the world.</p>
              </div>
              <div style="flex: 1;">
                <img src="https://images.pexels.com/photos/4197263/pexels-photo-4197263.jpeg?auto=compress&cs=tinysrgb&w=800&h=600&dpr=1" alt="Unleash Your Potential Image" style="max-width: 100%; border-radius: 10px;">
              </div>
            </div>
      
            <div style="margin-top: 60px;">
              <a href="#" style="display: inline-block; padding: 18px 40px; background-color: #333; color: #fff; font-size: 24px; text-decoration: none; border-radius: 40px;">Start Your Journey</a>
            </div>
          </div>
        </section>
      `,
    },
    {
      name: 'about 05',
      id: 'imprint-05',
      content: `
    <section style="background-color: #f2f2f2; padding: 20px;">
    <section style="background-color: #fff; padding: 20px; border-radius: 8px; margin-bottom: 20px;">
      <div style="max-width: 1200px; margin: 0 auto;">
        <h1 style="font-size: 36px; margin-bottom: 16px; text-align: center;">All About Target</h1>
  
        <div style="text-align: center;">
          <div>
            <h3 style="font-size: 24px; margin-bottom: 16px;">
              We're here to help all families discover the joy of everyday life.
            </h3>
  
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    </section>
  
    <section style="background-color: #fff; padding: 20px; border-radius: 8px; margin-bottom: 20px;">
      <div style="max-width: 1200px; margin: 0 auto;">
        <div>
          <div>
            <h2 style="font-size: 28px; margin-bottom: 16px;">Fast facts</h2>
  
            <ul style="list-style: disc; padding-left: 20px;">
              <li>
                Target is a general merchandise retailer with stores in all 50 U.S. states and the District of Columbia.
              </li>
              <li>
                75% of the U.S. population lives within 10 miles of a Target store.
                <a style="text-decoration: none; color: #333; font-weight: bold;" href="/about/locations">locations</a>
              </li>
              <li>
                We employ 400,000+ 
                <a target="_blank" style="text-decoration: none; color: #333; font-weight: bold;" href="/corporate-responsibility/team-members">team members</a>
              </li>
              <li>
                Our tagline is "Expect More. Pay Less." We've been using it since 1994!
              </li>
              <li>
                Target's CEO since August 2014 is Brian Cornell.
                <a target="_blank" style="text-decoration: none; color: #333; font-weight: bold;" href="/about/purpose-history/leadership">leadership</a>
              </li>
              <li>
                The Target Corporation also owns Shipt and Roundel. More to love!
              </li>
              <li>
                Target is headquartered in Minneapolis, Minnesota, its hometown since the first Target store opened in 1962 under The Dayton Company.
                <a target="_blank" style="text-decoration: none; color: #333; font-weight: bold;" href="/about/history">history</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  
     </div>
  </section>
  `,
    },

    {
      name: 'about 06',
      id: 'about-06',
      content: `
        <section style="background-color: #f6f6f6; padding: 80px 0; text-align: center;">
          <div style="max-width: 800px; margin: 0 auto;">
            <h1 style="font-size: 48px; color: #333; margin-bottom: 40px;">Unleash Your Inner Maverick</h1>
      
            <div style="display: flex; flex-wrap: wrap; justify-content: center; align-items: center; gap: 40px;">
              <div style="flex: 1;">
                <p style="font-size: 22px; line-height: 1.6; color: #555;">Welcome to a world where conformity is challenged, and creativity thrives. We are a tribe of forward-thinking visionaries dedicated to empowering you to break free from the ordinary and embrace a life of boldness and originality.</p>
                <p style="font-size: 22px; line-height: 1.6; color: #555;">Through our immersive programs, thought-provoking workshops, and vibrant community, we provide you with the tools and inspiration to unleash your inner maverick, challenge boundaries, and make a meaningful impact on the world.</p>
                <p style="font-size: 22px; line-height: 1.6; color: #555;">It's time to embrace your unique voice, unleash your untapped potential, and embark on a transformative journey where you redefine what's possible. Join us and be part of a movement that celebrates authenticity and champions innovation.</p>
              </div>
              <div style="flex: 1;">
                <img src="https://images.pexels.com/photos/6407685/pexels-photo-6407685.jpeg?auto=compress&cs=tinysrgb&w=800&h=600&dpr=1" alt="Unleash Your Inner Maverick Image" style="max-width: 100%; border-radius: 10px;">
              </div>
            </div>
      
            <div style="margin-top: 60px;">
              <a href="#" style="display: inline-block; padding: 20px 40px; background-color: #ff5f6d; color: #fff; font-size: 28px; text-decoration: none; border-radius: 40px;">Join the Maverick Revolution</a>
            </div>
          </div>
        </section>
      `,
    },

    {
      name: 'about 07',
      id: 'about-07',
      content: `
        <section style="background-color: #333; padding: 80px 0; text-align: center;">
          <div style="max-width: 800px; margin: 0 auto;">
            <h1 style="font-size: 48px; color: #fff; margin-bottom: 40px;">Unleash Your Inner Fire</h1>
      
            <div style="display: flex; flex-wrap: wrap-reverse; justify-content: center; align-items: center; gap: 40px;">
              <div style="flex: 1;">
                <img src="https://images.pexels.com/photos/2349202/pexels-photo-2349202.jpeg?auto=compress&cs=tinysrgb&w=800&h=600&dpr=1" alt="Unleash Your Inner Fire Image" style="max-width: 100%; border-radius: 10px;">
              </div>
              <div style="flex: 1;">
                <p style="font-size: 22px; line-height: 1.6; color: #fff;">Welcome to a world where passion ignites and dreams become reality. We are a tribe of fierce souls, determined to help you unleash your inner fire, embrace your purpose, and set ablaze a life of meaning and fulfillment.</p>
                <p style="font-size: 22px; line-height: 1.6; color: #fff;">Through our immersive experiences, transformative workshops, and unwavering support, we empower you to rise above limitations, unlock your unique talents, and make an indelible mark on the world.</p>
                <p style="font-size: 22px; line-height: 1.6; color: #fff;">Join us on this epic journey of self-discovery, where you'll connect with kindred spirits, ignite your passions, and blaze a trail towards the extraordinary. It's time to unleash your inner fire and leave an imprint that will burn brightly for generations to come.</p>
              </div>
            </div>
      
            <div style="margin-top: 60px;">
              <a href="#" style="display: inline-block; padding: 20px 40px; background-color: #ff8b00; color: #fff; font-size: 28px; text-decoration: none; border-radius: 40px;">Ignite Your Potential</a>
            </div>
          </div>
        </section>
      `,
    },
    {
      name: 'about 08',
      id: 'about-08',
      content: `
      <section style="background-color: #f9f9f9; padding: 80px 0; text-align: center;">
      <div style="max-width: 800px; margin: 0 auto;">
        <h1 style="font-size: 48px; color: #333; margin-bottom: 40px;">Unleash Your Inner Fire</h1>
        
        <div style="display: flex; flex-direction: column; align-items: center; gap: 40px;">
          <div style="max-width: 600px;">
            <img src="https://images.pexels.com/photos/2349202/pexels-photo-2349202.jpeg?auto=compress&cs=tinysrgb&w=800&h=600&dpr=1" alt="Unleash Your Inner Fire Image" style="width: 100%; border-radius: 10px; box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);">
          </div>
          <div style="max-width: 600px;">
            <p style="font-size: 22px; line-height: 1.6; color: #333;">Welcome to a world where passion ignites and dreams become reality. We are a tribe of fierce souls, determined to help you unleash your inner fire, embrace your purpose, and set ablaze a life of meaning and fulfillment.</p>
            <p style="font-size: 22px; line-height: 1.6; color: #333;">Through our immersive experiences, transformative workshops, and unwavering support, we empower you to rise above limitations, unlock your unique talents, and make an indelible mark on the world.</p>
            <p style="font-size: 22px; line-height: 1.6; color: #333;">Join us on this epic journey of self-discovery, where you'll connect with kindred spirits, ignite your passions, and blaze a trail towards the extraordinary. It's time to unleash your inner fire and leave an imprint that will burn brightly for generations to come.</p>
          </div>
          <a href="#" style="display: inline-block; padding: 20px 40px; background-color: #ff5252; color: #fff; font-size: 28px; text-decoration: none; border-radius: 40px;">Ignite Your Potential</a>
        </div>
      </div>
      </section>
      `,
    },

    {
      name: 'about 09',
      id: 'about-09',
      content: `
      <section style="background-color: #f9f9f9; padding: 80px 0; text-align: center;">
      <div style="max-width: 800px; margin: 0 auto;">
        <h1 style="font-size: 48px; color: #333; margin-bottom: 40px;">Our Passion for Quality</h1>

        <div style="display: flex; flex-wrap: wrap; justify-content: center; align-items: center; gap: 40px;">
          <div style="flex: 1;">
            <img src="https://images.pexels.com/photos/2349202/pexels-photo-2349202.jpeg?auto=compress&cs=tinysrgb&w=800&h=600&dpr=1" alt="Our Passion for Quality Image" style="max-width: 100%; border-radius: 10px;">
          </div>
          <div style="flex: 1;">
            <p style="font-size: 22px; line-height: 1.6; color: #555;">Welcome to our world of exceptional products and impeccable service. At our core, we are driven by a passion for quality, craftsmanship, and customer satisfaction.</p>
            <p style="font-size: 22px; line-height: 1.6; color: #555;">Every product in our collection is carefully curated, reflecting our commitment to offering the finest selection. From luxurious fabrics to attention to detail, we believe that true beauty lies in the small things that make a big difference.</p>
            <p style="font-size: 22px; line-height: 1.6; color: #555;">With a seamless online shopping experience and a dedicated team, we strive to exceed your expectations at every step. We are here to assist you, answer your questions, and ensure that you find the perfect piece that brings joy and elegance to your life.</p>
          </div>
        </div>

        <div style="margin-top: 60px;">
          <a href="#" style="display: inline-block; padding: 20px 40px; background-color: #ff6699; color: #fff; font-size: 28px; text-decoration: none; border-radius: 40px;">Explore Our Collection</a>
        </div>
      </div>
    </section>
      `,
    },

    {
      name: 'about 10',
      id: 'about-10',
      content: `
      <section style="background-color: #f5f5f5; padding: 60px;">
      <div style="max-width: 800px; margin: 0 auto;">
        <h1 style="text-align: center; font-size: 36px; color: #333; margin-bottom: 30px;">Discover Our Story</h1>
  
        <div style="display: flex; flex-wrap: wrap; gap: 40px;">
          <div style="flex: 1;">
            <img src="https://images.pexels.com/photos/5940709/pexels-photo-5940709.jpeg?auto=compress&cs=tinysrgb&w=800&h=600&dpr=1" alt="Discover Our Story Image" style="max-width: 100%; border-radius: 10px;">
          </div>
          <div style="flex: 1;">
            <p style="font-size: 20px; line-height: 1.6; color: #555;">Welcome to our inspiring journey of growth and transformation. We started with a simple vision: to empower individuals to unlock their full potential and create a life of purpose and fulfillment.</p>
            <p style="font-size: 20px; line-height: 1.6; color: #555;">Through years of dedication, relentless pursuit of excellence, and unwavering commitment to our mission, we have become a trusted partner for thousands of people worldwide.</p>
            <p style="font-size: 20px; line-height: 1.6; color: #555;">Join us on this incredible adventure as we continue to innovate, inspire, and make a positive impact in the lives of individuals and communities around the globe.</p>
          </div>
        </div>
  
        <div style="text-align: center; margin-top: 60px;">
          <a href="#" style="display: inline-block; padding: 16px 40px; background-color: #333; color: #fff; font-size: 24px; text-decoration: none; border-radius: 40px;">Learn More</a>
        </div>
      </div>
    </section>

      `,
    },
    {
      name: 'about 11',
      id: 'about-11',
      content: `
      <section style="background-color: #222; padding: 80px 0; color: #fff; text-align: center;">
      <div style="max-width: 800px; margin: 0 auto;">
        <h1 style="font-size: 42px; margin-bottom: 40px;">Empower Your Journey</h1>
  
        <div style="display: flex; flex-wrap: wrap; justify-content: center; align-items: center; gap: 40px;">
          <div style="flex: 1;">
            <p style="font-size: 22px; line-height: 1.6;">Welcome to a world of endless possibilities. We believe that every individual possesses immense potential, and our mission is to empower you to unleash that power and achieve extraordinary results.</p>
            <p style="font-size: 22px; line-height: 1.6;">With our transformative programs, personalized coaching, and cutting-edge resources, you'll gain the knowledge, skills, and mindset necessary to overcome obstacles, maximize your performance, and create a life of abundance.</p>
            <p style="font-size: 22px; line-height: 1.6;">Join our thriving community of high achievers, visionaries, and game-changers. Together, let's embark on a journey of growth, success, and fulfillment.</p>
          </div>
          <div style="flex: 1;">
            <img src="https://images.pexels.com/photos/5940720/pexels-photo-5940720.jpeg?auto=compress&cs=tinysrgb&w=800&h=600&dpr=1" alt="Empower Your Journey Image" style="max-width: 100%; border-radius: 10px;">
          </div>
        </div>
  
        <div style="margin-top: 60px;">
          <a href="#" style="display: inline-block; padding: 18px 40px; background-color: #ff5f6d; color: #fff; font-size: 26px; text-decoration: none; border-radius: 40px;">Start Your Transformation</a>
        </div>
      </div>
    </section>


      `,
    },

    {
      name: 'about 12',
      id: 'about-12',
      content: `
        <section style="background-color: #ffffff; padding: 80px 0; text-align: center;">
          <div style="max-width: 800px; margin: 0 auto;">
            <h1 style="font-size: 48px; color: #333; margin-bottom: 40px;">Embrace the Extraordinary</h1>
      
            <div style="display: flex; flex-wrap: wrap; justify-content: center; align-items: center; gap: 40px;">
              <div style="flex: 1;">
                <img src="https://images.pexels.com/photos/6126486/pexels-photo-6126486.jpeg?auto=compress&cs=tinysrgb&w=800&h=600&dpr=1" alt="Embrace the Extraordinary Image" style="max-width: 100%; border-radius: 10px;">
              </div>
              <div style="flex: 1;">
                <p style="font-size: 22px; line-height: 1.6; color: #555;">Welcome to a world where mediocrity is left behind. We invite you to embark on a transformative journey where you can unleash your true potential and live a life that defies limits.</p>
                <p style="font-size: 22px; line-height: 1.6; color: #555;">Through our immersive experiences, innovative mindset training, and supportive community, we empower you to tap into your inner brilliance, embrace your unique gifts, and create a legacy of impact and inspiration.</p>
                <p style="font-size: 22px; line-height: 1.6; color: #555;">Now is the time to break free from conformity, step into your greatness, and embrace the extraordinary. Join us in this adventure of self-discovery, growth, and boundless possibilities.</p>
              </div>
            </div>
      
            <div style="margin-top: 60px;">
              <a href="#" style="display: inline-block; padding: 20px 40px; background-color: #ff5f6d; color: #fff; font-size: 28px; text-decoration: none; border-radius: 40px;">Unleash Your Brilliance</a>
            </div>
          </div>
        </section>
      `,
    },
  ],

  imprint: [
    {
      name: 'imprint 000A',
      id: 'imprint-000A',
      content: `
      <div style="display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start; margin-top: 10px; width: 100%; gap: 5px; border: 1px solid #BFBFBF; padding: 8px; font-size: 1.25rem;">
      <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;">
        <span style="color: rgb(235, 31, 37); padding-top: 1px; font-weight: bold; font-size: 1.5rem; line-height: 1.5;">L.u.W. Zorn G.m.b.H.</span>
      </div>
      <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;">
        <a target="_blank" rel="noopener noreferrer" href="https://maps.google.com/maps/dir//Luzia+und+Wolfgang+Zorn+GmbH+Schlettergasse+22+1220+Wien+Autriche/@48.2534436,16.4359351,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x476d06a2950ed2db:0xd45058a775f6fe15">
          <span style="color: rgb(30, 30, 30); padding-top: 1px; font-weight: bold; font-size: 1.5rem; line-height: 1.5;">Schlettergasse 24 1220 Wien</span>
        </a>
      </div>
      <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;">
        <a href="tel:+43 660 1488445" target="_blank" rel="noopener noreferrer">
          <span style="color: rgb(30, 30, 30); padding-top: 1px; font-weight: bold; font-size: 1.5rem; line-height: 1.5;">
            Mobil: +43 (0) 699.17 161 161
          </span>
        </a>
      </div>
      <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;">
        <a href="mailto:office@lwz.at" target="_blank" rel="noopener noreferrer">
          <span style="color: rgb(235, 31, 37); padding-top: 1px; font-weight: bold; font-size: 1.5rem; line-height: 1.5;">
            E-Mail: office@lwz.at
          </span>
        </a>
      </div>
      <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;">
        <div style="color: rgb(30, 30, 30); margin-top: 40px; font-weight: bold; font-size: 1.5rem; line-height: 1.5;">
          ATU: 149 793 08
        </div>
      </div>
      <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;">
        <div style="margin-top: 40px;" class=" flex flex-col md:flex-row items-center justify-center gap-5 md:gap-10">
          <span style="color: rgb(30, 30, 30); font-weight: bold; font-size: 1.5rem; line-height: 1.5;">Angaben gemäß §5 E-Commerce Gesetz</span>
          <img alt="impressum" src="https://cdn.smartinia.com/lwz-staged//22573825f340326d4a2241ab6a40acfe.svg" decoding="async" data-nimg="intrinsic" srcset="https://cdn.smartinia.com/lwz-staged//22573825f340326d4a2241ab6a40acfe.svg 1x, https://cdn.smartinia.com/lwz-staged//22573825f340326d4a2241ab6a40acfe.svg 2x" width="169" height="23">        </div>
      </div>
      <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;">
        <div style="margin-top: 40px;" class=" flex flex-col items-start justify-start gap-5 md:gap-10">
          <span style="color: rgb(30, 30, 30); font-weight: bold; font-size: 1.5rem; line-height: 1.5;">Haftung für Inhalte</span>
          <p style="color: rgb(67, 67, 67); font-size: 1.5rem; line-height: 1.5;">
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Der Benutzer anerkennt den Gebrauch der Websites auf eigene Gefahr, sodass niemanden, der an der Erstellung der Informationen beteiligt war, eine (in-)direkte Haftung für Schäden trifft.
          </p>
        </div>
      </div>
      <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;">
        <div style="margin-top: 40px;" class=" flex flex-col items-start justify-start gap-5 md:gap-10">
          <span style="color: rgb(30, 30, 30); font-weight: bold; font-size: 1.5rem; line-height: 1.5;">Haftung für Links</span>
          <p style="color: rgb(67, 67, 67); font-size: 1.5rem; line-height: 1.5;">
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
          </p>
        </div>
      </div>
    </div>

  `,
    },
    {
      name: 'imprint 01',
      id: 'imprint-01',
      content: `
      <section style="background-color: #f9f9f9; padding: 30px;">
  <h2 style="font-size: 36px; color: #333; text-align: center; margin-bottom: 20px;">Imprint</h2>
  <div style="font-size: 18px; color: #555; line-height: 1.6;">
    <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Company Information</h3>
    <p style="margin-bottom: 20px;">[Your Company Name]</p>
    <p style="margin-bottom: 20px;">[Your Company Address]</p>
    <p style="margin-bottom: 20px;">[City, State, ZIP]</p>
    
    <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Contact Information</h3>
    <p style="margin-bottom: 20px;"><strong>Email:</strong> info@company.com</p>
    <p style="margin-bottom: 20px;"><strong>Phone:</strong> +1 (123) 456-7890</p>
    
    <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Represented by</h3>
    <p style="margin-bottom: 20px;">[Name of the Representative]</p>
    
    <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Registered Information</h3>
    <p style="margin-bottom: 20px;"><strong>Registered Name:</strong> [Your Company Legal Name]</p>
    <p style="margin-bottom: 20px;"><strong>Registered Number:</strong> [Company Registration Number]</p>
    <p style="margin-bottom: 20px;"><strong>VAT Number:</strong> [VAT Number, if applicable]</p>
    
    <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Disclaimer</h3>
    <p style="margin-bottom: 20px;">The information provided on this website is for general informational purposes only. While we make every effort to keep the information up to date and accurate, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
    
    <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Liability for Links</h3>
    <p style="margin-bottom: 20px;">This website may contain links to external websites that are not provided or maintained by us. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
    
    <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Legal Validity</h3>
    <p style="margin-bottom: 20px;">If any provision of this imprint is or becomes invalid, the validity of the remaining provisions shall not be affected.</p>
  </div>
</section>

  `,
    },
    {
      name: 'imprint 02',
      id: 'imprint-02',
      content: `
      <section style="background-color: #f9f9f9; padding: 30px;">
      <h2 style="font-size: 36px; color: #333; text-align: center; margin-bottom: 20px;">Imprint</h2>
      <div style="font-size: 18px; color: #555; line-height: 1.6;">
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Company Information</h3>
        <p style="margin-bottom: 20px;"><strong>Company Name:</strong> [Your Company Name]</p>
        <p style="margin-bottom: 20px;"><strong>Address:</strong> [Your Company Address]</p>
        <p style="margin-bottom: 20px;"><strong>City, State, ZIP:</strong> [City, State, ZIP]</p>
        
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Contact Information</h3>
        <p style="margin-bottom: 20px;"><strong>Email:</strong> info@company.com</p>
        <p style="margin-bottom: 20px;"><strong>Phone:</strong> +1 (123) 456-7890</p>
        
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Represented by</h3>
        <p style="margin-bottom: 20px;"><strong>Representative:</strong> [Name of the Representative]</p>
        
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Registered Information</h3>
        <p style="margin-bottom: 20px;"><strong>Registered Name:</strong> [Your Company Legal Name]</p>
        <p style="margin-bottom: 20px;"><strong>Registered Number:</strong> [Company Registration Number]</p>
        <p style="margin-bottom: 20px;"><strong>VAT Number:</strong> [VAT Number, if applicable]</p>
        
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Disclaimer</h3>
        <p style="margin-bottom: 20px;">The information provided on this website is for general informational purposes only. We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is strictly at your own risk.</p>
        
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Liability for Links</h3>
        <p style="margin-bottom: 20px;">This website may contain links to external websites that are not provided or maintained by us. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
        
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Copyright</h3>
        <p style="margin-bottom: 20px;">All content and materials available on this website, including but not limited to text, graphics, website name, code, images, and logos, are the intellectual property of [Your Company Name] and are protected by applicable copyright and trademark law. Any unauthorized use or reproduction of the materials on this website is prohibited.</p>
        
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Legal Validity</h3>
        <p style="margin-bottom: 20px;">If any provision of this imprint is or becomes invalid, the validity of the remaining provisions shall not be affected.</p>
      </div>
    </section>
    
  `,
    },
    {
      name: 'imprint 03',
      id: 'imprint-03',
      content: `
        <section style="max-width: 800px; margin: 0 auto; text-align: center; color: #444;">
        <h1 style="font-weight: 600; font-size: 28px; padding-bottom: 4px; border-bottom: 4px solid #3182ce;">Imprint</h1>
        <div style="display: flex; flex-wrap: wrap; justify-content: center; margin-top: 20px;">
            <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
                <h3 style="font-weight: 600; font-size: 20px;">ADDRESS</h3>
                <span style="font-size: 14px; color: #3d3d3d;">addressExample 1, A-1230 Vienna</span>
            </div>
            <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
                <h3 style="font-weight: 600; font-size: 20px;">COMPANY</h3>
                <span style="font-size: 14px; color: #3d3d3d;">webSiteExampleM Ltd</span>
            </div>
            <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
                <h3 style="font-weight: 600; font-size: 20px;">TELEPHONE</h3>
                <span style="font-size: 14px; color: #3d3d3d;">+43 *** *** 86 28</span>
            </div>
            <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
                <h3 style="font-weight: 600; font-size: 20px;">COMPANY NUMBER</h3>
                <span style="font-size: 14px; color: #3d3d3d;">FN 54********-*</span>
            </div>
            <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
                <h3 style="font-weight: 600; font-size: 20px;">MOBILE</h3>
                <span style="font-size: 14px; color: #3d3d3d;">+43 *** *** 88 88</span>
            </div>
            <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
                <h3 style="font-weight: 600; font-size: 20px;">COMPANY BOOK COURT</h3>
                <span style="font-size: 14px; color: #3d3d3d;">Vienna --  ---</span>
            </div>
            <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
                <h3 style="font-weight: 600; font-size: 20px;">E-MAIL</h3>
                <span style="font-size: 14px; color: #3d3d3d;">info@webSiteExample-excel.at</span>
            </div>
            <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
                <h3 style="font-weight: 600; font-size: 20px;">VAT ID</h3>
                <span style="font-size: 14px; color: #3d3d3d;">ATU**********</span>
            </div>
            <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
                <h3 style="font-weight: 600; font-size: 20px;">INTERNET</h3>
                <a href="www.webSiteExample-excel.at" target="_blank" style="font-size: 14px; color: #3182ce; text-decoration: underline;">www.webSiteExample-excel.at</a>
            </div>
            <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
                <h3 style="font-weight: 600; font-size: 20px;">WKO LINK</h3>
                <a href="linkexample.com" target="_blank"  style="font-size: 14px; color: #3182ce; text-decoration: underline;">webSiteExampleM GmbH WKO</a>
            </div>
        </div>
        <div style="margin-top: 20px; text-align: left;">
            <p style="font-size: 12px; line-height: 1.5;">Online dispute resolution in accordance with Art. 14 Para. 1 ODR-VO: The European Commission provides a platform for online dispute resolution (OS), which you can find at <a href="http://ec.webSiteExample.eu/consumers/odr/" target="_blank"  style="color: #3182ce; font-weight: 500; word-break: break-all; text-decoration: underline;">http://ec.webSiteExample.eu/consumers/odr/</a>. In addition, our company does not take part in consumer dispute proceedings.</p>
        </div>
        <div style="margin-top: 20px; text-align: left;">
            <h4 style="font-weight: 600; font-size: 20px; color: #4d4d4d;">Privacy Policy:</h4>
            <p style="font-size: 12px; line-height: 1.5;">We collect, process, and use your data only within the framework of the statutory provisions.</p>
            <p style="font-size: 12px; line-height: 1.5;">This privacy policy applies exclusively to the use of the websites offered by us. It does not apply to the websites of other service providers to which we only refer via a link.</p>
            <p style="font-size: 12px; line-height: 1.5;">When using our websites, you remain anonymous as long as you do not voluntarily provide us with personal data.</p>
            <p style="font-size: 12px; line-height: 1.5;">Personal data is only collected if necessary for the use of the services offered on the website, particularly forms. We treat the data you provide as strictly confidential.</p>
            <p style="font-size: 12px; line-height: 1.5;">We will not pass on any personal data without your express consent unless we are legally obliged to do so. However, please note that when data is transmitted over the Internet, it is always possible for third parties to intercept or manipulate your data.</p>
            <p style="font-size: 12px; line-height: 1.5;">If you request it, we will give you access to the data stored about you or delete it. If you want to correct, delete, or view data, a letter to the address given in the imprint is sufficient.</p>
            <p style="font-size: 12px; line-height: 1.5;">As part of notifying our customers about new products, etc., we will only send you emails if you provide us with your email address.</p>
            <p style="font-size: 12px; line-height: 1.5;">This website uses Google Analytics, a web analytics service provided by Google Inc. ('Google'). Google Analytics uses cookies, which are text files stored on your computer, to help analyze your use of the website. The information generated by the cookie about your use of this website (including your IP address) is transmitted to a Google server in the USA and stored there. Google will use this information to evaluate your use of the website, compile reports on website activity for website operators, and provide other services related to website activity and internet usage. Google may also transfer this information to third parties if required by law or if third parties process the data on behalf of Google. Google will not associate your IP address with any other data held by Google. You can refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do this, you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>
        </div>
    </section>
  `,
    },
    {
      name: 'imprint 04',
      id: 'imprint-04',
      content: `
      <section style="max-width: 800px; margin: 0 auto; text-align: center; color: #555;">
      <h1 style="font-weight: 600; font-size: 32px; padding-bottom: 8px; border-bottom: 2px solid #3182ce;">Imprint</h1>
      <div style="display: flex; flex-wrap: wrap; justify-content: center; margin-top: 20px;">
          <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
              <h3 style="font-weight: 600; font-size: 24px;">ADDRESS</h3>
              <span style="font-size: 16px; color: #444;">addressExample 1, A-1230 Vienna</span>
          </div>
          <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
              <h3 style="font-weight: 600; font-size: 24px;">COMPANY</h3>
              <span style="font-size: 16px; color: #444;">webSiteExampleM Ltd</span>
          </div>
          <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
              <h3 style="font-weight: 600; font-size: 24px;">TELEPHONE</h3>
              <span style="font-size: 16px; color: #444;">+43 *** *** 86 28</span>
          </div>
          <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
              <h3 style="font-weight: 600; font-size: 24px;">COMPANY NUMBER</h3>
              <span style="font-size: 16px; color: #444;">FN 54********-*</span>
          </div>
          <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
              <h3 style="font-weight: 600; font-size: 24px;">MOBILE</h3>
              <span style="font-size: 16px; color: #444;">+43 *** *** 88 88</span>
          </div>
          <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
              <h3 style="font-weight: 600; font-size: 24px;">COMPANY BOOK COURT</h3>
              <span style="font-size: 16px; color: #444;">Vienna --  ---</span>
          </div>
          <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
              <h3 style="font-weight: 600; font-size: 24px;">E-MAIL</h3>
              <span style="font-size: 16px; color: #444;">info@webSiteExample-excel.at</span>
          </div>
          <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
              <h3 style="font-weight: 600; font-size: 24px;">VAT ID</h3>
              <span style="font-size: 16px; color: #444;">ATU**********</span>
          </div>
          <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
              <h3 style="font-weight: 600; font-size: 24px;">INTERNET</h3>
              <a href="www.webSiteExample-excel.at" target="_blank" style="font-size: 16px; color: #3182ce; text-decoration: underline;">www.webSiteExample-excel.at</a>
          </div>
          <div style="flex: 1 1 50%; max-width: 400px; text-align: left; margin-bottom: 16px;">
              <h3 style="font-weight: 600; font-size: 24px;">WKO LINK</h3>
              <a href="linkexample.com" target="_blank"  style="font-size: 16px; color: #3182ce; text-decoration: underline;">webSiteExampleM GmbH WKO</a>
          </div>
      </div>
      <div style="margin-top: 20px; text-align: left;">
          <p style="font-size: 14px; line-height: 1.5;">Online dispute resolution in accordance with Art. 14 Para. 1 ODR-VO: The European Commission provides a platform for online dispute resolution (OS), which you can find at <a href="http://ec.webSiteExample.eu/consumers/odr/" target="_blank"  style="color: #3182ce; font-weight: 500; word-break: break-all; text-decoration: underline;">http://ec.webSiteExample.eu/consumers/odr/</a>. In addition, our company does not take part in consumer dispute proceedings.</p>
      </div>
      <div style="margin-top: 20px; text-align: left;">
          <h4 style="font-weight: 600; font-size: 24px; color: #4d4d4d;">Privacy Policy:</h4>
          <p style="font-size: 14px; line-height: 1.5;">We collect, process, and use your data only within the framework of the statutory provisions.</p>
          <p style="font-size: 14px; line-height: 1.5;">This privacy policy applies exclusively to the use of the websites offered by us. It does not apply to the websites of other service providers to which we only refer via a link.</p>
          <p style="font-size: 14px; line-height: 1.5;">When using our websites, you remain anonymous as long as you do not voluntarily provide us with personal data.</p>
          <p style="font-size: 14px; line-height: 1.5;">Personal data is only collected if necessary for the use of the services offered on the website, particularly forms. We treat the data you provide as strictly confidential.</p>
          <p style="font-size: 14px; line-height: 1.5;">We will not pass on any personal data without your express consent unless we are legally obliged to do so. However, please note that when data is transmitted over the Internet, it is always possible for third parties to intercept or manipulate your data.</p>
          <p style="font-size: 14px; line-height: 1.5;">If you request it, we will give you access to the data stored about you or delete it. If you want to correct, delete, or view data, a letter to the address given in the imprint is sufficient.</p>
          <p style="font-size: 14px; line-height: 1.5;">As part of notifying our customers about new products, etc., we will only send you emails if you provide us with your email address.</p>
          <p style="font-size: 14px; line-height: 1.5;">This website uses Google Analytics, a web analytics service provided by Google Inc. ('Google'). Google Analytics uses cookies, which are text files stored on your computer, to help analyze your use of the website. The information generated by the cookie about your use of this website (including your IP address) is transmitted to a Google server in the USA and stored there. Google will use this information to evaluate your use of the website, compile reports on website activity for website operators, and provide other services related to website activity and internet usage. Google may also transfer this information to third parties if required by law or if third parties process the data on behalf of Google. Google will not associate your IP address with any other data held by Google. You can refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do this, you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>
      </div>
  </section>
      `,
    },
  ],

  privacyPolicy: [
    {
      name: 'privacy Policy 000A',
      id: 'privacyPolicy-000A',
      content: `
      <section style="background-color: #ffffff; padding: 30px;">
      <h2 style="font-size: 28px; color: #333; text-align: center; margin-bottom: 20px;">Ihre Privatsphäre ist uns wichtig</h2>
      <div style="font-size: 16px; color: #555; line-height: 1.5;">
        <p style="margin-bottom: 20px;">Bei [Ihr Firmenname] setzen wir uns dafür ein, Ihre Privatsphäre zu schützen und Ihre persönlichen Informationen zu sichern. Diese Datenschutzerklärung erklärt, wie wir Informationen erfassen, verwenden, weitergeben und schützen, wenn Sie mit unserer Website und unseren Diensten interagieren.</p>
        <h3 style="font-size: 20px; color: #333; margin-bottom: 10px;">Welche Informationen erfassen wir?</h3>
        <p style="margin-bottom: 10px;">Wir können verschiedene Arten von Informationen von Ihnen erfassen, einschließlich:</p>
        <ul style="margin-left: 20px;">
          <li>Ihren Namen, Ihre E-Mail-Adresse und Kontaktdaten.</li>
          <li>Informationen, die Sie beim Ausfüllen von Formularen oder Umfragen angeben.</li>
          <li>Zahlungs- und Rechnungsinformationen für Einkäufe.</li>
          <li>Informationen über Ihre Interaktionen mit unserer Website und unseren Diensten.</li>
          <li>Geräte- und Browserinformationen, IP-Adresse und Cookies.</li>
        </ul>
        <h3 style="font-size: 20px; color: #333; margin-bottom: 10px;">Wie verwenden wir Ihre Informationen?</h3>
        <p style="margin-bottom: 10px;">Wir verwenden die erfassten Informationen für verschiedene Zwecke, einschließlich:</p>
        <ul style="margin-left: 20px;">
          <li>Bereitstellung und Personalisierung unserer Dienste, um Ihren Bedürfnissen gerecht zu werden.</li>
          <li>Verarbeitung von Transaktionen und Lieferung von Produkten oder Dienstleistungen.</li>
          <li>Kommunikation mit Ihnen und Beantwortung Ihrer Anfragen.</li>
          <li>Verbesserung unserer Website, Produkte und Dienstleistungen.</li>
          <li>Durchführung von Forschung und Analysen zur Verbesserung unseres Angebots.</li>
          <li>Einhaltung rechtlicher Verpflichtungen und Schutz vor Betrug.</li>
        </ul>
        <h3 style="font-size: 20px; color: #333; margin-bottom: 10px;">Wie schützen wir Ihre Informationen?</h3>
        <p style="margin-bottom: 10px;">Wir setzen Sicherheitsmaßnahmen ein, um die Vertraulichkeit und Integrität Ihrer Informationen zu gewährleisten. Dies beinhaltet die Verwendung von Verschlüsselung, Firewalls und sicheren Protokollen zum Schutz der Datenübertragung und -speicherung. Jedoch ist keine Übertragung oder Speicherung von Daten vollständig sicher, und wir können keine absolute Sicherheit garantieren.</p>
        <h3 style="font-size: 20px; color: #333; margin-bottom: 10px;">Teilen wir Ihre Informationen?</h3>
        <p style="margin-bottom: 10px;">Wir können Ihre Informationen in bestimmten Fällen mit Dritten teilen, wie zum Beispiel:</p>
        <ul style="margin-left: 20px;">
          <li>Dienstleister, die bei der Bereitstellung unserer Produkte und Dienstleistungen unterstützen.</li>
          <li>Rechts- und Aufsichtsbehörden, wenn dies gesetzlich vorgeschrieben ist oder zum Schutz unserer Rechte erforderlich ist.</li>
          <li>Geschäftspartner und verbundene Unternehmen zu Marketing- und Werbezwecken (mit Ihrer Zustimmung).</li>
        </ul>
        <p style="margin-bottom: 20px;">Wir verkaufen, handeln oder vermieten Ihre persönlichen Informationen nicht an Dritte zu Marketingzwecken.</p>
        <h3 style="font-size: 20px; color: #333; margin-bottom: 10px;">Ihre Auswahlmöglichkeiten und Rechte</h3>
        <p style="margin-bottom: 10px;">Sie haben bestimmte Rechte in Bezug auf Ihre persönlichen Informationen, einschließlich des Rechts auf:</p>
        <ul style="margin-left: 20px;">
          <li>Zugriff auf Ihre Informationen, Aktualisierung und Korrektur.</li>
          <li>Austragen aus dem Erhalt von Marketingkommunikationen.</li>
          <li>Anforderung der Löschung Ihrer Informationen (vorbehaltlich gesetzlicher Anforderungen).</li>
        </ul>
        <p style="margin-bottom: 20px;">Um Ihre Rechte auszuüben oder mehr über unsere Datenschutzpraktiken zu erfahren, kontaktieren Sie bitte unsere Datenschutzabteilung unter den unten angegebenen Kontaktdaten:</p>
        <p style="margin-bottom: 10px;"><strong>E-Mail:</strong> privacy@company.com</p>
        <p style="margin-bottom: 10px;"><strong>Telefon:</strong> +1 (123) 456-7890</p>
        <p style="margin-bottom: 0;"><strong>Adresse:</strong> 123 Privacy Street, Stadt, PLZ</p>
      </div>
    </section>
    
      `,
    },
    {
      name: 'privacy Policy 01',
      id: 'privacyPolicy-01',
      content: `
      <section style="background-color: #ffffff; padding: 30px;">
      <h2 style="font-size: 28px; color: #333; text-align: center; margin-bottom: 20px;">Your Privacy Matters to Us</h2>
      <div style="font-size: 16px; color: #555; line-height: 1.5;">
        <p style="margin-bottom: 20px;">At [Your Company Name], we are dedicated to protecting your privacy and safeguarding your personal information. This Privacy Policy explains how we collect, use, disclose, and secure your information when you interact with our website and services.</p>
        <h3 style="font-size: 20px; color: #333; margin-bottom: 10px;">What Information Do We Collect?</h3>
        <p style="margin-bottom: 10px;">We may collect various types of information from you, including:</p>
        <ul style="margin-left: 20px;">
          <li>Your name, email address, and contact details.</li>
          <li>Information you provide when filling out forms or surveys.</li>
          <li>Payment and billing information for purchases.</li>
          <li>Information about your interactions with our website and services.</li>
          <li>Device and browser information, IP address, and cookies.</li>
        </ul>
        <h3 style="font-size: 20px; color: #333; margin-bottom: 10px;">How Do We Use Your Information?</h3>
        <p style="margin-bottom: 10px;">We use the collected information for various purposes, including:</p>
        <ul style="margin-left: 20px;">
          <li>Providing and personalizing our services to meet your needs.</li>
          <li>Processing transactions and delivering products or services.</li>
          <li>Communicating with you and responding to your inquiries.</li>
          <li>Improving our website, products, and services.</li>
          <li>Conducting research and analysis to enhance our offerings.</li>
          <li>Complying with legal obligations and protecting against fraud.</li>
        </ul>
        <h3 style="font-size: 20px; color: #333; margin-bottom: 10px;">How Do We Protect Your Information?</h3>
        <p style="margin-bottom: 10px;">We implement security measures to ensure the confidentiality and integrity of your information. This includes using encryption, firewalls, and secure protocols to protect data transmission and storage. However, no method of transmission or storage is entirely secure, and we cannot guarantee absolute security.</p>
        <h3 style="font-size: 20px; color: #333; margin-bottom: 10px;">Do We Share Your Information?</h3>
        <p style="margin-bottom: 10px;">We may share your information with third parties in certain circumstances, such as:</p>
        <ul style="margin-left: 20px;">
          <li>Service providers who assist in delivering our products and services.</li>
          <li>Legal and regulatory authorities when required by law or to protect our rights.</li>
          <li>Business partners and affiliates for marketing and promotional purposes (with your consent).</li>
        </ul>
        <p style="margin-bottom: 20px;">We do not sell, trade, or rent your personal information to third parties for their marketing purposes.</p>
        <h3 style="font-size: 20px; color: #333; margin-bottom: 10px;">Your Choices and Rights</h3>
        <p style="margin-bottom: 10px;">You have certain rights regarding your personal information, including the right to:</p>
        <ul style="margin-left: 20px;">
          <li>Access, update, and correct your information.</li>
          <li>Opt-out of receiving marketing communications.</li>
          <li>Request the deletion of your information (subject to legal requirements).</li>
        </ul>
        <p style="margin-bottom: 20px;">To exercise your rights or learn more about our privacy practices, please contact our Privacy Department using the contact details provided below:</p>
        <p style="margin-bottom: 10px;"><strong>Email:</strong> privacy@company.com</p>
        <p style="margin-bottom: 10px;"><strong>Phone:</strong> +1 (123) 456-7890</p>
        <p style="margin-bottom: 0;"><strong>Address:</strong> 123 Privacy Street, City, State, ZIP</p>
      </div>
    </section>
    
    
      `,
    },

    {
      name: 'privacy Policy 04',
      id: 'privacyPolicy-04',
      content: `
        <section style="background-color: #f9f9f9; padding: 30px;">
          <h2 style="font-size: 32px; color: #333; text-align: center; margin-bottom: 20px;">Protecting Your Privacy</h2>
          <div style="font-size: 18px; color: #555; line-height: 1.6;">
            <p style="margin-bottom: 20px;">At [Your Company Name], we prioritize the privacy and security of our users. We are committed to protecting the personal information you share with us. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our services.</p>
            <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">What Information Do We Collect?</h3>
            <p style="margin-bottom: 10px;">When you interact with our website and services, we may collect the following information:</p>
            <ul style="margin-left: 20px;">
              <li>Your name, email address, and contact details.</li>
              <li>Demographic information such as age, gender, and location.</li>
              <li>Information provided when you fill out forms or surveys.</li>
              <li>Usage data, including IP address, browser type, and device identifiers.</li>
              <li>Information collected through cookies and similar technologies.</li>
            </ul>
            <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">How Do We Use Your Information?</h3>
            <p style="margin-bottom: 10px;">We use the collected information for the following purposes:</p>
            <ul style="margin-left: 20px;">
              <li>Providing and personalizing our services to meet your needs.</li>
              <li>Improving our website and enhancing user experience.</li>
              <li>Analyzing data to understand trends and user preferences.</li>
              <li>Sending relevant communications and updates about our services.</li>
              <li>Conducting research and development to innovate and expand our offerings.</li>
            </ul>
            <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">How Do We Protect Your Information?</h3>
            <p style="margin-bottom: 10px;">We have implemented robust security measures to ensure the confidentiality and integrity of your data. These measures include:</p>
            <ul style="margin-left: 20px;">
              <li>Encryption of data during transmission and storage.</li>
              <li>Regular security assessments and vulnerability testing.</li>
              <li>Restricted access to personal information on a need-to-know basis.</li>
              <li>Monitoring and auditing of our systems to detect and prevent unauthorized access.</li>
            </ul>
            <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Do We Share Your Information?</h3>
            <p style="margin-bottom: 10px;">We do not sell, trade, or rent your personal information to third parties without your consent. However, we may share your data in the following circumstances:</p>
            <ul style="margin-left: 20px;">
              <li>With trusted partners and service providers who assist us in delivering our services.</li>
              <li>In response to legal requests and to comply with applicable laws and regulations.</li>
              <li>In the event of a merger, acquisition, or sale of our company, your information may be transferred to the new owners.</li>
            </ul>
            <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Your Privacy Rights</h3>
            <p style="margin-bottom: 20px;">You have the right to access, update, and correct your personal information. If you wish to exercise these rights or have any questions regarding our privacy practices, please contact us using the information provided below.</p>
            <p style="margin-bottom: 10px;"><strong>Email:</strong> privacy@company.com</p>
            <p style="margin-bottom: 10px;"><strong>Phone:</strong> +1 (123) 456-7890</p>
            <p style="margin-bottom: 0;"><strong>Address:</strong> 123 Privacy Street, City, State, ZIP</p>
          </div>
        </section>
    
    
      `,
    },
    {
      name: 'privacy Policy 02',
      id: 'privacyPolicy-02',
      content: `
      <section style="max-width: 1200px; margin: 0 auto; text-align: center; padding-bottom: 4rem; font-family: DM, Arial, sans-serif; color: #333;">
        <h1 style="font-weight: bold; font-size: 24px; margin-bottom: 2rem; border-bottom: 4px solid #333;">Privacy Policy</h1>
        
        <div style="display: flex; flex-direction: column; gap: 2rem; font-size: 16px; padding-top: 2.5rem;">
          <div style="padding-top: 0.125rem; display: flex; flex-direction: column; gap: 1rem; text-align: left;">
            <p style="text-align: justify;">We collect, process and use your data only within the framework of the statutory provisions.</p>
            <p style="text-align: justify;">This data protection declaration applies exclusively to the use of the websites offered by us. It does not apply to the websites of other service providers to which we only refer via a link.</p>
            <p style="text-align: justify;">When using our websites, you remain anonymous as long as you do not voluntarily provide us with personal data.</p>
            <p style="text-align: justify;">Personal data is only collected if this is necessary for the use of the services offered on the website, in particular forms. We will treat the data you provide as strictly confidential.</p>
            <p style="text-align: justify;">We will not pass on any personal data without your express consent, unless we are legally obliged to do so. However, we would like to point out that when data is transmitted on the Internet, it can always happen that third parties take note of or falsify your data.</p>
            <p style="text-align: justify;">If you request it from us, we will give you access to the data stored about you or delete it. If you want to correct, delete or view data, a letter to the address given in the imprint is sufficient.</p>
            <p style="text-align: justify;">As part of the notification of our customers about new products, etc., we will only send you e-mails if you provide us with your e-mail address.</p>
            <p style="text-align: justify;">This website uses Google Analytics, a web analytics service provided by Google Inc. ('Google'). Google Analytics uses so-called 'cookies', text files that are stored on your computer and enable an analysis of your use of the website. The information generated by the cookie about your use of this website (including your IP address) is transmitted to a Google server in the USA and stored there. Google will use this information to evaluate your use of the website, to compile reports on website activity for website operators and to provide other services related to website activity and internet usage. Google may also transfer this information to third parties if required by law or if third parties process this data on behalf of Google. Under no circumstances will Google associate your IP address with other Google data. You can prevent the installation of cookies by setting your browser software accordingly; however, please note that in this case you may not be able to use all functions of this website in full. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>
          </div>
        </div>
      </section>
      `,
    },

    {
      name: 'privacy Policy 02',
      id: 'privacyPolicy-02',
      content: `
      <section style="background-color: #f8f8f8; padding: 20px;">
      <h2 style="font-size: 24px; color: #333; text-align: center; margin-bottom: 20px;">Your Privacy Rights</h2>
      <div style="font-size: 16px; color: #555; line-height: 1.5;">
        <p>We value your privacy and are committed to protecting your personal information. This section outlines your privacy rights and how you can exercise them:</p>
        <ul style="margin-left: 20px;">
          <li style="margin-bottom: 10px;">You have the right to access and review the personal information we hold about you. If you would like to request a copy of your information, please contact our Privacy Department.</li>
          <li style="margin-bottom: 10px;">You have the right to update or correct any inaccuracies in your personal information. You can do this by logging into your account or contacting our customer support team.</li>
          <li style="margin-bottom: 10px;">You have the right to request the deletion of your personal information. However, please note that certain legal obligations or legitimate business interests may prevent us from immediately deleting your information.</li>
          <li style="margin-bottom: 10px;">You have the right to opt-out of receiving marketing communications from us. You can unsubscribe by clicking the "unsubscribe" link in our emails or by updating your communication preferences in your account settings.</li>
          <li style="margin-bottom: 10px;">You have the right to lodge a complaint with a data protection authority if you believe that we have not complied with applicable data protection laws.</li>
        </ul>
        <p>If you have any questions or would like to exercise your privacy rights, please contact our Privacy Department using the contact details provided below:</p>
        <p style="margin-bottom: 10px;"><strong>Email:</strong> privacy@company.com</p>
        <p style="margin-bottom: 10px;"><strong>Phone:</strong> +1 (123) 456-7890</p>
        <p style="margin-bottom: 0;"><strong>Address:</strong> 123 Privacy Street, City, State, ZIP</p>
      </div>
    </section>
    
      `,
    },

    {
      name: 'privacy Policy 04',
      id: 'privacyPolicy-04',
      content: `
      <section style="font-family: Arial, sans-serif; color: #333;">
      <div>
       
        <dl style="font-size: 16px;">
          <dt></dt>
          <dd>
            <span>
              <p>At Target, we want you to know how we collect, use, share, and protect information about you. By interacting with Target through our stores, websites, mobile application(s), products, and services, you consent to the use of information that is collected or submitted as described in this privacy policy. We may change or add to this privacy policy, so we encourage you to review it periodically.</p>
              <p><a href="#Type" style="font-size: 16px; color: #333; text-decoration: underline;">Types of Information Collected</a></p>
              <p><a href="#InfoCollection" style="font-size: 16px; color: #333; text-decoration: underline;">How is Your Information Collected?</a></p>
              <ul style="font-size: 16px;">
                <li><a href="#YouProvide" style="font-size: 16px; color: #333; text-decoration: underline;">You Provide it to Us</a></li>
                <li><a href="#Auto" style="font-size: 16px; color: #333; text-decoration: underline;">Automated Information Collection</a></li>
                <li><a href="#Third" style="font-size: 16px; color: #333; text-decoration: underline;">Third-party Automated Collection</a></li>
                <li><a href="#Social" style="font-size: 16px; color: #333; text-decoration: underline;">Social Media Widgets</a></li>
                <li><a href="#MLI" style="font-size: 16px; color: #333; text-decoration: underline;">Mobile Location Information</a></li>
                <li><a href="#StoreCam" style="font-size: 16px; color: #333; text-decoration: underline;">Cameras</a></li>
                <li><a href="#InfoOther" style="font-size: 16px; color: #333; text-decoration: underline;">Information from Other Sources</a></li>
              </ul>
              <p><a href="#InfoUsed" style="font-size: 16px; color: #333; text-decoration: underline;">How is Your Information Used?</a></p>
              <ul style="font-size: 16px;">
                <li><a href="#Purposes" style="font-size: 16px; color: #333; text-decoration: underline;">Our Purposes</a></li>
                <li><a href="#Product" style="font-size: 16px; color: #333; text-decoration: underline;">Product and Service Fulfillment</a></li>
                <li><a href="#CC" style="font-size: 16px; color: #333; text-decoration: underline;">Cross-Context</a></li>
                <li><a href="#CDL" style="font-size: 16px; color: #333; text-decoration: underline;">Cross-Device Linking</a></li>
                <li><a href="#IBA" style="font-size: 16px; color: #333; text-decoration: underline;">Interest-Based Advertising</a></li>
                <li><a href="#UserEx" style="font-size: 16px; color: #333; text-decoration: underline;">User Experience Information</a></li>
                <li><a href="#SM" style="font-size: 16px; color: #333; text-decoration: underline;">Social Media</a></li>
                <li><a href="#SMAds" style="font-size: 16px; color: #333; text-decoration: underline;">Social Media Ads</a></li>
                <li><a href="#Prevent" style="font-size: 16px; color: #333; text-decoration: underline;">Prevention of Fraud and Other Harm</a></li>
                <li><a href="#Legal" style="font-size: 16px; color: #333; text-decoration: underline;">Legal Compliance</a></li>
              </ul>
              <p><a href="#InfoShared" style="font-size: 16px; color: #333; text-decoration: underline;">How is Your Information Shared?</a></p>
              <ul style="font-size: 16px;">
                <li><a href="#Subsid" style="font-size: 16px; color: #333; text-decoration: underline;">Target Subsidiaries and Affiliates</a></li>
                <li><a href="#CIB" style="font-size: 16px; color: #333; text-decoration: underline;">Card Issuing Bank</a></li>
                <li><a href="#ServPro" style="font-size: 16px; color: #333; text-decoration: underline;">Service Providers</a></li>
                <li><a href="#RegShop" style="font-size: 16px; color: #333; text-decoration: underline;">Registries and Shopping Lists</a></li>
                <li><a href="#OBP" style="font-size: 16px; color: #333; text-decoration: underline;">Other Businesses Products and Services</a></li>
                <li><a href="#LegalReq" style="font-size: 16px; color: #333; text-decoration: underline;">Legal Requirements</a></li>
                <li><a href="#Else" style="font-size: 16px; color: #333; text-decoration: underline;">Elsewhere at Your Direction</a></li>
                <li><a href="#Sharing" style="font-size: 16px; color: #333; text-decoration: underline;">Sharing Non-identifiable or Aggregate Information with Third Parties</a></li>
                <li><a href="#BT" style="font-size: 16px; color: #333; text-decoration: underline;">Business Transfers</a></li>
              </ul>
              <p><a href="#Choices" style="font-size: 16px; color: #333; text-decoration: underline;">What Choices Do You Have?</a></p>
              <p><a href="#State-Specific Privacy Information" style="font-size: 16px; color: #333; text-decoration: underline;">State-Specific Privacy Information</a></p>
              <ul style="font-size: 16px;">
                <li><a href="#California Residents" style="font-size: 16px; color: #333; text-decoration: underline;">California Residents</a></li>
                <li><a href="#Nevada Residents" style="font-size: 16px; color: #333; text-decoration: underline;">Nevada Residents</a></li>
                <li><a href="#Virginia Residents" style="font-size: 16px; color: #333; text-decoration: underline;">Virginia Residents</a></li>
              </ul>
              <p><a href="#AccessingPersonalInformation" style="font-size: 16px; color: #333; text-decoration: underline;">How do You Access and Update Your Personal Information?</a></p>
              <p><a href="#PersonalInformationSecurity" style="font-size: 16px; color: #333; text-decoration: underline;">How is Your Personal Information Protected?</a></p>
              <p><a href="#ContactTarget" style="font-size: 16px; color: #333; text-decoration: underline;">How do you contact Target?</a></p>
              <p><a href="#TargetPPS" style="font-size: 16px; color: #333; text-decoration: underline;">Target Privacy Policy Scope</a></p>
            </span>
          </dd>
        </dl>
      </div>
    </section>
    
      `,
    },
  ],

  termsAndConditions: [
    {
      name: 'terms And Conditions 0000Ab',
      id: 'termsAndConditions-0000Ab',
      content: `
      <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Geistiges Eigentum</h3>
      <p style="margin-bottom: 20px;">Alle Inhalte und Materialien, die auf dieser Website verfügbar sind, einschließlich, aber nicht beschränkt auf Text, Grafiken, Logos, Bilder, Videos und Software, sind Eigentum von [Ihr Firmenname] und durch geltendes geistiges Eigentumsrecht geschützt. Ohne unsere vorherige schriftliche Zustimmung dürfen Sie keine Inhalte verwenden, reproduzieren, modifizieren oder verteilen.</p>
      
      <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Verpflichtungen des Benutzers</h3>
      <p style="margin-bottom: 20px;">Bei der Nutzung dieser Website erklären Sie sich damit einverstanden:</p>
      <ul style="margin-left: 20px;">
        <li>genaue und aktuelle Informationen während des Registrierungsprozesses, sofern zutreffend, bereitzustellen.</li>
        <li>die Vertraulichkeit Ihrer Kontoinformationen zu wahren.</li>
        <li>die Website und ihre Funktionen verantwortungsvoll und rechtmäßig zu nutzen.</li>
        <li>keine Handlungen vorzunehmen, die die Funktionalität der Website stören oder beeinträchtigen könnten.</li>
        <li>keine schädlichen oder unbefugten Inhalte hochzuladen oder zu übermitteln.</li>
      </ul>
      
      <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Haftungsausschluss</h3>
      <p style="margin-bottom: 20px;">Die auf dieser Website bereitgestellten Informationen dienen nur zu allgemeinen Informationszwecken. Wir übernehmen keine Gewähr für die Richtigkeit, Vollständigkeit, Zuverlässigkeit oder Eignung der Informationen. Die Nutzung der Website und das Vertrauen in die Informationen erfolgen ausschließlich auf eigene Gefahr.</p>
      
      <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Links zu Websites Dritter</h3>
      <p style="margin-bottom: 20px;">Diese Website kann Links zu Websites Dritter enthalten, die nicht im Besitz oder unter der Kontrolle von [Ihr Firmenname] stehen. Wir haben keine Kontrolle über den Inhalt, die Datenschutzrichtlinien oder die Praktiken dieser Websites und übernehmen keine Verantwortung dafür. Wir empfehlen Ihnen, die Allgemeinen Geschäftsbedingungen und Datenschutzrichtlinien aller Websites Dritter zu überprüfen, die Sie besuchen.</p>
      
      <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Änderungen und Beendigung</h3>
      <p style="margin-bottom: 20px;">Wir behalten uns das Recht vor, die Website und ihre Dienste jederzeit ohne vorherige Ankündigung zu ändern, auszusetzen oder einzustellen. Wir können auch diese Allgemeinen Geschäftsbedingungen durch Veröffentlichung der aktualisierten Version auf dieser Website ändern. Ihre fortgesetzte Nutzung der Website nach etwaigen Änderungen bedeutet Ihre Zustimmung zu den aktualisierten Bedingungen.</p>
      
      <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Anwendbares Recht</h3>
      <p style="margin-bottom: 20px;">Diese Allgemeinen Geschäftsbedingungen unterliegen den Gesetzen von [Ihr Land/Bundesland] und sind in Übereinstimmung damit auszulegen. Etwaige Streitigkeiten im Zusammenhang mit diesen Bedingungen unterliegen der ausschließlichen Zuständigkeit der Gerichte von [Ihr Land/Bundesland].</p>
      
      <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Kontaktieren Sie uns</h3>
      <p style="margin-bottom: 10px;">Bei Fragen oder Bedenken bezüglich dieser Allgemeinen Geschäftsbedingungen können Sie uns über die unten angegebenen Informationen kontaktieren:</p>
      <p style="margin-bottom: 10px;"><strong>E-Mail:</strong> info@company.com</p>
      <p style="margin-bottom: 10px;"><strong>Telefon:</strong> +1 (123) 456-7890</p>
      <p style="margin-bottom: 0;"><strong>Adresse:</strong> 123 Terms Street, Stadt, Bundesland, PLZ</p>
    </div>
  </section>
  
    
    
    
      `,
    },
    {
      name: 'terms And Conditions 01',
      id: 'termsAndConditions-01',
      content: `
      <section style="background-color: #f9f9f9; padding: 30px;">
      <h2 style="font-size: 32px; color: #333; text-align: center; margin-bottom: 20px;">Terms and Conditions</h2>
      <div style="font-size: 18px; color: #555; line-height: 1.6;">
        <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Acceptance of Terms</h3>
        <p style="margin-bottom: 20px;">By accessing and using this website, you agree to comply with and be bound by the following terms and conditions. If you do not agree to these terms, please refrain from using this website.</p>
        
        <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Intellectual Property</h3>
        <p style="margin-bottom: 20px;">All content and materials available on this website, including but not limited to text, graphics, logos, images, videos, and software, are the property of [Your Company Name] and are protected by applicable intellectual property laws. You may not use, reproduce, modify, or distribute any of the content without our prior written consent.</p>
        
        <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">User Obligations</h3>
        <p style="margin-bottom: 20px;">When using this website, you agree to:</p>
        <ul style="margin-left: 20px;">
          <li>Provide accurate and current information during the registration process, if applicable.</li>
          <li>Maintain the confidentiality of your account credentials.</li>
          <li>Use the website and its features in a responsible and lawful manner.</li>
          <li>Not engage in any activities that may interfere with or disrupt the website's functionality.</li>
          <li>Not upload or transmit any harmful or unauthorized content.</li>
        </ul>
        
        <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Disclaimer of Liability</h3>
        <p style="margin-bottom: 20px;">The information provided on this website is for general informational purposes only. We make no representations or warranties of any kind, express or implied, regarding the accuracy, completeness, reliability, or suitability of the information. Your use of the website and reliance on the information is solely at your own risk.</p>
        
        <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Links to Third-Party Websites</h3>
        <p style="margin-bottom: 20px;">This website may contain links to third-party websites that are not owned or controlled by [Your Company Name]. We have no control over the content, privacy policies, or practices of these websites and assume no responsibility for them. We encourage you to review the terms and conditions and privacy policies of any third-party websites you visit.</p>
        
        <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Modifications and Termination</h3>
        <p style="margin-bottom: 20px;">We reserve the right to modify, suspend, or terminate the website and its services at any time without prior notice. We may also modify these terms and conditions by posting the updated version on this website. Your continued use of the website after any modifications will signify your acceptance of the updated terms.</p>
        
        <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Governing Law</h3>
        <p style="margin-bottom: 20px;">These terms and conditions shall be governed by and construed in accordance with the laws of [Your Country/State]. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of [Your Country/State].</p>
        
        <h3 style="font-size: 24px; color: #333; margin-bottom: 10px;">Contact Us</h3>
        <p style="margin-bottom: 10px;">If you have any questions or concerns regarding these terms and conditions, please contact us using the information provided below:</p>
        <p style="margin-bottom: 10px;"><strong>Email:</strong> info@company.com</p>
        <p style="margin-bottom: 10px;"><strong>Phone:</strong> +1 (123) 456-7890</p>
        <p style="margin-bottom: 0;"><strong>Address:</strong> 123 Terms Street, City, State, ZIP</p>
      </div>
    </section>
    
    
    
      `,
    },

    {
      name: 'terms And Conditions 02',
      id: 'termsAndConditions-02',
      content: `
      <section style="background-color: #f5f5f5; padding: 30px;">
      <h2 style="font-size: 36px; color: #333; text-align: center; margin-bottom: 20px;">Terms and Conditions</h2>
      <div style="font-size: 18px; color: #555; line-height: 1.6;">
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Acceptance of Terms</h3>
        <p style="margin-bottom: 20px;">Welcome to [Your Company Name]. These terms and conditions outline the rules and regulations for the use of our website. By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use this website if you do not agree to all the terms and conditions stated on this page.</p>
        
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Intellectual Property</h3>
        <p style="margin-bottom: 20px;">The content, features, and functionality of this website are the exclusive property of [Your Company Name] and are protected by international copyright, trademark, and other intellectual property laws.</p>
        
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">User Obligations</h3>
        <p style="margin-bottom: 20px;">By using this website, you agree to:</p>
        <ul style="margin-left: 20px;">
          <li>Provide accurate, current, and complete information when requested.</li>
          <li>Ensure the confidentiality of your account and password and accept responsibility for all activities that occur under your account.</li>
          <li>Use this website only for lawful purposes and in accordance with these terms and conditions.</li>
          <li>Not engage in any activity that may disrupt or interfere with the proper functioning of the website.</li>
          <li>Not attempt to gain unauthorized access to any portion of the website or any systems or networks associated with the website.</li>
        </ul>
        
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Disclaimer of Liability</h3>
        <p style="margin-bottom: 20px;">The information provided on this website is for general informational purposes only. We make no representations or warranties of any kind, express or implied, regarding the accuracy, reliability, or completeness of any information or content on the website. Your use of the website is at your own risk.</p>
        
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Links to Third-Party Websites</h3>
        <p style="margin-bottom: 20px;">This website may contain links to third-party websites that are not owned or controlled by [Your Company Name]. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites. By using this website, you acknowledge and agree that [Your Company Name] shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any third-party websites.</p>
        
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Changes to the Terms and Conditions</h3>
        <p style="margin-bottom: 20px;">We reserve the right to update or modify these terms and conditions at any time without prior notice. It is your responsibility to review these terms and conditions periodically for any changes. Your continued use of the website after any modifications will constitute your acknowledgment of the modified terms and conditions.</p>
        
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Governing Law and Jurisdiction</h3>
        <p style="margin-bottom: 20px;">These terms and conditions are governed by and construed in accordance with the laws of [Your Country/State]. Any disputes arising under or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of [Your Country/State].</p>
        
        <h3 style="font-size: 28px; color: #333; margin-bottom: 10px;">Contact Us</h3>
        <p style="margin-bottom: 10px;">If you have any questions or concerns regarding these terms and conditions, please contact us using the information provided below:</p>
        <p style="margin-bottom: 10px;"><strong>Email:</strong> info@company.com</p>
        <p style="margin-bottom: 10px;"><strong>Phone:</strong> +1 (123) 456-7890</p>
        <p style="margin-bottom: 0;"><strong>Address:</strong> 123 Terms Street, City, State, ZIP</p>
      </div>
    </section>
    
    
    
      `,
    },
  ],
};

// privacyPolicy
// termsAndConditions
