import { gql } from "@apollo/client";

export const GET_ALL_MENUS = gql`
query Menus($offset: Int, $limit: Int, $where: FilterMenusArgs, $join: MenuRelationsArgs, $sortBy: SortByFilterArgs) {
  menus(offset: $offset, limit: $limit, where: $where, join: $join, sortBy: $sortBy) {
    totalCount
    hasNextPage
    nodes {
      id
      isRoot
      updatedAt
      media {
        alt
        createdAt
        deletedAt
        file_name
        host
        full_url
        id
        original_name
        type
        path
        updatedAt
      }
      translationData {
        id
        createdAt
        updatedAt
        name
        slug
        description
        languageCode
        
      }
      children {
        createdAt
        deletedAt
        id
        isRoot
        updatedAt
        translationData {
          id
          createdAt
          updatedAt
          name
          slug
          description
          languageCode
         
          }
      }
      createdAt
      deletedAt
      collections {
        createdAt
        deletedAt
        id
        updatedAt
        productsCount
        translationData {
          id
          createdAt
          updatedAt
          name
          slug
          description
          languageCode
        }
      }
      parent {
        id
        createdAt
        updatedAt
        deletedAt
        translationData {
          id
          createdAt
          updatedAt
          name
          slug
          description
          languageCode
        }
        isRoot
      }
    }
  }
}
`;


export const GET_MENU = gql`
query Menu($menuId: Int!) {
  menu(id: $menuId) {
    id
    isRoot
    media {
      alt
      createdAt
      deletedAt
      file_name
      host
      full_url
      id
      original_name
      type
      path
      updatedAt
    }
    parent {
      id
      createdAt
      updatedAt
      deletedAt
      translationData {
        id
        createdAt
        updatedAt
        slug
        description
        languageCode
        name
      }
      isRoot
    }
    translationData {
      createdAt
      description
      id
      languageCode
      name
      slug
      updatedAt
    }
    updatedAt
    deletedAt
    createdAt
    collections {
      id
      deletedAt
      createdAt
      translationData {
        createdAt
        description
        id
        name
        languageCode
        slug
        updatedAt
      }
      updatedAt
      productsCount
    }
    children {
      deletedAt
      createdAt
      id
      isRoot
      translationData {
        createdAt
        description
        id
        languageCode
        name
        slug
        updatedAt
      }
      updatedAt
    }
  }
}
`;
