import React from 'react'
import { defaultDatat } from '../../../utils/helpers'
import i18n from "i18next";

const ProductData = ({item}) => {
  return (
    <div className="flex gap-1">
    <div className="W-[20%]">
      <div
        className="flex items-center  p-[1px] 
         h-[45px] w-[45px] "
      >
        <img
          src={
            item?.media && item?.media[0]?.full_url
              ? item?.media[0]?.full_url
              : "/images/empty-modal.png"
          }
          className=" block rounded-md w-8 object-contain"
        />
      </div>
    </div>
    <div
      className={`flex justify-center flex-col text-md  
      `}
    >
      <h2
        className="font-Roboto text-main_text line-clamp-2  text-xs font-semibold
      "
      >
        {item?.name}
        {/* {defaultDatat(item?.translationData, i18n.language, "name")} */}
      </h2>
    </div>
  </div>
  )
}

export default ProductData