import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingDelete: false,
  loadingDeleteAll: false,
  loadingCreate: false,
  loadingEdit: false,
  loadingImport: false,
  data: [],
  error: null,
  errorFetch: null,
  success: null,
  totalCount: 0,
  selectedOne: null,
  selectedMany: [],
  isSelectedMany: false,
  deleteModal: false,
  deleteAllModal: false,
  createModal: false,
  createAllModal: false,
  editModal: false,
  editAllModal: false,
  exportModal: false,
  exportSelectedModal: false,
  importModal: false,
};

export const discountSlice = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    // fetchInstance
    fetchDiscountsStart: (state) => {
      state.loading = true;
    },
    fetchDiscountsSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload?.discounts?.nodes;
      state.totalCount = action.payload?.discounts?.totalCount;
      state.errorFetch = false;
    },
    fetchDiscountsFail: (state, action) => {
      state.loading = false;
      state.data = [];
      state.errorFetch = action.payload;
    },

    // createInstance
    createDiscountStart: (state) => {
      state.loadingCreate = true;
    },
    createDiscountSuccess: (state, action) => {
      state.loadingCreate = false;
      state.data.unshift(action.payload);
      state.success = 'Discount created successfully.';
    },
    createDiscountFail: (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    },

    // editInstance
    editDiscountStart: (state) => {
      state.loadingEdit = true;
    },
    editDiscountSuccess: (state, action) => {
      state.loadingEdit = false;
      state.data = state.data.map((discount) =>
        discount.id === action.payload.id ? action.payload : discount
      );
      state.success = 'Discount updated successfully.';
    },
    editDiscountFail: (state, action) => {
      state.loadingEdit = false;
      state.error = action.payload;
    },

    // delete Instance
    deleteDiscountStart: (state) => {
      state.loadingDelete = true;
    },
    deleteDiscountSuccess: (state, action) => {
      state.loadingDelete = false;
      state.data = state.data.filter(
        (discount) => discount.id !== action.payload
      );
      state.success = 'Discount deleted successfully';
      state.deleteModal = false;
    },
    deleteDiscountFail: (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
      state.deleteModal = false;
    },

    // delete Many Instances
    deleteDiscountsStart: (state) => {
      state.loadingDeleteAll = true;
    },
    deleteDiscountsSuccess: (state, action) => {
      state.loadingDeleteAll = false;
      const deletedIds = action.payload;
      state.data = state.data.filter(
        (discount) => !deletedIds.includes(discount.id)
      );
      state.success = 'Discounts deleted successfully';
      state.deleteAllModal = false;
    },
    deleteDiscountsFail: (state, action) => {
      state.loadingDeleteAll = false;
      state.error = action.payload;
      state.deleteAllModal = false;
    },

    // selectInstance

    selectOne: (state, action) => {
      state.selectedOne = action.payload;
    },

    selectAll: (state, action) => {
      if (state.isSelectedMany) {
        state.selectedMany = [];
        state.isSelectedMany = false;
      } else {
        state?.data?.map((item) => {
          if (!state.selectedMany.includes(item.id)) {
            state.selectedMany = [...state.selectedMany, item.id];
          }
        });
        state.isSelectedMany = true;
      }
    },

    removeAll: (state, action) => {
      state.selectedMany = [];
      state.isSelectedMany = false;
    },

    updateSelected: (state, action) => {
      if (state.selectedMany.includes(action.payload)) {
        state.selectedMany = state.selectedMany.filter((item) => {
          return item !== action.payload;
        });
      } else {
        state.selectedMany = [...state.selectedMany, action.payload];
      }
    },

    // DELETEiNSTANCE

    openModelDelete: (state, action) => {
      state.selectedOne = action.payload;
      state.deleteModal = !state.deleteModal;
    },

    openModelDeleteAll: (state, action) => {
      state.deleteAllModal = !state.deleteAllModal;
    },

    openModelCreate: (state, action) => {
      state.createModal = !state.createModal;
    },

    openModelEdit: (state, action) => {
      state.selectedOne = action.payload;
      state.editModal = !state.editModal;
    },

    // ExportSelected

    openModeleExportAll: (state, action) => {
      state.exportModal = true;
    },

    openModeleExportSelected: (state, action) => {
      state.exportSelectedModal = true;
    },

    // Import

    openModeleImport: (state, action) => {
      state.importModal = !state.importModal;
    },

    importStart: (state) => {
      state.loadingImport = true;
    },
    importSuccess: (state, action) => {
      state.loadingImport = false;
      state.success = 'Discounts Imported successfully';
      state.importModal = false;
    },
    importFail: (state, action) => {
      state.loadingImport = false;
      state.error = action.payload;
      state.importModal = false;
    },

    // CLEAR
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  fetchDiscountsStart,
  fetchDiscountsSuccess,
  fetchDiscountsFail,
  createDiscountStart,
  createDiscountSuccess,
  createDiscountFail,
  editDiscountStart,
  editDiscountSuccess,
  editDiscountFail,
  deleteDiscountStart,
  deleteDiscountSuccess,
  deleteDiscountFail,
  clearMessages,
  updateSelected,
  selectAll,
  selectOne,
  openModelDelete,
  openModelDeleteAll,
  openModeleExportAll,
  openModeleExportSelected,
  deleteDiscountsStart,
  deleteDiscountsSuccess,
  deleteDiscountsFail,
  openModelCreate,
  openModelEdit,
  openModeleImport,
  importStart,
  importSuccess,
  importFail,
  removeAll,
} = discountSlice.actions;

export default discountSlice.reducer;
