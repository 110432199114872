import { constants } from '../constants';
import { client } from '../global/apolloClient';
import {
  CREATE_PAGE,
  DELETE_MANY_PAGE,
  DELETE_PAGE,
  EDIT_PAGE,
} from '../graphql/mutation/pages.mutation';
import { GET_ALL_PAGES, GET_PAGE } from '../graphql/query/pages.query';

import {
  createServiceFail,
  createServiceStart,
  createServiceSuccess,
  deleteServiceFail,
  deleteServiceStart,
  deleteServiceSuccess,
  deleteServicesFail,
  deleteServicesStart,
  deleteServicesSuccess,
  editServiceFail,
  editServiceStart,
  editServiceSuccess,
  fetchServicesFail,
  fetchServicesStart,
  fetchServicesSuccess,
  importFail,
  importStart,
  importSuccess,
  removeAll,
} from '../store/features/service/serviceSlicer';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation, resteCache } from './executeMutation';
import { uploadImage } from './upload';

const token = getTheTokenFromStorage();

export const createService = async (values, accessToken) => {
  return await executeMutation(
    CREATE_PAGE,
    [GET_ALL_PAGES, GET_PAGE],
    values,
    accessToken
  );
};

export const editService = async (values, accessToken) => {
  return await executeMutation(
    EDIT_PAGE,
    [GET_ALL_PAGES],
    values,
    accessToken
  );
};

export const deleteService = async (values, accessToken) => {
  return await executeMutation(
    DELETE_PAGE,
    [GET_ALL_PAGES, GET_PAGE],
    values,
    accessToken
  );
};

export const deleteManyService = async (values, accessToken) => {
  return await executeMutation(
    DELETE_MANY_PAGE,
    [GET_ALL_PAGES, GET_PAGE],
    values,
    accessToken
  );
};

export const fetchServices = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchServicesStart());
      const { data } = await client.query({
        query: GET_ALL_PAGES,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchServicesSuccess(data));
    } catch (error) {
      dispatch(fetchServicesFail(error));
    }
  };
};

export const handleDeleteService = (id) => {
  return async (dispatch) => {
    dispatch(deleteServiceStart());
    const dataSent = {
      removeServiceId: parseInt(id),
    };
    try {
      let res = await deleteService(dataSent, token);
      if (res?.data?.removeService) {
        dispatch(deleteServiceSuccess(id));
        dispatch(showSuccessMessage('Service deleted successfully'));
      } else {
        dispatch(deleteServiceFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteServiceFail(error));
    }
  };
};

export const handleDeleteManyService = (ids) => {
  return async (dispatch) => {
    dispatch(deleteServicesStart());
    const dataSent = {
      ids: ids.map((id) => +id)
    };
    try {
      let res = await deleteManyService(dataSent, token);
      if (res?.data?.removeServices) {
        dispatch(deleteServicesSuccess(ids));
        dispatch(showSuccessMessage('Services deleted successfully'));
        resteCache('pages');

      } else {
        dispatch(deleteServicesFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteServicesFail(error));
    }
  };
};

export const handleCreateService = (values, callback) => {
  return async (dispatch) => {
    dispatch(createServiceStart());

    const allImages = values.images?.length
      ? values.images.map(async (image) => {
          if (image.id) {
            return +image?.id;
          } else {
            let res = await uploadImage(image);
            if (res?.data?.id) {
              return +res?.data?.id;
            }
          }
        })
      : [];

    const dataSent = {
      createPageInput: {
        name: values?.name,
        description: values?.description,
        htmlBody: values?.htmlBody,
        type: values?.type,
        categories: values.categories.map(function (obj) {
          return +obj.id;
        }),
        media: await Promise.all(allImages)
          .then((responses) => {
            return responses;
          })
          .then((r) => r.filter((v) => v)),
      },
    };

    try {
      const res = await createService(dataSent, token);
      if (res?.data?.createPage) {
        dispatch(createServiceSuccess(res?.data?.createPage));
        dispatch(
          showSuccessMessage('this Service has been successfully created')
        );
        resteCache('pages');
      } else {
        dispatch(createServiceFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(createServiceFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleEditService = (values, callback) => {
  return async (dispatch) => {
    dispatch(editServiceStart());

    const allImages = values.images?.length
      ? values.images.map(async (image) => {
          if (image.id) {
            return +image?.id;
          } else {
            let res = await uploadImage(image);
            if (res?.data?.id) {
              return +res?.data?.id;
            }
          }
        })
      : [];

    const dataSent = {
      updatePageInput: {
        id: +values?.id,
        name: values?.name,
        description: values?.description,
        htmlBody: values?.htmlBody,
        type: values?.type,
        categories: values.categories.map(function (obj) {
          return +obj.id;
        }),
        media: await Promise.all(allImages)
          .then((responses) => {
            return responses;
          })
          .then((r) => r.filter((v) => v)),
      },
    };

    try {
      const res = await editService(dataSent, token);
      if (res?.data?.updatePage) {
        dispatch(editServiceSuccess(res?.data?.updatePage));
        dispatch(
          showSuccessMessage('this Service has been successfully updated')
        );
        resteCache('pages');
      } else {
        dispatch(editServiceFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(editServiceFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleImportServices = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyService(dataSent, token);
      if (res?.data?.removeServices) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Services Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteServicesFail(error));
      });
    }
  };
};

export const handleExportServices = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyService(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllServices = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyService(dataSent, token);
    } catch (error) {}
  };
};
