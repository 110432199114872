import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useStateContext } from "../../contexts/ContextProvider";
import { Box, InputAdornment, Paper } from "@mui/material";
import { SearchIcon } from "../Svg/MainIcons";

export default function BasicSelecAutoComplete({
  id,
  label,
  placeholder,
  options,
  value,
  onChange,
  isOptionEqualToValue,
  getOptionLabel,
  getOptionSelected,
  error,
  helperText,
  InputProps,
  rows,
  countries,
  renderOption,
  multiple,
  freeSolo,
  renderTags,
  search,
  disabled,
  inputValue,
  onInputChange,
  noOptionsText,
  filterOptions,
  ...rest

}) {
  const { bodyStyles } = useStateContext();

  return (
    <Autocomplete
      disabled={disabled}
      value={value}
      freeSolo={freeSolo ? true : false}
      noOptionsText={noOptionsText}
      multiple={multiple ? true : false}
      renderTags={renderTags}
      filterOptions={filterOptions}
      id="tags-filled"
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      {...rest}
      PaperComponent={({ children }) => (
        <Paper
          style={{
            background: bodyStyles.optionsBackground,
            color: bodyStyles?.text,
          }}
        >
          {children}
        </Paper>
      )}
      renderOption={renderOption}
      sx={{
        minHeight: "50px",
        width: "100%",
        ".MuiChip-label ": {
          color: bodyStyles?.subText,
          borderColor: bodyStyles?.text,
          // backgroundColor: "transparent",
          borderRadius: "10px",
        },
        ".MuiChip-root ": {
          background: "transparent",
        },

        "& .MuiChip-deleteIcon": {
          color: `${bodyStyles?.text} !important`,
        },

        color: bodyStyles?.inputText,
        input: {
          "&::placeholder": {
            color: bodyStyles?.subText,
          },
          color: bodyStyles?.subText,
        },

        "& label.Mui-focused": {
          color: bodyStyles?.text,
          backgroundColor: bodyStyles.background,
          paddingRight: "4px",
        },

        "& .MuiInputBase-input": {
          position: "relative",

          "&:focus": {
            borderColor: bodyStyles?.text,
          },
        },

        ".MuiOutlinedInput-notchedOutline": {
          borderColor: bodyStyles?.inputBorder,
          borderWidth: 1,
          color: bodyStyles?.text,
          "&::placeholder": {
            textOverflow: "ellipsis !important",
            color: "blue",
          },
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: bodyStyles?.inputBorder,
          "&::placeholder": {
            textOverflow: "ellipsis !important",
            color: "blue",
          },
        },

        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: bodyStyles?.inputBorder,
        },

        "& .MuiOutlinedInput-root": {
          display: "flex",
          color: bodyStyles?.inputText,
          alignItems: "center",
          "& fieldset": {
            borderColor: bodyStyles?.inputBorder,
          },
          "&:hover fieldset": {
            borderColor: bodyStyles?.inputBorder,
            display: "flex",
            alignItems: "center",
          },
          "&.Mui-focused fieldset": {
            borderColor: bodyStyles?.inputBorderSelected,
          },
        },

        "& .MuiSvgIcon-root": {
          color: bodyStyles?.inputSubText,
        },
      }}
      renderInput={(params) =>
        search ? (
          <TextField
            onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
            {...params}
            label={label}
            fullWidth
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              startAdornment: search && (
                <InputAdornment position="start">
                  <SearchIcon color="#CECECE" />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",

              color: bodyStyles?.inputText,

              "& label": {
                color: bodyStyles?.inputSubText,
              },

              "& label.Mui-focused": {
                color: bodyStyles?.text,
                backgroundColor: bodyStyles.background,
                paddingRight: "4px",
                top: "0",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: bodyStyles?.inputText,
              },
              "& .MuiOutlinedInput-root": {
                display: "flex",
                color: bodyStyles?.inputText,
                alignItems: "center",
                "& fieldset": {
                  borderColor: bodyStyles?.inputBorder,
                },
                "&:hover fieldset": {
                  borderColor: bodyStyles?.inputBorder,
                  display: "flex",
                  alignItems: "center",
                },
                "&.Mui-focused fieldset": {
                  borderColor: bodyStyles?.inputBorderSelected,
                },
              },
            }}
          />
        ) : (
          <TextField
            {...params}
            label={label}
            fullWidth
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            sx={{
              width: "100%",

              color: bodyStyles?.inputText,

              "& label": {
                color: bodyStyles?.inputSubText,
              },

              "& label.Mui-focused": {
                color: bodyStyles?.text,
                backgroundColor: bodyStyles.background,
                paddingRight: "4px",
                top: "0",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: bodyStyles?.inputText,
              },
              "& .MuiOutlinedInput-root": {
                display: "flex",
                color: bodyStyles?.inputText,
                alignItems: "center",
                "& fieldset": {
                  borderColor: bodyStyles?.inputBorder,
                },
                "&:hover fieldset": {
                  borderColor: bodyStyles?.inputBorder,
                  display: "flex",
                  alignItems: "center",
                },
                "&.Mui-focused fieldset": {
                  borderColor: bodyStyles?.inputBorderSelected,
                },
              },
            }}
          />
        )
      }
    />
  );
}
