import React, { useState } from "react";
import Editor from "../../../EditorCode";
import { useTranslation } from "react-i18next";

const AdditionalCode = () => {
  const { t } = useTranslation();

  const [data1, setData1] = useState(`<!-- Google tag (gtag.js) -->
    <script async src="https://www.googletagmanager.com/gtag/js?id={{ID}}"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', '{{ID}}');
    </script>`);
  const [code1, setCode1] = useState(
    "<style>\n .class  { color: #FFF; }\n</style>\n\n\n"
  );

  return (
    <div>
      <div className="flex w-full flex-row items-center justify-center gap-x-8">
        <div className=" w-full">
          <Editor code={data1} setCode={setData1} />
        </div>
        <div className=" w-full">
          <Editor code={code1} setCode={setCode1} />
        </div>
      </div>
      <div className="mt-10 flex w-full flex-col items-start justify-start gap-y-8">
        <h1 className="text-[19px] font-[600] uppercase text-[#808080]">
          {t("Additional header code")}
        </h1>
        <div className=" w-full">
          <Editor code={code1} setCode={setCode1} />
        </div>
      </div>
      <div className="mt-10 flex w-full flex-col items-start justify-start gap-y-8">
        <h1 className="text-[19px] font-[600] uppercase text-[#808080]">
          {t("Additional header code")}
        </h1>
        <div className=" w-full">
          <Editor code={code1} setCode={setCode1} />
        </div>
      </div>
    </div>
  );
};

export default AdditionalCode;
