import React, { useState } from 'react'

const RolesFiled = ({ item }) => {
    const [show, setShow] = useState(5);
    return (
      <div className=" flex w-full flex-wrap gap-3 py-1.5">
        {item?.assignments?.slice(0, show).map((role, index) => (
          <div className="border p-1" key={role?.role?.id}>
            {role?.role?.name}
          </div>
        ))}
  
        {item?.assignments?.length > show && (
          <div
            className="cursor-pointer border p-1"
            onClick={() => setShow(show + 5)}
          >
            +{item?.assignments.length - show}
          </div>
        )}
      </div>
    );
  };

export default RolesFiled