import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingDelete: false,
  loadingDeleteAll: false,
  loadingCreate: false,
  loadingEdit: false,
  loadingImport: false,
  data: [],
  error: null,
  errorFetch: null,
  success: null,
  totalCount: 0,
  selectedOne: null,
  selectedMany: [],
  isSelectedMany: false,
  deleteModal: false,
  deleteAllModal: false,
  createModal: false,
  createAllModal: false,
  editModal: false,
  editAllModal: false,
  exportModal: false,
  exportSelectedModal: false,
  importModal: false,
};

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    // fetchInstance
    fetchPagesStart: (state) => {
      state.loading = true;
    },
    fetchPagesSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload?.pages?.nodes;
      state.totalCount = action.payload?.pages?.totalCount;
      state.errorFetch = false;
    },
    fetchPagesFail: (state, action) => {
      state.loading = false;
      state.data = [];
      state.errorFetch = action.payload;
    },

    // createInstance
    createPageStart: (state) => {
      state.loadingCreate = true;
    },
    createPageSuccess: (state, action) => {
      state.loadingCreate = false;
      state.data.unshift(action.payload);
      state.success = 'Page created successfully.';
    },
    createPageFail: (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    },

    // editInstance
    editPageStart: (state) => {
      state.loadingEdit = true;
    },
    editPageSuccess: (state, action) => {
      state.loadingEdit = false;
      state.data = state.data.map((page) =>
        page.id === action.payload.id ? action.payload : page
      );
      state.success = 'Page updated successfully.';
    },
    editPageFail: (state, action) => {
      state.loadingEdit = false;
      state.error = action.payload;
    },

    // delete Instance
    deletePageStart: (state) => {
      state.loadingDelete = true;
    },
    deletePageSuccess: (state, action) => {
      state.loadingDelete = false;
      state.data = state.data.filter((page) => page.id !== action.payload);
      state.success = 'Page deleted successfully';
      state.deleteModal = false;
    },
    deletePageFail: (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
      state.deleteModal = false;
    },

    // delete Many Instances
    deletePagesStart: (state) => {
      state.loadingDeleteAll = true;
    },
    deletePagesSuccess: (state, action) => {
      state.loadingDeleteAll = false;
      const deletedIds = action.payload;
      state.data = state.data.filter((page) => !deletedIds.includes(page.id));
      state.success = 'Pages deleted successfully';
      state.deleteAllModal = false;
    },
    deletePagesFail: (state, action) => {
      state.loadingDeleteAll = false;
      state.error = action.payload;
      state.deleteAllModal = false;
    },

    // selectInstance

    selectOne: (state, action) => {
      state.selectedOne = action.payload;
    },

    selectAll: (state, action) => {
      if (state.isSelectedMany) {
        state.selectedMany = [];
        state.isSelectedMany = false;
      } else {
        state?.data?.map((item) => {
          if (!state.selectedMany.includes(item.id)) {
            state.selectedMany = [...state.selectedMany, item.id];
          }
        });
        state.isSelectedMany = true;
      }
    },

    removeAll: (state, action) => {
      state.selectedMany = [];
      state.isSelectedMany = false;
    },

    updateSelected: (state, action) => {
      if (state.selectedMany.includes(action.payload)) {
        state.selectedMany = state.selectedMany.filter((item) => {
          return item !== action.payload;
        });
      } else {
        state.selectedMany = [...state.selectedMany, action.payload];
      }
    },

    // DELETEiNSTANCE

    openModelDelete: (state, action) => {
      state.selectedOne = action.payload;
      state.deleteModal = !state.deleteModal;
    },

    openModelDeleteAll: (state, action) => {
      state.deleteAllModal = !state.deleteAllModal;
    },

    // ExportSelected

    openModeleExportAll: (state, action) => {
      state.exportModal = true;
    },

    openModeleExportSelected: (state, action) => {
      state.exportSelectedModal = true;
    },

    // Import

    openModeleImport: (state, action) => {
      state.importModal = !state.importModal;
    },

    importStart: (state) => {
      state.loadingImport = true;
    },
    importSuccess: (state, action) => {
      state.loadingImport = false;
      state.success = 'Pages Imported successfully';
      state.importModal = false;
    },
    importFail: (state, action) => {
      state.loadingImport = false;
      state.error = action.payload;
      state.importModal = false;
    },

    // CLEAR
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  fetchPagesStart,
  fetchPagesSuccess,
  fetchPagesFail,
  createPageStart,
  createPageSuccess,
  createPageFail,
  editPageStart,
  editPageSuccess,
  editPageFail,
  deletePageStart,
  deletePageSuccess,
  deletePageFail,
  clearMessages,
  updateSelected,
  selectAll,
  selectOne,
  openModelDelete,
  openModelDeleteAll,
  openModeleExportAll,
  openModeleExportSelected,
  deletePagesStart,
  deletePagesSuccess,
  deletePagesFail,
  openModeleImport,
  importStart,
  importSuccess,
  importFail,
  removeAll,
} = pageSlice.actions;

export default pageSlice.reducer;
