import { constants } from '../constants';
import { client } from '../global/apolloClient';
import {
  CREATE_ROLE,
  DELETE_ROLE,
  EDIT_ROLE,
} from '../graphql/mutation/role.mutation';

import { GET_ALL_ROLES } from '../graphql/query/roles.query';
import {
  createRolleFail,
  createRolleStart,
  createRolleSuccess,
  deleteRolleFail,
  deleteRolleStart,
  deleteRolleSuccess,
  deleteRollesFail,
  deleteRollesStart,
  deleteRollesSuccess,
  editRolleFail,
  editRolleStart,
  editRolleSuccess,
  fetchRollesFail,
  fetchRollesStart,
  fetchRollesSuccess,
  importFail,
  importStart,
  importSuccess,
} from '../store/features/rolle/rolleSlicer';
import {
  showErrorMessage,
  showErrorSettingMessage,
  showSuccessMessage,
  showSuccessSettingMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation, resteCache } from './executeMutation';

const token = getTheTokenFromStorage();

export const createRole = async (values, accessToken) => {
  return await executeMutation(
    CREATE_ROLE,
    [GET_ALL_ROLES],
    values,
    accessToken
  );
};

export const updateRole = async (values, accessToken) => {
  return await executeMutation(EDIT_ROLE, [GET_ALL_ROLES], values, accessToken);
};

export const deleteRole = async (values, accessToken) => {
  return await executeMutation(
    DELETE_ROLE,
    [GET_ALL_ROLES],
    values,
    accessToken
  );
};

export const deleteManyRole = async (values, accessToken) => {
  return await executeMutation(
    DELETE_ROLE,
    [GET_ALL_ROLES],
    values,
    accessToken
  );
};

export const fetchRolles = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchRollesStart());
      const { data } = await client.query({
        query: GET_ALL_ROLES,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchRollesSuccess(data));
    } catch (error) {
      dispatch(fetchRollesFail(error));
    }
  };
};

export const handleDeleteRole = (id) => {
  return async (dispatch) => {
    dispatch(deleteRolleStart());
    const dataSent = {
      removeRoleId: parseInt(id),
    };
    try {
      let res = await deleteRole(dataSent, token);
      if (res?.data?.removeRole) {
        dispatch(deleteRolleSuccess(id));
        dispatch(showSuccessSettingMessage('Role deleted successfully'));
        resteCache('roles');
      } else {
        dispatch(deleteRolleFail(res));
        dispatch(showErrorSettingMessage(res));
      }
    } catch (error) {
      dispatch(showErrorSettingMessage(error));
      dispatch(deleteRolleFail(error));
    }
  };
};

export const handleDeleteManyRole = (ids) => {
  return async (dispatch) => {
    dispatch(deleteRollesStart());
    const dataSent = {
      removeRoleId: ids.map((id) => parseInt(id)),
    };
    try {
      let res = await deleteManyRole(dataSent, token);
      if (res?.data?.removeRoles) {
        dispatch(deleteRollesSuccess(ids));
        dispatch(showSuccessMessage('Roles deleted successfully'));
      } else {
        dispatch(deleteRollesFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteRollesFail(error));
    }
  };
};

export const handleCreateRole = (values, callback) => {
  return async (dispatch) => {
    dispatch(createRolleStart());

    let truePermissionsList = [];
    values.rolesDesc.forEach((permission) => {
      permission.permissions.forEach((p) => {
        if (p.status) {
          truePermissionsList.push(+p.id);
        }
      });
    });

    const dataSent = {
      createRoleInput: {
        name: values.name,
        description: values.description,
        permissions: truePermissionsList,
      },
    };

    try {
      let res = await createRole(dataSent, token);
      if (res?.data?.createRole) {
        dispatch(createRolleSuccess(res?.data?.createRole));
        dispatch(
          showSuccessSettingMessage('this Role has been successfully created')
        );
        resteCache('roles');
      } else {
        dispatch(createRolleFail(res));
        dispatch(showErrorSettingMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(createRolleFail(error));
      dispatch(showErrorSettingMessage(error));
      callback && callback(error);
    }
  };
};

export const handleEditRole = (values, callback) => {
  return async (dispatch) => {
    dispatch(editRolleStart());

    let truePermissionsList = [];
    values?.rolesDesc.forEach((permission) => {
      permission.permissions.forEach((p) => {
        if (p.status) {
          truePermissionsList.push(+p.id);
        }
      });
    });

    const dataSent = {
      updateRoleInput: {
        id: +values.id,
        name: values.name,
        description: values.description,
        permissions: truePermissionsList,
      },
    };

    try {
      let res = await updateRole(dataSent, token);
      if (res?.data?.updateRole) {
        dispatch(editRolleSuccess(res?.data?.updateRole));
        dispatch(
          showSuccessSettingMessage('this Role has been successfully updated')
        );
      } else {
        dispatch(editRolleFail(res));
        dispatch(showErrorSettingMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(editRolleFail(error));
      dispatch(showErrorSettingMessage(error));
      callback && callback(error);
    }
  };
};

export const handleImportRoles = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyRole(dataSent, token);
      if (res?.data?.removeRoles) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Roles Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteRollesFail(error));
      });
    }
  };
};

export const handleExportRoles = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyRole(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllRoles = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyRole(dataSent, token);
    } catch (error) {}
  };
};
