import { constants } from '../constants';
import { client } from '../global/apolloClient';
import {
  CREATE_MANY_VARIANT,
  CREATE_VARIANT,
  DELETE_VARIANT,
  EDIT_VARIANT,
} from '../graphql/mutation/variant.mutation';
import { GET_PRODUCT } from '../graphql/query/products.query';
import { GET_ALL_VARIANTS } from '../graphql/query/variants.query';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import {
  createVariantFail,
  createVariantStart,
  createVariantSuccess,
  deleteVariantFail,
  deleteVariantStart,
  deleteVariantSuccess,
  deleteVariantsFail,
  deleteVariantsStart,
  deleteVariantsSuccess,
  editManyVariantStart,
  editManyVariantSuccess,
  editVManyariantFail,
  editVariantFail,
  editVariantStart,
  editVariantSuccess,
  fetchVariantsFail,
  fetchVariantsStart,
  fetchVariantsSuccess,
  importFail,
  importStart,
  importSuccess,
} from '../store/features/variant/variantSlicer';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation } from './executeMutation';
import { uploadImage } from './upload';

const token = getTheTokenFromStorage();

export const createVariant = async (values, accessToken) => {
  return await executeMutation(
    CREATE_VARIANT,
    [GET_PRODUCT],
    values,
    accessToken
  );
};

export const createManyVariant = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: CREATE_MANY_VARIANT,
      variables: {
        ...values,
      },
      context: { accessToken },
      refetchQueries: [GET_PRODUCT],
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const deleteVariant = async (values, accessToken) => {
  return await executeMutation(
    DELETE_VARIANT,
    [GET_PRODUCT, GET_ALL_VARIANTS],
    values,
    accessToken
  );
};

export const editVariant = async (values, accessToken) => {
  return await executeMutation(
    EDIT_VARIANT,
    [GET_PRODUCT],
    values,
    accessToken
  );
};

export const deleteManyVariants = async (values, accessToken) => {
  return await executeMutation(
    DELETE_VARIANT,
    [GET_ALL_VARIANTS, GET_PRODUCT],
    values,
    accessToken
  );
};

export const fetchVariants = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchVariantsStart());
      const { data } = await client.query({
        query: GET_ALL_VARIANTS,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchVariantsSuccess(data));
    } catch (error) {
      dispatch(fetchVariantsFail(error));
    }
  };
};

export const handleDeleteVariant = (id, callback) => {
  return async (dispatch) => {
    dispatch(deleteVariantStart());

    const dataSent = {
      removeVariantId: parseInt(id),
    };

    try {
      let res = await deleteVariant(dataSent, token);
      if (res?.data?.removeVariant) {
        dispatch(deleteVariantSuccess(id));
        dispatch(showSuccessMessage('Variant deleted successfully'));
      } else {
        dispatch(deleteVariantFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteVariantFail(error));
      callback && callback(error);
    }
  };
};

export const handleDeleteManyVariant = (ids) => {
  return async (dispatch) => {
    dispatch(deleteVariantsStart());
    const dataSent = {
      removeVariantId: ids.map((id) => parseInt(id)),
    };
    try {
      let res = await deleteManyVariants(dataSent, token);
      if (res?.data?.removeVariants) {
        dispatch(deleteVariantsSuccess(ids));
        dispatch(showSuccessMessage('Variants deleted successfully'));
      } else {
        dispatch(deleteVariantsFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteVariantsFail(error));
    }
  };
};

export const handleCreateVariant = (values, callback) => {
  return async (dispatch) => {
    dispatch(createVariantStart());

    let imageSent = values.featuredAsset[0]
      ? await uploadImage(values.featuredAsset[0])
      : null;

    let translationDataSent = values.languages
      ? Object.keys(values.languages).map((key) => {
          return {
            name: values.languages[key].name,
            description: values.languages[key].description,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      input: {
        isRoot: values.isRoot,
        parent: values.parent?.id ? +values.parent.id : null,
        translationData: translationDataSent,
        collections: values.collections.map(function (obj) {
          return +obj.id;
        }),
        media: imageSent?.data?.id ? +imageSent?.data?.id : null,
      },
    };

    try {
      const res = await createVariant(dataSent, token);
      if (res?.data?.createVariant) {
        dispatch(createVariantSuccess(res?.data?.createVariant));
        dispatch(
          showSuccessMessage('this Variant has been successfully created')
        );

        if (callback) {
          callback(res?.data?.createVariant.id);
        }
      } else {
        dispatch(createVariantFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(createVariantFail(error));
      dispatch(showErrorMessage(error));
    }
  };
};


export const handleEditVariant = (values, callback) => {
  return async (dispatch) => {
    dispatch(editVariantStart());

    let imageSent = values.images[0]
    ? values.images[0].id
      ? +values.images[0].id
      : await uploadImage(values.images[0])
    : null;

  const dataSent = {
    input: {
      id: +values.variantId,
      product: +values.productId,
      isEnabled: values.isEnabled,
      sku: values.sku ? values.sku : null,
      stockOnHand: values.stockOnHand,
      stockAllocated: values.stockAllocated,
      pricing: values.price,
      compareAtPrice: values?.compareAtPrice ? values?.compareAtPrice : 0,
      currencyCode: values?.currencyCode,
      media: imageSent?.data?.id ? imageSent?.data?.id : imageSent,
    },
  };

    try {
      let res = await editVariant(dataSent, token);
      if (res?.data?.updateVariant) {
        dispatch(editVariantSuccess(res?.data?.updateVariant));
        dispatch(
          showSuccessMessage('this Variant has been successfully updated')
        );
      } else {
        dispatch(editVariantFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(editVariantFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleEditManyVariantsStock = (values, callback) => {
  return async (dispatch) => {
    dispatch(editManyVariantStart());
    return Promise.all(
      values.map(async (item, index) => {
        console.log('item.save', item.save);
        if (!(item.save === false)) {
          return null; // Skip this iteration and return null
        }
        const dataSent = {
          input: {
            id: +item.id,
            stockOnHand: +item.stockOnHand,
            currencyCode: item?.currencyCode,
          },
        };
        const resProduct = await editVariant(dataSent, token);
        if (resProduct?.data) {
          return resProduct;
        }
      })
    )
      .then((responses) => {
        // Filter out null responses
        const filteredResponses = responses.filter((res) => res !== null);

        if (filteredResponses.length > 0) {
          dispatch(editManyVariantSuccess(filteredResponses));
          dispatch(
            showSuccessMessage('this Variants has been successfully updated')
          );
          if (callback) {
            callback(filteredResponses);
          }
        } else {
          dispatch(editVManyariantFail('error in update one of variants'));
          dispatch(showErrorMessage('error in update one of variants'));
        }
      })
      .catch((error) => {
        dispatch(editVManyariantFail(error));
        dispatch(showErrorMessage(error));
      });
  };
};

export const handleImportVariant = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyVariants(dataSent, token);
      if (res?.data?.removeVariants) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Variants Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteVariantsFail(error));
      });
    }
  };
};

export const handleExportVariant = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyVariants(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllVariants = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyVariants(dataSent, token);
    } catch (error) {}
  };
};
