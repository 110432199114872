import React from "react";
import { useTranslation } from "react-i18next";

const NonAuthorized = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full min-h-[60vh] flex justify-center items-center flex-col ">
      <img
        src={1 ? `/images/403.svg` : "/images/404.svg"}
        alt="Item Not Found"
        className="max-h-[400px] "
      />

      <div className="text-lg font-Inter flex flex-col justify-center items-center gap-2">
        <p className="font-[600]">{t("Non Authorized")} </p>
      </div>
    </div>
  );
};

export default NonAuthorized;
