import React, { useContext, useState } from 'react';
import Tabs from '../../TabsLanguages';
import { storeContext } from '../../../global/store';
import BasicInput from '../../Inputs/BasicInput';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import DragImages from '../../DragImages';
import DragToUpload from '../../DragToUpload';
import { useQuery } from '@apollo/client';
import Editor from '../../Editor/Editor';
import BasicSelecAutoComplete from '../../Inputs/BasicSelecAutoComplete';
import { GET_ALL_CATEGORIES } from '../../../graphql/query/categories.query';

const EditServiceForm = ({
  fields,
  setFields,
  formik,
  images,
  setImages,
}) => {
  const { store } = useContext(storeContext);

  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();

  const [loadingUpload, setLoadingUpload] = useState(null);
  const [uploadMore, setUploadMore] = useState(true);
  const [openAssetsModal, setOpenAssetsModal] = useState(false);

  const [searchCategory, setSearchCategory] = useState('');

  const {
    loading: loadinCategories,
    error: errorCategories,
    refetch: refetchCategories,
    data: categoriesData,
  } = useQuery(GET_ALL_CATEGORIES, {
    variables: {
      offset: 0,
      limit: 10,
      name : searchCategory?.trim()?.replace(/\W{2,}/, ' '),
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleDeleteImage = (i) => {
    setImages(images.filter((image, index) => index !== i));
  }; 

  return (
    <form id='add-product-form' className=' font-Inter relative mb-[50px] '>
      <div className='flex flex-col gap-y-3 py-3'>
        <div>
          <div className='flex gap-5 flex-col md:flex-row  '>
            <div className='w-[100%]  space-y-6 '>
              <div className=' space-y-2  rounded-lg'>
                <Tabs availableLanguages={store.availableLanguages}>
                  {store.availableLanguages?.map((item, indexLanguages) => (
                    <div
                      key={indexLanguages}
                      className='space-y-6 font-urbanist '
                    >
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-10 px-2 sm:px-0'>
                        <div className='space-y-4 col-span-2  '>
                          <div
                            className='font-Inter text-xl pt-7 pb-5 uppercase font-bold flex items-center justify-start'
                            style={{
                              color: bodyStyles.subTitle,
                            }}
                          >
                            <span>{t('Service informations')}</span>
                          </div>
                          <div className='flex flex-col  gap-6 '>
                            <BasicInput
                              label={t('Service Name')}
                              placeholder='Iphone13 pro'
                              value={fields?.name}
                              onChange={(e) => {
                                setFields((prevState) => ({
                                  ...prevState,
                                  name: e.target.value,
                                }));
                              }}
                              error={
                                formik?.touched?.name &&
                                Boolean(formik?.errors?.name)
                              }
                              helperText={
                                formik?.touched?.name && formik?.errors?.name
                              }
                            />

                            {/* <BasicInput
                              label={t('Description')}
                              placeholder='Tristique viverra risus mauris nunc arcu suspendisse id. Condimentum morbi arcu lectus et sagittis. Sit et, justo tristique vel vestibulum. Sit morbi tristique sed nec scelerisque aliquam convallis quis. Ipsum aenean a libero augue ultrices nam consequat adipiscing leo. Hac vel augue fermentum pharetra, arcu cras. Urna ut nec integer dignissim nibh est, nunc feugiat. Bibendum in elementum leo fringilla venenatis nunc ac. '
                              rows={4}
                              value={fields?.description}
                              onChange={(e) => {
                                setFields((prevState) => ({
                                  ...prevState,
                                  description: e.target.value,
                                }));
                              }}
                              error={
                                formik?.touched?.description &&
                                Boolean(formik?.errors?.description)
                              }
                              helperText={
                                formik?.touched?.description &&
                                formik?.errors?.description
                              }
                            /> */}

                            <Editor
                              placeholder={`${t('Details')} ...`}
                              value={fields?.htmlBody}
                              onChange={(e) => {
                                if (e) {
                                  setFields((prevState) => ({
                                    ...prevState,
                                    htmlBody: e,
                                  }));
                                }
                              }}
                              selectTable
                            />
                          </div>

                          <div
                            className='font-Inter text-sm pt-4 pb-4 uppercase font-bold flex items-center justify-start'
                            style={{
                              color: bodyStyles.subTitle,
                            }}
                          >
                            <span>{`${t('Media')}`}</span>
                          </div>
                          <div>
                            <div className='flex gap-1 flex-wrap'>
                              {(images?.length > 0 || loadingUpload) && (
                                <DragImages
                                  images={images.map((item, index) => ({
                                    id: `${index}`,
                                    imageUrl: item,
                                  }))}
                                  setImages={setImages}
                                  handleDeleteImage={handleDeleteImage}
                                  loadingUpload={loadingUpload}
                                />
                              )}

                              {((!loadingUpload && images?.length === 0) ||
                                uploadMore) && (
                                <DragToUpload
                                  setImages={setImages}
                                  images={images}
                                  setLoadingUpload={setLoadingUpload}
                                  loadingUpload={loadingUpload}
                                  setUploadMore={setUploadMore}
                                  uploadMore={uploadMore}
                                  // text={t("Accepts images, videos, or 3D models")}
                                  button01={t('Add file')}
                                  // button02={t("select assets")}
                                  height='140px'
                                  accept={{
                                    'image/*': ['.jpeg', '.png', '.svg'],
                                  }}
                                  setOpenUrlModal={setOpenAssetsModal}
                                  error={formik.errors.images}
                                />
                              )}
                            </div>

                            <span className='text-[#d32f2f] text-sm px-1'>
                              {formik.errors.images}
                            </span>
                          </div>
                        </div>

                        <div className='space-y-4 col-span-2  md:col-span-1 w-full '>
                          <div
                            className='font-Inter text-xl pt-7 pb-5 uppercase font-bold flex items-center justify-start'
                            style={{
                              color: bodyStyles.subTitle,
                            }}
                          >
                            <span>{t('Service Category')}</span>
                          </div>

                          <BasicSelecAutoComplete
                            multiple
                            fullWidth
                            label={t('Categories')}
                            placeholder='Search for categories'
                            options={
                              categoriesData?.categories?.nodes
                                ? categoriesData?.categories?.nodes
                                : []
                            }
                            getOptionLabel={(option) => option?.name}
                            isOptionEqualToValue={(option, value) =>
                              option?.id === (value?.id || null)
                            }
                            value={fields?.categories}
                            freeSolo
                            onChange={(event, newValue) => {
                              setFields({
                                ...fields,
                                categories: newValue,
                              });
                            }}
                            inputValue={searchCategory}
                            onInputChange={(event, newInputValue) => {
                              setSearchCategory(newInputValue);
                            }}
                            error={
                              formik.touched.categories &&
                              Boolean(formik.errors.categories)
                            }
                            helperText={
                              formik.touched.categories &&
                              formik.errors.categories
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};


export default EditServiceForm;
