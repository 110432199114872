import React from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { CircularProgress } from "@mui/material";
import { InputBase, InputAdornment, TextField } from "@mui/material";
import { SearchIcon } from "../Svg/MainIcons";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useHistory, useLocation } from 'react-router';


const SearchGrid = ({ 
    placeholder,
    value,
    onChange,
 }) => {
  const { bodyStyles } = useStateContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();


  const search = (e) => {
    e.preventDefault()
   let newQueryParameters = new URLSearchParams(location.search);
   newQueryParameters.set("search", value);
   setSearchParams(newQueryParameters)
  }

  return (
    <form onSubmit={search} className="h-full">
      <TextField
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className="cursor-pointer" title="search" onClick={e=>search(e)} >
              <SearchIcon color="#CECECE"/>
            </InputAdornment>
          ),
        }}
        size="medium"
        sx={{
          width: "100%",
          height: "100%",
          margin: "0",
          padding: "0",
          input: {
            "&::placeholder": {
              color: bodyStyles?.inputPlaceHolder,
            },
            color: bodyStyles?.inputText,
          
          },

          "& label": {
            color: bodyStyles?.text,
          },
          "& label.Mui-focused": {
            color: bodyStyles?.subText,
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: bodyStyles?.title,
          },
          "& .MuiOutlinedInput-root": {
            height: "100%",

            "& fieldset": {
              borderColor: bodyStyles?.subText,
            },
            "&:hover fieldset": {
              borderColor: bodyStyles?.subText,
            },
            "&.Mui-focused fieldset": {
              borderColor: bodyStyles?.subText,
            },
          },
        }}
      />

    </form>
  );
};

export default SearchGrid;
