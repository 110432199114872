import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { storeContext } from '../global/store';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Badge, ButtonBase, ClickAwayListener } from '@mui/material';
import { Collapse } from '@mui/material';
import {
  AnalyticsIcon,
  DiscountsIcon,
  HomeIcon,
  OrdersIcon,
  ProductsIcon,
  SettingsIcon,
  UsersIcon,
} from './Svg/NavIcons';

import { useTranslation } from 'react-i18next';
import { generalData } from '../utils/general';
import {
  accountSettings,
  allPermissions,
  analyticsPermissions,
  brandPermissions,
  checkoutPermissions,
  collectionPermissions,
  customerPermissions,
  discountPermissions,
  hasPermission,
  homePermissions,
  inventoryPermissions,
  menuPermissions,
  orderPermissions,
  productPermissions,
  tagPermissions,
} from '../utils/permissions';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { GET_UNREAD_MESSAGES } from '../graphql/query/contacts.query';
import { useQuery } from '@apollo/client';

const Sidebar = ({ openSetting, setOpenSetting }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { bodyStyles, activeMenu, setActiveMenu, screenSize } =
    useStateContext();

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const { store, unSeen, open: openSection } = useContext(storeContext);

  const didMount = useRef(false);

  const [dataCount, setDataCount] = useState({
    all: 0,
    published: 0,
    draft: 0,
  });

  useEffect(() => {
    if (didMount.current) {
    } else {
      didMount.current = true;
    }
  }, [store, didMount.current]);

  return (
    <ClickAwayListener
      onClickAway={() => screenSize < 500 && setActiveMenu(false)}
    >
      <div
        className={`min-h-[101vh] h-full hover:overflow-auto `}
        style={{
          background: bodyStyles?.sideBarBackground,
          color: bodyStyles?.sideBartext,
        }}
      >
        {1 && (
          <>
            <div
              className={`h-full flex flex-col justify-between `}
              style={{ overflowY: 'overlay', zIndex: 999 }}
            >
              <div>
                <div
                  className='text-center sticky -top-1 pt-4 pb-8 z-[99999] mt-[38px]'
                  style={{
                    color: bodyStyles?.sideBarLogo,
                    background: bodyStyles?.sideBarBackground,
                  }}
                >
                  <div
                    className={`flex flex-col gap-2 items-center justify-center cursor-pointer `}
                  >
                    {activeMenu ? (
                      <Link to='/' onClick={handleCloseSideBar}>
                        {/* <span>{`${t("SHOP V")} ${generalData?.version}`}</span> */}
                        <h2 className='text-4xl font-semibold '>
                          {generalData?.storeName}
                        </h2>
                      </Link>
                    ) : (
                      <div onClick={() => setActiveMenu(!activeMenu)}>
                        <h2 className='text-4xl font-semibold '>
                          {generalData?.storeName?.slice(0, 1)}{' '}
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={`flex flex-col items-start  justify-center w-full ${
                    activeMenu ? 'p-4' : ''
                  } `}
                >
                  <Management
                    unSeen={unSeen}
                    dataCount={dataCount}
                    setDataCount={setDataCount}
                  />
                </div>
              </div>

              {allPermissions.includes('setting') && (
                <div className='px-4 pb-10'>
                  <div
                    onClick={() => {
                      navigate('/settings/general');
                    }}
                  >
                    <div style={{ width: '100%', borderRadius: 4 }}>
                      <div
                        className={`text-sm cursor-pointer h-[48px] rounded-md font-semibold flex items-center 
                        justify-between w-full  bg-clip-padding `}
                      >
                        <div
                          className={`flex flex-row items-start justify-start py-3   ${
                            activeMenu
                              ? i18n.language === 'ar'
                                ? 'pr-4 pl-2 '
                                : 'pl-4 pr-2'
                              : 'flex justify-center w-full '
                          } w-full rounded-[4px] hover:bg-[rgba(190,190,190,.5)]`}
                        >
                          <div
                            className={`flex gap-x-4  uppercase font-Inter text-sm ${
                              i18n.language === 'ar'
                                ? 'text-right '
                                : 'text-left '
                            }  font-urbanist font-medium text-base`}
                          >
                            {/* {activeMenu ? (
                              <>
                                <SettingsIcon color={bodyStyles.sideBarIcon} />
                                <span>{t('settings')}</span>
                              </>
                            ) : (
                              <>
                                <SettingsIcon color={bodyStyles.sideBarIcon} />
                              </>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default Sidebar;

const Management = () => {
  const { open, setOpen, store } = useContext(storeContext);

  const { bodyStyles } = useStateContext();

  const location = useLocation();
  const { t } = useTranslation();

  const {
    loading,
    error,
    data: unreadMessage,
  } = useQuery(GET_UNREAD_MESSAGES, {
    fetchPolicy: 'cache-and-network',
  });

  const [sections, setSections] = useState([
    {
      title: 'HOME',
      url: '/',
      icon: <HomeIcon color={bodyStyles.sideBarIcon} />,
      permissions: [homePermissions['home:list']],
      display: generalData?.pages?.home,
    },

    {
      title: 'ORDERS',
      key: 'orders',
      permissions: [
        orderPermissions['order:list'],
        checkoutPermissions['checkout:list'],
      ],
      icon: <OrdersIcon color={bodyStyles.sideBarIcon} />,
      display:
        generalData?.pages?.order || generalData?.pages?.abandonedCheckout,

      childs: [
        {
          title: 'ORDERS',
          url: '/orders',
          key: 'orders',
          display: generalData?.pages?.order,
          icon: <OrdersIcon color={bodyStyles.sideBarIcon} />,
          permissions: [orderPermissions['order:list']],
        },
        {
          title: 'Abandoned Checkout',
          url: '/abandoned-checkout',
          key: 'abandoned-checkout',
          display: generalData?.pages?.abandonedCheckout,
          icon: <OrdersIcon color={bodyStyles.sideBarIcon} />,
          permissions: [checkoutPermissions['checkout:list']],
        },
      ],
    },

    {
      title: 'Products',
      key: 'products',
      icon: <ProductsIcon color={bodyStyles.sideBarIcon} />,
      permissions: [
        'product:list',
        'menu:list',
        'variant:list',
        'collection:list',
        'brand:list',
        'tag:list',
      ],
      display:
        generalData?.pages?.product ||
        generalData?.pages?.variant ||
        generalData?.pages?.menu ||
        generalData?.pages?.collection ||
        generalData?.pages?.brand ||
        generalData?.pages?.tag,
      childs: [
        {
          title: 'Products',
          url: '/products',
          key: 'products',
          icon: <OrdersIcon color={bodyStyles.sideBarIcon} />,
          permissions: [productPermissions['product:list']],
          display: generalData?.pages?.product,
        },

        {
          title: 'Services',
          url: '/services',
          key: 'services',
          icon: <OrdersIcon color={bodyStyles.sideBarIcon} />,
          permissions: [productPermissions['product:list']],
          display: generalData?.pages?.service,
        },
        {
          title: 'Planning',
          url: '/planning',
          key: 'planning',
          icon: <OrdersIcon color={bodyStyles.sideBarIcon} />,
          permissions: [productPermissions['product:list']],
          display: generalData?.pages?.planning,
        },
        {
          title: 'References',
          url: '/references',
          key: 'references',
          icon: <OrdersIcon color={bodyStyles.sideBarIcon} />,
          permissions: [productPermissions['product:list']],
          display: generalData?.pages?.reference,
        },

        {
          title: 'Inventory',
          url: '/inventory',
          key: 'inventory',
          icon: <OrdersIcon color={bodyStyles.sideBarIcon} />,
          permissions: [inventoryPermissions['variant:list']],
          display: generalData?.pages?.variant,
        },
        {
          title: 'Menus',
          url: '/menus',
          key: 'menus',
          icon: <OrdersIcon color={bodyStyles.sideBarIcon} />,
          permissions: [menuPermissions['menu:list']],
          display: generalData?.pages?.menu,
        },
        {
          title: 'Collections',
          url: '/collections',
          key: 'collections',
          icon: <OrdersIcon color={bodyStyles.sideBarIcon} />,
          permissions: [collectionPermissions['collection:list']],
          display: generalData?.pages?.collection,
        },
        {
          title: 'Categories',
          url: '/categories',
          key: 'categories',
          icon: <OrdersIcon color={bodyStyles.sideBarIcon} />,
          permissions: [collectionPermissions['collection:list']],
          display: generalData?.pages?.category,
        },
        {
          title: 'Brands',
          url: '/brands',
          key: 'brands',
          hidden: false,
          icon: <OrdersIcon color={bodyStyles.sideBarIcon} />,
          permissions: [brandPermissions['brand:list']],
          display: generalData?.pages?.brand,
        },
        {
          title: 'Tags',
          url: '/tags',
          key: 'tags',
          icon: <OrdersIcon color={bodyStyles.sideBarIcon} />,
          permissions: [tagPermissions['tag:list']],
          display: generalData?.pages?.tag,
        },
      ],
    },

    {
      title: 'DISCOUNTS',
      url: '/discounts',
      icon: <DiscountsIcon color={bodyStyles.sideBarIcon} />,
      permissions: [discountPermissions['discount:list']],
      display: generalData?.pages?.discount,
    },

    {
      title: 'PAGES',
      url: '/pages',
      icon: (
        <div
          style={{
            color: bodyStyles.sideBarIcon,
            opacity: '80%',
          }}
        >
          <NewspaperIcon sx={{ fontSize: 24 }} />
        </div>
      ),
      permissions: [discountPermissions['discount:list']],
      display: generalData?.pages?.pages,
    },

    {
      title: 'MESSAGES',
      url: '/contacts',
      icon: (
        <div
          style={{
            color: bodyStyles.sideBarIcon,
            opacity: '95%',
          }}
        >
          <Badge
            badgeContent={
              unreadMessage?.totalUnreadContacts
                ? unreadMessage?.totalUnreadContacts
                : 0
            }
            color='secondary'
          >
            <MailOutlineIcon
              sx={{ fontSize: 25 }}
              color='action'
              style={{
                color: bodyStyles.sideBarIcon,
              }}
            />
          </Badge>
        </div>
      ),
      permissions: [discountPermissions['discount:list']],
      display: generalData?.pages?.contact,
    },

    {
      title: 'CUSTOMERS',
      url: '/customers',
      icon: <UsersIcon color={bodyStyles.sideBarIcon} />,
      permissions: [customerPermissions['customer:list']],
      display: generalData?.pages?.customer,
    },
    {
      title: 'ANALYTICS',
      url: '/analytics',
      icon: <AnalyticsIcon color={bodyStyles.sideBarIcon} />,
      permissions: [analyticsPermissions['analytics:list']],
      display: generalData?.pages?.analytic,
    },
  ]);

  useEffect(() => {
    if (unreadMessage && unreadMessage.totalUnreadContacts !== undefined) {
      const updatedSections = sections.map((section) => {
        if (section.title === 'MESSAGES') {
          // Update the badgeContent in the MESSAGES section
          return {
            ...section,
            icon: (
              <div
                style={{
                  color: bodyStyles.sideBarIcon,
                  opacity: '95%',
                }}
              >
                <Badge
                  badgeContent={
                    unreadMessage.totalUnreadContacts
                      ? unreadMessage.totalUnreadContacts
                      : 0
                  }
                  color='secondary'
                >
                  <MailOutlineIcon
                    sx={{ fontSize: 25 }}
                    color='action'
                    style={{
                      color: bodyStyles.sideBarIcon,
                    }}
                  />
                </Badge>
              </div>
            ),
          };
        }
        return section; 
      });

      setSections(updatedSections);
    }
  }, [unreadMessage]);

  return (
    <div className='pb-6  flex flex-col justify-between w-full'>
      <div className=' flex flex-col gap-y-3 '>
        {sections?.map((item, index) =>
          item?.childs ? (
            <div key={`pr-${index}`}>
              {item?.permissions?.some((per) =>
                hasPermission(store?.permissions, per, store.usePermissions)
              ) &&
                item?.display && (
                  <Section
                    key={index}
                    title={t(item?.title)}
                    open={open[item?.key]}
                    setOpen={() =>
                      setOpen({ ...open, [item?.key]: !open[item?.key] })
                    }
                  >
                    {item.icon}

                    {item?.childs.map(
                      (child, childIndex) =>
                        child?.display && (
                          <div key={`pr-${childIndex}`}>
                            {child?.permissions?.some((per) =>
                              hasPermission(
                                store?.permissions,
                                per,
                                store.usePermissions
                              )
                            ) && (
                              <SubSection
                                key={childIndex}
                                title={child?.title}
                                url={child?.url}
                                selected={location?.pathname === child?.url}
                              >
                                {child?.icon}
                              </SubSection>
                            )}
                          </div>
                        )
                    )}
                  </Section>
                )}
            </div>
          ) : (
            <div key={`pr-ch-${index}`}>
              {item?.permissions?.some((per) =>
                hasPermission(store?.permissions, per, store.usePermissions)
              ) &&
                item?.display && (
                  <SectionNonCollapse
                    title={t(item?.title)}
                    url={item?.url}
                    selected={location?.pathname === item?.url}
                    key={`ii1-${item?.url}`}
                  >
                    {item?.icon}
                  </SectionNonCollapse>
                )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

const SectionNonCollapse = ({ title, url, selected, children }) => {
  const { i18n } = useTranslation();

  const { bodyStyles, activeMenu } = useStateContext();
  const navigate = useNavigate();

  const navigatePage = () => {
    localStorage.setItem('previouspath', window.location.pathname);
    if (url) {
      navigate(url);
    }
  };

  return (
    <div>
      <div style={{ width: '100%', borderRadius: 4 }}>
        <div onClick={() => navigatePage()}>
          <div
            className={`text-sm cursor-pointer h-[48px] rounded-md font-semibold flex items-center justify-between w-full
                bg-clip-padding  `}
          >
            <div
              className={`flex flex-row items-start justify-start py-3 relative ${
                selected ? 'bg-[rgba(190,190,190,.5)]' : ''
              } w-full rounded-[4px]  hover:bg-[rgba(190,190,190,.5)]
              ${
                activeMenu
                  ? i18n.language === 'ar'
                    ? 'pr-4 pl-2 '
                    : 'pl-4 pr-2 '
                  : 'flex justify-center w-full '
              }`}
            >
              <div
                className={`flex gap-x-4 uppercase font-Inter text-sm items-center 
              ${
                selected ? 'text-mainSideBar_text ' : 'text-mainSideBar_text '
              } font-urbanist font-medium text-base ${
                  i18n.language === 'ar' ? 'text-right' : 'text-left'
                }`}
                style={{
                  color: bodyStyles.sideBartext,
                  fontSize: bodyStyles?.sideBarLinksSize,
                }}
              >
                {activeMenu ? (
                  <>
                    <div className='scale-[90%] '>{children}</div>
                    <span>{title}</span>
                  </>
                ) : (
                  <>{children}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section = ({ open, setOpen, title, url, children }) => {
  const location = useLocation();

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const { bodyStyles, activeMenu } = useStateContext();

  const navigatePage = () => {
    localStorage.setItem('previouspath', window.location.pathname);
    if (url) {
      navigate(url);
    }
  };

  return (
    <div onClick={() => navigatePage()}>
      <ButtonBase style={{ width: '100%', borderRadius: 4 }}>
        <div
          className='text-sm cursor-pointer h-[48px] rounded-[4px] text-mainSideBar_text
          font-semibold flex items-center justify-between w-full '
          onClick={() => setOpen(!open)}
        >
          <div
            className={`flex flex-row items-start justify-start py-3  ${
              activeMenu
                ? i18n.language === 'ar'
                  ? 'pr-4 pl-2'
                  : 'pl-4 pr-2'
                : 'flex justify-center w-full '
            } ${
              location?.pathname.includes(url)
                ? 'bg-[rgba(190,190,190,.5)]'
                : ''
            } w-full rounded-[4px] hover:bg-[rgba(190,190,190,.5)]`}
          >
            <div
              className={`flex flex-row items-center justify-center gap-x-4
              uppercase font-Inter text-sm
              ${
                location?.pathname.includes(url)
                  ? 'text-mainSideBar_text'
                  : 'text-mainSideBar_text'
              } font-urbanist font-medium text-base`}
              style={{
                color: bodyStyles.sideBartext,
                fontSize: bodyStyles?.sideBarLinksSize,
              }}
            >
              {activeMenu ? (
                <>
                  <div className='scale-[90%] '>{children[0]}</div>
                  <span>{t(title)}</span>
                </>
              ) : (
                <div className='scale-[90%] '>{children[0]}</div>
              )}
            </div>
          </div>
        </div>
      </ButtonBase>
      <Collapse in={open} className='cursor-pointer mt-4 '>
        {children.slice(1)}
      </Collapse>
    </div>
  );
};

const SubSection = ({ title, url, children }) => {
  const { t, i18n } = useTranslation();
  const { activeMenu, bodyStyles } = useStateContext();
  const location = useLocation();

  return (
    <div style={{ width: '100%', borderRadius: 4 }}>
      <NavLink to={`${url}`}>
        <div
          className={`${
            activeMenu ? (i18n.language === 'ar' ? 'pr-10' : 'pl-10') : ''
          } 
          ${
            location?.pathname.includes(url) ? 'bg-[rgba(190,190,190,.5)] ' : ''
          }
          text-sm cursor-pointer py-3  h-[48px] rounded-md font-semibold mb-2
          flex items-center justify-between w-full hover:bg-[rgba(190,190,190,.5)]`}
        >
          <div
            title={`${title}`}
            className={`flex items-center  gap-x-2 ${
              0 ? 'text-mainSideBar_text' : 'text-mainSideBar_text'
            } font-urbanist font-medium text-base w-full rounded-[4px] `}
          >
            <div
              className={` w-[6px] h-[23px] ${
                0 ? 'bg-[transparent]' : 'bg-[transparent]'
              }`}
            ></div>
            <span
              className={`w-full truncate break-words ${
                i18n.language === 'ar' ? 'text-right' : 'text-left'
              }  font-Inter text-[11px] md:text-sm `}
              style={{
                color: location?.pathname.includes(url)
                  ? bodyStyles.sideBartext
                  : bodyStyles.sideBartext,
              }}
            >
              {activeMenu ? (
                <>
                  <span>{t(title)}</span>
                </>
              ) : (
                <div className='scale-[80%] '>{title.slice(0, 5)}</div>
              )}
            </span>
          </div>
        </div>
      </NavLink>
    </div>
  );
};
