import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { delay } from '../../utils/helpers';
import { handleCreateProduct } from '../../services/product';
import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import BottomBanner from '../../components/Actions/BottomBanner';
import PageModal from '../../components/PageModal';
import CreateCategoryForm from '../../components/Categories/createCategory/CreateCategoryForm';
import * as Yup from "yup";
import { handleCreateCategory } from '../../services/category';

const AddCategory = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.category);
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const [fields, setFields] = useState({
    name: '',
    description :"",
    type: "PRODUCT",
  });



  const addCategorySchema = Yup.object().shape({
    name: Yup.string().required(t("This Field is required")),
    description: Yup.string().required(t("This Field is required")),
    type: Yup.string().required(t("This Field is required")),
   
  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name : fields.name,
      description : fields.description,
      type : fields?.type
    },
    validationSchema: addCategorySchema,
    onSubmit: async (values) => {
      dispatch(
        handleCreateCategory(values, (res) => {
          if (res?.data?.createCategory?.id) {
            delay(1000).then(async () => {
              navigate(`/categories/edit/${res?.data?.createCategory?.id}`);
            });
          }
           
        })
      );
    },
  });


 
  
  return (
    <PageModal pageName={t('Add Category')} pagePrev={`/categories`}>
      <BottomBanner
        btn01='Cancel'
        btn01Action={() => navigate(`categories`)}
        btn02='Add Category'
        btn02Action={() => formik.handleSubmit()}
        loading={data?.loadingCreate}
      />

      <CreateCategoryForm
        fields={fields}
        setFields={setFields}
        formik={formik}    
      />
    </PageModal>
  );
};

export default AddCategory;
