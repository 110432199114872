export const hasPermission = (
  permissions,
  permission,
  usePermission = true
) => {
  if (!usePermission) return true; 
  return permissions.filter((e) => e.name === permission).length > 0;
};

export const allPermissions = [
  "home:list",
  "order:list",
  "order:create",
  "order:edit",
  "order:delete",

  "product:list",
  "product:create",
  "product:edit",
  "product:delete",

  "inventory:list",
  "inventory:create",
  "inventory:edit",
  "inventory:delete",

  "collection:list",
  "collection:create",
  "collection:edit",
  "collection:delete",

  "menu:list",
  "menu:create",
  "menu:edit",
  "menu:delete",

  "brand:list",
  "brand:create",
  "brand:edit",
  "brand:delete",

  "tag:list",
  "tag:create",
  "tag:edit",
  "tag:delete",

  "checkout:list",
  "checkout:create",
  "checkout:edit",
  "checkout:delete",

  "discount:list",
  "discount:create",
  "discount:edit",
  "discount:delete",

  "customer:list",
  "customer:create",
  "customer:edit",
  "customer:delete",

  "analytics:list",
  "analytics:create",
  "analytics:edit",
  "analytics:delete",
  "setting",
];

export const settingPermissions = [
  "generalSettings:list",
  "generalSettings:create",
  "generalSettings:edit",
  "generalSettings:delete",

  "zone:list",
  "zone:create",
  "zone:edit",
  "zone:delete",

  "shippingMethod:list",
  "shippingMethod:create",
  "shippingMethod:edit",
  "shippingMethod:delete",

  "staffSetting:list",
  "staffSetting:create",
  "staffSetting:edit",
  "staffSetting:delete",

  "role:list",
  "role:create",
  "role:edit",
  "role:delete",

  "user:list",
  "user:create",
  "user:edit",
  "user:delete",

  "assignment:list",
  "assignment:create",
  "assignment:edit",
  "assignment:delete",
];

export const homePermissions = {
  "home:list": "dashboard:list",
  "home:create": "home:create",
  "home:edit": "home:edit",
  "home:delete": "home:delete",
  "dashboard:live" : "dashboard:live"
}; 

export const productPermissions = {
  "product:list": "product:list",
  "product:create": "product:create",
  "product:edit": "product:edit",
  "product:view": "product:view",
  "product:delete": "product:delete",
};

export const brandPermissions = {
  "brand:list": "brand:list",
  "brand:edit": "brand:edit",
  "brand:create": "brand:create",
  "brand:view": "brand:view",
  "brand:delete": "brand:delete",
};

export const orderPermissions = {
  "order:list": "order:list",
  "order:create": "order:create",
  "order:edit": "order:edit",
  "order:view": "order:view",
  "order:delete": "order:delete",
};

export const checkoutPermissions = {
  "checkout:list": "checkout:list",
  "checkout:create": "checkout:create",
  "checkout:edit": "checkout:edit",
  "checkout:view": "checkout:view",
  "checkout:delete": "checkout:delete",
};

export const inventoryPermissions = {
  "variant:list": "variant:list",
  "variant:create": "variant:create",
  "variant:edit": "variant:edit",
  "variant:view": "variant:view",
  "variant:delete": "variant:delete",
};

export const collectionPermissions = {
  "collection:list": "collection:list",
  "collection:create": "collection:create",
  "collection:edit": "collection:edit",
  "collection:view": "collection:view",
  "collection:delete": "collection:delete",
};

export const menuPermissions = {
  "menu:list": "menu:list",
  "menu:create": "menu:create",
  "menu:edit": "menu:edit",
  "menu:view": "menu:view",
  "menu:delete": "menu:delete",
};

export const customerPermissions = {
  "customer:list": "customer:list",
  "customer:create": "customer:create",
  "customer:edit": "customer:edit",
  "customer:view": "customer:view",
  "customer:delete": "customer:delete",
};

export const tagPermissions = {
  "tag:list": "tag:list",
  "tag:create": "tag:create",
  "tag:edit": "tag:edit",
  "tag:view": "tag:view",
  "tag:delete": "tag:delete",
};

export const discountPermissions = {
  "discount:list": "discount:list",
  "discount:create": "discount:create",
  "discount:edit": "discount:edit",
  "discount:view": "discount:view",
  "discount:delete": "discount:delete",
};

export const analyticsPermissions = {
  "analytics:list": "analytics:list",
  "analytics:create": "analytics:create",
  "analytics:edit": "analytics:edit",
  "analytics:view": "analytics:view",
  "analytics:delete": "analytics:delete",
};

// Setting Permission
export const generalSettings = {
  "general-settings:list": "general-settings:list",
  "general-settings:create": "general-settings:create",
  "general-settings:edit": "general-settings:edit",
  "general-settings:view": "general-settings:view",
  "general-settings:delete": "general-settings:delete",
};

// -account-settings
export const accountSettings = {
  "account-settings:list": "account-settings:list",
  "account-settings:create": "account-settings:create",
  "account-settings:edit": "account-settings:edit",
  "account-settings:view": "account-settings:view",
  "account-settings:delete": "account-settings:delete",
};

// 	-staff-account-settings
export const staffAcountSettings = {
  "staff-account-settings:list": "staff-account-settings:list",
  "staff-account-settings:create": "staff-account-settings:create",
  "staff-account-settings:edit": "staff-account-settings:edit",
  "staff-account-settings:view": "staff-account-settings:view",
  "staff-account-settings:delete": "staff-account-settings:delete",
};

// 	-role-settings

export const roleSetting = {
  "role-settings:list": "role-settings:list",
  "role-settings:create": "role-settings:create",
  "role-settings:edit": "role-settings:edit",
  "role-settings:view": "role-settings:view",
  "role-settings:delete": "role-settings:delete",
};

// 	-online-settings
export const onlineSetting = {
  "online-settings:list": "online-settings:list",
  "online-settings:create": "online-settings:create",
  "online-settings:edit": "online-settings:edit",
  "online-settings:view": "online-settings:view",
  "online-settings:delete": "online-settings:delete",
};
// 	-payment-settings
export const paymentSetting = {
  "payment-settings:list": "payment-settings:list",
  "payment-settings:create": "payment-settings:create",
  "payment-settings:edit": "payment-settings:edit",
  "payment-settings:view": "payment-settings:view",
  "payment-settings:delete": "payment-settings:delete",
};
// 	-email-settings
export const emailSetting = {
  "email-setting:list": "email-setting:list",
  "email-setting:create": "email-setting:create",
  "email-setting:edit": "email-setting:edit",
  "email-setting:view": "email-setting:view",
  "email-setting:delete": "email-setting:delete",
};

export const shippingMethodSettings = {
  "shipping-method:list": "shipping-method:list",
  "shipping-method:create": "shipping-method:create",
  "shipping-method:edit": "shipping-method:edit",
  "shipping-method:view": "shipping-method:view",
  "shipping-method:delete": "shipping-method:delete",
};

export const zoneSetting = {
  "zone:list": "zone:list",
  "zone:create": "zone:create",
  "zone:edit": "zone:edit",
  "zone:view": "zone:view",
  "zone:delete": "zone:delete",
};

export const checkoutSetting = {
  "checkout-settings:list": "checkout-settings:list",
  "checkout-settings:create": "checkout-settings:create",
  "checkout-settings:edit": "checkout-settings:edit",
  "checkout-settings:view": "checkout-settings:view",
  "checkout-settings:delete": "checkout-settings:delete",
};