import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import BasicButton from "./Buttons/BasicButton";
import FiledButton from "./Buttons/FiledButton";
import { useStateContext } from "../contexts/ContextProvider";
import { useTranslation } from "react-i18next";
import ModalDialogMessage from "./ModalDialogMessage";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ModalDialog({
  children,
  title,
  open,
  setOpen,
  actionTitle,
  action,
  loadingAction,
  cancelTitle,
  cancel,
  deleteTitle,
  deleteAction,
  description,
  disableAction,
}) {
  const { bodyStyles } = useStateContext();
  const { t, i18n } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        open={open}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        dir={i18n.language === "ar" ? "rtl" : ""}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className="capitalize"
        >
          {t(title)}
        </BootstrapDialogTitle>

        <DialogContent dividers>
          {description && (
            <div
              className="font-Inter pt-2 pb-4 mt-1
           font-bold flex items-center justify-start"
              style={{
                color: bodyStyles.text,
              }}
            >
              {t(description)}
            </div>
          )}
          {children && <div>
            <ModalDialogMessage />
            {children}</div>}
        </DialogContent>

        <DialogActions>
          <div className="h-[35px] flex gap-2 justify-end w-full min-w-[500px]">
            {deleteTitle && (
              <BasicButton
                text={deleteTitle}
                onClick={() => deleteAction()}
                textSmall
                borderColor="#D32F2F"
                color="#D32F2F"
              />
            )}

            <div></div>

            {cancelTitle && (
              <BasicButton
                text={cancelTitle}
                onClick={() => cancel()}
                textSmall
              />
            )}

            {actionTitle && (
              <>
                {loadingAction ? (
                  <FiledButton loading />
                ) : (
                  <FiledButton
                    text={actionTitle}
                    onClick={() => action()}
                    textSmall
                    disabled={disableAction}
                  />
                )}
              </>
            )}
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
