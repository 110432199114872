import React, { useState } from 'react';
import BasicInput from '../../../Inputs/BasicInput';
import { BagIcon } from '../../../Svg/MainIcons';
import FiledButton from '../../../Buttons/FiledButton';
import BasicSelecAutoComplete from '../../../Inputs/BasicSelecAutoComplete';
import { useTranslation } from 'react-i18next';
import RtlConversion from '../../../RtlConversion';
import IconPlusText from './IconPlusText';
import BasicButton from '../../../Buttons/BasicButton';
import DragToUpload from '../../../Upload/DragToUpload';
import DragImages from '../../../DragImages';
import { initInstanceState } from '../../../../utils/initStates';

const BasicInformation = ({
  edit,
  setEdit,
  basicInformation,
  storeIndustryOptions,
  setBasicInformation,
  formik,
  onSubmit,
  loading,
  featuredAsset,
  setFeaturedAsset,
  logo,
}) => {
  const { t, i18n } = useTranslation();

  const [instanceState, setInstanceState] = useState(initInstanceState);
  return (
    <div className='border-color p-4 lg:p-7 font-Inter'>
      <div className='w-full flex justify-between text-sm'>
        <p className='uppercase text-[#808080]'>{t('Basic information')}</p>
        <p
          className='text-[#358DEB] cursor-pointer'
          onClick={() => setEdit(!edit)}
        >
          {t('Edit')}
        </p>
      </div>
      {edit ? (
        <RtlConversion rtl={i18n.language === 'ar' ? true : false}>
          <div>
            <div className='flex flex-row gap-5 py-4 flex-wrap justify-between'>
              <div className='w-full md:w-[49%] '>
                <BasicInput
                  label={t('Store name')}
                  placeholder='Ecomlia'
                  value={basicInformation?.storeName}
                  onChange={(e) =>
                    setBasicInformation({
                      ...basicInformation,
                      storeName: e.target.value,
                    })
                  }
                  error={
                    formik.touched.storeName && Boolean(formik.errors.storeName)
                  }
                  helperText={
                    formik.touched.storeName && formik.errors.storeName
                  }
                />
              </div>
              <div className='w-full md:w-[49%] '>
                <BasicInput
                  label={t('Legal Name Of Company')}
                  placeholder='Ecomlia'
                  value={basicInformation?.legalNameOfCompany}
                  onChange={(e) =>
                    setBasicInformation({
                      ...basicInformation,
                      legalNameOfCompany: e.target.value,
                    })
                  }
                  error={
                    formik.touched.legalNameOfCompany &&
                    Boolean(formik.errors.legalNameOfCompany)
                  }
                  helperText={
                    formik.touched.legalNameOfCompany &&
                    formik.errors.legalNameOfCompany
                  }
                />
              </div>
              <div className='w-full md:w-[49%] '>
                <BasicSelecAutoComplete
                  single
                  label={t('Store industry')}
                  placeholder={t('Search for industry')}
                  options={storeIndustryOptions}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) =>
                    // option?.id === (value?.id || null)
                    option === (value || null)
                  }
                  value={basicInformation?.storeIndustry}
                  onChange={(event, newValue) => {
                    setBasicInformation({
                      ...basicInformation,
                      storeIndustry: newValue,
                    });
                  }}
                  textFiled
                  error={
                    formik.touched.storeIndustry &&
                    Boolean(formik.errors.storeIndustry)
                  }
                  helperText={
                    formik.touched.storeIndustry && formik.errors.storeIndustry
                  }
                />
              </div>
            </div>

            <div>
              <div className='flex gap-1 flex-wrap max-w-[250px]'>
                {(featuredAsset.length > 0 || instanceState.loadingUpload) && (
                  <DragImages
                    images={featuredAsset.map((item, index) => ({
                      id: `${index}`,
                      imageUrl: item,
                    }))}
                    setImages={setFeaturedAsset}
                    handleDeleteImage={(i) =>
                      setFeaturedAsset(
                        featuredAsset.filter((image, index) => index !== i)
                      )
                    }
                    loadingUpload={instanceState.loadingUpload}
                    single
                  />
                )}

                {((!instanceState.loadingUpload &&
                  featuredAsset?.length === 0) ||
                  instanceState.uploadMore) && (
                  <DragToUpload
                    setImages={setFeaturedAsset}
                    images={featuredAsset}
                    setLoadingUpload={(num) =>
                      setInstanceState((prev) => {
                        return {
                          ...prev,
                          loadingUpload: num,
                        };
                      })
                    }
                    loadingUpload={instanceState.loadingUpload}
                    setUploadMore={(status) => {
                      setInstanceState((prev) => {
                        return {
                          ...prev,
                          uploadMore: status,
                        };
                      });
                    }}
                    uploadMore={instanceState.uploadMore}
                    single
                    button01={t('Add Logo')}
                  />
                )}
              </div>
              <span className='text-[#d32f2f] text-sm px-1'>
                {formik.errors.featuredAsset}
              </span>
            </div>
            <div
              className=' items-center
            flex justify-between pt-8'
            >
              <div className='flex gap-2  justify-end w-full'>
                <BasicButton
                  text={t('Cancel')}
                  onClick={() => setEdit(false)}
                  textSmall
                />
                {loading ? (
                  <FiledButton type='submit' loading />
                ) : (
                  <FiledButton
                    form='product'
                    text={t('Save')}
                    onClick={() => onSubmit()}
                    minWidth='90px'
                  />
                )}
              </div>
            </div>
          </div>
        </RtlConversion>
      ) : (
        <div className='py-4 lg:py-8 grid grid-cols-1 md:grid-cols-2 gap-4'>
          <IconPlusText
            icon={
              logo ? <img src={logo} className="object-contain " /> : <BagIcon />
            }
            title={basicInformation.storeName}
            detail={basicInformation?.legalNameOfCompany}
          />
          <IconPlusText
            icon={<BagIcon />}
            title={t('Industry')}
            detail={basicInformation?.storeIndustry}
          />
        </div>
      )}
    </div>
  );
};

export default BasicInformation;