import * as Yup from "yup";
import { preferredLanguage } from "../utils/languages";
import { useContext } from "react";
import { storeContext } from "../global/store";

const useMenuValidation = () => {
  const {store} = useContext(storeContext);

  const addMenuSchema = Yup.object().shape({
    featuredAsset: Yup.array(),
    // .min(1, "You must at least add an image of the product")
    // .required("This Field is required")
    languages: Yup.object().shape({
      [store.preferredLanguage]: Yup.object().shape({
        name: Yup.string().required("This Field is required"),
        description: Yup.string().required("This Field is required"),
      }),
    }),
    collections: Yup.array(),
    // .min(1, "You must at least add one collection")
    // .required("This Field is required"),
  });

  const editMenuSchema = Yup.object().shape({
    featuredAsset: Yup.array(),
    // .min(1, "You must at least add an image of the product")
    // .required("This Field is required")
    translationData: Yup.object().shape({
      [store.preferredLanguage]: Yup.object().shape({
        name: Yup.string().required("This Field is required"),
        description: Yup.string().required("This Field is required"),
      }),
    }),

    collections: Yup.array(),
    // .min(1, "You must at least add one collection")
    // .required("This Field is required"),
  });


  return {
    addMenuSchema,
    editMenuSchema
  };
};

export { useMenuValidation };
