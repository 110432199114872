import React from "react";
import Tabs from "./../../../TabsScreens";
import { useStateContext } from "../../../../contexts/ContextProvider";
import { InputAdornment } from "@mui/material";
import { CloseIcon, ArrowLeftIcon } from "../../../Svg/MainIcons";
import BasicInput from "../../../Inputs/BasicInput";
import { useTranslation } from "react-i18next";
import RtlConversion from "../../../RtlConversion";

const ChooseProvider = ({ openChooseProvider, setOpenChooseProvider }) => {
  const { t, i18n } = useTranslation();
  const tabs = [t("Search by payment methods"), t("Search by provider")];

  const { bodyStyles } = useStateContext();
  return (
    <div className="px-6">
      <div
        className="flex items-center gap-2 w-full"
        style={{
          color: bodyStyles?.title || "#808080",
        }}
      >
        <div
          className={`cursor-pointer rounded-full w-10
                        duration-300 hover:bg-[rgba(0,0,0,0.089)] p-1 ${
                        i18n.language === "ar" && "rotate-180 "
                    }`}
          onClick={() => setOpenChooseProvider(false)}
        >
          <ArrowLeftIcon color={bodyStyles?.title} />
        </div>
        <h1 className="font-semibold text-xl uppercase  ">{t("Payment")}</h1>
      </div>
      <Tabs screens={tabs}>
        <div key={0} className="space-y-16 font-urbanist mt-5 md:mt-8">
          <div className="w-full flex flex-col items-start justify-start">
            <div className="w-full flex flex-row items-center justify-between  max-w-[243px] p-4">
              <img
                src="/icons/PayPal.svg"
                alt="PayPal"
                className=" block cursor-pointer"
              />
              <CloseIcon />
            </div>
            <div className="flex flex-row items-start justify-center w-full max-w-[750px]">
              <div className="flex flex-col items-center justify-center w-full py-8  gap-y-4">
                <RtlConversion rtl={i18n.language === "ar" ? true : false}>
                  <BasicInput
                    label={t("Search by payment methods")}
                    placeholder="e.g. paypal"
                    // value={fields?.webSite}
                    // onChange={(e) => {
                    //   setFields({
                    //     ...fields,
                    //     webSite: e.target.value,
                    //   });
                    // }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src="/icons/Search.svg"
                            alt="Search"
                            className={`cursor-pointer `}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </RtlConversion>
              </div>
            </div>
          </div>
        </div>
        <div key={1} className="space-y-6 font-urbanist mt-5 md:mt-8">
          <div className="w-full flex flex-col items-start justify-start">
            <div className="flex flex-row items-start justify-center w-full max-w-[750px]">
              <div className="flex flex-col items-center justify-center w-full py-8  gap-y-4">
                <RtlConversion rtl={i18n.language === "ar" ? true : false}>
                  <BasicInput
                    label={t("Search by provider")}
                    placeholder="e.g."
                    // value={fields?.webSite}
                    // onChange={(e) => {
                    //   setFields({
                    //     ...fields,
                    //     webSite: e.target.value,
                    //   });
                    // }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src="/icons/Search.svg"
                            alt="Search"
                            className={`cursor-pointer `}
                          />
                        </InputAdornment>
                      ),
                      // style: { fontSize: "16px" },
                    }}
                  />
                </RtlConversion>
              </div>
            </div>
          </div>
        </div>
      </Tabs>
    </div>
  );
};

export default ChooseProvider;
