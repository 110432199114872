import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingDelete: false,
  loadingDeleteAll: false,
  loadingCreate: false,
  loadingEdit: false,
  loadingEditMany: false,
  loadingImport: false,
  data: [],
  error: null,
  errorFetch: null,
  errorEditMany: null,
  success: null,
  totalCount: 0,
  selectedOne: null,
  selectedMany: [],
  isSelectedMany: false,
  deleteModal: false,
  deleteAllModal: false,
  createModal: false,
  createAllModal: false,
  editModal: false,
  editAllModal: false,
  exportModal: false,
  exportSelectedModal: false,
  importModal: false,
};

export const variantSlice = createSlice({
  name: 'variant',
  initialState,
  reducers: {
    // fetchInstance
    fetchVariantsStart: (state) => {
      state.loading = true;
    },
    fetchVariantsSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload?.variants?.nodes;
      state.totalCount = action.payload?.variants?.totalCount;
      state.errorFetch = false;
    },
    fetchVariantsFail: (state, action) => {
      state.loading = false;
      state.data = [];
      state.errorFetch = action.payload;
    },

    // createInstance
    createVariantStart: (state) => {
      state.loadingCreate = true;
    },
    createVariantSuccess: (state, action) => {
      state.loadingCreate = false;
      state.data.unshift(action.payload);
      state.success = 'Variant created successfully.';
    },
    createVariantFail: (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    },

    // editInstance
    editVariantStart: (state) => {
      state.loadingEdit = true;
    },
    editVariantSuccess: (state, action) => {
      state.loadingEdit = false;
      state.data = state.data.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      state.success = 'Variant updated successfully.';
    },
    editVariantFail: (state, action) => {
      state.loadingEdit = false;
      state.error = action.payload;
    },

    // edit Many Instance
    editManyVariantStart: (state) => {
      state.loadingEditMany = true;
    },
    editManyVariantSuccess: (state, action) => {
      state.loadingEditMany = false;
      state.data = state.data.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      state.success = 'Variants updated successfully.';
    },
    editVManyariantFail: (state, action) => {
      state.loadingEditMany = false;
      state.errorEditMany = action.payload;
    },

   

    // delete Instance
    deleteVariantStart: (state) => {
      state.loadingDelete = true;
    },
    deleteVariantSuccess: (state, action) => {
      state.loadingDelete = false;
      state.data = state.data.filter((item) => item.id !== action.payload);
      state.success = 'Variant deleted successfully';
      state.deleteModal = false;
    },
    deleteVariantFail: (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
      state.deleteModal = false;
    },

    // delete Many Instances
    deleteVariantsStart: (state) => {
      state.loadingDeleteAll = true;
    },
    deleteVariantsSuccess: (state, action) => {
      state.loadingDeleteAll = false;
      const deletedIds = action.payload;
      state.data = state.data.filter((item) => !deletedIds.includes(item.id));
      state.success = 'Variants deleted successfully';
      state.deleteAllModal = false;
    },
    deleteVariantsFail: (state, action) => {
      state.loadingDeleteAll = false;
      state.error = action.payload;
      state.deleteAllModal = false;
    },

    // selectInstance

    selectOne: (state, action) => {
      state.selectedOne = action.payload;
    },

    selectAll: (state, action) => {
      if (state.isSelectedMany) {
        state.selectedMany = [];
        state.isSelectedMany = false;
      } else {
        state?.data?.map((item) => {
          if (!state.selectedMany.includes(item.id)) {
            state.selectedMany = [...state.selectedMany, item.id];
          }
        });
        state.isSelectedMany = true;
      }
    },

    removeAll: (state, action) => {
      state.selectedMany = [];
      state.isSelectedMany = false;
    },

    updateSelected: (state, action) => {
      if (state.selectedMany.includes(action.payload)) {
        state.selectedMany = state.selectedMany.filter((item) => {
          return item !== action.payload;
        });
      } else {
        state.selectedMany = [...state.selectedMany, action.payload];
      }
    },

    // DELETEiNSTANCE

    openModelDelete: (state, action) => {
      state.selectedOne = action.payload;
      state.deleteModal = !state.deleteModal;
    },

    openModelDeleteAll: (state, action) => {
      state.deleteAllModal = !state.deleteAllModal;
    },

    // ExportSelected

    openModeleExportAll: (state, action) => {
      state.exportModal = true;
    },

    openModeleExportSelected: (state, action) => {
      state.exportSelectedModal = true;
    },


    openModelCreate: (state, action) => {
      state.createModal = !state.createModal;
    },

    openModelEdit: (state, action) => {
      state.selectedOne = action.payload;
      state.editModal = !state.editModal;
    },

    // Import

    openModeleImport: (state, action) => {
      state.importModal = !state.importModal;
    },

    importStart: (state) => {
      state.loadingImport = true;
    },
    importSuccess: (state, action) => {
      state.loadingImport = false;
      state.success = 'Variants Imported successfully';
      state.importModal = false;
    },
    importFail: (state, action) => {
      state.loadingImport = false;
      state.error = action.payload;
      state.importModal = false;
    },

    // CLEAR
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  fetchVariantsStart,
  fetchVariantsSuccess,
  fetchVariantsFail,
  createVariantStart,
  createVariantSuccess,
  createVariantFail,
  editVariantStart,
  editVariantSuccess,
  editVariantFail,
  deleteVariantStart,
  deleteVariantSuccess,
  deleteVariantFail,
  clearMessages,
  updateSelected,
  selectAll,
  selectOne,
  openModelDelete,
  openModelDeleteAll,
  openModeleExportAll,
  openModeleExportSelected,
  deleteVariantsStart,
  deleteVariantsSuccess,
  deleteVariantsFail,
  openModeleImport,
  importStart,
  importSuccess,
  importFail,
  removeAll,
  editManyVariantStart,
  editManyVariantSuccess,
  editVManyariantFail,
  openModelCreate,
  openModelEdit
} = variantSlice.actions;

export default variantSlice.reducer;
