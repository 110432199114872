import * as Yup from "yup";
import { useContext } from "react";
import { storeContext } from "../global/store";

const useZoneValidation = () => {
  const { store } = useContext(storeContext);

  const addZoneSchema = Yup.object().shape({
    translationData: Yup.object().shape({
      [store.preferredLanguage]: Yup.object().shape({
        name: Yup.string().required("This Field is required"),
        description: Yup.string(),
        //   .required("This Field is required"),
      }),
    }),
  });

  const editZoneSchema = Yup.object().shape({
    translationData: Yup.object().shape({
      [store.preferredLanguage]: Yup.object().shape({
        name: Yup.string().required("This Field is required"),
        description: Yup.string(),
        //   .required("This Field is required"),
      }),
    }),
  });

  return {
    addZoneSchema,
    editZoneSchema,
  };
};

export { useZoneValidation };
