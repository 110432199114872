import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingDelete: false,
  loadingDeleteAll: false,
  loadingCreate: false,
  loadingEdit: false,
  loadingImport: false,
  data: [],
  error: null,
  errorFetch: null,
  success: null,
  totalCount: 0,
  selectedOne: null,
  selectedMany: [],
  isSelectedMany: false,
  deleteModal: false,
  deleteAllModal: false,
  createModal: false,
  createAllModal: false,
  editModal: false,
  editAllModal: false,
  exportModal: false,
  exportSelectedModal: false,
  importModal: false,

  sendRecoveryEmailModal: false,
  loadingSendRecoveryEmail: false,
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    // fetchInstance
    fetchCheckoutsStart: (state) => {
      state.loading = true;
    },
    fetchCheckoutsSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload?.checkouts?.nodes;
      state.totalCount = action.payload?.checkouts?.totalCount;
      state.errorFetch = false;
    },
    fetchCheckoutsFail: (state, action) => {
      state.loading = false;
      state.data = [];
      state.errorFetch = action.payload;
    },

    // createInstance
    createCheckoutStart: (state) => {
      state.loadingCreate = true;
    },
    createCheckoutSuccess: (state, action) => {
      state.loadingCreate = false;
      state.data.unshift(action.payload);
      state.success = 'Checkout created successfully.';
    },
    createCheckoutFail: (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    },

    // editInstance
    editCheckoutStart: (state) => {
      state.loadingEdit = true;
    },
    editCheckoutSuccess: (state, action) => {
      state.loadingEdit = false;
      state.data = state.data.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      state.success = 'Checkout updated successfully.';
    },
    editCheckoutFail: (state, action) => {
      state.loadingEdit = false;
      state.error = action.payload;
    },

    // delete Instance
    deleteCheckoutStart: (state) => {
      state.loadingDelete = true;
    },
    deleteCheckoutSuccess: (state, action) => {
      state.loadingDelete = false;
      state.data = state.data.filter((item) => item.id !== action.payload);
      state.success = 'Checkout deleted successfully';
      state.deleteModal = false;
    },
    deleteCheckoutFail: (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
      state.deleteModal = false;
    },

    // delete Many Instances
    deleteCheckoutsStart: (state) => {
      state.loadingDeleteAll = true;
    },
    deleteCheckoutsSuccess: (state, action) => {
      state.loadingDeleteAll = false;
      const deletedIds = action.payload;
      state.data = state.data.filter((item) => !deletedIds.includes(item.id));
      state.success = 'Checkouts deleted successfully';
      state.deleteAllModal = false;
    },
    deleteCheckoutsFail: (state, action) => {
      state.loadingDeleteAll = false;
      state.error = action.payload;
      state.deleteAllModal = false;
    },

    // selectInstance

    selectOne: (state, action) => {
      state.selectedOne = action.payload;
    },

    selectAll: (state, action) => {
      if (state.isSelectedMany) {
        state.selectedMany = [];
        state.isSelectedMany = false;
      } else {
        state?.data?.map((item) => {
          if (!state.selectedMany.includes(item.id)) {
            state.selectedMany = [...state.selectedMany, item.id];
          }
        });
        state.isSelectedMany = true;
      }
    },

    removeAll: (state, action) => {
      state.selectedMany = [];
      state.isSelectedMany = false;
    },

    updateSelected: (state, action) => {
      if (state.selectedMany.includes(action.payload)) {
        state.selectedMany = state.selectedMany.filter((item) => {
          return item !== action.payload;
        });
      } else {
        state.selectedMany = [...state.selectedMany, action.payload];
      }
    },

    // DELETEiNSTANCE

    openModelDelete: (state, action) => {
      state.selectedOne = action.payload;
      state.deleteModal = !state.deleteModal;
    },

    openModelDeleteAll: (state, action) => {
      state.deleteAllModal = !state.deleteAllModal;
    },

    openModelRecoveryEmail: (state, action) => {
      state.sendRecoveryEmailModal = !state.sendRecoveryEmailModal;
    },

    sendRecoveryEmailStart: (state) => {
      state.loadingSendRecoveryEmail = true;
    },
    sendRecoveryEmailSuccess: (state, action) => {
      state.loadingSendRecoveryEmail = false;
      state.sendRecoveryEmailModal = false;
    },
    sendRecoveryEmailFail: (state, action) => {
      state.loadingSendRecoveryEmail = false;
      state.error = action.payload;
      state.sendRecoveryEmailModal = false;
    },

    // ExportSelected

    openModeleExportAll: (state, action) => {
      state.exportModal = true;
    },

    openModeleExportSelected: (state, action) => {
      state.exportSelectedModal = true;
    },

    // Import

    openModeleImport: (state, action) => {
      state.importModal = !state.importModal;
    },

    importStart: (state) => {
      state.loadingImport = true;
    },
    importSuccess: (state, action) => {
      state.loadingImport = false;
      state.success = 'Checkouts Imported successfully';
      state.importModal = false;
    },
    importFail: (state, action) => {
      state.loadingImport = false;
      state.error = action.payload;
      state.importModal = false;
    },

    // CLEAR
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  fetchCheckoutsStart,
  fetchCheckoutsSuccess,
  fetchCheckoutsFail,
  createCheckoutStart,
  createCheckoutSuccess,
  createCheckoutFail,
  editCheckoutStart,
  editCheckoutSuccess,
  editCheckoutFail,
  deleteCheckoutStart,
  deleteCheckoutSuccess,
  deleteCheckoutFail,
  clearMessages,
  updateSelected,
  selectAll,
  selectOne,
  openModelDelete,
  openModelDeleteAll,
  openModeleExportAll,
  openModeleExportSelected,
  deleteCheckoutsStart,
  deleteCheckoutsSuccess,
  deleteCheckoutsFail,
  openModeleImport,
  importStart,
  importSuccess,
  importFail,
  removeAll,

  openModelRecoveryEmail,
  sendRecoveryEmailStart,
  sendRecoveryEmailSuccess,
  sendRecoveryEmailFail,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
