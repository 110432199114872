import React, { useContext, useEffect, useState } from 'react';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import { defaultDatat } from '../../../../utils/helpers';
import BasicInput from '../../../Inputs/BasicInput';
import ModalDialog from '../../../ModalDialog';
import { storeContext } from '../../../../global/store';
import { useFormik } from 'formik';
import BasicSelecAutoComplete from '../../../Inputs/BasicSelecAutoComplete';
import { FormControl, InputAdornment } from '@mui/material';
import Tabs from '../../../TabsLanguages';
import RadioGroupType from '../../../Inputs/RadioGroupType';
import { useShippingMethodeValidation } from '../../../../validations/shippingMethode.validations';
import { openModelEdit } from '../../../../store/features/shippingMethode/shippingMethodeSlicer';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleEditShippingMethode,
} from '../../../../services/shippingMethod';
const EditShippingAdressModal = () => {
  const { store } = useContext(storeContext);
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  const dispatch = useDispatch();

  const { shippingMethode: data, zone: zoneState } = useSelector(
    (state) => state
  );

  const { addShippingMethodeSchema } = useShippingMethodeValidation();
  const [translationData, setTranslationData] = useState({
    [store.preferredLanguage]: { name: '', description: '', rateName: '' },
  });
  useEffect(() => {
    if (data.selectedOne) {
      let prevTranslationData = {};
      data.selectedOne?.translationData.map((item, index) => {
        prevTranslationData = {
          ...prevTranslationData,
          [item?.languageCode.toLowerCase()]: item,
        };
      });
      setTranslationData(prevTranslationData);

      setFields((prevState) => ({
        ...prevState,
        zones: data.selectedOne?.zone ? [data.selectedOne?.zone] : [],
        price: data.selectedOne.default_price,
      }));
    }
  }, [data.selectedOne]);

  const [fields, setFields] = useState({
    name: '',
    code: '',
    description: '',
    zones: [],
    price: 0,
    conditionBasedOn: 'price',
    withCondition: false,
    minWeigh: 0,
    maxWeigh: 0,
    minPrice: 0,
    maxPrice: 0,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      translationData: translationData,
      zones: fields.zones,
      withCondition: fields.withCondition,
      minPrice: fields.minPrice,
      maxPrice: fields.maxPrice,
      conditionBasedOn: fields.conditionBasedOn,
      price: fields.price,
      store: store?.currencyStore?.currencyCode,
      id: +data?.selectedOne?.id,
    },
    validationSchema: addShippingMethodeSchema,
    onSubmit: async (values) => {
      dispatch(
        handleEditShippingMethode(values, (res) => {
          closeModal();
        })
      );
    },
  });

  const closeModal = () => {
    dispatch(openModelEdit());
    setFields({
      name: '',
      code: '',
      description: '',
      zones: [],
      price: 0,
      conditionBasedOn: 'price',
      withCondition: false,
      minWeigh: 0,
      maxWeigh: 0,
      minPrice: 0,
      maxPrice: 0,
    });

    setTranslationData({
      [store.preferredLanguage]: { name: '', description: '', rateName: '' },
    });
  };

  return (
    <div>
      <ModalDialog
        open={data?.editModal}
        setOpen={() => closeModal()}
        title={t('Edit Shipping Methode')}
        cancelTitle={t('Cancel')}
        cancel={() => closeModal()}
        actionTitle={t('save Shipping Methode')}
        action={() => formik.handleSubmit()}
        loadingAction={data?.loadingEdit}
      >
        <form
          id='add-discount-modal'
          className='flex gap-5 flex-col md:w-[550px] py-0'
        >
          <div className='flex flex-col font-Inter gap-4  p-0 m-0 -mt-2 pb-5   '>
            <div className=''>
              <Tabs availableLanguages={store?.availableLanguages} small fixed>
                {store?.availableLanguages?.map((item, indexLanguages) => (
                  <div key={indexLanguages} className='font-urbanist'>
                    <div className='w-[98%]'>
                      <div
                        className='font-Inter text-sm pt-4 pb-4 mt-4
                                      uppercase font-bold flex items-center justify-start'
                        style={{
                          color: bodyStyles.subTitle,
                        }}
                      >
                        <span>{`${t('Shipping information')}`}</span>
                      </div>

                      <div className='flex flex-col  gap-6 '>
                        <BasicInput
                          label={t('Name')}
                          placeholder={t('Shipping Name')}
                          value={translationData[item.code]?.name}
                          onChange={(e) => {
                            setTranslationData({
                              ...translationData,
                              [item?.code]: {
                                ...translationData[item?.code],
                                name: e.target.value,
                              },
                            });
                          }}
                          error={
                            item?.code === store.preferredLanguage &&
                            formik?.touched?.translationData &&
                            Boolean(
                              formik?.errors?.translationData &&
                                formik?.errors?.translationData[
                                  store.preferredLanguage
                                ]?.name
                            )
                          }
                          helperText={
                            item?.code === store.preferredLanguage &&
                            formik?.touched?.translationData &&
                            formik?.errors?.translationData &&
                            formik?.errors?.translationData[
                              store.preferredLanguage
                            ]?.name
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                fontSize='small'
                                position='end'
                                sx={{ color: bodyStyles?.inputText }}
                              >
                                <span className='text-xs'>
                                  {item?.code?.toUpperCase()}
                                </span>
                              </InputAdornment>
                            ),
                            style: { fontSize: '12px' },
                          }}
                        />

                        <BasicInput
                          label={t('Description')}
                          placeholder='Tristique viverra risus mauris nunc arcu suspendisse id.
                             Condimentum morbi arcu lectus et sagittis. Sit et, justo tristique vel vestibulum. '
                          rows={3}
                          value={translationData[item.code]?.description}
                          onChange={(e) => {
                            setTranslationData({
                              ...translationData,
                              [item?.code]: {
                                ...translationData[item?.code],
                                description: e.target.value,
                              },
                            });
                          }}
                          error={
                            item?.code === store.preferredLanguage &&
                            formik?.touched?.translationData &&
                            Boolean(
                              formik?.errors?.translationData &&
                                formik?.errors?.translationData[
                                  store.preferredLanguage
                                ]?.description
                            )
                          }
                          helperText={
                            item?.code === store.preferredLanguage &&
                            formik?.touched?.translationData &&
                            formik?.errors?.translationData &&
                            formik?.errors?.translationData[
                              store.preferredLanguage
                            ]?.description
                          }
                        />

                        <BasicSelecAutoComplete
                          single
                          label={t('Zones')}
                          placeholder='Search for Zone'
                          freeSolo
                          options={zoneState?.data ? zoneState?.data : []}
                          getOptionLabel={(option) =>
                            ` ${defaultDatat(
                              option?.translationData,
                              i18n.language,
                              'name'
                            )} (${option?.countries?.length})`
                          }
                          isOptionEqualToValue={(option, value) =>
                            option?.id === (value?.id || null)
                          }
                          value={
                            fields?.zones?.length ? fields?.zones[0] : null
                          }
                          onChange={(event, newValue) => {
                            setFields({
                              ...fields,
                              zones: newValue ? [newValue] : [],
                            });
                          }}
                          error={
                            formik.touched.zones && Boolean(formik.errors.zones)
                          }
                          helperText={
                            formik.touched.zones && formik.errors.zones
                          }
                        />

                        <BasicInput
                          label={t('Rate Name')}
                          placeholder='standard'
                          value={translationData[item.code]?.rateName}
                          onChange={(e) => {
                            setTranslationData({
                              ...translationData,
                              [item?.code]: {
                                ...translationData[item?.code],
                                rateName: e.target.value,
                              },
                            });
                          }}
                          error={
                            item?.code === store.preferredLanguage &&
                            formik?.touched?.translationData &&
                            Boolean(
                              formik?.errors?.translationData &&
                                formik?.errors?.translationData[
                                  store.preferredLanguage
                                ]?.rateName
                            )
                          }
                          helperText={
                            item?.code === store.preferredLanguage &&
                            formik?.touched?.translationData &&
                            formik?.errors?.translationData &&
                            formik?.errors?.translationData[
                              store.preferredLanguage
                            ]?.rateName
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                fontSize='small'
                                position='end'
                                sx={{ color: bodyStyles?.inputText }}
                              >
                                <span className='text-xs'>
                                  {item?.code?.toUpperCase()}
                                </span>
                              </InputAdornment>
                            ),
                            style: { fontSize: '12px' },
                          }}
                        />

                        <BasicInput
                          label={t('price')}
                          placeholder='200'
                          number
                          value={fields?.price}
                          onChange={(e) =>
                            setFields({
                              ...fields,
                              price: +e.target.value,
                            })
                          }
                          error={
                            formik.touched.price && Boolean(formik.errors.price)
                          }
                          helperText={
                            formik.touched.price && formik.errors.price
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                fontSize='small'
                                position='end'
                                sx={{ color: bodyStyles?.inputText }}
                              >
                                <span className='text-xs'>
                                  {store?.currencyStore?.currencyCode}
                                </span>
                              </InputAdornment>
                            ),
                            style: { fontSize: '12px' },
                          }}
                        />

                        {/* withCondition */}

                        <div className='flex flex-col gap-1'>
                          {fields.withCondition && (
                            <div className='flex flex-col gap-5'>
                              <FormControl>
                                <RadioGroupType
                                  value={fields?.conditionBasedOn}
                                  onChange={(e) =>
                                    setFields({
                                      ...fields,
                                      conditionBasedOn: e.target.value,
                                    })
                                  }
                                  gapY={0}
                                  options={[
                                    {
                                      value: 'weight',
                                      label: t('Based on item weight'),
                                    },
                                    {
                                      value: 'price',
                                      label: t('Based on order price'),
                                    },
                                  ]}
                                />
                              </FormControl>

                              {fields?.conditionBasedOn === 'weight' ? (
                                <div className='flex gap-8 flex-col md:flex-row'>
                                  <BasicInput
                                    label={t('Minimum weight')}
                                    placeholder='1'
                                    number
                                    size='small'
                                    //  disabled={fields?.minWeigh}
                                    value={fields?.minWeigh}
                                    onChange={(e) =>
                                      setFields({
                                        ...fields,
                                        minWeigh: +e.target.value,
                                      })
                                    }
                                    error={
                                      formik.touched.minWeigh &&
                                      Boolean(formik.errors.minWeigh)
                                    }
                                    helperText={
                                      formik.touched.price &&
                                      formik.errors.minWeigh
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          fontSize='small'
                                          position='end'
                                          sx={{ color: bodyStyles?.inputText }}
                                        >
                                          <span className='text-xs'>Kg</span>
                                        </InputAdornment>
                                      ),
                                      style: { fontSize: '12px' },
                                    }}
                                  />

                                  <BasicInput
                                    label={t('Maximum weight')}
                                    placeholder='1'
                                    number
                                    size='small'
                                    value={fields?.maxWeigh}
                                    onChange={(e) =>
                                      setFields({
                                        ...fields,
                                        maxWeigh: +e.target.value,
                                      })
                                    }
                                    error={
                                      formik.touched.maxWeigh &&
                                      Boolean(formik.errors.maxWeigh)
                                    }
                                    helperText={
                                      formik.touched.maxWeigh &&
                                      formik.errors.maxWeigh
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          fontSize='small'
                                          position='end'
                                          sx={{ color: bodyStyles?.inputText }}
                                        >
                                          <span className='text-xs'>Kg</span>
                                        </InputAdornment>
                                      ),
                                      style: { fontSize: '12px' },
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className='flex gap-8 flex-col md:flex-row'>
                                  <BasicInput
                                    label={t('Minimum Price')}
                                    placeholder='50'
                                    number
                                    size='small'
                                    value={fields?.minPrice}
                                    onChange={(e) =>
                                      setFields({
                                        ...fields,
                                        minPrice: +e.target.value,
                                      })
                                    }
                                    error={
                                      formik.touched.minWeigh &&
                                      Boolean(formik.errors.minPrice)
                                    }
                                    helperText={
                                      formik.touched.minPrice &&
                                      formik.errors.minPrice
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          fontSize='small'
                                          position='end'
                                          sx={{ color: bodyStyles?.inputText }}
                                        >
                                          <span className='text-xs'>
                                            {store?.currencyStore?.currencyCode}
                                          </span>
                                        </InputAdornment>
                                      ),
                                      style: { fontSize: '12px' },
                                    }}
                                  />

                                  <BasicInput
                                    label={t('Maximum Price')}
                                    placeholder='250'
                                    number
                                    size='small'
                                    value={fields?.maxPrice}
                                    onChange={(e) =>
                                      setFields({
                                        ...fields,
                                        maxPrice: +e.target.value,
                                      })
                                    }
                                    error={
                                      formik.touched.maxPrice &&
                                      Boolean(formik.errors.maxPrice)
                                    }
                                    helperText={
                                      formik.touched.maxPrice &&
                                      formik.errors.maxPrice
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          fontSize='small'
                                          position='end'
                                          sx={{ color: bodyStyles?.inputText }}
                                        >
                                          <span className='text-xs'>
                                            {store?.currencyStore?.currencyCode}
                                          </span>
                                        </InputAdornment>
                                      ),
                                      style: { fontSize: '12px' },
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Tabs>
            </div>
          </div>
        </form>
      </ModalDialog>
    </div>
  );
};

export default EditShippingAdressModal;
