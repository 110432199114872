import * as React from "react";
import TextField from "@mui/material/TextField";
import { useStateContext } from "../../contexts/ContextProvider";
import { NumericFormat, NumberFormatBase } from "react-number-format";

const BasicInput = ({
  label,
  placeholder,
  value,
  onChange,
  error,
  helperText,
  InputProps,
  rows,
  numberFormat,
  number,
  priceFormat,
  priceFormatSepration,
  size,
  disabledColor,
  disabled,
  ...rest
}) => {
  const { bodyStyles } = useStateContext();

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      InputProps={{
        ...InputProps,
        inputComponent: number
          ? NumberCustom
          : numberFormat
          ? NumberFormatCustom
          : priceFormat ? PriceFormat
          :priceFormatSepration && PriceFormatSepration,
      }}
      rows={rows ? rows : ""}
      multiline={rows ? true : false}
      className={`z-0 `}
      size={size}
      disabled={disabled}
      sx={{
        width: "100%",
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: disabledColor ? disabledColor : "",
        },
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: `0 0 0 100px ${bodyStyles?.background} inset`,
            WebkitTextFillColor: bodyStyles?.inputText,
          },
        },

        color: bodyStyles?.inputText,

        "& label": {
          top: value ? "0px" : "-2px",
          paddingRight: "4px",
          backgroundColor: bodyStyles.background,
          color: bodyStyles?.inputSubText,
        },

        "& label.Mui-focused": {
          color: bodyStyles?.text,
          backgroundColor: bodyStyles.background,
          paddingRight: "4px",
          top: "0",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: bodyStyles?.inputText,
          paddingRight: "4px",
        },
        "& .MuiOutlinedInput-root": {
          display: "flex",
          color: bodyStyles?.inputText,
          alignItems: "center",

          "& fieldset": {
            borderColor: bodyStyles?.inputBorder,
            
          },
          "&:hover fieldset": {
            borderColor: !disabled && bodyStyles?.inputBorder,
            display: "flex",
            alignItems: "center",
          },
          "&.Mui-focused fieldset": {
            borderColor: bodyStyles?.inputBorderSelected,
          },
        },
        "& .MuiSvgIcon-root": {
          color: !disabled && bodyStyles?.inputSubText,
        },
      }}
      {...rest}
    />
  );
};

export default BasicInput;

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { inputRef, value, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      value={+value}
      thousandSeparator=" "
    />
  );
});

const NumberCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { inputRef, value, onChange, ...other } = props;

  return (
    <NumberFormatBase
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      value={+value}
      isNumericString
    />
  );
});

const PriceFormat = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { inputRef, value, onChange, priceFormatSepration, ...other  } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      value={+value}
      thousandSeparator=""
      isNumericString
    />
  );
});


const PriceFormatSepration = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { inputRef, value, onChange, ...other  } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      value={+value}
      thousandSeparator=","
      isNumericString
    />
  );
});
