import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "de",
    resources: {
      de: {
        translations: require("./translations/de.json"),
      },
      en: {
        translations: require("./translations/en.json"),
      },
      fr: {
        translations: require("./translations/fr.json"),
      },

    },
    ns: ["translations"],
    defaultNS: "translations",
    detection: {
      // order and from where user language should be detected
      order: ["localStorage"],
      // keys or params to lookup language from
      lookupLocalStorage: "i18nextLng",
      // cache user language on
      caches: ["localStorage"],
      excludeCacheFor: [],
    },
    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
  });

// i18n.languages = ["de", "en", "fr"];
i18n.languages = ["de"];

export default i18n;
