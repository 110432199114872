import React from 'react';
import { ArrowRightIcon, } from '../../Svg/MainIcons';
import { useStateContext } from '../../../contexts/ContextProvider';

const InstanceEdit = ({ openModalEdit }) => {
  const { bodyStyles } = useStateContext();

  return (
    <div className='flex gap-1.5 justify-end'>
      <div
        className='flex cursor-pointer'
        onClick={() => {
          openModalEdit();
        }}
      >
        <ArrowRightIcon color={bodyStyles?.text} />
      </div>
    </div>
  );
};

export default InstanceEdit;
