import React from 'react';

import { useStateContext } from '../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

const Pagination = ({
  count,
  pagesize,
  setPage,
  page,
  showPages = 3,
  localPagenation,
}) => {
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const pages = (currentPage, last) => {
    var list = [];
    var Lastcell = null;

    for (var i = 1; i <= last; i++) {
      if (
        i === 1 ||
        (currentPage + 1 === 1 && i <= showPages) ||
        i === last ||
        (currentPage + 1 === last && i >= last - (showPages - 1)) ||
        i === currentPage + 1 ||
        (!(i <= currentPage + 1 - Math.ceil(showPages / 2)) &&
          i <= currentPage) ||
        (!(i >= currentPage + 1 + Math.floor(showPages / 2) + 1) &&
          i >= currentPage) ||
        (currentPage < Math.ceil(showPages / 2) &&
          !(
            i >=
            currentPage +
              1 +
              Math.floor(showPages / 2) +
              (Math.ceil(showPages / 2) - (currentPage + 1 - 1))
          ) &&
          i >= currentPage) ||
        (last - (currentPage + 1) < Math.ceil(showPages / 2) &&
          !(
            i <=
            currentPage +
              1 -
              (Math.ceil(showPages) - (last - (currentPage + 1)))
          ) &&
          i <= currentPage)
      ) {
        list.push(i);
        Lastcell = `${i}`;
      } else {
        if (Lastcell !== '...') {
          list.push('...');
          Lastcell = '...';
        }
      }
    }
    return list;
  };

  const paginate = (e, value) => {
    e.preventDefault();
    if (localPagenation) {
    } else {
      let newQueryParameters = new URLSearchParams(location.search);
      newQueryParameters.set('page', value + 1);
      setSearchParams(newQueryParameters);
    }
  };

  return (
    <div className='md-sm:gap-x-4 mb-[40px] flex flex-row items-center justify-center gap-x-2 sm:gap-x-6'>
      <div
        className='m-0 flex flex-row gap-0 overflow-hidden rounded-md border p-0'
        style={{
          borderColor: bodyStyles?.text,
        }}
      >
        <div
          className='cursor-pointer px-1 hover:opacity-[90%] '
          onClick={(e) => {
            if (page + 1 > 1) {
              setPage(page - 1);
              paginate(e, page - 1);
            }
          }}
          disabled={page > 0 ? false : true}
        >
          <span className=' flex h-full w-[35px] items-center justify-center text-xs font-semibold  '>
            {t('Prev')}
          </span>
        </div>

        <div className='flex flex-row items-center justify-center gap-0'>
          {pages(page, Math.ceil(count / pagesize)).map((pageCell, index) => (
            <Cell
              key={index}
              setPage={setPage}
              page={page}
              number={pageCell}
              paginate={paginate}
            />
          ))}
        </div>

        <div
          className='cursor-pointer px-1 hover:opacity-[90%] '
          onClick={(e) => {
            if (page + 1 < Math.ceil(count / pagesize)) {
              setPage(page + 1);
              paginate(e, page + 1);
            }
          }}
          disabled={page + 1 < Math.ceil(count / pagesize) ? false : true}
        >
          <span className='flex h-full w-[35px] items-center justify-center text-xs font-semibold  '>
            {t('Next')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Pagination;

const Cell = ({ setPage, page, number, paginate }) => {
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();

  return (
    <div
      onClick={(e) => {
        if (number !== '...') {
          setPage(number - 1);
          paginate(e, number - 1);
        }
      }}
      className={`flex h-[30px] min-w-[30px] cursor-pointer items-center justify-center text-[9px] font-semibold duration-300 
      hover:font-[700]  hover:opacity-[90%] md:h-[35px] md:min-w-[35px]  md:text-xs
      ${i18n.language === 'ar' ? ' border-l' : 'border-r'}
      ${number === 1 && i18n.language === 'ar' ? ' border-r' : ''}
      ${number === 1 && i18n.language !== 'ar' ? ' border-l' : ''}
      `}
      style={{
        color:
          page + 1 === number
            ? bodyStyles?.paginationTextActive
            : bodyStyles?.paginationText,
        borderColor: bodyStyles?.text,
        background:
          page + 1 === number ? bodyStyles?.paginationBackgroundActive : '',
        fontWeight: page + 1 === number ? 'bolder' : '',
      }}
    >
      {number}
    </div>
  );
};
