import React from "react";
import { FormControl } from "@mui/material";
import BasicSelectMui from "../../../Inputs/BasicSelectMui";
import { useTranslation } from "react-i18next";
import RtlConversion from "../../../RtlConversion";
import { availableCurrenciesCode } from "../../../../utils/currency";

const StoreCurrency = ({
  generalStoreSetting,
  setGeneralStoreSetting,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="border-t-1 border-color p-4 lg:p-7 font-Inter ">
      <div className="w-full flex justify-start text-sm">
        <p className="uppercase text-[#808080]">{t("Store Currency")}</p>
      </div>
      <p className="text-[#888888] text-sm pt-3 lg:pt-6 ">
        {t("The currency your products are sold in")}
      </p>
      <div className="py-4 lg:py-8 grid grid-cols-1 md:grid-cols-2 gap-4">
        <RtlConversion rtl={i18n.language === "ar" ? true : false}>
          <FormControl fullWidth>
            <BasicSelectMui
              type="text"
              name="currency"
              id="currency"
              label={t("Currency")}
              value={generalStoreSetting?.currency}
              onChange={(e) =>
                setGeneralStoreSetting({
                  ...generalStoreSetting,
                  currency: e.target.value,
                })
              }
              options={availableCurrenciesCode}
            />
          </FormControl>
        </RtlConversion>
      </div>
    </div>
  );
};

export default StoreCurrency;
