import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../contexts/ContextProvider';
import { useNavigate } from 'react-router-dom';


const ModalFetchError = ({ message, returnMessage, action, errorStatus }) => {
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  const navigate = useNavigate()

  console.log('errorStatus', errorStatus)
  return (
    <div className='w-full min-h-[60vh] flex justify-center items-center flex-col '>
      <img
        src={errorStatus ? `/images/${errorStatus}.svg` : '/images/404.svg'}
        alt='Item Not Found'
        className='max-h-[370px] '
      />

      <div className='text-lg font-Inter flex flex-col justify-center items-center gap-2'>
        <p className=''>{t(message)} </p>

        {returnMessage && (
          <button
            className='font-bold text-base capitalize
           cursor-pointer py-2 px-5 shadow-lg rounded-md hover:shadow-lg hover:drop-shadow-md duration-300 '
            style={{
              color: '#fff',
              background: bodyStyles.primary,
            }}
            onClick={() =>action ? action() : navigate(-1)}
          >
            {' '}
            {t(returnMessage)}
          </button>
        )}
      </div>
    </div>
  );
};

export default ModalFetchError;
