import { useTranslation } from 'react-i18next';

import DataGridSection from './DataGridSection';
import PageModal from '../PageModal';
import usePageState from '../../hooks/layout.hook';
import PageModalSetting from '../PageModalSetting';
const FetchPageSetting = ({ data, headerStikyPostion }) => {
  const { t } = useTranslation();
  const {
    status,
    search,
    filter,
    sortBy, 
    order,
    tabs,
    tabSelect,
    sortOptions,
    handleTabChange,
    setSearch,
    setFilter,
    setSortBy,
    setStatus,
    setOrder,
  } = usePageState(data?.sort, data?.defaultSortBy, data?.tabs);

  return (
    <PageModalSetting
      tabSelect={tabSelect}
      tabs={tabs}
      handleTabChange={handleTabChange}
      search={search}
      setSearch={setSearch}
      filter={filter}
      setFilter={setFilter}
      sortOptions={sortOptions}
      sortBy={sortBy}
      setSortBy={setSortBy}
      status={status}
      pageName={t(data?.pageName)}
      addSectionLabel={t(data?.addSectionLabel)}
      addSection={data?.addSection}
      addSectionUrl={data?.addSectionUrl}
      addInstanceModal={data?.openModelCreate}
      filterSection={data?.filterSection}
      searchButton={data?.searchButton}
      filterButton={data?.filterButton}
      headerStikyPostion={headerStikyPostion}
    >
      <DataGridSection
        search={search}
        filter={filter}
        order={order}
        status={status}
        data={data}
      />
   </PageModalSetting>
  );
};

export default FetchPageSetting;
