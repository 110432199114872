import React from "react";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../contexts/ContextProvider";
import ModalDialog from "../ModalDialog";

import CheckBox from "../Inputs/CheckBox";
import DragFiles from "./DragFiles";
import DragToUpload from "./DragToUpload";

const ImportCSVModal = ({
  children,
  open,
  setOpen,
  title,
  description,
  cancelTitle,
  action,
  actionTitle,
  loadingAction,
  overrideExistingFiles,
  setOverrideExistingFiles,
  overrideLable,
  files,
  setFiles,
  deleteFiles,
  setLoadingUpload,
  loadingUpload,
  setUploadMore,
  uploadMore,
  reqErrors,
}) => {
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();

  return (
    <ModalDialog
      open={open}
      setOpen={setOpen}
      title={title}
      cancelTitle={cancelTitle ? cancelTitle : t("Cancel")}
      cancel={() => setOpen(false)}
      actionTitle={actionTitle ? actionTitle : t("Import")}
      action={action}
      loadingAction={loadingAction}
    >
      <div className=" flex flex-col font-Inter gap-4">
        <div>
          <p
            className="text-sm"
            style={{
              color: bodyStyles?.subText,
            }}
          >
            {description}
          </p>
        </div>

        <div className="flex gap-2 justify-start items-center">
          <CheckBox
            checked={overrideExistingFiles ? true : false}
            onClick={() => setOverrideExistingFiles(!overrideExistingFiles)}
            label={t(overrideLable)}
            color={bodyStyles?.subText}
          />
        </div>

        <div>
          <div className="flex gap-1 flex-wrap">
            {(files.length > 0 || loadingUpload) && (
              <DragFiles
                images={files.map((item, index) => ({
                  id: `${index}`,
                  imageUrl: item,
                }))}
                setImages={setFiles}
                handleDeleteImage={deleteFiles}
                loadingUpload={loadingUpload}
                single
              />
            )}

            {((!loadingUpload && files?.length === 0) || uploadMore) && (
              <DragToUpload
                setImages={setFiles}
                images={files}
                setLoadingUpload={setLoadingUpload}
                loadingUpload={loadingUpload}
                setUploadMore={setUploadMore}
                uploadMore={uploadMore}
                single
                text={t("Accepts CSV files")}
                button01={t("Add file")}
                accept={{ "text/csv": [".csv", ".htm"] }}
                error={reqErrors?.files}
              />
            )}
          </div>

          <span className="text-[#d32f2f] text-sm px-1">
            {reqErrors?.files}
          </span>
        </div>

        <div className="flex items-center justify-between py-3">
          <h4
            className="font-semibold border-b-[2px] text-sm pb-0.5 cursor-pointer"
            style={{
              color: bodyStyles.subText,
              borderColor: bodyStyles.subText,
            }}
          >
            {t("Need help?")}
          </h4>
        </div>
      </div>
    </ModalDialog>
  );
};

export default ImportCSVModal;
