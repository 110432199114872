import { useStateContext } from "../../../../contexts/ContextProvider";
import { Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import RatioField from "./RatioField";

const CustomerContactMethod = ({ fields, setFields }) => {
  const { t } = useTranslation();
  const { bodyStyles } = useStateContext();

  return (
    <div className="w-full flex flex-col items-start justify-start ">
      <h1
        style={{
          color: bodyStyles?.title || "#808080",
        }}
        className="text-[#808080] text-[19px] font-[600] uppercase"
      >
        {t("Customer contact method")}
      </h1>

      <div className="flex flex-col items-center justify-start w-full py-6 gap-y-4">
        <h1
          style={{
            color: bodyStyles?.subTitle || "#888888",
          }}
          className="w-full text-[#888888] text-[18px] font-[400]"
        >
          {t("Select what contact method customers use to check out.")}
        </h1>
        <RatioField
          checked={fields.phoneNumberOrEmail}
          onChange={() => {
            setFields((prevState) => ({
              ...prevState,
              phoneNumberOrEmail: true,
              EmailUique: false,
            }));
          }}
          value={fields.phoneNumberOrEmail}
          name="radio-buttons-1"
          title={t("Phone number or email")}
          desc={t("Customers will only be able to check out as guests.")}
        />
        <RatioField
          checked={fields.EmailUique}
          onChange={() => {
            setFields((prevState) => ({
              ...prevState,
              phoneNumberOrEmail: false,
              EmailUique: true,
            }));
          }}
          value={fields.EmailUique}
          name="radio-buttons-2"
          title={t("Email")}
        />
      </div>
      <div className="flex flex-col items-center justify-start w-full py-6 gap-y-4">
        <h1
          style={{
            color: bodyStyles?.subTitle || "#888888",
          }}
          className="w-full text-[#888888] text-[18px] font-[400]"
        >
          {t("Select how customers can choose to get shipping updates.")}
        </h1>
        <div className="flex flex-rox w-full items-center justify-center">
          <Checkbox
            checked={fields.showLinkToDownloadShopApp}
            onChange={() => {
              setFields((prevState) => ({
                ...prevState,
                showLinkToDownloadShopApp: !fields.showLinkToDownloadShopApp,
              }));
            }}
            value={fields.showLinkToDownloadShopApp}
            sx={{
              "&, &.Mui-checked": {
                color: bodyStyles?.primary,
              },
            }}
          />

          <div className="flex flex-col items-start justify-start w-full">
            <span
              style={{
                color: bodyStyles?.text || "#000",
              }}
              className="text-base text-black "
            >
              {t("Show a link to download the Shop App")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerContactMethod;
