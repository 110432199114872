import React, { memo, useEffect, useState } from 'react';
import BasicSelecAutoComplete from '../../../../Inputs/BasicSelecAutoComplete';
import LoadingSpinner from '../../../../LoadingSpinner';
import CheckBox from '../../../../Inputs/CheckBox';
import { regroupWithGroupName } from './helper';
import { GET_ALL_ROLES } from '../../../../../graphql/query/roles.query';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../../../contexts/ContextProvider';


const AssignRoles = memo(({ instanceCreated, formikRoles, setRoles, roles }) => {
  const { bodyStyles } = useStateContext();

  const { t } = useTranslation();

  const permissionState = useSelector((state) => state.permission);

  const [searchRole, setSearchRole] = useState('');

  const [rolesDesc, setRolesDesc] = useState([]);

  const {
    loading: loadingRoles,
    error: errorRoles,
    data: rolesData,
  } = useQuery(GET_ALL_ROLES, {
    variables: {
      offset: 0,
      limit: 10,
      search: searchRole,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (permissionState?.data) {
      setRolesDesc(regroupWithGroupName(permissionState?.data, roles));
    }
  }, [permissionState?.data, roles]);

  return (
    <>
      {' '}
      <div
        className='w-full pt-2 pb-2 z-[10] sticky -top-5'
        style={{
          backgroundColor: bodyStyles.background,
        }}
      >
        <BasicSelecAutoComplete
          disabled={!instanceCreated ? true : false}
          multiple
          fullWidth
          label={t('Roles')}
          placeholder='Select Roles'
          options={rolesData?.roles?.nodes ? rolesData?.roles?.nodes : []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option?.id === (value?.id || null)
          }
          value={roles}
          freeSolo
          onChange={(event, newValue) => {
            setRoles(newValue);
          }}
          inputValue={searchRole}
          onInputChange={(event, newInputValue) => {
            setSearchRole(newInputValue);
          }}
          error={formikRoles.touched.roles && Boolean(formikRoles.errors.roles)}
          helperText={formikRoles.touched.roles && formikRoles.errors.roles}
        />
      </div>
      <div className='w-full'>
        {0 ? (
          <div className=' flex h-[120px] w-full items-center justify-center'>
            {' '}
            <LoadingSpinner size='34px' />{' '}
          </div>
        ) : (
          <div>
            {rolesDesc?.map((role) => (
              <div className='flex rounded-md  pt-2 pb-0' key={role.id}>
                <div className='flex w-[33%] flex-col gap-1 border bg-[#F2F3F5] p-1 justify-center items-center'>
                  <h4 className='font-Inter text-sm font-[700] '>
                    {role.name}
                  </h4>
                </div>
                <div className='flex w-[67%] flex-wrap gap-2 border p-2'>
                  {role.permissions.map((permission, permissionIndex) => (
                    <div
                      className='flex h-min min-w-[160px] 
                          flex-wrap justify-start break-all bg-[#E8E8E8] px-2 py-1'
                      key={permission.id}
                    >
                      <CheckBox
                        checked={permission.status}
                        label={permission.name}
                        size={20}
                        disabled={true}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
});

export default AssignRoles;
