import { constants } from '../constants';
import { client } from '../global/apolloClient';
import {
  CREATE_MENU,
  DELETE_MENU,
  EDIT_MENU,
} from '../graphql/mutation/menu.mutation';
import { GET_ALL_MENUS, GET_MENU } from '../graphql/query/menus.query';
import {
  createMenuFail,
  createMenuStart,
  createMenuSuccess,
  deleteMenuFail,
  deleteMenuStart,
  deleteMenuSuccess,
  deleteMenusFail,
  deleteMenusStart,
  deleteMenusSuccess,
  editMenuFail,
  editMenuStart,
  editMenuSuccess,
  fetchMenusFail,
  fetchMenusStart,
  fetchMenusSuccess,
  importFail,
  importStart,
  importSuccess,
  removeAll,
} from '../store/features/menu/menuSlicer';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation } from './executeMutation';
import { uploadImage } from './upload';

const token = getTheTokenFromStorage();

export const createMenu = async (values, accessToken) => {
  return await executeMutation(
    CREATE_MENU,
    [GET_ALL_MENUS, GET_MENU],
    values,
    accessToken
  );
};

export const editMenu = async (values, accessToken) => {
  return await executeMutation(
    EDIT_MENU,
    [GET_ALL_MENUS, GET_MENU],
    values,
    accessToken
  );
};

export const deleteMenu = async (values, accessToken) => {
  return await executeMutation(
    DELETE_MENU,
    [GET_ALL_MENUS, GET_MENU],
    values,
    accessToken
  );
};

export const deleteManyMenu = async (values, accessToken) => {
  return await executeMutation(
    DELETE_MENU,
    [GET_ALL_MENUS, GET_MENU],
    values,
    accessToken
  );
};

export const fetchMenus = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchMenusStart());
      const { data } = await client.query({
        query: GET_ALL_MENUS,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchMenusSuccess(data));
    } catch (error) {
      dispatch(fetchMenusFail(error));
    }
  };
};

export const handleDeleteMenu = (id) => {
  return async (dispatch) => {
    dispatch(deleteMenuStart());
    const dataSent = {
      removeMenuId: parseInt(id),
    };
    try {
      let res = await deleteMenu(dataSent, token);
      if (res?.data?.removeMenu) {
        dispatch(deleteMenuSuccess(id));
        dispatch(showSuccessMessage('Menu deleted successfully'));
      } else {
        dispatch(deleteMenuFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteMenuFail(error));  
    }
  };
};

export const handleDeleteManyMenu = (ids) => {
  return async (dispatch) => {
    dispatch(deleteMenusStart());
    const dataSent = {
      removeMenuId: ids.map((id) => parseInt(id)),
    };
    try {
      let res = await deleteManyMenu(dataSent, token);
      if (res?.data?.removeMenus) {
        dispatch(deleteMenusSuccess(ids));
        dispatch(showSuccessMessage('Menus deleted successfully'));
      } else {
        dispatch(deleteMenusFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteMenusFail(error));
    }
  };
};

export const handleCreateMenu = (values, callback) => {
  return async (dispatch) => {
    dispatch(createMenuStart());

    let imageSent = values.featuredAsset[0]
      ? await uploadImage(values.featuredAsset[0])
      : null;

    let translationDataSent = values.languages
      ? Object.keys(values.languages).map((key) => {
          return {
            name: values.languages[key].name,
            description: values.languages[key].description,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      input: {
        isRoot: values.isRoot,
        parent: values.parent?.id ? +values.parent.id : null,
        translationData: translationDataSent,
        collections: values.collections.map(function (obj) {
          return +obj.id;
        }),
        media: imageSent?.data?.id ? +imageSent?.data?.id : null,
      },
    };

    try {
      const res = await createMenu(dataSent, token);
      if (res?.data?.createMenu) {
        dispatch(createMenuSuccess(res?.data?.createMenu));
        dispatch(showSuccessMessage('this Menu has been successfully created'));

        if (callback) {
          callback(res?.data?.createMenu.id);
        }
      } else {
        dispatch(createMenuFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(createMenuFail(error));
      dispatch(showErrorMessage(error));
    }
  };
};

export const handleEditMenu = (values, callback) => {
  return async (dispatch) => {
    dispatch(editMenuStart());

    let imageSent = values.featuredAsset[0]
      ? await uploadImage(values.featuredAsset[0])
      : null;

    let translationDataSent = values.translationData
      ? Object.keys(values.translationData).map((key) => {
          return {
            name: values.translationData[key].name,
            description: values.translationData[key].description,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      input: {
        id: values.id,
        isRoot: values.isRoot,
        parent: values.parent?.id ? +values.parent.id : null,
        translationData: translationDataSent,
        collections: values.collections.map(function (obj) {
          return +obj.id;
        }),
        media: imageSent?.data?.id ? +imageSent?.data?.id : null,
      },
    };

    try {
      let res = await editMenu(dataSent, token);
      if (res?.data?.updateMenu) {
        dispatch(editMenuSuccess(res?.data?.updateMenu));
        dispatch(showSuccessMessage('this Menu has been successfully updated'));

        if (callback) {
          callback(res?.data?.updateMenu.id);
        }
      } else {
        dispatch(editMenuFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(editMenuFail(error));
      dispatch(showErrorMessage(error));
    }
  };
};

export const handleImportFiles = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyMenu(dataSent, token);
      if (res?.data?.removeMenus) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Menus Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteMenusFail(error));
      });
    }
  };
};

export const handleExportFiles = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyMenu(dataSent, token);
      // dispatch(removeAll())
    
    } catch (error) {
      // dispatch(removeAll())

     
    }
  };
};

export const handleExportAllFiles = () => {
  console.log('handleExportAllFiles =>' );
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyMenu(dataSent, token);
    } catch (error) {}
  };
};
