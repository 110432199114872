import { useState } from 'react';

import Moment from 'react-moment';

import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import ShowPoints from './ShowPoints';
import { useEffect } from 'react';

export const MapLeaflet = ({ center, eventData, sessionData, sessions }) => {
  const [analyticsSelect, setAnalyticsSelect] = useState(3);

  useEffect(() => {}, [analyticsSelect, sessions]);

  return (
    <div
      className='relative h-full w-full overflow-hidden'
      // onClick={() => setAnalyticsSelect(3)}
    >
      <MapContainer center={center} zoom={2.5} minZoom={2.5}>
        <TileLayer
          noWrap={true}
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />

        {/* {analyticsSelect === 1 ? (
          <ShowPoints data={eventData} pointColor="#291287" type="order" />
        ) : analyticsSelect === 2?(
          <ShowPoints data={sessionData} pointColor="#004cff" type="session" />
        ) : (
          <>
          <ShowPoints data={eventData} pointColor="#291287" type="order" />
          <ShowPoints data={sessionData} pointColor="#004cff" type="session" />
          <ShowPoints data={sessions} pointColor="#004cff" type="session Real Time" />

          </>
        )} */}

        <ShowPoints data={sessions} pointColor='#004cff' type='session' />
      </MapContainer>
      <InfoBox
        setAnalyticsSelect={setAnalyticsSelect}
        analyticsSelect={analyticsSelect}
      />
    </div>
  );
};

MapLeaflet.defaultProps = {
  center: {
    lat: 29.305561,
    lng: -3.981108,
  },
};

const InfoBox = ({ setAnalyticsSelect, analyticsSelect }) => {
  return (
    <div
      className='bg absolute top-8 left-16 z-[1000] flex
    flex-col gap-2 rounded-md bg-[rgba(0,0,0,.3)] p-4 text-lg text-[#FFFFFF]'
    >
      <div>
        <Moment format='MMMM Do YYYY, h:mm'>{Date.now()}</Moment>
      </div>

      <div className='flex gap-5'>
        <div
          className='flex cursor-pointer items-center gap-2'
          onClick={() => setAnalyticsSelect(1)}
        >
          <div
            className={`h-[10px] w-[10px] rounded-full bg-[#291287] duration-100
          ${analyticsSelect === 1 ? 'scale-105 border shadow-xl ' : ''}`}
          ></div>
          <span>Session</span>
        </div>

        {/* <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => setAnalyticsSelect(2)}
        >
          <div className={`w-[10px] h-[10px] bg-[#004cff] rounded-full duration-100
          ${analyticsSelect ===2? "border scale-105 shadow-xl":"" }
          `}></div>
          <span>Order</span>
        </div> */}
      </div>
    </div>
  );
};
export default MapLeaflet;
