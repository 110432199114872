import { client } from "../global/apolloClient";
import { EDIT_SHIPPING_LINE } from "../graphql/mutation/shippingMethod.mutation";
import { GET_ORDER } from "../graphql/query/orders.query";

export const editShippingLine = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: EDIT_SHIPPING_LINE,
      variables: {
        ...values,
      },
      context: { accessToken },
      refetchQueries: [GET_ORDER],
    });

    return result;
  } catch (error) {
    return error.message;
  }
};
 