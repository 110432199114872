export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo (the Democratic Republic of the)",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands [Malvinas]",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom of Great Britain and Northern Ireland",
  "United States Minor Outlying Islands",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

export const goldCountries = [
  "Bahrain",
  "Iraq",
  "Kuwait",
  "Oman",
  "Qatar",
  "Saudi Arabia",
  "United Arab Emirates",
];

export const countriesWithCode = [
  {
    name: "Afghanistan",
    name_fr: "Afghanistan",
    name_ar: "أفغانستان",
    code: "AF",
  },
  { name: "land Islands", name_fr: "", name_ar: "", code: "AX" },
  {
    name: "Albania",
    name_fr: "Albanie",
    name_ar: "ألبانيا",
    code: "AL",
  },
  {
    name: "Algeria",
    name_fr: "Algérie",
    name_ar: "الجزائر",
    code: "DZ",
  },
  { name: "American Samoa", name_fr: "", name_ar: "", code: "AS" },
  {
    name: "AndorrA",
    name_fr: "Andorre",
    name_ar: "أندورا",
    code: "AD",
  },
  {
    name: "Angola",
    name_fr: "Angola",
    name_ar: "أنغولا",
    code: "AO",
  },
  { name: "Anguilla", name_fr: "", name_ar: "", code: "AI" },
  { name: "Antarctica", name_fr: "", name_ar: "", code: "AQ" },
  {
    name: "Antigua and Barbuda",
    name_fr: "Antigua-et-Barbuda",
    name_ar: "أنتيغوا وباربودا",
    code: "AG",
  },
  {
    name: "Argentina",
    name_fr: "Argentine",
    name_ar: "الأرجنتين",
    code: "AR",
  },
  {
    name: "Armenia",
    name_fr: "Arménie",
    name_ar: "أرمينيا",
    code: "AM",
  },
  { name: "Aruba", name_fr: "", name_ar: "", code: "AW" },
  {
    name: "Australia",
    name_fr: "Australie",
    name_ar: "أستراليا",
    code: "AU",
  },
  {
    name: "Austria",
    name_fr: "Autriche",
    name_ar: "النمسا",

    name_it: "Austria",
    name_de: "Österreich",

    code: "AT",
  },
  {
    name: "Azerbaijan",
    name_fr: "Azerbaïdjan",
    name_ar: "أذربيجان",
    code: "AZ",
  },
  {
    name: "Bahamas",
    name_fr: "Bahamas",
    name_ar: "باهاماس",
    code: "BS",
  },
  {
    name: "Bahrain",
    name_fr: "Bahreïn",
    name_ar: "البحرين",
    code: "BH",
  },
  {
    name: "Bangladesh",
    name_fr: "Bangladesh",
    name_ar: "بنغلاديش",
    code: "BD",
  },
  {
    name: "Barbados",
    name_fr: "Barbade",
    name_ar: "باربادوس",
    code: "BB",
  },
  {
    name: "Belarus",
    name_fr: "Biélorussie",
    name_ar: "بيلاروس",
    code: "BY",
  },
  {
    name: "Belgium",
    name_fr: "Belgique",
    name_ar: "بلجيكا",

    name_it: "Belgio",
    name_de: "Belgien",
    code: "BE",
  },
  { name: "Belize", name_fr: "Belize", name_ar: "بليز", code: "BZ" },
  { name: "Benin", name_fr: "Bénin", name_ar: "بنين", code: "BJ" },
  { name: "Bermuda", name_fr: "", name_ar: "", code: "BM" },
  {
    name: "Bhutan",
    name_fr: "Bhoutan",
    name_ar: "بوتان",
    code: "BT",
  },
  {
    name: "Bolivia",
    name_fr: "Bolivie",
    name_ar: "بوليفيا",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    name_fr: "Bosnie-Herzégovine",
    name_ar: "البوسنة والهرسك",
    code: "BA",
  },
  {
    name: "Botswana",
    name_fr: "Botswana",
    name_ar: "بوتسوانا",
    code: "BW",
  },
  { name: "Bouvet Island", name_fr: "", name_ar: "", code: "BV" },
  {
    name: "Brazil",
    name_fr: "Brésil",
    name_ar: "البرازيل",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    name_fr: "",
    name_ar: "",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    name_fr: "Brunei",
    name_ar: "بروناي",
    code: "BN",
  },
  {
    name: "Bulgaria",
    name_fr: "Bulgarie",
    name_ar: "بلغاريا",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    name_fr: "Burkina Faso",
    name_ar: "بوركينا فاسو",
    code: "BF",
  },
  {
    name: "Burundi",
    name_fr: "Burundi",
    name_ar: "بوروندي",
    code: "BI",
  },
  {
    name: "Cambodia",
    name_fr: "Cambodge",
    name_ar: "كمبوديا",
    code: "KH",
  },
  {
    name: "Cameroon",
    name_fr: "Cameroun",
    name_ar: "الكاميرون",
    code: "CM",
  },
  { name: "Canada", name_fr: "Canada", name_ar: "كندا", code: "CA" },
  {
    name: "Cape Verde",
    name_fr: "Cap-Vert",
    name_ar: "الرأس الأخضر",
    code: "CV",
  },
  { name: "Cayman Islands", name_fr: "", name_ar: "", code: "KY" },
  {
    name: "Central African Republic",
    name_fr: "République centrafricaine",
    name_ar: "جمهورية أفريقيا الوسطى",
    code: "CF",
  },
  { name: "Chad", name_fr: "Tchad", name_ar: "تشاد", code: "TD" },
  { name: "Chile", name_fr: "Chili", name_ar: "تشيلي", code: "CL" },
  { name: "China", name_fr: "Chine", name_ar: "الصين", code: "CN" },
  { name: "Christmas Island", name_fr: "", name_ar: "", code: "CX" },
  {
    name: "Cocos (Keeling) Islands",
    name_fr: "",
    name_ar: "",
    code: "CC",
  },
  {
    name: "Colombia",
    name_fr: "Colombie",
    name_ar: "كولومبيا",
    code: "CO",
  },
  {
    name: "Comoros",
    name_fr: "Comores (pays)",
    name_ar: "جزر القمر",
    code: "KM",
  },
  {
    name: "Congo",
    name_fr: "République du Congo",
    name_ar: "جمهورية الكونغو",
    code: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the",
    name_fr: "République démocratique du Congo",
    name_ar: "جمهورية الكونغو الديمقراطية",
    code: "CD",
  },
  { name: "Cook Islands", name_fr: "", name_ar: "", code: "CK" },
  {
    name: "Costa Rica",
    name_fr: "Costa Rica",
    name_ar: "كوستاريكا",
    code: "CR",
  },
  {
    name: "Cote D'Ivoire",
    name_fr: "Côte d'Ivoire",
    name_ar: "ساحل العاج",
    code: "CI",
  },
  {
    name: "Croatia",
    name_fr: "Croatie",
    name_ar: "كرواتيا",
    code: "HR",
  },
  { name: "Cuba", name_fr: "Cuba", name_ar: "كوبا", code: "CU" },
  {
    name: "Cyprus",
    name_fr: "Chypre (pays)",
    name_ar: "قبرص",
    code: "CY",
  },
  {
    name: "Czech Republic",
    name_fr: "Tchéquie",
    name_ar: "جمهورية التشيك",
    code: "CZ",
  },
  {
    name: "Denmark",
    name_fr: "Danemark",
    name_ar: "الدنمارك",
    code: "DK",
  },
  {
    name: "Djibouti",
    name_fr: "Djibouti",
    name_ar: "جيبوتي",
    code: "DJ",
  },
  {
    name: "Dominica",
    name_fr: "Dominique",
    name_ar: "دومينيكا",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    name_fr: "République dominicaine",
    name_ar: "جمهورية الدومينيكان",
    code: "DO",
  },
  {
    name: "Ecuador",
    name_fr: "Équateur (pays)",
    name_ar: "الإكوادور",
    code: "EC",
  },
  { name: "Egypt", name_fr: "Égypte", name_ar: "مصر", code: "EG" },
  {
    name: "El Salvador",
    name_fr: "Salvador",
    name_ar: "السلفادور",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    name_fr: "Guinée équatoriale",
    name_ar: "غينيا الاستوائية",
    code: "GQ",
  },
  {
    name: "Eritrea",
    name_fr: "Érythrée",
    name_ar: "إريتريا",
    code: "ER",
  },
  {
    name: "Estonia",
    name_fr: "Estonie",
    name_ar: "إستونيا",
    code: "EE",
  },
  {
    name: "Ethiopia",
    name_fr: "Éthiopie",
    name_ar: "إثيوبيا",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    name_fr: "",
    name_ar: "",
    code: "FK",
  },
  { name: "Faroe Islands", name_fr: "", name_ar: "", code: "FO" },
  { name: "Fiji", name_fr: "Fidji", name_ar: "فيجي", code: "FJ" },
  {
    name: "Finland",
    name_fr: "Finlande",
    name_ar: "فنلندا",
    code: "FI",
  },
  {
    name: "France",
    name_fr: "France",
    name_ar: "فرنسا",

    name_it: "Francia",
    name_de: "Frankreich",
    code: "FR",
  },
  { name: "French Guiana", name_fr: "", name_ar: "", code: "GF" },
  { name: "French Polynesia", name_fr: "", name_ar: "", code: "PF" },
  {
    name: "French Southern Territories",
    name_fr: "",
    name_ar: "",
    code: "TF",
  },
  {
    name: "Gabon",
    name_fr: "Gabon",
    name_ar: "الغابون",
    code: "GA",
  },
  {
    name: "Gambia",
    name_fr: "Gambie",
    name_ar: "غامبيا",
    code: "GM",
  },
  {
    name: "Georgia",
    name_fr: "Géorgie (pays)",
    name_ar: "جورجيا",
    code: "GE",
  },
  {
    name: "Germany",
    name_fr: "Allemagne",
    name_ar: "ألمانيا",

    name_it: "Germania",
    name_de: "Deutschland",
    code: "DE",
  },
  { name: "Ghana", name_fr: "Ghana", name_ar: "غانا", code: "GH" },
  { name: "Gibraltar", name_fr: "", name_ar: "", code: "GI" },
  {
    name: "Greece",
    name_fr: "Grèce",
    name_ar: "اليونان",
    code: "GR",
  },
  { name: "Greenland", name_fr: "", name_ar: "", code: "GL" },
  {
    name: "Grenada",
    name_fr: "Grenade (pays)",
    name_ar: "غرينادا",
    code: "GD",
  },
  { name: "Guadeloupe", name_fr: "", name_ar: "", code: "GP" },
  { name: "Guam", name_fr: "", name_ar: "", code: "GU" },
  {
    name: "Guatemala",
    name_fr: "Guatemala",
    name_ar: "غواتيمالا",
    code: "GT",
  },
  { name: "Guernsey", name_fr: "", name_ar: "", code: "GG" },
  {
    name: "Guinea",
    name_fr: "Guinée",
    name_ar: "غينيا",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    name_fr: "Guinée-Bissau",
    name_ar: "غينيا بيساو",
    code: "GW",
  },
  {
    name: "Guyana",
    name_fr: "Guyana",
    name_ar: "غيانا",
    code: "GY",
  },
  { name: "Haiti", name_fr: "Haïti", name_ar: "هايتي", code: "HT" },
  {
    name: "Heard Island and Mcdonald Islands",
    name_fr: "",
    name_ar: "",
    code: "HM",
  },
  {
    name: "Holy See (Vatican City State)",
    name_fr: "",
    name_ar: "",
    code: "VA",
  },
  {
    name: "Honduras",
    name_fr: "Honduras",
    name_ar: "هندوراس",
    code: "HN",
  },
  { name: "Hong Kong", name_fr: "", name_ar: "", code: "HK" },
  {
    name: "Hungary",
    name_fr: "Hongrie",
    name_ar: "المجر",
    code: "HU",
  },
  {
    name: "Iceland",
    name_fr: "Islande",
    name_ar: "آيسلندا",
    code: "IS",
  },
  { name: "India", name_fr: "Inde", name_ar: "الهند", code: "IN" },
  {
    name: "Indonesia",
    name_fr: "Indonésie",
    name_ar: "إندونيسيا",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic Of",
    name_fr: "Iran",
    name_ar: "إيران",
    code: "IR",
  },
  { name: "Iraq", name_fr: "Irak", name_ar: "العراق", code: "IQ" },
  {
    name: "Ireland",
    name_fr: "Irlande (pays)",
    name_ar: "أيرلندا",
    code: "IE",
  },
  { name: "Isle of Man", name_fr: "", name_ar: "", code: "IM" },
  
  {
    name: "Italy",
    name_fr: "Italie",
    name_ar: "إيطاليا",
    code: "IT",
  },
  {
    name: "Jamaica",
    name_fr: "Jamaïque",
    name_ar: "جامايكا",
    code: "JM",
  },
  {
    name: "Japan",
    name_fr: "Japon",
    name_ar: "اليابان",
    code: "JP",
  },
  { name: "Jersey", name_fr: "", name_ar: "", code: "JE" },
  {
    name: "Jordan",
    name_fr: "Jordanie",
    name_ar: "الأردن",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    name_fr: "Kazakhstan",
    name_ar: "كازاخستان",
    code: "KZ",
  },
  { name: "Kenya", name_fr: "Kenya", name_ar: "كينيا", code: "KE" },
  {
    name: "Kiribati",
    name_fr: "Kiribati",
    name_ar: "كيريباتي",
    code: "KI",
  },
  {
    name: "Korea, Democratic People'S Republic of",
    name_fr: "Corée du Nord",
    name_ar: "كوريا الشمالية",
    code: "KP",
  },
  {
    name: "Korea, Republic of",
    name_fr: "Corée du Sud",
    name_ar: "كوريا الجنوبية",
    code: "KR",
  },
  {
    name: "Kuwait",
    name_fr: "Koweït",
    name_ar: "الكويت",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    name_fr: "Kirghizistan",
    name_ar: "قيرغيزستان",
    code: "KG",
  },
  {
    name: "Lao People'S Democratic Republic",
    name_fr: "Laos",
    name_ar: "لاوس",
    code: "LA",
  },
  {
    name: "Latvia",
    name_fr: "Lettonie",
    name_ar: "لاتفيا",
    code: "LV",
  },
  {
    name: "Lebanon",
    name_fr: "Liban",
    name_ar: "لبنان",
    code: "LB",
  },
  {
    name: "Lesotho",
    name_fr: "Lesotho",
    name_ar: "ليسوتو",
    code: "LS",
  },
  {
    name: "Liberia",
    name_fr: "Liberia",
    name_ar: "ليبيريا",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    name_fr: "Libye",
    name_ar: "ليبيا",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    name_fr: "Liechtenstein",
    name_ar: "ليختنشتاين",
    code: "LI",
  },
  {
    name: "Lithuania",
    name_fr: "Lituanie",
    name_ar: "ليتوانيا",
    code: "LT",
  },
  {
    name: "Luxembourg",
    name_fr: "Luxembourg (pays)",
    name_ar: "لوكسمبورغ",

    name_it: "Lussemburgo",
    name_de: "Luxemburg",

    code: "LU",
  },
  { name: "Macao", name_fr: "", name_ar: "", code: "MO" },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    name_fr: "Macédoine du Nord",
    name_ar: "مقدونيا",
    code: "MK",
  },
  {
    name: "Madagascar",
    name_fr: "Madagascar",
    name_ar: "مدغشقر",
    code: "MG",
  },
  {
    name: "Malawi",
    name_fr: "Malawi",
    name_ar: "مالاوي",
    code: "MW",
  },
  {
    name: "Malaysia",
    name_fr: "Malaisie",
    name_ar: "ماليزيا",
    code: "MY",
  },
  {
    name: "Maldives",
    name_fr: "Maldives",
    name_ar: "جزر المالديف",
    code: "MV",
  },
  { name: "Mali", name_fr: "Mali", name_ar: "مالي", code: "ML" },
  { name: "Malta", name_fr: "Malte", name_ar: "مالطا", code: "MT" },
  {
    name: "Marshall Islands",
    name_fr: "Îles Marshall (pays)",
    name_ar: "جزر مارشال",
    code: "MH",
  },
  { name: "Martinique", name_fr: "", name_ar: "", code: "MQ" },
  {
    name: "Mauritania",
    name_fr: "Mauritanie",
    name_ar: "موريتانيا",
    code: "MR",
  },
  {
    name: "Mauritius",
    name_fr: "Maurice (pays)",
    name_ar: "موريشيوس",
    code: "MU",
  },
  { name: "Mayotte", name_fr: "", name_ar: "", code: "YT" },
  {
    name: "Mexico",
    name_fr: "Mexique",
    name_ar: "المكسيك",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of",
    name_fr: "États fédérés de Micronésie (pays)",
    name_ar: "ولايات ميكرونيسيا المتحدة",
    code: "FM",
  },
  {
    name: "Moldova, Republic of",
    name_fr: "Moldavie",
    name_ar: "مولدوفا",
    code: "MD",
  },
  {
    name: "Monaco",
    name_fr: "Monaco",
    name_ar: "موناكو",
    code: "MC",
  },
  {
    name: "Mongolia",
    name_fr: "Mongolie",
    name_ar: "منغوليا",
    code: "MN",
  },
  {
    name: "Montenegro",
    name_fr: "Monténégro",
    name_ar: "الجبل الأسود",
    code: "ME",
  },
  { name: "Montserrat", name_fr: "", name_ar: "", code: "MS" },
  {
    name: "Morocco",
    name_fr: "Maroc",
    name_ar: "المغرب",
    code: "MA",
  },
  {
    name: "Mozambique",
    name_fr: "Mozambique",
    name_ar: "موزمبيق",
    code: "MZ",
  },
  {
    name: "Myanmar",
    name_fr: "Birmanie",
    name_ar: "ميانمار",
    code: "MM",
  },
  {
    name: "Namibia",
    name_fr: "Namibie",
    name_ar: "ناميبيا",
    code: "NA",
  },
  { name: "Nauru", name_fr: "Nauru", name_ar: "ناورو", code: "NR" },
  { name: "Nepal", name_fr: "Népal", name_ar: "نيبال", code: "NP" },
  {
    name: "Netherlands",
    name_fr: "Pays-Bas",
    name_ar: "هولندا",
    code: "NL",
  },
  {
    name: "Netherlands Antilles",
    name_fr: "",
    name_ar: "",
    code: "AN",
  },
  { name: "New Caledonia", name_fr: "", name_ar: "", code: "NC" },
  {
    name: "New Zealand",
    name_fr: "Nouvelle-Zélande",
    name_ar: "نيوزيلندا",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    name_fr: "Nicaragua",
    name_ar: "نيكاراغوا",
    code: "NI",
  },
  { name: "Niger", name_fr: "Niger", name_ar: "النيجر", code: "NE" },
  {
    name: "Nigeria",
    name_fr: "Nigeria",
    name_ar: "نيجيريا",
    code: "NG",
  },
  { name: "Niue", name_fr: "", name_ar: "", code: "NU" },
  { name: "Norfolk Island", name_fr: "", name_ar: "", code: "NF" },
  {
    name: "Northern Mariana Islands",
    name_fr: "",
    name_ar: "",
    code: "MP",
  },
  {
    name: "Norway",
    name_fr: "Norvège",
    name_ar: "النرويج",
    code: "NO",
  },
  { name: "Oman", name_fr: "Oman", name_ar: "عمان", code: "OM" },
  {
    name: "Pakistan",
    name_fr: "Pakistan",
    name_ar: "باكستان",
    code: "PK",
  },
  { name: "Palau", name_fr: "Palaos", name_ar: "بالاو", code: "PW" },
  {
    name: "Palestinian Territory, Occupied",
    name_fr: "",
    name_ar: "",
    code: "PS",
  },
  { name: "Panama", name_fr: "Panama", name_ar: "بنما", code: "PA" },
  {
    name: "Papua New Guinea",
    name_fr: "Papouasie-Nouvelle-Guinée",
    name_ar: "بابوا غينيا الجديدة",
    code: "PG",
  },
  {
    name: "Paraguay",
    name_fr: "Paraguay",
    name_ar: "باراغواي",
    code: "PY",
  },
  { name: "Peru", name_fr: "Pérou", name_ar: "بيرو", code: "PE" },
  {
    name: "Philippines",
    name_fr: "Philippines",
    name_ar: "الفلبين",
    code: "PH",
  },
  { name: "Pitcairn", name_fr: "", name_ar: "", code: "PN" },
  {
    name: "Poland",
    name_fr: "Pologne",
    name_ar: "بولندا",
    code: "PL",
  },
  {
    name: "Portugal",
    name_fr: "Portugal",
    name_ar: "البرتغال",
    code: "PT",
  },
  { name: "Puerto Rico", name_fr: "", name_ar: "", code: "PR" },
  { name: "Qatar", name_fr: "Qatar", name_ar: "قطر", code: "QA" },
  { name: "Reunion", name_fr: "", name_ar: "", code: "RE" },
  {
    name: "Romania",
    name_fr: "Roumanie",
    name_ar: "رومانيا",
    code: "RO",
  },
  {
    name: "Russian Federation",
    name_fr: "Russie",
    name_ar: "روسيا",
    code: "RU",
  },
  {
    name: "RWANDA",
    name_fr: "Rwanda",
    name_ar: "رواندا",
    code: "RW",
  },
  { name: "Saint Helena", name_fr: "", name_ar: "", code: "SH" },
  {
    name: "Saint Kitts and Nevis",
    name_fr: "Saint-Christophe-et-Niévès",
    name_ar: "سانت كيتس ونيفيس",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    name_fr: "Sainte-Lucie",
    name_ar: "سانت لوسيا",
    code: "LC",
  },
  {
    name: "Saint Pierre and Miquelon",
    name_fr: "",
    name_ar: "",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    name_fr: "Saint-Vincent-et-les-Grenadines",
    name_ar: "سانت فينسنت والغرينادين",
    code: "VC",
  },
  { name: "Samoa", name_fr: "Samoa", name_ar: "ساموا", code: "WS" },
  {
    name: "San Marino",
    name_fr: "Saint-Marin",
    name_ar: "سان مارينو",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    name_fr: "Sao Tomé-et-Principe",
    name_ar: "ساو تومي وبرينسيب",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    name_fr: "Arabie saoudite",
    name_ar: "السعودية",
    code: "SA",
  },
  {
    name: "Senegal",
    name_fr: "Sénégal",
    name_ar: "السنغال",
    code: "SN",
  },
  {
    name: "Serbia",
    name_fr: "Serbie",
    name_ar: "صربيا",
    code: "RS",
  },
  {
    name: "Seychelles",
    name_fr: "Seychelles",
    name_ar: "سيشل",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    name_fr: "Sierra Leone",
    name_ar: "سيراليون",
    code: "SL",
  },
  {
    name: "Singapore",
    name_fr: "Singapour",
    name_ar: "سنغافورة",
    code: "SG",
  },
  {
    name: "Slovakia",
    name_fr: "Slovaquie",
    name_ar: "سلوفاكيا",
    code: "SK",
  },
  {
    name: "Slovenia",
    name_fr: "Slovénie",
    name_ar: "سلوفينيا",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    name_fr: "Îles Salomon",
    name_ar: "جزر سليمان",
    code: "SB",
  },
  {
    name: "Somalia",
    name_fr: "Somalie",
    name_ar: "الصومال",
    code: "SO",
  },
  {
    name: "South Africa",
    name_fr: "Afrique du Sud",
    name_ar: "جنوب أفريقيا",
    code: "ZA",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    name_fr: "",
    name_ar: "",
    code: "GS",
  },
  {
    name: "Spain",
    name_fr: "Espagne",
    name_ar: "إسبانيا",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    name_fr: "Sri Lanka",
    name_ar: "سريلانكا",
    code: "LK",
  },
  {
    name: "Sudan",
    name_fr: "Soudan",
    name_ar: "السودان",
    code: "SD",
  },
  {
    name: "Suriname",
    name_fr: "Suriname",
    name_ar: "سورينام",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    name_fr: "",
    name_ar: "",
    code: "SJ",
  },
  {
    name: "Swaziland",
    name_fr: "Eswatini",
    name_ar: "إسواتيني",
    code: "SZ",
  },
  {
    name: "Sweden",
    name_fr: "Suède",
    name_ar: "السويد",
    code: "SE",
  },
  {
    name: "Switzerland",
    name_fr: "Suisse",
    name_ar: "سويسرا",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    name_fr: "Syrie",
    name_ar: "سوريا",
    code: "SY",
  },
  {
    name: "Taiwan, Province of China",
    name_fr: "",
    name_ar: "",
    code: "TW",
  },
  {
    name: "Tajikistan",
    name_fr: "Tadjikistan",
    name_ar: "طاجيكستان",
    code: "TJ",
  },
  {
    name: "Tanzania, United Republic of",
    name_fr: "Tanzanie",
    name_ar: "تنزانيا",
    code: "TZ",
  },
  {
    name: "Thailand",
    name_fr: "Thaïlande",
    name_ar: "تايلاند",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    name_fr: "Timor oriental",
    name_ar: "تيمور الشرقية",
    code: "TL",
  },
  { name: "Togo", name_fr: "Togo", name_ar: "توغو", code: "TG" },
  { name: "Tokelau", name_fr: "", name_ar: "", code: "TK" },
  { name: "Tonga", name_fr: "Tonga", name_ar: "تونغا", code: "TO" },
  {
    name: "Trinidad and Tobago",
    name_fr: "Trinité-et-Tobago",
    name_ar: "ترينيداد وتوباغو",
    code: "TT",
  },
  {
    name: "Tunisia",
    name_fr: "Tunisie",
    name_ar: "تونس",
    code: "TN",
  },
  {
    name: "Turkey",
    name_fr: "Turquie",
    name_ar: "تركيا",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    name_fr: "Turkménistan",
    name_ar: "تركمانستان",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    name_fr: "",
    name_ar: "",
    code: "TC",
  },
  {
    name: "Tuvalu",
    name_fr: "Tuvalu",
    name_ar: "توفالو",
    code: "TV",
  },
  {
    name: "Uganda",
    name_fr: "Ouganda",
    name_ar: "أوغندا",
    code: "UG",
  },
  {
    name: "Ukraine",
    name_fr: "Ukraine",
    name_ar: "أوكرانيا",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    name_fr: "Émirats arabes unis",
    name_ar: "الإمارات العربية المتحدة",
    code: "AE",
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    name_fr: "Royaume-Uni",
    name_ar: "المملكة المتحدة",
    name_it: "Regno Unito di Gran Bretagna e Irlanda del Nord",
    name_de: "Vereinigtes Königreich Großbritannien und Nordirland",
    code: "GB",
  },
  {
    name: "United States of America",
    name_fr: "États-Unis",
    name_ar: "الولايات المتحدة",
    name_it: "Stati Uniti d'America",
    name_de: "vereinigte Staaten von Amerika",
    code: "US",
  },
  {
    name: "United States Minor Outlying Islands",
    name_fr: "",
    name_ar: "",
    code: "UM",
  },
  {
    name: "Uruguay",
    name_fr: "Uruguay",
    name_ar: "الأوروغواي",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    name_fr: "Ouzbékistan",
    name_ar: "أوزبكستان",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    name_fr: "Vanuatu",
    name_ar: "فانواتو",
    code: "VU",
  },
  {
    name: "Venezuela",
    name_fr: "Venezuela",
    name_ar: "فنزويلا",
    code: "VE",
  },
  {
    name: "Viet Nam",
    name_fr: "Viêt Nam",
    name_ar: "فيتنام",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    name_fr: "",
    name_ar: "",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    name_fr: "",
    name_ar: "",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    name_fr: "",
    name_ar: "",
    code: "WF",
  },
  { name: "Western Sahara", name_fr: "", name_ar: "", code: "EH" },
  { name: "Yemen", name_fr: "Yémen", name_ar: "اليمن", code: "YE" },
  {
    name: "Zambia",
    name_fr: "Zambie",
    name_ar: "زامبيا",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    name_fr: "Zimbabwe",
    name_ar: "زيمبابوي",
    code: "ZW",
  },
];

export const sofortContries = [
  "Germany",
  "Austria",
  "Belgium",
  "Netherlands",
  "Italy",
  "Spain",
  "Slovakia",
  "France",
];

export const klarnaCountries = [
  "Sweden",
  "Norway",
  "Finland",
  "Denmark",
  "Germany",
  "Austria",
  "Netherlands",
  "Belgium",
  "Switzerland",
  "France",
  "Italy",
  "Poland",
  "Spain",
  "Portugal",
  "Great Britain",
  "Hungary",
  "Czech Republic",
  "Slovakia",
  "Australia",
  "United States of America",
];

export const SepaCountries = [
  "Austria",
  "Belgium",
  "Cyprus",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Ireland",
  "Italy",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Monaco",
  "Netherlands",
  "Portugal",
  "San Marino",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Bulgaria",
  "Croatia",
  "Czech Republic",
  "Denmark",
  "Hungary",
  "Iceland",
  "Liechtenstein",
  "Norway",
  "Poland",
  "Romania",
  "Sweden",
  "Switzerland",
  "United Kingdom",
];
