import { gql } from '@apollo/client';

export const GET_ALL_PRODUCTS = gql`
query Products($offset: Int!, $limit: Int!, $where: FilterProductsArgs, $sortBy: ProductSortBy) {
  products(offset: $offset, limit: $limit, where: $where, sortBy: $sortBy) {
    nodes {
      id
      createdAt
      updatedAt
      deletedAt
      name
      body
      media {
        id
        original_name
        full_url
        file_name
        __typename
        alt
        host
        path
      }
      categories {
        id
        name
        type
      }
    }
    hasNextPage
    totalCount
  }
}
`;

export const GET_COUNT_PRODUCTS = gql`
  query Products {
    products {
      id
      createdAt
      updatedAt
      deletedAt
      translationData {
        id
        createdAt
        updatedAt
        name
        description
        languageCode
        shortDescription
      }
      collection {
        id
        createdAt
        updatedAt
        deletedAt
      }
      variants {
        id
        createdAt
        deletedAt
        updatedAt

        sku
        stockOnHand
        stockAllocated
        outOfStockThreshold
        isEnabled
      }
      isEnabled
    }
  }
`;

export const GET_PRODUCT = gql`
query Product($productId: Float!) {
  product(id: $productId) {
    body
    createdAt
    deletedAt
    id
    media {
      id
      original_name
      full_url
      file_name
      __typename
      alt
      host
      path
    }
    updatedAt
    name
    categories {
      id
      name
      type
    }
  }
}
`;
