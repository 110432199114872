import { constants } from '../constants';
import { client } from '../global/apolloClient';
import {
  CREATE_BRAND,
  DELETE_BRAND,
  EDIT_BRAND,
} from '../graphql/mutation/bands.mutation';
import { GET_ALL_BRANDS, GET_BRAND } from '../graphql/query/brands.query';
import {
  createBrandFail,
  createBrandStart,
  createBrandSuccess,
  deleteBrandFail,
  deleteBrandStart,
  deleteBrandSuccess,
  deleteBrandsFail,
  deleteBrandsStart,
  deleteBrandsSuccess,
  editBrandFail,
  editBrandStart,
  editBrandSuccess,
  fetchBrandsFail,
  fetchBrandsStart,
  fetchBrandsSuccess,
  importFail,
  importStart,
  importSuccess,
  removeAll,
} from '../store/features/brand/brandSlicer';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation } from './executeMutation';
import { uploadImage } from './upload';

const token = getTheTokenFromStorage();

export const createBrand = async (values, accessToken) => {
  return await executeMutation(
    CREATE_BRAND,
    [GET_ALL_BRANDS, GET_BRAND],
    values,
    accessToken
  );
};

export const editBrand = async (values, accessToken) => {
  return await executeMutation(
    EDIT_BRAND,
    [GET_ALL_BRANDS, GET_BRAND],
    values,
    accessToken
  );
};

export const deleteBrand = async (values, accessToken) => {
  return await executeMutation(
    DELETE_BRAND,
    [GET_ALL_BRANDS, GET_BRAND],
    values,
    accessToken
  );
};

export const deleteManyBrand = async (values, accessToken) => {
  return await executeMutation(
    DELETE_BRAND,
    [GET_ALL_BRANDS, GET_BRAND],
    values,
    accessToken
  );
};

export const fetchBrands = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchBrandsStart());
      const { data } = await client.query({
        query: GET_ALL_BRANDS,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchBrandsSuccess(data));
    } catch (error) {
      dispatch(fetchBrandsFail(error));
    }
  };
};

export const handleDeleteBrand = (id) => {
  return async (dispatch) => {
    dispatch(deleteBrandStart());
    const dataSent = {
      removeBrandId: parseInt(id),
    };
    try {
      let res = await deleteBrand(dataSent, token);
      if (res?.data?.removeBrand) {
        dispatch(deleteBrandSuccess(id));
        dispatch(showSuccessMessage('Brand deleted successfully'));
      } else {
        dispatch(deleteBrandFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteBrandFail(error));
    }
  };
};

export const handleDeleteManyBrand = (ids) => {
  return async (dispatch) => {
    dispatch(deleteBrandsStart());
    const dataSent = {
      removeBrandId: ids.map((id) => parseInt(id)),
    };
    try {
      let res = await deleteManyBrand(dataSent, token);
      if (res?.data?.removeBrands) {
        dispatch(deleteBrandsSuccess(ids));
        dispatch(showSuccessMessage('Brands deleted successfully'));
      } else {
        dispatch(deleteBrandsFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteBrandsFail(error));
    }
  };
};

export const handleCreateBrand = (values, callback) => {
  return async (dispatch) => {
    dispatch(createBrandStart());

    let translationDataSent = values.languages
      ? Object.keys(values.languages).map((key) => {
          return {
            name: values.languages[key].name,
            description: values.languages[key].description,
            languageCode: key,
          };
        })
      : [];

    let imageSent = values.featuredAsset[0]
      ? await uploadImage(values.featuredAsset[0])
      : null;

    const dataSent = {
      input: {
        translationData: translationDataSent,
        media: imageSent?.data?.id ? +imageSent?.data?.id : null,
      },
    };

    try {
      let res = await createBrand(dataSent, token);
      if (res?.data?.createBrand) {
        dispatch(createBrandSuccess(res?.data?.createBrand));
        dispatch(
          showSuccessMessage('this Brand has been successfully created')
        );
      } else {
        dispatch(createBrandFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(createBrandFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};


export const handleEditBrand = (values, callback) => {
  return async (dispatch) => {
    dispatch(editBrandStart());

    let translationDataSent = values.translationData
      ? Object.keys(values.translationData).map((key) => {
          return {
            name: values.translationData[key].name,
            description: values.translationData[key].description,
            languageCode: key,
          };
        })
      : [];

    let imageSent = values.featuredAsset[0]
      ? values.featuredAsset[0].id
        ? +values.featuredAsset[0].id
        : await uploadImage(values.featuredAsset[0])
      : null;

    const dataSent = {
      input: {
        id: values.id,
        translationData: translationDataSent,
        media: imageSent?.data?.id
          ? +imageSent?.data?.id
          : imageSent
          ? imageSent
          : null,
      },
    };


    try {
      let res = await editBrand(dataSent, token);
      if (res?.data?.updateBrand) {
        dispatch(editBrandSuccess(res?.data?.updateBrand));
        dispatch(
          showSuccessMessage('this Brand has been successfully updated')
        );
      } else {
        dispatch(editBrandFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res)
    } catch (error) {
      dispatch(editBrandFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error)

    }
  };
};

export const handleImportBrands = (data) => {
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyBrand(dataSent, token);
      if (res?.data?.removeBrands) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Brands Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteBrandsFail(error));
      });
    }
  };
};

export const handleExportBrands = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyBrand(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllBrands = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyBrand(dataSent, token);
    } catch (error) {}
  };
};
