import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingDelete: false,
  loadingDeleteAll: false,
  loadingCreate: false,
  loadingEdit: false,
  loadingImport: false,
  data: [],
  error: null,
  errorFetch: null,
  success: null,
  totalCount: 0,
  selectedOne: null,
  selectedMany: [],
  isSelectedMany: false,
  deleteModal: false,
  deleteAllModal: false,
  createModal: false,
  createAllModal: false,
  editModal: false,
  editAllModal: false,
  exportModal: false,
  exportSelectedModal: false,
  importModal: false,
};

export const rolleSlice = createSlice({
  name: 'rolle',
  initialState,
  reducers: {
    // fetchInstance
    fetchRollesStart: (state) => {
      state.loading = true;
    },
    fetchRollesSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload?.roles?.nodes;
      state.totalCount = action.payload?.roles?.totalCount;
      state.errorFetch = false;
    },
    fetchRollesFail: (state, action) => {
      state.loading = false;
      state.data = [];
      state.errorFetch = action.payload;
    },

    // createInstance
    createRolleStart: (state) => {
      state.loadingCreate = true;
    },
    createRolleSuccess: (state, action) => {
      state.loadingCreate = false;
      state.data.unshift(action.payload);
      state.success = 'Rolle created successfully.';
    },
    createRolleFail: (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    },

    // editInstance
    editRolleStart: (state) => {
      state.loadingEdit = true; 
    },
    editRolleSuccess: (state, action) => {
      state.loadingEdit = false;
      state.data = state.data.map((rolle) =>
        rolle.id === action.payload.id ? action.payload : rolle
      );
      state.success = 'Rolle updated successfully.';
    },
    editRolleFail: (state, action) => {
      state.loadingEdit = false;
      state.error = action.payload;
    },

    // delete Instance
    deleteRolleStart: (state) => {
      state.loadingDelete = true;
    },
    deleteRolleSuccess: (state, action) => {
      state.loadingDelete = false;
      state.data = state.data.filter((rolle) => rolle.id !== action.payload);
      state.success = 'Rolle deleted successfully';
      state.deleteModal = false;
    },
    deleteRolleFail: (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
      state.deleteModal = false;
    },

    // delete Many Instances
    deleteRollesStart: (state) => {
      state.loadingDeleteAll = true;
    },
    deleteRollesSuccess: (state, action) => {
      state.loadingDeleteAll = false;
      const deletedIds = action.payload;
      state.data = state.data.filter((rolle) => !deletedIds.includes(rolle.id));
      state.success = 'Rolles deleted successfully';
      state.deleteAllModal = false;
    },
    deleteRollesFail: (state, action) => {
      state.loadingDeleteAll = false;
      state.error = action.payload;
      state.deleteAllModal = false;
    },

    // selectInstance

    selectOne: (state, action) => {
      state.selectedOne = action.payload;
    },

    selectAll: (state, action) => {
      if (state.isSelectedMany) {
        state.selectedMany = [];
        state.isSelectedMany = false;
      } else {
        state?.data?.map((item) => {
          if (!state.selectedMany.includes(item.id)) {
            state.selectedMany = [...state.selectedMany, item.id];
          }
        });
        state.isSelectedMany = true;
      }
    },

    removeAll: (state, action) => {
      state.selectedMany = [];
      state.isSelectedMany = false;
    },

    updateSelected: (state, action) => {
      if (state.selectedMany.includes(action.payload)) {
        state.selectedMany = state.selectedMany.filter((item) => {
          return item !== action.payload;
        });
      } else {
        state.selectedMany = [...state.selectedMany, action.payload];
      }
    },

    // DELETEiNSTANCE

    openModelDelete: (state, action) => {
      state.selectedOne = action.payload;
      state.deleteModal = !state.deleteModal;
    },

    openModelDeleteAll: (state, action) => {
      state.deleteAllModal = !state.deleteAllModal;
    },


    openModelCreate: (state, action) => {
      state.createModal = !state.createModal;
    },

    openModelEdit: (state, action) => {
      state.selectedOne = action.payload;
      state.editModal = !state.editModal;
    },
    // ExportSelected

    openModeleExportAll: (state, action) => {
      state.exportModal = true;
    },

    openModeleExportSelected: (state, action) => {
      state.exportSelectedModal = true;
    },

    // Import

    openModeleImport: (state, action) => {
      state.importModal = !state.importModal;
    },

    importStart: (state) => {
      state.loadingImport = true;
    },
    importSuccess: (state, action) => {
      state.loadingImport = false;
      state.success = 'Rolles Imported successfully';
      state.importModal = false;
    },
    importFail: (state, action) => {
      state.loadingImport = false;
      state.error = action.payload;
      state.importModal = false;
    },

    // CLEAR
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  fetchRollesStart,
  fetchRollesSuccess,
  fetchRollesFail,
  createRolleStart,
  createRolleSuccess,
  createRolleFail,
  editRolleStart,
  openModelEdit,
  openModelCreate,
  editRolleSuccess,
  editRolleFail,
  deleteRolleStart,
  deleteRolleSuccess,
  deleteRolleFail,
  clearMessages,
  updateSelected,
  selectAll,
  selectOne,
  openModelDelete,
  openModelDeleteAll,
  openModeleExportAll,
  openModeleExportSelected,
  deleteRollesStart,
  deleteRollesSuccess,
  deleteRollesFail,
  openModeleImport,
  importStart,
  importSuccess,
  importFail,
  removeAll,
} = rolleSlice.actions;

export default rolleSlice.reducer;
