
export const citiesAustriaWittZipCode = [
    {
      country_code: "AT",
      zipcode: [
        {
          city: "Eisenstadt",
          zipcode: "7000",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Rust",
          zipcode: "7071",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Loretto",
          zipcode: "2443",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Wimpassing an der Leitha",
          zipcode: "2485",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Steinbrunn",
          zipcode: "2491",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Siegendorf",
          zipcode: "7011",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Zagersdorf",
          zipcode: "7012",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Klingenbach",
          zipcode: "7013",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Zillingtal",
          zipcode: "7033",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Zillingtal",
          zipcode: "7034",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Zillingtal",
          zipcode: "7035",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Wulkaprodersdorf",
          zipcode: "7041",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Großhöflein",
          zipcode: "7051",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Müllendorf",
          zipcode: "7052",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Hornstein",
          zipcode: "7053",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Trausdorf an der Wulka",
          zipcode: "7061",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Sankt Margarethen im Burgenland",
          zipcode: "7062",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Oggau am Neusiedler See",
          zipcode: "7063",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Oslip",
          zipcode: "7064",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Mörbisch am See",
          zipcode: "7072",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Schützen am Gebirge",
          zipcode: "7081",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Donnerskirchen",
          zipcode: "7082",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Purbach am Neusiedler See",
          zipcode: "7083",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Breitenbrunn am Neusiedler See",
          zipcode: "7091",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Eberau",
          zipcode: "7521",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Strem",
          zipcode: "7522",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Ollersdorf im Burgenland",
          zipcode: "7533",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Olbendorf",
          zipcode: "7534",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Sankt Michael im Burgenland",
          zipcode: "7535",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Güttenbach",
          zipcode: "7536",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Neuberg im Burgenland",
          zipcode: "7537",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Tschanigraben",
          zipcode: "7540",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Güssing",
          zipcode: "7542",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Kukmirn",
          zipcode: "7543",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Tobaj",
          zipcode: "7544",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Heugraben",
          zipcode: "7551",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Stinatz",
          zipcode: "7552",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Burgauberg-Neudauberg",
          zipcode: "8291",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Hackerberg",
          zipcode: "8292",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Wörterberg",
          zipcode: "8293",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Heiligenkreuz im Lafnitztal",
          zipcode: "7561",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Eltendorf",
          zipcode: "7562",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Königsdorf",
          zipcode: "7563",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Rudersdorf",
          zipcode: "7564",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Rudersdorf",
          zipcode: "7571",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Deutsch Kaltenbrunn",
          zipcode: "7572",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Jennersdorf",
          zipcode: "8282",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Sankt Martin an der Raab",
          zipcode: "8350",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Jennersdorf",
          zipcode: "8380",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Weichselbaum",
          zipcode: "8382",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Sankt Martin an der Raab",
          zipcode: "8383",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Minihof-Liebau",
          zipcode: "8384",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Mühlgraben",
          zipcode: "8385",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Loipersbach im Burgenland",
          zipcode: "7020",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Baumgarten",
          zipcode: "7021",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Schattendorf",
          zipcode: "7022",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Zemendorf-Stöttera",
          zipcode: "7023",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Hirm",
          zipcode: "7024",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Krensdorf",
          zipcode: "7031",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Sigleß",
          zipcode: "7032",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Pöttsching",
          zipcode: "7033",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Antau",
          zipcode: "7042",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Neudörfl",
          zipcode: "7201",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Bad Sauerbrunn",
          zipcode: "7202",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Wiesen",
          zipcode: "7203",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Mattersburg",
          zipcode: "7210",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Forchtenstein",
          zipcode: "7212",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Marz",
          zipcode: "7221",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Rohrbach bei Mattersburg",
          zipcode: "7222",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Sieggraben",
          zipcode: "7223",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Edelstal",
          zipcode: "2413",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Kittsee",
          zipcode: "2421",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Pama",
          zipcode: "2422",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Deutsch Jahrndorf",
          zipcode: "2423",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Zurndorf",
          zipcode: "2424",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Nickelsdorf",
          zipcode: "2425",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Bruckneudorf",
          zipcode: "2460",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Bruckneudorf",
          zipcode: "2462",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Parndorf",
          zipcode: "2471",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Potzneusiedl",
          zipcode: "2473",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Gattendorf",
          zipcode: "2474",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Neudorf",
          zipcode: "2475",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Winden am See",
          zipcode: "7092",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Jois",
          zipcode: "7093",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Weiden am See",
          zipcode: "7100",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Parndorf",
          zipcode: "7111",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Weiden am See",
          zipcode: "7121",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Gols",
          zipcode: "7122",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Mönchhof",
          zipcode: "7123",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Halbturn",
          zipcode: "7131",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Frauenkirchen",
          zipcode: "7132",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Podersdorf am See",
          zipcode: "7141",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Illmitz",
          zipcode: "7142",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Apetlon",
          zipcode: "7143",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Wallern im Burgenland",
          zipcode: "7151",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Pamhagen",
          zipcode: "7152",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Sankt Andrä am Zicksee",
          zipcode: "7161",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Tadten",
          zipcode: "7162",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Andau",
          zipcode: "7163",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Markt Sankt Martin",
          zipcode: "2812",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Deutschkreutz",
          zipcode: "7301",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Nikitsch",
          zipcode: "7302",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Großwarasdorf",
          zipcode: "7304",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Neckenmarkt",
          zipcode: "7311",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Horitschon",
          zipcode: "7312",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Lackendorf",
          zipcode: "7321",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Lackenbach",
          zipcode: "7322",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Ritzing",
          zipcode: "7323",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Weppersdorf",
          zipcode: "7331",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Kobersdorf",
          zipcode: "7332",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Kobersdorf",
          zipcode: "7341",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Kaisersdorf",
          zipcode: "7342",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Neutal",
          zipcode: "7343",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Stoob",
          zipcode: "7344",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Oberpullendorf",
          zipcode: "7350",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Lutzmannsburg",
          zipcode: "7361",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Draßmarkt",
          zipcode: "7371",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Draßmarkt",
          zipcode: "7372",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Piringsdorf",
          zipcode: "7373",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Weingraben",
          zipcode: "7374",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Pilgersdorf",
          zipcode: "7441",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Lockenhaus",
          zipcode: "7442",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Mannersdorf an der Rabnitz",
          zipcode: "7443",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Mannersdorf an der Rabnitz",
          zipcode: "7444",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Oberloisdorf",
          zipcode: "7451",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Frankenau-Unterpullendorf",
          zipcode: "7452",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Steinberg-Dörfl",
          zipcode: "7453",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Oberschützen",
          zipcode: "2852",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Oberschützen",
          zipcode: "7400",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Loipersdorf-Kitzladen",
          zipcode: "7410",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Markt Allhau",
          zipcode: "7411",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Wolfau",
          zipcode: "7412",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Riedlingsdorf",
          zipcode: "7422",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Wiesfleck",
          zipcode: "7423",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Bad Tatzmannsdorf",
          zipcode: "7431",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Oberschützen",
          zipcode: "7432",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Mariasdorf",
          zipcode: "7433",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Bernstein",
          zipcode: "7434",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Unterkohlstätten",
          zipcode: "7435",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Weiden bei Rechnitz",
          zipcode: "7461",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Weiden bei Rechnitz",
          zipcode: "7463",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Markt Neuhodis",
          zipcode: "7464",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Rechnitz",
          zipcode: "7471",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Schandorf",
          zipcode: "7472",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Hannersdorf",
          zipcode: "7473",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Deutsch Schützen-Eisenberg",
          zipcode: "7474",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Rotenturm an der Pinka",
          zipcode: "7501",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Großpetersdorf",
          zipcode: "7503",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Mischendorf",
          zipcode: "7511",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Badersdorf",
          zipcode: "7512",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Kemeten",
          zipcode: "7531",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Litzelsdorf",
          zipcode: "7532",
          state: "Burgenland",
          state_code: "01",
        },
        {
          city: "Pinkafeld",
          zipcode: "8240",
          state: "Burgenland",
          state_code: "01",
        },
      ],
      state: "Burgenland",
      state_code: "01",
      cities: [
        "Eisenstadt",
        "Neusiedl am See",
        "Oberwart",
        "Mattersburg",
        "Pinkafeld",
        "Parndorf",
        "Neudörfl",
        "Jennersdorf",
        "Gols",
        "Güssing",
        "Grosspetersdorf",
        "Neufeld an der Leitha",
        "Oberpullendorf",
        "Kittsee",
        "Deutschkreutz",
        "Rechnitz",
        "Hornstein",
        "Siegendorf im Burgenland",
        "Pöttsching",
        "Purbach am Neusiedlersee",
        "Frauenkirchen",
        "Rohrbach bei Mattersburg",
        "Wiesen",
        "Stegersbach",
        "Sankt Margarethen im Burgenland",
        "Stinkenbrunn",
        "Oberschützen",
        "Weiden am See",
        "Illmitz",
        "Schattendorf",
        "Andau",
        "Mörbisch am See",
        "Zurndorf",
        "Rudersdorf",
        "Rust",
      ],
    },
    {
      country_code: "AT",
      zipcode: [
        {
          city: "Klagenfurt am Wörthersee",
          zipcode: "9020",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Klagenfurt am Wörthersee",
          zipcode: "9061",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Klagenfurt am Wörthersee",
          zipcode: "9063",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Klagenfurt am Wörthersee",
          zipcode: "9065",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Klagenfurt am Wörthersee",
          zipcode: "9073",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Klagenfurt am Wörthersee",
          zipcode: "9201",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Villach",
          zipcode: "9500",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Villach",
          zipcode: "9504",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Villach",
          zipcode: "9523",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Villach",
          zipcode: "9524",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Villach",
          zipcode: "9580",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Villach",
          zipcode: "9585",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Villach",
          zipcode: "9586",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Villach",
          zipcode: "9587",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Stefan im Gailtal",
          zipcode: "9614",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Hermagor-Pressegger See",
          zipcode: "9615",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Hermagor-Pressegger See",
          zipcode: "9620",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Gitschtal",
          zipcode: "9622",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Stefan im Gailtal",
          zipcode: "9623",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Hermagor-Pressegger See",
          zipcode: "9624",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Hermagor-Pressegger See",
          zipcode: "9631",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Kirchbach",
          zipcode: "9632",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Kirchbach",
          zipcode: "9633",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Kirchbach",
          zipcode: "9634",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Dellach",
          zipcode: "9635",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Kötschach-Mauthen",
          zipcode: "9640",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Kötschach-Mauthen",
          zipcode: "9651",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Lesachtal",
          zipcode: "9652",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Lesachtal",
          zipcode: "9653",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Lesachtal",
          zipcode: "9654",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Lesachtal",
          zipcode: "9655",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Magdalensberg",
          zipcode: "9020",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Krumpendorf am Wörthersee",
          zipcode: "9061",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Moosburg",
          zipcode: "9062",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Maria Saal",
          zipcode: "9063",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Magdalensberg",
          zipcode: "9064",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Ebenthal in Kärnten",
          zipcode: "9065",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Köttmannsdorf",
          zipcode: "9071",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Ludmannsdorf",
          zipcode: "9072",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Maria Wörth",
          zipcode: "9073",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Keutschach am See",
          zipcode: "9074",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Maria Wörth",
          zipcode: "9081",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Maria Wörth",
          zipcode: "9082",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Poggersdorf",
          zipcode: "9130",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Grafenstein",
          zipcode: "9131",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Köttmannsdorf",
          zipcode: "9161",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Feistritz im Rosental",
          zipcode: "9162",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Ferlach",
          zipcode: "9163",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Ferlach",
          zipcode: "9170",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Margareten im Rosental",
          zipcode: "9173",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Feistritz im Rosental",
          zipcode: "9181",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Krumpendorf am Wörthersee",
          zipcode: "9201",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Krumpendorf am Wörthersee",
          zipcode: "9210",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Techelsberg am Wörther See",
          zipcode: "9212",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Schiefling am Wörthersee",
          zipcode: "9220",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Schiefling am Wörthersee",
          zipcode: "9535",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Moosburg",
          zipcode: "9560",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Hüttenberg",
          zipcode: "8820",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Hüttenberg",
          zipcode: "8822",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Metnitz",
          zipcode: "8850",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Veit an der Glan",
          zipcode: "9063",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Brückl",
          zipcode: "9064",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Brückl",
          zipcode: "9102",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Veit an der Glan",
          zipcode: "9300",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Frauenstein",
          zipcode: "9311",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Mölbling",
          zipcode: "9312",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Georgen am Längsee",
          zipcode: "9313",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Kappel am Krappfeld",
          zipcode: "9314",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Kappel am Krappfeld",
          zipcode: "9321",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Micheldorf",
          zipcode: "9322",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Mölbling",
          zipcode: "9330",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Guttaring",
          zipcode: "9334",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Hüttenberg",
          zipcode: "9335",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Straßburg",
          zipcode: "9341",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Gurk",
          zipcode: "9342",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Weitensfeld im Gurktal",
          zipcode: "9343",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Weitensfeld im Gurktal",
          zipcode: "9344",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Weitensfeld im Gurktal",
          zipcode: "9345",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Glödnitz",
          zipcode: "9346",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Micheldorf",
          zipcode: "9360",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Friesach",
          zipcode: "9361",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Metnitz",
          zipcode: "9362",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Metnitz",
          zipcode: "9363",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Brückl",
          zipcode: "9371",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Eberstein",
          zipcode: "9372",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Klein St. Paul",
          zipcode: "9373",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Klein St. Paul",
          zipcode: "9374",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Hüttenberg",
          zipcode: "9375",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Hüttenberg",
          zipcode: "9376",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Liebenfels",
          zipcode: "9555",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Liebenfels",
          zipcode: "9556",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Deutsch-Griffen",
          zipcode: "9571",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Deutsch-Griffen",
          zipcode: "9572",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Radenthein",
          zipcode: "9545",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Bad Kleinkirchheim",
          zipcode: "9546",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Spittal an der Drau",
          zipcode: "9701",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Spittal an der Drau",
          zipcode: "9702",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Sachsenburg",
          zipcode: "9751",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Kleblach-Lind",
          zipcode: "9753",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Steinfeld",
          zipcode: "9754",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Greifenburg",
          zipcode: "9761",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Weißensee",
          zipcode: "9762",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Berg im Drautal",
          zipcode: "9771",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Dellach im Drautal",
          zipcode: "9772",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Irschen",
          zipcode: "9773",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Oberdrauburg",
          zipcode: "9781",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Spittal an der Drau",
          zipcode: "9800",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Baldramsdorf",
          zipcode: "9805",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Lendorf",
          zipcode: "9811",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Lurnfeld",
          zipcode: "9812",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Lurnfeld",
          zipcode: "9813",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Mühldorf",
          zipcode: "9814",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Reißeck",
          zipcode: "9815",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Reißeck",
          zipcode: "9816",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Obervellach",
          zipcode: "9821",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Mallnitz",
          zipcode: "9822",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Flattach",
          zipcode: "9831",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Stall",
          zipcode: "9832",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Rangersdorf",
          zipcode: "9833",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Winklern",
          zipcode: "9841",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Mörtschach",
          zipcode: "9842",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Großkirchheim",
          zipcode: "9843",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Heiligenblut am Großglockner",
          zipcode: "9844",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Seeboden am Millstätter See",
          zipcode: "9851",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Trebesing",
          zipcode: "9852",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Gmünd in Kärnten",
          zipcode: "9853",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Malta",
          zipcode: "9854",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Krems in Kärnten",
          zipcode: "9861",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Rennweg am Katschberg",
          zipcode: "9862",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Rennweg am Katschberg",
          zipcode: "9863",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Seeboden am Millstätter See",
          zipcode: "9871",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Millstatt am See",
          zipcode: "9872",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Radenthein",
          zipcode: "9873",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Jakob im Rosental",
          zipcode: "9182",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Jakob im Rosental",
          zipcode: "9183",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Jakob im Rosental",
          zipcode: "9184",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Velden am Wörther See",
          zipcode: "9220",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Velden am Wörther See",
          zipcode: "9231",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Rosegg",
          zipcode: "9232",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Wernberg",
          zipcode: "9241",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Weißenstein",
          zipcode: "9500",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Treffen am Ossiacher See",
          zipcode: "9520",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Treffen am Ossiacher See",
          zipcode: "9521",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Bad Bleiberg",
          zipcode: "9530",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Nötsch im Gailtal",
          zipcode: "9531",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Velden am Wörther See",
          zipcode: "9535",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Velden am Wörther See",
          zipcode: "9536",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Treffen am Ossiacher See",
          zipcode: "9541",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Treffen am Ossiacher See",
          zipcode: "9542",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Arriach",
          zipcode: "9543",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Feld am See",
          zipcode: "9544",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Treffen am Ossiacher See",
          zipcode: "9551",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Finkenstein am Faaker See",
          zipcode: "9581",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Finkenstein am Faaker See",
          zipcode: "9582",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Finkenstein am Faaker See",
          zipcode: "9583",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Finkenstein am Faaker See",
          zipcode: "9584",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Finkenstein am Faaker See",
          zipcode: "9585",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Finkenstein am Faaker See",
          zipcode: "9586",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Arnoldstein",
          zipcode: "9587",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Arnoldstein",
          zipcode: "9601",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Arnoldstein",
          zipcode: "9602",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Nötsch im Gailtal",
          zipcode: "9611",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Nötsch im Gailtal",
          zipcode: "9612",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Hohenthurn",
          zipcode: "9613",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Nötsch im Gailtal",
          zipcode: "9623",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Paternion",
          zipcode: "9701",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Ferndorf",
          zipcode: "9702",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Paternion",
          zipcode: "9710",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Paternion",
          zipcode: "9711",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Fresach",
          zipcode: "9712",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Stockenboi",
          zipcode: "9713",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Stockenboi",
          zipcode: "9714",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Weißenstein",
          zipcode: "9721",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Weißenstein",
          zipcode: "9722",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Völkermarkt",
          zipcode: "9064",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Völkermarkt",
          zipcode: "9100",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Völkermarkt",
          zipcode: "9102",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Diex",
          zipcode: "9103",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Völkermarkt",
          zipcode: "9111",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Griffen",
          zipcode: "9112",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Ruden",
          zipcode: "9113",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Völkermarkt",
          zipcode: "9121",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Kanzian am Klopeiner See",
          zipcode: "9122",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Gallizien",
          zipcode: "9123",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Eberndorf",
          zipcode: "9125",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Gallizien",
          zipcode: "9131",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Gallizien",
          zipcode: "9132",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Sittersdorf",
          zipcode: "9133",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Eisenkappel-Vellach",
          zipcode: "9135",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Eberndorf",
          zipcode: "9141",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Globasnitz",
          zipcode: "9142",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Feistritz ob Bleiburg",
          zipcode: "9143",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Bleiburg",
          zipcode: "9150",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Neuhaus",
          zipcode: "9155",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Gallizien",
          zipcode: "9173",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Völkermarkt",
          zipcode: "9371",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Andrä",
          zipcode: "9112",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Wolfsberg",
          zipcode: "9400",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Wolfsberg",
          zipcode: "9411",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Wolfsberg",
          zipcode: "9412",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Frantschach-St. Gertraud",
          zipcode: "9413",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Andrä",
          zipcode: "9421",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Andrä",
          zipcode: "9422",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Georgen im Lavanttal",
          zipcode: "9423",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Wolfsberg",
          zipcode: "9431",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Andrä",
          zipcode: "9433",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Bad St. Leonhard im Lavanttal",
          zipcode: "9441",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Preitenegg",
          zipcode: "9451",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Wolfsberg",
          zipcode: "9461",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Bad St. Leonhard im Lavanttal",
          zipcode: "9462",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Reichenfels",
          zipcode: "9463",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Paul im Lavanttal",
          zipcode: "9470",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Lavamünd",
          zipcode: "9472",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Lavamünd",
          zipcode: "9473",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Feldkirchen in Kärnten",
          zipcode: "9062",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Ossiach",
          zipcode: "9231",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Albeck",
          zipcode: "9345",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Steindorf am Ossiacher See",
          zipcode: "9551",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Himmelberg",
          zipcode: "9552",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "St. Urban",
          zipcode: "9554",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Glanegg",
          zipcode: "9555",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Glanegg",
          zipcode: "9556",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Steuerberg",
          zipcode: "9560",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Himmelberg",
          zipcode: "9562",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Gnesau",
          zipcode: "9563",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Reichenau",
          zipcode: "9564",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Reichenau",
          zipcode: "9565",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Ossiach",
          zipcode: "9570",
          state: "Kärnten",
          state_code: "02",
        },
        {
          city: "Albeck",
          zipcode: "9571",
          state: "Kärnten",
          state_code: "02",
        },
      ],
      state: "Kärnten",
      state_code: "02",
      cities: [
        "Klagenfurt",
        "Wolfsberg",
        "Spittal an der Drau",
        "Feldkirchen",
        "Sankt Veit an der Glan",
        "Völkermarkt",
        "Sankt Andrä",
        "Velden am Wörthersee",
        "Ferlach",
        "Arnoldstein",
        "Seeboden",
        "Eberndorf",
        "Radenthein",
        "Paternion",
        "Wernberg",
        "Friesach",
        "Althofen",
        "Moosburg",
        "Treffen",
        "Bad Sankt Leonhard im Lavanttal",
        "Sankt Jakob",
        "Bleiburg",
        "Maria Saal",
        "Steindorf am Ossiacher See",
        "Sankt Georgen am Längsee",
        "Frauenstein",
        "Griffen",
        "Millstatt",
        "Liebenfels",
        "Sankt Paul im Levanttal",
        "Poggersdorf",
        "Köttmannsdorf",
        "Weissenstein",
        "Lavamünd",
        "Grafenstein",
        "Pörtschach am Wörthersee",
        "Brückl",
        "Schiefling am See",
        "Kirchbach",
        "Maria Rain",
        "Gmünd",
        "Feistritz im Rosental",
        "Keutschach am See",
        "Bad Eisenkappel",
        "Himmelberg",
        "Bleiberg ob Villach",
        "Sankt Martin am Techelsberg",
        "Obervellach",
        "Bistrica nad Pliberkom",
        "Villach",
        "Hermagor",
      ],
    },
    {
      country_code: "AT",
      zipcode: [
        {
          city: "Krems an der Donau",
          zipcode: "3500",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Krems an der Donau",
          zipcode: "3506",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Krems an der Donau",
          zipcode: "3541",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Pölten",
          zipcode: "3100",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Pölten",
          zipcode: "3104",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Pölten",
          zipcode: "3105",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Pölten",
          zipcode: "3107",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Pölten",
          zipcode: "3140",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Pölten",
          zipcode: "3151",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Pölten",
          zipcode: "3385",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Waidhofen an der Ybbs",
          zipcode: "3263",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Waidhofen an der Ybbs",
          zipcode: "3340",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wiener Neustadt",
          zipcode: "2700",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wiener Neustadt",
          zipcode: "2751",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wiener Neustadt",
          zipcode: "2752",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ardagger",
          zipcode: "3300",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Georgen am Ybbsfelde",
          zipcode: "3304",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Amstetten",
          zipcode: "3311",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zeillern",
          zipcode: "3312",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wallsee-Sindelburg",
          zipcode: "3313",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Strengberg",
          zipcode: "3314",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ardagger",
          zipcode: "3321",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Viehdorf",
          zipcode: "3322",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Neustadtl an der Donau",
          zipcode: "3323",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Euratsfeld",
          zipcode: "3324",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ferschnitz",
          zipcode: "3325",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sonntagberg",
          zipcode: "3331",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sonntagberg",
          zipcode: "3332",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sonntagberg",
          zipcode: "3333",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ybbsitz",
          zipcode: "3340",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ybbsitz",
          zipcode: "3341",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Opponitz",
          zipcode: "3342",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hollenstein an der Ybbs",
          zipcode: "3343",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Georgen am Reith",
          zipcode: "3344",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Haag",
          zipcode: "3350",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Weistrach",
          zipcode: "3351",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Peter in der Au",
          zipcode: "3352",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Biberbach",
          zipcode: "3353",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Strengberg",
          zipcode: "3354",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ertl",
          zipcode: "3355",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Aschbach-Markt",
          zipcode: "3361",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Amstetten",
          zipcode: "3362",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Amstetten",
          zipcode: "3363",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Neuhofen an der Ybbs",
          zipcode: "3364",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Allhartsberg",
          zipcode: "3365",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ferschnitz",
          zipcode: "3372",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Valentin",
          zipcode: "4300",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Pantaleon-Erla",
          zipcode: "4303",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Haidershofen",
          zipcode: "4431",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ernsthofen",
          zipcode: "4432",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Behamberg",
          zipcode: "4441",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Peter in der Au",
          zipcode: "4442",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Peter in der Au",
          zipcode: "4443",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Valentin",
          zipcode: "4482",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Reisenberg",
          zipcode: "2440",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Mitterndorf an der Fischa",
          zipcode: "2441",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ebreichsdorf",
          zipcode: "2442",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Seibersdorf",
          zipcode: "2443",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Seibersdorf",
          zipcode: "2444",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Reisenberg",
          zipcode: "2452",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ebreichsdorf",
          zipcode: "2483",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pottendorf",
          zipcode: "2485",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pottendorf",
          zipcode: "2486",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Alland",
          zipcode: "2500",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sooß",
          zipcode: "2504",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pfaffstätten",
          zipcode: "2511",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Traiskirchen",
          zipcode: "2512",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Traiskirchen",
          zipcode: "2514",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Trumau",
          zipcode: "2521",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Oberwaltersdorf",
          zipcode: "2522",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Tattendorf",
          zipcode: "2523",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Teesdorf",
          zipcode: "2524",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schönau an der Triesting",
          zipcode: "2525",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Heiligenkreuz",
          zipcode: "2532",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Klausen-Leopoldsdorf",
          zipcode: "2533",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Alland",
          zipcode: "2534",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bad Vöslau",
          zipcode: "2540",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Leobersdorf",
          zipcode: "2542",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kottingbrunn",
          zipcode: "2544",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Enzesfeld-Lindabrunn",
          zipcode: "2551",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Enzesfeld-Lindabrunn",
          zipcode: "2552",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hernstein",
          zipcode: "2560",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pottenstein",
          zipcode: "2563",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pottenstein",
          zipcode: "2564",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Weissenbach an der Triesting",
          zipcode: "2565",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Altenmarkt an der Triesting",
          zipcode: "2571",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Altenmarkt an der Triesting",
          zipcode: "2572",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schönau an der Triesting",
          zipcode: "2602",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Klausen-Leopoldsdorf",
          zipcode: "3033",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Klausen-Leopoldsdorf",
          zipcode: "3053",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schwechat",
          zipcode: "1300",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schwechat",
          zipcode: "2320",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zwölfaxing",
          zipcode: "2322",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Himberg",
          zipcode: "2325",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Lanzendorf",
          zipcode: "2326",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Leopoldsdorf",
          zipcode: "2333",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Fischamend",
          zipcode: "2401",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Haslau-Maria Ellend",
          zipcode: "2402",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Scharndorf",
          zipcode: "2403",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Petronell-Carnuntum",
          zipcode: "2404",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hundsheim",
          zipcode: "2405",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hainburg a.d. Donau",
          zipcode: "2410",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wolfsthal",
          zipcode: "2412",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Berg",
          zipcode: "2413",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Enzersdorf an der Fischa",
          zipcode: "2431",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schwadorf",
          zipcode: "2432",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Enzersdorf an der Fischa",
          zipcode: "2433",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Götzendorf an der Leitha",
          zipcode: "2434",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Götzendorf an der Leitha",
          zipcode: "2435",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gramatneusiedl",
          zipcode: "2440",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Moosbrunn",
          zipcode: "2441",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hof am Leithaberge",
          zipcode: "2451",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Mannersdorf am Leithagebirge",
          zipcode: "2452",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sommerein",
          zipcode: "2453",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Trautmannsdorf an der Leitha",
          zipcode: "2454",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bruck an der Leitha",
          zipcode: "2460",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bruck an der Leitha",
          zipcode: "2462",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Trautmannsdorf an der Leitha",
          zipcode: "2463",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Göttlesbrunn-Arbesthal",
          zipcode: "2464",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Höflein",
          zipcode: "2465",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Rohrau",
          zipcode: "2471",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Prellenkirchen",
          zipcode: "2472",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Prellenkirchen",
          zipcode: "2473",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Palterndorf-Dobermannsdorf",
          zipcode: "2181",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Palterndorf-Dobermannsdorf",
          zipcode: "2182",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Neusiedl an der Zaya",
          zipcode: "2183",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hauskirchen",
          zipcode: "2184",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hauskirchen",
          zipcode: "2185",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Auersthal",
          zipcode: "2214",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Matzen-Raggendorf",
          zipcode: "2215",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Groß-Schweinbarth",
          zipcode: "2221",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bad Pirawarth",
          zipcode: "2222",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Matzen-Raggendorf",
          zipcode: "2223",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sulz im Weinviertel",
          zipcode: "2224",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zistersdorf",
          zipcode: "2225",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gänserndorf",
          zipcode: "2230",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Strasshof an der Nordbahn",
          zipcode: "2231",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Aderklaa",
          zipcode: "2232",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schönkirchen-Reyersdorf",
          zipcode: "2241",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Prottes",
          zipcode: "2242",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Matzen-Raggendorf",
          zipcode: "2243",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Spannberg",
          zipcode: "2244",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Velm-Götzendorf",
          zipcode: "2245",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ebenthal",
          zipcode: "2251",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Angern an der March",
          zipcode: "2252",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Weikendorf",
          zipcode: "2253",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Angern an der March",
          zipcode: "2261",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Angern an der March",
          zipcode: "2262",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Dürnkrut",
          zipcode: "2263",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Jedenspeigen",
          zipcode: "2264",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Drösing",
          zipcode: "2265",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ringelsdorf-Niederabsdorf",
          zipcode: "2272",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hohenau an der March",
          zipcode: "2273",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Raasdorf",
          zipcode: "2281",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Markgrafneusiedl",
          zipcode: "2282",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Obersiebenbrunn",
          zipcode: "2283",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Untersiebenbrunn",
          zipcode: "2284",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Leopoldsdorf im Marchfelde",
          zipcode: "2285",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Haringsee",
          zipcode: "2286",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Lassee",
          zipcode: "2291",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Engelhartstetten",
          zipcode: "2292",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Marchegg",
          zipcode: "2293",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Marchegg",
          zipcode: "2294",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Weiden an der March",
          zipcode: "2295",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Groß-Enzersdorf",
          zipcode: "2301",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Mannsdorf an der Donau",
          zipcode: "2304",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Eckartsau",
          zipcode: "2305",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Heidenreichstein",
          zipcode: "3834",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Heidenreichstein",
          zipcode: "3860",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Eggern",
          zipcode: "3861",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Eisgarn",
          zipcode: "3862",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Reingers",
          zipcode: "3863",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Brand-Nagelberg",
          zipcode: "3871",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Amaliendorf-Aalfang",
          zipcode: "3872",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Brand-Nagelberg",
          zipcode: "3873",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Haugschlag",
          zipcode: "3874",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Großschönau",
          zipcode: "3922",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Großschönau",
          zipcode: "3923",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kirchberg am Walde",
          zipcode: "3932",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hirschbach",
          zipcode: "3942",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schrems",
          zipcode: "3943",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schrems",
          zipcode: "3944",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hoheneich",
          zipcode: "3945",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Großdietmanns",
          zipcode: "3950",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Waldenstein",
          zipcode: "3961",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Unserfrau-Altweitra",
          zipcode: "3962",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Unserfrau-Altweitra",
          zipcode: "3970",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Martin",
          zipcode: "3971",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bad Großpertholz",
          zipcode: "3972",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bad Großpertholz",
          zipcode: "3973",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Göllersdorf",
          zipcode: "2013",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hollabrunn",
          zipcode: "2014",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hollabrunn",
          zipcode: "2020",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wullersdorf",
          zipcode: "2022",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Nappersdorf-Kammersdorf",
          zipcode: "2023",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Mailberg",
          zipcode: "2024",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hollabrunn",
          zipcode: "2031",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hollabrunn",
          zipcode: "2032",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Nappersdorf-Kammersdorf",
          zipcode: "2033",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wullersdorf",
          zipcode: "2041",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Guntersdorf",
          zipcode: "2042",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zellerndorf",
          zipcode: "2051",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pernersdorf",
          zipcode: "2052",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pernersdorf",
          zipcode: "2053",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Alberndorf im Pulkautal",
          zipcode: "2054",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hadres",
          zipcode: "2061",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Seefeld-Kadolz",
          zipcode: "2062",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Retzbach",
          zipcode: "2070",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zellerndorf",
          zipcode: "2073",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Retzbach",
          zipcode: "2074",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hardegg",
          zipcode: "2081",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hardegg",
          zipcode: "2082",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hardegg",
          zipcode: "2083",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hardegg",
          zipcode: "2092",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hohenwarth-Mühlbach a.M.",
          zipcode: "3472",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hohenwarth-Mühlbach a.M.",
          zipcode: "3473",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Heldenberg",
          zipcode: "3701",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Heldenberg",
          zipcode: "3704",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ziersdorf",
          zipcode: "3710",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hohenwarth-Mühlbach a.M.",
          zipcode: "3711",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Maissau",
          zipcode: "3712",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Maissau",
          zipcode: "3713",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sitzendorf an der Schmida",
          zipcode: "3714",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ravelsbach",
          zipcode: "3720",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Maissau",
          zipcode: "3721",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pulkau",
          zipcode: "3741",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pulkau",
          zipcode: "3742",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Weitersfeld",
          zipcode: "2081",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Weitersfeld",
          zipcode: "2084",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Langau",
          zipcode: "2091",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Geras",
          zipcode: "2093",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Drosendorf-Zissersdorf",
          zipcode: "2094",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Drosendorf-Zissersdorf",
          zipcode: "2095",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gars am Kamp",
          zipcode: "3571",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gars am Kamp",
          zipcode: "3573",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Rosenburg-Mold",
          zipcode: "3580",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Altenburg",
          zipcode: "3591",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Röhrenbach",
          zipcode: "3592",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Röhrenbach",
          zipcode: "3593",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Brunn an der Wild",
          zipcode: "3595",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Burgschleinitz-Kühnring",
          zipcode: "3713",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Straning-Grafenberg",
          zipcode: "3722",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Burgschleinitz-Kühnring",
          zipcode: "3730",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Weitersfeld",
          zipcode: "3742",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Röschitz",
          zipcode: "3743",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Rosenburg-Mold",
          zipcode: "3744",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sigmundsherberg",
          zipcode: "3751",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Weitersfeld",
          zipcode: "3752",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Geras",
          zipcode: "3753",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Irnfritz-Messern",
          zipcode: "3754",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Bernhard-Frauenhofen",
          zipcode: "3761",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Japons",
          zipcode: "3763",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Langenzersdorf",
          zipcode: "1210",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Stockerau",
          zipcode: "2000",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Großmugl",
          zipcode: "2002",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Leitzersdorf",
          zipcode: "2003",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Niederhollabrunn",
          zipcode: "2004",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sierndorf",
          zipcode: "2011",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Großmugl",
          zipcode: "2032",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Stetten",
          zipcode: "2100",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Langenzersdorf",
          zipcode: "2102",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Langenzersdorf",
          zipcode: "2103",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Spillern",
          zipcode: "2104",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Leobendorf",
          zipcode: "2105",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Harmannsdorf",
          zipcode: "2111",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Harmannsdorf",
          zipcode: "2112",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Großrußbach",
          zipcode: "2113",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Großrußbach",
          zipcode: "2114",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ernstbrunn",
          zipcode: "2115",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ernstbrunn",
          zipcode: "2116",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gerasdorf bei Wien",
          zipcode: "2201",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hagenbrunn",
          zipcode: "2202",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Stetteldorf am Wagram",
          zipcode: "3430",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Stetteldorf am Wagram",
          zipcode: "3463",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Stetteldorf am Wagram",
          zipcode: "3464",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Stetteldorf am Wagram",
          zipcode: "3701",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Rußbach",
          zipcode: "3702",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bergern im Dunkelsteinerwald",
          zipcode: "3122",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gedersdorf",
          zipcode: "3485",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Straß im Straßertale",
          zipcode: "3491",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Grafenegg",
          zipcode: "3492",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hadersdorf-Kammern",
          zipcode: "3493",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gedersdorf",
          zipcode: "3494",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Rohrendorf bei Krems",
          zipcode: "3495",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Senftenberg",
          zipcode: "3500",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Paudorf",
          zipcode: "3508",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Furth bei Göttweig",
          zipcode: "3511",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Mautern an der Donau",
          zipcode: "3512",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Senftenberg",
          zipcode: "3521",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Lichtenau im Waldviertel",
          zipcode: "3522",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Lichtenau im Waldviertel",
          zipcode: "3524",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Rastenfeld",
          zipcode: "3532",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Weinzierl am Walde",
          zipcode: "3541",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gföhl",
          zipcode: "3542",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Krumau am Kamp",
          zipcode: "3543",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Krumau am Kamp",
          zipcode: "3544",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Langenlois",
          zipcode: "3550",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Stratzing",
          zipcode: "3552",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Langenlois",
          zipcode: "3553",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Langenlois",
          zipcode: "3561",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schönberg am Kamp",
          zipcode: "3562",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schönberg am Kamp",
          zipcode: "3564",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Jaidhof",
          zipcode: "3572",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Dürnstein",
          zipcode: "3601",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Rossatz-Arnsdorf",
          zipcode: "3602",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Weißenkirchen in der Wachau",
          zipcode: "3610",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Weinzierl am Walde",
          zipcode: "3611",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Albrechtsberg an der Großen Krems",
          zipcode: "3613",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Maria Laach am Jauerling",
          zipcode: "3620",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Rossatz-Arnsdorf",
          zipcode: "3621",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Mühldorf",
          zipcode: "3622",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Aggsbach",
          zipcode: "3641",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bergern im Dunkelsteinerwald",
          zipcode: "3642",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Maria Laach am Jauerling",
          zipcode: "3643",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hainfeld",
          zipcode: "2572",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kleinzell",
          zipcode: "2663",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Veit an der Gölsen",
          zipcode: "3150",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Eschenau",
          zipcode: "3153",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Traisen",
          zipcode: "3160",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Veit an der Gölsen",
          zipcode: "3161",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Veit an der Gölsen",
          zipcode: "3162",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Rohrbach an der Gölsen",
          zipcode: "3163",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hainfeld",
          zipcode: "3170",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kleinzell",
          zipcode: "3171",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ramsau",
          zipcode: "3172",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Lilienfeld",
          zipcode: "3180",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hohenberg",
          zipcode: "3183",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Türnitz",
          zipcode: "3184",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hohenberg",
          zipcode: "3192",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Aegyd am Neuwalde",
          zipcode: "3193",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Aegyd am Neuwalde",
          zipcode: "3195",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Annaberg",
          zipcode: "3222",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Annaberg",
          zipcode: "3223",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Mitterbach am Erlaufsee",
          zipcode: "3224",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Aegyd am Neuwalde",
          zipcode: "8630",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Aegyd am Neuwalde",
          zipcode: "8694",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Dunkelsteinerwald",
          zipcode: "3122",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kilb",
          zipcode: "3203",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kilb",
          zipcode: "3204",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bischofstetten",
          zipcode: "3232",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kilb",
          zipcode: "3233",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Mank",
          zipcode: "3240",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kirnberg an der Mank",
          zipcode: "3241",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Texingtal",
          zipcode: "3242",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Leonhard am Forst",
          zipcode: "3243",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ruprechtshofen",
          zipcode: "3244",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Petzenkirchen",
          zipcode: "3252",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Erlauf",
          zipcode: "3253",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bergland",
          zipcode: "3254",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ybbs an der Donau",
          zipcode: "3370",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Neumarkt an der Ybbs",
          zipcode: "3371",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Blindenmarkt",
          zipcode: "3372",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Neumarkt an der Ybbs",
          zipcode: "3373",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ybbs an der Donau",
          zipcode: "3374",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Krummnußbaum",
          zipcode: "3375",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Martin-Karlsbach",
          zipcode: "3376",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pöchlarn",
          zipcode: "3380",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Golling an der Erlauf",
          zipcode: "3381",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Loosdorf",
          zipcode: "3382",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hürm",
          zipcode: "3383",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Melk",
          zipcode: "3390",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Dunkelsteinerwald",
          zipcode: "3392",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zelking-Matzleinsdorf",
          zipcode: "3393",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schönbühel-Aggsbach",
          zipcode: "3642",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Emmersdorf an der Donau",
          zipcode: "3644",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pöggstall",
          zipcode: "3650",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Leiben",
          zipcode: "3652",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Weiten",
          zipcode: "3653",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Raxendorf",
          zipcode: "3654",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Klein-Pöchlarn",
          zipcode: "3660",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Maria Taferl",
          zipcode: "3661",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Münichreith-Laimbach",
          zipcode: "3662",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Münichreith-Laimbach",
          zipcode: "3663",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Marbach an der Donau",
          zipcode: "3671",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Maria Taferl",
          zipcode: "3672",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Persenbeug-Gottsdorf",
          zipcode: "3680",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hofamt Priel",
          zipcode: "3681",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Yspertal",
          zipcode: "3683",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Oswald",
          zipcode: "3684",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Nöchling",
          zipcode: "3691",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Dorfstetten",
          zipcode: "4392",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gnadendorf",
          zipcode: "2032",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Großharras",
          zipcode: "2034",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Großharras",
          zipcode: "2063",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Laa an der Thaya",
          zipcode: "2064",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kreuttal",
          zipcode: "2112",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kreuttal",
          zipcode: "2114",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Niederleis",
          zipcode: "2115",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Niederleis",
          zipcode: "2116",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wolkersdorf im Weinviertel",
          zipcode: "2120",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wolkersdorf im Weinviertel",
          zipcode: "2122",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kreuttal",
          zipcode: "2123",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kreuzstetten",
          zipcode: "2124",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kreuzstetten",
          zipcode: "2125",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ladendorf",
          zipcode: "2126",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wilfersdorf",
          zipcode: "2130",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Mistelbach",
          zipcode: "2132",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Fallbach",
          zipcode: "2133",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Staatz",
          zipcode: "2134",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Neudorf bei Staatz",
          zipcode: "2135",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Laa an der Thaya",
          zipcode: "2136",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Poysdorf",
          zipcode: "2141",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Großkrut",
          zipcode: "2143",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Altlichtenwarth",
          zipcode: "2144",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hausbrunn",
          zipcode: "2145",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Asparn an der Zaya",
          zipcode: "2151",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gnadendorf",
          zipcode: "2152",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Stronsdorf",
          zipcode: "2153",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gaubitsch",
          zipcode: "2154",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Poysdorf",
          zipcode: "2161",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Falkenstein",
          zipcode: "2162",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wildendürnbach",
          zipcode: "2163",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wildendürnbach",
          zipcode: "2164",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Drasenhofen",
          zipcode: "2165",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Poysdorf",
          zipcode: "2170",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Herrnbaumgarten",
          zipcode: "2171",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schrattenberg",
          zipcode: "2172",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wilfersdorf",
          zipcode: "2185",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gaweinstal",
          zipcode: "2191",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Mistelbach",
          zipcode: "2192",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wilfersdorf",
          zipcode: "2193",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Großebersdorf",
          zipcode: "2203",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pillichsdorf",
          zipcode: "2211",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Großengersdorf",
          zipcode: "2212",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bockfließ",
          zipcode: "2213",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gaweinstal",
          zipcode: "2223",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bockfließ",
          zipcode: "2231",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Rabensburg",
          zipcode: "2274",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bernhardsthal",
          zipcode: "2275",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bernhardsthal",
          zipcode: "2276",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Vösendorf",
          zipcode: "2331",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hennersdorf",
          zipcode: "2332",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Vösendorf",
          zipcode: "2334",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Maria Enzersdorf",
          zipcode: "2340",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Maria Enzersdorf",
          zipcode: "2344",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Brunn am Gebirge",
          zipcode: "2345",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wiener Neudorf",
          zipcode: "2351",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gumpoldskirchen",
          zipcode: "2352",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Guntramsdorf",
          zipcode: "2353",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Laxenburg",
          zipcode: "2361",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Biedermannsdorf",
          zipcode: "2362",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hinterbrühl",
          zipcode: "2371",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gießhübl",
          zipcode: "2372",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Perchtoldsdorf",
          zipcode: "2380",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Laab im Walde",
          zipcode: "2381",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Breitenfurt bei Wien",
          zipcode: "2384",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kaltenleutgeben",
          zipcode: "2391",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wienerwald",
          zipcode: "2392",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hinterbrühl",
          zipcode: "2393",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Achau",
          zipcode: "2481",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Münchendorf",
          zipcode: "2482",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gaaden",
          zipcode: "2531",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ternitz",
          zipcode: "2620",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Breitenau",
          zipcode: "2624",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schwarzau am Steinfeld",
          zipcode: "2625",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bürg-Vöstenhof",
          zipcode: "2630",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ternitz",
          zipcode: "2631",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Grafenbach-St. Valentin",
          zipcode: "2632",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Altendorf",
          zipcode: "2640",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schottwien",
          zipcode: "2641",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schottwien",
          zipcode: "2642",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Payerbach",
          zipcode: "2650",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Reichenau an der Rax",
          zipcode: "2651",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Reichenau an der Rax",
          zipcode: "2654",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schwarzau im Gebirge",
          zipcode: "2661",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schwarzau im Gebirge",
          zipcode: "2662",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Payerbach",
          zipcode: "2671",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Payerbach",
          zipcode: "2673",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Semmering",
          zipcode: "2680",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Höflein an der Hohen Wand",
          zipcode: "2724",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Willendorf",
          zipcode: "2731",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Willendorf",
          zipcode: "2732",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Höflein an der Hohen Wand",
          zipcode: "2733",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Puchberg am Schneeberg",
          zipcode: "2734",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Puchberg am Schneeberg",
          zipcode: "2761",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Thomasberg",
          zipcode: "2813",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pitten",
          zipcode: "2822",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pitten",
          zipcode: "2823",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Seebenstein",
          zipcode: "2824",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Warth",
          zipcode: "2831",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Scheiblingkirchen-Thernberg",
          zipcode: "2832",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Scheiblingkirchen-Thernberg",
          zipcode: "2833",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Grimmenstein",
          zipcode: "2840",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Thomasberg",
          zipcode: "2842",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Thomasberg",
          zipcode: "2851",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zöbern",
          zipcode: "2852",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Aspangberg-St. Peter",
          zipcode: "2870",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zöbern",
          zipcode: "2871",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Mönichkirchen",
          zipcode: "2872",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Feistritz am Wechsel",
          zipcode: "2873",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Feistritz am Wechsel",
          zipcode: "2880",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Trattenbach",
          zipcode: "2881",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Mönichkirchen",
          zipcode: "7421",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zöbern",
          zipcode: "8244",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Purkersdorf",
          zipcode: "1140",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wolfsgraben",
          zipcode: "2381",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pressbaum",
          zipcode: "2533",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Mauerbach",
          zipcode: "3001",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Purkersdorf",
          zipcode: "3002",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gablitz",
          zipcode: "3003",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Tullnerbach",
          zipcode: "3004",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Tullnerbach",
          zipcode: "3011",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pressbaum",
          zipcode: "3012",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Tullnerbach",
          zipcode: "3013",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pressbaum",
          zipcode: "3021",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pressbaum",
          zipcode: "3031",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Maria-Anzbach",
          zipcode: "3032",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Maria-Anzbach",
          zipcode: "3033",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Maria-Anzbach",
          zipcode: "3034",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Neulengbach",
          zipcode: "3040",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Asperhofen",
          zipcode: "3041",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Neulengbach",
          zipcode: "3051",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Altlengbach",
          zipcode: "3052",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Brand-Laaben",
          zipcode: "3053",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kirchstetten",
          zipcode: "3061",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kirchstetten",
          zipcode: "3062",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kasten bei Böheimkirchen",
          zipcode: "3071",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kasten bei Böheimkirchen",
          zipcode: "3072",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Brand-Laaben",
          zipcode: "3073",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Michelbach",
          zipcode: "3074",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pyhra",
          zipcode: "3104",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Neidling",
          zipcode: "3110",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Karlstetten",
          zipcode: "3121",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wölbling",
          zipcode: "3122",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Obritzberg-Rust",
          zipcode: "3123",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wölbling",
          zipcode: "3124",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Statzendorf",
          zipcode: "3125",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Herzogenburg",
          zipcode: "3130",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Inzersdorf-Getzersdorf",
          zipcode: "3131",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Traismauer",
          zipcode: "3133",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Nußdorf ob der Traisen",
          zipcode: "3134",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Böheimkirchen",
          zipcode: "3140",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kapelln",
          zipcode: "3141",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Perschling",
          zipcode: "3142",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pyhra",
          zipcode: "3143",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pyhra",
          zipcode: "3144",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wilhelmsburg",
          zipcode: "3150",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ober-Grafendorf",
          zipcode: "3200",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hofstetten-Grünau",
          zipcode: "3202",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Rabenstein an der Pielach",
          zipcode: "3203",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kirchberg an der Pielach",
          zipcode: "3204",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Weinburg",
          zipcode: "3205",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Loich",
          zipcode: "3211",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schwarzenbach an der Pielach",
          zipcode: "3212",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Frankenfels",
          zipcode: "3213",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Frankenfels",
          zipcode: "3214",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Margarethen an der Sierning",
          zipcode: "3231",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hofstetten-Grünau",
          zipcode: "3233",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Haunoldstein",
          zipcode: "3384",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gerersdorf",
          zipcode: "3385",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hafnerbach",
          zipcode: "3386",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Mauerbach",
          zipcode: "3400",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Tullnerbach",
          zipcode: "3443",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Asperhofen",
          zipcode: "3451",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Herzogenburg",
          zipcode: "3454",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Anton an der Jeßnitz",
          zipcode: "3213",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Puchenstuben",
          zipcode: "3214",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Puchenstuben",
          zipcode: "3221",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gaming",
          zipcode: "3223",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Oberndorf an der Melk",
          zipcode: "3241",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Anton an der Jeßnitz",
          zipcode: "3242",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wieselburg-Land",
          zipcode: "3250",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Purgstall an der Erlauf",
          zipcode: "3251",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wieselburg-Land",
          zipcode: "3252",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Steinakirchen am Forst",
          zipcode: "3261",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wang",
          zipcode: "3262",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Randegg",
          zipcode: "3263",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gresten",
          zipcode: "3264",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Scheibbs",
          zipcode: "3270",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Oberndorf an der Melk",
          zipcode: "3281",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Georgen an der Leys",
          zipcode: "3282",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Scheibbs",
          zipcode: "3283",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gaming",
          zipcode: "3291",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gaming",
          zipcode: "3292",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Lunz am See",
          zipcode: "3293",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gaming",
          zipcode: "3294",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gaming",
          zipcode: "3295",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wang",
          zipcode: "3325",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Randegg",
          zipcode: "3340",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gresten-Land",
          zipcode: "3341",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Göstling an der Ybbs",
          zipcode: "3345",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Randegg",
          zipcode: "3364",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wolfpassing",
          zipcode: "3371",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wieselburg-Land",
          zipcode: "3373",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gaming",
          zipcode: "8924",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Tulbing",
          zipcode: "3001",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sieghartskirchen",
          zipcode: "3004",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Würmla",
          zipcode: "3042",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Klosterneuburg",
          zipcode: "3400",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Andrä-Wördern",
          zipcode: "3413",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Klosterneuburg",
          zipcode: "3420",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Klosterneuburg",
          zipcode: "3421",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Andrä-Wördern",
          zipcode: "3422",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "St. Andrä-Wördern",
          zipcode: "3423",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zeiselmauer-Wolfpassing",
          zipcode: "3424",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Tulln an der Donau",
          zipcode: "3425",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Muckendorf-Wipfing",
          zipcode: "3426",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Tulbing",
          zipcode: "3430",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Königstetten",
          zipcode: "3433",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Tulbing",
          zipcode: "3434",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zwentendorf an der Donau",
          zipcode: "3435",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sieghartskirchen",
          zipcode: "3441",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Langenrohr",
          zipcode: "3442",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sieghartskirchen",
          zipcode: "3443",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sieghartskirchen",
          zipcode: "3451",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Atzenbrugg",
          zipcode: "3452",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zwentendorf an der Donau",
          zipcode: "3454",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Königsbrunn am Wagram",
          zipcode: "3462",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Königsbrunn am Wagram",
          zipcode: "3465",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kirchberg am Wagram",
          zipcode: "3470",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Großriedenthal",
          zipcode: "3471",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kirchberg am Wagram",
          zipcode: "3474",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Fels am Wagram",
          zipcode: "3481",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Fels am Wagram",
          zipcode: "3482",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Grafenwörth",
          zipcode: "3483",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Grafenwörth",
          zipcode: "3484",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Großweikersdorf",
          zipcode: "3701",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Raabs an der Thaya",
          zipcode: "2094",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Raabs an der Thaya",
          zipcode: "2095",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ludweis-Aigen",
          zipcode: "3762",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Groß-Siegharts",
          zipcode: "3812",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Dietmanns",
          zipcode: "3813",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ludweis-Aigen",
          zipcode: "3814",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Raabs an der Thaya",
          zipcode: "3820",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Karlstein an der Thaya",
          zipcode: "3822",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Raabs an der Thaya",
          zipcode: "3823",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Raabs an der Thaya",
          zipcode: "3824",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Waidhofen an der Thaya",
          zipcode: "3830",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pfaffenschlag bei Waidhofen a.d.Thaya",
          zipcode: "3834",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Windigsteig",
          zipcode: "3841",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Thaya",
          zipcode: "3842",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Dobersberg",
          zipcode: "3843",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Dobersberg",
          zipcode: "3844",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kautzen",
          zipcode: "3851",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gastern",
          zipcode: "3852",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Windigsteig",
          zipcode: "3900",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Vitis",
          zipcode: "3902",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Vitis",
          zipcode: "3932",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Ebenfurth",
          zipcode: "2490",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zillingdorf",
          zipcode: "2491",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zillingdorf",
          zipcode: "2492",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Lichtenwörth",
          zipcode: "2493",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sollenau",
          zipcode: "2601",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sollenau",
          zipcode: "2602",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Felixdorf",
          zipcode: "2603",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Theresienfeld",
          zipcode: "2604",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Rohr im Gebirge",
          zipcode: "2663",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Lichtenwörth",
          zipcode: "2700",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Markt Piesting",
          zipcode: "2721",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Winzendorf-Muthmannsdorf",
          zipcode: "2722",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hohe Wand",
          zipcode: "2723",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hohe Wand",
          zipcode: "2724",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Matzendorf-Hölles",
          zipcode: "2751",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wöllersdorf-Steinabrückl",
          zipcode: "2752",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Markt Piesting",
          zipcode: "2753",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Waldegg",
          zipcode: "2754",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Waldegg",
          zipcode: "2755",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Waidmannsfeld",
          zipcode: "2761",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pernitz",
          zipcode: "2763",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gutenstein",
          zipcode: "2770",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Katzelsdorf",
          zipcode: "2801",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hochwolkersdorf",
          zipcode: "2802",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wiesmath",
          zipcode: "2803",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Wiesmath",
          zipcode: "2811",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hollenthon",
          zipcode: "2812",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Lichtenegg",
          zipcode: "2813",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Lanzenkirchen",
          zipcode: "2821",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Walpersbach",
          zipcode: "2822",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bad Erlach",
          zipcode: "2823",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bromberg",
          zipcode: "2833",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Lichtenegg",
          zipcode: "2842",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Lichtenegg",
          zipcode: "2851",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Hochneukirchen-Gschaidt",
          zipcode: "2852",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kirchschlag in der Buckligen Welt",
          zipcode: "2853",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kirchschlag in der Buckligen Welt",
          zipcode: "2860",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Krumbach",
          zipcode: "2871",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Lichtenwörth",
          zipcode: "7202",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Lanzenkirchen",
          zipcode: "7212",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sallingberg",
          zipcode: "3524",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Sallingberg",
          zipcode: "3525",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Waldhausen",
          zipcode: "3531",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zwettl-Niederösterreich",
          zipcode: "3532",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zwettl-Niederösterreich",
          zipcode: "3533",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pölla",
          zipcode: "3593",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Pölla",
          zipcode: "3594",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kottes-Purk",
          zipcode: "3622",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kottes-Purk",
          zipcode: "3623",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Kirchschlag",
          zipcode: "3631",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Bad Traunstein",
          zipcode: "3632",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schönbach",
          zipcode: "3633",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Martinsberg",
          zipcode: "3664",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Gutenbrunn",
          zipcode: "3665",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Göpfritz an der Wild",
          zipcode: "3800",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Echsenbach",
          zipcode: "3804",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Göpfritz an der Wild",
          zipcode: "3811",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schwarzenau",
          zipcode: "3900",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Echsenbach",
          zipcode: "3902",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Echsenbach",
          zipcode: "3903",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zwettl-Niederösterreich",
          zipcode: "3910",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Rappottenstein",
          zipcode: "3911",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Grafenschlag",
          zipcode: "3912",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Großgöttfritz",
          zipcode: "3913",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Waldhausen",
          zipcode: "3914",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Groß Gerungs",
          zipcode: "3920",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Langschlag",
          zipcode: "3921",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schweiggers",
          zipcode: "3922",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schweiggers",
          zipcode: "3923",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zwettl-Niederösterreich",
          zipcode: "3924",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Arbesbach",
          zipcode: "3925",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Schweiggers",
          zipcode: "3931",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Zwettl-Niederösterreich",
          zipcode: "3932",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Langschlag",
          zipcode: "3973",
          state: "Niederösterreich",
          state_code: "03",
        },
        {
          city: "Altmelon",
          zipcode: "4372",
          state: "Niederösterreich",
          state_code: "03",
        },
      ],
      state: "Niederösterreich",
      state_code: "03",
      cities: [
        "Wiener Neustadt",
        "Klosterneuburg",
        "Krems an der Donau",
        "Amstetten",
        "Mödling",
        "Traiskirchen",
        "Schwechat",
        "Stockerau",
        "Perchtoldsdorf",
        "Ternitz",
        "Korneuburg",
        "Neunkirchen",
        "Brunn am Gebirge",
        "Bad Vöslau",
        "Hollabrunn",
        "Mistelbach",
        "Gänserndorf",
        "Waidhofen an der Ybbs",
        "Groß-Enzersdorf",
        "Gerasdorf bei Wien",
        "Ebreichsdorf",
        "Zwettl",
        "Strasshof an der Nordbahn",
        "Purkersdorf",
        "Wiener Neudorf",
        "Sankt Valentin",
        "Guntramsdorf",
        "Maria Enzersdorf",
        "Deutsch-Wagram",
        "Neulengbach",
        "Langenzersdorf",
        "Bruck an der Leitha",
        "Sankt Andrä vor dem Hagenthale",
        "Herzogenburg",
        "Langenlois",
        "Pressbaum",
        "Sieghartskirchen",
        "Kottingbrunn",
        "Himberg",
        "Wolkersdorf im Weinviertel",
        "Pottendorf",
        "Vösendorf",
        "Hainburg an der Donau",
        "Wilhelmsburg",
        "Horn",
        "Laa an der Thaya",
        "Traismauer",
        "Gloggnitz",
        "Breitenfurth bei Wien",
        "Ybbs an der Donau",
        "Fischamend",
        "Haag",
        "Melk",
        "Poysdorf",
        "Waidhofen an der Thaya",
        "Schrems",
        "Zistersdorf",
        "Gmünd",
        "Sankt Peter in der Au Markt",
        "Böheimkirchen",
        "Leopoldsdorf",
        "Sollenau",
        "Gablitz",
        "Leobersdorf",
        "Leobendorf",
        "Pottschach",
        "Bisamberg",
        "Oberwaltersdorf",
        "Eichgraben",
        "Obergrafendorf",
        "Grossgerungs",
        "Felixdorf",
        "Retz",
        "Scheibbs",
        "Wieselburg",
        "Mannersdorf am Leithagebirge",
        "Hinterbrühl",
        "Heidenreichstein",
        "Lanzenkirchen",
        "Harmannsdorf",
        "Gaweinstal",
        "Sierndorf",
        "Sankt Veit an der Gölsen",
        "Pöchlarn",
        "Ebergassing",
        "Gumpoldskirchen",
        "Sonntagberg",
        "Aschbach Markt",
        "Loosdorf",
        "Hausleiten",
        "Gföhl",
        "Hainfeld",
        "Haidershofen",
        "Kirchberg am Wagram",
        "Mauerbach",
        "Trumau",
        "Pyhra",
        "Pfaffstätten",
        "Eggenburg",
        "Gars am Kamp",
        "Ardagger Markt",
        "Theresienfeld",
        "Gramatneusiedl",
        "Traisen",
        "Ziersdorf",
        "Ybbsitz",
        "Bad Fischau",
        "Seitenstetten Markt",
        "Behamberg",
        "Angern an der March",
        "Kaltenleutgeben",
        "Weidling",
        "Katzelsdorf",
        "Kirchberg an der Pielach",
        "Mank",
        "Ernstbrunn",
        "Großweikersdorf",
        "Kierling",
        "Ebenfurth",
        "Enzersdorf an der Fischa",
        "Grafenwörth",
        "Gaming",
        "Michelhausen",
        "Grafenegg",
        "Maria Anzbach",
        "Ennsdorf",
        "Markt Piesting",
        "Göllersdorf",
        "Münchendorf",
        "Sankt Leonhard am Forst",
        "Biedermannsdorf",
        "Tulbing",
        "Marchegg",
        "Oberndorf an der Melk",
        "Atzenbrugg",
        "Neuhofen an der Ybbs",
        "Altlengbach",
        "Pottenstein",
        "Kirchschlag in der Buckligen Welt",
        "Sankt Georgen am Ybbsfelde",
        "Trautmannsdorf an der Leitha",
        "Sulz im Wienerwald",
        "Leopoldsdorf im Marchfelde",
        "Lilienfeld",
        "Laxenburg",
        "Tullnerbach-Lawies",
        "Groß-Siegharts",
        "Lassee",
        "Gschwandt",
        "Hohenau an der March",
        "Lichtenwörth",
        "Blindenmarkt",
        "Pitten",
        "Weitra",
        "Mitterndorf an der Fischa",
        "Puchberg am Schneeberg",
        "Vitis",
        "Raabs an der Thaya",
        "Euratsfeld",
        "Alland",
        "Paudorf",
        "Sankt Pantaleon",
        "Hirtenberg",
        "Reichenau",
        "Kilb",
        "Kritzendorf",
        "Kirchberg am Wechsel",
        "Pernitz",
        "Pöggstall",
        "Zellerndorf",
        "Königstetten",
        "Wullersdorf",
        "Langenrohr",
        "Gießhübl",
        "Fels am Wagram",
        "Krumbach Markt",
        "Ruprechtshofen",
        "Ladendorf",
        "Steinakirchen am Forst",
        "Biberbach",
        "Dürnkrut",
        "Litschau",
        "Großebersdorf",
        "Ernsthofen",
        "Hagenbrunn",
        "Spillern",
        "Weistrach",
        "Asperhofen",
        "Großrußbach",
        "Kirchstetten",
        "Gedersdorf",
        "Schwadorf",
        "Wilfersdorf",
        "Sitzendorf an der Schmida",
        "Sankt Pölten",
        "Baden",
        "Tulln",
      ],
    },
    {
      country_code: "AT",
      zipcode: [
        {
          city: "Linz",
          zipcode: "4020",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Linz",
          zipcode: "4030",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Linz",
          zipcode: "4040",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Steyr",
          zipcode: "4400",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Steyr",
          zipcode: "4407",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Steyr",
          zipcode: "4451",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Wels",
          zipcode: "4600",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Wels",
          zipcode: "4623",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Aspach",
          zipcode: "4931",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Aspach",
          zipcode: "4932",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Aspach",
          zipcode: "4933",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Polling im Innkreis",
          zipcode: "4943",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Altheim",
          zipcode: "4950",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Polling im Innkreis",
          zipcode: "4951",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Altheim",
          zipcode: "4952",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Weng im Innkreis",
          zipcode: "4961",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Mining",
          zipcode: "4962",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Peter am Hart",
          zipcode: "4963",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Pantaleon",
          zipcode: "5120",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Radegund",
          zipcode: "5121",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hochburg-Ach",
          zipcode: "5122",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Franking",
          zipcode: "5131",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Geretsberg",
          zipcode: "5132",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gilgenberg am Weilhart",
          zipcode: "5133",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Schwand im Innkreis",
          zipcode: "5134",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Moosdorf",
          zipcode: "5141",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Eggelsberg",
          zipcode: "5142",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Feldkirchen bei Mattighofen",
          zipcode: "5143",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Georgen am Fillmannsbach",
          zipcode: "5144",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Burgkirchen",
          zipcode: "5145",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Palting",
          zipcode: "5163",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Perwang am Grabensee",
          zipcode: "5166",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Lengau",
          zipcode: "5211",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Lengau",
          zipcode: "5212",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Lochen am See",
          zipcode: "5221",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Munderfing",
          zipcode: "5222",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pfaffstätt",
          zipcode: "5223",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Auerbach",
          zipcode: "5224",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Jeging",
          zipcode: "5225",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Mattighofen",
          zipcode: "5230",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Schalchen",
          zipcode: "5231",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kirchberg bei Mattighofen",
          zipcode: "5232",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pischelsdorf am Engelbach",
          zipcode: "5233",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Maria Schmolln",
          zipcode: "5241",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Johann am Walde",
          zipcode: "5242",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Höhnhart",
          zipcode: "5251",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Aspach",
          zipcode: "5252",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Helpfau-Uttendorf",
          zipcode: "5261",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Mauerkirchen",
          zipcode: "5270",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Helpfau-Uttendorf",
          zipcode: "5271",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Treubach",
          zipcode: "5272",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Roßbach",
          zipcode: "5273",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Burgkirchen",
          zipcode: "5274",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Braunau am Inn",
          zipcode: "5280",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Neukirchen an der Enknach",
          zipcode: "5282",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pupping",
          zipcode: "4070",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Alkoven",
          zipcode: "4072",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Stroheim",
          zipcode: "4074",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Fraham",
          zipcode: "4075",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Marienkirchen an der Polsenz",
          zipcode: "4076",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hartkirchen",
          zipcode: "4081",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Aschach an der Donau",
          zipcode: "4082",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Haibach ob der Donau",
          zipcode: "4083",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Scharten",
          zipcode: "4612",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Stroheim",
          zipcode: "4730",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Prambachkirchen",
          zipcode: "4731",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Prambachkirchen",
          zipcode: "4732",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Liebenau",
          zipcode: "3925",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hirschbach im Mühlkreis",
          zipcode: "4192",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Waldburg",
          zipcode: "4193",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Unterweitersdorf",
          zipcode: "4210",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Neumarkt im Mühlkreis",
          zipcode: "4211",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Neumarkt im Mühlkreis",
          zipcode: "4212",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Wartberg ob der Aist",
          zipcode: "4224",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hagenberg im Mühlkreis",
          zipcode: "4230",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hagenberg im Mühlkreis",
          zipcode: "4232",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Rainbach im Mühlkreis",
          zipcode: "4240",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hirschbach im Mühlkreis",
          zipcode: "4242",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sandl",
          zipcode: "4251",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Liebenau",
          zipcode: "4252",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Rainbach im Mühlkreis",
          zipcode: "4261",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Leopoldschlag",
          zipcode: "4262",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Windhaag bei Freistadt",
          zipcode: "4263",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Grünbach",
          zipcode: "4264",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Lasberg",
          zipcode: "4271",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Leonhard bei Freistadt",
          zipcode: "4272",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Unterweißenbach",
          zipcode: "4273",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Schönau im Mühlkreis",
          zipcode: "4274",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Königswiesen",
          zipcode: "4280",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Königswiesen",
          zipcode: "4281",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pierbach",
          zipcode: "4282",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Bad Zell",
          zipcode: "4283",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Tragwein",
          zipcode: "4284",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Lasberg",
          zipcode: "4291",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gutau",
          zipcode: "4292",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gutau",
          zipcode: "4293",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Leonhard bei Freistadt",
          zipcode: "4294",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Tragwein",
          zipcode: "4311",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Scharnstein",
          zipcode: "4644",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Scharnstein",
          zipcode: "4645",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Vorchdorf",
          zipcode: "4653",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Vorchdorf",
          zipcode: "4655",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kirchham",
          zipcode: "4656",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Roitham am Traunfall",
          zipcode: "4661",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Laakirchen",
          zipcode: "4662",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Laakirchen",
          zipcode: "4663",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gschwandt",
          zipcode: "4664",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ohlsdorf",
          zipcode: "4694",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Altmünster",
          zipcode: "4801",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ebensee",
          zipcode: "4802",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pinsdorf",
          zipcode: "4810",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ohlsdorf",
          zipcode: "4812",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Altmünster",
          zipcode: "4813",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Altmünster",
          zipcode: "4814",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Laakirchen",
          zipcode: "4816",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Konrad",
          zipcode: "4817",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Bad Ischl",
          zipcode: "4820",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Bad Ischl",
          zipcode: "4821",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Bad Goisern am Hallstättersee",
          zipcode: "4822",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hallstatt",
          zipcode: "4823",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gosau",
          zipcode: "4824",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gosau",
          zipcode: "4825",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hallstatt",
          zipcode: "4830",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Obertraun",
          zipcode: "4831",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pinsdorf",
          zipcode: "4845",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Bad Ischl",
          zipcode: "5351",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "St. Wolfgang im Salzkammergut",
          zipcode: "5360",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Agatha",
          zipcode: "4083",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Agatha",
          zipcode: "4084",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Eschenau im Hausruckkreis",
          zipcode: "4085",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Wallern an der Trattnach",
          zipcode: "4611",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Meggenhofen",
          zipcode: "4625",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Wallern an der Trattnach",
          zipcode: "4631",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Schlüßlberg",
          zipcode: "4632",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kematen am Innbach",
          zipcode: "4633",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Meggenhofen",
          zipcode: "4672",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gaspoltshofen",
          zipcode: "4673",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gaspoltshofen",
          zipcode: "4674",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Weibern",
          zipcode: "4675",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Aistersheim",
          zipcode: "4676",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Haag am Hausruck",
          zipcode: "4680",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Rottenbach",
          zipcode: "4681",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Geboltskirchen",
          zipcode: "4682",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gaspoltshofen",
          zipcode: "4692",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Bad Schallerbach",
          zipcode: "4701",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Wallern an der Trattnach",
          zipcode: "4702",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Schlüßlberg",
          zipcode: "4707",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pollham",
          zipcode: "4710",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Michaelnbach",
          zipcode: "4712",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gallspach",
          zipcode: "4713",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Meggenhofen",
          zipcode: "4714",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Georgen bei Grieskirchen",
          zipcode: "4715",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hofkirchen an der Trattnach",
          zipcode: "4716",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kallham",
          zipcode: "4720",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Peuerbach",
          zipcode: "4722",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Natternbach",
          zipcode: "4723",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Neukirchen am Walde",
          zipcode: "4724",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Waizenkirchen",
          zipcode: "4730",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Waizenkirchen",
          zipcode: "4731",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Thomas",
          zipcode: "4732",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Heiligenberg",
          zipcode: "4733",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Wendling",
          zipcode: "4741",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pram",
          zipcode: "4742",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Natternbach",
          zipcode: "4762",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gaspoltshofen",
          zipcode: "4902",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Steinbach an der Steyr",
          zipcode: "4421",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kremsmünster",
          zipcode: "4532",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kremsmünster",
          zipcode: "4540",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Nußbach",
          zipcode: "4542",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kremsmünster",
          zipcode: "4550",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ried im Traunkreis",
          zipcode: "4551",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Wartberg an der Krems",
          zipcode: "4552",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Schlierbach",
          zipcode: "4553",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Oberschlierbach",
          zipcode: "4554",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kirchdorf an der Krems",
          zipcode: "4560",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Steinbach am Ziehberg",
          zipcode: "4562",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kirchdorf an der Krems",
          zipcode: "4563",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Klaus an der Pyhrnbahn",
          zipcode: "4564",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Inzersdorf im Kremstal",
          zipcode: "4565",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Klaus an der Pyhrnbahn",
          zipcode: "4571",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Pankraz",
          zipcode: "4572",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hinterstoder",
          zipcode: "4573",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Vorderstoder",
          zipcode: "4574",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Vorderstoder",
          zipcode: "4575",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Edlbach",
          zipcode: "4580",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Rosenau am Hengstpaß",
          zipcode: "4581",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Spital am Pyhrn",
          zipcode: "4582",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Rosenau am Hengstpaß",
          zipcode: "4591",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Grünburg",
          zipcode: "4592",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Grünburg",
          zipcode: "4593",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Grünburg",
          zipcode: "4594",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Steinbach an der Steyr",
          zipcode: "4596",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kremsmünster",
          zipcode: "4621",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pettenbach",
          zipcode: "4643",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Leonding",
          zipcode: "4020",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Traun",
          zipcode: "4050",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ansfelden",
          zipcode: "4052",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ansfelden",
          zipcode: "4053",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pucking",
          zipcode: "4055",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Leonding",
          zipcode: "4060",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pasching",
          zipcode: "4061",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kirchberg-Thening",
          zipcode: "4062",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hörsching",
          zipcode: "4063",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Oftering",
          zipcode: "4064",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Wilhering",
          zipcode: "4073",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Enns",
          zipcode: "4470",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Enns",
          zipcode: "4481",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hargelsberg",
          zipcode: "4483",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kronstorf",
          zipcode: "4484",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Florian",
          zipcode: "4490",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Niederneukirchen",
          zipcode: "4491",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hofkirchen im Traunkreis",
          zipcode: "4492",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Neuhofen an der Krems",
          zipcode: "4501",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Marien",
          zipcode: "4502",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Neuhofen an der Krems",
          zipcode: "4511",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Marien",
          zipcode: "4521",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kematen an der Krems",
          zipcode: "4531",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kematen an der Krems",
          zipcode: "4532",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Piberbach",
          zipcode: "4533",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kematen an der Krems",
          zipcode: "4550",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Eggendorf im Traunkreis",
          zipcode: "4616",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Eggendorf im Traunkreis",
          zipcode: "4622",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Georgen an der Gusen",
          zipcode: "4222",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Georgen an der Gusen",
          zipcode: "4223",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Katsdorf",
          zipcode: "4224",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Luftenberg an der Donau",
          zipcode: "4225",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pabneukirchen",
          zipcode: "4281",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Thomas am Blasenstein",
          zipcode: "4282",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Allerheiligen im Mühlkreis",
          zipcode: "4284",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Mauthausen",
          zipcode: "4310",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Schwertberg",
          zipcode: "4311",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ried in der Riedmark",
          zipcode: "4312",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Perg",
          zipcode: "4320",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Windhaag bei Perg",
          zipcode: "4322",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Münzbach",
          zipcode: "4323",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Rechberg",
          zipcode: "4324",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Naarn im Machlande",
          zipcode: "4331",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Naarn im Machlande",
          zipcode: "4332",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Baumgartenberg",
          zipcode: "4341",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Baumgartenberg",
          zipcode: "4342",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Mitterkirchen im Machland",
          zipcode: "4343",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Saxen",
          zipcode: "4351",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Klam",
          zipcode: "4352",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Grein",
          zipcode: "4360",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pabneukirchen",
          zipcode: "4362",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pabneukirchen",
          zipcode: "4363",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Thomas am Blasenstein",
          zipcode: "4364",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Dimbach",
          zipcode: "4371",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Georgen am Walde",
          zipcode: "4372",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Nikola an der Donau",
          zipcode: "4381",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Waldhausen im Strudengau",
          zipcode: "4382",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Waldhausen im Strudengau",
          zipcode: "4391",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Waldhausen im Strudengau",
          zipcode: "4392",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Luftenberg an der Donau",
          zipcode: "4481",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Geiersberg",
          zipcode: "4680",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Peterskirchen",
          zipcode: "4743",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Taiskirchen im Innkreis",
          zipcode: "4751",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Lambrechten",
          zipcode: "4752",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Taiskirchen im Innkreis",
          zipcode: "4753",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Andrichsfurt",
          zipcode: "4754",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Lambrechten",
          zipcode: "4772",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Eberschwang",
          zipcode: "4906",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ried im Innkreis",
          zipcode: "4910",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Tumeltsham",
          zipcode: "4911",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Neuhofen im Innkreis",
          zipcode: "4912",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Schildorn",
          zipcode: "4920",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hohenzell",
          zipcode: "4921",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Geiersberg",
          zipcode: "4922",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Lohnsburg am Kobernaußerwald",
          zipcode: "4923",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Waldzell",
          zipcode: "4924",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pramet",
          zipcode: "4925",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Marienkirchen am Hausruck",
          zipcode: "4926",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Mettmach",
          zipcode: "4931",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kirchheim im Innkreis",
          zipcode: "4932",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Mehrnbach",
          zipcode: "4941",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gurten",
          zipcode: "4942",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Geinberg",
          zipcode: "4943",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Geinberg",
          zipcode: "4951",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Mühlheim am Inn",
          zipcode: "4961",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Eitzing",
          zipcode: "4970",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Aurolzmünster",
          zipcode: "4971",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Utzenaich",
          zipcode: "4972",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Mörschwang",
          zipcode: "4973",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ort im Innkreis",
          zipcode: "4974",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Reichersberg",
          zipcode: "4980",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Reichersberg",
          zipcode: "4981",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Mörschwang",
          zipcode: "4982",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Georgen bei Obernberg am Inn",
          zipcode: "4983",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Weilbach",
          zipcode: "4984",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hofkirchen im Mühlkreis",
          zipcode: "4085",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Martin im Mühlkreis",
          zipcode: "4113",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kleinzell im Mühlkreis",
          zipcode: "4114",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kirchberg ob der Donau",
          zipcode: "4115",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Ulrich im Mühlkreis",
          zipcode: "4116",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Neufelden",
          zipcode: "4120",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Altenfelden",
          zipcode: "4121",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Arnreit",
          zipcode: "4122",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Niederkappel",
          zipcode: "4131",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hörbich",
          zipcode: "4132",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Putzleinsdorf",
          zipcode: "4133",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Putzleinsdorf",
          zipcode: "4134",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Putzleinsdorf",
          zipcode: "4141",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pfarrkirchen im Mühlkreis",
          zipcode: "4142",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Neustift im Mühlkreis",
          zipcode: "4143",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pfarrkirchen im Mühlkreis",
          zipcode: "4144",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Rohrbach-Berg",
          zipcode: "4150",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Oepping",
          zipcode: "4151",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Hörbich",
          zipcode: "4152",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Peilstein im Mühlviertel",
          zipcode: "4153",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kollerschlag",
          zipcode: "4154",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Nebelberg",
          zipcode: "4155",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Aigen-Schlägl",
          zipcode: "4160",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ulrichsberg",
          zipcode: "4161",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Julbach",
          zipcode: "4162",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Klaffer am Hochficht",
          zipcode: "4163",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Schwarzenberg am Böhmerwald",
          zipcode: "4164",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Haslach an der Mühl",
          zipcode: "4170",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Peter am Wimberg",
          zipcode: "4171",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Johann am Wimberg",
          zipcode: "4172",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Veit im Mühlkreis",
          zipcode: "4173",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Niederwaldkirchen",
          zipcode: "4174",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Veit im Mühlkreis",
          zipcode: "4175",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ahorn",
          zipcode: "4182",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ahorn",
          zipcode: "4183",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Peter am Wimberg",
          zipcode: "4184",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Waldkirchen am Wesen",
          zipcode: "4085",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Engelhartszell",
          zipcode: "4090",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Vichtenstein",
          zipcode: "4091",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Esternberg",
          zipcode: "4092",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Altschwendt",
          zipcode: "4721",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kopfing im Innkreis",
          zipcode: "4723",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Waldkirchen am Wesen",
          zipcode: "4724",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Aegidi",
          zipcode: "4725",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Dorf an der Pram",
          zipcode: "4751",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Riedau",
          zipcode: "4752",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Zell an der Pram",
          zipcode: "4755",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Raab",
          zipcode: "4760",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Enzenkirchen",
          zipcode: "4761",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Willibald",
          zipcode: "4762",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Andorf",
          zipcode: "4770",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sigharting",
          zipcode: "4771",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Andorf",
          zipcode: "4772",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Eggerding",
          zipcode: "4773",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Marienkirchen bei Schärding",
          zipcode: "4774",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Taufkirchen an der Pram",
          zipcode: "4775",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Diersbach",
          zipcode: "4776",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Mayrhof",
          zipcode: "4777",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Florian am Inn",
          zipcode: "4780",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Florian am Inn",
          zipcode: "4782",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Wernstein am Inn",
          zipcode: "4783",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Schardenberg",
          zipcode: "4784",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Freinberg",
          zipcode: "4785",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Brunnenthal",
          zipcode: "4786",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Rainbach im Innkreis",
          zipcode: "4791",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Münzkirchen",
          zipcode: "4792",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Roman",
          zipcode: "4793",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kopfing im Innkreis",
          zipcode: "4794",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Suben",
          zipcode: "4975",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Eggerding",
          zipcode: "4980",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gaflenz",
          zipcode: "3334",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gaflenz",
          zipcode: "3335",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Garsten",
          zipcode: "4400",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Dietach",
          zipcode: "4407",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Aschach an der Steyr",
          zipcode: "4421",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Ulrich bei Steyr",
          zipcode: "4442",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Großraming",
          zipcode: "4443",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Garsten",
          zipcode: "4451",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ternberg",
          zipcode: "4452",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ternberg",
          zipcode: "4453",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Losenstein",
          zipcode: "4460",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Laussa",
          zipcode: "4461",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Reichraming",
          zipcode: "4462",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Großraming",
          zipcode: "4463",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Weyer",
          zipcode: "4464",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Dietach",
          zipcode: "4484",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Wolfern",
          zipcode: "4493",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Schiedlberg",
          zipcode: "4521",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sierning",
          zipcode: "4522",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Garsten",
          zipcode: "4523",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Rohr im Kremstal",
          zipcode: "4531",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Rohr im Kremstal",
          zipcode: "4532",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pfarrkirchen bei Bad Hall",
          zipcode: "4540",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Adlwang",
          zipcode: "4541",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Rohr im Kremstal",
          zipcode: "4550",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pfarrkirchen bei Bad Hall",
          zipcode: "4552",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Aschach an der Steyr",
          zipcode: "4594",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Waldneukirchen",
          zipcode: "4595",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Weyer",
          zipcode: "8934",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Lichtenberg",
          zipcode: "4040",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Puchenau",
          zipcode: "4048",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ottensheim",
          zipcode: "4100",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Feldkirchen an der Donau",
          zipcode: "4101",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Goldwörth",
          zipcode: "4102",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Walding",
          zipcode: "4111",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sankt Gotthard im Mühlkreis",
          zipcode: "4112",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Feldkirchen an der Donau",
          zipcode: "4113",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Herzogsdorf",
          zipcode: "4175",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Zwettl an der Rodl",
          zipcode: "4180",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Oberneukirchen",
          zipcode: "4181",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Oberneukirchen",
          zipcode: "4182",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Vorderweißenbach",
          zipcode: "4183",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Vorderweißenbach",
          zipcode: "4184",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Bad Leonfelden",
          zipcode: "4190",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Vorderweißenbach",
          zipcode: "4191",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Schenkenfelden",
          zipcode: "4192",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Reichenthal",
          zipcode: "4193",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Eidenberg",
          zipcode: "4201",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Kirchschlag bei Linz",
          zipcode: "4202",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Altenberg bei Linz",
          zipcode: "4203",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Reichenau im Mühlkreis",
          zipcode: "4204",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Engerwitzdorf",
          zipcode: "4209",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Alberndorf in der Riedmark",
          zipcode: "4210",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Alberndorf in der Riedmark",
          zipcode: "4211",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Steyregg",
          zipcode: "4221",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Engerwitzdorf",
          zipcode: "4222",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Reichenthal",
          zipcode: "4240",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pitzenberg",
          zipcode: "4690",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Schlatt",
          zipcode: "4691",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Niederthalheim",
          zipcode: "4692",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Desselbrunn",
          zipcode: "4693",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Attnang-Puchheim",
          zipcode: "4800",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Regau",
          zipcode: "4812",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Aurach am Hongar",
          zipcode: "4814",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Vöcklabruck",
          zipcode: "4840",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ungenach",
          zipcode: "4841",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Zell am Pettenfirst",
          zipcode: "4842",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ampflwang im Hausruckwald",
          zipcode: "4843",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Regau",
          zipcode: "4844",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Regau",
          zipcode: "4845",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Redlham",
          zipcode: "4846",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Puchkirchen am Trattberg",
          zipcode: "4849",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Timelkam",
          zipcode: "4850",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gampern",
          zipcode: "4851",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Weyregg am Attersee",
          zipcode: "4852",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Steinbach am Attersee",
          zipcode: "4853",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Steinbach am Attersee",
          zipcode: "4854",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Timelkam",
          zipcode: "4860",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Schörfling am Attersee",
          zipcode: "4861",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Seewalchen am Attersee",
          zipcode: "4863",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Attersee am Attersee",
          zipcode: "4864",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Nußdorf am Attersee",
          zipcode: "4865",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Innerschwand am Mondsee",
          zipcode: "4866",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Vöcklamarkt",
          zipcode: "4870",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Frankenburg am Hausruck",
          zipcode: "4871",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Neukirchen an der Vöckla",
          zipcode: "4872",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Frankenburg am Hausruck",
          zipcode: "4873",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Berg im Attergau",
          zipcode: "4880",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Straß im Attergau",
          zipcode: "4881",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Oberwang",
          zipcode: "4882",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Frankenmarkt",
          zipcode: "4890",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pöndorf",
          zipcode: "4891",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Fornach",
          zipcode: "4892",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Tiefgraben",
          zipcode: "4893",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Oberhofen am Irrsee",
          zipcode: "4894",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Ottnang am Hausruck",
          zipcode: "4901",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Niederthalheim",
          zipcode: "4902",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Manning",
          zipcode: "4903",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Atzbach",
          zipcode: "4904",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Oberhofen am Irrsee",
          zipcode: "5202",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Weißenkirchen im Attergau",
          zipcode: "5204",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pöndorf",
          zipcode: "5212",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Tiefgraben",
          zipcode: "5310",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Innerschwand am Mondsee",
          zipcode: "5311",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Schleißheim",
          zipcode: "4600",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Buchkirchen",
          zipcode: "4611",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Buchkirchen",
          zipcode: "4612",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Buchkirchen",
          zipcode: "4613",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Marchtrenk",
          zipcode: "4614",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Holzhausen",
          zipcode: "4615",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Weißkirchen an der Traun",
          zipcode: "4616",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sipbachzell",
          zipcode: "4621",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Gunskirchen",
          zipcode: "4623",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pennewang",
          zipcode: "4624",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Offenhausen",
          zipcode: "4625",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Krenglbach",
          zipcode: "4631",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pichl bei Wels",
          zipcode: "4632",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pichl bei Wels",
          zipcode: "4633",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Steinhaus",
          zipcode: "4641",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Sattledt",
          zipcode: "4642",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Eberstalzell",
          zipcode: "4643",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Edt bei Lambach",
          zipcode: "4650",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Stadl-Paura",
          zipcode: "4651",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Steinerkirchen an der Traun",
          zipcode: "4652",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Eberstalzell",
          zipcode: "4653",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Bad Wimsbach-Neydharting",
          zipcode: "4654",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Steinerkirchen an der Traun",
          zipcode: "4655",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Neukirchen bei Lambach",
          zipcode: "4671",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Bachmanning",
          zipcode: "4672",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Stadl-Paura",
          zipcode: "4690",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pichl bei Wels",
          zipcode: "4701",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Pichl bei Wels",
          zipcode: "4702",
          state: "Oberösterreich",
          state_code: "04",
        },
        {
          city: "Offenhausen",
          zipcode: "4714",
          state: "Oberösterreich",
          state_code: "04",
        },
      ],
      state: "Oberösterreich",
      state_code: "04",
      cities: [
        "Linz",
        "Wels",
        "Steyr",
        "Leonding",
        "Traun",
        "Braunau am Inn",
        "Ansfelden",
        "Bad Ischl",
        "Marchtrenk",
        "Gmunden",
        "Vöcklabruck",
        "Enns",
        "Ried im Innkreis",
        "Laakirchen",
        "Altmünster",
        "Sierning",
        "Attnang-Puchheim",
        "Engerwitzdorf",
        "Perg",
        "Freistadt",
        "Ebensee",
        "Pasching",
        "Vorchdorf",
        "Bad Goisern",
        "Garsten",
        "Asten",
        "Kremsmünster",
        "Mattighofen",
        "Gallneukirchen",
        "Neuhofen an der Krems",
        "Markt Sankt Florian",
        "Hörsching",
        "Gunskirchen",
        "Alkoven",
        "Wilhering",
        "Micheldorf in Oberösterreich",
        "Timelkam",
        "Thalheim bei Wels",
        "Schwertberg",
        "Feldkirchen an der Donau",
        "Pregarten",
        "Pettenbach",
        "Schärding",
        "Ohlsdorf",
        "Andorf",
        "Gramastetten",
        "Stadl-Paura",
        "Lenzing",
        "Bad Hall",
        "Grieskirchen",
        "Mauthausen",
        "Vöcklamarkt",
        "Steyregg",
        "Altheim",
        "Scharnstein",
        "Sankt Marien",
        "Lengau",
        "Ottensheim",
        "Altenberg bei Linz",
        "Kirchdorf",
        "Peuerbach",
        "Puchenau",
        "Sankt Georgen im Attergau",
        "Schwanenstadt",
        "Weyer Markt",
        "Wartberg ob der Aist",
        "Ried in der Riedmark",
        "Bad Schallerbach",
        "Sankt Georgen an der Gusen",
        "Eferding",
        "Hartkirchen",
        "Walding",
        "Alberndorf in der Riedmark",
        "Luftenberg",
        "Buchkirchen",
        "Tiefgraben",
        "Pucking",
        "Schalchen",
        "Pinsdorf",
        "Gründberg",
        "Sankt Martin im Mühlkreis",
        "Mondsee",
        "Waizenkirchen",
        "Naarn im Machland",
        "Molln",
        "Frankenmarkt",
        "Gaspoltshofen",
        "Lambach",
        "Weisskirchen an der Traun",
        "Kronstorf",
        "Ternberg",
        "Eberschwang",
        "Ostermiething",
        "Hochburg-Ach",
        "Dietach",
        "Oberneukirchen",
        "Neumarkt im Mühlkreis",
        "Krenglbach",
        "Sankt Florian am Inn",
        "Königswiesen",
        "Schlüsslberg",
        "Katsdorf",
        "Sankt Pantaleon",
        "Tragwein",
        "Wallern an der Trattnach",
        "Aurolzmünster",
        "Wartberg an der Krems",
        "Sankt Ulrich bei Steyr",
        "Munderfing",
        "Rainbach im Mühlkreis",
        "Gampern",
        "Grein",
        "Taufkirchen an der Pram",
        "Prambachkirchen",
        "Sankt Oswald bei Freistadt",
        "Waldhausen im Strudengau",
        "Pichl bei Wels",
        "Schlierbach",
        "Esternberg",
        "Ulrichsberg",
        "Kematen an der Krems",
        "Gallspach",
        "Sankt Wolfgang im Salzkammergut",
        "Lasberg",
        "Ried im Traunkreis",
        "Hagenberg im Mühlkreis",
        "Gutau",
        "Grossraming",
        "Sattledt",
        "Burgkirchen",
        "Vorderweissenbach",
        "Lichtenhag",
        "Eberstallzell",
        "Münzkirchen",
        "Mauerkirchen",
        "Neukirchen an der Vöckla",
        "Rohrbach",
        "Haslach an der Mühl",
        "Langenstein",
        "Bad Wimsbach-Neydharting",
        "Berg bei Rohrbach",
        "Herzogsdorf",
        "Kallham",
        "Sankt Lorenz",
        "Sankt Peter",
        "Neuhofen im Innkreis",
        "Schardenberg",
        "Taiskirchen im Innkreis",
        "Fraham",
        "Windischgarsten",
        "Pöndorf",
        "Steinerkirchen an der Traun",
        "Eggelsberg",
        "Mehrnbach",
        "Mettmach",
        "Sankt Marienkirchen an der Polsenz",
        "Natternbach",
        "Hellmonsödt",
        "Nussbach",
        "Sarleinsbach",
        "Raab",
        "Aschach an der Steyr",
        "Scharten",
        "Hohenzell",
        "Pfarrkirchen bei Bad Hall",
        "Waldneukirchen",
        "Spital am Pyhrn",
        "Lohnsburg",
        "Altenfelden",
        "Neukirchen an der Enknach",
        "Aschach an der Donau",
        "Edt bei Lambach",
        "Unterweissenbach",
        "Steinhaus",
        "Waldzell",
        "Haag am Hausruck",
      ],
    },
    {
      country_code: "AT",
      zipcode: [
        {
          city: "Salzburg",
          zipcode: "5020",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Salzburg",
          zipcode: "5023",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Salzburg",
          zipcode: "5026",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Salzburg",
          zipcode: "5061",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Salzburg",
          zipcode: "5071",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Salzburg",
          zipcode: "5081",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Salzburg",
          zipcode: "5082",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Hallein",
          zipcode: "5083",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Adnet",
          zipcode: "5323",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Hallein",
          zipcode: "5400",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Oberalm",
          zipcode: "5411",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Puch bei Hallein",
          zipcode: "5412",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Adnet",
          zipcode: "5421",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Hallein",
          zipcode: "5422",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Sankt Koloman",
          zipcode: "5423",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Bad Vigaun",
          zipcode: "5424",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Krispl",
          zipcode: "5425",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Kuchl",
          zipcode: "5431",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Golling an der Salzach",
          zipcode: "5440",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Abtenau",
          zipcode: "5441",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Rußbach am Paß Gschütt",
          zipcode: "5442",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Annaberg-Lungötz",
          zipcode: "5523",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Annaberg-Lungötz",
          zipcode: "5524",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Sankt Gilgen",
          zipcode: "4854",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Sankt Gilgen",
          zipcode: "4866",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Koppl",
          zipcode: "5023",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Elsbethen",
          zipcode: "5061",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Wals-Siezenheim",
          zipcode: "5071",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Anif",
          zipcode: "5081",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Grödig",
          zipcode: "5082",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Grödig",
          zipcode: "5083",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Großgmain",
          zipcode: "5084",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Bergheim",
          zipcode: "5101",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Anthering",
          zipcode: "5102",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Lamprechtshausen",
          zipcode: "5110",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Bürmoos",
          zipcode: "5111",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Lamprechtshausen",
          zipcode: "5112",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Sankt Georgen bei Salzburg",
          zipcode: "5113",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Göming",
          zipcode: "5114",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Nußdorf am Haunsberg",
          zipcode: "5151",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Dorfbeuern",
          zipcode: "5152",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Elixhausen",
          zipcode: "5161",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Nußdorf am Haunsberg",
          zipcode: "5162",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Mattsee",
          zipcode: "5163",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Seeham",
          zipcode: "5164",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Nußdorf am Haunsberg",
          zipcode: "5165",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Seekirchen am Wallersee",
          zipcode: "5201",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Neumarkt am Wallersee",
          zipcode: "5202",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Köstendorf",
          zipcode: "5203",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Straßwalchen",
          zipcode: "5204",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Schleedorf",
          zipcode: "5205",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Hallwang",
          zipcode: "5300",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Eugendorf",
          zipcode: "5301",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Henndorf am Wallersee",
          zipcode: "5302",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Thalgau",
          zipcode: "5303",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Sankt Gilgen",
          zipcode: "5310",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Koppl",
          zipcode: "5321",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Hof bei Salzburg",
          zipcode: "5322",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Ebenau",
          zipcode: "5323",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Faistenau",
          zipcode: "5324",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Plainfeld",
          zipcode: "5325",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Fuschl am See",
          zipcode: "5330",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Sankt Gilgen",
          zipcode: "5340",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Sankt Gilgen",
          zipcode: "5342",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Strobl",
          zipcode: "5350",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Strobl",
          zipcode: "5351",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Sankt Gilgen",
          zipcode: "5360",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Werfen",
          zipcode: "5450",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Werfen",
          zipcode: "5451",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Werfen",
          zipcode: "5452",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Werfenweng",
          zipcode: "5453",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Bischofshofen",
          zipcode: "5500",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Mühlbach am Hochkönig",
          zipcode: "5505",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Hüttau",
          zipcode: "5511",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Hüttau",
          zipcode: "5521",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Sankt Martin am Tennengebirge",
          zipcode: "5522",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Eben im Pongau",
          zipcode: "5531",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Filzmoos",
          zipcode: "5532",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Altenmarkt im Pongau",
          zipcode: "5541",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Flachau",
          zipcode: "5542",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Radstadt",
          zipcode: "5550",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Forstau",
          zipcode: "5552",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Untertauern",
          zipcode: "5561",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Untertauern",
          zipcode: "5562",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Sankt Johann im Pongau",
          zipcode: "5600",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Wagrain",
          zipcode: "5602",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Kleinarl",
          zipcode: "5603",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Großarl",
          zipcode: "5611",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Hüttschlag",
          zipcode: "5612",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Schwarzach im Pongau",
          zipcode: "5620",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Goldegg",
          zipcode: "5621",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Goldegg",
          zipcode: "5622",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Bad Hofgastein",
          zipcode: "5630",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Dorfgastein",
          zipcode: "5632",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Bad Gastein",
          zipcode: "5640",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Bad Gastein",
          zipcode: "5645",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Goldegg",
          zipcode: "5651",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Radstadt",
          zipcode: "8974",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Tweng",
          zipcode: "5562",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Tweng",
          zipcode: "5563",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Mauterndorf",
          zipcode: "5570",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Mariapfarr",
          zipcode: "5571",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Sankt Andrä im Lungau",
          zipcode: "5572",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Weißpriach",
          zipcode: "5573",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Göriach",
          zipcode: "5574",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Lessach",
          zipcode: "5575",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Tamsweg",
          zipcode: "5580",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Sankt Margarethen im Lungau",
          zipcode: "5581",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Sankt Michael im Lungau",
          zipcode: "5582",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Muhr",
          zipcode: "5583",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Zederhaus",
          zipcode: "5584",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Unternberg",
          zipcode: "5585",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Ramingstein",
          zipcode: "5591",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Thomatal",
          zipcode: "5592",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Lofer",
          zipcode: "5090",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Unken",
          zipcode: "5091",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Sankt Martin bei Lofer",
          zipcode: "5092",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Weißbach bei Lofer",
          zipcode: "5093",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Lend",
          zipcode: "5651",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Dienten am Hochkönig",
          zipcode: "5652",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Taxenbach",
          zipcode: "5660",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Rauris",
          zipcode: "5661",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Bruck an der Großglocknerstraße",
          zipcode: "5662",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Bruck an der Großglocknerstraße",
          zipcode: "5671",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Fusch an der Großglocknerstraße",
          zipcode: "5672",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Zell am See",
          zipcode: "5700",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Kaprun",
          zipcode: "5710",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Piesendorf",
          zipcode: "5721",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Niedernsill",
          zipcode: "5722",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Uttendorf",
          zipcode: "5723",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Stuhlfelden",
          zipcode: "5724",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Mittersill",
          zipcode: "5730",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Hollersbach im Pinzgau",
          zipcode: "5731",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Bramberg am Wildkogel",
          zipcode: "5732",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Bramberg am Wildkogel",
          zipcode: "5733",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Neukirchen am Großvenediger",
          zipcode: "5741",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Wald im Pinzgau",
          zipcode: "5742",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Krimml",
          zipcode: "5743",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Maishofen",
          zipcode: "5751",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Viehhofen",
          zipcode: "5752",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Saalbach-Hinterglemm",
          zipcode: "5753",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Saalbach-Hinterglemm",
          zipcode: "5754",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Saalfelden am Steinernen Meer",
          zipcode: "5760",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Maria Alm am Steinernen Meer",
          zipcode: "5761",
          state: "Salzburg",
          state_code: "05",
        },
        {
          city: "Leogang",
          zipcode: "5771",
          state: "Salzburg",
          state_code: "05",
        },
      ],
      state: "Salzburg",
      state_code: "05",
      cities: [
        "Salzburg",
        "Hallein",
        "Saalfelden am Steinernen Meer",
        "Sankt Johann im Pongau",
        "Bischofshofen",
        "Zell am See",
        "Strasswalchen",
        "Kuchl",
        "Grödig",
        "Eugendorf",
        "Bad Hofgastein",
        "Neumarkt am Wallersee",
        "Thalgau",
        "Abtenau",
        "Oberndorf bei Salzburg",
        "Tamsweg",
        "Elsbethen",
        "Bergheim",
        "Mittersill",
        "Henndorf am Wallersee",
        "Bürmoos",
        "Radstadt",
        "Obertrum am See",
        "Bruck an der Großglocknerstraße",
        "Puch bei Hallein",
        "Oberalm",
        "Golling an der Salzach",
        "Altenmarkt im Pongau",
        "Anif",
        "Hallwang",
        "Lamprechtshausen",
        "Badgastein",
        "Bramberg am Wildkogel",
        "Sankt Gilgen",
        "Grossarl",
        "Piesendorf",
        "Sankt Veit im Pongau",
        "Anthering",
        "Strobl",
        "Maishofen",
        "Hof bei Salzburg",
        "Adnet",
        "Sankt Michael im Lungau",
        "Schwarzach im Pongau",
        "Koppl",
        "Mattsee",
        "Leogang",
        "Kaprun",
        "Faistenau",
        "Wagrain",
        "Rauris",
        "Werfen",
        "Elixhausen",
        "Sankt Georgen bei Salzburg",
        "Uttendorf",
        "Flachau",
        "Taxenbach",
        "Niedernsill",
        "Köstendorf",
        "Grossgmain",
        "Goldegg",
        "Neukirchen am Großvenediger",
        "Eben im Pongau",
        "Nußdorf am Haunsberg",
        "Mariapfarr",
        "Viehausen",
      ],
    },
    {
      country_code: "AT",
      zipcode: [
        {
          city: "Graz",
          zipcode: "8010",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8020",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8036",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8041",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8042",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8043",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8044",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8045",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8046",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8047",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8051",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8052",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8053",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8054",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8055",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8073",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Graz",
          zipcode: "8074",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Josef (Weststeiermark)",
          zipcode: "8143",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Martin im Sulmtal",
          zipcode: "8443",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Groß Sankt Florian",
          zipcode: "8444",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Eibiswald",
          zipcode: "8455",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Lannach",
          zipcode: "8502",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Josef (Weststeiermark)",
          zipcode: "8503",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Preding",
          zipcode: "8504",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Stainz",
          zipcode: "8510",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Stefan ob Stainz",
          zipcode: "8511",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Wettmannstätten",
          zipcode: "8521",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Groß Sankt Florian",
          zipcode: "8522",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Frauental an der Laßnitz",
          zipcode: "8523",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Deutschlandsberg",
          zipcode: "8524",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Frauental an der Laßnitz",
          zipcode: "8530",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Wies",
          zipcode: "8541",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Peter im Sulmtal",
          zipcode: "8542",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Martin im Sulmtal",
          zipcode: "8543",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pölfing-Brunn",
          zipcode: "8544",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Wies",
          zipcode: "8551",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Eibiswald",
          zipcode: "8552",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Wies",
          zipcode: "8553",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Eibiswald",
          zipcode: "8554",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Stefan ob Stainz",
          zipcode: "8562",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Stefan ob Stainz",
          zipcode: "8563",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Eggersdorf bei Graz",
          zipcode: "8010",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Weinitzen",
          zipcode: "8044",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Stattegg",
          zipcode: "8045",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Stattegg",
          zipcode: "8046",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kainbach bei Graz",
          zipcode: "8047",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Thal",
          zipcode: "8051",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Hitzendorf",
          zipcode: "8052",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Seiersberg-Pirka",
          zipcode: "8054",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Seiersberg-Pirka",
          zipcode: "8055",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Radegund bei Graz",
          zipcode: "8061",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kumberg",
          zipcode: "8062",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Eggersdorf bei Graz",
          zipcode: "8063",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Hausmannstätten",
          zipcode: "8071",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Fernitz-Mellach",
          zipcode: "8072",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Seiersberg-Pirka",
          zipcode: "8073",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Raaba-Grambach",
          zipcode: "8074",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Hart bei Graz",
          zipcode: "8075",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Vasoldsberg",
          zipcode: "8076",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gössendorf",
          zipcode: "8077",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Vasoldsberg",
          zipcode: "8081",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Marein bei Graz",
          zipcode: "8082",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gratkorn",
          zipcode: "8101",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Semriach",
          zipcode: "8102",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gratwein-Straßengel",
          zipcode: "8103",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gratwein-Straßengel",
          zipcode: "8111",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gratwein-Straßengel",
          zipcode: "8112",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Bartholomä",
          zipcode: "8113",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gratwein-Straßengel",
          zipcode: "8114",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Peggau",
          zipcode: "8120",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Frohnleiten",
          zipcode: "8121",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Deutschfeistritz",
          zipcode: "8122",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Übelbach",
          zipcode: "8124",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Frohnleiten",
          zipcode: "8130",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Frohnleiten",
          zipcode: "8131",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Premstätten",
          zipcode: "8141",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Wundschuh",
          zipcode: "8142",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Dobl-Zwaring",
          zipcode: "8143",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Haselsdorf-Tobelbad",
          zipcode: "8144",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Hitzendorf",
          zipcode: "8151",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Hitzendorf",
          zipcode: "8152",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gratwein-Straßengel",
          zipcode: "8153",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kumberg",
          zipcode: "8160",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Semriach",
          zipcode: "8163",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kumberg",
          zipcode: "8181",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Nestelbach bei Graz",
          zipcode: "8200",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Eggersdorf bei Graz",
          zipcode: "8301",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Nestelbach bei Graz",
          zipcode: "8302",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Marein bei Graz",
          zipcode: "8322",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Nestelbach bei Graz",
          zipcode: "8323",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Marein bei Graz",
          zipcode: "8324",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kalsdorf bei Graz",
          zipcode: "8401",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Werndorf",
          zipcode: "8402",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Fernitz-Mellach",
          zipcode: "8410",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Dobl-Zwaring",
          zipcode: "8411",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Lieboch",
          zipcode: "8501",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Dobl-Zwaring",
          zipcode: "8503",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Dobl-Zwaring",
          zipcode: "8504",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Hitzendorf",
          zipcode: "8561",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Heiligenkreuz am Waasen",
          zipcode: "8072",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Empersdorf",
          zipcode: "8081",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Empersdorf",
          zipcode: "8302",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Lebring-Sankt Margarethen",
          zipcode: "8403",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Georgen an der Stiefing",
          zipcode: "8410",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Hengsberg",
          zipcode: "8411",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Heiligenkreuz am Waasen",
          zipcode: "8412",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Ragnitz",
          zipcode: "8413",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Schwarzautal",
          zipcode: "8421",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Veit in der Südsteiermark",
          zipcode: "8422",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Veit in der Südsteiermark",
          zipcode: "8423",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gabersdorf",
          zipcode: "8424",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Tillmitsch",
          zipcode: "8430",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gralla",
          zipcode: "8431",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Leibnitz",
          zipcode: "8435",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kitzeck im Sausal",
          zipcode: "8441",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kitzeck im Sausal",
          zipcode: "8442",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Oberhaag",
          zipcode: "8443",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Andrä-Höch",
          zipcode: "8444",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Großklein",
          zipcode: "8451",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Großklein",
          zipcode: "8452",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Johann im Saggautal",
          zipcode: "8453",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Johann im Saggautal",
          zipcode: "8454",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Oberhaag",
          zipcode: "8455",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Ehrenhausen an der Weinstraße",
          zipcode: "8461",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gamlitz",
          zipcode: "8462",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gamlitz",
          zipcode: "8463",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Straß in Steiermark",
          zipcode: "8471",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Straß in Steiermark",
          zipcode: "8472",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Veit in der Südsteiermark",
          zipcode: "8481",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Hengsberg",
          zipcode: "8504",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Nikolai im Sausal",
          zipcode: "8505",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Nikolai im Sausal",
          zipcode: "8521",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Andrä-Höch",
          zipcode: "8522",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Oberhaag",
          zipcode: "8544",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Leoben",
          zipcode: "8700",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Niklasdorf",
          zipcode: "8712",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Stefan ob Leoben",
          zipcode: "8713",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kraubath an der Mur",
          zipcode: "8714",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Michael in Obersteiermark",
          zipcode: "8770",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Traboch",
          zipcode: "8772",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kammern im Liesingtal",
          zipcode: "8773",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Mautern in Steiermark",
          zipcode: "8774",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kalwang",
          zipcode: "8775",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Wald am Schoberpaß",
          zipcode: "8781",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Eisenerz",
          zipcode: "8790",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Leoben",
          zipcode: "8792",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Trofaiach",
          zipcode: "8793",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Vordernberg",
          zipcode: "8794",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Radmer",
          zipcode: "8795",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gaishorn am See",
          zipcode: "8781",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gaishorn am See",
          zipcode: "8782",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gaishorn am See",
          zipcode: "8783",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Trieben",
          zipcode: "8784",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Rottenmann",
          zipcode: "8786",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Selzthal",
          zipcode: "8900",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Lassing",
          zipcode: "8903",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Ardning",
          zipcode: "8904",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Ardning",
          zipcode: "8911",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Admont",
          zipcode: "8912",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Admont",
          zipcode: "8913",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Landl",
          zipcode: "8920",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Landl",
          zipcode: "8921",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Landl",
          zipcode: "8922",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Landl",
          zipcode: "8923",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Wildalpen",
          zipcode: "8924",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Landl",
          zipcode: "8931",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Gallen",
          zipcode: "8932",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Gallen",
          zipcode: "8933",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Altenmarkt bei Sankt Gallen",
          zipcode: "8934",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Liezen",
          zipcode: "8940",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Wörschach",
          zipcode: "8942",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Aigen im Ennstal",
          zipcode: "8943",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Stainach-Pürgg",
          zipcode: "8950",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Stainach-Pürgg",
          zipcode: "8951",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Irdning-Donnersbachtal",
          zipcode: "8952",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Irdning-Donnersbachtal",
          zipcode: "8953",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Mitterberg-Sankt Martin",
          zipcode: "8954",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Öblarn",
          zipcode: "8960",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sölk",
          zipcode: "8961",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Michaelerberg-Pruggern",
          zipcode: "8962",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Aich",
          zipcode: "8965",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Aich",
          zipcode: "8966",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Haus",
          zipcode: "8967",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Haus",
          zipcode: "8970",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Schladming",
          zipcode: "8971",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Schladming",
          zipcode: "8972",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Schladming",
          zipcode: "8973",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Schladming",
          zipcode: "8974",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Bad Mitterndorf",
          zipcode: "8982",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Bad Mitterndorf",
          zipcode: "8983",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Bad Mitterndorf",
          zipcode: "8984",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Bad Aussee",
          zipcode: "8990",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Altaussee",
          zipcode: "8992",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Grundlsee",
          zipcode: "8993",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Scheifling",
          zipcode: "8811",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Neumarkt in der Steiermark",
          zipcode: "8812",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Lambrecht",
          zipcode: "8813",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Neumarkt in der Steiermark",
          zipcode: "8820",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Mühlen",
          zipcode: "8822",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Oberwölz",
          zipcode: "8831",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Oberwölz",
          zipcode: "8832",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Teufenbach-Katsch",
          zipcode: "8833",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Teufenbach-Katsch",
          zipcode: "8841",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "St. Peter am Kammersberg",
          zipcode: "8842",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "St. Peter am Kammersberg",
          zipcode: "8843",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Schöder",
          zipcode: "8844",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Murau",
          zipcode: "8850",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Murau",
          zipcode: "8852",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Ranten",
          zipcode: "8853",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Krakau",
          zipcode: "8854",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Georgen am Kreischberg",
          zipcode: "8861",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Stadl-Predlitz",
          zipcode: "8862",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Stadl-Predlitz",
          zipcode: "8863",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Stadl-Predlitz",
          zipcode: "8864",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Neumarkt in der Steiermark",
          zipcode: "9323",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Neumarkt in der Steiermark",
          zipcode: "9360",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Geistthal-Södingberg",
          zipcode: "8113",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Stallhofen",
          zipcode: "8151",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Stallhofen",
          zipcode: "8152",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Geistthal-Södingberg",
          zipcode: "8153",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Söding-Sankt Johann",
          zipcode: "8561",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Mooskirchen",
          zipcode: "8562",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Ligist",
          zipcode: "8563",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Krottendorf-Gaisfeld",
          zipcode: "8564",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Söding-Sankt Johann",
          zipcode: "8565",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Rosental an der Kainach",
          zipcode: "8570",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Bärnbach",
          zipcode: "8572",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kainach bei Voitsberg",
          zipcode: "8573",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Martin am Wöllmißberg",
          zipcode: "8580",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Rosental an der Kainach",
          zipcode: "8582",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Edelschrott",
          zipcode: "8583",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Hirschegg-Pack",
          zipcode: "8584",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Maria Lankowitz",
          zipcode: "8591",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Maria Lankowitz",
          zipcode: "8592",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Bärnbach",
          zipcode: "8593",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Hirschegg-Pack",
          zipcode: "9451",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gutenberg-Stenzengreith",
          zipcode: "8061",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Ludersdorf-Wilfersdorf",
          zipcode: "8063",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Passail",
          zipcode: "8102",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Fladnitz an der Teichalm",
          zipcode: "8130",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Mitterdorf an der Raab",
          zipcode: "8160",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gutenberg-Stenzengreith",
          zipcode: "8162",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Fladnitz an der Teichalm",
          zipcode: "8163",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Kathrein am Offenegg",
          zipcode: "8171",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Anger",
          zipcode: "8172",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Ruprecht an der Raab",
          zipcode: "8181",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Puch bei Weiz",
          zipcode: "8182",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Anger",
          zipcode: "8183",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Anger",
          zipcode: "8184",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Birkfeld",
          zipcode: "8190",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Birkfeld",
          zipcode: "8191",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Strallegg",
          zipcode: "8192",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sinabelkirchen",
          zipcode: "8200",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Ilztal",
          zipcode: "8211",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pischelsdorf am Kulm",
          zipcode: "8212",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pischelsdorf am Kulm",
          zipcode: "8222",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Floing",
          zipcode: "8223",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sinabelkirchen",
          zipcode: "8261",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sinabelkirchen",
          zipcode: "8262",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gersdorf an der Feistritz",
          zipcode: "8265",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gleisdorf",
          zipcode: "8302",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Markt Hartmannsdorf",
          zipcode: "8311",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Markt Hartmannsdorf",
          zipcode: "8312",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gleisdorf",
          zipcode: "8321",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "St. Margarethen an der Raab",
          zipcode: "8322",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "St. Margarethen an der Raab",
          zipcode: "8323",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gasen",
          zipcode: "8616",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Fischbach",
          zipcode: "8654",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "St. Kathrein am Hauenstein",
          zipcode: "8671",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "St. Kathrein am Hauenstein",
          zipcode: "8672",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Fischbach",
          zipcode: "8673",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Rettenegg",
          zipcode: "8674",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Lobmingtal",
          zipcode: "8592",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Marein-Feistritz",
          zipcode: "8715",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Margarethen bei Knittelfeld",
          zipcode: "8720",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kobenz",
          zipcode: "8723",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Spielberg",
          zipcode: "8724",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gaal",
          zipcode: "8731",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Seckau",
          zipcode: "8732",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Marein-Feistritz",
          zipcode: "8733",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Lobmingtal",
          zipcode: "8734",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Weißkirchen in Steiermark",
          zipcode: "8740",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Weißkirchen in Steiermark",
          zipcode: "8741",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Obdach",
          zipcode: "8742",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Judenburg",
          zipcode: "8750",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pöls-Oberkurzheim",
          zipcode: "8753",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pöls-Oberkurzheim",
          zipcode: "8754",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Peter ob Judenburg",
          zipcode: "8755",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Georgen ob Judenburg",
          zipcode: "8756",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pöls-Oberkurzheim",
          zipcode: "8761",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pölstal",
          zipcode: "8762",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pölstal",
          zipcode: "8763",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pusterwald",
          zipcode: "8764",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pölstal",
          zipcode: "8765",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Hohentauern",
          zipcode: "8785",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Georgen ob Judenburg",
          zipcode: "8800",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Obdach",
          zipcode: "9463",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Neuberg an der Mürz",
          zipcode: "2654",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Spital am Semmering",
          zipcode: "2680",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Mariazell",
          zipcode: "3294",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pernegg an der Mur",
          zipcode: "8131",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pernegg an der Mur",
          zipcode: "8132",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Bruck an der Mur",
          zipcode: "8600",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Lorenzen im Mürztal",
          zipcode: "8605",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Tragöß-Sankt Katharein",
          zipcode: "8611",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Tragöß-Sankt Katharein",
          zipcode: "8612",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pernegg an der Mur",
          zipcode: "8614",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Thörl",
          zipcode: "8621",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Thörl",
          zipcode: "8622",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Aflenz",
          zipcode: "8623",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Thörl",
          zipcode: "8624",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Lorenzen im Mürztal",
          zipcode: "8625",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Mariazell",
          zipcode: "8630",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Mariazell",
          zipcode: "8632",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Mariazell",
          zipcode: "8634",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Mariazell",
          zipcode: "8635",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Turnau",
          zipcode: "8636",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kapfenberg",
          zipcode: "8641",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Lorenzen im Mürztal",
          zipcode: "8642",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kindberg",
          zipcode: "8643",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kindberg",
          zipcode: "8644",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kindberg",
          zipcode: "8650",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Lorenzen im Mürztal",
          zipcode: "8652",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kindberg",
          zipcode: "8653",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Stanz im Mürztal",
          zipcode: "8654",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Barbara im Mürztal",
          zipcode: "8661",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Krieglach",
          zipcode: "8662",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Barbara im Mürztal",
          zipcode: "8663",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Barbara im Mürztal",
          zipcode: "8664",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Langenwang",
          zipcode: "8665",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Krieglach",
          zipcode: "8670",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Krieglach",
          zipcode: "8671",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Langenwang",
          zipcode: "8680",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Mürzzuschlag",
          zipcode: "8682",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Spital am Semmering",
          zipcode: "8684",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Spital am Semmering",
          zipcode: "8685",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Neuberg an der Mürz",
          zipcode: "8691",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Neuberg an der Mürz",
          zipcode: "8692",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Neuberg an der Mürz",
          zipcode: "8693",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Neuberg an der Mürz",
          zipcode: "8694",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Schäffern",
          zipcode: "2852",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Schäffern",
          zipcode: "2870",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Schäffern",
          zipcode: "2871",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pinggau",
          zipcode: "2872",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pinggau",
          zipcode: "7421",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pinggau",
          zipcode: "7423",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Feistritztal",
          zipcode: "8212",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Feistritztal",
          zipcode: "8221",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Feistritztal",
          zipcode: "8222",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Stubenberg",
          zipcode: "8223",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Hartl",
          zipcode: "8224",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pöllau",
          zipcode: "8225",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Lafnitz",
          zipcode: "8230",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Grafendorf bei Hartberg",
          zipcode: "8232",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Grafendorf bei Hartberg",
          zipcode: "8233",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Rohrbach an der Lafnitz",
          zipcode: "8234",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Friedberg",
          zipcode: "8240",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Dechantskirchen",
          zipcode: "8241",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Lorenzen am Wechsel",
          zipcode: "8242",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Pinggau",
          zipcode: "8243",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Schäffern",
          zipcode: "8244",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Vorau",
          zipcode: "8250",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Vorau",
          zipcode: "8251",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Vorau",
          zipcode: "8252",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Vorau",
          zipcode: "8253",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Wenigzell",
          zipcode: "8254",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Jakob im Walde",
          zipcode: "8255",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Ilz",
          zipcode: "8261",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Ilz",
          zipcode: "8262",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Großwilfersdorf",
          zipcode: "8263",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Großwilfersdorf",
          zipcode: "8264",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Großsteinbach",
          zipcode: "8265",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Hartl",
          zipcode: "8271",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Ebersdorf",
          zipcode: "8272",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Hartl",
          zipcode: "8273",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Buch-St. Magdalena",
          zipcode: "8274",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Fürstenfeld",
          zipcode: "8280",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Loipersdorf bei Fürstenfeld",
          zipcode: "8282",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Bad Blumau",
          zipcode: "8283",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Burgau",
          zipcode: "8291",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Neudau",
          zipcode: "8292",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Rohr bei Hartberg",
          zipcode: "8293",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Johann in der Haide",
          zipcode: "8294",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Johann in der Haide",
          zipcode: "8295",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Ilz",
          zipcode: "8311",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Ottendorf an der Rittschein",
          zipcode: "8312",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Fürstenfeld",
          zipcode: "8362",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Jakob im Walde",
          zipcode: "8673",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Jakob im Walde",
          zipcode: "8674",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kirchbach-Zerlach",
          zipcode: "8081",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kirchbach-Zerlach",
          zipcode: "8082",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Stefan im Rosental",
          zipcode: "8083",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Jagerberg",
          zipcode: "8091",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Mettersdorf am Saßbach",
          zipcode: "8092",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Mettersdorf am Saßbach",
          zipcode: "8093",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Eichkögl",
          zipcode: "8311",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Riegersburg",
          zipcode: "8312",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Riegersburg",
          zipcode: "8313",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Eichkögl",
          zipcode: "8322",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kirchberg an der Raab",
          zipcode: "8323",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kirchberg an der Raab",
          zipcode: "8324",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Edelsbach bei Feldbach",
          zipcode: "8330",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Eichkögl",
          zipcode: "8332",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Riegersburg",
          zipcode: "8333",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Riegersburg",
          zipcode: "8334",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Paldau",
          zipcode: "8341",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gnas",
          zipcode: "8342",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Gnas",
          zipcode: "8343",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Bad Gleichenberg",
          zipcode: "8344",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Straden",
          zipcode: "8345",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Unterlamm",
          zipcode: "8350",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Unterlamm",
          zipcode: "8352",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Kapfenstein",
          zipcode: "8353",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Sankt Anna am Aigen",
          zipcode: "8354",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Tieschen",
          zipcode: "8355",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Unterlamm",
          zipcode: "8361",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Fehring",
          zipcode: "8362",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Jagerberg",
          zipcode: "8421",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Mettersdorf am Saßbach",
          zipcode: "8422",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Murfeld",
          zipcode: "8423",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Murfeld",
          zipcode: "8471",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Murfeld",
          zipcode: "8473",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Mureck",
          zipcode: "8480",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Deutsch Goritz",
          zipcode: "8483",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Halbenrain",
          zipcode: "8484",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Bad Radkersburg",
          zipcode: "8490",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Halbenrain",
          zipcode: "8492",
          state: "Steiermark",
          state_code: "06",
        },
        {
          city: "Klöch",
          zipcode: "8493",
          state: "Steiermark",
          state_code: "06",
        },
      ],
      state: "Steiermark",
      state_code: "06",
      cities: [
        "Graz",
        "Leoben",
        "Kapfenberg",
        "Bruck an der Mur",
        "Feldbach",
        "Knittelfeld",
        "Leibnitz",
        "Deutschlandsberg",
        "Weiz",
        "Trofaiach",
        "Gleisdorf",
        "Judenburg",
        "Köflach",
        "Voitsberg",
        "Mürzzuschlag",
        "Stainz",
        "Fürstenfeld",
        "Liezen",
        "Kindberg",
        "Gratkorn",
        "Fohnsdorf",
        "Seiersberg",
        "Fehring",
        "Zeltweg",
        "Hafendorf",
        "Hitzendorf",
        "Kalsdorf bei Graz",
        "Schladming",
        "Frohnleiten",
        "Hartberg",
        "Feldkirchen bei Graz",
        "Eggersdorf bei Graz",
        "Eibiswald",
        "Unterpremstätten",
        "Pöllau",
        "Gnas",
        "Wagna",
        "Spielberg bei Knittelfeld",
        "Wildon",
        "Bad Gleichenberg",
        "Krieglach",
        "Sankt Ruprecht an der Raab",
        "Rottenmann",
        "Lieboch",
        "Admont",
        "Birkfeld",
        "Riegersburg",
        "Weißkirchen in Steiermark",
        "Straß in Steiermark",
        "Bad Aussee",
        "Vorau",
        "Kirchberg an der Raab",
        "Wies",
        "Deutschfeistritz",
        "Passail",
        "Sinabelkirchen",
        "Groß Sankt Florian",
        "Anger",
        "Sankt Johann ob Hohenburg",
        "Sankt Margarethen an der Raab",
        "Eisenerz",
        "Gössendorf",
        "Sankt Stefan im Rosental",
        "Langenwang",
        "Kumberg",
        "Mariazell",
        "Obdach",
        "Sankt Marein bei Graz",
        "Gratwein",
        "Murau",
        "Sankt Lorenzen im Mürztal",
        "Straden",
        "Sankt Stefan ob Stainz",
        "Mureck",
        "Lannach",
        "Trieben",
        "Semriach",
        "Hausmannstätten",
        "Pirka",
        "Ligist",
        "Gamlitz",
        "Oberaich",
        "Bad Radkersburg",
        "Pinggau",
        "Paldau",
        "Mühldorf bei Feldbach",
        "Stallhofen",
        "Grafendorf bei Hartberg",
        "Sankt Michael in Obersteiermark",
        "Sankt Martin im Sulmtal",
        "Gröbming",
        "Eisbach",
        "Oberwölz Stadt",
        "Sankt Peter am Ottersbach",
        "Kaindorf",
        "Stattegg",
        "Freidorf an der Lassnitz",
        "Maria Lankowitz",
        "Stainach",
        "Lassnitzhöhe",
        "Gleinstätten",
        "Heiligenkreuz am Waasen",
        "Sankt Margarethen bei Knittelfeld",
        "Irdning",
        "Aigen im Ennstal",
        "Landl",
        "Sankt Marein im Mürztal",
        "Nestelbach bei Graz",
        "Rohrbach an der Lafnitz",
        "Friedberg",
        "Pirching am Traubenberg",
        "Pischelsdorf in Steiermark",
        "Kaindorf an der Sulm",
        "Niklasdorf",
        "Neuberg an der Mürz",
        "Krottendorf bei Ligist",
        "Raaba",
        "Feistritz",
        "Sankt Peter-Freienstein",
        "Mitterdorf im Mürztal",
        "Pöls",
        "Werndorf",
        "Pernegg an der Mur",
        "Sankt Anna am Aigen",
        "Thörl",
        "Großklein",
        "Sankt Nikolai im Sausal",
        "Hofstätten an der Raab",
        "Mooskirchen",
        "Peggau",
        "Mortantsch",
        "Stubenberg",
        "Sankt Radegund bei Graz",
      ],
    },
    {
      country_code: "AT",
      zipcode: [
        {
          city: "Innsbruck",
          zipcode: "6020",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Innsbruck",
          zipcode: "6080",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Stams",
          zipcode: "6182",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Silz",
          zipcode: "6183",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Mieming",
          zipcode: "6414",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Obsteig",
          zipcode: "6416",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Rietz",
          zipcode: "6421",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Stams",
          zipcode: "6422",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Mötz",
          zipcode: "6423",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Silz",
          zipcode: "6424",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Haiming",
          zipcode: "6425",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Roppen",
          zipcode: "6426",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Haiming",
          zipcode: "6430",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Sautens",
          zipcode: "6432",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Oetz",
          zipcode: "6433",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Umhausen",
          zipcode: "6441",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Längenfeld",
          zipcode: "6444",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Sölden",
          zipcode: "6450",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Sölden",
          zipcode: "6452",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Sölden",
          zipcode: "6456",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Sölden",
          zipcode: "6458",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Imst",
          zipcode: "6460",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Karres",
          zipcode: "6462",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Karrösten",
          zipcode: "6463",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Tarrenz",
          zipcode: "6464",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Nassereith",
          zipcode: "6465",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Arzl im Pitztal",
          zipcode: "6471",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Jerzens",
          zipcode: "6473",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Jerzens",
          zipcode: "6474",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "St. Leonhard im Pitztal",
          zipcode: "6481",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Imsterberg",
          zipcode: "6492",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Mils bei Imst",
          zipcode: "6493",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Mutters",
          zipcode: "6020",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Hall in Tirol",
          zipcode: "6060",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Rum",
          zipcode: "6063",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Thaur",
          zipcode: "6065",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Absam",
          zipcode: "6067",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Mils",
          zipcode: "6068",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Gnadenwald",
          zipcode: "6069",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Ampass",
          zipcode: "6070",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Aldrans",
          zipcode: "6071",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Lans",
          zipcode: "6072",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Aldrans",
          zipcode: "6073",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Rinn",
          zipcode: "6074",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Tulfes",
          zipcode: "6075",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Patsch",
          zipcode: "6082",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Ellbögen",
          zipcode: "6083",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Götzens",
          zipcode: "6091",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Birgitz",
          zipcode: "6092",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Axams",
          zipcode: "6094",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Grinzens",
          zipcode: "6095",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Leutasch",
          zipcode: "6100",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Reith bei Seefeld",
          zipcode: "6103",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Leutasch",
          zipcode: "6105",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Scharnitz",
          zipcode: "6108",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Volders",
          zipcode: "6111",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Wattens",
          zipcode: "6112",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Wattenberg",
          zipcode: "6113",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kolsass",
          zipcode: "6114",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kolsassberg",
          zipcode: "6115",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Baumkirchen",
          zipcode: "6121",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Fritzens",
          zipcode: "6122",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Schönberg im Stubaital",
          zipcode: "6141",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Mieders",
          zipcode: "6142",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Mühlbachl",
          zipcode: "6143",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Navis",
          zipcode: "6145",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Steinach am Brenner",
          zipcode: "6150",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Trins",
          zipcode: "6152",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Schmirn",
          zipcode: "6154",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Gries am Brenner",
          zipcode: "6156",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Obernberg am Brenner",
          zipcode: "6157",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Natters",
          zipcode: "6161",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Mutters",
          zipcode: "6162",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Telfes im Stubai",
          zipcode: "6165",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Fulpmes",
          zipcode: "6166",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Fulpmes",
          zipcode: "6167",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Pettnau",
          zipcode: "6170",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Oberperfuss",
          zipcode: "6173",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kematen in Tirol",
          zipcode: "6175",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Völs",
          zipcode: "6176",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Unterperfuss",
          zipcode: "6178",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Ranggen",
          zipcode: "6179",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Sellrain",
          zipcode: "6181",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Gries im Sellrain",
          zipcode: "6182",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "St. Sigmund im Sellrain",
          zipcode: "6184",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Inzing",
          zipcode: "6401",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Hatting",
          zipcode: "6402",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Flaurling",
          zipcode: "6403",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Polling in Tirol",
          zipcode: "6404",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Pfaffenhofen",
          zipcode: "6405",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Oberhofen im Inntal",
          zipcode: "6406",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Pettnau",
          zipcode: "6408",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Telfs",
          zipcode: "6410",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Wildermieming",
          zipcode: "6413",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Itter",
          zipcode: "6305",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Hopfgarten im Brixental",
          zipcode: "6314",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kössen",
          zipcode: "6345",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Going am Wilden Kaiser",
          zipcode: "6353",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Hopfgarten im Brixental",
          zipcode: "6361",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Westendorf",
          zipcode: "6363",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Brixen im Thale",
          zipcode: "6364",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Reith bei Kitzbühel",
          zipcode: "6365",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Reith bei Kitzbühel",
          zipcode: "6370",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Aurach bei Kitzbühel",
          zipcode: "6371",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Oberndorf in Tirol",
          zipcode: "6372",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Jochberg",
          zipcode: "6373",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "St. Johann in Tirol",
          zipcode: "6380",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kirchdorf in Tirol",
          zipcode: "6382",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kirchdorf in Tirol",
          zipcode: "6383",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Waidring",
          zipcode: "6384",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Schwendt",
          zipcode: "6385",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Fieberbrunn",
          zipcode: "6391",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "St. Jakob in Haus",
          zipcode: "6392",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "St. Ulrich am Pillersee",
          zipcode: "6393",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Hochfilzen",
          zipcode: "6395",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Brixlegg",
          zipcode: "6230",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Münster",
          zipcode: "6232",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kramsach",
          zipcode: "6233",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Brandenberg",
          zipcode: "6234",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Reith im Alpbachtal",
          zipcode: "6235",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Alpbach",
          zipcode: "6236",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Rattenberg",
          zipcode: "6240",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Radfeld",
          zipcode: "6241",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kundl",
          zipcode: "6250",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Breitenbach am Inn",
          zipcode: "6252",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Reith im Alpbachtal",
          zipcode: "6260",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Wörgl",
          zipcode: "6300",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Söll",
          zipcode: "6306",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Wildschönau",
          zipcode: "6311",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Wildschönau",
          zipcode: "6313",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Wildschönau",
          zipcode: "6314",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Angerberg",
          zipcode: "6320",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Angath",
          zipcode: "6321",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kirchbichl",
          zipcode: "6322",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Bad Häring",
          zipcode: "6323",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Mariastein",
          zipcode: "6324",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kufstein",
          zipcode: "6330",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Schwoich",
          zipcode: "6334",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Thiersee",
          zipcode: "6335",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Langkampfen",
          zipcode: "6336",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Ebbs",
          zipcode: "6341",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Niederndorf",
          zipcode: "6342",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Erl",
          zipcode: "6343",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Walchsee",
          zipcode: "6344",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Niederndorferberg",
          zipcode: "6346",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Rettenschöss",
          zipcode: "6347",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Söll",
          zipcode: "6351",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Ellmau",
          zipcode: "6352",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Fließ",
          zipcode: "6473",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Schönwies",
          zipcode: "6491",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Stanz bei Landeck",
          zipcode: "6500",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Zams",
          zipcode: "6511",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Fließ",
          zipcode: "6521",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Prutz",
          zipcode: "6522",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kaunertal",
          zipcode: "6524",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Faggen",
          zipcode: "6525",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kauns",
          zipcode: "6526",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kaunerberg",
          zipcode: "6527",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Fendels",
          zipcode: "6528",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Ried im Oberinntal",
          zipcode: "6531",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Ladis",
          zipcode: "6532",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Fiss",
          zipcode: "6533",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Serfaus",
          zipcode: "6534",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Serfaus",
          zipcode: "6541",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Pfunds",
          zipcode: "6542",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Nauders",
          zipcode: "6543",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Spiss",
          zipcode: "6544",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Pians",
          zipcode: "6551",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Tobadill",
          zipcode: "6552",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kappl",
          zipcode: "6553",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kappl",
          zipcode: "6555",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Ischgl",
          zipcode: "6561",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Ischgl",
          zipcode: "6562",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Galtür",
          zipcode: "6563",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Strengen",
          zipcode: "6571",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Flirsch",
          zipcode: "6572",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Pettneu am Arlberg",
          zipcode: "6574",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "St. Anton am Arlberg",
          zipcode: "6580",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Pians",
          zipcode: "6591",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Nikolsdorf",
          zipcode: "9782",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Lienz",
          zipcode: "9900",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Oberlienz",
          zipcode: "9903",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Thurn",
          zipcode: "9904",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Gaimberg",
          zipcode: "9905",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Lavant",
          zipcode: "9906",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Tristach",
          zipcode: "9907",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Amlach",
          zipcode: "9908",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Leisach",
          zipcode: "9909",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Assling",
          zipcode: "9911",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Anras",
          zipcode: "9912",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Abfaltersbach",
          zipcode: "9913",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Strassen",
          zipcode: "9918",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Heinfels",
          zipcode: "9919",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Sillian",
          zipcode: "9920",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Außervillgraten",
          zipcode: "9931",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Innervillgraten",
          zipcode: "9932",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kartitsch",
          zipcode: "9941",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Obertilliach",
          zipcode: "9942",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Untertilliach",
          zipcode: "9943",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Ainet",
          zipcode: "9951",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "St. Johann im Walde",
          zipcode: "9952",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Schlaiten",
          zipcode: "9954",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Hopfgarten in Defereggen",
          zipcode: "9961",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "St. Veit in Defereggen",
          zipcode: "9962",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "St. Jakob in Defereggen",
          zipcode: "9963",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Matrei in Osttirol",
          zipcode: "9971",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Virgen",
          zipcode: "9972",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Prägraten am Großvenediger",
          zipcode: "9974",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kals am Großglockner",
          zipcode: "9981",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Nußdorf-Debant",
          zipcode: "9990",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Dölsach",
          zipcode: "9991",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Iselsberg-Stronach",
          zipcode: "9992",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Pflach",
          zipcode: "6600",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Höfen",
          zipcode: "6604",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Wängle",
          zipcode: "6610",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Heiterwang",
          zipcode: "6611",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Bichlbach",
          zipcode: "6621",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Berwang",
          zipcode: "6622",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Namlos",
          zipcode: "6623",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Lermoos",
          zipcode: "6631",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Ehrwald",
          zipcode: "6632",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Biberwier",
          zipcode: "6633",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Stanzach",
          zipcode: "6642",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Elmen",
          zipcode: "6644",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Vorderhornbach",
          zipcode: "6645",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Hinterhornbach",
          zipcode: "6646",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Pfafflar",
          zipcode: "6647",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Gramais",
          zipcode: "6650",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Häselgehr",
          zipcode: "6651",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Elbigenalp",
          zipcode: "6652",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Bach",
          zipcode: "6653",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Holzgau",
          zipcode: "6654",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Steeg",
          zipcode: "6655",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Forchach",
          zipcode: "6670",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Ehenbichl",
          zipcode: "6671",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Nesselwängle",
          zipcode: "6672",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Grän",
          zipcode: "6673",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Tannheim",
          zipcode: "6675",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Schattwald",
          zipcode: "6677",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Vils",
          zipcode: "6682",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Jungholz",
          zipcode: "6691",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Steeg",
          zipcode: "6767",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Weer",
          zipcode: "6116",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Terfens",
          zipcode: "6123",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Schwaz",
          zipcode: "6130",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Weerberg",
          zipcode: "6133",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Vomp",
          zipcode: "6134",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Stans",
          zipcode: "6135",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Pill",
          zipcode: "6136",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Stans",
          zipcode: "6200",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Wiesing",
          zipcode: "6210",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Eben am Achensee",
          zipcode: "6212",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Eben am Achensee",
          zipcode: "6213",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Achenkirch",
          zipcode: "6215",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Buch in Tirol",
          zipcode: "6220",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Gallzein",
          zipcode: "6222",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Bruck am Ziller",
          zipcode: "6260",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Schlitters",
          zipcode: "6261",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Schlitters",
          zipcode: "6262",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Fügen",
          zipcode: "6263",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Fügenberg",
          zipcode: "6264",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Hart im Zillertal",
          zipcode: "6265",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Uderns",
          zipcode: "6271",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Kaltenbach",
          zipcode: "6272",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Ried im Zillertal",
          zipcode: "6273",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Aschau im Zillertal",
          zipcode: "6274",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Stumm",
          zipcode: "6275",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Stummerberg",
          zipcode: "6276",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Zellberg",
          zipcode: "6277",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Hainzenberg",
          zipcode: "6278",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Gerlosberg",
          zipcode: "6280",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Gerlos",
          zipcode: "6281",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Hippach",
          zipcode: "6283",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Ramsau im Zillertal",
          zipcode: "6284",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Finkenberg",
          zipcode: "6290",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Finkenberg",
          zipcode: "6292",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Tux",
          zipcode: "6293",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Tux",
          zipcode: "6294",
          state: "Tirol",
          state_code: "07",
        },
        {
          city: "Finkenberg",
          zipcode: "6295",
          state: "Tirol",
          state_code: "07",
        },
      ],
      state: "Tirol",
      state_code: "07",
      cities: [
        "Innsbruck",
        "Kufstein",
        "Telfs",
        "Hall in Tirol",
        "Wörgl",
        "Schwaz",
        "Lienz",
        "Imst",
        "Sankt Johann in Tirol",
        "Rum",
        "Kitzbühel",
        "Zirl",
        "Wattens",
        "Landeck",
        "Jenbach",
        "Absam",
        "Völs",
        "Reutte",
        "Axams",
        "Kirchbichl",
        "Hopfgarten im Brixental",
        "Ebbs",
        "Kirchberg in Tirol",
        "Vomp",
        "Kramsach",
        "Neustift im Stubaital",
        "Matrei in Osttirol",
        "Haiming",
        "Kundl",
        "Volders",
        "Fulpmes",
        "Kössen",
        "Fieberbrunn",
        "Fügen",
        "Götzens",
        "Thaur",
        "Kirchdorf in Tirol",
        "Mayrhofen",
        "Inzing",
        "Mieming",
        "Westendorf",
        "Söll",
        "Steinach am Brenner",
        "Breitenbach am Inn",
        "Seefeld in Tirol",
        "Zams",
        "Umhausen",
        "Eben am Achensee",
        "Sölden",
        "Arzl im Pitztal",
        "Oberperfuss",
        "Fliess",
        "Brixlegg",
        "Kematen in Tirol",
        "Ellmau",
        "Tarrenz",
        "Niederndorf",
        "Reith im Alpbachtal",
        "Aldrans",
        "Brixen im Thale",
        "Kappl",
        "Pfunds",
        "Ehrwald",
        "Alpbach",
        "Silz",
        "Schwoich",
        "Radfeld",
        "Sankt Anton am Arlberg",
        "Oetz",
        "Leutasch",
        "Dölsach",
        "Rietz",
        "Sistrans",
        "Mutters",
        "Virgen",
        "Oberndorf in Tirol",
        "Achenkirch",
        "Terfens",
      ],
    },
    {
      country_code: "AT",
      zipcode: [
        {
          city: "Stallehr",
          zipcode: "6700",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Bürs",
          zipcode: "6706",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Bürserberg",
          zipcode: "6707",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Brand",
          zipcode: "6708",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Nenzing",
          zipcode: "6710",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Thüringen",
          zipcode: "6712",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Ludesch",
          zipcode: "6713",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Nüziders",
          zipcode: "6714",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Bludesch",
          zipcode: "6719",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Thüringerberg",
          zipcode: "6721",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "St. Gerold",
          zipcode: "6722",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Blons",
          zipcode: "6723",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Sonntag",
          zipcode: "6731",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Fontanella",
          zipcode: "6733",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Raggal",
          zipcode: "6741",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Bludenz",
          zipcode: "6751",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Dalaas",
          zipcode: "6752",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Klösterle",
          zipcode: "6754",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Klösterle",
          zipcode: "6762",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Lech",
          zipcode: "6763",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Dalaas",
          zipcode: "6764",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "St. Anton im Montafon",
          zipcode: "6771",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Vandans",
          zipcode: "6773",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Tschagguns",
          zipcode: "6774",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Schruns",
          zipcode: "6780",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Bartholomäberg",
          zipcode: "6781",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Silbertal",
          zipcode: "6782",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "St. Gallenkirch",
          zipcode: "6787",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "St. Gallenkirch",
          zipcode: "6791",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Gaschurn",
          zipcode: "6793",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Gaschurn",
          zipcode: "6794",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Nenzing",
          zipcode: "6820",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Warth",
          zipcode: "6767",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Schwarzenberg",
          zipcode: "6850",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Bildstein",
          zipcode: "6858",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Alberschwende",
          zipcode: "6861",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Egg",
          zipcode: "6863",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Andelsbuch",
          zipcode: "6866",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Schwarzenberg",
          zipcode: "6867",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Bezau",
          zipcode: "6870",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Bezau",
          zipcode: "6874",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Mellau",
          zipcode: "6881",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Schnepfau",
          zipcode: "6882",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Au",
          zipcode: "6883",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Damüls",
          zipcode: "6884",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Schoppernau",
          zipcode: "6886",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Schröcken",
          zipcode: "6888",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Möggers",
          zipcode: "6900",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Lochau",
          zipcode: "6911",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Möggers",
          zipcode: "6912",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Hohenweiler",
          zipcode: "6914",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Kennelbach",
          zipcode: "6921",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Wolfurt",
          zipcode: "6922",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Lauterach",
          zipcode: "6923",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Langen bei Bregenz",
          zipcode: "6932",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Doren",
          zipcode: "6933",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Sulzberg",
          zipcode: "6934",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Langenegg",
          zipcode: "6941",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Krumbach",
          zipcode: "6942",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Riefensberg",
          zipcode: "6943",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Lingenau",
          zipcode: "6951",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Hittisau",
          zipcode: "6952",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Buch",
          zipcode: "6960",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Hard",
          zipcode: "6971",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Fußach",
          zipcode: "6972",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Höchst",
          zipcode: "6973",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Gaißau",
          zipcode: "6974",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Mittelberg",
          zipcode: "6991",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Mittelberg",
          zipcode: "6992",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Mittelberg",
          zipcode: "6993",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Hohenems",
          zipcode: "6845",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Dornbirn",
          zipcode: "6850",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Lustenau",
          zipcode: "6890",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Feldkirch",
          zipcode: "6800",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Göfis",
          zipcode: "6811",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Meiningen",
          zipcode: "6812",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Frastanz",
          zipcode: "6820",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Satteins",
          zipcode: "6822",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Schlins",
          zipcode: "6824",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Laterns",
          zipcode: "6830",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Röthis",
          zipcode: "6832",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Fraxern",
          zipcode: "6833",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Übersaxen",
          zipcode: "6834",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Zwischenwasser",
          zipcode: "6835",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Viktorsberg",
          zipcode: "6836",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Weiler",
          zipcode: "6837",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Götzis",
          zipcode: "6840",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Mäder",
          zipcode: "6841",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Koblach",
          zipcode: "6842",
          state: "Vorarlberg",
          state_code: "08",
        },
        {
          city: "Altach",
          zipcode: "6844",
          state: "Vorarlberg",
          state_code: "08",
        },
      ],
      state: "Vorarlberg",
      state_code: "08",
      cities: [
        "Dornbirn",
        "Bregenz",
        "Lustenau",
        "Hohenems",
        "Bludenz",
        "Hard",
        "Rankweil",
        "Götzis",
        "Lauterach",
        "Wolfurt",
        "Höchst",
        "Altach",
        "Hörbranz",
        "Nenzing",
        "Lochau",
        "Nüziders",
        "Mittelberg",
        "Koblach",
        "Mäder",
        "Schwarzach",
        "Fußach",
        "Schruns",
        "Egg",
        "Ludesch",
        "Göfis",
        "Bürs",
        "Klaus",
        "Satteins",
        "Sulz",
        "Schlins",
        "Bludesch",
        "Bartholomäberg",
        "Sankt Gallenkirch",
        "Meiningen",
        "Thüringen",
        "Tschagguns",
      ],
    },
    {
      country_code: "AT",
      zipcode: [
        {
          city: "Gemeindebezirk Innere Stadt",
          zipcode: "1010",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Leopoldstadt",
          zipcode: "1020",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Landstrasse",
          zipcode: "1030",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Wieden",
          zipcode: "1040",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Margareten",
          zipcode: "1050",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Mariahilf",
          zipcode: "1060",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Neubau",
          zipcode: "1070",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Josefstadt",
          zipcode: "1080",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Alsergrund",
          zipcode: "1090",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Favoriten",
          zipcode: "1100",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Simmering",
          zipcode: "1110",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Meidling",
          zipcode: "1120",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Hietzing",
          zipcode: "1130",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Penzing",
          zipcode: "1140",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Rudolfsheim-Fünfhaus",
          zipcode: "1150",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Ottakring",
          zipcode: "1160",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Hernals",
          zipcode: "1170",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Währing",
          zipcode: "1180",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Döbling",
          zipcode: "1190",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Brigittenau",
          zipcode: "1200",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Floridsdorf",
          zipcode: "1210",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Donaustadt",
          zipcode: "1220",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Liesing",
          zipcode: "1230",
          state: "Wien",
          state_code: "09",
        },
        {
          city: "Gemeindebezirk Donaustadt",
          zipcode: "2301",
          state: "Wien",
          state_code: "09",
        },
      ],
      state: "Wien",
      state_code: "09",
      cities: ["Wien"],
    },
  ];