import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingDelete: false,
  loadingDeleteAll: false,
  loadingCreate: false,
  loadingEdit: false,
  loadingImport: false,
  data: [],
  dataWithGroupName: [],
  error: null,
  errorFetch: null,
  success: null,
  totalCount: 0,
  selectedOne: null,
  selectedMany: [],
  isSelectedMany: false,
  deleteModal: false,
  deleteAllModal: false,
  createModal: false,
  createAllModal: false,
  editModal: false,
  editAllModal: false,
  exportModal: false,
  exportSelectedModal: false,
  importModal: false,
};

export const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    // fetchInstance
    fetchPermissionsStart: (state) => {
      state.loading = true;
    },
    fetchPermissionsSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload?.permissions;
      // state.dataWithGroupName = retTest(action.payload?.permissions, []);
      state.totalCount = action.payload?.permissions?.totalCount;
      state.errorFetch = false;
    },
    fetchPermissionsFail: (state, action) => {
      state.loading = false;
      state.data = [];
      state.errorFetch = action.payload;
    },

    // createInstance
    createPermissionStart: (state) => {
      state.loadingCreate = true;
    },
    createPermissionSuccess: (state, action) => {
      state.loadingCreate = false;
      state.data.unshift(action.payload);
      state.success = 'Permission created successfully.';
    },
    createPermissionFail: (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    },

    // editInstance
    editPermissionStart: (state) => {
      state.loadingEdit = true;
    },
    editPermissionSuccess: (state, action) => {
      state.loadingEdit = false;
      state.data = state.data.map((permission) =>
        permission.id === action.payload.id ? action.payload : permission
      );
      state.success = 'Permission updated successfully.';
    },
    editPermissionFail: (state, action) => {
      state.loadingEdit = false;
      state.error = action.payload;
    },

    // delete Instance
    deletePermissionStart: (state) => {
      state.loadingDelete = true;
    },
    deletePermissionSuccess: (state, action) => {
      state.loadingDelete = false;
      state.data = state.data.filter(
        (permission) => permission.id !== action.payload
      );
      state.success = 'Permission deleted successfully';
      state.deleteModal = false;
    },
    deletePermissionFail: (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
      state.deleteModal = false;
    },

    // delete Many Instances
    deletePermissionsStart: (state) => {
      state.loadingDeleteAll = true;
    },
    deletePermissionsSuccess: (state, action) => {
      state.loadingDeleteAll = false;
      const deletedIds = action.payload;
      state.data = state.data.filter(
        (permission) => !deletedIds.includes(permission.id)
      );
      state.success = 'Permissions deleted successfully';
      state.deleteAllModal = false;
    },
    deletePermissionsFail: (state, action) => {
      state.loadingDeleteAll = false;
      state.error = action.payload;
      state.deleteAllModal = false;
    },

    // selectInstance

    selectOne: (state, action) => {
      state.selectedOne = action.payload;
    },

    selectAll: (state, action) => {
      if (state.isSelectedMany) {
        state.selectedMany = [];
        state.isSelectedMany = false;
      } else {
        state?.data?.map((item) => {
          if (!state.selectedMany.includes(item.id)) {
            state.selectedMany = [...state.selectedMany, item.id];
          }
        });
        state.isSelectedMany = true;
      }
    },

    removeAll: (state, action) => {
      state.selectedMany = [];
      state.isSelectedMany = false;
    },

    updateSelected: (state, action) => {
      if (state.selectedMany.includes(action.payload)) {
        state.selectedMany = state.selectedMany.filter((item) => {
          return item !== action.payload;
        });
      } else {
        state.selectedMany = [...state.selectedMany, action.payload];
      }
    },

    // DELETEiNSTANCE

    openModelDelete: (state, action) => {
      state.selectedOne = action.payload;
      state.deleteModal = !state.deleteModal;
    },

    openModelDeleteAll: (state, action) => {
      state.deleteAllModal = !state.deleteAllModal;
    },

    openModelCreate: (state, action) => {
      state.createModal = !state.createModal;
    },

    openModelEdit: (state, action) => {
      state.selectedOne = action.payload;
      state.editModal = !state.editModal;
    },
    // ExportSelected

    openModeleExportAll: (state, action) => {
      state.exportModal = true;
    },

    openModeleExportSelected: (state, action) => {
      state.exportSelectedModal = true;
    },

    // Import

    openModeleImport: (state, action) => {
      state.importModal = !state.importModal;
    },

    importStart: (state) => {
      state.loadingImport = true;
    },
    importSuccess: (state, action) => {
      state.loadingImport = false;
      state.success = 'Permissions Imported successfully';
      state.importModal = false;
    },
    importFail: (state, action) => {
      state.loadingImport = false;
      state.error = action.payload;
      state.importModal = false;
    },

    // CLEAR
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  fetchPermissionsStart,
  fetchPermissionsSuccess,
  fetchPermissionsFail,
  createPermissionStart,
  createPermissionSuccess,
  createPermissionFail,
  editPermissionStart,
  openModelEdit,
  openModelCreate,
  editPermissionSuccess,
  editPermissionFail,
  deletePermissionStart,
  deletePermissionSuccess,
  deletePermissionFail,
  clearMessages,
  updateSelected,
  selectAll,
  selectOne,
  openModelDelete,
  openModelDeleteAll,
  openModeleExportAll,
  openModeleExportSelected,
  deletePermissionsStart,
  deletePermissionsSuccess,
  deletePermissionsFail,
  openModeleImport,
  importStart,
  importSuccess,
  importFail,
  removeAll,
} = permissionSlice.actions;

export default permissionSlice.reducer;

export const retTest = (data, roles) => {
  const permissionsArray = roles?.flatMap((role) =>
    role?.permissions?.map((permission) => permission)
  );

  const newList = data?.reduce((acc, item, index) => {
    if (item.subject) {
      const existingItem = acc.find((c) => c.name === item.subject);
      if (existingItem) {
        existingItem.permissions.push({
          id: item.id,
          groupName: item.subject,
          name: item.name,
          action: item.action,
          status: permissionsArray.some(
            (permissionObj) => permissionObj.id === item.id
          ),
        });
      } else {
        acc.push({
          id: index,
          name: item.subject,
          permissions: [
            {
              id: item.id,
              groupName: item.subject,
              name: item.name,
              action: item.action,
              status: permissionsArray.some(
                (permissionObj) => permissionObj.id === item.id
              ),
            },
          ],
          status: false,
        });
      }
    }
    return acc;
  }, []);

  return newList ? newList : [];
};
