import React from 'react';
import ModalDialog from '../ModalDialog';
import { useTranslation } from 'react-i18next';
import { templates } from '../../utils/template';
import { useState } from 'react';

import Tabs from '../TabsScreens';

const TemplatesModal = ({ open, setOpen, action }) => {
  const { t } = useTranslation();
  const [select, setSelect] = useState('');

  const tabs = [
    t('home'),
    t('About us'),
    t('Imprint'),
    t("privacy Policy"),
    t("terms & Conditions"),
    // { name: 'About us', value: 'about' },
    // { name: 'Imprint', value: 'imprint' },
  ];

  const close = () => {
    setOpen(false);
  };
  return (
    <ModalDialog
      open={open}
      setOpen={() => close()}
      title={t('Select template')}
      cancelTitle={t('Cancel')}
      cancel={() => close()}
      actionTitle={t('Select')}
      action={() => action(select)}
      // loadingAction={data?.loadingDelete}
      className='p-0 m-0'
    >
      <div className='rounded-md custom-border-color p-1 shadow-custom md:width-600 space-y-3 font-urbanist min-h-[90vh]
      
      '>
        <Tabs screens={tabs} sticky stickyTopValue='-top-[24px]'>
          {Object.keys(templates).map((key, index) => (
            <div key={index} className='font-urbanist mt-4 space-y-8 md:mt-8'>
              {templates[key].map((item, index) => (
                <div
                  className={`${
                    select?.id === item?.id ? 'border-primary' : 'border'
                  } relative border-4 max-h-[280px] overflow-scroll cursor-pointer`}
                  key={index}
                  onClick={() => {
                    setSelect(item);
                  }}
                >
                  <div
                  className='select-none '
                  >
                    <div
                      style={{
                        zoom: '60%',
                      }}
                      dangerouslySetInnerHTML={{ __html: item?.content }}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </Tabs>

      </div>
    </ModalDialog>
  );
};

export default TemplatesModal;
