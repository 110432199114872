import React, { useEffect, Fragment, useState, useContext } from "react";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { useStateContext } from "../contexts/ContextProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationIcon, UserIcon } from "./Svg/MainIcons";
import {
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  Skeleton,
} from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import { deleteTheUserAndTokenFromStorage } from "../services/auth";
import { storeContext } from "../global/store";
import { generalData } from "../utils/general";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}



const languages = [
  { code: "de", flag: "/icons/germany.svg", label: "Deutsch" },
  // { code: "fr", flag: "/icons/fr.png", label: "Français" },
  // { code: "en", flag: "/icons/en.png", label: "English" },
]


const Navbar = () => {
  const {
    bodyStyles,
    currentColor,
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
    setScreenSize,
    screenSize,
  } = useStateContext();

  const location = useLocation();

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
    <div className="flex justify-between  relative h-full ">
      <div
        className={`relative w-full  h-full ${
          i18n.language === "ar" ? "xl:right-280 " : "xl:left-280 "
        }`}
      >
        <div
          className={`h-full xl:full-width ${
            i18n.language === "ar" ? "xl:right-280 " : "xl:left-280 "
          }`}
        >
          {/* header */}
          <div
            className={`flex flex-row items-center justify-between
               sticky top-0  w-full px-6  z-[999] 
               shadow-sm drop-shadow-md pb-4 md:py-3  
               ${i18n.language === "ar" ? "lg:pl-[40px] " : "lg:pr-[40px]  "}
               

               `}
          >
            <div
              className="flex  items-center justify-between w-full gap-x-8
              flex-col md:flex-row gap-y-4 relative pb-4 "
            >
              {generalData.storeNameWithDescription ? (
                <div className="flex items-center gap-4 min-w-[45%] ">
                  <img
                    src="/images/avatar.svg"
                    alt="avatar"
                    className=" block mx-auto cursor-pointer w-[30px] md:w-[36px] "
                  />
                  <h4 className=" text-md lg:text-2xl w-full">
                    {t("Hi,")} {generalData.storeNameWithDescription}
                  </h4>
                </div>
              ) : (
                <div className="flex items-center gap-4 min-w-[45%] "></div>
              )}

              <div
                className="flex flex-row items-center justify-between gap-8 text-xs 
              w-full md:w-auto px-3
              "
              >
                <div className=" block  cursor-pointer h-6 ">
                  {languages?.length > 1 &&  <LanguageMenu /> }
                </div>
                <div className="flex gap-8 justify-start items-center">
                  <div className=" block mx-auto cursor-pointer h-6 w-6">
                    {/* <NotificationMenu /> */}
                  </div>
                  <div className=" block mx-auto cursor-pointer h-6 w-6">
                    {/* <UserIcon color={bodyStyles.navBarIcons} /> */}
                    <AccountMenu />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    store,
    setStore,
    unSeen,
    open: openSection,
  } = useContext(storeContext);
  const navigate = useNavigate();

  const {t} = useTranslation()
  const {
    bodyStyles,

    themeSettings,
    setSelectedTabSetting,
    setThemeSettings,
  } = useStateContext();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment>
      <Box>
        <Tooltip title={t("Account settings")}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <UserIcon color={bodyStyles.navBarIcons} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem
          onClick={() => {
            setSelectedTabSetting("General");
            setThemeSettings(true);
          }}
        >
          <Avatar /> {t("Profile")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSelectedTabSetting("Account");
            setThemeSettings(true);
          }}
        >
          <Avatar /> {t("My account")}
        </MenuItem>
        <Divider /> */}

        {/*
          <MenuItem>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem> 
        */}

        {/* <MenuItem
          className="min-w-[190px]"
          onClick={() => setThemeSettings(true)}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t("Settings")}
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            deleteTheUserAndTokenFromStorage();
            setStore({ ...store, isLogged: false });
            navigate(`/login`);
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("Logout")}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

const LanguageMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { bodyStyles } = useStateContext();
  const { t, i18n } = useTranslation();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Box>
        <Tooltip title="Languages ">
          <button
            onClick={handleClick}
            className="inline-flex justify-center w-full rounded-md 
            py-2  text-sm font-medium
           text-gray-700 focus:outline-none"
          >
            <img
              // src={`/icons/${i18n.language}.png`}
              src={languages.find((l) => l.code === i18n.language).flag}
              // alt="us flag"
              className=" block mx-auto cursor-pointer"
              width={30}
              height={40}
            />

            <ChevronDownIcon
              className={` h-5 w-5 ${
                i18n.language === "ar" ? "-ml-1 mr-2" : "-mr-1 ml-2"
              }`}
              aria-hidden="true"
              style={{
                color: bodyStyles.title,
              }}
            />
          </button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {languages.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              i18n.changeLanguage(item.code);
            }}
          >
            <a
              className={classNames(
                " text-sm flex gap-6 items-center cursor-pointer"
              )}
            >
              <img
                src={item.flag}
                alt=""
                className=" block h-[20px] cursor-pointer"
              />
              {item.label}
            </a>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

const NotificationMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { bodyStyles } = useStateContext();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment>
      <Box>
        <Tooltip title="Notfications">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <NotificationIcon color={bodyStyles.navBarIcons} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          className="flex gap-2"
          style={{
            width: "200px",
          }}
        >
          <Skeleton variant="circular" width={34} height={34} />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            className="flex-grow"
          />
        </MenuItem>
        <MenuItem className="flex gap-2">
          <Skeleton variant="circular" width={34} height={34} />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            className="flex-grow"
          />
        </MenuItem>
        <Divider />
        <MenuItem className="flex gap-2">
          <Skeleton variant="circular" width={34} height={34} />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            className="flex-grow"
          />
        </MenuItem>
        <MenuItem className="flex gap-2">
          <Skeleton variant="circular" width={34} height={34} />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            className="flex-grow"
          />
        </MenuItem>
        <MenuItem className="flex gap-2">
          <Skeleton variant="circular" width={34} height={34} />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            className="flex-grow"
          />
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
