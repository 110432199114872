import * as Yup from "yup";
import { useContext } from "react";
import { storeContext } from "../global/store";
import { ref } from "yup";

const useShippingMethodeValidation = () => {
  const { store } = useContext(storeContext);

  const addShippingMethodeSchema = Yup.object().shape({
    translationData: Yup.object().shape({
      [store.preferredLanguage]: Yup.object().shape({
        name: Yup.string().required("This Field is required"),
        description: Yup.string().required("This Field is required"),
      }),
    }),

    conditionBasedOn: Yup.string().required("This Field is required"),
    zones: Yup.array()
      .min(1, "You must at least add one zone area")
      .required("This Field is required"),

    price: Yup.number(),

    withCondition: Yup.boolean(),

    minPrice: Yup.number().when(["withCondition", "conditionBasedOn"], {
      is: (withCondition, conditionBasedOn) =>
        withCondition && conditionBasedOn == "price",
      then: Yup.number()
        .min(0.4, "this filed does not allow null value")
        .typeError("the Filed must be a number ")
        .required("This Field is required"),
    }),

    maxPrice: Yup.number().when(["withCondition", "conditionBasedOn"], {
      is: (withCondition, conditionBasedOn) =>
        withCondition && conditionBasedOn == "price",
      then: Yup.number()
        .min(
          ref("minPrice"),
          "Minimum order price can't be higher than maximum order price"
        )
        .typeError("the Filed must be a number ")
        .required("This Field is required"),
    }),
  });

  const editShippingMethodeSchema = Yup.object().shape({
    translationData: Yup.object().shape({
      [store.preferredLanguage]: Yup.object().shape({
        name: Yup.string().required("This Field is required"),
        description: Yup.string().required("This Field is required"),
      }),
    }),

    conditionBasedOn: Yup.string().required("This Field is required"),
    zones: Yup.array()
      .min(1, "You must at least add one zone area")
      .required("This Field is required"),

    price: Yup.number(),

    withCondition: Yup.boolean(),

    minPrice: Yup.number().when(["withCondition", "conditionBasedOn"], {
      is: (withCondition, conditionBasedOn) =>
        withCondition && conditionBasedOn == "price",
      then: Yup.number()
        .min(0.4, "this filed does not allow null value")
        .typeError("the Filed must be a number ")
        .required("This Field is required"),
    }),

    maxPrice: Yup.number().when(["withCondition", "conditionBasedOn"], {
      is: (withCondition, conditionBasedOn) =>
        withCondition && conditionBasedOn == "price",
      then: Yup.number()
        .min(
          ref("minPrice"),
          "Minimum order price can't be higher than maximum order price"
        )
        .typeError("the Filed must be a number ")
        .required("This Field is required"),
    }),
  });

  return {
    addShippingMethodeSchema,
    editShippingMethodeSchema,
  };
};

export { useShippingMethodeValidation };
