import React, { useEffect, useState } from 'react';
import Tabs from './../../../TabsScreens';
import BasicInput from '../../../Inputs/BasicInput';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import RtlConversion from '../../../RtlConversion';
import { handleEditSeetingWithData } from '../../../../services/setting';
import FiledButton from '../../../Buttons/FiledButton';
import { useDispatch, useSelector } from 'react-redux';

const EmailSetting = ({}) => {
  const { t } = useTranslation();
  const tabs = [t('Email Setting')];

  const settingState = useSelector((state) => state.setting);

  return (
    <div className='px-6'>
      <Tabs screens={tabs}>
        <div key={0} className='space-y-6 font-urbanist mt-5 md:mt-8'>
          <EmailContainer data={settingState?.data} />
        </div>
        <div key={1} className='space-y-6 font-urbanist mt-5 md:mt-8 '></div>
      </Tabs>
    </div>
  );
};

export default EmailSetting;

const EmailContainer = ({ data }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState({
    mailSetting: false,
  });
  const { t, i18n } = useTranslation();
  const [fields, setFields] = useState({
    postmark_key: '',
    sendgrid_key: '',
    awsses_key: '',
    awsses_secret: '',
    from_email: '',
  });

  const [disableAll, setdisableAll] = useState(false);

  useEffect(() => {
    if (data?.setting_email) {
      setFields((prevState) => ({
        ...prevState,
        postmark_key: data?.setting_email?.postmark_key,
        sendgrid_key: data?.setting_email?.sendgrid_key,
        awsses_key: data?.setting_email?.awsses_key,
        awsses_secret: data?.setting_email?.awsses_secret,
        from_email: data?.setting_email?.from_email,
      }));
    }
  }, [data]);

  const addInstanceSchema = Yup.object().shape({
    postmark_key: Yup.string().required(t('This Field is required')),
    sendgrid_key: Yup.string().required(t('This Field is required')),
    awsses_key: Yup.string().required(t('This Field is required')),
    awsses_secret: Yup.string().required(t('This Field is required')),
    from_email: Yup.string().required(t('This Field is required')),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      postmark_key: fields?.postmark_key,
      sendgrid_key: fields?.sendgrid_key,
      awsses_key: fields?.awsses_key,
      awsses_secret: fields?.awsses_secret,
      from_email: fields?.from_email,
    },
    validationSchema: addInstanceSchema,
    onSubmit: async (values) => {
      addOrUpdateInstance(values);
    },
  });

  const addOrUpdateInstance = async (values) => {
    setLoading({ ...loading, mailSetting: true });

    const dataSent = {
      updateSettingInput: {
        setting_email: {
          postmark_key: values?.postmark_key,
          sendgrid_key: values?.sendgrid_key,
          awsses_key: values?.awsses_key,
          awsses_secret: values?.awsses_secret,
          from_email: values?.from_email,
        },
      },
    };

    dispatch(
      handleEditSeetingWithData(dataSent, (res) => {
        setLoading({ ...loading, mailSetting: false });
      })
    );
  };

  return (
    <div className='w-full flex flex-col items-center justify-center gap-5'>
      <div className='w-full flex flex-col border-[3px] border-[#ffde00] rounded-[4px]'>
        <div
          className='bg-[#ffde00] h-[54px] text-[#000] flex flex-row items-center justify-start px-2 gap-x-2 
        font-bold 
        '
        >
          <img
            src='/images/postmark.png'
            alt='postmark'
            className=' block cursor-pointer h-8'
          />
          <span>{t('Postmark settings')}</span>
        </div>
        <div className='flex flex-row items-start justify-center w-full'>
          <RtlConversion rtl={i18n.language === 'ar' ? true : false}>
            <div className='flex flex-col items-center justify-center w-full py-8 px-4 gap-y-4'>
              <BasicInput
                disabled={disableAll}
                label={t('Postmark key')}
                // placeholder=""
                value={fields?.postmark_key}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    postmark_key: e.target.value,
                  });
                }}
                error={
                  formik.touched.postmark_key &&
                  Boolean(formik.errors.postmark_key)
                }
                helperText={
                  formik.touched.postmark_key && formik.errors.postmark_key
                }
                InputProps={{
                  style: { fontSize: '12px' },
                }}
              />
            </div>
          </RtlConversion>
        </div>
      </div>

      <div className='w-full flex flex-col border-[3px] border-[#294661] rounded-[4px]'>
        <div
          className='bg-[#294661] h-[54px] text-[#fff] flex flex-row items-center justify-start px-2 gap-x-2 
        font-bold 
        '
        >
          <img
            src='/images/SendGrid.png'
            alt='SendGrid'
            className=' block cursor-pointer h-8'
          />
          <span>{t('SendGrid settings')}</span>
        </div>
        <div className='flex flex-row items-start justify-center w-full'>
          <RtlConversion rtl={i18n.language === 'ar' ? true : false}>
            <div className='flex flex-col items-center justify-center w-full py-8 px-4 gap-y-4'>
              <BasicInput
                disabled={disableAll}
                label={t('Sendgrid Key')}
                // placeholder=""
                value={fields?.sendgrid_key}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    sendgrid_key: e.target.value,
                  });
                }}
                error={
                  formik.touched.sendgrid_key &&
                  Boolean(formik.errors.sendgrid_key)
                }
                helperText={
                  formik.touched.sendgrid_key && formik.errors.sendgrid_key
                }
                InputProps={{
                  style: { fontSize: '12px' },
                }}
              />
            </div>
          </RtlConversion>
        </div>
      </div>

      <div className='w-full flex flex-col border-[3px] border-[#232f3e] rounded-[4px]'>
        <div
          className='bg-[#232f3e] h-[54px] text-white flex flex-row items-center justify-start px-2 gap-x-2 
        font-bold 
        '
        >
          <img
            src='/images/aws-ses.png'
            alt='aws-ses'
            className=' block cursor-pointer h-8'
          />
          <span>{t('aws-ses settings')}</span>
        </div>
        <div className='flex flex-row items-start justify-center w-full'>
          <RtlConversion rtl={i18n.language === 'ar' ? true : false}>
            <div className='flex flex-col items-center justify-center w-full py-8 px-4 gap-y-4'>
              <BasicInput
                disabled={disableAll}
                label={t('Awsses Secret')}
                // placeholder=""
                value={fields?.awsses_secret}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    awsses_secret: e.target.value,
                  });
                }}
                error={
                  formik.touched.awsses_secret &&
                  Boolean(formik.errors.awsses_secret)
                }
                helperText={
                  formik.touched.awsses_secret && formik.errors.awsses_secret
                }
                InputProps={{
                  style: { fontSize: '12px' },
                }}
              />

              <BasicInput
                disabled={disableAll}
                label={t('Awsses Key')}
                // placeholder=""
                value={fields?.awsses_key}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    awsses_key: e.target.value,
                  });
                }}
                error={
                  formik.touched.awsses_key && Boolean(formik.errors.awsses_key)
                }
                helperText={
                  formik.touched.awsses_key && formik.errors.awsses_key
                }
                InputProps={{
                  style: { fontSize: '12px' },
                }}
              />
            </div>
          </RtlConversion>
        </div>
      </div>

      <div className='w-full flex flex-col border-[3px] border-[#4285F4] rounded-[4px]'>
        <div
          className='bg-[#4285F4] h-[54px] text-white flex flex-row items-center justify-start px-2 gap-x-2 
        font-bold 
        '
        >
          <span>{t('Email settings')}</span>
        </div>
        <div className='flex flex-row items-start justify-center w-full'>
          <RtlConversion rtl={i18n.language === 'ar' ? true : false}>
            <div className='flex flex-col items-center justify-center w-full py-8 px-4 gap-y-4'>
              <BasicInput
                disabled={disableAll}
                label={t('from Email')}
                // placeholder=""
                value={fields?.from_email}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    from_email: e.target.value,
                  });
                }}
                error={
                  formik.touched.from_email && Boolean(formik.errors.from_email)
                }
                helperText={
                  formik.touched.from_email && formik.errors.from_email
                }
                InputProps={{
                  style: { fontSize: '12px' },
                }}
              />
            </div>
          </RtlConversion>
        </div>
      </div>
      <div className='flex justify-end w-full pt-6 pb-4'>
        {loading?.mailSetting ? (
          <FiledButton loading />
        ) : (
          <FiledButton text={t('save')} onClick={() => formik.submitForm()} />
        )}
      </div>
    </div>
  );
};
