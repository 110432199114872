import React from "react";
import { useStateContext } from "../../../../contexts/ContextProvider";
import { useTranslation } from "react-i18next";
import RatioField from "./RatioField";

const CustomerAccounts = ({ fields, setFields }) => {
  const { t } = useTranslation();
  const { bodyStyles } = useStateContext();

  return (
    <div className="w-full flex flex-col items-start justify-start ">
      <h1
        style={{
          color: bodyStyles?.title || "#808080",
        }}
        className="text-[#808080] text-[19px] font-[600] uppercase"
      >
        {t("Customer accounts")}
      </h1>
      <div className="flex flex-col items-center justify-start w-full py-6 gap-y-4">
        <RatioField
          checked={fields.accountsAreOptional}
          onChange={() => {
            setFields((prevState) => ({
              ...prevState,
              // useAccounts: false,
              accountsAreOptional: true,
              accountsAreRequired: false,
            }));
          }}
          value={fields.accountsAreOptional}
          name="radio-buttons-2"
          title={t("Accounts are optional")}
          desc={t("Customers can create accounts or checkout as guests.")}
        />
        <RatioField
          checked={fields.accountsAreRequired}
          onChange={() => {
            setFields((prevState) => ({
              ...prevState,
              // useAccounts: false,
              accountsAreOptional: false,
              accountsAreRequired: true,
            }));
          }}
          value={fields.accountsAreRequired}
          name="radio-buttons-3"
          title={t("Accounts are required")}
          desc={t("Customers can create accounts or checkout as guests.")}
        />
      </div>
    </div>
  );
};

export default CustomerAccounts;
