import { useState } from 'react';
import { useFormik } from 'formik';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { delay } from '../../utils/helpers';
import { useEffect } from 'react';
import { initInstanceState } from '../../utils/initStates';
import BottomBanner from '../../components/Actions/BottomBanner';
import PageModal from '../../components/PageModal';
import ModalNotFound from '../../components/MessageModals/ModalNotFound';
import { useDispatch, useSelector } from 'react-redux';
import EditCategoryForm from '../../components/Categories/updateCategory/EditCategoryForm';
import { handleEditCategory } from '../../services/category';
import * as Yup from 'yup';
import { GET_CATEGORY } from '../../graphql/query/categories.query';

const EditCategory = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.category);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const params = useParams();
  const [instanceState, setInstanceState] = useState(initInstanceState);

  const [fields, setFields] = useState({
    name: '',
    description: '',
    type: 'PRODUCT',
  });

  const {
    loading: loadingInstance,
    error: errorInstance,
    refetch: refetchInstance,
    data: instanceData,
  } = useQuery(GET_CATEGORY, {
    variables: {
      categoryId: instanceState.id,
    },
    skip: instanceState.id ? false : true,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setInstanceState((prev) => {
      return { ...prev, id: +params?.id };
    });
  }, [params]);

  useEffect(() => {
    if (instanceData?.category) {
      setFields((prev) => {
        return {
          ...prev,
          name: instanceData?.category?.name,
          description: instanceData?.category?.description,
          type: instanceData?.category?.type,
        };
      });
    }
  }, [instanceData]);

  const addCategorySchema = Yup.object().shape({
    name: Yup.string().required(t('This Field is required')),
    description: Yup.string().required(t('This Field is required')),
    type: Yup.string().required(t('This Field is required')),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: instanceState.id,
      name: fields.name,
      description: fields.description,
      type: fields?.type,
    },
    
    validationSchema: addCategorySchema,
    onSubmit: async (values) => {
      dispatch(
        handleEditCategory(values, (res) => {  })
      );
    },
  });

  return (
    <PageModal
      pageName={t('Edit Category')}
      pagePrev={`/categories`}
      loading={loadingInstance}
    >
      <div
        className={`${instanceState?.id ? 'opacity-100' : 'opacity-0 hidden'}`}
      >
        <BottomBanner
          btn01={t('Cancel')}
          btn01Action={() => navigate(`/categories`)}
          btn02={t('Save Category')}
          btn02Action={() => formik.handleSubmit()}
          loading={data?.loadingEdit}
        />
      </div>

      {instanceData && !errorInstance ? (
        <EditCategoryForm
          fields={fields}
          setFields={setFields}
          formik={formik}
          instanceData={instanceData}
        />
      ) : (
        <ModalNotFound
          message='Item Not Found.'
          returnMessage='Return to categories page'
          action={() => navigate(`/reference`)}
        />
      )}
    </PageModal>
  );
};
export default EditCategory;
