import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import DateRange from '../components/DateRange';
import { useStateContext } from '../contexts/ContextProvider';
import CheckBox from '../components/Inputs/CheckBox';
import { ArrowLeftIcon } from '../components/Svg/MainIcons';
import { useTranslation } from 'react-i18next';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import AreaChart from '../components/Charts/AreaChart';
import StepLine from '../components/Charts/StepLine';
import GraphCard from '../components/Analytics/GraphCard';
import { useQuery } from '@apollo/client';
import { GET_CURRENT_SESSIONS } from '../graphql/query/analytics.query';
import TotalSeesionsAnalytics from '../components/Charts/TotalSeesionsAnalytics';
import { generalData } from '../utils/general';
const Analytics = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  let dateNow = new Date();
  const lastWeek = new Date(new Date().setDate(new Date().getDate() - 6));
  const [period, setPeriod] = useState({
    id: 3,
    label: 'Last 7 days',
    startDate: lastWeek,
    endDate: dateNow,
  });
  const [dates, setDates] = useState({
    begin: lastWeek,
    end: dateNow,
  });

  const {
    loading,
    error,
    data: dataSessions,
    refetch,
    end,
  } = useQuery(GET_CURRENT_SESSIONS, {
    // variables: {

    // },
    fetchPolicy: 'cache-and-network',
  });

  const [autoRefresh, setAutoRefresh] = useState(false);
  const AreaChartData = [
    {
      name: t('Jan'),
      product1: 60,
      product2: 0,
      product3: -20,
    },
    {
      name: t('Feb'),
      product1: 20,
      product2: -20,
      product3: 20,
    },
    {
      name: t('Mar'),
      product1: 60,
      product2: 0,
      product3: -20,
    },
    {
      name: t('Avr'),
      product1: 100,
      product2: 20,
      product3: -60,
    },
    {
      name: t('Mai'),
      product1: 0,
      product2: 60,
      product3: 20,
    },
    {
      name: t('Jun'),
      product1: -20,
      product2: 20,
      product3: 60,
    },
    {
      name: t('Jul'),
      product1: -100,
      product2: 0,
      product3: 30,
    },
  ];

  const AvergeOrderate = [
    {
      name: t('Jan'),
      product1: 30,
    },
    {
      name: t('Feb'),
      product1: 30,
    },
    {
      name: t('Mar'),
      product1: 80,
    },
    {
      name: t('Avr'),
      product1: 100,
    },
    {
      name: t('Mai'),
      product1: 80,
    },
    {
      name: t('Jun'),
      product1: 120,
    },
    {
      name: t('Jul'),
      product1: 80,
    },
  ];

  const StepLineChartData = [
    {
      name: 'Day 1',
      value: -20,
    },
    {
      name: 'Day 2',
      value: 0,
    },
    {
      name: 'Day 3',
      value: -60,
    },
    {
      name: 'Day 4',
      value: -60,
    },

    {
      name: 'Day 5',
      value: 0,
    },

    {
      name: 'Day 6',
      value: 60,
    },

    {
      name: 'Day 7',
      value: 10,
    },
  ];

  const totalSales = [
    {
      name: t('Day') + ' 1',
      product1: 100,
    },
    {
      name: t('Day') + ' 2',
      product1: 200,
    },
    {
      name: t('Day') + ' 3',
      product1: 300,
    },
    {
      name: t('Day') + ' 4',
      product1: 200,
    },
    {
      name: t('Day') + ' 5',
      product1: 500,
    },
    {
      name: t('Day') + ' 6',
      product1: 700,
    },

    {
      name: t('Day') + ' 7',
      product1: 0,
    },

    {
      name: t('Day') + ' 8',
      product1: 600,
    },
  ];

  const totalOrders = [
    {
      name: t('Day') + ' 1',
      product1: 50,
    },
    {
      name: t('Day') + ' 2',
      product1: 100,
    },
    {
      name: t('Day') + ' 3',
      product1: 40,
    },
    {
      name: t('Day') + ' 4',
      product1: 10,
    },
    {
      name: t('Day') + ' 5',
      product1: 150,
    },
    {
      name: t('Day') + ' 6',
      product1: 80,
    },
    {
      name: t('Day') + ' 7',
      product1: 100,
    },
    {
      name: t('Day') + ' 8',
      product1: 55,
    },
  ];


  const SalesBySocialSource = [
    { name: 'Facebook', count: '805.3', percentage: 25 },
    { name: 'Instagram', count: '205.6', percentage: -9 },
    { name: 'Tiktok', count: '107.4', percentage: 15 },
    { name: 'Snapchat', count: '104', percentage: 3 },
    { name: 'Unknown', count: '250', percentage: 10 },
  ];

  const SalesByTraficSource = [
    { name: 'Direct', count: 780, percentage: 30 },
    { name: 'Social', count: '180', percentage: -11 },
    { name: 'Search', count: '250', percentage: 25 },
    { name: 'Unknown', count: '10', percentage: 15 },
    { name: 'Email', count: '4', percentage: -7 },
  ];

  const topProductsByUnitSold = [
    { name: 'UNTERSETZER AUS BAMBUS', count: '230', percentage: 30 },
    { name: 'WASSERKOCHER', count: '120', percentage: -11 },
    { name: 'WASSERKOCHER 1.7 L', count: '25', percentage: 25 },
    { name: 'WAFFELEISEN', count: '10', percentage: 15 },
    { name: 'BABYRASSELN', count: '4', percentage: -7 },
  ];

  const mostSelledProducts = [
    { name: 'UNTERSETZER AUS BAMBUS', count: '230', percentage: 30 },
    { name: 'WASSERKOCHER', count: '120', percentage: -11 },
    { name: 'WASSERKOCHER 1.7 L', count: '25', percentage: 25 },
    { name: 'WAFFELEISEN', count: '10', percentage: 15 },
    { name: 'BABYRASSELN', count: '4', percentage: -7 },
  ];

  const SessionsByDeviceType = [
    { name: 'Desktop', count: '2.245', percentage: 51 },
    { name: 'Mobile', count: '2.238', percentage: -11 },
    { name: 'Tablet', count: '57', percentage: 12 },
    { name: 'Other', count: '37', percentage: 36 },
  ];

  const SessionsBySocialSource = [
    { name: 'Facebook', count: '54.236', percentage: 25 },
    { name: 'Instagram', count: '3.120', percentage: -9 },
    { name: 'Tiktok', count: '2.215', percentage: 15 },
    { name: 'Other', count: '101', percentage: 15 },
  ];

  const SessionsByLocation = [
    { name: 'wein', count: '51.486', percentage: 25 },
    { name: 'Burgenland', count: '5.220', percentage: -9 },
    { name: 'Salzburg', count: '7.215', percentage: 11 },
    { name: 'Vorarlberg', count: '102', percentage: 13 },
    { name: 'Other', count: '101', percentage: -4 },
  ];

  const TopLandingPagesBySessions = [
    { name: 'product?tag=Wochenangebote', count: '1.486', percentage: 25 },
    { name: 'product', count: '1.220', percentage: 9 },
    { name: 'product?idCategories=6#products', count: '915', percentage: 11 },
    { name: 'product/51/WASSERKOCHER', count: '802', percentage: 13 },
    { name: 'product?sorting=new', count: '10', percentage: 4 },
    { name: 'fav', count: '8', percentage: 3 },
    { name: 'contact', count: '5', percentage: 3 },
  ];

  const SessionsLineData = [
    {
      name: 'date 01',
      product1: 0,
    },
    {
      name: 'date 02',
      product1: 0,
    },
    {
      name: 'date 03',
      product1: 0,
    },
    {
      name: 'date 04',
      product1: 40,
    },
  ];

  const conversionRateData = [
    { name: 'Add To Cart', count: '3.71', percentage: 1 },
    { name: 'Reached Checkout', count: '3.51', percentage: -3 },
    { name: 'Seesion Converted', count: '1.77', percentage: 8 },
  ];

  return (
    <Layout>
      <div
        className='font-manrope mb-2 flex flex-wrap items-center justify-between'
        style={{
          color: bodyStyles?.text ? bodyStyles?.text : '#fff',
        }}
      >
        <div
          className='sticky top-[60px] z-[5] flex w-full flex-col gap-3  space-y-1
         pt-4 pb-2 md:top-[52px]
         '
          style={{
            background: bodyStyles?.background,
            color: bodyStyles?.text,
          }}
        >
          <div
            className='flex w-full items-center gap-2'
            style={{
              color: bodyStyles?.title ? bodyStyles?.title : '#fff',
            }}
          >
            <div
              className={`w-10 cursor-pointer rounded-full
                        p-1 duration-300 hover:bg-[rgba(0,0,0,0.089)] ${
                          i18n.language === 'ar' && 'rotate-180 '
                        }`}
              onClick={() => navigate(`/`)}
            >
              <ArrowLeftIcon color={bodyStyles?.text} />
            </div>
            <h1 className='px-3 text-xl font-semibold uppercase '>
              {t('analytics')}
            </h1>
          </div>

          <div className='mt-10 flex w-full flex-row items-center justify-between px-1'>
            <DateRange
              initialPeriod={period}
              getPeriod={setPeriod}
              dates={dates}
              setDates={setDates}
            />

            <div className='flex flex-row items-center justify-center gap-3 px-2'>
              <CheckBox
                checked={autoRefresh}
                onClick={() => setAutoRefresh(!autoRefresh)}
                label={t('auto refresh')}
                color={bodyStyles?.subText}
              />
            </div>
          </div>
        </div>

        <div className='w-full space-y-4'>
          <div className='flex flex-row items-center justify-between'>
            <div className='mt-4 flex w-full flex-col items-start justify-center'>
              <div
                className=' mt-10 grid min-h-screen
                w-full grid-cols-1 items-start justify-between gap-10 px-2 xl:grid-cols-2'
              >
                {/* graph-1 */}
                <GraphCard
                  title={t('TOTAL SALES')}
                  // description={t("Secondary text")}
                >
                  <StepLine
                    data={totalSales}
                    minY={0}
                    lineTitle={t('Sales')}
                    Unit='EUR'
                  />
                </GraphCard>

                {/* graph-2 */}
                <GraphCard
                  title={t('TOTAL ORDERS')}
                  // description={t("Secondary text")}
                >
                  <StepLine
                    data={totalOrders}
                    minY={0}
                    lineTitle={t('orders')}
                    // Unit="EUR"
                  />
                </GraphCard>

                <GraphCard title={t('Total Session')}>
                  <div className='flex w-full items-center justify-between p-8 '>
                    <div className='w-2/3'>
                      <span
                        className='p-1 text-3xl font-extrabold'
                        style={{
                          color: bodyStyles?.title,
                        }}
                      >
                        {dataSessions?.currentSessions}
                      </span>
                      <span className='px-1 text-xl font-extrabold'>
                        {t("Vistors")}
                      </span>
                    </div>

                    <div className='w-1/2 text-3xl'>
                      <div className='flex h-[92px] items-center  justify-center'>
                        <TotalSeesionsAnalytics
                          data={SessionsLineData}
                          color='#B835B3'
                        />
                      </div>
                    </div>
                  </div>
                </GraphCard>

                <GraphCard title={t('Conversion Rate')}>
                  <InlineData data={conversionRateData} unit='%' />
                </GraphCard>

                <GraphCard title={t('Averge Order Rate')}>
                  <div className='flex h-full w-full flex-col gap-4'>
                    <div className='pl-6'>
                      <InlineData
                        data={[
                          {
                            name: t('Averge Order Value'),
                            count: '51.486',
                            percentage: 25,
                          },
                        ]}
                        unit='%'
                      />
                    </div>

                    <AreaChart
                      data={AvergeOrderate}
                      lineTitle={t('Order Value')}
                    />
                  </div>
                </GraphCard>

                <GraphCard title={t('Top Landing Pages By Sessions')}>
                  <InlineData
                    data={TopLandingPagesBySessions}
                    unit=''
                    typeName='url'
                  />
                </GraphCard>

                {/* graph-3 */}
                <GraphCard title={t('Sales By Social Source')}>
                  <InlineData data={SalesBySocialSource} unit='€' />
                </GraphCard>

                {/* graph-4 */}
                <GraphCard title={t('Sales By Trafic Source')}>
                  <InlineData data={SalesByTraficSource} unit='€' />
                </GraphCard>

                <GraphCard title={t('Top Products By Unit Sold')}>
                  <InlineData data={topProductsByUnitSold} unit='' />
                </GraphCard>

                {/* graph-5 SessionsByDeviceType */}
                <GraphCard title={t('Sessions By Device Type')}>
                  <InlineData data={SessionsByDeviceType} unit='' />
                </GraphCard>

                {/* graph-6 SessionsBySocialSource */}
                <GraphCard title={t('Sessions By Social Source')}>
                  <InlineData data={SessionsBySocialSource} unit='' />
                </GraphCard>

                {/* graph-6 SessionsByLocation */}
                <GraphCard title={t('Sessions By Location')}>
                  <InlineData data={SessionsByLocation} unit='' />
                </GraphCard>

                <GraphCard title={t('most Selled Products')}>
                  <InlineData data={mostSelledProducts} unit='€' />
                </GraphCard>

                {/* graph-*/}
                {/* <GraphCard
                  title={t("PRIMARY TEXT")}
                  description={t("Secondary text")}
                >
                  <AreaChart data={AreaChartData} />
                </GraphCard>

                <GraphCard
                  title={t("PRIMARY TEXT")}
                  description={t("Secondary text")}
                >
                  <AreaChart data={AreaChartData} />
                </GraphCard>

                <GraphCard
                  title={t("PRIMARY TEXT")}
                  description={t("Secondary text")}
                >
                  <GraphBarChart data={GraphBarChartData} />
                </GraphCard>

                <GraphCard
                  title={t("PRIMARY TEXT")}
                  description={t("Secondary text")}
                >
                  <GraphBarChart data={GraphBarChartData} />
                </GraphCard> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Analytics;

const InlineData = ({ data, unit, typeName }) => {
  return (
    <div className='mt-8 flex h-full w-full flex-col gap-3 p-4'>
      {data.map((item, index) => (
        <div className={`flex w-full flex-row gap-1`} key={index}>
          <div
            className='w-[45%]'
            onClick={() => {
              typeName === 'url' &&
                window.open(`${generalData.clientSideUrl}/${item.name}`);
            }}
          >
            <span
              className={` font-[600] text-[#535151]
             ${
               typeName === 'url'
                 ? 'text-md cursor-pointer text-[blue]'
                 : 'text-xl'
             }
            `}
            >
              {item.name}
            </span>
          </div>

          <div className='w-[35%] '>
            <span className='text-lg font-[600] text-[#535151]'>
              {unit} {item.count}
            </span>
          </div>

          <div className='flex w-[20%] items-start'>
            {item.percentage > 0 ? (
              <NorthIcon color='success' />
            ) : (
              <SouthIcon color='error' />
            )}

            <span className='text-lg font-[600] text-[#535151]'>
              {item.percentage}%
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
