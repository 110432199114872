import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { ArrowLeftIcon } from './Svg/MainIcons';
import { useTranslation } from 'react-i18next';
import TabsType from './Tabs';
import FiledButton from './Buttons/FiledButton';
import FilterSearchHeader from './Filter/FilterSearchHeader';
import LoadingSpinner from './LoadingSpinner';
import SnackbarModal from './MessageModals/SnackbarModal';
import { useDispatch } from 'react-redux';

const PageModalSetting = ({
  children,
  tabSelect,
  tabs,
  handleTabChange,
  search,
  setSearch,
  sortOptions,
  sortBy,
  setSortBy,
  status,
  pageName,
  addSection,
  addSectionLabel,
  addSectionUrl,
  addSectionAction,
  addInstanceModal,
  pageNameStatus,
  empty,
  filterSection,
  loading,
  searchButton,
  filterButton,
  headerStikyPostion,
  component,
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState([]);

  useEffect(() => {
    setParams([]);
    for (let entry of searchParams.entries()) {
      setParams((prev) => [...prev, entry]);
    }
  }, [searchParams]);

  const removeParams = (param) => {
    searchParams.delete(param);
    setSearchParams(searchParams);
  };

  return (
    <div
      className='font-manrope flex flex-wrap items-center justify-between w-full'
      style={{
        color: bodyStyles?.text ? bodyStyles?.text : '#fff',
      }}
    >
      <div className='w-full space-y-2 '>
        {!empty && (
          <div
            className={`${
              headerStikyPostion === 0 ? '-top-1' : 'top-[53px] md:top-[54px] '
            } sticky z-[5] space-y-1 shadow-sm pt-0.5 pb-1 px-2`}
            style={{
              background: bodyStyles?.background,
              color: bodyStyles?.text,
              top: headerStikyPostion ? `${headerStikyPostion}px` : '',
            }}
          >
            <div
              className='mb-2 flex flex-row items-center justify-between '
              style={{
                maxWidth: addSectionLabel ? '100%' : '99.1%',
              }}
            >
              <div className='mt-2 hidden w-full flex-col items-start justify-center lg:flex'>
                <div className='flex w-full justify-between gap-4 '>
                  <div
                    className='flex items-center gap-2 flex-wrap '
                    style={{
                      color: bodyStyles?.title ? bodyStyles?.title : '#fff',
                    }}
                  >
                    <div className='flex items-center gap-x-1 px-2'>
                      <h1 className='text-xl font-semibold uppercase  '>
                        {pageName}
                      </h1>
                      {pageNameStatus && (
                        <h1 className='text-xl font-semibold uppercase  '>
                          {t(status)}
                        </h1>
                      )}
                    </div>

                  </div>

                  {tabs && tabs?.length > 0 && (
                    <TabsType
                      tabSelect={tabSelect}
                      tabs={tabs}
                      onChange={handleTabChange}
                    />
                  )}

                  <div className='min-w-[150px]  '>
                    {addSection && addSectionLabel && (
                      <FiledButton
                        text={addSectionLabel}
                        onClick={() =>
                          addInstanceModal
                            ? dispatch(addInstanceModal())
                            : addSectionAction
                            ? addSectionAction()
                            : navigate(addSectionUrl)
                        }
                        fullWidth
                        minHeight='40px'
                      />
                    )}
                  </div>

                  {component && component}
                </div>
              </div>
              {/* Mobile  */}

              <div className='flex w-full flex-col justify-between gap-0 pt-1 md:pt-0 md:pb-5 lg:hidden flex-wrap '>
                <div className='flex justify-between flex-wrap gap-2'>
                  <div
                    className='flex items-center gap-2'
                    style={{
                      color: bodyStyles?.title ? bodyStyles?.title : '#fff',
                    }}
                  >
                    <div
                      className={`w-10 cursor-pointer rounded-full
                        p-1 duration-300 hover:bg-[rgba(0,0,0,0.089)] ${
                          i18n.language === 'ar' && 'rotate-180 '
                        }`}
                      onClick={() => navigate(-1)}
                    >
                      <ArrowLeftIcon color={bodyStyles?.text} />
                    </div>
                    <h1 className='text-md font-semibold uppercase  '>
                      {t(status)} {pageName}
                    </h1>
                  </div>
                  <div className='min-w-[90px]'>
                    {addSection && addSectionLabel && (
                      <FiledButton
                        text={addSectionLabel}
                        onClick={() =>
                          addInstanceModal
                            ? dispatch(addInstanceModal())
                            : addSectionAction
                            ? addSectionAction()
                            : navigate(addSectionUrl)
                        }
                        fullWidth
                        minHeight='40px'
                      />
                    )}
                    {component && component}
                  </div>
                </div>

                {tabs && tabs?.length > 0 && (
                  <TabsType
                    tabSelect={tabSelect}
                    tabs={tabs}
                    onChange={handleTabChange}
                  />
                )}
                <div className='w-full'></div>
              </div>
            </div>

            {filterSection && (
              <FilterSearchHeader
                placeholder={t('Search')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                sortOptions={sortOptions}
                sortBy={sortBy}
                setSortBy={setSortBy}
                searchButton={searchButton}
                filterButton={filterButton}
              />
            )}

            {params?.length > 0 && (
              <div className='hidden flex-row gap-2 md:flex '>
                {params &&
                  params.map((item, index) => (
                    <div key={index}>
                      {item[0] !== 'type' &&
                        item[0] !== 'status' &&
                        item[0] !== 'page' && (
                          <span
                            class='m-1 flex cursor-pointer flex-wrap items-center justify-between rounded-xl bg-gray-50 px-3 py-1.5
                    pt-3 text-xs font-bold leading-loose duration-300 hover:bg-gray-100 dark:text-gray-300 sm:text-sm'
                          >
                            {`${item[0]}. ${item[1]}`}
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              class='ml-4 h-3 w-3 text-gray-500 duration-300 hover:text-gray-900 dark:text-gray-400
                        dark:hover:text-gray-300 sm:h-4 sm:w-4'
                              viewBox='0 0 20 20'
                              fill='currentColor'
                              onClick={() => removeParams(item[0])}
                            >
                              <path
                                fill-rule='evenodd'
                                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                                clip-rule='evenodd'
                              />
                            </svg>
                          </span>
                        )}
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}
        <div className='px-0.5'>
          {/* <SnackbarModal /> */}
          {loading ? (
            <div className=' flex h-full min-h-[70vh] w-full items-center justify-center'>
              <LoadingSpinner size='34px' />{' '}
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
};

export default PageModalSetting;
