import React from 'react';

const DiscountStatus = ({ item }) => {
  return (
    <>
      {item?.status?.toLowerCase() == 'active' ? (
        <div className='py-1 w-[60px] bg-[#209BBB] bg-opacity-20 text-[#209BBB] text-xs text-center  rounded-full font-Roboto'>
          {item?.status}
        </div>
      ) : item?.status?.toLowerCase() == 'expired' ? (
        <div className='py-1 w-[60px] bg-[#EB5757] bg-opacity-20 text-[#EB5757] text-xs text-center  rounded-full font-Roboto'>
          {item?.status}
        </div>
      ) : (
        <div className='py-1 w-[60px] bg-[#FF9F10] bg-opacity-20 text-[#FF9F10] text-xs text-center rounded-full font-Roboto'>
          {item?.status}
        </div>
      )}
    </>
  );
};

export default DiscountStatus;
