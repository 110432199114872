import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { delay } from "../../../utils/helpers";
import { useStateContext } from "../../../contexts/ContextProvider";
import { InputAdornment } from "@mui/material";
import { storeContext } from "../../../global/store";
import RadioField from "../../RadioField";
import BasicInput from "../../Inputs/BasicInput";
import ModalDialog from "../../ModalDialog";
import * as Yup from "yup";

const ShippingModal = ({
    shippingOrderModal,
    setShippingtOrderModal,
    instanceState: prevInstanceState,
    setInstanceState: prevSetInstanceState,
  }) => {
    const { t, i18n } = useTranslation();
    const { bodyStyles } = useStateContext();
    const { store } = useContext(storeContext);
  
    const [shippingOrder, setShippingtOrder] = useState({
      shippingType: "free",
      shippingRate: "",
      shippingValue: 0,
    });
  
    const [instanceState, setInstanceState] = useState({
      loadingCreateInstance: false,
      createInstance: false,
      errorCreatInstance: false,
      uploadMore: false,
      loadingUpload: null,
      openModol: false,
    });
  
    const addInstanceSchema = Yup.object().shape({
      shippingType: Yup.string().required("This Field is required"),
      shippingRate: Yup.string().when("shippingType", {
        is: "custom",
        then: Yup.string().required(t("This Field is required")),
      }),
      shippingValue: Yup.string().required("This Field is required"),
    });
  
    const addInstanceFormik = useFormik({
      enableReinitialize: true,
      initialValues: {
        shippingType: shippingOrder.shippingType,
        shippingRate: shippingOrder.shippingRate,
        shippingValue: shippingOrder.shippingValue,
      },
      validationSchema: addInstanceSchema,
      onSubmit: async (values) => {
        addInstance(values);
      },
    });
  
    const addInstance = async (values) => {
      setInstanceState((prev) => {
        return {
          ...prev,
          loadingCreateInstance: true,
        };
      });
  
      delay(1000).then(async () => {
        setShippingtOrderModal((prev) => {
          return {
            ...shippingOrderModal,
            ...shippingOrder,
          };
        });
  
        setInstanceState((prev) => {
          return {
            ...prev,
            createInstance: true,
            openModol: false,
            loadingCreateInstance: false,
          };
        });
  
        prevSetInstanceState((prev) => {
          return {
            ...prev,
            createInstance: true,
            message: "this Shipping has been successfully applied",
          };
        });
  
        delay(2000).then(async () => {
          setInstanceState((prev) => {
            return {
              ...prev,
              createInstance: false,
            };
          });
  
          prevSetInstanceState((prev) => {
            return {
              ...prev,
              createInstance: false,
              message: "",
            };
          });
        });
      });
    };
    return (
      <div>
        <p className="font-semibold capitalize   hover:underline">
          {t("Shipping")}
        </p>
  
        <ModalDialog
          open={instanceState.openModol}
          setOpen={(state) =>
            setInstanceState((prev) => {
              return {
                ...prev,
                openModol: state,
              };
            })
          }
          title={t("Add Shipping")}
          cancelTitle={t("Cancel")}
          cancel={() =>
            setInstanceState((prev) => {
              return {
                ...prev,
                openModol: false,
              };
            })
          }
          actionTitle={t("Apply")}
          action={() => addInstanceFormik.handleSubmit()}
          loadingAction={instanceState.loadingCreateInstance}
        >
          <form
            id="add-discount-modal"
            className="flex gap-5 flex-col md:w-[550px]"
          >
            <div className="">
              <RadioField
                checked={shippingOrder.shippingType === "free"}
                onChange={(e) => {
                  setShippingtOrder({
                    ...shippingOrder,
                    shippingType: e.target.value,
                  });
                }}
                value="free"
                name="radio-buttons-1"
                title={"Free shipping"}
              />
              <RadioField
                checked={shippingOrder.shippingType === "custom"}
                onChange={(e) => {
                  setShippingtOrder({
                    ...shippingOrder,
                    shippingType: e.target.value,
                  });
                }}
                value="custom"
                name="radio-buttons-2"
                title={"Custom"}
              />
  
              {shippingOrder.shippingType === "custom" && (
                <div className="flex gap-8 flex-col md:flex-row items-start mt-4">
                  <BasicInput
                    label="Rate Name"
                    type="text"
                    name="Rate"
                    placeholder={t("shipping name")}
                    id="rate"
                    value={shippingOrder.shippingRate}
                    onChange={(e) =>
                      setShippingtOrder({
                        ...shippingOrder,
                        shippingRate: e.target.value,
                      })
                    }
                    error={
                      addInstanceFormik.touched.shippingRate &&
                      Boolean(addInstanceFormik.errors.shippingRate)
                    }
                    helperText={
                      addInstanceFormik.touched.shippingRate &&
                      addInstanceFormik.errors.shippingRate
                    }
                  />
  
                  <BasicInput
                    label={t("Price")}
                    priceFormat
                    placeholder={t("10.00")}
                    value={shippingOrder?.shippingValue}
                    onChange={(e) =>
                      setShippingtOrder({
                        ...shippingOrder,
                        shippingValue: e.target.value,
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          fontSize="small"
                          position="end"
                          sx={{ color: bodyStyles?.inputText }}
                        >
                          <span className="text-xs">
                            {store?.currencyStore?.symbol}
                          </span>
                        </InputAdornment>
                      ),
                    }}
                    error={
                      addInstanceFormik.touched.shippingValue &&
                      Boolean(addInstanceFormik.errors.shippingValue)
                    }
                    helperText={
                      addInstanceFormik.touched.shippingValue &&
                      addInstanceFormik.errors.shippingValue
                    }
                  />
                </div>
              )}
            </div>
          </form>
        </ModalDialog>
      </div>
    );
  };
  

export default ShippingModal