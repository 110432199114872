import React, { useEffect } from "react";
import { useState } from "react";
import MapLeaflet from "../components/Maps/MapLeaflet";
import Layout from "../components/Layout";
import { delay } from "../utils/helpers";
import LoadingSpinner from "../components/LoadingSpinner";
import { ArrowLeftIcon } from "../components/Svg/MainIcons";
import { useStateContext } from "../contexts/ContextProvider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GET_SESSIONS } from "../graphql/query/analytics.query";
import { useQuery } from "@apollo/client";
import DateRange from "../components/DateRange";
const Live = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { bodyStyles } = useStateContext();
  const { t, i18n } = useTranslation();

  const [sessions, setSessions] = useState([]);

  let dateNow = new Date();
  const lastWeek = new Date(new Date().setDate(new Date().getDate() - 6));

  const [period, setPeriod] = useState({
    id: 3,
    label: "Last 7 days",
    startDate: lastWeek,
    endDate: dateNow,
  });

  const [dates, setDates] = useState({
    startDate: lastWeek,
    endDate: dateNow,
  });

  // const [sortBy, setSortBy] = useState({
  //   createdAt: period.startDate,
  //   updatedAt: period.endDate,
  // });

  const [sortBy, setSortBy] = useState({
    // createdAt: period.startDate,
    updatedAt: "DESC"
  });


  const {
    loading: loadingSessions,
    error,
    data,
    refetch,
  } = useQuery(GET_SESSIONS, {
    variables: {
      offset: 0,
      limit: 500,
      sortBy: sortBy,
    },
    fetchPolicy: "cache-and-network",
  });

  // useEffect(() => {
  //   setSortBy({
  //     ...sortBy,
  //     createdAt: period.startDate,
  //     updatedAt: period.endDate,
  //   });
  // }, [period]);

  const fetchEventes = async () => {
    setLoading(true);
    delay(800).then(async () => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchEventes();
  }, []);

  useEffect(() => {
    if (data?.sessions) {
      let newData = data?.sessions?.nodes?.map((item) => {
        return {
          id: item.id,
          title: item.id,
          description: item.id,
          geometries: [
            {
              type: "Point",
              coordinates: [item.longitude, item.latitude],
            },
          ],
        };
      });

      setSessions(newData);
    }
  }, [data]);

  const OrdersTest = [
    {
      id: "order_00",
      title: "Order 01",
      description: "order Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [-0.59041, 35.693887],
        },
      ],
    },

    {
      id: "order_01",
      title: "Oran Order 01",
      description: "order Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [-0.69041, 34.693887],
        },
      ],
    },

    {
      id: "order_02",
      title: "Djelfa Order 02",
      description: "order Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [2.918538, 34.953493],
        },
      ],
    },

    {
      id: "order_03",
      title: " Order 03",
      description: "order Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [13.34931899999998, 47.6975425],
        },
      ],
    },

    {
      id: "order_04",
      title: " Order 04",
      description: "order Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [0.574791, 48.603858],
        },
      ],
    },

    {
      id: "order_05",
      title: " Order 05",
      description: "order Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [0.574791, 50.603858],
        },
      ],
    },

    {
      id: "order_06",
      title: " Order 06",
      description: "order Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [1.574791, 22.603858],
        },
      ],
    },

    {
      id: "order_07",
      title: " Order 07",
      description: "order Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [0.574791, 48.603858],
        },
      ],
    },

    {
      id: "order_08",
      title: " Order 08",
      description: "order Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [0.574791, 28.603858],
        },
      ],
    },
  ];

  const sessionData = [
    {
      id: "session_00",
      title: "Order 01",
      description: "order Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [-0.59041, 37.693887],
        },
      ],
    },

    {
      id: "session_01",
      title: "session Order 01",
      description: "session Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [44.69041, 34.693887],
        },
      ],
    },

    {
      id: "session_02",
      title: "Djelfa session 02",
      description: "session Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [22.918538, 11.953493],
        },
      ],
    },

    {
      id: "session_03",
      title: " session 03",
      description: "session Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [14.34931899999998, 27.6975425],
        },
      ],
    },

    {
      id: "session_04",
      title: " session 04",
      description: "session Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [10.574791, 48.603858],
        },
      ],
    },

    {
      id: "session_05",
      title: " session 05",
      description: "session Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [-2.574791, 8.603858],
        },
      ],
    },

    {
      id: "session_06",
      title: " session 06",
      description: "session Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [4.574791, 26.603858],
        },
      ],
    },

    {
      id: "session_07",
      title: " session 07",
      description: "session Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [2.574791, 43.603858],
        },
      ],
    },

    {
      id: "session_08",
      title: " session 08",
      description: "session Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [1.574791, 22.603858],
        },
      ],
    },
    {
      id: "session_09",
      title: " session 09",
      description: "session Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [1.874791, 22.803858],
        },
      ],
    },
    {
      id: "session_10",
      title: " session 10",
      description: "session Detials",
      geometries: [
        {
          type: "Point",
          coordinates: [1.974791, 23.803858],
        },
      ],
    },
  ];

  return (
    <Layout>
      <div className="">
        <div className="flex w-full justify-between gap-4 pb-3 ">
          <div
            className="flex w-full items-center gap-2"
            style={{
              color: bodyStyles?.title ? bodyStyles?.title : "#fff",
            }}
          >
            <div
              className={`w-10 cursor-pointer rounded-full
                        p-1 duration-300 hover:bg-[rgba(0,0,0,0.089)] ${
                          i18n.language === "ar" && "rotate-180 "
                        }`}
              onClick={() => navigate("/")}
            >
              <ArrowLeftIcon color={bodyStyles?.text} />
            </div>
            <div className="flex items-center gap-x-1">
              <h1 className="text-xl font-semibold uppercase  ">
                {t("live view")}{" "}
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="mb-2 flex justify-start">
        <DateRange
          initialPeriod={period}
          setInitPeriod={setPeriod}
          dates={dates}
          setDates={setDates}
        />
      </div> */}

      <div className="h-[82vh] ">
        {loading || loadingSessions ? (
          <div className="flex h-[80vh] w-full items-center justify-center ">
            <LoadingSpinner size={35} />
          </div>
        ) : (
          <MapLeaflet
            center={[20.918538, 34.953493]}
            eventData={OrdersTest}
            sessionData={sessionData}
            sessions={sessions}
          />
        )}
      </div>
    </Layout>
  );
};

export default Live;