import { client } from '../global/apolloClient';

import {
  CREATE_USER,
  DELETE_USER,
  SET_ASSIGNMENTS,
  UPDATE_STAFF,
  UPDATE_USER,
} from '../graphql/mutation/user.mutation';
import {
  GET_ALL_USERS,
  GET_USER,
  GET_USER_BY_TOKEN,
} from '../graphql/query/users.query';

import {
  assignRolesFail,
  assignRolesStart,
  assignRolesSuccess,
  createUserFail,
  createUserStart,
  createUserSuccess,
  deleteUserFail,
  deleteUserStart,
  deleteUserSuccess,
  deleteUsersFail,
  deleteUsersStart,
  deleteUsersSuccess,
  editUserFail,
  editUserStart,
  editUserSuccess,
  fetchUsersFail,
  fetchUsersStart,
  fetchUsersSuccess,
  importFail,
  importStart,
  importSuccess,
  removeAll,
} from '../store/features/user/userSlicer';
import {
  showErrorMessage,
  showErrorModalMessage,
  showErrorSettingMessage,
  showSuccessMessage,
  showSuccessModalMessage,
  showSuccessSettingMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation, resteCache } from './executeMutation';

import { CREATE_STAFF } from '../graphql/mutation/auth.mutation';
import { constants } from '../constants';

const token = getTheTokenFromStorage();

let initVariables;

export const createUser = async (values, accessToken) => {
  return await executeMutation(
    CREATE_USER,
    [GET_ALL_USERS, GET_USER],
    values,
    accessToken
  );
};

export const createStaff = async (values, accessToken) => {
  return await executeMutation(
    CREATE_STAFF,
    [GET_ALL_USERS],
    values,
    accessToken
  );
};

export const editUser = async (values, accessToken) => {
  return await executeMutation(
    UPDATE_USER,
    [GET_ALL_USERS, GET_USER, GET_USER_BY_TOKEN],
    values,
    accessToken
  );
};

export const deleteUser = async (values, accessToken) => {
  return await executeMutation(
    DELETE_USER,
    [GET_ALL_USERS, GET_USER],
    values,
    accessToken
  );
};

export const deleteManyUser = async (values, accessToken) => {
  return await executeMutation(
    DELETE_USER,
    [GET_ALL_USERS, GET_USER],
    values,
    accessToken
  );
};

export const fetchUsers = (variables) => {
  initVariables = variables;
  return async (dispatch) => {
    try {
      dispatch(fetchUsersStart());
      const { data } = await client.query({
        query: GET_ALL_USERS,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchUsersSuccess(data));
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const handleDeleteUser = (id) => {
  return async (dispatch) => {
    dispatch(deleteUserStart());
    const dataSent = {
      blockStaffAccountId: parseInt(id),
    };
    try {
      let res = await deleteUser(dataSent, token);
      if (res?.data?.blockStaffAccount) {
        dispatch(deleteUserSuccess(id));
        dispatch(showSuccessSettingMessage('User deleted successfully'));

        resteCache('users');
      } else {
        dispatch(deleteUserFail(res));
        dispatch(showErrorSettingMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(showErrorSettingMessage(error));
    }
  };
};

export const handleDeleteManyUser = (ids) => {
  return async (dispatch) => {
    dispatch(deleteUsersStart());
    const dataSent = {
      removeUserId: ids.map((id) => parseInt(id)),
    };
    try {
      let res = await deleteManyUser(dataSent, token);
      if (res?.data?.removeUsers) {
        dispatch(showSuccessSettingMessage(ids));
        dispatch(showSuccessMessage('Users deleted successfully'));
      } else {
        dispatch(deleteUsersFail(res));
        dispatch(showErrorSettingMessage(res));
      }
    } catch (error) {
      dispatch(showErrorSettingMessage(error));
      dispatch(deleteUsersFail(error));
    }
  };
};



export const handleCreateStaff = (values, callback) => {
  return async (dispatch) => {
    dispatch(createUserStart());

    const dataSent = {
      input: {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        type: 'STAFF',
      },
    };

    try {
      let res = await createStaff(dataSent, token);
      if (res?.data) {
        dispatch(
          createUserSuccess({
            id: res?.data?.createAccount?.id,
            email: values.email,
            customer: {
              firstName: values.firstName,
              lastName: values.lastName,
            },
          })
        );
        dispatch(
          showSuccessModalMessage('this Staff has been successfully created')
        );
        resteCache('users');
      } else {
        dispatch(createUserFail(res));
        dispatch(showErrorModalMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(createUserFail(error));
      dispatch(showErrorModalMessage(error));
      callback && callback(error);
    }
  };
};

export const handleUpdateStaff = (values, callback) => {
  return async (dispatch) => {
    dispatch(editUserStart());

    const dataSent = {
      input: {
        id: +values.id,
        firstName: values.firstName,
        lastName: values.lastName,
        type: 'STAFF',
      },
    };

    try {
      let res = await updateStaff(dataSent, token);
      if (res?.data) {
        dispatch(
          editUserSuccess({
            email: values.email,
            customer: {
              firstName: values.firstName,
              lastName: values.lastName,
            },
          })
        );
        dispatch(
          showSuccessModalMessage('this Staff has been successfully updated')
        );
        resteCache('users');
      } else {
        dispatch(editUserFail(res));
        dispatch(showErrorModalMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(editUserFail(error));
      dispatch(showErrorModalMessage(error));
      callback && callback(error);
    }
  };
};

export const handleAssignRoles = (values, callback) => {
  return async (dispatch) => {
    dispatch(assignRolesStart());

    let rolesSent = values.roles.map((product) => {
      return +product.id;
    });

    const dataSent = {
      assignRolesInput: {
        user: values?.id,
        roles: rolesSent,
      },
    };

    try {
      let res = await setAssignments(dataSent, token);
      if (res?.data) {
        dispatch(assignRolesSuccess(res));
        dispatch(
          showSuccessSettingMessage('rolles has been successfully Assignment')
        );
      } else {
        dispatch(assignRolesFail(res));
        dispatch(showErrorSettingMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(assignRolesFail(error));
      dispatch(showErrorSettingMessage(error));
      callback && callback(error);
    }
  };
};

export const handleCreateUser = (values, callback) => {
  return async (dispatch) => {
    dispatch(createUserStart());
    let translationDataSent = values.translationData
      ? Object.keys(values.translationData).map((key) => {
          return {
            name: values.translationData[key].name,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      input: {
        translationData: translationDataSent,
      },
    };

    try {
      const res = await createUser(dataSent, token);
      if (res?.data?.createUser) {
        dispatch(createUserSuccess(res?.data?.createUser));
        dispatch(showSuccessMessage('this User has been successfully created'));
      } else {
        dispatch(createUserFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(createUserFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleEditUser = (values, callback) => {
  return async (dispatch) => {
    dispatch(editUserStart());

    let translationDataSent = values.translationData
      ? Object.keys(values.translationData).map((key) => {
          return {
            name: values.translationData[key].name,
            description: values.translationData[key].description,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      input: {
        id: values.id,
        translationData: translationDataSent,
      },
    };

    try {
      let res = await editUser(dataSent, token);
      if (res?.data?.updateUser) {
        dispatch(editUserSuccess(res?.data?.updateUser));
        dispatch(showSuccessMessage('this User has been successfully updated'));

        if (callback) {
          callback(res?.data?.updateUser.id);
        }
      } else {
        dispatch(editUserFail(res));
        dispatch(showErrorMessage(res));
        if (callback) {
          callback(res);
        }
      }
    } catch (error) {
      dispatch(editUserFail(error));
      dispatch(showErrorMessage(error));
      if (callback) {
        callback(error);
      }
    }
  };
};

export const handleImportUsers = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyUser(dataSent, token);
      if (res?.data?.removeUsers) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Users Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteUsersFail(error));
      });
    }
  };
};

export const handleExportUsers = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyUser(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllUsers = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyUser(dataSent, token);
    } catch (error) {}
  };
};

export const updateUser = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: UPDATE_USER,
      variables: {
        ...values,
      },
      context: { accessToken },
      refetchQueries: [GET_USER_BY_TOKEN],
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const updateStaff = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: UPDATE_STAFF,
      variables: {
        ...values,
      },
      context: { accessToken },
      refetchQueries: [GET_ALL_USERS],
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const setAssignments = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: SET_ASSIGNMENTS,
      variables: {
        ...values,
      },
      context: { accessToken },
      refetchQueries: [GET_ALL_USERS],
    });

    return result;
  } catch (error) {
    return error.message;
  }
};
