import React from "react";
import CustomButton from "../../../Buttons/CustomButton";
import { TrashIcon } from "../../../Svg/MainIcons";
import { useStateContext } from "../../../../contexts/ContextProvider";
import { useTranslation } from "react-i18next";
const Cookies = () => {
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  return (
    <div className="w-full ">
      <div className="flex w-full flex-row items-center justify-center gap-x-8">
        <span
          style={{
            color: bodyStyles?.subTitle ? bodyStyles?.subTitle : "#888888",
          }}
          className="w-full text-[#888888]"
        >
          {t("Clear cache and cookies")}
        </span>
        <div className="flex w-full items-end justify-end">
          <CustomButton className=" flex flex-row items-center justify-center gap-x-4 rounded-lg px-8 py-3 text-base">
            <TrashIcon color={bodyStyles?.filedButtonText} />
            <span style={{ color: bodyStyles?.filedButtonText }}>
              {t("Clear")}
            </span>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
