import { gql } from "@apollo/client";

export const GET_CURRENT_SESSIONS = gql`
  query Query {
    currentSessions
  }
`;

export const GET_SESSIONS = gql`
query Nodes($offset: Int!, $limit: Int!, $sortBy: SortByFilterArgs) {
  sessions(offset: $offset, limit: $limit, sortBy: $sortBy) {
    nodes {
      id
      createdAt
      updatedAt
      sessionId
      country
      city
      timezone
      browser
      platform
      os
      latitude
      longitude
      lastAccess
      __typename
    }
    totalCount
    hasNextPage
    __typename
  }
}
`;


export const TOTAL_SALES_PER_DATE = gql`
  query StockMovementTotalSalesPerDate($date: DateTime!) {
    stockMovementTotalSalesPerDate(date: $date) {
      totalSales
      salesNumber
    }
  }
`;