import React, { useEffect, useState } from 'react';
import BasicInput from '../../../Inputs/BasicInput';
import CustomButton from '../../../Buttons/CustomButton';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { SaveIcon } from '../../../Svg/MainIcons';
import { useTranslation } from 'react-i18next';
import RtlConversion from '../../../RtlConversion';
import { CircularProgress } from '@mui/material';
import { getTheTokenFromStorage } from '../../../../services/auth';
import { useQuery } from '@apollo/client';
import { GET_USER_BY_TOKEN } from '../../../../graphql/query/users.query';
import { updateUser } from '../../../../services/user';
import { useDispatch } from 'react-redux';
import { showErrorSettingMessage, showSuccessSettingMessage } from '../../../../store/features/snackbar/snackbarSlice';

const Profile = () => {
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  const dispatch = useDispatch();
  const token = getTheTokenFromStorage();
  const [loading, setLoading] = useState({
    profileInformation: false,
  });

  const {
    loading: loadingUser,
    error: errorUser,
    refetch: refetchUser,
    data: UserData,
  } = useQuery(GET_USER_BY_TOKEN, {
    fetchPolicy: 'cache-and-network',
  });

  const [fields, setFields] = useState({
    name: '',
    lastName: '',
    email: '',
    phone: '',
    AboutYou: '',
    webSite: '',
  });

  useEffect(() => {
    if (UserData?.getUserAccount) {
      setFields((prevState) => ({
        ...prevState,
        name: UserData?.getUserAccount?.customer?.firstName,
        lastName: UserData?.getUserAccount?.customer?.lastName,
        email: UserData?.getUserAccount?.email,
        phone: UserData?.getUserAccount?.customer?.phoneNumber || "",
      }));
    }
  }, [UserData]);

 const profileValidation = Yup.object().shape({
    name: Yup.string().required(t('This Field is required')),
    lastName: Yup.string().required(t('This Field is required')),
    email: Yup.string()
      .email(t('Invalid email'))
      .required(t('This Field is required')),
    phone: Yup.string()
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: fields?.name,
      lastName: fields?.lastName,
      email: fields?.email,
      phone: fields?.phone,
    },
    validationSchema: profileValidation,
    onSubmit: async (values) => {
      createProfileInformation(values);
    },
  });

  const createProfileInformation = async (values) => {
    setLoading({ ...loading, profileInformation: true });
  
    const dataSent = {
      input: {
        firstName: values.name,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phone,
      },
    };

    const res = await updateUser(dataSent, token);

    res.data
      ? dispatch(
          showSuccessSettingMessage(
            'Account Information has been successfully updated'
          )
        )
      : dispatch(showErrorSettingMessage(res));
    setLoading({ ...loading, profileInformation: false });

  };

  return (
    <RtlConversion rtl={i18n.language === 'ar' ? true : false}>
      <div className='flex w-full flex-col items-start justify-start'>
        <h1
          style={{ color: bodyStyles?.title ? bodyStyles?.title : '#808080' }}
          className='text-[19px] font-[600] uppercase text-[#808080]'
        >
          {t('Profile')}
        </h1>
        <div className='flex w-full flex-row items-start justify-center'>
          <div className='flex w-full flex-col items-center justify-center gap-y-4  py-8'>
            <div className='grid w-full grid-cols-1 gap-x-20 gap-y-4 md:grid-cols-2'>
              <BasicInput
                label={t('Name')}
                placeholder='e.g. john smith'
                value={fields?.name}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    name: e.target.value,
                  });
                }}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                InputProps={{
                  style: { fontSize: '12px' },
                }}
              />
              <BasicInput
                label={t('Last Name')}
                placeholder='e.g. john smith'
                value={fields?.lastName}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    lastName: e.target.value,
                  });
                }}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                InputProps={{
                  style: { fontSize: '12px' },
                }}
              />
              <BasicInput
                label={t('Email')}
                placeholder='e.g. johnsmith@smartinia.com'
                value={fields?.email}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    email: e.target.value,
                  });
                }}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  style: { fontSize: '12px' },
                }}
              />
              <BasicInput
                label={t('Phone Number') + t('(optional)')}
                placeholder='0554260312'
                value={fields?.phone}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    phone: e.target.value,
                  });
                }}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                InputProps={{
                  style: { fontSize: '12px' },
                }}
              />
            </div>
          
          </div>
        </div>
        <div className='flex w-full items-end justify-end'>
          <CustomButton
            onClick={() => formik.submitForm()}
            className=' flex flex-row items-center justify-center gap-x-4 rounded-lg px-8 py-3 text-base'
          >
            <SaveIcon color={bodyStyles?.filedButtonText} />
            <span style={{ color: bodyStyles?.filedButtonText }}>
              {loading?.profileInformation ? (
                <CircularProgress
                  style={{
                    width: 15,
                    height: 15,
                    color: bodyStyles?.filedButtonText,
                  }}
                />
              ) : (
                t('Save')
              )}
            </span>
          </CustomButton>
        </div>
      </div>
    </RtlConversion>
  );
};

export default Profile;
