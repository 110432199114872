import React, { useEffect, useState } from 'react';
import { FormControl } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import BasicInput from '../../components/Inputs/BasicInput';
import { useTranslation } from 'react-i18next';
import BottomBanner from '../../components/Actions/BottomBanner';
import PageModal from '../../components/PageModal';
import { useQuery } from '@apollo/client';
import { initInstanceState } from '../../utils/initStates';
import BasicSelectMui from '../../components/Inputs/BasicSelectMui';
import { handleEditCustomer } from '../../services/customer';
import { GET_CUSTOMER } from '../../graphql/query/customer.query';
import ModalNotFound from '../../components/MessageModals/ModalNotFound';
import { customerValidation } from '../../validations/customer.validation';
import TabsScreens from '../../components/Account/TabsScreens';
import DataGridOrders from '../../components/Account/DataGridOrders';
import DataGridCheckouts from '../../components/Account/DataGridCheckouts';
import { useDispatch, useSelector } from 'react-redux';

const EditCustomer = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { bodyStyles } = useStateContext();
  const [instanceState, setInstanceState] = useState(initInstanceState);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.customer);

  const { editUserSchema } = customerValidation();

  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    phoneNumber: '',
    password: '',
    role: 'user',
  });

  useEffect(() => {
    setInstanceState((prev) => {
      return { ...prev, id: +params?.id };
    });
  }, [params]);

  const {
    loading: loadingInstance,
    error: errorInstance,
    refetch: refetchInstance,
    data: instanceData,
  } = useQuery(GET_CUSTOMER, {
    variables: {
      customerId: instanceState.id,
    },
    skip: instanceState.id ? false : true,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (instanceData?.Customer) {
      setInstanceState((prev) => {
        return { ...prev, existe: true };
      });

      setFields((prev) => {
        return {
          ...prev,
          firstName: instanceData?.Customer?.firstName,
          lastName: instanceData?.Customer?.lastName,
          email: instanceData?.Customer?.email,
          phoneNumber: instanceData?.Customer?.phoneNumber
            ? instanceData?.Customer?.phoneNumber
            : '',
          password: instanceData?.Customer?.password
            ? instanceData?.Customer?.password
            : '',
        };
      });
    }
  }, [instanceData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: fields.firstName,
      lastName: fields.lastName,
      email: fields.email,
      phoneNumber: fields.phoneNumber,
      password: fields.password,
      role: fields.role,
      id: instanceState.id,
    },
    validationSchema: editUserSchema,
    onSubmit: async (values) => {
      dispatch(handleEditCustomer(values, () => {}));
    },
  });

  return (
    <PageModal
      pageName={t('Edit Customer')}
      pagePrev={`/customers`}
      loading={loadingInstance}
    >
      <BottomBanner
        btn01='Cancel'
        btn01Action={() => navigate(`/customers`)}
        btn02='save Customer'
        btn02Action={() => formik.handleSubmit()}
        loading={data.loadingEdit}
      />
      {instanceData && !errorInstance ? (
        <form id='product' className=' font-Inter relative'>
          <div className='min-h-[83vh] flex flex-col justify-between'>
            <div>
              <div className='flex gap-5 flex-col md:flex-row  '>
                <div className='w-[100%]  space-y-6 '>
                  <div className=' space-y-2  rounded-lg  '>
                    <div className='space-y-6 font-urbanist '>
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-10 px-2 sm:px-0'>
                        <div className='space-y-4 col-span-2  '>
                          <div
                            className='font-Inter text-xl pt-7 pb-5 uppercase font-bold flex items-center justify-start'
                            style={{
                              color: bodyStyles.subTitle,
                            }}
                          >
                            <span>{t('Customer Overview')}</span>
                          </div>
                          <div className='flex flex-col md:flex-row  gap-4'>
                            <BasicInput
                              label={t('FirstName')}
                              placeholder='Amine'
                              value={fields?.firstName}
                              onChange={(e) =>
                                setFields({
                                  ...fields,
                                  firstName: e.target.value,
                                })
                              }
                              error={
                                formik.touched.firstName &&
                                Boolean(formik.errors.firstName)
                              }
                              helperText={
                                formik.touched.firstName &&
                                formik.errors.firstName
                              }
                            />

                            <BasicInput
                              label={t('LastName')}
                              placeholder='Amine'
                              value={fields?.lastName}
                              onChange={(e) =>
                                setFields({
                                  ...fields,
                                  lastName: e.target.value,
                                })
                              }
                              error={
                                formik.touched.lastName &&
                                Boolean(formik.errors.lastName)
                              }
                              helperText={
                                formik.touched.lastName &&
                                formik.errors.lastName
                              }
                            />
                          </div>

                          <BasicInput
                            label={t('email')}
                            placeholder='cust_name@email.com'
                            value={fields?.email}
                            onChange={(e) =>
                              setFields({ ...fields, email: e.target.value })
                            }
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                            helperText={
                              formik.touched.email && formik.errors.email
                            }
                          />

                          <BasicInput
                            label={t('Phone Number')}
                            type='tel'
                            placeholder='0554260312'
                            value={fields?.phoneNumber}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                phoneNumber: e.target.value,
                              });
                            }}
                            error={
                              formik.touched.phoneNumber &&
                              Boolean(formik.errors.phoneNumber)
                            }
                            helperText={
                              formik.touched.phoneNumber &&
                              formik.errors.phoneNumber
                            }
                          />
                        </div>

                        {/* stauts Column */}
                        <div className='space-y-4 col-span-1 w-full  '>
                          <div
                            className='font-Inter text-xl pt-7 pb-5
                  uppercase font-bold flex items-center justify-start'
                            style={{
                              color: bodyStyles.subTitle,
                            }}
                          >
                            <span>{t('Customer Status')}</span>
                          </div>

                          <FormControl fullWidth>
                            <BasicSelectMui
                              type='text'
                              disabled={true}
                              name='role'
                              id='user-role-select'
                              label={t('Role')}
                              value={fields.role}
                              onChange={(e) =>
                                setFields({ ...fields, role: e.target.value })
                              }
                              error={
                                formik.touched.role &&
                                Boolean(formik.errors.role)
                              }
                              options={[
                                { value: 'user', label: t('User') },
                                { value: 'admin', label: t('Admin') },
                                { value: 'staff', label: t('Staff') },
                                { value: 'seller', label: t('Seller') },
                              ]}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <section className='w-full min-h-[50vh] mt-11'>
                  <TabsScreens screens={['Orders', 'Abandoned Checkouts']}>
                    <div key={0} className='w-full'>
                      <DataGridOrders idCustomer={instanceData?.Customer?.id} />
                      {/* DataGridOrders */}
                    </div>
                    <div key={1} className='w-full'>
                      <DataGridCheckouts
                        idCustomer={instanceData?.Customer?.id}
                      />
                    </div>
                  </TabsScreens>
                </section>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <ModalNotFound
          message='Item Not Found.'
          returnMessage='Return to Collections page'
          action={() => navigate(`/customers`)}
        />
      )}
    </PageModal>
  );
};

export default EditCustomer;
