import { gql } from "@apollo/client";

export const GET_ALL_ORDERS = gql`
query Orders(
  $offset: Int
  $limit: Int
  $where: FilterOrderArgs
  $sortBy: SortByFilterArgs
) {
  orders(
    offset: $offset
    limit: $limit
    where: $where
    sortBy: $sortBy
  ) {
    nodes {
      id
      canceled_at
      updatedAt
      subTotal
      status
      customer {
        firstName
        id
        email
        createdAt
        lastName
        phoneNumber
        updatedAt
     
      }
     
      createdAt
      currencyCode
      order_code
     
    }
    hasNextPage
    totalCount
  }
}

`;

export const GET_ORDER = gql`
  query Order($orderId: Float!, $join: OrderRelationsArgs) {
    order(id: $orderId, join: $join) {
      id
      shippingStatus
      discount {
        max_usage
        name
        perCustomerUsageLimit
        remaining
        percentage
        status
        startsAt
        id
        fixedAmount
        endsAt
        couponCode
        createdAt
        updatedAt
      }
      canceled_at
      checkout {
        createdAt
        id
        email
        deletedAt
        paymentMethod
        paymentStatus
        phoneNumber
        total
        updatedAt
      }
      createdAt
      currencyCode
      customer {
        addresses {
          apartment
          city
          company
          country {
            translationData {
              createdAt
              description
              id
              languageCode
              name
              slug
              updatedAt
            }
            code
            createdAt
            id
            isEnabled
          }
          createdAt
          deletedAt
          fullName
          id
          isDefaultShippingAddress
          isDefaultBillingAddress
          phoneNumber
          postalCode
          province
          streetLine1
          streetLine2
          updatedAt
        }
        createdAt
        email
        firstName
        id
        lastName
        phoneNumber
        updatedAt
      }
      order_code
      shippingLine {
        trackingNumber
        shippingCompanyName
        shippingMethod {
          id
          translationData {
            createdAt
            description
            id
            languageCode
            name
            rateName
          }
          currency_code
          default_price
        }
        billingAddress {
          apartment
          city
          id
          createdAt
          updatedAt
          deletedAt
          fullName
          company
          streetLine1
          streetLine2
          phoneNumber
          postalCode
          province
          isDefaultShippingAddress
          isDefaultBillingAddress
          country {
            code
            createdAt
            id
            isEnabled
            translationData {
              id
              createdAt
              updatedAt
              name
              slug
              description
              languageCode
            }
            updatedAt
          }
        }
        createdAt
        deletedAt
        id
        shippingAddress {
          id
          createdAt
          updatedAt
          deletedAt
          fullName
          company
          streetLine1
          streetLine2
          apartment
          phoneNumber
          postalCode
          city
          province
          isDefaultShippingAddress
          isDefaultBillingAddress
          country {
            code
            createdAt
            id
            isEnabled
            translationData {
              id
              createdAt
              updatedAt
              name
              slug
              description
              languageCode
            }
          }
        }
        shippingCost
      }
      status
      subTotal

      updatedAt
      orderLine {
        createdAt
        id
        quantity
        price
        updatedAt
        variant {
          id
          deletedAt
          currencyCode
          createdAt
          compareAtPrice
          isEnabled
          media {
            alt
            createdAt
            deletedAt
            id
            updatedAt
            original_name
            file_name
            path
            host
            full_url
            type
          }
          options {
            group {
              id
              createdAt
              updatedAt
              values {
                id
                createdAt
                updatedAt
                group {
                  id
                  createdAt
                  updatedAt
                }
                translationData {
                  id
                  createdAt
                  updatedAt
                  name
                  slug
                  description
                  languageCode
                }
              }
              translationData {
                id
                createdAt
                updatedAt
                name
                slug
                description
                languageCode
              }
            }
            id
            translationData {
              id
              createdAt
              updatedAt
              name
              slug
              description
              languageCode
            }
            updatedAt
            createdAt
          }
          outOfStockThreshold
          pricing
          sku
          stockAllocated
          stockOnHand
          updatedAt
          product {
            id
            translationData {
              id
              createdAt
              updatedAt
              name
              slug
              description
              languageCode
            }
            media {
              alt
              createdAt
              deletedAt
              id
              updatedAt
              original_name
              file_name
              path
              host
              full_url
              type
            }
          }
        }
      }
      cart {
        id
        cartItems {
          createdAt
          id
          updatedAt
          quantity
          variant {
            id
            deletedAt
            currencyCode
            createdAt
            compareAtPrice
            isEnabled
            media {
              alt
              createdAt
              deletedAt
              id
              updatedAt
              original_name
              file_name
              path
              host
              full_url
              type
            }

            options {
              id
              updatedAt
              createdAt
              translationData {
                id
                description
                createdAt
                languageCode
                name
                slug
              }
              group {
                createdAt
                id
                translationData {
                  createdAt
                  description
                  id
                  languageCode
                  name
                  slug
                  updatedAt
                }
              }
            }
          }
        }
      }
    }
  }
`;


