import React from 'react';

const Testt = () => {
  const trowError = () => {
    throw new Error('test Sent Error ==========>');
  };
  return (
    <div>
      <div
        className='p-2 m-2 w-[200px] h-[200px] border cursor-pointer bg-[#ccc] flex justify-center items-center text-2xl'
        onClick={() => trowError()}
      >
        Click Me
      </div>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui tempore
      temporibus itaque pariatur nulla dolores maiores? Est, quae repellendus
      incidunt, amet possimus cumque eligendi, ipsum perferendis adipisci eaque
      laborum impedit?
    </div>
  );
};

export default Testt;
