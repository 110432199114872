import React from 'react';
import ModalDialog from '../../ModalDialog';
import { useTranslation } from 'react-i18next';

const DeleteOne = ({ data, dataPage, setOpen, action }) => {
  const { t } = useTranslation();
  return (
    <ModalDialog
      open={data?.deleteModal}
      setOpen={setOpen}
      title={t(dataPage?.deleteInstanceTitle)}
      cancelTitle={t('Cancel')}
      cancel={() => setOpen()}
      actionTitle={t(dataPage?.deleteInstanceActionTitle)}
      action={() => action()}
      loadingAction={data?.loadingDelete}
      className='p-0 m-0'
    >
      <div className='rounded-md custom-border-color p-1 shadow-custom md:width-600 space-y-3 font-urbanist'>
        <p className='font-semibold '>
          {t(dataPage.deleteInstanceDescription)}
          <span className='font-bold'> {data?.selectedOne?.id} </span>
        </p>
      </div>
    </ModalDialog>
  );
};

export default DeleteOne;
