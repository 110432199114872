import * as React from "react";
import TextField from "@mui/material/TextField";
import { useStateContext } from "../../contexts/ContextProvider";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

const RadioGroupType = ({ value, onChange, options, gapY, ...rest }) => {
  const { bodyStyles } = useStateContext();
  const { t, i18n } = useTranslation();
  return (
    <RadioGroup
      name="controlled-radio-buttons-group-nn"
      value={value}
      onChange={onChange}
      
    >
      {options &&
        options.map((item, index) => (
          <FormControlLabel
          key={index}
            value={item.value}
            control={
              <Radio
                sx={{
                  "&, &.Mui-checked": {
                    color: bodyStyles?.primary,
                  },
                }}
              />
            }
            label={
              <div
                className={`flex flex-col ${
                  i18n.language === "ar" ? "pr-1" : "pl-1"
                }`}
              >
                <h5 className="font-[600] ">{t(item.label)}</h5>

                {item?.elemnt && item?.elemnt()}
              </div>
            }
            style={{marginBottom: gapY}}
          />
        ))}
    </RadioGroup>
  );
};

export default RadioGroupType;
