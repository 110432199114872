import { gql } from '@apollo/client';

export const EDIT_SETTING = gql`
  mutation UpdateSetting($updateSettingInput: UpdateSettingInput!) {
    updateSetting(updateSettingInput: $updateSettingInput) {
      createdAt
      deletedAt
      id
      logo
      setting_account {
        bio
        createdAt
        deletedAt
        email
        id
        last_name
        name
        phone_number
        updatedAt
        website
      }
      setting_checkout {
        address_line
        company_name
        id
        createdAt
        updatedAt
        deletedAt
        customer_accounts
        email
        phone_number
        shop_app_link
        last_name
        last_first
        shipping_address
      }
      setting_general {
        id
        createdAt
        updatedAt
        deletedAt
        store_name
        store_name_legal
        store_industry
        country
        apartment
        city
        address
        postal_code
        email
        phone_number
      }
      setting_online {
        id
        createdAt
        updatedAt
        deletedAt
        google_track_id
        google_deliverability_rate
        google_conversion_type
        analytics_script_head
        fb_pixel
        fb_deliverability_rate
        fb_conversion_type
        snapchat_pixel
        snapchat_deliverability_rate
        snapchat_conversion_type
        tiktok_pixel
        tiktok_deliverability_rate
        tiktok_conversion_type
      }
      setting_payment {
        id
        createdAt
        updatedAt
        deletedAt
        stripe_pk
        stripe_sk
        stripe_wh
        paypal_pk
        paypal_sk
      }

      setting_email {
        awsses_key
        awsses_secret
        createdAt
        deletedAt
        from_email
        postmark_key
        sendgrid_key
        updatedAt
        id
      }

      updatedAt
      website
    }
  }
`;
