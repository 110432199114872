import React, { useState } from 'react';
import EditShippedModal from './EditShippedModal';
import { Menu, MenuItem } from '@mui/material';
import EditShippingStatusModal from './EditShippingStatusModal';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../contexts/ContextProvider';
import { defaultDatat } from '../../../utils/helpers';
import BasicButtonWithIcon from '../../Buttons/BasicButtonWithIcon';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import InfoIcon from '@mui/icons-material/Info';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import { useDispatch } from 'react-redux';
import {
  openModelEditShipped,
  openModelEditShipping,
} from '../../../store/features/order/orderSlicer';
const ShippingCard = ({ order }) => {
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  const dispatch = useDispatch();

  const [openShippingAddressTooltip, setOpenShippingAddressTooltip] =
    useState(false);

  return (
    <div className='flex flex-col gap-4 '>
      <div className='flex justify-between items-center'>
        <div
          className='font-Inter text-xl uppercase font-bold flex items-center justify-start'
          style={{
            color: bodyStyles.subTitle,
          }}
        >
          <span>{t('Shipping')}</span>
        </div>

        <div>
          <EditShippingStatusModal data={order} />

          <Menu
            anchorEl={openShippingAddressTooltip}
            id='account-menu'
            open={openShippingAddressTooltip}
            onClose={() => setOpenShippingAddressTooltip(null)}
            onClick={() => setOpenShippingAddressTooltip(null)}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
          >
            <MenuItem onClick={() => dispatch(openModelEditShipping())}>
              <div className='min-w-[100px] '>{t('Edit Shipping status')}</div>
            </MenuItem>
          </Menu>
        </div>
      </div>

      <div className='flex flex-col gap-2 mb-4'>
        <div className='drop-shadow-xl rounded-lg border  p-2 flex flex-col gap-1.5'>
          <div className='flex gap-1'>
            <span className='w-[30%] font-semibold'>{t('Status')}</span>
            <span className=''>
              {!order?.shippingLine?.shippingMethod ? (
                order?.shippingStatus === 'NOT_PICKED' ? (
                  <div
                    className='py-1 px-4 bg-[#FFE3E3] text-[#EB5757] bg-opacity-30
                          text-sm text-center font-extrabold rounded-md font-Inter '
                  >
                    {t('NOT PICKED')}
                  </div>
                ) : order?.shippingStatus === 'READY_TO_PICK' ? (
                  <div
                    className='py-1 px-4 bg-[#4caf50] text-[#4caf50] bg-opacity-30
                      text-sm text-center font-extrabold rounded-md font-Inter '
                  >
                    {t('READY TO PICK UP')}
                  </div>
                ) : (
                  order?.shippingStatus === 'PICKED' && (
                    <div
                      className='py-1 px-4 bg-[#4caf50] text-[#4caf50]  bg-opacity-30
                  text-sm text-center font-extrabold rounded-md font-Inter '
                    >
                      {t('PICKED')}
                    </div>
                  )
                )
              ) : order?.shippingStatus === 'SHIPPED' ? (
                <div
                  className='py-1 px-4 bg-[#4caf50] text-[#4caf50] bg-opacity-30
                          text-sm text-center font-extrabold rounded-md font-Inter '
                >
                  {t('Shipped')}
                </div>
              ) : (
                <div
                  className='py-1  bg-[#FFE3E3] text-[#EB5757] bg-opacity-30
                          text-sm text-center font-extrabold rounded-md font-Inter px-4'
                >
                  {t('Not Shipped')}
                </div>
              )}
            </span>
          </div>

          <div className='flex gap-1 pt-2'>
            <span className='w-[30%] font-semibold'>{t('Name')}</span>
            <span className=''>
              {order?.shippingLine?.shippingAddress?.fullName
                ? order?.shippingLine?.shippingAddress?.fullName
                : '-'}
            </span>
          </div>

          <div className='flex gap-1'>
            <span className='w-[30%] font-semibold'>{t('Address')}</span>
            <span className=''>
              {order?.shippingLine?.shippingAddress?.streetLine1
                ? order?.shippingLine?.shippingAddress?.streetLine1
                : '-'}
              <br />
              {order?.shippingLine?.shippingAddress?.streetLine2
                ? order?.shippingLine?.shippingAddress?.streetLine2
                : ''}
            </span>
          </div>

          <div className='flex gap-1 '>
            <span className='w-[30%] font-semibold'>{t('city')}</span>
            <span className=''>
              {' '}
              {order?.shippingLine?.shippingAddress?.city
                ? order?.shippingLine?.shippingAddress?.city
                : '-'}
            </span>
          </div>

          <div className='flex gap-1 '>
            <span className='w-[30%] font-semibold'>{t('Country')}</span>
            <span className=''>
              {' '}
              {order?.shippingLine?.shippingAddress?.country?.translationData
                ? defaultDatat(
                    order?.shippingLine?.shippingAddress?.country
                      ?.translationData,
                    i18n.language,
                    'name'
                  )
                : '-'}
            </span>
          </div>

          <div className='flex gap-1 '>
            <span className='w-[30%] font-semibold'>{t('Phone')}</span>
            <span className=''>
              {' '}
              {order?.shippingLine?.shippingAddress?.phoneNumber
                ? order?.shippingLine?.shippingAddress?.phoneNumber
                : '-'}
            </span>
          </div>

          {order?.shippingLine?.trackingNumber && (
            <div className='flex gap-1 '>
              <span className='w-[40%] font-semibold'>
                {t('Tracking Number')}
              </span>
              <span className=''>
                {' '}
                {order?.shippingLine?.trackingNumber
                  ? order?.shippingLine?.trackingNumber
                  : '-'}
              </span>
            </div>
          )}

          <div className='flex flex-col gap-4'>
            {order?.checkout?.paymentStatus?.toLowerCase() == 'success' &&
              order?.shippingStatus === 'READY_TO_PICK' && (
                <div className='flex justify-end  gap-4'>
                  <BasicButtonWithIcon
                    title='confirm pick up'
                    icon={<CreditScoreIcon />}
                    position='self'
                    onClick={() => dispatch(openModelEditShipping())}
                  />
                </div>
              )}

            {order?.shippingLine?.shippingMethod &&
              !(order?.shippingStatus === 'SHIPPED') && (
                <div className='flex justify-end  gap-4 '>
                  <BasicButtonWithIcon
                    title='shipped details'
                    icon={<InfoIcon />}
                    position='self'
                    onClick={() => dispatch(openModelEditShipped())}
                  />
                </div>
              )}
          </div>

          {order?.checkout?.paymentStatus?.toLowerCase() == 'success' &&
            order?.shippingStatus === 'NOT_SHIPPED' && (
              <BasicButtonWithIcon
                title='confirm Shipping'
                icon={<PlaylistAddCheckCircleIcon />}
                position='self'
                onClick={() => dispatch(openModelEditShipping())}
              />
            )}
        </div>
      </div>

      <div>
        <EditShippedModal data={order} />
      </div>
    </div>
  );
};

export default ShippingCard;
