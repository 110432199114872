import React from "react";

import { CircularProgress } from "@mui/material";
import { useStateContext } from "../contexts/ContextProvider";
const LoadingSpinner = ({color, size}) => {
  const { bodyStyles } = useStateContext();

  return (
        <CircularProgress
          style={{
            width: size? size: 15,
            height: size? size: 15,
            color: color? color :bodyStyles?.primary,
          }}
        />
    );
};

export default LoadingSpinner;
