import React from "react";
import { useStateContext } from "../../../../contexts/ContextProvider";
import { Radio } from "@mui/material";
import { useTranslation } from "react-i18next";

const RatioField = ({ checked, onChange, value, title, desc, ...rest }) => {
  const { bodyStyles } = useStateContext();
  return (
    <div className="flex flex-rox w-full items-center justify-center">
      <Radio
        sx={{
          "&, &.Mui-checked": {
            color: bodyStyles?.primary,
          },
        }}
        checked={checked}
        onChange={onChange}
        value={value}
        {...rest}
      />

      <div className="flex flex-col items-start justify-start w-full">
        <span
          style={{
            color: bodyStyles?.text || "#000",
          }}
          className="text-base text-black "
        >
          {title}
        </span>
        {desc && (
          <span
            style={{
              color: bodyStyles?.subTitle || "#818181",
            }}
            className="text-sm text-[#818181] "
          >
            {desc}
          </span>
        )}
      </div>
    </div>
  );
};

export default RatioField;
