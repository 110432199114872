import BasicInput from '../../../../Inputs/BasicInput';
import * as Yup from 'yup';
import ModalDialog from '../../../../ModalDialog';
import FiledButton from '../../../../Buttons/FiledButton';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  openModelEdit,
} from '../../../../../store/features/user/userSlicer';

import {
  handleAssignRoles,
  handleUpdateStaff,
} from '../../../../../services/user';
import AssignRoles from './AssignRoles';

const EditStaffModal = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user);
  const [roles, setRoles] = useState([]);
  const { t } = useTranslation();

  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  useEffect(() => {
    setFields((prevState) => ({
      ...prevState,
      firstName: data.selectedOne?.customer?.firstName,
      lastName: data.selectedOne?.customer?.lastName,
      email: data.selectedOne?.email,
    }));

    setRoles(
      data?.selectedOne?.assignments?.map((item, index) => {
        return item?.role;
      }) || []
    );
  }, [data.selectedOne]);

  const addInstanceSchema = Yup.object().shape({
    firstName: Yup.string().required(t('This Field is required')),
    lastName: Yup.string().required(t('This Field is required')),
    email: Yup.string()
      .email(t('Invalid email'))
      .required(t('This Field is required')),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: +data.selectedOne?.id,
      firstName: fields?.firstName,
      lastName: fields?.lastName,
      email: fields?.email,
    },
    validationSchema: addInstanceSchema,
    onSubmit: async (values) => {
      dispatch(handleUpdateStaff(values, (res) => {}));
    },
  });

  const addRoleSchema = Yup.object().shape({
    roles: Yup.array(),
  });

  const formikRoles = useFormik({
    enableReinitialize: true,
    initialValues: {
      roles: roles,
      id: +data.selectedOne?.id,
    },
    validationSchema: addRoleSchema,
    onSubmit: async (values) => {
      dispatch(
        handleAssignRoles(values, (res) => {
          closeModal();
        })
      );
    },
  });

  const closeModal = () => {
    dispatch(openModelEdit());
    setFields({
      firstName: '',
      lastName: '',
      email: '',
    });
    setRoles([]);
  };

  return (
    <div className='relative'>
      <ModalDialog
        open={data?.editModal}
        setOpen={() => closeModal()}
        title={t('Edit Staff')}
        cancelTitle={t('close')}
        cancel={() => closeModal()}
        actionTitle={t('assign Roles')}
        action={() => formikRoles.handleSubmit()}
        loadingAction={data.loadingAssignRoles}
      >

        <div
          id='add-discount-modal'
          className='flex flex-col gap-5 py-0 md:w-[550px]'
        >
          <div className='m-0 -mt-2 flex flex-col  gap-4 p-0 pb-0 font-Inter   '>
            <div className=''>
              <div className='font-urbanist pt-3'>
                <div className='w-[98%]'>
                  <div className='flex flex-col  gap-4 relative'>
                    <div className='flex gap-4 flex-col md:flex-row'>
                      <BasicInput
                        label={t('First Name')}
                        placeholder='e.g. john smith'
                        value={fields?.firstName}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            firstName: e.target.value,
                          });
                        }}
                        error={
                          formik.touched.firstName &&
                          Boolean(formik.errors.firstName)
                        }
                        helperText={
                          formik.touched.firstName && formik.errors.firstName
                        }
                        InputProps={{
                          style: { fontSize: '12px' },
                        }}
                      />
                      <BasicInput
                        label={t('Last Name')}
                        placeholder='e.g. john smith'
                        value={fields?.lastName}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            lastName: e.target.value,
                          });
                        }}
                        error={
                          formik.touched.lastName &&
                          Boolean(formik.errors.lastName)
                        }
                        helperText={
                          formik.touched.lastName && formik.errors.lastName
                        }
                        InputProps={{
                          style: { fontSize: '12px' },
                        }}
                      />
                    </div>
                    <BasicInput
                      label={t('Email')}
                      placeholder='e.g. johnsmith@smartinia.com'
                      value={fields?.email}
                      onChange={(e) => {
                        setFields({
                          ...fields,
                          email: e.target.value,
                        });
                      }}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      inputProps={{
                        style: { fontSize: '12px' },
                        autocomplete: 'off',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                    />

                    <div className='flex justify-end'>
                      <>
                        {data?.loadingEdit ? (
                          <FiledButton loading />
                        ) : (
                          <FiledButton
                            text={t('Edit Staff')}
                            onClick={() => formik.handleSubmit()}
                            textSmall
                          />
                        )}
                      </>
                    </div>

                    <AssignRoles
                      instanceCreated={true}
                      roles={roles}
                      setRoles={setRoles}
                      formikRoles={formikRoles}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    </div>
  );
};

export default EditStaffModal;

