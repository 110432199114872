import React from 'react';

const OrderStatus = ({ item }) => {
  return (
    <>
      {item?.status?.toLowerCase() == 'completed' ? (
        <div
          className='py-1 px-1 max-w-[80px] bg-[#2e7d32] bg-opacity-20 text-[#2e7d32] 
              text-xs text-center  rounded-full font-Roboto '
        >
          {item?.status}
        </div>
      ) : item?.status?.toLowerCase() == 'archived' ? (
        <div
          className='py-1 px-1 max-w-[80px] bg-[#209BBB] bg-opacity-20 text-[#209BBB] 
              text-xs text-center  rounded-full font-Roboto '
        >
          {item?.status}
        </div>
      ) : item?.status?.toLowerCase() == 'pending' ? (
        <div
          className='py-1 px-1 max-w-[80px] bg-[#FF9F10] bg-opacity-20 text-[#FF9F10] 
              text-xs text-center  rounded-full font-Roboto '
        >
          {item?.status}
        </div>
      ) : (
        <div
          className='py-1 px-2 max-w-[80px] bg-[#EB5757] bg-opacity-20 text-[#EB5757] 
          text-xs text-center  rounded-full font-Roboto '
        >
          {item?.status}
        </div>
      )}
    </>
  );
};

export default OrderStatus;
