import { client } from '../global/apolloClient';

export const executeMutation = async (
  mutation,
  refetchQueries,
  variables,
  accessToken
) => {
  try {
    const result = await client.mutate({
      mutation,
      variables,
      context: { accessToken },
      refetchQueries: refetchQueries,
    });

    return result;
  } catch (error) {
    return error.message;
  }
};


export const resteCache =(fieldName, query, variables)=> {
  client.cache.evict({ fieldName: fieldName });
  client.cache.gc();
}



export const resetCacheWithRefresh = (fieldName, query, variables)=> {
  client.cache.evict({ fieldName: fieldName });
  client.cache.gc();
  client.query({ query, variables });
}


// const resetCacheWithRefresh = (query, variables, refetchQueries) => {
//   client.cache.evict({ fieldName: query });
//   client.cache.gc();
//   client.query({ query, variables, refetchQueries });
// };