import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import { useStateContext } from '../../../contexts/ContextProvider';
import { ArrowRightIcon } from '../../Svg/MainIcons';

const InstanceNavigate = ({ link, newTab }) => {
  const navigate = useNavigate();
  const { bodyStyles } = useStateContext();
  return (
    <>
      {newTab ? (
        <div
          className={`flex cursor-pointer
      ${i18n.language === 'ar' && 'rotate-180 '}
      `}
        >
          <Link to={link} target='_blank'>
            <ArrowRightIcon color={bodyStyles?.text} />
          </Link>
        </div>
      ) : (
        <div
          className={`flex cursor-pointer ${
            i18n.language === 'ar' && 'rotate-180 '
          }`}
          onClick={() => navigate(link)}
        >
          <ArrowRightIcon color={bodyStyles?.text} />
        </div>
      )}
      <div
        className={`flex cursor-pointer ${
          i18n.language === 'ar' && 'rotate-180 '
        }`}
        onClick={() => navigate(link)}
      >
        <ArrowRightIcon color={bodyStyles?.text} />
      </div>
    </>
  );
};

export default InstanceNavigate;
