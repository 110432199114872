import { gql } from "@apollo/client";

export const GET_ALL_COUNTRIES = gql`
query Countries($name: String, $continent: String!) {
  countries(name: $name, continent: $continent) {
    code
    createdAt
    id
    isEnabled
    updatedAt
    translationData {
      id
      description
      createdAt
      slug
      name
      languageCode
      updatedAt 
    }
    continent
  }
}
`;
