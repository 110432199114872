import { CREATE_ADDRESS } from '../graphql/mutation/address.mutation';
import {
  GET_ALL_CUSTOMERS,
  GET_CUSTOMER,
} from '../graphql/query/customer.query';
import { createAddressFail, createAddressStart, createAddressSuccess } from '../store/features/address/addressSlicer';
import { showErrorMessage, showSuccessMessage } from '../store/features/snackbar/snackbarSlice';
import { getTheTokenFromStorage } from './auth';
import { executeMutation } from './executeMutation';
const token = getTheTokenFromStorage();


export const createAddress = async (values, accessToken) => {
  return await executeMutation(
    CREATE_ADDRESS,
    [GET_ALL_CUSTOMERS, GET_CUSTOMER],
    values,
    accessToken
  );
};

export const handleCreateAddress = (values, callback) => {
  return async (dispatch) => {
    dispatch(createAddressStart());

    let dataSent = {
      input: {
        city: values.city,
        apartment: values.apartment,
        country: +values.country?.id,
        phoneNumber: values.phone,
        customer: +values.user.id,
        fullName: `${values.firstName} ${values.lastName}`,
        isDefaultBillingAddress: false,
        isDefaultShippingAddress: true,
        province: values.state?.state,
        streetLine1: values.address,
        postalCode: values.postalCode,
        // streetLine2: values.address,
      },
    };
    try {
      const res = await createAddress(dataSent, token);
      if (res?.data?.createAddress) {
        dispatch(createAddressSuccess(res?.data?.createAddress));
        dispatch(
          showSuccessMessage('this Address has been successfully created')
        );
      } else {
        dispatch(createAddressFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(createAddressFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};
