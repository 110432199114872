import React from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import SearchGrid from './SearchGrid';
import FilterButton from './FilterButton';

const FilterSearchHeader = ({
  placeholder,
  value,
  onChange,
  sortOptions,
  sortBy,
  setSortBy,
  searchButton=true,
  filterButton=true,
}) => {
  const { bodyStyles } = useStateContext();
  const { t, i18n } = useTranslation();

  return (
    <div
      className='flex justify-between items-center w-full h-[44px]  gap-3 '
      style={{
        color: bodyStyles?.subText,
      }}
    >
      <div className=' flex-grow md:flex-grow-0 md:w-[78%]  h-full'>
        {searchButton ? (
          <SearchGrid
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        ) : (
          <></>
        )}
      </div>

      {filterButton ? (
        <FilterButton
          options={sortOptions}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default FilterSearchHeader;
