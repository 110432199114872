import React from 'react';
import Tabs from './../../../TabsScreens';
import CustomButton from '../../../Buttons/CustomButton';
import { SaveIcon } from '../../../Svg/MainIcons';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import FacebookPixels from './FacebookPixels';
import GoogleAnalyticsPixels from './GoogleAnalyticsPixels';
import SnapchatPixels from './SnapchatPixels';
import TiktokPixels from './TiktokPixels';
import AdditionalCode from './AdditionalCode';
import Cookies from './Cookies';
import { useSelector } from 'react-redux';

const Online = () => {

  const settingState = useSelector((state) => state.setting);

  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  
  const tabs = [
    t('Google analytics & Pixels'),
    // t('CSS/JavaScript configs'),
    // t("Cookies"),
  ];

  return (
    <div className='px-6'>
      <Tabs screens={tabs}>
        <div key={0} className='font-urbanist mt-5 space-y-6 md:mt-8'>
          <FacebookPixels data={settingState?.data} />
          <GoogleAnalyticsPixels data={settingState?.data} />
          {/* <SnapchatPixels
            instanceState={instanceState}
            setInstanceState={setInstanceState}
            data={data}
          />
          <TiktokPixels
            instanceState={instanceState}
            setInstanceState={setInstanceState}
            data={data}
          /> */}
          <div className='flex items-end justify-end'>
            {/* <CustomButton className=" text-base rounded-lg px-8 py-3 flex flex-row items-center justify-center gap-x-4">
              <SaveIcon color={bodyStyles?.filedButtonText} />
              <span style={{ color: bodyStyles?.filedButtonText }}>
                {t("Save")}
              </span>
            </CustomButton> */}
          </div>
        </div>
        <div key={1} className='font-urbanist mt-5 space-y-6 md:mt-8 '>
          <div className=' flex w-full flex-row items-center justify-between bg-[#0E4DA4] px-6 py-6'>
            <div className='flex flex-row items-center justify-center gap-x-6'>
              <img
                src='/icons/Info_square.svg'
                alt='Google'
                className=' block cursor-pointer'
              />
              <div className='flex items-center justify-start gap-x-2 text-[12px] font-[400] text-white'>
                <p className='font-[600]'>{t('Note')} :</p>
                <p className=''>
                  {t(
                    'Do not forget to surround your custom code with the correct tags'
                  )}
                </p>
              </div>
            </div>
            <img
              src='/icons/close_white.svg'
              alt='Google'
              className=' block cursor-pointer'
            />
          </div>
          <AdditionalCode />
          <div className='flex items-end justify-end'>
            <CustomButton className=' flex flex-row items-center justify-center gap-x-4 rounded-lg px-8 py-3 text-base'>
              <SaveIcon color={bodyStyles?.filedButtonText} />
              <span style={{ color: bodyStyles?.filedButtonText }}>
                {t('Save')}
              </span>
            </CustomButton>
          </div>
        </div>
        <div key={2} className='font-urbanist mt-5 space-y-6 md:mt-8 '>
          <Cookies />
        </div>
      </Tabs>
    </div>
  );
};

export default Online;
