export const getCurrencyCharacter = (currency) => {
  let symbol = "$";
  if (currency.toLowerCase() === "usd") symbol = "$";
  if (currency.toLowerCase() === "cad") symbol = "(CAD) $";
  if (currency.toLowerCase() === "eur") symbol = "€";
  if (currency.toLowerCase() === "gbp") symbol = "£";
  return symbol;
};

export const preferredCurrency = () => {
  return "€";
};

export const availableCurrencies = [
  { currencyCode: "EUR", symbol: "€" },
  // { currencyCode: "USD", symbol: "$" },
  // { currencyCode: "GBP", symbol: "£" },
];

export const availableCurrenciesCode = ["EUR"
// , "USD", "GBP"
];

export const preferredCurrenciesCode = () => {
  return "EUR";
};
