import { gql } from "@apollo/client";

export const CREATE_ADDRESS = gql`
  mutation CreateAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
      id
      country {
        id
        code
        createdAt
        isEnabled
        translationData {
          updatedAt
          slug
          name
          languageCode
          id
          description
          createdAt
        }
      }
      company
      city
      apartment
      createdAt
      deletedAt
      fullName
      isDefaultBillingAddress
      isDefaultShippingAddress
      phoneNumber
      postalCode
      province
      streetLine1
      streetLine2
    }
  }
`;
