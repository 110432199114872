import React, { useEffect, useState } from 'react';
import ModalDialog from '../../ModalDialog';
import { FormControl } from '@mui/material';
import RadioGroupType from '../../Inputs/RadioGroupType';
import BasicInput from '../../Inputs/BasicInput';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { openModelEditShipped } from '../../../store/features/order/orderSlicer';
import { handleEditOrderShippedStatus } from '../../../services/order';

const EditShippedModal = ({ data }) => {
  const { t } = useTranslation();

  const dataState = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const [fields, setFields] = useState({
    withTrackingNumber: 'withoutTrackingNumebr',
    trackingNumber: '',
    shippingCompany: '',
  });

  useEffect(() => {
    if (data?.shippingLine?.trackingNumber) {
      setFields({
        ...fields,
        withTrackingNumber: 'withTrackingNumebr',
        trackingNumber: data?.shippingLine?.trackingNumber,
        shippingCompany: data?.shippingLine?.shippingCompanyName,
      });
    } else {
      setFields({
        ...fields,
        withTrackingNumber: 'withoutTrackingNumebr',
        trackingNumber: '',
        shippingCompany: '',
      });
    }
  }, [data]);

  const addInstanceSchema = Yup.object().shape({
    withTrackingNumber: Yup.string(),
    trackingNumber: Yup.string().when('withTrackingNumber', {
      is: 'withTrackingNumebr',
      then: Yup.string().required('This Field is required'),
    }),
    shippingCompany: Yup.string().when('withTrackingNumber', {
      is: 'withTrackingNumebr',
      then: Yup.string().required('This Field is required'),
    }),
  });

  const addInstanceFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      trackingNumber: fields.trackingNumber,
      shippingCompany: fields.shippingCompany,
      withTrackingNumber: fields?.withTrackingNumber,
      data: data,
    },
    validationSchema: addInstanceSchema,
    onSubmit: async (values) => {
      dispatch(handleEditOrderShippedStatus(values, (res) => {}));
    },
  });

  return (
    <div>
      <ModalDialog
        open={dataState?.editShippedModal}
        setOpen={() => dispatch(openModelEditShipped())}
        title={t('Shipped Details')}
        cancelTitle={t('Cancel')}
        cancel={() => dispatch(openModelEditShipped())}
        actionTitle={t('confirm')}
        action={() => addInstanceFormik.handleSubmit()}
        loadingAction={dataState.loadingEditShipped}
      >
        <form
          id='add-discount-modal'
          className='flex gap-5 flex-col md:w-[550px]'
        >
          <div className='w-[98%]'>
            <FormControl>
              <RadioGroupType
                value={fields?.withTrackingNumber}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    withTrackingNumber: e.target.value,
                  });
                }}
                gapY={0}
                options={[
                  {
                    value: 'withoutTrackingNumebr',
                    label: t('Shipped Without Tracking Number'),
                  },
                  {
                    value: 'withTrackingNumebr',
                    label: t('Shipped With Tracking Number'),
                  },
                ]}
              />
            </FormControl>

            {fields?.withTrackingNumber == 'withTrackingNumebr' && (
              <div className='flex flex-col gap-6 py-3'>
                <BasicInput
                  label={t('Tracking Number')}
                  placeholder='10'
                  value={fields?.trackingNumber}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      trackingNumber: e.target.value,
                    })
                  }
                  error={
                    addInstanceFormik.touched.trackingNumber &&
                    Boolean(addInstanceFormik.errors.trackingNumber)
                  }
                  helperText={
                    addInstanceFormik.touched.trackingNumber &&
                    addInstanceFormik.errors.trackingNumber
                  }
                  InputProps={{
                    style: { fontSize: '12px' },
                  }}
                />

                <BasicInput
                  label={t('Shipping Company')}
                  placeholder='10'
                  value={fields?.shippingCompany}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      shippingCompany: e.target.value,
                    })
                  }
                  error={
                    addInstanceFormik.touched.shippingCompany &&
                    Boolean(addInstanceFormik.errors.shippingCompany)
                  }
                  helperText={
                    addInstanceFormik.touched.shippingCompany &&
                    addInstanceFormik.errors.shippingCompany
                  }
                  InputProps={{
                    style: { fontSize: '12px' },
                  }}
                />
              </div>
            )}
          </div>
        </form>
      </ModalDialog>
    </div>
  );
};

export default EditShippedModal;
