export const regroupWithGroupName = (data, instanceSelected) => {
  let newList = [];
  data.forEach((item, index) => {
    if (item.subject) {
      const existingGroup = newList.find((c) => c.name === item.subject);

      if (existingGroup) {
        existingGroup.permissions.push({
          id: item.id,
          groupName: item.subject,
          name: item.name,
          action: item.action,
          status:
            instanceSelected?.permissions.some(
              (permissionObj) => permissionObj.id === item.id
            ) || false,
        });
      } else {
        newList.push({
          id: index,
          name: item.subject,
          permissions: [
            {
              id: item.id,
              groupName: item.subject,
              name: item.name,
              action: item.action,
              status:
                instanceSelected?.permissions.some(
                  (permissionObj) => permissionObj.id === item.id
                ) || false,
            },
          ],
          status:
            instanceSelected?.permissions.some(
              (permissionObj) => permissionObj.id === item.id
            ) || false,
        });
      }
    }
  });

  return newList || [];
};
