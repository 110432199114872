import React, { useState } from "react";
import ImportCSVModal from "../Upload/ImportCSVModal";
import { delay } from "../../utils/helpers";
import { createInstance } from "../../helpers/instanceActions";
import * as Yup from "yup";
import { useFormik } from "formik";

const ImportModal = (props) => {
  const [overrideExistingFiles, setOverrideExistingFiles] = useState(false);
  const [files, setFiles] = useState([]);
  const [loadingUpload, setLoadingUpload] = useState(null);
  const [uploadMore, setUploadMore] = useState(false);

  const [loadingAction, setLoadingAction] = useState(false);

  const handleDeleteFile = (i) => {
    setFiles(files.filter((file, index) => index !== i));
  };

  const importDataSchema = Yup.object().shape({
    files: Yup.array()
      .min(1, "This Field is required")
      .required("This Field is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      files: files,
    },
    validationSchema: importDataSchema,
    onSubmit: async (values) => {
      importFiles(values);
    },
  });

  const importFiles = async () => {
    if (files.length > 0) {
      setLoadingAction(true);

      props.setInstanceState((prev) => {
        return { ...prev, loadingCreateInstance: true };
      });

      const res = await props.importInstance;

      createInstance({
        setInstanceState: props.setInstanceState,
        res: res.data,
        successAction: () => {
          props.prevSetInstanceState((prev) => {
            return {
              ...prev,
              createInstance: true,
              message: "this Menu has been successfully deleted",
            };
          });

          props.setOpen(false);
          delay(2000).then(async () => {
            props.prevSetInstanceState((prev) => {
              return { ...prev, createInstance: false };
            });
            setLoadingAction(false);
          });
        },
        successMessage: "this Data has been successfully Imported",
        errorAction: () => {
          props.prevSetInstanceState((prev) => {
            return {
              ...prev,
              errorCreatInstance: true,
              loadingCreateInstance: false,
              message: "Unable to Import this Data . please try again later",
            };
          });
          props.setOpen(false);

          delay(3000).then(async () => {
            props.prevSetInstanceState((prev) => {
              return { ...prev, errorCreatInstance: false };
            });
            setLoadingAction(false);
          });
        },

        errorMeesage: "Unable to Import this Data . please try again later",
      });

      setFiles([]);
    }
  };

  return (
    <ImportCSVModal
      open={props.open}
      setOpen={props.setOpen}
      title={props.title}
      description={props.description}
      overrideExistingFiles={overrideExistingFiles}
      setOverrideExistingFiles={setOverrideExistingFiles}
      overrideLable={props.overrideLable}
      files={files}
      setFiles={setFiles}
      deleteFiles={handleDeleteFile}
      setLoadingUpload={setLoadingUpload}
      loadingUpload={loadingUpload}
      setUploadMore={setUploadMore}
      uploadMore={uploadMore}
      action={() => formik.handleSubmit()}
      loadingAction={loadingAction}
      reqErrors={formik.errors}
    />
  );
};

export default ImportModal;
