import React, { useState, useContext } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useStateContext } from '../../contexts/ContextProvider';
import { SettingsIcon } from '../Svg/NavIcons';
import { useTranslation } from 'react-i18next';
import Account from './Tabs/Account/Account';
import General from './Tabs/General/General';
import Roles from './Tabs/Roles/Roles';
import Online from './Tabs/Online/Online';
import Payment from './Tabs/Payment/Payment';
import EmailSetting from './Tabs/EmailSetting/EmailSetting';
import ShippingMethod from './Tabs/ShippingMethod/ShippingMethod';
import Zones from './Tabs/Zones/Zones';
import Checkout from './Tabs/Checkout/Checkout';
import { storeContext } from '../../global/store';
import {
  accountSettings,
  checkoutSetting,
  emailSetting,
  generalSettings,
  hasPermission,
  onlineSetting,
  paymentSetting,
  roleSetting,
  shippingMethodSettings,
  zoneSetting,
} from '../../utils/permissions';
import NonAuthorized from './NonAuthorized';
import LayoutSettings from './LayoutSettings';

const MainSettings = ({ cloesSettings, page }) => {
  const { t } = useTranslation();
  const { store } = useContext(storeContext);

  const { bodyStyles } = useStateContext();


  const [sections, setSections] = useState([
    {
      title: 'General',
      key: 'General',
      component: () => <General />,
      permissions: [generalSettings['general-settings:list']],
    },
    {
      title: 'Account',
      key: 'Account',
      component: () => <Account />,
      permissions: [accountSettings['account-settings:list']],
    },

    {
      title: 'Roles',
      key: 'Roles',
      component: () => <Roles />,
      permissions: [roleSetting['role-settings:list']],
    },

    {
      title: 'Online',
      key: 'Online',
      component: () => <Online />,
      permissions: [onlineSetting['online-settings:list']],
    },

    {
      title: 'Payment',
      key: 'Payment',
      component: () => <Payment />,
      permissions: [paymentSetting['payment-settings:list']],
    },

    {
      title: 'EmailSetting',
      key: 'EmailSetting',
      component: () => <EmailSetting />,
      permissions: [emailSetting['email-setting:list']],
    },

    {
      title: 'shippingMethode',
      key: 'shippingMethode',
      component: () => <ShippingMethod />,
      permissions: [shippingMethodSettings['shipping-method:list']],
    },

    {
      title: 'zones',
      key: 'zones',
      component: () => <Zones />,
      permissions: [zoneSetting['zone:list']],
    },

    {
      title: 'Checkout',
      key: 'Checkout',
      component: () => <Checkout />,
      permissions: [checkoutSetting['checkout-settings:list']],
    },
  ]);

  return (
    <div
      style={{ backgroundColor: bodyStyles?.background || '#414141' }}
      className='z-[999] float-right  w-full rounded-lg border border-[#C4C4C4] bg-white
                shadow dark:bg-[#484B52] dark:text-gray-200 '
    >
      <div className='flex items-center justify-between border-b border-[#C4C4C4] px-4 py-3 '>
        <div className='flex flex-row items-center justify-center gap-2 px-4'>
          <SettingsIcon color={bodyStyles?.text || '#414141'} />
          <p
            style={{ color: bodyStyles?.text || '#414141' }}
            className='text-lg font-semibold uppercase text-[#414141]'
          >
            {t('settings')}
          </p>
        </div>
        <button
          type='button'
          onClick={() => cloesSettings(false)}
          style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
          className='p-3 text-2xl hover:bg-light-gray hover:drop-shadow-xl'
        >
          <MdOutlineCancel style={{ color: bodyStyles?.text || '#414141' }} />
        </button>
      </div>
      <LayoutSettings>
        <div style={{ backgroundColor: bodyStyles.background }} className=''>
          {sections.map((item, index) => {
            if (item.key === page) {
              return (
                <div key={index}>
                  {item?.permissions?.some((per) =>
                    hasPermission(store?.permissions, per, store.usePermissions)
                  ) ? (
                    <>{item.component()}</>
                  ) : (
                    <NonAuthorized />
                  )}
                </div>
              );
            }
            return null;
          })}
        </div>
      </LayoutSettings>
    </div>
  );
};

export default MainSettings;
