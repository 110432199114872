import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../contexts/ContextProvider';
import { getCurrencyCharacter } from '../../../utils/currency';

const PaymentDetails = ({ discountOrderModal, shippingOrderModal, order }) => {
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  return (
    <div>
      <div
        className='font-Inter text-xl py-4
        uppercase font-semibold flex items-center justify-start'
        style={{
          color: bodyStyles.subTitle,
        }}
      >
        <span>{t('Payment Details')}</span>
      </div>

      <div className='grid grid-cols-3 gap-4 '>
        <div className='space-y-3 lg:space-y-6 text-base'>
          <p className='font-semibold capitalize  hover:underline'>
            {t('discount')}
          </p>

          <p className='font-semibold capitalize  hover:underline'>
            {t('Shipping')}
          </p>

          <p className='font-bold text-xl pt-8'>{t('Total')}</p>
        </div>
        <div className='space-y-3 lg:space-y-6  text-base '>
          <p>
            {discountOrderModal?.discountType
              ? discountOrderModal?.discountType
              : '_'}
          </p>

          <p>
            {' '}
            {shippingOrderModal?.shippingType
              ? shippingOrderModal?.shippingType
              : '_'}
          </p>

          <p className='text-transparent pt-8'>.</p>
        </div>

        <div className='space-y-3 lg:space-y-6 text-base'>
          <p className=' font-light'>{`${
            discountOrderModal.discountType === 'amount'
              ? order?.currencyCode
                ? getCurrencyCharacter(order?.currencyCode)
                : ''
              : '%'
          } 
        ${
          discountOrderModal?.discountValue
            ? (discountOrderModal?.discountValue / 1)
                .toFixed(2)
                .replace('.', ',')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0.00'
        }
        
        `}</p>

          <p className=' font-light'>{`${
            order?.shippingLine?.shippingMethod?.currency_code
              ? getCurrencyCharacter(
                  order?.shippingLine?.shippingMethod?.currency_code
                )
              : ''
          } 
        ${
          shippingOrderModal?.shippingValue
            ? (shippingOrderModal?.shippingValue / 1)
                .toFixed(2)
                .replace('.', ',')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0.00'
        }
        
        `}</p>

          <p className='font-bold text-xl pt-8'>
            {order?.currencyCode
              ? getCurrencyCharacter(order?.currencyCode)
              : ''}

            {order?.subTotal ? order?.subTotal?.toFixed(2) : '0.00'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
