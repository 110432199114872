import React, { useState } from 'react'

import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../contexts/ContextProvider';
import { defaultDatat } from '../../../utils/helpers';


const ShippingCard = ({ order }) => {
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  return (
    <div className="flex flex-col gap-4">
      <div
        className="font-Inter text-xl uppercase font-bold flex items-center justify-start"
        style={{
          color: bodyStyles.subTitle,
        }}
      >
        <span>{t("Shipping")}</span>
      </div>

      <div className="flex flex-col gap-2">
        <div className="drop-shadow-xl rounded-lg border  p-2 flex flex-col gap-1.5">
          <div className="flex gap-1">
            <span className="w-[30%] font-semibold">{t("Status")}</span>
            <span className="">
              {order?.status === "Shipped" ? (
                <div
                  className="py-1 px-4 bg-[#4caf50] text-[#4caf50] bg-opacity-30
                            text-sm text-center font-extrabold rounded-md font-Inter "
                >
                  {order?.status}
                </div>
              ) : (
                <div
                  className="py-1  bg-[#FFE3E3] text-[#EB5757] bg-opacity-30
                            text-sm text-center font-extrabold rounded-md font-Inter px-4"
                >
                  {order?.status}
                </div>
              )}
            </span>
          </div>

          <div className="flex gap-1 pt-2">
            <span className="w-[30%] font-semibold">{t("Name")}</span>
            <span className="">
              {order?.shippingLine?.shippingAddress?.fullName
                ? order?.shippingLine?.shippingAddress?.fullName
                : "-"}
            </span>
          </div>

          <div className="flex gap-1">
            <span className="w-[30%] font-semibold">{t("Address")}</span>
            <span className="">
              {order?.shippingLine?.shippingAddress?.streetLine1
                ? order?.shippingLine?.shippingAddress?.streetLine1
                : "-"}
            </span>
          </div>

          <div className="flex gap-1 ">
            <span className="w-[30%] font-semibold">{t("city")}</span>
            <span className="">
              {" "}
              {order?.shippingLine?.shippingAddress?.city
                ? order?.shippingLine?.shippingAddress?.city
                : "-"}
            </span>
          </div>

          <div className="flex gap-1 ">
            <span className="w-[30%] font-semibold">{t("Country")}</span>
            <span className="">
              {" "}
              {order?.shippingLine?.shippingAddress?.country?.translationData
                ? defaultDatat(
                    order?.shippingLine?.shippingAddress?.country
                      ?.translationData,
                    i18n.language,
                    "name"
                  )
                : "-"}
            </span>
          </div>

          <div className="flex gap-1 ">
            <span className="w-[30%] font-semibold">{t("Phone")}</span>
            <span className="">
              {" "}
              {order?.shippingLine?.shippingAddress?.phoneNumber
                ? order?.shippingLine?.shippingAddress?.phoneNumber
                : "-"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingCard;
