export const regroupWithGroupName = (data, roles) => {
  const permissionsArray = roles?.flatMap((role) =>
    role?.permissions?.map((permission) => permission)
  );

  const newList = data?.reduce((acc, item, index) => {
    if (item.subject) {
      const existingItem = acc.find((c) => c.name === item.subject);
      if (existingItem) {
        existingItem.permissions.push({
          id: item.id,
          groupName: item.subject,
          name: item.name,
          action: item.action,
          status: permissionsArray.some(
            (permissionObj) => permissionObj.id === item.id
          ),
        });
      } else {
        acc.push({
          id: index,
          name: item.subject,
          permissions: [
            {
              id: item.id,
              groupName: item.subject,
              name: item.name,
              action: item.action,
              status: permissionsArray.some(
                (permissionObj) => permissionObj.id === item.id
              ),
            },
          ],
          status: false,
        });
      }
    }
    return acc;
  }, []);


  return newList ? newList : [];
};
  