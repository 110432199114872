import React, { useContext, useState } from "react";
import ModalDialog from "../../ModalDialog";
import {  InputAdornment } from "@mui/material";
import BasicInput from "../../Inputs/BasicInput";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { createInstance } from "../../../helpers/instanceActions";
import { delay } from "../../../utils/helpers";
import * as Yup from "yup";
import { useStateContext } from "../../../contexts/ContextProvider";
import { storeContext } from "../../../global/store";
import BasicSelectMui from "../../Inputs/BasicSelectMui";



const DiscountModal = ({
    discountOrderModal,
    setDiscountOrderModal,
    instanceState: prevInstanceState,
    setInstanceState: prevSetInstanceState,
  }) => {
    const { t, i18n } = useTranslation();
    const { bodyStyles } = useStateContext();
    const { store } = useContext(storeContext);
  
    const [discountOrder, setDiscountOrder] = useState({
      discountType: "amount",
      discountValue: 0,
      reason: "",
    });
  
    const [instanceState, setInstanceState] = useState({
      loadingCreateInstance: false,
      createInstance: false,
      errorCreatInstance: false,
      uploadMore: false,
      loadingUpload: null,
      openModol: false,
    });
  
    const addDiscountSchema = Yup.object().shape({
      discountType: Yup.string().required("This Field is required"),
      discountValue: Yup.string().required("This Field is required"),
      reason: Yup.string().required("This Field is required"),
    });
  
    const addDiscountFormik = useFormik({
      enableReinitialize: true,
      initialValues: {
        discountType: discountOrder.discountType,
        discountValue: discountOrder.discountValue,
        reason: discountOrder.reason,
      },
      validationSchema: addDiscountSchema,
      onSubmit: async (values) => {
        addDiscount(values);
      },
    });
  
    const addDiscount = async (values) => {
      setInstanceState((prev) => {
        return {
          ...prev,
          loadingCreateInstance: true,
        };
      });
  
      delay(1000).then(async () => {
        setDiscountOrderModal((prev) => {
          return {
            ...discountOrderModal,
            ...discountOrder,
          };
        });
  
        setInstanceState((prev) => {
          return {
            ...prev,
            createInstance: true,
            openModol: false,
            loadingCreateInstance: false,
          };
        });
  
        prevSetInstanceState((prev) => {
          return {
            ...prev,
            createInstance: true,
            message: "this discount has been successfully applied",
          };
        });
  
        delay(2000).then(async () => {
          setInstanceState((prev) => {
            return {
              ...prev,
              createInstance: false,
            };
          });
  
          prevSetInstanceState((prev) => {
            return {
              ...prev,
              createInstance: false,
              message: "",
            };
          });
        });
      });
    };
    return (
      <div>
        <p
        
          className="font-semibold capitalize  hover:underline"
        >
          {t("discount")}
        </p>
  
        <ModalDialog
          open={instanceState.openModol}
          setOpen={(state) =>
            setInstanceState((prev) => {
              return {
                ...prev,
                openModol: state,
              };
            })
          }
          title={t("Add discount")}
          cancelTitle={t("Cancel")}
          cancel={() =>
            setInstanceState((prev) => {
              return {
                ...prev,
                openModol: false,
              };
            })
          }
          actionTitle={t("Apply")}
          action={() => addDiscountFormik.handleSubmit()}
          loadingAction={instanceState.loadingCreateInstance}
        >
          <form
            id="add-discount-modal"
            className="flex gap-5 flex-col md:w-[550px]"
          >
            <div className="flex gap-8 flex-col md:flex-row items-start ">
              <BasicSelectMui
                label="Discount type"
                name="DiscountType"
                id="discountType"
                value={discountOrder.discountType}
                onChange={(e) => {
                  setDiscountOrder({
                    ...discountOrder,
                    discountType: e.target.value,
                  });
                }}
                options={[
                  { value: "amount", label: "Amount" },
                  { value: "percentage", label: "Percentage" },
                ]}
              />
  
              <BasicInput
                priceFormat
                label={t("Discount value")}
                placeholder={t("DZD 0.00")}
                value={discountOrder?.discountValue}
                onChange={(e) =>
                  setDiscountOrder({
                    ...discountOrder,
                    discountValue: e.target.value,
                  })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      fontSize="small"
                      position="end"
                      sx={{ color: bodyStyles?.inputText }}
                    >
                      <span className="text-xs">
                        {discountOrder.discountType === "amount"
                          ? store?.currencyStore?.symbol
                          : "%"}
                      </span>
                    </InputAdornment>
                  ),
                  style: { fontSize: "12px" },
                }}
                error={
                  addDiscountFormik.touched.discountValue &&
                  Boolean(addDiscountFormik.errors.discountValue)
                }
                helperText={
                  addDiscountFormik.touched.discountValue &&
                  addDiscountFormik.errors.discountValue
                }
              />
            </div>
  
            <div className="space-y-1">
              <label>Reason</label>
              <BasicInput
                type="text"
                name="reason"
                id="reason"
                value={discountOrder.reason}
                onChange={(e) =>
                  setDiscountOrder({
                    ...discountOrder,
                    reason: e.target.value,
                  })
                }
                error={
                  addDiscountFormik.touched.reason &&
                  Boolean(addDiscountFormik.errors.reason)
                }
                helperText={
                  addDiscountFormik.touched.reason &&
                  addDiscountFormik.errors.reason
                }
              />
              <p className="font-thin text-sm ">
                Your customers can see this reason
              </p>
            </div>
          </form>
        </ModalDialog>
      </div>
    );
  };
  

export default DiscountModal