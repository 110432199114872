import React, { useContext, useEffect, useState } from 'react';
import { InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import DragImages from '../../components/DragImages';
import DragToUpload from '../../components/DragToUpload';
import Tabs from '../../components/TabsLanguages';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import BasicInput from '../../components/Inputs/BasicInput';
import { useTranslation } from 'react-i18next';
import BottomBanner from '../../components/Actions/BottomBanner';
import PageModal from '../../components/PageModal';
import BasicSelecAutoComplete from '../../components/Inputs/BasicSelecAutoComplete';
import CheckBox from '../../components/Inputs/CheckBox';
import { GET_ALL_MENUS } from '../../graphql/query/menus.query';
import { useQuery } from '@apollo/client';
import { defaultDatat, delay } from '../../utils/helpers';
import { GET_ALL_COLLECTIONS } from '../../graphql/query/collections.query';
import { initInstanceState } from '../../utils/initStates';
import { useMenuValidation } from '../../validations/menu.validation';
import { storeContext } from '../../global/store';
import { handleCreateMenu } from '../../services/menu';
import { useDispatch, useSelector } from 'react-redux';
const AddMenu = () => {
  const { store } = useContext(storeContext);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.menu);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();

  const [instanceState, setInstanceState] = useState(initInstanceState);
  const { addMenuSchema } = useMenuValidation();

  const [searchCollection, setSearchCollection] = useState('');
  const [searchMenus, setSearchMenus] = useState('');

  const [languages, setLanguages] = useState({
    [store.preferredLanguage]: { name: '', description: '' },
  });

  useEffect(() => {
    setLanguages({ [store.preferredLanguage]: { name: '', description: '' } });
  }, [store.preferredLanguage]);

  const {
    loading: loadingMenus,
    error: errorMenus,
    refetch: refetchMenus,
    data: MenusData,
  } = useQuery(GET_ALL_MENUS, {
    variables: {
      offset: 0,
      limit: 10,
      where: {
        languageCode: null,
        search: searchMenus,
      },
      join: {
        children: true,
        collections: true,
        parent: true,
        translationData: true,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: loadinCollections,
    error: errorCollections,
    refetch: refetchCollections,
    data: CollectionsData,
  } = useQuery(GET_ALL_COLLECTIONS, {
    variables: {
      offset: 1,
      limit: 10,
      where: {
        isEnabled: null,
        languageCode: null,
        search: searchCollection,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const [featuredAsset, setFeaturedAsset] = useState([]);

  const [fields, setFields] = useState({
    menuParent: '',
    isRoot: false,
    collections: [],
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      featuredAsset: featuredAsset,
      languages: languages,
      collections: fields.collections,
      isRoot: fields.isRoot,
      parent: fields.menuParent,
    },
    validationSchema: addMenuSchema,
    onSubmit: async (values) => {
      dispatch(
        handleCreateMenu(values, (id) => {
          delay(1000).then(async () => {
            navigate(`/menus/edit/${id}`);
          });
        })
      );
    },
    
  });


  return (
    <PageModal
      pageName={t('Add Menu')}
      pagePrev={`/menus`}
    >
      <BottomBanner
        btn01='Cancel'
        btn01Action={() => navigate(`/menus`)}
        btn02='Add Menu'
        btn02Action={() => formik.handleSubmit()}
        loading={data.loadingCreate}
      />

      <form id='product' className=' font-Inter relative mb-[50px] '>
        <div className='flex flex-col gap-y-3 py-3'>
          <div>
            <div className='flex gap-5 flex-col md:flex-row  '>
              <div className='w-[100%]  space-y-6 '>
                <div className=' space-y-2  rounded-lg'>
                  <Tabs availableLanguages={store?.availableLanguages}>
                    {store?.availableLanguages?.map((item, index) => (
                      <div key={index} className='space-y-6 font-urbanist '>
                        <div className='grid grid-cols-1 md:grid-cols-3 gap-10 px-2 sm:px-0'>
                          <div className='space-y-4 col-span-2  '>
                            <div
                              className='font-Inter text-xl pt-7 pb-5 uppercase font-bold flex items-center justify-start'
                              style={{
                                color: bodyStyles.subTitle,
                              }}
                            >
                              <span>{t('Menu informations')}</span>
                            </div>

                            <BasicInput
                              label={t('Title')}
                              placeholder={t('Home appliance')}
                              value={languages[item.code]?.name}
                              onChange={(e) => {
                                setLanguages({
                                  ...languages,
                                  [item?.code]: {
                                    ...languages[item?.code],
                                    name: e.target.value,
                                  },
                                });
                              }}
                              error={
                                item?.code === store.preferredLanguage &&
                                formik?.touched?.languages &&
                                Boolean(
                                  formik?.errors?.languages &&
                                    formik?.errors?.languages[
                                      store.preferredLanguage
                                    ]?.name
                                )
                              }
                              helperText={
                                item?.code === store.preferredLanguage &&
                                formik?.touched?.languages &&
                                formik?.errors?.languages &&
                                formik?.errors?.languages[
                                  store.preferredLanguage
                                ]?.name
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    fontSize='small'
                                    position='end'
                                    sx={{ color: bodyStyles?.inputText }}
                                  >
                                    <span className='text-xs'>
                                      {item?.code?.toUpperCase()}
                                    </span>
                                  </InputAdornment>
                                ),
                                style: { fontSize: '12px' },
                              }}
                            />

                            <BasicInput
                              label={t('Description')}
                              placeholder='Tristique viverra risus mauris nunc arcu suspendisse id. Condimentum morbi arcu lectus et sagittis. Sit et, justo tristique vel vestibulum. Sit morbi tristique sed nec scelerisque aliquam convallis quis. Ipsum aenean a libero augue ultrices nam consequat adipiscing leo. Hac vel augue fermentum pharetra, arcu cras. Urna ut nec integer dignissim nibh est, nunc feugiat. Bibendum in elementum leo fringilla venenatis nunc ac. '
                              rows={6}
                              value={languages[item.code]?.description}
                              onChange={(e) => {
                                setLanguages({
                                  ...languages,
                                  [item?.code]: {
                                    ...languages[item?.code],
                                    description: e.target.value,
                                  },
                                });
                              }}
                              error={
                                item?.code === store.preferredLanguage &&
                                formik?.touched?.languages &&
                                Boolean(
                                  formik?.errors?.languages &&
                                    formik?.errors?.languages[
                                      store.preferredLanguage
                                    ]?.description
                                )
                              }
                              helperText={
                                item?.code === store.preferredLanguage &&
                                formik?.touched?.languages &&
                                formik?.errors?.languages &&
                                formik?.errors?.languages[
                                  store.preferredLanguage
                                ]?.description
                              }
                            />

                            <BasicSelecAutoComplete
                              label={t('Parent')}
                              placeholder='Search for Menu'
                              options={
                                MenusData?.menus?.nodes
                                  ? MenusData?.menus?.nodes
                                  : []
                              }
                              getOptionLabel={(option) =>
                                defaultDatat(
                                  option?.translationData,
                                  i18n.language,
                                  'name'
                                )
                              }
                              isOptionEqualToValue={(option, value) =>
                                option?.id === (value?.id || null)
                              }
                              value={fields?.menuParent}
                              freeSolo
                              onChange={(event, newValue) => {
                                setFields({
                                  ...fields,
                                  menuParent: newValue,
                                });
                              }}
                              inputValue={searchMenus}
                              onInputChange={(event, newInputValue) => {
                                setSearchMenus(newInputValue);
                              }}
                              error={
                                formik.touched.collections &&
                                Boolean(formik.errors.collections)
                              }
                              helperText={
                                formik.touched.collections &&
                                formik.errors.collections
                              }
                            />

                            <BasicSelecAutoComplete
                              multiple
                              fullWidth
                              label={t('Collections')}
                              placeholder='Search for Collections'
                              options={
                                CollectionsData?.collections.nodes
                                  ? CollectionsData?.collections.nodes
                                  : []
                              }
                              getOptionLabel={(option) =>
                                defaultDatat(
                                  option?.translationData,
                                  i18n.language,
                                  'name'
                                )
                              }
                              isOptionEqualToValue={(option, value) =>
                                option?.id === (value?.id || null)
                              }
                              value={fields?.collections}
                              freeSolo
                              onChange={(event, newValue) => {
                                setFields({
                                  ...fields,
                                  collections: newValue,
                                });
                              }}
                              inputValue={searchCollection}
                              onInputChange={(event, newInputValue) => {
                                setSearchCollection(newInputValue);
                              }}
                              error={
                                formik.touched.collections &&
                                Boolean(formik.errors.collections)
                              }
                              helperText={
                                formik.touched.collections &&
                                formik.errors.collections
                              }
                            />

                            <div className='py-3'>
                              <CheckBox
                                checked={fields?.isRoot}
                                onClick={() =>
                                  setFields({
                                    ...fields,
                                    isRoot: !fields?.isRoot,
                                  })
                                }
                                label={t('Is Root')}
                              />
                            </div>
                          </div>
                          <div className='space-y-4 col-span-2 md:col-span-1 w-full  '>
                            <div
                              className='font-Inter text-xl pt-8 pb-5
                          uppercase font-bold flex items-center justify-start'
                              style={{
                                color: bodyStyles.subTitle,
                              }}
                            >
                              <span>{t('Menu image')}</span>
                            </div>

                            {/* Media */}
                            <div>
                              <div className='flex gap-1 flex-wrap'>
                                {(featuredAsset.length > 0 ||
                                  instanceState.loadingUpload) && (
                                  <DragImages
                                    images={featuredAsset.map(
                                      (item, index) => ({
                                        id: `${index}`,
                                        imageUrl: item,
                                      })
                                    )}
                                    setImages={setFeaturedAsset}
                                    handleDeleteImage={(i) =>
                                      setFeaturedAsset(
                                        featuredAsset.filter(
                                          (image, index) => index !== i
                                        )
                                      )
                                    }
                                    loadingUpload={instanceState.loadingUpload}
                                    single
                                  />
                                )}

                                {((!instanceState.loadingUpload &&
                                  featuredAsset?.length === 0) ||
                                  instanceState.uploadMore) && (
                                  <DragToUpload
                                    setImages={setFeaturedAsset}
                                    images={featuredAsset}
                                    setLoadingUpload={(num) =>
                                      setInstanceState((prev) => {
                                        return { ...prev, loadingUpload: num };
                                      })
                                    }
                                    loadingUpload={instanceState.loadingUpload}
                                    setUploadMore={(status) => {
                                      setInstanceState((prev) => {
                                        return { ...prev, uploadMore: status };
                                      });
                                    }}
                                    uploadMore={instanceState.uploadMore}
                                    single
                                    button01={t('Add file')}
                                   
                                  />
                                )}
                              </div>
                              <span className='text-[#d32f2f] text-sm px-1'>
                                {formik.errors.featuredAsset}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </PageModal>
  );
};

export default AddMenu;
