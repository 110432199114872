import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { handleCreateProduct } from '../../services/product';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import BottomBanner from '../../components/Actions/BottomBanner';
import PageModal from '../../components/PageModal';
import CreateProductForm from '../../components/Productsv2/createProduct/CreateProductForm';
import * as Yup from 'yup';
import { delay } from '../../utils/helpers';

const AddProduct = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.product);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [images, setImages] = useState([]);

  const [fields, setFields] = useState({
    name: '',
    htmlBody: '',
    description: '',
    categories: [],
  });

  const addInstanceSchema = Yup.object().shape({
    name: Yup.string().required(t('This Field is required')),
    description: Yup.string(),
    htmlBody: Yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: fields?.name,
      description: fields?.description,
      htmlBody: fields?.htmlBody,
      categories: fields?.categories,
      images: images,
    },
    validationSchema: addInstanceSchema,
    onSubmit: async (values) => {
      dispatch(
        handleCreateProduct(values, (res) => {
          if (res?.data?.createProduct?.id) {
            delay(1000).then(async () => {
              navigate(`/products/edit/${res?.data?.createProduct?.id}`);
            });
          }
        })
      );
    },
  });


  return (
    <PageModal pageName={t('Add Product')} pagePrev={`/products`}>
      <BottomBanner
        btn01='Cancel'
        btn01Action={() => navigate(`/products`)}
        btn02='Add Product'
        btn02Action={() => formik.handleSubmit()}
        loading={data?.loadingCreate}
      />

      <CreateProductForm
        fields={fields}
        setFields={setFields}
        formik={formik}
        images={images}
        setImages={setImages}
      />
    </PageModal>
  );
};

export default AddProduct;
