import React from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { CircularProgress } from "@mui/material";

const CustomButton = ({ loading = false, children, ...rest }) => {
  const { bodyStyles } = useStateContext();

  return (
    <button
      {...rest}
      style={{
        color: bodyStyles?.text,
        backgroundColor: bodyStyles?.filedButtonBackground,
      }}
      disabled={loading ? true : false}>
      {loading ? (
        <CircularProgress
          style={{
            width: 15,
            height: 15,
            color: bodyStyles?.text,
          }}
        />
      ) : (
        <></>
      )}
      {children}
    </button>
  );
};

export default CustomButton;
