import React from 'react';
import EditPaymentStatusModal from './EditPaymentStatusModal';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../contexts/ContextProvider';
import { dateFormatter } from '../../../utils/helpers';
import BasicButtonWithIcon from '../../Buttons/BasicButtonWithIcon';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { useDispatch } from 'react-redux';
import { openModelEditPayment } from '../../../store/features/order/orderSlicer';

const PaymnetCard = ({ order}) => {
  const { t } = useTranslation();
  const { bodyStyles } = useStateContext();
  const dispatch = useDispatch();

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex justify-between items-center'>
        <div
          className='font-Inter text-xl uppercase font-bold flex items-center justify-start'
          style={{
            color: bodyStyles.subTitle,
          }}
        >
          <span>{t('Payment')}</span>
        </div>

        <div>
          <EditPaymentStatusModal data={order} />
        </div>
      </div>

      <div className='flex flex-col gap-2'>
        <div className='drop-shadow-xl rounded-lg border  p-2 flex flex-col gap-1.5'>
          <div className='flex gap-1'>
            <span className='w-[40%] font-semibold'>{t('Status')}</span>
            <span className=' break-all'>
              {order?.checkout?.paymentStatus?.toLowerCase() == 'success' ? (
                <div
                  className='py-1 px-4 bg-[#4caf50] text-[#4caf50] bg-opacity-30
                            text-sm text-center font-extrabold rounded-md font-Inter '
                >
                  {order?.checkout?.paymentStatus}
                </div>
              ) : (
                <div
                  className='py-1  bg-[#FFE3E3] text-[#EB5757] bg-opacity-30
                            text-sm text-center font-extrabold rounded-md font-Inter px-4'
                >
                  {order?.checkout?.paymentStatus}
                </div>
              )}
            </span>
          </div>

          {order?.checkout?.paymentStatus && order?.checkout?.updatedAt && (
            <div className='flex gap-1  break-all'>
              <span className='w-[40%] font-semibold'>{t('Paid At')}</span>
              <span className='break-all '>
                {dateFormatter(order?.checkout?.updatedAt)}
              </span>
            </div>
          )}

          <div className='flex gap-1 break-all'>
            <span className='w-[40%] font-semibold'>{t('Payment method')}</span>
            <span className='break-all '>
              {order?.checkout?.paymentMethod
                ? order?.checkout?.paymentMethod
                : '-'}{' '}
            </span>
          </div>

          <div className='flex gap-1  break-all'>
            <span className='w-[40%] font-semibold'>{t('Amount')}</span>
            <span className='break-all '>
              {order?.checkout?.total
                ? order?.checkout?.total?.toFixed(2)
                : '-'}{' '}
            </span>
          </div>

          <div className='flex gap-1 '>
            <span className='w-[40%] font-semibold'>{t('Transaction ID')}</span>
            <span className='break-all '>
              {order?.checkout?.transactionId
                ? order?.checkout?.transactionId
                : '-'}{' '}
            </span>
          </div>

          <div className='flex flex-col gap-4'>
            <div className='flex justify-end  gap-4'>
              {order?.checkout?.paymentMethod === 'cash' &&
                order?.checkout?.paymentStatus?.toLowerCase() != 'success' &&
                (order?.shippingStatus === 'READY_TO_PICK' ||
                  order?.shippingStatus === 'NOT_SHIPPED') && (
                  <BasicButtonWithIcon
                    title='Confirm Payment'
                    icon={<CreditScoreIcon />}
                    position='self'
                    onClick={() => dispatch(openModelEditPayment())}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymnetCard;
