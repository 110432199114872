import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../contexts/ContextProvider';
import { storeContext } from '../../../global/store';
import DiscountModal from './DiscountModal';


const PaymentDetils = ({
    discountOrderModal,
    setDiscountOrderModal,
    products,
  }) => {
    const { t, i18n } = useTranslation();
    const { bodyStyles } = useStateContext();
    const { store } = useContext(storeContext);
  
    const [shippingOrderModal, setShippingtOrderModal] = useState({
      shippingType: "free",
      shippingRate: "",
      shippingValue: 0,
    });
  
    return (
      <div>
        <div
          className="font-Inter text-xl py-4
                            uppercase font-semibold flex items-center justify-start"
          style={{
            color: bodyStyles.subTitle,
          }}
        >
          <span>{t("Payment")}</span>
        </div>
  
        <div className="grid grid-cols-3 gap-4 ">
          <div className="space-y-3 lg:space-y-6 text-base">
            <DiscountModal
              discountOrderModal={discountOrderModal}
              setDiscountOrderModal={setDiscountOrderModal}
            />
  
            <p className="font-semibold ">{t("Total")}</p>
          </div>
          <div className="space-y-3 lg:space-y-6  text-base ">
            <p>
              {discountOrderModal?.discountType
                ? discountOrderModal?.discountType
                : "_"}
            </p>
  
            <p className="text-transparent">.</p>
          </div>
          <div className="space-y-3 lg:space-y-6 text-base">
            <p className=" font-light">{`${
              discountOrderModal.discountType === "amount"
                ? store?.currencyStore?.symbol
                : "%"
            } 
                            ${
                              discountOrderModal?.discountValue
                                ? (discountOrderModal?.discountValue / 1)
                                    .toFixed(2)
                                    .replace(".", ",")
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                : "0.00"
                            }
                            
                            `}</p>
  
            <p className=" font-semibold flex gap-0.5">
              <span className="">{store?.currencyStore?.symbol}</span>
              {(
                (products.reduce((acc, item) => {
                  return acc + item.pricing * (item.quantity ? item.quantity : 1);
                }, 0) -
                  (discountOrderModal.discountType === "amount"
                    ? discountOrderModal?.discountValue
                    : products.reduce((acc, item) => {
                        return (
                          acc + item.pricing * (item.quantity ? item.quantity : 1)
                        );
                      }, 0) *
                      (discountOrderModal?.discountValue / 100)) >
                0
                  ? products.reduce((acc, item) => {
                      return (
                        acc + item.pricing * (item.quantity ? item.quantity : 1)
                      );
                    }, 0) -
                    (discountOrderModal.discountType === "amount"
                      ? discountOrderModal?.discountValue
                      : products.reduce((acc, item) => {
                          return (
                            acc +
                            item.pricing * (item.quantity ? item.quantity : 1)
                          );
                        }, 0) *
                        (discountOrderModal?.discountValue / 100))
                  : 0) +
                (shippingOrderModal?.shippingValue
                  ? +shippingOrderModal?.shippingValue
                  : 0)
              )
                .toFixed(2)
                .replace(".", ",")
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
            </p>
          </div>
        </div>
      </div>
    );
  };
  

export default PaymentDetils