import { gql } from "@apollo/client";

export const GET_ALL_CUSTOMERS = gql`
query Customers($offset: Int, $limit: Int, $search: String, $sortBy: CustomerSortByArgs) {
  Customers(offset: $offset, limit: $limit, search: $search, sortBy: $sortBy) {
    hasNextPage
    nodes {
      id
      createdAt
      updatedAt
      firstName
      lastName
      phoneNumber
      addresses {
        id
        createdAt
        updatedAt 
        deletedAt
        fullName
        company
        streetLine1
        streetLine2
        apartment
        phoneNumber
        postalCode
        city
        province
        isDefaultShippingAddress
        isDefaultBillingAddress
        country {
          id
          createdAt
          updatedAt
          code
          isEnabled
          translationData {
            id
            createdAt
            updatedAt
            name
            slug
            description
            languageCode
          }
        }
      }
     
      email
    }
    totalCount
  }
}
`;

export const GET_CUSTOMER = gql`
  query Customer($customerId: Int!) {
    Customer(id: $customerId) {
      updatedAt
      phoneNumber
      lastName
      id
      firstName
      createdAt
      email
      addresses {
        apartment
        city
        company
        country {
          code
          createdAt
          id
          isEnabled
          translationData {
            languageCode
            id
            name
          }
        }
        createdAt
        deletedAt
        fullName
        id
        isDefaultBillingAddress
        isDefaultShippingAddress
        phoneNumber
        postalCode
        province
        streetLine1
        streetLine2
        updatedAt
      }
    }
  }
`;
