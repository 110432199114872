import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { CircularProgress } from '@mui/material';
import BasicInput from '../../../Inputs/BasicInput';
import RtlConversion from '../../../RtlConversion';
import ModalDialog from '../../../ModalDialog';
import { useEffect } from 'react';
import { handleEditSeetingWithData } from '../../../../services/setting';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const StripePaymeny = ({ data, loading }) => {
  const dispatch = useDispatch();
  const [loadingCretae, setLoadingCreate] = useState({
    stripe: false,
  });

  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  const [open, setOpen] = useState(false);

  const [fields, setFields] = useState({
    publicKey: '',
    secretKey: '',
  });

  useEffect(() => {}, []);

  const addStripeKeys = Yup.object().shape({
    publicKey: Yup.string()
      .matches(/(pk)_(test|live)_[A-Za-z0-9]/, 'Enter a valid Key')
      .required(t('This Field is required')),
    secretKey: Yup.string()
      .matches(/(sk)_(test|live)_[A-Za-z0-9]/, 'Enter a valid Key')
      .required(t('This Field is required')),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      publicKey: fields?.publicKey,
      secretKey: fields?.secretKey,
    },
    validationSchema: addStripeKeys,
    onSubmit: async (values) => {
      handleCreatePayment(values);
    },
  });

  const handleCreatePayment = async (values) => {
    setLoadingCreate({ ...loadingCretae, stripe: true });

    const dataSent = {
      updateSettingInput: {
        setting_online: {
          fb_pixel: values.facebookPixels,
          fb_access_token: values.fb_access_token,
        },
      },
    };

    dispatch(
      handleEditSeetingWithData(dataSent, (res) => {
        setLoadingCreate({ ...loadingCretae, stripe: false });
        setOpen(false);
      })
    );

    
  };

  return (
    <div className='w-full flex flex-col items-start justify-start '>
      <ModalDialog
        open={open}
        setOpen={setOpen}
        title={
          data?.setting_payment?.stripe_pk
            ? t('Edit Stripe Payment')
            : t('Add Stripe Payment')
        }
        cancelTitle={t('Cancel')}
        cancel={() => setOpen(false)}
        actionTitle={
          data?.setting_payment?.stripe_pk
            ? t('Save Payment')
            : t('Add Payment')
        }
        action={() => formik.handleSubmit()}
        className='p-0 m-0'
        loadingAction={loadingCretae?.stripe}
      >
        <div className='flex flex-col font-Inter gap-4  p-0 m-0   '>
          <div className='flex flex-col w-full max-w-[580px]'>
            <RtlConversion rtl={i18n.language === 'ar' ? true : false}>
              <div className='flex flex-col items-center justify-center w-full  gap-y-4'>
                <BasicInput
                  label={t('Stripe Public ID')}
                  // placeholder=""
                  value={fields?.publicKey}
                  onChange={(e) => {
                    setFields({
                      ...fields,
                      publicKey: e.target.value,
                    });
                  }}
                  error={
                    formik.touched.publicKey && Boolean(formik.errors.publicKey)
                  }
                  helperText={
                    formik.touched.publicKey && formik.errors.publicKey
                  }
                  InputProps={{
                    style: { fontSize: '12px' },
                  }}
                />
                <BasicInput
                  label={t('Stripe secret Key')}
                  // placeholder=""
                  value={fields?.secretKey}
                  onChange={(e) => {
                    setFields({
                      ...fields,
                      secretKey: e.target.value,
                    });
                  }}
                  error={
                    formik.touched.secretKey && Boolean(formik.errors.secretKey)
                  }
                  helperText={
                    formik.touched.secretKey && formik.errors.secretKey
                  }
                  InputProps={{
                    style: { fontSize: '12px' },
                  }}
                />{' '}
              </div>
            </RtlConversion>
          </div>
        </div>
      </ModalDialog>

      <h1
        style={{
          color: bodyStyles?.title || '#808080',
        }}
        className='text-[#808080] text-[19px] font-[600] uppercase'
      >
        {t('Stripe')}
      </h1>

      <div className='w-full mt-3'>
        <img src='/images/stripeplus.png' className='max-w-[450px] ' />
      </div>
      <div className='flex items-center justify-start w-full py-6 text-lg max-w-[600px]'>
        <span
          style={{
            color: bodyStyles?.subTitle ? bodyStyles?.subTitle : '#888888',
          }}
          className='text-[#888888]'
        >
          {t(
            'Stripe is a payment provider which has a simple fee structure without the need for a separate merchant account or payment gateway.'
          )}
          {/* <span className="text-[#358DEB] cursor-pointer underline px-1 font-bold">
              {t("see more.")}
            </span> */}
        </span>
      </div>

      <div className='px-2'>
        {loading && (
          <CircularProgress
            style={{
              width: 15,
              height: 15,
              color: bodyStyles?.text,
            }}
          />
        )}
        {data?.setting_payment?.stripe_pk ? (
          <div className='flex'>
            {t('stripe pk')}{' '}
            <div className='px-2 font-bold  flex'>
              <span>{data?.setting_payment?.stripe_pk.slice(0, 10)}</span>
              <p className='pt-1'>***************</p>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      <div className='flex items-start justify-start w-full mt-4'>
        <button
          type='button'
          // onClick={() => formik.submitForm()}
          onClick={() => setOpen(true)}
          className='bg-white text-[#414141] text-base rounded-lg px-8 py-3 flex flex-row items-center justify-center gap-x-4 border border-black'
        >
          <span>
            {/* {t("Add Stripe Payment")} */}
            {data?.setting_payment?.stripe_pk
              ? t('Edit Stripe Payment')
              : t('Add Stripe Payment')}
          </span>
        </button>
      </div>
    </div>
  );
};
export default StripePaymeny;
