import React, { useContext, useEffect, useState } from 'react';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import { storeContext } from '../../../../global/store';
import { useFormik } from 'formik';
import { InputAdornment } from '@mui/material';
import { useZoneValidation } from '../../../../validations/zone.validations';
import { useDispatch, useSelector } from 'react-redux';
import {
  openModelEdit,
} from '../../../../store/features/zone/zoneSlicer';
import BasicInput from '../../../Inputs/BasicInput';
import ModalDialog from '../../../ModalDialog';
import Tabs from '../../../TabsLanguages';
import CountriesSection from './CountriesSection';
import { handleEditZone } from '../../../../services/zone';

const EditZoneModal = () => {
  const { store } = useContext(storeContext);

  const dispatch = useDispatch();

  const { zone: data } = useSelector((state) => state);

  const { t } = useTranslation();
  const { bodyStyles } = useStateContext();

  const { addZoneSchema } = useZoneValidation();

  const [checked, setChecked] = useState([]);

  const [translationData, setTranslationData] = useState({
    [store.preferredLanguage]: { name: '', description: '' },
  });

  useEffect(() => {
    setTranslationData({
      [store.preferredLanguage]: { name: '', description: '' },
    });
  }, [store.preferredLanguage]);

  useEffect(() => {
    if (data.selectedOne) {
      if (data.selectedOne) {
        let prevTranslationData = {};
        data.selectedOne?.translationData.map((item, index) => {
          prevTranslationData = {
            ...prevTranslationData,
            [item?.languageCode.toLowerCase()]: item,
          };
        });
        setTranslationData(prevTranslationData);

        setChecked(
          data.selectedOne.countries.map((item, index) => {
            return item.code;
          })
        );
      }
    }
  }, [data.selectedOne]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      translationData: translationData,
      checked: checked,
      currencyCode: store?.currencyStore?.currencyCode,
      id: +data?.selectedOne?.id,
    },
    validationSchema: addZoneSchema,
    onSubmit: async (values) => {
      dispatch(
        handleEditZone(values, (res) => {
          closeModal();
        })
      );
    },
  });

  const closeModal = () => {
    dispatch(openModelEdit());
    setTranslationData({
      [store.preferredLanguage]: { name: '', description: '' },
    });
    setChecked([]);
  };

  return (
    <div>
      <ModalDialog
        open={data?.editModal}
        setOpen={() => closeModal()}
        title={t('Edit Zone')}
        cancelTitle={t('Cancel')}
        cancel={() => closeModal()}
        actionTitle={t('save Zone')}
        action={() => formik.handleSubmit()}
        loadingAction={data?.loadingEdit}
      >
        <form
          id='add-discount-modal'
          className='flex gap-5 flex-col md:w-[550px] py-0'
        >
          <div className='flex flex-col font-Inter gap-4  p-0 m-0 -mt-2 pb-0   '>
            <div className=''>
              <Tabs availableLanguages={store?.availableLanguages} small fixed>
                {store?.availableLanguages?.map((item, indexLanguages) => (
                  <div key={indexLanguages} className='font-urbanist pt-3'>
                    <div className='w-[98%]'>
                      <div className='flex flex-col  gap-3 '>
                        <BasicInput
                          label={t('Zone Name')}
                          placeholder='Europe'
                          value={translationData[item.code]?.name}
                          onChange={(e) => {
                            setTranslationData({
                              ...translationData,
                              [item?.code]: {
                                ...translationData[item?.code],
                                name: e.target.value,
                              },
                            });
                          }}
                          error={
                            item?.code === store.preferredLanguage &&
                            formik?.touched?.translationData &&
                            Boolean(
                              formik?.errors?.translationData &&
                                formik?.errors?.translationData[
                                  store.preferredLanguage
                                ]?.name
                            )
                          }
                          helperText={
                            item?.code === store.preferredLanguage &&
                            formik?.touched?.translationData &&
                            formik?.errors?.translationData &&
                            formik?.errors?.translationData[
                              store.preferredLanguage
                            ]?.name
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                fontSize='small'
                                position='end'
                                sx={{ color: bodyStyles?.inputText }}
                              >
                                <span className='text-xs'>
                                  {item?.code?.toUpperCase()}
                                </span>
                              </InputAdornment>
                            ),
                            style: { fontSize: '12px' },
                          }}
                        />

                        <BasicInput
                          label={t('Description')}
                          placeholder='Tristique viverra risus mauris nunc arcu suspendisse id.
                            Urna ut nec integer dignissim nibh est, nunc feugiat. Bibendum in elementum leo fringilla venenatis nunc ac. '
                          rows={3}
                          value={translationData[item.code]?.description}
                          onChange={(e) => {
                            setTranslationData({
                              ...translationData,
                              [item?.code]: {
                                ...translationData[item?.code],
                                description: e.target.value,
                              },
                            });
                          }}
                          error={
                            item?.code === store.preferredLanguage &&
                            formik?.touched?.translationData &&
                            Boolean(
                              formik?.errors?.translationData &&
                                formik?.errors?.translationData[
                                  store.preferredLanguage
                                ]?.description
                            )
                          }
                          helperText={
                            item?.code === store.preferredLanguage &&
                            formik?.touched?.translationData &&
                            formik?.errors?.translationData &&
                            formik?.errors?.translationData[
                              store.preferredLanguage
                            ]?.description
                          }
                        />
                      </div>

                      <CountriesSection
                        checked={checked}
                        setChecked={setChecked}
                      />
                    </div>
                  </div>
                ))}
              </Tabs>
            </div>
          </div>
        </form>
      </ModalDialog>
    </div>
  );
};

export default EditZoneModal;
