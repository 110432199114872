import { constants } from '../constants';
import { client } from '../global/apolloClient';
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  DELETE_MANY_CATEGORIES,
  EDIT_CATEGORY,
} from '../graphql/mutation/categories.mutation';

import {
  GET_ALL_CATEGORIES,
  GET_CATEGORY,
} from '../graphql/query/categories.query';

import {
  createCategoryFail,
  createCategoryStart,
  createCategorySuccess,
  deleteCategoryFail,
  deleteCategoryStart,
  deleteCategorySuccess,
  deleteCategoriesFail,
  deleteCategoriesStart,
  deleteCategoriesSuccess,
  editCategoryFail,
  editCategoryStart,
  editCategorySuccess,
  fetchCategoriesFail,
  fetchCategoriesStart,
  fetchCategoriesSuccess,
  importFail,
  importStart,
  importSuccess,
} from '../store/features/category/categorySlicer';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation, resteCache } from './executeMutation';
import { uploadImage } from './upload';
const token = getTheTokenFromStorage();

export const createCategory = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: CREATE_CATEGORY,
      variables: {
        ...values,
      },
      context: { accessToken },
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const getCategory = async (values, accessToken) => {
  try {
    const result = await client.query({
      query: GET_CATEGORY,
      variables: {
        ...values,
      },
      context: { accessToken },
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const editCategory = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: EDIT_CATEGORY,
      variables: {
        ...values,
      },
      context: { accessToken },
      refetchQueries: [GET_ALL_CATEGORIES, GET_CATEGORY],
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const deleteCategory = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: DELETE_CATEGORY,
      variables: {
        ...values,
      },
      context: { accessToken },
      refetchQueries: [GET_ALL_CATEGORIES],
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const deleteManyCategories = async (values, accessToken) => {
  return await executeMutation(
    DELETE_MANY_CATEGORIES,
    [GET_ALL_CATEGORIES, GET_CATEGORY],
    values,
    accessToken
  );
};

export const fetchCategories = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCategoriesStart());
      const { data } = await client.query({
        query: GET_ALL_CATEGORIES,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchCategoriesSuccess(data));
    } catch (error) {
      dispatch(fetchCategoriesFail(error));
    }
  };
};

export const handleDeleteCategory = (id, callback) => {
  return async (dispatch) => {
    dispatch(deleteCategoryStart());
    const dataSent = {
      removeCategoryId: parseInt(id),
    };
    try {
      let res = await deleteCategory(dataSent, token);
      if (res?.data?.removeCategory) {
        dispatch(deleteCategorySuccess(id));
        dispatch(showSuccessMessage('Category deleted successfully'));
      } else {
        dispatch(deleteCategoryFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteCategoryFail(error));
      callback && callback(error);
    }
  };
};

export const handleDeleteManyCategory = (ids, callback) => {
  return async (dispatch) => {
    dispatch(deleteCategoriesStart());

    let dataSent = {
      ids: ids.map((id) => +id),
    };

    try {
      let res = await deleteManyCategories(dataSent, token);
      if (res?.data) {
        dispatch(deleteCategoriesSuccess(ids));
        dispatch(showSuccessMessage('Categories deleted successfully'));
        resteCache('categories');
        resteCache('category');
      } else {
        dispatch(deleteCategoriesFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteCategoriesFail(error));
      callback && callback(error);
    }
  };
};

export const handleCreateCategory = (values, callback) => {
  return async (dispatch) => {
    dispatch(createCategoryStart());

    const dataSent = {
      createCategoryInput: {
        name: values?.name,
        description: values?.description,
        type: values?.type,
      },
    };

    try {
      const res = await createCategory(dataSent, token);
      if (res?.data?.createCategory) {
        dispatch(createCategorySuccess(res?.data?.createCategory));
        dispatch(
          showSuccessMessage('this Category has been successfully created')
        );
      } else {
        dispatch(createCategoryFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(createCategoryFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleEditCategory = (values, callback) => {
  return async (dispatch) => {
    dispatch(editCategoryStart());

    const dataSent = {
      updateCategoryInput: {
        id: +values?.id,
        name: values?.name,
        description: values?.description,
        type: values?.type,
      },
    };

    try {
      let res = await editCategory(dataSent, token);
      if (res?.data) {
        dispatch(editCategorySuccess(res?.data));
        dispatch(
          showSuccessMessage('this Category has been successfully updated')
        );
      } else {
        dispatch(editCategoryFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(editCategoryFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleImportCategory = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyCategories(dataSent, token);
      if (res?.data?.removeCategories) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Categories Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteCategoriesFail(error));
      });
    }
  };
};

export const handleExportCategory = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyCategories(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllCategories = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyCategories(dataSent, token);
    } catch (error) {}
  };
};
