export const Themes = [
  {
    name: "lightTheme",
    styles: {
      primary: "#B835B3",
      background: "#FFFFFF",
      title: "#000000",
      subTitle: "#666666",
      text: "#111111",
      subText: "#666666",

      // Buttons
      filedButtonBackground: "#000000",
      filedButtonText: "#FFFFFF",
      filedButtonBorder: "#000000",

      basicButtonBackground: "transparent",
      basicButtonText: "#000000",
      basicButtonBorder: "#000000",

      skelton: "#1f1f2b",

      primaryFont: "font-Inter",

      textFont: "font-Inter",

      border: "#DEDEDE",
      tableHeaderBackground: "#F1F1F1",
      checkBox: "#818181",
      titleFont: "font-Inter",
      cards: "#F7F7F7",

      // dataGrid
      dataGrid: "transparent",
      dataGridHeader: "#F7F7F7",
      dataGridborder: "#F2F2F2",

      // Input
      inputText: "#111111",
      inputSubText: "#222222",
      inputBorder: "#666666",
      inputBorderSelected: "#111111",
      inputPlaceHolder: "#888888",
      inputSelected: "#111111",
      inputTextDisabled : "#959595",


      // Sizes
      titleSize: "2rem",
      linksSize: "1rem",
      textSize: "1rem",
      subTextSize: ".8rem",

      //SideBar
      sideBarBackground: "#111111",
      sideBarLogo: "#FFFFFF",
      sideBarSubLogo: "#EEEEEE",
      sideBartext: "#FFFFFF",
      sideBarSubText: "#BBBBBB",
      sideBarIcon: "#BBBBBB",
      sideBarfont: "font-Inter",
      sideBarLinksSize: "1rem",
      sideBarLinksSizeMobile: "1rem",

      //Navbar
      navBarIcons: "#1E1E1E",
      sticky: false,

      // Cards
      cardBackground: "#FBFBFB",
      opacityBackground: "rgba(255,255,255,.05)",

      //dropDown
      dropDownBackground: "#1f1f2b",
      dropDownBackgroundActive: "#8B8B8B",
      dropDowntext: "#FFFFFF",
      dropDowntextActive: "#FFFFFF",
      dropDownShadow: "#2A2A2A",
      dropDownBorder: "#4D4D4D",

      //pagination

      paginationBackground: "transparent",
      paginationBackgroundActive: "#F6F6F6",
      paginationText: "#000000",
      paginationTextActive: "#B835B3",
      paginationBorder: "#000000",

      //
      divider: "#EEEEEE",

      //
      optionsBackground: "#FFFFFF",
    },
  },

  {
    name: "darkTheme",
    styles: {
      primary: "#3f50b5",
      background: "#181821",
      title: "#FFFFFF",
      subTitle: "#9a9cab",
      text: "#FFFFFF",
      subText: "#9a9cab",

      // Buttons
      filedButtonBackground: "#e9f0f2",
      filedButtonText: "#355261",
      filedButtonBorder: "#355261",

      basicButtonBackground: "transparent",
      basicButtonText: "#e9f0f2",
      basicButtonBorder: "#e9f0f2",

      skelton: "#1f1f2b",

      primaryFont: "font-Inter",

      textFont: "font-Inter",

      border: "#111",
      tableHeaderBackground: "#F1F1F1",
      checkBox: "#818181",
      titleFont: "font-Inter",
      cards: "#F7F7F7",

      // dataGrid
      dataGrid: "#1f1f2b",
      dataGridHeader: "#1f1f2b",
      dataGridborder: "#9a9cab",

      // Input
      inputText: "#FFFFFF",
      inputSubText: "#D2D2D2",
      inputBorder: "#BBBBBB",
      inputBorderSelected: "#BBBBBB",
      inputPlaceHolder: "#EEEEEE",
      inputSelected: "#FFFFFF",
      inputTextDisabled : "#959595",

      // Sizes
      titleSize: "2rem",
      linksSize: "1rem",
      textSize: "1rem",
      subTextSize: ".8rem",

      //SideBar
      sideBarBackground: "#111111",
      sideBarLogo: "#FFFFFF",
      sideBarSubLogo: "#EEEEEE",
      sideBartext: "#FFFFFF",
      sideBarSubText: "#BBBBBB",
      sideBarIcon: "#BBBBBB",
      sideBarfont: "font-Inter",
      sideBarLinksSize: "1rem",
      sideBarLinksSizeMobile: "1rem",

      //Navbar
      navBarIcons: "#FFFFFF",
      sticky: false,

      // Cards
      cardBackground: "#030310",
      opacityBackground: "rgba(255,255,255,.05)",

      //dropDown
      dropDownBackground: "#1f1f2b",
      dropDownBackgroundActive: "#8B8B8B",
      dropDowntext: "#FFFFFF",
      dropDowntextActive: "#FFFFFF",
      dropDownShadow: "#2A2A2A",
      dropDownBorder: "#4D4D4D",

      //pagination

      paginationBackground: "",
      paginationBackgroundActive: "#4d4d4d",
      paginationText: "#FFFFFF",
      paginationTextActive: "#EEEEEE",
      paginationBorder: "#1f1f2b",

      //
      divider: "#EEEEEE",

      //
      optionsBackground: "#1f1f2b",
    },
  },
];
