import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { defaultDatat } from '../../utils/helpers';
import { useQuery } from '@apollo/client';
import { initInstanceState } from '../../utils/initStates';
import { GET_CHECKOUT } from '../../graphql/query/checkouts.query';
import ModalNotFound from '../../components/MessageModals/ModalNotFound';
import ProductOrderDataGrid from '../../components/AbandonedCheckout/AbandonedCheckout/ProductOrderDataGrid';
import PageModal from '../../components/PageModal';
import ShippingCard from '../../components/AbandonedCheckout/AbandonedCheckout/ShippingCard';
import CustomerCard from '../../components/AbandonedCheckout/AbandonedCheckout/CustomerCard';
import PaymentDetails from '../../components/AbandonedCheckout/AbandonedCheckout/PaymentDetails';
import SendRecoveryEmail from '../../components/AbandonedCheckout/AbandonedCheckout/SendRecoveryEmail';
import BasicButtonWithIcon from '../../components/Buttons/BasicButtonWithIcon';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { openModelRecoveryEmail } from '../../store/features/checkout/checkoutSlicer';
import { useDispatch } from 'react-redux';
const AbandonedCheckout = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  const [instanceState, setInstanceState] = useState(initInstanceState);

  const dispatch = useDispatch();

  const [discountOrderModal, setDiscountOrderModal] = useState({
    discountType: 'amount',
    discountValue: 0,
    reason: '',
  });

  const [shippingOrderModal, setShippingtOrderModal] = useState({
    shippingType: 'free',
    shippingRate: '',
    shippingValue: 0,
  });

  const [products, setProducts] = useState([]);

  useEffect(() => {
    setInstanceState((prev) => {
      return { ...prev, id: +params?.id };
    });
  }, [params]);

  const {
    loading: loadingInstance,
    error: errorInstance,
    refetch: refetchInstance,
    data: instanceData,
  } = useQuery(GET_CHECKOUT, {
    variables: {
      join: {
        order: {
          customer: true,
          cart: true,
          discount: true,
          orderLine: {
            order: true,
            variant: {
              media: true,
              product: true,
              options: true,
            },
          },
          shippingLine: true,
        },
      },
      checkoutId: instanceState.id,
    },
    skip: instanceState.id ? false : true,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (instanceData?.checkout) {
      setProducts(
        instanceData?.checkout?.order?.cart?.cartItems
          ? instanceData?.checkout?.order?.cart?.cartItems
          : instanceData?.checkout?.order?.orderLine
          ? instanceData?.checkout?.order?.orderLine
          : []
      );

      setDiscountOrderModal((prev) => ({
        ...prev,
        discountType: instanceData?.checkout?.order?.discount?.fixedAmount
          ? 'amount'
          : 'percentage',
        discountValue: instanceData?.checkout?.order?.discount?.fixedAmount
          ? instanceData?.checkout?.order?.discount?.fixedAmount
          : instanceData?.checkout?.order?.discount?.percentage,
      }));

      setShippingtOrderModal((prev) => ({
        ...prev,
        shippingType: instanceData?.checkout?.order?.shippingLine?.shippingCost
          ? defaultDatat(
              instanceData?.order?.checkout?.shippingMethod?.translationData,
              i18n.language,
              'name'
            )
          : 'free',
        shippingValue: instanceData?.checkout?.order?.shippingLine?.shippingCost
          ? instanceData?.checkout?.order?.shippingLine?.shippingCost
          : 0,
      }));
    }
  }, [instanceData]);

  return (
    <PageModal
      pageName={`${t('Abandoned Checkout')} #${instanceState.id}`}
      pagePrev={`/abandoned-checkout`}
      loading={loadingInstance}
    >
      {instanceData && !errorInstance ? (
        <form
          id='form-order-create'
          className=' font-Inter relative mb-[50px]  '
        >
          <div className='flex gap-5 flex-col md:flex-row  '>
            <div className='w-[100%]  space-y-6 pb-10 lg:pb-20 '>
              <div className=' space-y-2  rounded-lg  px-4 lg:px-8 '>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-10 lg:gap-20 px-2 sm:px-0'>
                  <div className=' w-full flex flex-col gap-6 pt-4 col-span-2  '>
                    <div className='flex flex-col gap-4'>
                      <div
                        className='font-Inter text-xl 
                          uppercase font-bold flex items-center justify-start'
                        style={{
                          color: bodyStyles.subTitle,
                        }}
                      >
                        <span>{t('Products')}</span>
                      </div>

                      {products?.length > 0 && (
                        <div
                          className='flex flex-col items-start justify-start w-full overflow-x-scroll px-0 pb-2 mobileScrollBar mb-8 '
                          style={{
                            color: bodyStyles?.text ? bodyStyles?.text : '#fff',
                          }}
                        >
                          <ProductOrderDataGrid products={products} />
                        </div>
                      )}

                      <PaymentDetails
                        discountOrderModal={discountOrderModal}
                        setDiscountOrderModal={setDiscountOrderModal}
                        shippingOrderModal={shippingOrderModal}
                        setShippingtOrderModal={setShippingtOrderModal}
                        instanceState={instanceState}
                        setInstanceState={setInstanceState}
                        order={instanceData?.checkout?.order}
                      />
                    </div>
                  </div>
                  <div className=' w-full flex flex-col gap-6 pt-4'>
                    <div className='flex flex-col gap-4'>
                      <div className='flex justify-end  gap-4'>
                        <BasicButtonWithIcon
                          title={
                            instanceData?.checkout?.recoveryEmailSentAt
                              ? t('Re-send recovery email')
                              : t('Send recovery email')
                          }
                          icon={<ScheduleSendIcon />}
                          position='self'
                          onClick={() => dispatch(openModelRecoveryEmail())}
                        />
                      </div>
                    </div>

                    <SendRecoveryEmail id={instanceState.id} />

                    <ShippingCard order={instanceData?.checkout?.order} />

                    <CustomerCard order={instanceData?.checkout?.order} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <ModalNotFound
          message='Item Not Found.'
          returnMessage='Return to Orders page'
          action={() => navigate(`/orders`)}
        />
      )}
    </PageModal>
  );
};

export default AbandonedCheckout;
