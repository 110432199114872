import { useState } from 'react';
import { useFormik } from 'formik';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { initInstanceState } from '../../utils/initStates';
import BottomBanner from '../../components/Actions/BottomBanner';
import PageModal from '../../components/PageModal';
import ModalNotFound from '../../components/MessageModals/ModalNotFound';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import EditReferenceForm from '../../components/Reference/updateReference/EditReferenceForm';
import { GET_REFERENCE } from '../../graphql/query/reference.query';
import { handleEditReference } from '../../services/reference';


const EditReference = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.reference);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const params = useParams();
  const [instanceState, setInstanceState] = useState(initInstanceState);

  const [images, setImages] = useState([]);

  const [fields, setFields] = useState({
    company : "",
    customerFullName: '',
    description: '',
    location: '',
    subDescription: '',
  });

  const {
    loading: loadingInstance,
    error: errorInstance,
    refetch: refetchInstance,
    data: instanceData,
  } = useQuery(GET_REFERENCE, {
    variables: {
      referenceId: instanceState.id,
    },
    skip: instanceState.id ? false : true,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setInstanceState((prev) => {
      return { ...prev, id: +params?.id };
    });
  }, [params]);

  useEffect(() => {
    if (instanceData?.reference) {
    
        setFields((prev) => {
          return {
            ...prev,
            company : instanceData?.reference?.company, 
            customerFullName: instanceData?.reference?.customerFullName,
            description: instanceData?.reference?.description,
            subDescription: instanceData?.reference?.subDescription,
            location: instanceData?.reference?.location,
          };
        });
  
        setImages(instanceData?.reference?.media || []);
    }
  }, [instanceData]);

  const addInstanceSchema = Yup.object().shape({
    customerFullName: Yup.string().required(t('This Field is required')),
    description: Yup.string(),
    location: Yup.string(),
    subDescription: Yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id : instanceState?.id,
      customerFullName: fields?.customerFullName,
      description: fields?.description,
      location: fields?.location,
      subDescription: fields?.subDescription,
      company: fields?.company,
      images: images,
    },
    validationSchema: addInstanceSchema,
    onSubmit: async (values) => {
      dispatch(
        handleEditReference(values, (res) => {})
      );
    },
  });


  return (
    <PageModal
      pageName={t('Edit Reference')}
      pagePrev={`/references`}
      loading={loadingInstance}
    >
      <div
        className={`${
          instanceData?.reference ? 'opacity-100' : 'opacity-0 hidden'
        }`}
      >
        <BottomBanner
          btn01={t('Cancel')}
          btn01Action={() => navigate(`/references`)}
          btn02={t('Save Reference')}
          btn02Action={() => formik.handleSubmit()}
          loading={data?.loadingEdit}
        />
      </div>

 

      {instanceData && !errorInstance ? (
        <EditReferenceForm
          fields={fields}
          setFields={setFields}
          formik={formik}
          images={images}
          setImages={setImages}
          instanceData={instanceData}
        />
      ) : (
        <ModalNotFound
          message='Item Not Found.'
          returnMessage='Return to Reference page'
          action={() => navigate(`/references`)}
        />
      )}
    </PageModal>
  );
};
export default EditReference;
