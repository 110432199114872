import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isObjectNotEmpty } from '../../utils/helpers';
import usePage from '../../hooks/page.hook';
import { useNavigate } from 'react-router-dom';
import DataGridContainer from '../DataGrid/DataGridContainer';

const DataGridSection = ({ search, filter, order, status, data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page, pageSize, setPage } = usePage(search);
  const dataInstance = useSelector((state) => state[data.stateName]);
  
  // console.log('dataInstance', dataInstance)

  
  const [variables, setVariables] = useState({});

  useEffect(() => {
    setVariables((variables) => ({
      ...data.variables({
        pageSize: data?.pageSize ? data?.pageSize : pageSize,
        page,
        search,
        order,
        status,
      }),
    }));
  }, [search, page, pageSize, order, status]);


  useEffect(() => {
    if (isObjectNotEmpty(variables)) {
      dispatch(data.fetch(variables));
      return () => {
        dispatch(data.clearMessages());
      };
    }
  }, [variables]);

  return (
    <div className='w-full flex flex-col items-center justify-center font-Inter'>
      <DataGridContainer
        data={dataInstance}
        dataPage={data}
        ModalFetchErrorAction={() => navigate(-1)}
        ModalFetchErrorMessage='Go back'
        search={search}
        addInstanceAction={() => navigate('/menu/add')}
        rows={data.rows}
        selectAll={data?.selectAll}
        updateSelected={data?.updateSelected}
        openModelCreate={data?.openModelCreate}
        openModelEdit={data?.openModelEdit}
        openModelDelete={data?.openModelDelete}
        openModelDeleteAll={data?.openModelDeleteAll}
        handleDelete={data?.handleDelete}
        handleDeleteMany={data?.handleDeleteMany}
        handleExportFiles={data?.handleExportFiles}
        openModeleImport={data?.openModeleImport}
        handleExportAllFiles={data?.handleExportAllFiles}
        handleImportFiles={data?.handleImportFiles}
        handleUpdateMany={data.handleUpdateMany}
        components={data?.components}
        page={page}
        setPage={setPage}
      />
    </div>
  );
};

export default DataGridSection;
