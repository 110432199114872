import { pagesSettingsData } from '../../../../../pages_data/pages_data';
import FetchPageSetting from '../../../../fetchPages/FetchPageSetting';

const Staff = () => {
  return (
    <div className='flex w-full'>
      <FetchPageSetting data={pagesSettingsData?.staff?.main} />
    </div>
  );
};

export default Staff;
