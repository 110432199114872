import * as Yup from 'yup';

const orderValidation = () => {
  const addOrderSchema = Yup.object().shape({
    customers: Yup.array()
      .min(1, 'This Field is required')
      .required('This Field is required'),

    shippingAddress: Yup.array()
      .min(1, 'This Field is required')
      .required('This Field is required'),

    products: Yup.array()
      .min(1, 'You must at least add one product')
      .required('This Field is required'),

    shippingMethod: Yup.string().required('Shipping Method is required'),
  });

  return {
    addOrderSchema,
  };
};

export { orderValidation };
