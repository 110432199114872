import { useTranslation } from 'react-i18next';
import PageModal from '../components/PageModal';
import { useContext, useEffect, useState } from 'react';
import { useDynamicHeightAdjustment } from '../hooks/heightObserver.hook';
import { storeContext } from '../global/store';
import Tabs from '../components/TabsLanguages';
import Editor from '../components/Editor/Editor';
import BottomBanner from '../components/Actions/BottomBanner';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleCreatePage } from '../services/page';
import { useQuery } from '@apollo/client';
import { GET_ALL_PAGES } from '../graphql/query/pages.query';

const Pages = () => {
  const { t } = useTranslation();
  const { store } = useContext(storeContext);
  const [selectedLang, setSelectedLang] = useState('');
  const data = useSelector((state) => state.page);
  const dispatch = useDispatch();

  const {
    loading: loadingAllPages,
    error: errorAllPages,
    refetch: refetchAllPages,
    data: allPagesData,
  } = useQuery(GET_ALL_PAGES, {
    variables: {
      offset: 0,
      limit: 80,
      type: 'LEGAL',
    },
    fetchPolicy: 'cache-and-network',
  });


  useEffect(() => {
    if (allPagesData?.pages?.nodes?.length) {
      const updatedPagesData = { ...pagesData }; // Create a copy of the pagesData object
      Object.keys(updatedPagesData).forEach((key) => {
        let findContent = allPagesData?.pages?.nodes
          ? allPagesData?.pages?.nodes?.find(
              (item) => item.name === updatedPagesData[key].value
            )
          : '';
        if (findContent?.htmlBody) {
          updatedPagesData[key].content = findContent?.htmlBody;
        }
      });

      setPagesData(updatedPagesData); //
    }
  }, [allPagesData]);

  const navigate = useNavigate();

  const conRef = useDynamicHeightAdjustment();

  const [pageSelected, setPageSelected] = useState('home');




  const [pages, setPages] = useState([
    { name: 'Home', value: 'home' },
    { name: 'About us', value: 'about' },
    { name: 'Imprint', value: 'imprint' },
    { name: 'Privacy Policy', value: 'privacyPolicy' },
    { name: 'Terms and Conditions', value: 'termsAndConditions' },
  ]);

  const [pagesData, setPagesData] = useState({
    home: {
      name: 'Home',
      value: 'home',
      content: '',
      type: 'LEGAL',
    },
    about: {
      name: 'about us',
      value: 'about',
      content: '',
      type: 'LEGAL',
    },
    imprint: {
      name: 'Imprint',
      value: 'imprint',
      content: '',
      type: 'LEGAL',
    },
    privacyPolicy: {
      name: 'Privacy Policy',
      value: 'privacyPolicy',
      content: '',
      type: 'LEGAL',
    },
    termsAndConditions: {
      name: 'Terms and Conditions',
      value: 'termsAndConditions',
      content: '',
      type: 'LEGAL',
    },
  });


  const selectChange = (item) => {
    setPagesData((prevState) => ({
      ...prevState,
      [pageSelected]: {
        ...prevState[pageSelected],
        content: item?.content,
      },
    }));
  };

  const addInstanceSchema = Yup.object().shape({
    name: Yup.string().required(t('This Field is required')),
    description: Yup.string(),
    htmlBody: Yup.string(),
    type: Yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: pagesData?.[pageSelected]?.value || 'home',
      description: '',
      htmlBody: pagesData?.[pageSelected]?.content,
      type: 'LEGAL',
      categories: [],
      images: [],
    },
    validationSchema: addInstanceSchema,
    onSubmit: async (values) => {
      dispatch(handleCreatePage(values, (res) => {}));
    },
  });

  return (
    <PageModal pageName={t('Pages')} pagePrev={`/`} empty>
      <BottomBanner
        btn02={t('Save Page')}
        btn02Action={() => formik.handleSubmit()}
        loading={data?.loadingCreate}
      />

      <div className='w-full relative flex xl:hidden justify-end px-6 text-xs'>
        <div className='mt-8 flex flex-row gap-6 '>
          {Object.keys(pagesData).map((key, index) => (
            <div
              key={index}
              className={`font-Inter font-semibold capitalize cursor-pointer py-2 ${
                pageSelected === pagesData[key].value
                  ? 'border-b-4 border-primary transition-colors duration-300'
                  : 'opacity-90'
              } hover:opacity-100`}
              onClick={() => {
                setPageSelected(pagesData[key].value);
              }}
            >
              {t(pagesData[key].name)}
            </div>
          ))}
        </div>
      </div>
      <div
        className='flex flex-col xl:flex-row  text-gray-800 border-4  overflow-y-scroll'
        ref={conRef}
      >
        <div className='w-[190px] relative hidden xl:flex'>
          <div className='w-full'>
            <aside className='fixed w-[190px] bg-white p-4 mb-4 break-all'>
              <div className='my-4 w-full border-b-4 pb-4 border-indigo-100 text-center'>
                <span className='font-mono text-xl font-bold tracking-widest'>
                  {t('Pages')}
                </span>
              </div>
              <div className='mt-8 flex flex-col gap-2'>
                {pages?.map((item, index) => (
                  <div
                    key={index}
                    className={`font-Inter font-semibold capitalize cursor-pointer py-2 ${
                      pageSelected === item.value
                        ? 'border-b-4 border-primary transition-colors duration-300'
                        : 'opacity-90'
                    } hover:opacity-100`}
                    onClick={() => {
                      setPageSelected(item.value);
                    }}
                  >
                    {t(item.name)}
                  </div>
                ))}
              </div>
            </aside>
          </div>
        </div>

        <main
          className='main flex flex-grow flex-col px-1 md:px-4 transition-all duration-150 ease-in bg-gray-100 h-full xl:w-min'
          style={{ overflowX: 'hidden' }}
        >
          <Tabs
            availableLanguages={store.availableLanguages}
            setSelected={(item) => setSelectedLang(item)}
          >
            {store.availableLanguages?.map((item, indexLanguages) => (
              <div key={`${pageSelected}-${item?.code}`}>
                <Editor
                  placeholder={`${t('Content')} ...`}
                  value={pagesData?.[pageSelected]?.content}
                  onChange={(e) => {
                    setPagesData((prevState) => ({
                      ...prevState,
                      [pageSelected]: {
                        ...prevState[pageSelected],
                        content: e,
                      },
                    }));
                  }}
                  minHeight='540px'
                  selectChange={(item) => selectChange(item)}
                />
              </div>
            ))}
          </Tabs>
        </main>
      </div>
    </PageModal>
  );
};

export default Pages;
