import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageModal from '../components/PageModal';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const navigate = useNavigate();
  const {t} = useTranslation()

  useEffect(() => {
    navigate('/products');
  }, []);

  return <PageModal pageName={t('Reports')} pagePrev={`/`} empty></PageModal>;
};

export default Home;
