import { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { GET_ALL_VARIANTS } from '../../graphql/query/variants.query';
import { defaultDatat, delay } from '../../utils/helpers';
import { useQuery } from '@apollo/client';
import { GET_ALL_CUSTOMERS } from '../../graphql/query/customer.query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import BottomBanner from '../../components/Actions/BottomBanner';
import BasicSelecAutoComplete from '../../components/Inputs/BasicSelecAutoComplete';
import PageModal from '../../components/PageModal';
import { orderValidation } from '../../validations/order.validations';
import AddCustomerModal from '../../components/Orders/CreateOrder/AddCustomerModal';
import AddShippingAdressModal from '../../components/Orders/CreateOrder/AddShippingAdressModal';
import ProductOrderDataGrid from '../../components/Orders/CreateOrder/ProductOrderDataGrid';
import PaymentDetils from '../../components/Orders/CreateOrder/PaymentDetils';
import ShippingMethodes from '../../components/Orders/CreateOrder/ShippingMethodes';
import CheckBox from '../../components/Inputs/CheckBox';
import { useDispatch, useSelector } from 'react-redux';
import { handleCreateOrder } from '../../services/order';
import { storeContext } from '../../global/store';

const AddOrder = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();
  const { store } = useContext(storeContext);

  const data = useSelector((state) => state.order);
  const dispatch = useDispatch()

  const { addOrderSchema } = orderValidation();
  const [searchVarints, setSearchVarints] = useState('');
  const [discountOrderModal, setDiscountOrderModal] = useState({
    discountType: 'amount',
    discountValue: 0,
    reason: '',
  });

  const [shippingMethod, setShippingMethod] = useState('');

  const [productsOptions, setProductsOptions] = useState([]);

  const [products, setProducts] = useState([]);

  const [fields, setFields] = useState({
    customers: [],
    shippingAddress: [],
    shippingAddressInputValue: '',
    customersInputValue: '',
    cash: false,
  });

  const [initChippingAddrees, setInitChippingAddrees] = useState([]);

  const {
    loading: loadingVariants,
    error: errorVarints,
    data: variants,
    refetch: refetchVariants,
  } = useQuery(GET_ALL_VARIANTS, {
    variables: {
      offset: 0,
      limit: 10,
      where: {
        search: searchVarints,
        isEnabled: true,
        languageCode: null,
      },
      join: {
        product: true,
        options: true,
      },
    },

    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: loadingCustomers,
    error: errorCustomers,
    data: customersData,
    refetch: refetchCustomers,
  } = useQuery(GET_ALL_CUSTOMERS, {
    variables: {
      offset: 0,
      limit: 10,
      search: fields?.customersInputValue ? fields?.customersInputValue : '',
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setProductsOptions(
      variants?.variants?.nodes ? variants?.variants?.nodes : []
    );
  }, [variants, searchVarints]);

  useEffect(() => {
    setInitChippingAddrees(
      fields?.customers[0]?.addresses?.length
        ? fields?.customers[0]?.addresses
        : []
    );
    setFields({
      ...fields,
      shippingAddress: [],
      shippingAddressInputValue: '',
    });
  }, [fields.customers]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      products: products,
      customers: fields.customers,
      shippingAddress: fields.shippingAddress,
      products: products,
      discountOrderModal: discountOrderModal,
      shippingMethod: shippingMethod,
      paymentMethode: fields.cash,
      currencyCode: store?.currencyStore?.currencyCode,
    },
    validationSchema: addOrderSchema,
    onSubmit: async (values) => {
      dispatch(
        handleCreateOrder(values, (id) => {
          delay(1000).then(async () => {
            navigate(`/orders/order/${id}`);
          });
        })
      );
    },
  });

  return (
    <PageModal
      pageName={t('New Order')}
      pagePrev={`/orders`}
    >
      <BottomBanner
        btn01={t('Cancel')}
        btn01Action={() => navigate(`/orders`)}
        btn02={t('Add Order')}
        btn02Action={() => formik.handleSubmit()}
        loading={data?.loadingCreate}
      />

      <form
        onSubmit={formik.handleSubmit}
        id='form-order-create'
        className=' font-Inter relative mb-[50px]  '
      >
        <div className='flex gap-5 flex-col md:flex-row  '>
          <div className='w-[100%]  space-y-6 pb-10 lg:pb-20 '>
            <div className=' space-y-2  rounded-lg  px-4 lg:px-8 '>
              <div className='grid grid-cols-1 md:grid-cols-3 gap-10 lg:gap-20 px-2 sm:px-0'>
                <div className='w-full flex flex-col gap-6 pt-4 col-span-2  '>
                  <div className='flex flex-col gap-4'>
                    <div
                      className='font-Inter text-xl 
                        uppercase font-bold flex items-center justify-start'
                      style={{
                        color: bodyStyles.subTitle,
                      }}
                    >
                      <span>{t('Products')}</span>
                    </div>

                    <div>
                      <div
                        className='flex justify-between items-center w-full  h-full gap-1 '
                        style={{
                          color: bodyStyles?.subText,
                        }}
                      >
                        <div className=' flex-grow md:flex-grow-0 md:w-full '>
                          <BasicSelecAutoComplete
                            multiple
                            placeholder={t('Search for products')}
                            options={productsOptions}
                            getOptionLabel={(option) => (
                              <div className='flex gap-2 items-center'>
                                <div
                                  style={{
                                    borderColor: bodyStyles?.borderColor,
                                  }}
                                  className=' w-8 h-8 border rounded p-1 flex justify-center items-center'
                                >
                                  <img
                                    className=' block w-full h-full rounded-md'
                                    src={
                                      option?.media?.full_url
                                        ? option?.media?.full_url
                                        : option?.product?.media[0]?.full_url
                                        ? option?.product?.media[0]?.full_url
                                        : '/images/empty-modal.png'
                                    }
                                    alt='product'
                                  />
                                </div>
                                {`${defaultDatat(
                                  option?.product?.translationData,
                                  i18n.language,
                                  'name'
                                )}
                              ${option?.options?.length > 0 ? '(' : ''}
                              ${
                                option?.options?.length > 0
                                  ? option?.options?.map((opt) => {
                                      return (
                                        defaultDatat(
                                          opt?.translationData,
                                          i18n.language,
                                          'name'
                                        ) + ' '
                                      );
                                    })
                                  : ''
                              }
                            ${option?.options?.length > 0 ? ')' : ''}`}{' '}
                              </div>
                            )}
                            filterOptions={(options, state) => options}
                            isOptionEqualToValue={(option, value) =>
                              option?.id === (value?.id || null)
                            }
                            value={[]}
                            freeSolo
                            search
                            disableClearable
                            inputValue={searchVarints}
                            onInputChange={(event, newInputValue) => {
                              setSearchVarints(newInputValue);
                            }}
                            onChange={(event, newValue) => {
                              if (
                                newValue[0]?.id &&
                                !products.find(
                                  (product) => product.id === newValue[0].id
                                )
                              ) {
                                setProducts((current) => [
                                  ...current,
                                  newValue[0],
                                ]);
                              }
                            }}
                            error={
                              formik.touched.products &&
                              Boolean(formik.errors.products)
                            }
                            helperText={
                              formik.touched.products && formik.errors.products
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {products?.length > 0 && (
                      <div
                        className='flex flex-col items-start justify-start w-full 
                          overflow-x-scroll px-0 pb-2 mobileScrollBar mb-8
                          '
                        style={{
                          color: bodyStyles?.text ? bodyStyles?.text : '#fff',
                        }}
                      >
                        {' '}
                        <ProductOrderDataGrid
                          products={products}
                          setProducts={setProducts}
                        />
                      </div>
                    )}

                    <PaymentDetils
                      discountOrderModal={discountOrderModal}
                      setDiscountOrderModal={setDiscountOrderModal}
                      products={products}
                    />
                  </div>
                </div>
                <div className=' w-full flex flex-col gap-6 pt-4'>
                  <div className='flex flex-col gap-4'>
                    <div
                      className='font-Inter text-xl 
                        uppercase font-bold flex items-center justify-start'
                      style={{
                        color: bodyStyles.subTitle,
                      }}
                    >
                      <span>{t('Customer')}</span>
                    </div>

                    <div className='flex flex-col gap-2'>
                      <BasicSelecAutoComplete
                        single
                        label={t('Customers')}
                        placeholder={t('Search for customers')}
                        freeSolo
                        options={
                          customersData?.Customers?.nodes
                            ? customersData?.Customers?.nodes
                            : []
                        }
                        getOptionLabel={(option) =>
                          `${option?.firstName} ${option?.lastName}`
                        }
                        isOptionEqualToValue={(option, value) =>
                          option?.id === (value?.id || null)
                        }
                        value={
                          fields?.customers?.length
                            ? fields?.customers[0]
                            : null
                        }
                        onChange={(event, newValue) => {
                          setFields({
                            ...fields,
                            customers: newValue ? [newValue] : [],
                          });
                        }}
                        onInputChange={(event, newInputValue) => {
                          setFields({
                            ...fields,
                            customersInputValue: newInputValue,
                          });
                        }}
                        error={
                          formik.touched.customers &&
                          Boolean(formik.errors.customers)
                        }
                        helperText={
                          formik.touched.customers && formik.errors.customers
                        }
                      />

                      <AddCustomerModal
                        setFields={setFields}
                        fields={fields}
                      />
                    </div>
                  </div>

                  <div className='flex flex-col gap-4'>
                    <div
                      className='font-Inter text-xl uppercase font-bold flex items-center justify-start'
                      style={{
                        color: bodyStyles.subTitle,
                      }}
                    >
                      <span>{t('Shipping Address')}</span>
                    </div>

                    <div className='flex flex-col gap-2'>
                      <BasicSelecAutoComplete
                        disabled={!fields?.customers[0]}
                        single
                        label={t('Shipping Address')}
                        placeholder={t('Search for Shipping Address')}
                        options={initChippingAddrees}
                        getOptionLabel={(option) =>
                          `${option?.fullName ? option?.fullName : '__'}, ${
                            option?.streetLine1 ? option?.streetLine1 : '__'
                          }. ${
                            option?.postalCode ? option?.postalCode : '__'
                          }, ${option?.city ? option?.city : '__'}, ${
                            option?.country?.translationData
                              ? defaultDatat(
                                  option?.country?.translationData,
                                  i18n.language,
                                  'name'
                                )
                              : '__'
                          }`
                        }
                        isOptionEqualToValue={(option, value) =>
                          option?.id === (value?.id || null)
                        }
                        value={
                          fields?.shippingAddress?.length
                            ? fields?.shippingAddress[0]
                            : null
                        }
                        freeSolo
                        onChange={(event, newValue) => {
                          setFields({
                            ...fields,
                            shippingAddress: newValue ? [newValue] : [],
                          });
                        }}
                        onInputChange={(event, newInputValue) => {
                          setFields({
                            ...fields,
                            shippingAddressInputValue: newInputValue,
                          });
                        }}
                        error={
                          formik.touched.shippingAddress &&
                          Boolean(formik.errors.shippingAddress)
                        }
                        helperText={
                          formik.touched.shippingAddress &&
                          formik.errors.shippingAddress
                        }
                      />

                      {fields?.customers[0] && (
                        <>
                          <AddShippingAdressModal
                            user={
                              fields?.customers[0] ? fields?.customers[0] : ''
                            }
                            setFields={setFields}
                            fields={fields}
                          />

                          {fields?.shippingAddress[0] && (
                            <div className='flex flex-col border p-2 gap-2'>
                              <span>
                                {fields?.shippingAddress[0]?.fullName
                                  ? fields?.shippingAddress[0]?.fullName
                                  : '__'}
                              </span>
                              <span>
                                {`${
                                  fields?.shippingAddress[0]?.streetLine1
                                    ? fields?.shippingAddress[0]?.streetLine1
                                    : '__'
                                },
                                  ${
                                    fields?.shippingAddress[0]?.city
                                      ? fields?.shippingAddress[0]?.city
                                      : '__'
                                  },
                                  ${
                                    fields?.shippingAddress[0]?.postalCode
                                      ? fields?.shippingAddress[0]?.postalCode
                                      : '__'
                                  }
                         
                          `}
                              </span>

                              <span>
                                {`
                          ${
                            fields?.shippingAddress[0]?.country?.translationData
                              ? defaultDatat(
                                  fields?.shippingAddress[0]?.country
                                    ?.translationData,
                                  i18n.language,
                                  'name'
                                )
                              : '__'
                          }
                          `}
                              </span>

                              <span>
                                {`
                          ${
                            fields?.shippingAddress[0]?.phoneNumber
                              ? fields?.shippingAddress[0]?.phoneNumber
                              : '___'
                          }`}
                              </span>
                            </div>
                          )}
                        </>
                      )}

                      <div
                        className='font-Inter text-xl uppercase font-bold flex items-center justify-start pt-4'
                        style={{
                          color: bodyStyles.subTitle,
                        }}
                      >
                        <span>{t('Shipping Method')}</span>
                      </div>

                      <ShippingMethodes
                        city={fields?.shippingAddress[0]?.city}
                        shippingMethod={shippingMethod}
                        setShippingMethod={setShippingMethod}
                      />

                      <span className='text-[#d32f2f] text-sm px-3 font-Roboto capitalize'>
                        {formik?.errors?.shippingMethod}
                      </span>

                      <div
                        className='font-Inter text-xl uppercase font-bold flex items-center justify-start pt-2'
                        style={{
                          color: bodyStyles.subTitle,
                        }}
                      >
                        <span>{t('Payment Method')}</span>
                      </div>

                      <div className=''>
                        <CheckBox
                          checked={fields?.cash}
                          onClick={() =>
                            setFields({
                              ...fields,
                              cash: !fields?.cash,
                            })
                          }
                          label={t('CASH')}
                          color={bodyStyles.title}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </PageModal>
  );
};
export default AddOrder;
