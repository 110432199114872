export const generalData = {
  //  backendApi: process.env.REACT_APP_API_ENDPOINT,
  backendApi: "api-lwz.smartinia.store",
  clientSideUrl: process.env.REACT_APP_CLIENT_SIDE_URL,
  name: process.env.REACT_APP_NAME,
  navName: process.env.REACT_APP_NAVNAME,
  storeName: process.env.REACT_APP_STORENAME,
  storepath: process.env.REACT_APP_STOREPATH,
  storelink: process.env.REACT_APP_STORLINK,
  logo: process.env.REACT_APP_LOGO,
  emailDemo: process.env.REACT_APP_EMAIL_DEMO,
  passwordDemo: process.env.REACT_APP_PASSWORD_DEMO,
  version: process.env.REACT_APP_VERSION,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  pages: {
    home: false,
    order: false,
    abandonedCheckout: false,
    product: true,
    service: true,
    planning : true,
    reference : true,
    variant: false,
    menu: false,
    category : true,
    contact  : true,
    collection: false,
    brand: false,
    tag: false,
    discount: false,
    customer: false,
    analytic: false,
    pages : true,
    setting: false,
    generalSetting: true,
    accountSetting: false,
    roleSetting: false,
    emailSetting: false,
    onlineSetting: false,
    paymentSetting: false,
    shippingMethodeSetting: false,
    zoneSetting: false,
    checkoutSetting: false,
    staffSetting: false,
  },
};
