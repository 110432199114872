import { gql } from '@apollo/client';

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($createCategoryInput: CreateCategoryInput!) {
    createCategory(createCategoryInput: $createCategoryInput) {
      createdAt
      description
      id
      name
      type
      updatedAt
      deletedAt
    }
  }
`;

export const EDIT_CATEGORY = gql`
  mutation UpdateCategory($updateCategoryInput: UpdateCategoryInput!) {
    updateCategory(updateCategoryInput: $updateCategoryInput) {
      description
      id
      name
      type
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation RemoveCategory($removeCategoryId: Int!) {
    removeCategory(id: $removeCategoryId) {
      id
    }
  }
`;

export const DELETE_MANY_CATEGORIES = gql`
  mutation RemoveCategory($ids: [Int!]!) {
    removeCategory(ids: $ids)
  }
`;
