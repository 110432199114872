import { useEffect, useRef } from 'react';

export const useDynamicHeightAdjustment = () => {
  const mainRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const mainElement = mainRef.current;
      const screenHeight = window.innerHeight;
      const mainYPosition = mainElement.getBoundingClientRect().top;
      const mainHeight = screenHeight - mainYPosition -60;
      mainElement.style.height = `${mainHeight}px`;
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return mainRef;
};
