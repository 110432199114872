import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { delay } from '../../utils/helpers';
import { storeContext } from '../../global/store';
import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import BottomBanner from '../../components/Actions/BottomBanner';
import PageModal from '../../components/PageModal';
import CreateServiceForm from '../../components/Services/createService/CreateServiceForm';
import * as Yup from 'yup';
import { handleCreateService } from '../../services/service';

const AddService = () => {
  const { store } = useContext(storeContext);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.service);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [images, setImages] = useState([]);

  const [fields, setFields] = useState({
    name: '',
    htmlBody: '',
    description: '',
    type: 'SERVICE',
    categories: [],
  });

  const addInstanceSchema = Yup.object().shape({
    name: Yup.string().required(t('This Field is required')),
    description: Yup.string(),
    htmlBody: Yup.string(),
    type: Yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: fields?.name,
      description: fields?.description,
      htmlBody: fields?.htmlBody,
      type: fields?.type,
      categories : fields?.categories,
      images: images,
    },
    validationSchema: addInstanceSchema,
    onSubmit: async (values) => {
      dispatch(
        handleCreateService(values, (res) => {
          if (res?.data?.createPage?.id) {
            delay(1000).then(async () => {
              navigate(`/services/edit/${res?.data?.createPage?.id}`);
            });
          }
        })
      );
    },
  });



  return (
    <PageModal pageName={t('Add Service')} pagePrev={`/service`}>
      <BottomBanner
        btn01='Cancel'
        btn01Action={() => navigate(`/products`)}
        btn02='Add Service'
        btn02Action={() => formik.handleSubmit()}
        loading={data?.loadingCreate}
      />

      <CreateServiceForm
        fields={fields}
        setFields={setFields}
        formik={formik}
        images={images}
        setImages={setImages}
      />
    </PageModal>
  );
};

export default AddService;
