import React, { useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { useTranslation } from "react-i18next";
import {  FilterIcon } from "../Svg/MainIcons";
import { ClickAwayListener, Collapse, FormControl } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import RadioGroupType from "../Inputs/RadioGroupType";
import {  useSearchParams, useLocation } from "react-router-dom";

const FilterButton = ({ options, sortBy, setSortBy }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const { bodyStyles } = useStateContext();
  const { t, i18n } = useTranslation();
  const [OpenFilter, setOpenFilter] = useState(false);




  const filter = (e, value) => {
    e.preventDefault()
   let newQueryParameters = new URLSearchParams(location.search);
   newQueryParameters.set("order", value);
   setSearchParams(newQueryParameters)
  }

  return (
    <ClickAwayListener onClickAway={() => setOpenFilter(false)}>
      <div>
        <div
          className={`flex flex-row items-center justify-start gap-5 capitalize 
        rounded-[4px] border h-full  group duration-300
        cursor-pointer w-[55px] md:w-[120px] min-h-[42px]
        ${i18n.language === "ar" ? "pr-4" : "pl-4 "}
        `}
          style={{ borderColor: bodyStyles?.basicButtonBorder }}
          onClick={() => {
            setOpenFilter(!OpenFilter);
          }}
        >
          <FilterIcon color={bodyStyles?.basicButtonText} />
          <span
            className="font-normal  text-md duration-300 hidden md:block "
            style={{ color: bodyStyles?.basicButtonText }}
          >
            {t("Filter")}
          </span>
        </div>

        <Collapse in={OpenFilter} timeout={{ enter: 100, exit: 200 }}>
          <div className="relative z-[999] bg-white w-full flex items-center justify-center ">
            <div
              className={` absolute top-0  border w-[250px] mt-1 py-4 px-6 shadow-inner
              drop-shadow-xl rounded bg-white space-y-4
              ${i18n.language === "ar" ? "left-0 " : "right-0"}
              `}
            >
              <div className="w-full flex justify-between items-center">
                <div
                  className={`w-[20px] h-[20px] absolute  top-2 cursor-pointer
                rounded-full flex justify-center items-center
              ${i18n.language === "ar" ? "left-2" : "right-2"}
                
                `}
                >
                  <div
                    className={`cursor-pointer rounded-full w-10
                        duration-300 hover:bg-[rgba(0,0,0,0.089)] p-1 `}
                  >
                    <CloseIcon
                      onClick={() => {
                        setOpenFilter(false);
                      }}
                      className="text-gray cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <FormControl>
                <RadioGroupType
                  value={sortBy}
                  onChange={(e) => {
                    setSortBy(e.target.value);
                    filter(e, e.target.value )
                  }}
                  gapY={0}
                  options={options}
                />
              </FormControl>
            </div>
          </div>
        </Collapse>
      </div>
    </ClickAwayListener>
  );
};

export default FilterButton;
