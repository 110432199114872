import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  ReferenceLine,
} from "recharts";
import { useTranslation } from "react-i18next";

const TotalSeesionsAnalytics = ({ data, color }) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      // className={`text-center  ${i18n.language === "ar" ? "pl-4" : "pr-4"}`}
    >
      <ResponsiveContainer className={`m-0 p-0 `}>
        <ComposedChart
          width={500}
          height={400}
          data={data}
          className={` 
              ${i18n.language === "ar" ? "-mr-5" : "-ml-5"}
              `}
          orientation={i18n.language === "ar" ? "right" : "left"}
        >
          <Tooltip />

          <Line
            dataKey="product1"
            strokeWidth={3}
            type="linear"
            dot={{
              stroke: "#165BAA",
              strokeWidth: 1,
              r: 3.5,
              strokeDasharray: "",
              fill: "#165BAA",
            }}
            stroke="#165BAA"
            fill="#165BAA"
            name={"vistors"}
            reversed={true}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TotalSeesionsAnalytics;
