import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';

import StoreProvider from './global/StoreProvider';
import { ApolloProvider } from '@apollo/client';
import { client } from './global/apolloClient';

import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import './i18n';
import { MapContext } from './contexts/MapContext';

import * as Sentry from '@sentry/react';
import { generalData } from './utils/general';

import { Provider } from 'react-redux'
import store from './store/store'
import { HelmetProvider } from 'react-helmet-async';

Sentry.init({
  dsn: generalData?.sentryDsn,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <StoreProvider>
            <ContextProvider>
              <MapContext>
              <HelmetProvider>
                <App />
              </HelmetProvider>

              </MapContext>
            </ContextProvider>
          </StoreProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
