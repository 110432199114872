export const regroupWithContinent = (countries, searchCountires) => {
  let newList = [];
  countries
    .filter((story) =>
      story?.translationData[0].name
        ?.toLocaleLowerCase()
        .includes(searchCountires?.toLocaleLowerCase())
    )
    .map((item, index) => {
      if (item.continent) {
        if (newList.find((c) => c.region === item.continent)) {
          newList.forEach((element, index) => {
            if (element.region === item.continent) {
              newList[index].countries.push(item);
            }
          });
        } else {
          newList.push({ region: item.continent, countries: [item] });
        }
      }
    });

  return newList ? newList : []
};
