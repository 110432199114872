import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingDelete: false,
  loadingDeleteAll: false,
  loadingCreate: false,
  loadingEdit: false,
  loadingImport: false,
  data: [],
  error: null,
  errorFetch: null,
  success: null,
  totalCount: 0,
  selectedOne: null,
  selectedMany: [],
  isSelectedMany: false,
  deleteModal: false,
  deleteAllModal: false,
  createModal: false,
  createAllModal: false,
  editModal: false,
  editAllModal: false,
  exportModal: false,
  exportSelectedModal: false,
  importModal: false,
  loadingAssignRoles : false
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // fetchInstance
    fetchUsersStart: (state) => {
      state.loading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload?.users?.nodes;
      state.totalCount = action.payload?.users?.totalCount;
      state.errorFetch = false;
    },
    fetchUsersFail: (state, action) => {
      state.loading = false;
      state.data = [];
      state.errorFetch = action.payload;
    },

    // createInstance
    createUserStart: (state) => {
      state.loadingCreate = true;
    },
    createUserSuccess: (state, action) => {
      state.loadingCreate = false;
      state.data.unshift(action.payload);
      state.success = 'User created successfully.';
    },
    createUserFail: (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    },


    assignRolesStart: (state) => {
      state.loadingAssignRoles = true;
    },
    assignRolesSuccess: (state, action) => {
      state.loadingAssignRoles= false;
      state.success = 'Roles Assigned successfully.';
    },
    assignRolesFail: (state, action) => {
      state.loadingAssignRoles = false;
      state.error = action.payload;
    },

    // editInstance
    editUserStart: (state) => {
      state.loadingEdit = true;
    },
    editUserSuccess: (state, action) => {
      state.loadingEdit = false;
      state.data = state.data.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
      state.success = 'User updated successfully.';
    },
    editUserFail: (state, action) => {
      state.loadingEdit = false;
      state.error = action.payload;
    },

    // delete Instance
    deleteUserStart: (state) => {
      state.loadingDelete = true;
    },
    deleteUserSuccess: (state, action) => {
      state.loadingDelete = false;
      state.data = state.data.filter((user) => user.id !== action.payload);
      state.success = 'User deleted successfully';
      state.deleteModal = false;
    },
    deleteUserFail: (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
      state.deleteModal = false;
    },

    // delete Many Instances
    deleteUsersStart: (state) => {
      state.loadingDeleteAll = true;
    },
    deleteUsersSuccess: (state, action) => {
      state.loadingDeleteAll = false;
      const deletedIds = action.payload;
      state.data = state.data.filter((user) => !deletedIds.includes(user.id));
      state.success = 'Users deleted successfully';
      state.deleteAllModal = false;
    },
    deleteUsersFail: (state, action) => {
      state.loadingDeleteAll = false;
      state.error = action.payload;
      state.deleteAllModal = false;
    },

    // selectInstance

    selectOne: (state, action) => {
      state.selectedOne = action.payload;
    },

    selectAll: (state, action) => {
      if (state.isSelectedMany) {
        state.selectedMany = [];
        state.isSelectedMany = false;
      } else {
        state?.data?.map((item) => {
          if (!state.selectedMany.includes(item.id)) {
            state.selectedMany = [...state.selectedMany, item.id];
          }
        });
        state.isSelectedMany = true;
      }
    },

    removeAll: (state, action) => {
      state.selectedMany = [];
      state.isSelectedMany = false;
    },

    updateSelected: (state, action) => {
      if (state.selectedMany.includes(action.payload)) {
        state.selectedMany = state.selectedMany.filter((item) => {
          return item !== action.payload;
        });
      } else {
        state.selectedMany = [...state.selectedMany, action.payload];
      }
    },

    // DELETEiNSTANCE

    openModelDelete: (state, action) => {
      state.selectedOne = action.payload;
      state.deleteModal = !state.deleteModal;
    },

    openModelDeleteAll: (state, action) => {
      state.deleteAllModal = !state.deleteAllModal;
    },


    openModelCreate: (state, action) => {
      state.createModal = !state.createModal;
    },

    openModelEdit: (state, action) => {
      state.selectedOne = action.payload;
      state.editModal = !state.editModal;
    },
    // ExportSelected

    openModeleExportAll: (state, action) => {
      state.exportModal = true;
    },

    openModeleExportSelected: (state, action) => {
      state.exportSelectedModal = true;
    },

    // Import

    openModeleImport: (state, action) => {
      state.importModal = !state.importModal;
    },

    importStart: (state) => {
      state.loadingImport = true;
    },
    importSuccess: (state, action) => {
      state.loadingImport = false;
      state.success = 'Users Imported successfully';
      state.importModal = false;
    },
    importFail: (state, action) => {
      state.loadingImport = false;
      state.error = action.payload;
      state.importModal = false;
    },

    // CLEAR
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  createUserStart,
  createUserSuccess,
  createUserFail,
  editUserStart,
  openModelEdit,
  openModelCreate,
  editUserSuccess,
  editUserFail,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFail,
  clearMessages,
  updateSelected,
  selectAll,
  selectOne,
  openModelDelete,
  openModelDeleteAll,
  openModeleExportAll,
  openModeleExportSelected,
  deleteUsersStart,
  deleteUsersSuccess,
  deleteUsersFail,
  openModeleImport,
  importStart,
  importSuccess,
  importFail,
  removeAll,
  assignRolesStart,
assignRolesSuccess,
assignRolesFail
} = userSlice.actions;

export default userSlice.reducer;
