import React from "react";
import DataGrid from "../../DataGrid";
import BasicInput from "../../Inputs/BasicInput";
import { useStateContext } from "../../../contexts/ContextProvider";
import { defaultDatat } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../Svg/MainIcons";
import { ButtonBase } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";



const ProductOrderDataGrid = ({ products, setProducts, setInventory }) => {
    const {  i18n } = useTranslation();

    const { bodyStyles } = useStateContext();

  const tableData = [
    {
      title: "",
      key: "Image",
      width: "10%",
      elemnt: function (variant) {
        return (
          <div className="col-span-2 flex items-start gap-x-3 py-1">
            <div
              style={{
                borderColor: bodyStyles?.borderColor,
              }}
              className=" w-14 h-14 border rounded p-1 flex justify-center items-center"
            >
              <img
                className=" block w-full h-full rounded-md"
                src={
                  variant?.media?.full_url
                    ? variant?.media?.full_url
                    : variant?.product?.media[0]
                        ?.full_url
                    ? variant?.product?.media[0]
                        ?.full_url
                    : "/images/empty-modal.png"
                }
                alt="product"
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Product name",
      key: "Product name",
      width: "30%",
      elemnt: function (variant) {
        return (
          <div className="col-span-2 flex items-start gap-x-3 py-1 flex-wrap">
            <div className="space-y-1  text-base">
              <div className="flex flex-col">
                <p className=" text-sm break-all">
                  {/* ss */}
                  {defaultDatat(
                    variant?.product?.translationData,
                    i18n.language,
                    "name"
                  )}
                </p>
                <div className="flex items-center gap-[1px] text-xs flex-wrap break-all">
                  {variant?.options?.length > 0 && (
                    <>
                      (
                      {variant?.options?.map(
                        (opt, index) => (
                          <span className="text-sm" key={index}>
                            {defaultDatat(
                              opt?.translationData,
                              i18n.language,
                              "name"
                            )}
                            {variant?.options?.length !=
                              index + 1 && (
                              <span className="px-1">
                                /
                              </span>
                            )}
                          </span>
                        )
                      )}
                      )
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },

    {
      title: "SKU",
      key: "sku",
      width: "20%",
      elemnt: function (variant) {
        return (
          <div className="flex items-start  py-1 break-all">
            {variant?.sku ? variant?.sku : "-"}
          </div>
        );
      },
    },

    {
      title: "Price",
      key: "price",
      width: "20%",
      elemnt: function (variant) {
        return (
          <p className=" font-normal">
            <BasicInput
              value={variant.pricing}
              onChange={(e) => {}}
              disabled
              disabledColor="#358DEB"
              size="small"
              priceFormatSepration="."
              borderNone
              variant="standard"
              InputProps={{
                disableUnderline: true,
                inputProps: {
                  style: {
                    fontSize: "1.05rem",
                  },
                },
              }}
            />
          </p>
        );
      },
    },

    {
      title: "Quantity",
      key: "quantity",
      width: "190px",
      elemnt: function (item) {
        return (
          <AvalibleColumn
            item={item}
            setInventory={setProducts}
          />
        );
      },
    },

    {
      title: `Total`,
      key: "total",
      width: "20%",
      elemnt: function (variant) {
        return (
          <div className="text-bold">
            <BasicInput
              value={(variant.quantity
                ? (variant.quantity
                    ? variant.quantity
                    : 1) *
                  (variant.pricing
                    ? variant.pricing
                    : 0)
                : variant.pricing
                ? variant.pricing
                : 0
              )
                .toFixed(2)}
              onChange={(e) => {}}
              disabled
              disabledColor=""
              size="small"
              priceFormatSepration="."
              borderNone
              variant="standard"
              InputProps={{
                disableUnderline: true,
                inputProps: {
                  style: {
                    fontSize: "1.2rem",
                  },
                },
              }}
            />
          </div>
        );
      },
    },

    {
      title: "",
      key: "action",
      width: "5%",
      elemnt: function (item) {
        return (
          <div
            className="cursor-pointer flex justify-end"
            onClick={() =>
              setProducts((current) =>
                current.filter((product) => {
                  return product.id !== item.id;
                })
              )
            }
          >
            <CloseIcon />
          </div>
        );
      },
    },
  ]

  return <DataGrid data={products} fildesDis={tableData} />;
};

export default ProductOrderDataGrid;




const AvalibleColumn = ({ item, setInventory }) => {
    const { bodyStyles } = useStateContext();
  
    const upadateStock = (e, type) => {
      setInventory((prevState) => {
        const newState = prevState.map((obj) => {
          if (obj.id === item.id) {
            return {
              ...obj,
              quantity:
                type === 1
                  ? parseInt(obj.quantity ? obj.quantity : 1) + 1
                  : type === -1
                  ? parseInt(obj.quantity) - 1
                  : e.target.value,
            };
          }
          return obj;
        });
  
        return newState;
      });
    };
  
    return (
      <div className="flex gap-5 justify-between items-center px-1">
        <div className="">
          <div
            style={{ borderColor: bodyStyles?.subText }}
            className=" rounded border text-md  h-full  
                          cursor-pointer w-[90%]   flex justify-between px-2 items-center"
          >
            <div className=" text-center text-lg flex items-center  justify-center">
           
              <div className="w-[60px] cursor-default">
                {item.quantity ? item.quantity : 1}
              </div>
            </div>
            <div className="w-1/3 font-thin">
              <ButtonBase
                onClick={(e) => {
                  item.quantity
                    ? item.quantity <
                        +(item.stockOnHand - item.outOfStockThreshold) &&
                      upadateStock(e, 1)
                    : upadateStock(e, 1);
                }}
              >
                <KeyboardArrowUpIcon />
              </ButtonBase>
              <ButtonBase
                onClick={(e) => item.quantity > 1 && upadateStock(e, -1)}
              >
                <KeyboardArrowDownIcon className="" />
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
    );
  };