import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { storeContext } from '../../global/store';
import { CircularProgress } from '@mui/material';
import { useStateContext } from '../../contexts/ContextProvider';
import NonAuthorized from './NonAuthorized';
import { hasPermission } from '../../utils/permissions';

const PrivateRoute = ({ children, permissions, skipPermissions }) => {
  const {
    store,
    setStore,
    unSeen,
    open: openSection,
  } = useContext(storeContext);

  const { bodyStyles } = useStateContext();
  const navigate = useNavigate();

  const [pending, setPending] = useState(true);

  useEffect(() => {
    if (store) {
      if (store.isLoading) {
        setPending(true);
      } else {
        if (store && store.isLogged) {
          setPending(false);
        } else {
          navigate('/login');
        }
      }
    }
  }, [store]);

  return (
    <div
      style={{
        background: bodyStyles?.background,
        color: bodyStyles?.text,
      }}
    >
      {pending ? (
        <div className='flex justify-center items-center w-full h-screen'>
          <CircularProgress
            style={{
              width: 40,
              height: 40,
              color: bodyStyles?.primary,
            }}
          />
        </div>
      ) : (
        <div>
          {store && !store.loadingPermissions && store?.isLogged ? (
            <div>
              {skipPermissions ||
              permissions?.some((per) =>
                hasPermission(store?.permissions, per, store.usePermissions)
              ) ? (
                <>{children}</>
              ) : (
                <NonAuthorized />
              )}
            </div>
          ) : (
            <div className='flex justify-center items-center w-full h-screen'>
              <CircularProgress
                style={{
                  width: 40,
                  height: 40,
                  color: bodyStyles?.primary,
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PrivateRoute;
