import { gql } from "@apollo/client";

export const CREATE_CHECKOUT = gql`
  mutation CreateCheckout($createCheckoutInput: CreateCheckoutInput!) {
    createCheckout(createCheckoutInput: $createCheckoutInput) {
      id
      paymentStatus
      phoneNumber
      email
      total
      updatedAt
      orderId
      paymentMethod
      createdAt
      deletedAt
    }
  }
`;

export const SEND_RECOVRERY_EMAIL = gql`
  mutation ($checkoutId: Int!) {
    sendRecoveryEmail(checkoutId: $checkoutId) {
      message
      order
    }
  }
`;
