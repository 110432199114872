import React from 'react';
import { EditIcon, TrashIcon } from '../../Svg/MainIcons';

const InstanceEditDelte = ({ openModalDelte, openModalEdit }) => {
  return (
    <div className='flex gap-1.5'>
      <div
        className='flex cursor-pointer'
        onClick={() => {
          openModalEdit();
        }}
      >
        <EditIcon color={'#414141'} />
      </div>

      <div
        className='flex cursor-pointer'
        onClick={() => {
          openModalDelte();
        }}
      >
        <TrashIcon color={'#EB5757'} />
      </div>
    </div>
  );
};

export default InstanceEditDelte;
