import React from 'react';
import Tabs from '.././../../TabsScreens';
import { useTranslation } from 'react-i18next';
import Staff from './Staff/Staff';
import Profile from './Profile';
import Password from './Password';

const Account = () => {
  const { t } = useTranslation();

  const tabs = [
    t('Account Information'),
    // t("Recent Login History"),
    t('Staff Accounts'),
  ];
  return (
    <div className='px-6 relative'>
      <Tabs screens={tabs} sticky>
        <div key={0} className='font-urbanist mt-5 space-y-16 md:mt-8'>
          <Profile />
          <Password />
        </div>
        {/* <div key={1} className="space-y-6 font-urbanist mt-5 md:mt-8">
          <LoginHistory />
        </div> */}
        <div key={2} className='font-urbanist mt-5 space-y-6 md:mt-4'>
          <Staff />
        </div>
      </Tabs>
    </div>
  );
};

export default Account;
