import axios from "axios";
import { generalData } from "../utils/general";

export const getInvoice = async (id) => {

  try {
    const response = await axios({
      method: "get",
      url: `https://${generalData.backendApi}/checkout/invoice/${id}`,
      responseType: 'blob'

    });
    return response;
  } catch (error) {
    return null;
  }
};

