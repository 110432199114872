import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  successMessage: null,
  errorMessage: null,
  successSettingMessage: null,
  errorSettingMessage: null,

  successModalMessage: null,
  errorModalMessage: null,
  position: null,
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
      state.errorMessage = null;
    },
    showErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
      state.successMessage = null;
    },

    showSuccessSettingMessage: (state, action) => {
      state.successSettingMessage = action.payload;
      state.errorSettingMessage = null;
    },
    showErrorSettingMessage: (state, action) => {
      state.errorSettingMessage = action.payload;
      state.successSettingMessage = null;
    },

    showSuccessModalMessage: (state, action) => {
      state.successModalMessage = action.payload;
      state.errorModalMessage = null;
    },
    showErrorModalMessage: (state, action) => {
      state.errorModalMessage = action.payload;
      state.successModalMessage = null;
    },

    updateModalPosition: (state, action) => {
      state.position = action.payload;
    },
    clearMessages: (state) => {
      state.successMessage = null;
      state.errorMessage = null;
      state.successSettingMessage = null;
      state.errorSettingMessage = null;
      state.successModalMessage = null;
      state.errorModalMessage = null;
    },
  },
});

export const {
  showSuccessMessage,
  showErrorMessage,
  showSuccessSettingMessage,
  showErrorSettingMessage,
  showSuccessModalMessage,
  showErrorModalMessage,
  clearMessages,
} = snackbarSlice.actions;

export default snackbarSlice.reducer;
