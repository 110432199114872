import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  ReferenceLine,
} from "recharts";
import { useTranslation } from "react-i18next";

const AreaChart = ({data, lineTitle}) => {
  const { t, i18n } = useTranslation();
  
  
  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <div className="flex gap-6 w-full flex-wrap">
        {payload.map((entry, index) => (
          <div className="flex gap-2 items-center" key={index}>
            <div
              className="w-[25px] h-[9px] "
              style={{
                backgroundColor: entry.color,
              }}
            ></div>
            <div className="font-[500] ">{entry.value}</div>
          </div>
        ))}
      </div>
    );
  };



  return (
    <div
      style={{ width: "100%", height: 300 }}
    >
      <ResponsiveContainer>
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          className={` 
          ${i18n.language === "ar" ? "-mr-5" : "-ml-5"}
          `}
        >
          <CartesianGrid stroke="#ECECEC" />
          <XAxis   dataKey="name"
            tickLine={false}
            reversed={i18n.language === "ar" ? true : false} />
          <YAxis
          tickLine={false}
          orientation={i18n.language === "ar" ? "right" : "left"}
          style={{ direction: "ltr" }}
          />
          <Tooltip />
          <Legend
            iconType="wye"
            iconSize={22}
            width="100%"
            align="rect"
            wrapperStyle={{
              paddingLeft: i18n.language !== "ar" && "12%",
              paddingRight: i18n.language === "ar" && "22%",
              paddingTop: "10px",
            }}
            content={renderLegend}

          />
          <ReferenceLine y={0} stroke="#000" />
          {/* <Area
            dataKey="product1"
            type=""
            // type="monotone" 
            fillOpacity={1}
            stroke="#165BAA"
            fill="#165BAA"
            name={lineTitle}
            

          /> */}
          <Line
            type=""
            dataKey="product1"
            stroke="#165BAA"
            // strokeDasharray="8"
            strokeWidth={3}
            activeDot={{ r: 5 }}
            dot={{
              stroke: "#165BAA",
              strokeWidth: 1,
              r: 4,
              strokeDasharray: "",
              fill: "#165BAA",
            }}
            name={lineTitle}

          />
          {/* 
          <Line
            type=""
            dataKey="product3"
            stroke="#A155B9"
            // strokeDasharray="8"
            strokeWidth={3}
            activeDot={{ r: 5 }}
            dot={{
              stroke: "#A155B9",
              strokeWidth: 1,
              r: 4,
              strokeDasharray: "",
              fill: "#A155B9",
            }}
            name="Product 3"
          /> */}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaChart;


