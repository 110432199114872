import React, { Fragment, useState } from "react";
import TabsLanguages from "./../../../TabsLanguages";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useStateContext } from "../../../../contexts/ContextProvider";
import { InputAdornment } from "@mui/material";
import BasicInput from "../../../Inputs/BasicInput";
import { useTranslation } from "react-i18next";
import ModalDialog from "../../../ModalDialog";
import {
  availableLanguages,
  preferredLanguage,
} from "../../../../utils/languages";

const ManualPaymentMethodModal = ({
  open,
  setOpen,
  methodesSelect,
  setMethodesSelect,
  title,
  defaultName,
  value,
}) => {
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();

  const [translationData, setTranslationData] = useState({
    [preferredLanguage()]: {
      name: defaultName ? defaultName : "",
      details: "",
      paymentInstructions: "",
    },
  });

  const addManualPaymentMethod = Yup.object().shape({
    translationData: Yup.object().shape({
      [preferredLanguage()]: Yup.object().shape({
        name: Yup.string().required("This Field is required"),
        details: Yup.string().required("This Field is required"),
        paymentInstructions: Yup.string().required("This Field is required"),
      }),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      translationData: translationData,
    },
    validationSchema: addManualPaymentMethod,
    onSubmit: async (values) => {
      setMethodesSelect((current) => [
        ...current,
        {
          value: value,
          translationData: translationData,
        },
      ]);

      setTranslationData({
        [preferredLanguage()]: {
          name: defaultName ? defaultName : "",
          details: "",
          paymentInstructions: "",
        },
      });

      setOpen(false);

      // setLoading(false);
    },
  });

  return (
    <ModalDialog
      open={open}
      setOpen={setOpen}
      title={title}
      cancelTitle={t("Cancel")}
      cancel={() => setOpen(false)}
      actionTitle={t("Active")}
      action={() => formik.handleSubmit()}
      className="p-0 m-0"
    >
      <div className="flex flex-col font-Inter gap-4  p-0 m-0   ">
        <div className="">
          <TabsLanguages
            availableLanguages={availableLanguages}
            small
            fixed
            error={formik.errors}
          >
            {availableLanguages?.map((item, indexLanguages) => (
              <div key={indexLanguages} className="font-urbanist">
                <div className="w-[98%]">
                  <div
                    className="font-Inter text-sm pt-4 pb-4 mt-4
                                      uppercase font-bold flex items-center justify-start"
                    style={{
                      color: bodyStyles.subTitle,
                    }}
                  >
                    <span>{`${t("Payment Method information")}`}</span>
                  </div>

                  <div className="flex flex-col  gap-6 ">
                    <BasicInput
                      label={t("Custom payment method name ")}
                      placeholder="Name"
                      size="small"
                      value={translationData[item.code]?.name}
                      onChange={(e) => {
                        setTranslationData({
                          ...translationData,
                          [item?.code]: {
                            ...translationData[item?.code],
                            name: e.target.value,
                          },
                        });
                      }}
                      error={
                        item?.code === preferredLanguage() &&
                        formik?.touched?.translationData &&
                        Boolean(
                          formik?.errors?.translationData &&
                            formik?.errors?.translationData[preferredLanguage()]
                              ?.name
                        )
                      }
                      helperText={
                        item?.code === preferredLanguage() &&
                        formik?.touched?.translationData &&
                        formik?.errors?.translationData &&
                        formik?.errors?.translationData[preferredLanguage()]
                          ?.name
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            fontSize="small"
                            position="end"
                            sx={{ color: bodyStyles?.inputText }}
                          >
                            <span className="text-xs">
                              {item?.code?.toUpperCase()}
                            </span>
                          </InputAdornment>
                        ),
                        style: { fontSize: "12px" },
                      }}
                    />

                    <div>
                      <BasicInput
                        label={t("Additional details")}
                        size="small"
                        placeholder={t("Details")}
                        rows={4}
                        value={translationData[item.code]?.details}
                        onChange={(e) => {
                          setTranslationData({
                            ...translationData,
                            [item?.code]: {
                              ...translationData[item?.code],
                              details: e.target.value,
                            },
                          });
                        }}
                        error={
                          item?.code === preferredLanguage() &&
                          formik?.touched?.translationData &&
                          Boolean(
                            formik?.errors?.translationData &&
                              formik?.errors?.translationData[
                                preferredLanguage()
                              ]?.details
                          )
                        }
                        helperText={
                          item?.code === preferredLanguage() &&
                          formik?.touched?.translationData &&
                          formik?.errors?.translationData &&
                          formik?.errors?.translationData[preferredLanguage()]
                            ?.details
                        }
                      />
                      <span className="px-1">
                        {t(
                          "Displays to customers when they’re choosing a payment method."
                        )}
                      </span>
                    </div>

                    <div>
                      <BasicInput
                        label={t("Payment instructions")}
                        size="small"
                        placeholder={t("instructions")}
                        rows={4}
                        value={translationData[item.code]?.paymentInstructions}
                        onChange={(e) => {
                          setTranslationData({
                            ...translationData,
                            [item?.code]: {
                              ...translationData[item?.code],
                              paymentInstructions: e.target.value,
                            },
                          });
                        }}
                        error={
                          item?.code === preferredLanguage() &&
                          formik?.touched?.translationData &&
                          Boolean(
                            formik?.errors?.translationData &&
                              formik?.errors?.translationData[
                                preferredLanguage()
                              ]?.paymentInstructions
                          )
                        }
                        helperText={
                          item?.code === preferredLanguage() &&
                          formik?.touched?.translationData &&
                          formik?.errors?.translationData &&
                          formik?.errors?.translationData[preferredLanguage()]
                            ?.paymentInstructions
                        }
                      />
                      <span className="px-1">
                        {t(
                          "Displays to customers after they place an order with this payment method."
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TabsLanguages>
        </div>
      </div>
    </ModalDialog>
  );
};

export default ManualPaymentMethodModal;
