import { gql } from "@apollo/client";

export const CREATE_VARIANT = gql`
  mutation CreateVariant($input: CreateVariantInput!) {
    createVariant(input: $input) {
      id
      createdAt
      updatedAt
      pricing
      sku
      stockAllocated
      stockOnHand
      isEnabled
      deletedAt
      currencyCode
      outOfStockThreshold
      media {
        alt
        createdAt
        deletedAt
        full_url
        file_name
        host
        id
        original_name
        path
        type
        updatedAt
      }
    }
  }
`;


export const CREATE_MANY_VARIANT = gql`
mutation CreateManyVariant($input: CreateManyVariantsInput!) {
  createManyVariant(input: $input) {
    createdAt
    currencyCode
    deletedAt
    compareAtPrice
    isEnabled
    id
    media {
      alt
    }
    sku
    pricing
    stockAllocated
    stockOnHand
    updatedAt
  }
}
`;

export const DELETE_VARIANT = gql`
  mutation RemoveVariant($removeVariantId: Int!) {
    removeVariant(id: $removeVariantId)
  }
`;

export const EDIT_VARIANT = gql`
mutation UpdateVariant($input: UpdateVariantInput!) {
  updateVariant(input: $input) {
    id
    pricing
    options {
      createdAt
      group {
        createdAt
        id
        translationData {
          createdAt
          description
          languageCode
          id
          name
          slug
          updatedAt
        }
        updatedAt
      }
      id
      translationData {
        createdAt
        description
        id
        languageCode
        name
        slug
        updatedAt
      }
    }
    sku
    stockOnHand
    updatedAt
    stockAllocated
    outOfStockThreshold
    deletedAt
    currencyCode
    createdAt
    media {
      alt
      createdAt
      deletedAt
      full_url
      file_name
      host
      id
      original_name
      path
      type
      updatedAt
    }
  }
}
`;
