import React from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
const FiledButton = ({
  icon,
  background,
  color,
  bgHoverColor,
  size,
  text,
  borderRadius,
  width,
  type,
  onClick,
  form,
  loading,
  fullWidth,
  fullHeight,
  minHeight,
  minWidth,
  textSmall,
  disabled,
}) => {
  const { bodyStyles } = useStateContext();
  const { t } = useTranslation();

  return (
    <button
      type={type}
      form={form}
      onClick={onClick}
      className={` rounded-md  px-4  flex items-center justify-center 

        font-semibold border-[1.5px] min-h-7 py-2 cursor-pointer font-Inter
        ${fullWidth ? "w-full" : ""}
        ${fullHeight ? "h-full" : ""} 
        ${disabled ? "opacity-60 cursor-default" : ""} 
        text-normal capitalize  
      `}
      style={{
        backgroundColor: background
          ? background
          : bodyStyles?.filedButtonBackground,
        color: color ? color : bodyStyles?.filedButtonText,
        borderColor: background ? background : bodyStyles?.filedButtonBorder,
        minHeight: minHeight ? minHeight : "",
        minWidth: minWidth ? minWidth : "",
      }}
      disabled={(loading ? true : false) || disabled}
    >
      {loading ? (
        <CircularProgress
          style={{
            width: 15,
            height: 15,
            color: bodyStyles?.background,
          }}
        />
      ) : (
        <div className="flex gap-x-3 items-center">
          {icon ? icon : ""} {t(text)}
        </div>
      )}
    </button>
  );
};

export default FiledButton;
