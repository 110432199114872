import { client } from "../global/apolloClient";
import {
  REFRESH_TOKEN,
  RESET_PASSWORD,
  LOGIN,
  CREATE_STAFF,
} from "../graphql/mutation/auth.mutation";
import { GET_ALL_USERS } from "../graphql/query/users.query";

export const login = async (values) => {
  try {
    //login
    const result = await client.mutate({
      mutation: LOGIN,
      variables: {
        ...values,
      },
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const resetPassword = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: RESET_PASSWORD,
      variables: {
        ...values,
      },
      context: { accessToken },
    });

    return result;
  } catch (error) {
    return error.message;
  }
};



export const createStaff = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: CREATE_STAFF,
      variables: {
        ...values,
      },
      context: { accessToken },
      refetchQueries: [GET_ALL_USERS],

    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const getAccessWithRefresh = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: REFRESH_TOKEN,
      variables: {
        ...values,
      },
      context: { accessToken },
      
    });

    return result;

  } catch (error) {
    return error.message;
  }
};

export function storeTheToken(token, refresh) {
  localStorage.setItem("token", token);
  localStorage.setItem("refresh", refresh);
}



export function storeTheUser(data) {
  localStorage.setItem("user", JSON.stringify(data));
}

export function getTheUserFromStorage() {
  if (typeof window !== "undefined")
    return JSON.parse(localStorage.getItem("user"));
}

export function getTheTokenFromStorage() {
  if (typeof window !== "undefined") return localStorage.getItem("token");
}

export function getTheRefresh() {
  if (typeof window !== "undefined") return localStorage.getItem("refresh");
}

export function deleteTheUserAndTokenFromStorage() {
  localStorage.removeItem("token"); 
  localStorage.removeItem("refresh");
  localStorage.removeItem("user");
}
