import { constants } from '../constants';
import { client } from '../global/apolloClient';
import { GET_ALL_PERMISSIONS } from '../graphql/query/permissions.query';
import {
  fetchPermissionsFail,
  fetchPermissionsStart,
  fetchPermissionsSuccess,
} from '../store/features/permission/permissionSlicer';

import { getTheTokenFromStorage } from './auth';
import { executeMutation } from './executeMutation';

const token = getTheTokenFromStorage();

export const createPermission = async (values, accessToken) => {};

export const editPermission = async (values, accessToken) => {};

export const deletePermission = async (values, accessToken) => {};

export const deleteManyPermission = async (values, accessToken) => {};

export const fetchPermissions = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchPermissionsStart());
      const { data } = await client.query({
        query: GET_ALL_PERMISSIONS,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchPermissionsSuccess(data));
    } catch (error) {
      dispatch(fetchPermissionsFail(error));
    }
  };
};

export const handleDeletePermission = (id) => {};

export const handleDeleteManyPermission = (ids) => {};

export const handleCreatePermission = (values, callback) => {};

export const handleEditPermission = (values, callback) => {};
