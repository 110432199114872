import { gql } from '@apollo/client';

export const CREATE_REFERENCE = gql`
  mutation CreateReference($createReferenceInput: CreateReferenceInput!) {
    createReference(createReferenceInput: $createReferenceInput) {
      customerFullName
      description
      id
      location
      media {
        id
        full_url
      }
      subDescription
    }
  }
`;

export const EDIT_REFERENCE = gql`
  mutation UpdateReference($updateReferenceInput: UpdateReferenceInput!) {
    updateReference(updateReferenceInput: $updateReferenceInput) {
      createdAt
      description
      id
      location
      media {
        full_url
        id
      }
      subDescription
    }
  }
`;

export const DELETE_REFERENCE = gql`
  mutation RemoveReference($removeReferenceId: Int!) {
    removeReference(id: $removeReferenceId) {
      id
    }
  }
`;

export const DELETE_MANY_REFERENCE = gql`
  mutation RemoveReference($ids: [Int!]!) {
    removeReference(ids: $ids)
  }
`;
