import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicButton from '../../Buttons/BasicButton';
import FiledButton from '../../Buttons/FiledButton';
import { useStateContext } from '../../../contexts/ContextProvider';

export const ImportContainer = (props) => {
  const { bodyStyles } = useStateContext();
  const { t } = useTranslation();
  
  return (
    <div className='w-full min-h-[60vh] flex justify-center items-center flex-col -mt-1'>
      {props?.emptyDataImage ? (
        <img
          src={props?.emptyDataImage}
          className='max-w-[70%] md:max-w-[290px]'
        />
      ) : (
        <div className='w-full flex justify-center'>
          <img
            src='/images/no-data.svg'
            className='max-w-[70%] md:max-w-[290px]'
          />
        </div>
      )}

      <div className='text-lg font-Inter flex flex-col justify-center items-center gap-2  text-justify'>
        <h2
          className='font-Inter text-xl pt-2'
          style={{ color: bodyStyles?.title }}
        >
          {t(props.emptyDataTitle)}
        </h2>

        <p
          className='font-Inter text-md max-w-[890px] text-center'
          style={{ color: bodyStyles?.subText }}
        >
          {t(props.emptyDataDescription)}
        </p>
      </div>

      <div className='flex gap-4 mt-4'>
        {props.importInstanceMessage && (
          <BasicButton
            text={t(props.importInstanceMessage)}
            onClick={props.importInstanceAction}
          />
        )}

        {props.addInstanceMessage && (
          <FiledButton
            text={t(props.addInstanceMessage)}
            onClick={props.addInstanceAction}
          />
        )}
      </div>
    </div>
  );
};
