import React, { useEffect, useState } from 'react';
import ModalNoDataFound from '../MessageModals/ModalNoDataFound';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../contexts/ContextProvider';
import BasicButton from '../Buttons/BasicButton';
import FiledButton from '../Buttons/FiledButton';
import TableWrapper from './TableWrapper';
import SkeletonTable from '../SkeletonTable';
import ModalFetchError from '../MessageModals/ModalFetchError';
import ModalDialog from '../ModalDialog';
import ImportModal from '../Modals/ImportModal';
import { useSearchParams } from 'react-router-dom';

const TableSection = (props) => {
  const { t, i18n } = useTranslation();
  const { bodyStyles } = useStateContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState([]);

  useEffect(() => {
    const params = [];
    setParams([]);
    for (let entry of searchParams.entries()) {
      setParams((prev) => [...prev, entry]);
    }
  }, [searchParams]);


  const [importInstanceAction, setImportInstanceAction] = useState(false);

  const updateChecks = (id) => {
    if (props.checked.includes(id)) {
      props.setChecked((current) =>
        current.filter((item) => {
          return item !== id;
        })
      );
    } else {
      props.setChecked((checked) => [...checked, id]);
    }
  };

  const selectAll = () => {
    if (props.checkAll) {
      props.setChecked([]);
      props.setCheckAll(!props.checkAll);
    } else {
      props.data?.map((item) => {
        if (!props.checked.includes(item.id)) {
          props.setChecked((checked) => [...checked, item.id]);
        }
      });
      props.setCheckAll(!props.checkAll);
    }
  };

  const deletAllSelected = async () => {
    props.setOpenModeleDeleteAll(true);
  };

  const exportAllSelected = () => {
    console.log('exportAllSelected', props.checked);
    props.setChecked([]);
    props.setCheckAll(false);
  };

  function checkParamsExists(params) {
    for (let i = 0; i < params.length; i++) {
      if (
        params[i][0] === 'status' ||
        params[i][0] === 'type' ||
        params[i][0] === 'page'
      ) {
        return true;
      }
    }
    return false;
  }

  return (
    <div className='w-full pb-3'>
      <div className='relative w-full'>
        <div
          className={`fixed z-[10]  flex  py-2 px-3  mt-1 border-t justify-end fill-available duration-500
                    ${props.showUpdateAll ? 'bottom-0' : '-bottom-[100px] '}
                     `}
          style={{
            backgroundColor: bodyStyles.background,
            borderColor: bodyStyles?.inputBorder,
            width: '-webkit-fill-available',
          }}
        >
          <div className='flex gap-2 h-[35px] '>
            <BasicButton
              text={props.updateAlllCancelTitle}
              onClick={() => props.updateAlllCancel}
              textSmall
            />
            {props.updateAlllLoading ? (
              <FiledButton loading />
            ) : (
              <FiledButton
                text={props.updateAlllActionTitle}
                minWidth='95px'
                onClick={props.updateAlllAction}
              />
            )}
          </div>
        </div>
      </div>

      <ImportModal
        open={importInstanceAction}
        setOpen={setImportInstanceAction}
        title={t(props.importInstanceModelTitle)}
        description={t(props.importInstanceModelDescription)}
        importInstance={props.importInstance}
        instanceState={props.instanceState}
        setInstanceState={props.setInstanceState}
        prevSetInstanceState={props.prevSetInstanceState}
        overrideLable={props.overrideLable}
      />

      <ModalDialog
        open={props.openModalDelete}
        setOpen={props.setOpenModalDelete}
        title={props.deleteInstanceTitle}
        cancelTitle={t('Cancel')}
        cancel={() => props.setOpenModalDelete(false)}
        actionTitle={props.deleteInstanceActionTitle}
        action={() => props.deleteInstanceAction()}
        loadingAction={props.instanceState?.loadingDeleteInstance}
        className='p-0 m-0'
      >
        <div className='rounded-md custom-border-color p-1 shadow-custom md:width-600 space-y-3 font-urbanist'>
          <p className='font-semibold '>
            {t(props.deleteInstanceDescription)}
            <span className='font-bold'> {props.instanceSelected?.id} </span>
          </p>
        </div>
      </ModalDialog>

      <ModalDialog
        open={props.openModeleDeleteAll}
        setOpen={props.setOpenModeleDeleteAll}
        title={props.deleteAllInstanceTitle && t(props.deleteAllInstanceTitle)}
        cancelTitle={t('Cancel')}
        cancel={() => props.setOpenModeleDeleteAll(false)}
        actionTitle={
          props.deleteAllInstanceActionTitle &&
          t(props.deleteAllInstanceActionTitle)
        }
        action={() => props.deleteAllInstanceAction()}
        loadingAction={props.instanceState?.loadingDeleteInstance}
        className='p-0 m-0'
      >
        <div className='rounded-md custom-border-color p-1 shadow-custom md:width-600 space-y-3 font-urbanist'>
          <p className='font-semibold '>
            {t(props.deleteAllInstanceDescription)}
            <span className='font-bold'>
              {' '}
              {props.checked.join(' - ').toString()}{' '}
            </span>
          </p>
        </div>
      </ModalDialog>

      {props.loading ? (
        <div className='w-full'>
          <SkeletonTable />
        </div>
      ) : props.error ? (
        <ModalFetchError
          message={props?.error}
          returnMessage={props.errorReturnMessage}
          action={props.errorAction}
          errorStatus={
            props?.error?.graphQLErrors[0]?.extensions?.response?.statusCode ||
            props?.error?.graphQLErrors[0]?.extensions?.exception?.status
          }
        />
      ) : (
        <div className='flex flex-col items-center justify-center w-full '>
          {props.data?.length > 0 ? (
            <TableWrapper
              data={props.data}
              fildesDis={props.fildesDis}
              checkAll={props.checkAll}
              selectAll={selectAll}
              productSelected={props.instanceSelected}
              setProductSelected={props.setInstanceSelected}
              setOpenModalDelete={props.setOpenModalDelete}
              checked={props.checked}
              updateChecks={updateChecks}
              deletAllSelected={
                props.setOpenModeleDeleteAll ? deletAllSelected : null
              }
              exportAllSelected={props.exportAllSelected && exportAllSelected}
              instancesCount={props.instancesCount}
              pageSize={props.pageSize}
              setPage={props.setPage}
              page={props.page}
              localPagenation={props.localPagenation}
            />
          ) : 
          props.data?.length == 0 &&
            (props.search || checkParamsExists(params)) ? (
            <ModalNoDataFound
              message={props.dataNotFoundMessage}
              returnMessage={props.dataNotFoundReturnMessage}
              action={() => props.dataNotFoundAction}
            />
          ) : (
            <div className='w-full min-h-[60vh] flex justify-center items-center flex-col -mt-1'>
              {props?.emptyDataImage ? (
                <img
                  src={props?.emptyDataImage}
                  className='max-w-[70%] md:max-w-[290px]'
                />
              ) : (
                <div className='w-full flex justify-center'>
                  <img
                    src='/images/no-data.svg'
                    className='max-w-[70%] md:max-w-[290px]'
                  />
                </div>
              )}

              <div className='text-lg font-Inter flex flex-col justify-center items-center gap-2  text-justify'>
                <h2
                  className='font-Inter text-xl pt-2'
                  style={{ color: bodyStyles?.title }}
                >
                  {t(props.emptyDataTitle)}
                </h2>

                <p
                  className='font-Inter text-md max-w-[890px] text-center'
                  style={{ color: bodyStyles?.subText }}
                >
                  {t(props.emptyDataDescription)}
                </p>
              </div>

              <div className='flex gap-4 mt-4'>
                {props.importInstanceMessage && (
                  <BasicButton
                    text={t(props.importInstanceMessage)}
                    onClick={setImportInstanceAction}
                  />
                )}

                {props.addInstanceMessage && (
                  <FiledButton
                    text={t(props.addInstanceMessage)}
                    onClick={props.addInstanceAction}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TableSection;
