import React from 'react';

//Material UI
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';

//store
import { useStateContext } from '../contexts/ContextProvider';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ children, screens, sticky, stickyTopValue }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { bodyStyles } = useStateContext();

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        className={`${
          sticky
            ? `${
                stickyTopValue ? stickyTopValue : 'top-0'
              } sticky z-[20] space-y-1 pt-0 shadow-sm  md:-pt-1 md:pb-2`
            : ''
        }`}
        style={{ background: bodyStyles?.background }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          TabIndicatorProps={{
            style: { background: bodyStyles?.primary || '#B835B3' },
          }}
          className=''
          sx={{
            fontSize: '11px' ,
          }}
        >
          {screens?.map((item, index) => (
            <Tab
              key={index}
              label={item}
              style={{ color: bodyStyles?.text }}
              className='font-urbanist  text-xl text-mouseGray font-semibold '
              iconPosition='end'
              {...a11yProps(index)}
              sx={{
                fontSize: '11px' ,
              }}
            />
          ))}
        </Tabs>
      </Box>

      {screens?.map((item, index) => (
        <TabPanel key={index} value={value} index={index}  sx={{
          fontSize: '11px' ,
        }}>
          {children[index]}
        </TabPanel>
      ))}
    </Box>
  );
}
