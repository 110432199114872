import { constants } from '../constants';
import { client } from '../global/apolloClient';
import {
  CREATE_REFERENCE,
  DELETE_MANY_REFERENCE,
  DELETE_REFERENCE,
  EDIT_REFERENCE,
} from '../graphql/mutation/reference.mutation';

import {
  GET_ALL_REFERENCES,
  GET_REFERENCE,
} from '../graphql/query/reference.query';

import {
  createReferenceFail,
  createReferenceStart,
  createReferenceSuccess,
  deleteReferenceFail,
  deleteReferenceStart,
  deleteReferenceSuccess,
  deleteReferencesFail,
  deleteReferencesStart,
  deleteReferencesSuccess,
  editReferenceFail,
  editReferenceStart,
  editReferenceSuccess,
  fetchReferencesFail,
  fetchReferencesStart,
  fetchReferencesSuccess,
  importFail,
  importStart,
  importSuccess,
  removeAll,
} from '../store/features/reference/referenceSlicer';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation, resteCache } from './executeMutation';
import { uploadImage } from './upload';

const token = getTheTokenFromStorage();

export const createReference = async (values, accessToken) => {
  return await executeMutation(
    CREATE_REFERENCE,
    [GET_ALL_REFERENCES, GET_REFERENCE],
    values,
    accessToken
  );
};

export const editReference = async (values, accessToken) => {
  return await executeMutation(
    EDIT_REFERENCE,
    [GET_ALL_REFERENCES],
    values,
    accessToken
  );
};

export const deleteReference = async (values, accessToken) => {
  return await executeMutation(
    DELETE_REFERENCE,
    [GET_ALL_REFERENCES],
    values,
    accessToken
  );
};

export const deleteManyReference = async (values, accessToken) => {
  return await executeMutation(
    DELETE_MANY_REFERENCE,
    [GET_ALL_REFERENCES],
    values,
    accessToken
  );
};

export const fetchReferences = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchReferencesStart());
      const { data } = await client.query({
        query: GET_ALL_REFERENCES,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchReferencesSuccess(data));
    } catch (error) {
      dispatch(fetchReferencesFail(error));
    }
  };
};

export const handleDeleteReference = (id) => {
  return async (dispatch) => {
    dispatch(deleteReferenceStart());
    const dataSent = {
      removeReferenceId: parseInt(id),
    };
    try {
      let res = await deleteReference(dataSent, token);
      if (res?.data?.removeReference) {
        dispatch(deleteReferenceSuccess(id));
        dispatch(showSuccessMessage('Reference deleted successfully'));
      } else {
        dispatch(deleteReferenceFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteReferenceFail(error));
    }
  };
};

export const handleDeleteManyReference = (ids) => {
  return async (dispatch) => {
    dispatch(deleteReferencesStart());
    const dataSent = {
      ids: ids.map((id) => +id)
    };
    try {
      let res = await deleteManyReference(dataSent, token);
      if (res?.data) {
        dispatch(deleteReferencesSuccess(ids));
        dispatch(showSuccessMessage('References deleted successfully'));
        resteCache('references');
        resteCache('reference');
      } else {
        dispatch(deleteReferencesFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteReferencesFail(error));
    }
  };
};

export const handleCreateReference = (values, callback) => {
  return async (dispatch) => {
    dispatch(createReferenceStart());

    const allImages = values.images?.length
      ? values.images.map(async (image) => {
          if (image.id) {
            return +image?.id;
          } else {
            let res = await uploadImage(image);
            if (res?.data?.id) {
              return +res?.data?.id;
            }
          }
        })
      : [];

    const dataSent = {
      createReferenceInput: {
        customerFullName: values?.customerFullName,
        description: values?.description,
        subDescription: values?.subDescription,
        location: values?.location,
        company: values?.company,
        media: await Promise.all(allImages)
          .then((responses) => {
            return responses;
          })
          .then((r) => r.filter((v) => v)),
      },
    };

    try {
      const res = await createReference(dataSent, token);
      if (res?.data?.createReference) {
        dispatch(createReferenceSuccess(res?.data?.createReference));
        dispatch(
          showSuccessMessage('this Reference has been successfully created')
        );
        resteCache('references');
      } else {
        dispatch(createReferenceFail(res));
        dispatch(showErrorMessage(res));
      }

      callback && callback(res);
    } catch (error) {
      dispatch(createReferenceFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleEditReference = (values, callback) => {
  return async (dispatch) => {
    dispatch(editReferenceStart());

    const allImages = values.images?.length
      ? values.images.map(async (image) => {
          if (image.id) {
            return +image?.id;
          } else {
            let res = await uploadImage(image);
            if (res?.data?.id) {
              return +res?.data?.id;
            }
          }
        })
      : [];

    const dataSent = {
      updateReferenceInput: {
        id: +values?.id,
        customerFullName: values?.customerFullName,
        description: values?.description,
        subDescription: values?.subDescription,
        location: values?.location,
        company: values?.company,
        media: await Promise.all(allImages)
          .then((responses) => {
            return responses;
          })
          .then((r) => r.filter((v) => v)),
      },
    };

    try {
      const res = await editReference(dataSent, token);
      if (res?.data?.updateReference) {
        dispatch(editReferenceSuccess(res?.data?.updateReference));
        dispatch(
          showSuccessMessage('this Reference has been successfully updated')
        );
        resteCache('references');
      } else {
        dispatch(editReferenceFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(editReferenceFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleImportReferences = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyReference(dataSent, token);
      if (res?.data?.removeReferences) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('References Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteReferencesFail(error));
      });
    }
  };
};

export const handleExportReferences = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyReference(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllReferences = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyReference(dataSent, token);
    } catch (error) {}
  };
};
