import React, { useEffect, useState } from 'react';
import BasicInput from '../../../Inputs/BasicInput';
import Editor from '../../../EditorCode';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import RtlConversion from '../../../RtlConversion';
import {
  handleEditSeetingWithData,
} from '../../../../services/setting';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';

const GoogleAnalyticsPixels = ({ data }) => {
  const { bodyStyles } = useStateContext();
  const dispatch = useDispatch();
  const [showConfig, setShowConfig] = useState(false);

  const [data1, setData1] = useState(`<!-- Measurement_ID (gtag.js) -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=${data?.setting_online?.measurement_id}"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', '${data?.setting_online?.measurement_id}');
    </script>`);
  const [code1, setCode1] = useState(
    '<style>\n .class  { color: #FFF; }\n</style>\n\n\n'
  );

  const [loading, setLoading] = useState({
    googleSettings: false,
  });
  const { t, i18n } = useTranslation();
  const [fields, setFields] = useState({
    googleTracking: '',
    deliverabilityRate: '',
    conversionType: '',
    googleAnalytics: '',
    measurement_id: '',
    stream_id: '',
  });

  const [disableAll, setdisableAll] = useState(false);

  useEffect(() => {
    if (data?.setting_online) {
      setFields((prevState) => ({
        ...prevState,
        googleTracking: data?.setting_online?.google_track_id
          ? data?.setting_online?.google_track_id
          : '',
        deliverabilityRate: data?.setting_online?.google_deliverability_rate
          ? data?.setting_online?.google_deliverability_rate
          : '',
        conversionType: data?.setting_online?.google_conversion_type
          ? data?.setting_online?.google_conversion_type
          : '',
        googleAnalytics: data?.setting_online?.analytics_script_head
          ? data?.setting_online?.analytics_script_head
          : '',

        measurement_id: data?.setting_online?.measurement_id
          ? data?.setting_online?.measurement_id
          : '',
        stream_id: data?.setting_online?.stream_id
          ? data?.setting_online?.stream_id
          : '',
      }));
    }
  }, [data]);
  const addGoogleAnalyticsPixels = Yup.object().shape({
    googleTracking: Yup.string(),
    deliverabilityRate: Yup.string(),
    conversionType: Yup.string(),
    googleAnalytics: Yup.string(),
    measurement_id: Yup.string(),
    stream_id: Yup.string(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      googleTracking: fields?.googleTracking,
      deliverabilityRate: fields?.deliverabilityRate,
      conversionType: fields?.conversionType,
      googleAnalytics: fields?.googleAnalytics,
      measurement_id: fields?.measurement_id,
      stream_id: fields?.stream_id,
    },
    validationSchema: addGoogleAnalyticsPixels,
    onSubmit: async (values) => {
      addOrUpdateGoogleSettings(values);
    },
  });

  const addOrUpdateGoogleSettings = async (values) => {
    setLoading({ ...loading, googleSettings: true });

    const dataSent = {
      updateSettingInput: {
        setting_online: {
          google_track_id: values.googleTracking,
          google_deliverability_rate: values.deliverabilityRate,
          google_conversion_type: values.conversionType,
          analytics_script_head: values.googleAnalytics,
          measurement_id: values?.measurement_id,
          stream_id: values?.stream_id,
        },
      },
    };

    dispatch(
      handleEditSeetingWithData(dataSent, (res) => {
        if (res.data) {
          setLoading({ ...loading, googleSettings: false });
        }
      })
    );
  };

  return (
    <div className='flex w-full flex-col items-center justify-center'>
      <div className='flex w-full flex-col rounded-[4px] border-[3px] border-[#DB4437]'>
        <div className='flex h-[54px] flex-row items-center justify-start gap-x-2 bg-[#DB4437] px-2 text-white '>
          <img
            src='/icons/GoogleIcon.svg'
            alt='Google'
            className=' block cursor-pointer'
          />
          <span>{t('Google analytics settings')}</span>
        </div>
        <div className='flex w-full flex-row items-start justify-center'>
          <RtlConversion rtl={i18n.language === 'ar' ? true : false}>
            <div className='flex w-full flex-col items-center justify-center gap-y-4 py-8 px-4'>
              {/* <BasicInput
                  disabled={true}
                  label={t('Google tracking IDs')}
                  // placeholder=""
                  value={fields?.googleTracking}
                  onChange={(e) => {
                    setFields({
                      ...fields,
                      googleTracking: e.target.value,
                    });
                  }}
                  error={
                    formik.touched.googleTracking &&
                    Boolean(formik.errors.googleTracking)
                  }
                  helperText={
                    formik.touched.googleTracking && formik.errors.googleTracking
                  }
                  InputProps={{
                    style: { fontSize: '12px' },
                  }}
                />
                <BasicInput
                  disabled={true}
                  label={t('Deliverability rate (COD only)')}
                  // placeholder=""
                  value={fields?.deliverabilityRate}
                  onChange={(e) => {
                    setFields({
                      ...fields,
                      deliverabilityRate: e.target.value,
                    });
                  }}
                  error={
                    formik.touched.deliverabilityRate &&
                    Boolean(formik.errors.deliverabilityRate)
                  }
                  helperText={
                    formik.touched.deliverabilityRate &&
                    formik.errors.deliverabilityRate
                  }
                  InputProps={{
                    style: { fontSize: '12px' },
                  }}
                />
                <BasicInput
                  disabled={true}
                  label={t('Conversion type (COD only)')}
                  // placeholder=""
                  value={fields?.conversionType}
                  onChange={(e) => {
                    setFields({
                      ...fields,
                      conversionType: e.target.value,
                    });
                  }}
                  error={
                    formik.touched.conversionType &&
                    Boolean(formik.errors.conversionType)
                  }
                  helperText={
                    formik.touched.conversionType && formik.errors.conversionType
                  }
                  InputProps={{
                    style: { fontSize: '12px' },
                  }}
                /> */}

              <BasicInput
                disabled={disableAll}
                label={t('Measurement ID')}
                // placeholder=""
                value={fields?.measurement_id}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    measurement_id: e.target.value,
                  });
                }}
                error={
                  formik.touched.measurement_id &&
                  Boolean(formik.errors.measurement_id)
                }
                helperText={
                  formik.touched.measurement_id && formik.errors.measurement_id
                }
                InputProps={{
                  style: { fontSize: '12px' },
                }}
              />

              {data?.setting_online?.measurement_id && (
                <div
                  className='text-start flex justify-start  cursor-pointer w-full'
                  onClick={() => {
                    setShowConfig(!showConfig);
                  }}
                  style={{
                    color: bodyStyles?.primary,
                  }}
                >
                  {!showConfig
                    ? t('show client app config ?')
                    : t('hide client app config')}
                </div>
              )}

              <div className='flex w-full justify-start'>
                {showConfig && <Editor code={data1} />}
              </div>

              {/* <BasicInput
                  disabled={disableAll}
                  label={t('Stream Id')}
                  // placeholder=""
                  value={fields?.stream_id}
                  onChange={(e) => {
                    setFields({
                      ...fields,
                      stream_id: e.target.value,
                    });
                  }}
                  error={
                    formik.touched.stream_id && Boolean(formik.errors.stream_id)
                  }
                  helperText={formik.touched.stream_id && formik.errors.stream_id}
                  InputProps={{
                    style: { fontSize: '12px' },
                  }}
                /> */}

              {/* <BasicInput
                  disabled={true}
                  label={t('Google analytics')}
                  // placeholder=""
                  value={fields?.googleAnalytics}
                  onChange={(e) => {
                    setFields({
                      ...fields,
                      googleAnalytics: e.target.value,
                    });
                  }}
                  error={
                    formik.touched.googleAnalytics &&
                    Boolean(formik.errors.googleAnalytics)
                  }
                  helperText={
                    formik.touched.googleAnalytics &&
                    formik.errors.googleAnalytics
                  }
                  multiline
                  rows={6}
                  InputProps={{
                    'aria-label': 'post',
                    style: { fontSize: '12px' },
                  }}
                /> */}
            </div>
          </RtlConversion>

          <div className='flex h-full w-[10%] items-start justify-end py-8 px-6'>
            <span
              className=' cursor-pointer text-xl text-[#358DEB] underline'
              onClick={() => formik.submitForm()}
            >
              {loading?.googleSettings ? (
                <CircularProgress
                  style={{
                    width: 15,
                    height: 15,
                    color: '#358DEB',
                  }}
                />
              ) : (
                t('Save')
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleAnalyticsPixels;
