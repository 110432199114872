import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../contexts/ContextProvider';
import MenuCustomer from './MenuCustomer';

const CustomerCard = ({ data }) => {
  const { t } = useTranslation();
  const { bodyStyles } = useStateContext();
  return (
    <div className='flex flex-col gap-4'>
      <div
        className='font-Inter text-xl uppercase font-bold flex items-center justify-between'
        style={{
          color: bodyStyles.subTitle,
        }}
      >
        <span>{t('Customer')}</span>

        <MenuCustomer id={data?.id} />
      </div>

      <div className='flex flex-col gap-2'>
        <div className='drop-shadow-xl rounded-lg border  p-2 flex flex-col gap-1.5'>
          <div className='flex gap-1'>
            <span className='w-[30%] font-semibold'>{t('Email')}</span>
            <span className='break-all'>
              {' '}
              {data?.email ? data?.email : '-'}
            </span>
          </div>

          <div className='flex gap-1'>
            <span className='w-[30%] font-semibold'>{t('Name')}</span>
            <span className='break-all'>
              {data?.firstName ? data?.firstName : '-'}

              <span className='px-1 break-all'>
                {data?.lastName ? data?.lastName : '-'}
              </span>
            </span>
          </div>

          <div className='flex gap-1 '>
            <span className='w-[30%] font-semibold'>{t('Phone')}</span>
            <span className='break-all'>
              {' '}
              {data?.phoneNumber ? data?.phoneNumber : '-'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomerCard;
