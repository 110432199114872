import React, { useCallback, useEffect, useState } from "react";
import L from "leaflet";
import useSupercluster from "use-supercluster";
import { Marker, Popup, useMap } from "react-leaflet";

function ShowPoints({ data, pointColor, clusterColor, type }) {
  const maxZoom = 22;
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(12);
  const map = useMap();

  useEffect(() => {}, [zoom, pointColor, clusterColor]);

  // get map bounds
  function updateMap() {
    const b = map.getBounds();
    setBounds([
      b.getSouthWest().lng,
      b.getSouthWest().lat,
      b.getNorthEast().lng,
      b.getNorthEast().lat,
    ]);
    setZoom(map.getZoom());
  }

  const onMove = useCallback(() => {
    updateMap();
  }, [map]);

  React.useEffect(() => {
    updateMap();
  }, [map]);

  useEffect(() => {
    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);

  const eventDataIndex = {
    8: "sessions",
    10: "sessions",
    12: "products",
    15: "products",
  };
  let eventDataIndexNum = Object.keys(eventDataIndex);
  eventDataIndexNum = eventDataIndexNum.map((index) => Number(index));

  const points = data.map((event) => ({
    type: "Feature",
    properties: {
      cluster: false,
      eventKey: event.id,
      eventTitle: event.title,
    },
    geometry: {
      type: "Point",
      coordinates: [
        event.geometries[0].coordinates[0],
        event.geometries[0].coordinates[1],
      ],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points: points,
    bounds: bounds,
    zoom: zoom,
    options: { radius: 40, maxZoom: 20 },
  });

  return (
    <>
      {clusters.map((cluster, index) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;

        if (isCluster) {
          return (
            <Marker
              key={`cluster-${cluster.id}-${index}`}
              position={[latitude, longitude]}
              icon={L.divIcon({
                html: `<div class="flex justify-center items-center
                min-w-3 min-h-3 rounded-full animate-pulse z-[20]
                text-[#fff] font-Inter font-[500] p-1 border-0
                " style="width: ${
                  (10 + (pointCount / points.length) * 40) * 1.6 + 5
                }px; height: ${
                  (10 + (pointCount / points.length) * 40) * 1.6 + 5
                }px;  background:${pointColor} ">
                  ${pointCount}
                </div>`,
              })}
              eventHandlers={{
                click: () => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    maxZoom
                  );
                  map.setView([latitude, longitude], expansionZoom, {
                    animate: true,
                  });
                },
              }}
            />
          );
        }

        if (!isCluster) {
          return (
            <Marker
              key={`node-${cluster.properties.eventType}-${index}`}
              position={[latitude, longitude]}
              icon={L.divIcon({
                html: `<div class="flex justify-center items-center
                min-w-3 min-h-3 rounded-full  z-0
                text-[#fff] font-Inter font-[500] p-1 border-0
                " style="width: ${5 * 1.6 + 5}; height: 
                ${5 * 1.6 + 5}px ; background:${pointColor} ">
                
                </div>`,
              })}
            >
              <Popup>
                <div className="p-0 bg-[rgba(255,255,255,.3)] rounded-md text-sm ">
                  <h2>Session Location Info</h2>
                  <ul className="list-none p-0 ">
                    <li className="p-1">
                      ID: <strong>{index}</strong>
                    </li>
                    <li className="p-1">
                      LOCATION:{" "}
                      <strong>{`${cluster.geometry.coordinates[0]} ,${cluster.geometry.coordinates[1]} `}</strong>
                    </li>
                  </ul>
                </div>
              </Popup>
            </Marker>
          );
        }
      })}
    </>
  );
}

export default ShowPoints;
