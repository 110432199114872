import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import ManualPaymentMethodModal from "./ManualPaymentMethodModal";
import ManualPaymentMethodModalEdit from "./ManualPaymentMethodModalEdit";

const ManualPaymentMethods = () => {
  const { t } = useTranslation();
  const [methodesSelect, setMethodesSelect] = useState([
    // { value: "cod", label: "cash on delivery (cod)" },
    // { value: "bank-deposit", label: "bank deposit" },
  ]);

  const [manualPaymentMethods, setManualPaymentMethods] = useState([
    {
      value: "create-custom-payment-methode",
      label: "create custom payment methode",
      action: () => {
        setOpenManualPaymentMethodModal(true);
      },

      edit: () => {
        setOpenManualPaymentMethodEditModal(true);
      },
    },
    {
      value: "bank-deposit",
      label: "bank deposit",
      action: () => {
        setOpenBankDeposit(true);
      },
      edit: () => {
        setOpenBankDepositEdit(true);
      },
    },
    {
      value: "money-order",
      label: "money order",
      action: () => {
        setOpenMoneyOrder(true);
      },
      edit: () => {
        setOpenMoneyOrderEdit(true);
      },
    },
    {
      value: "cod",
      label: "cash on delivery (cod)",
      action: () => {
        setOpenCod(true);
      },
      edit: () => {
        setOpenCodEdit(true);
      },
    },
  ]);

  const [openManualPaymentMethodModal, setOpenManualPaymentMethodModal] =
    useState(false);
  const [
    openManualPaymentMethodEditModal,
    setOpenManualPaymentMethodEditModal,
  ] = useState(false);

  const [openBankDeposit, setOpenBankDeposit] = useState(false);
  const [openBankDepositEdit, setOpenBankDepositEdit] = useState(false);

  const [openMoneyOrder, setOpenMoneyOrder] = useState(false);
  const [openMoneyOrderEdit, setOpenMoneyOrderEdit] = useState(false);

  const [openCod, setOpenCod] = useState(false);
  const [openCodEdit, setOpenCodEdit] = useState(false);

  const openEditModal = (item) => {
    manualPaymentMethods.find((o) => o.value === item)?.edit();
  };

  return (
    <div className="w-full flex flex-col items-start justify-start ">
      <ManualPaymentMethodModal
        open={openManualPaymentMethodModal}
        setOpen={setOpenManualPaymentMethodModal}
        setMethodesSelect={setMethodesSelect}
        methodesSelect={methodesSelect}
        defaultName=""
        title={t("Set up manual payment method")}
        value="create-custom-payment-methode"
      />

      <ManualPaymentMethodModalEdit
        open={openManualPaymentMethodEditModal}
        setOpen={setOpenManualPaymentMethodEditModal}
        setMethodesSelect={setMethodesSelect}
        methodesSelect={methodesSelect}
        defaultName=""
        title={t("Edit manual payment method")}
        value="create-custom-payment-methode"
        initData={methodesSelect.find(
          (item, index) => item.value == "create-custom-payment-methode"
        )}
      />

      {/*  */}
      <ManualPaymentMethodModal
        open={openBankDeposit}
        setOpen={setOpenBankDeposit}
        setMethodesSelect={setMethodesSelect}
        methodesSelect={methodesSelect}
        defaultName="Bank Deposit"
        title={t("Set up Bank Deposit")}
        value="bank-deposit"
      />

      <ManualPaymentMethodModalEdit
        open={openBankDepositEdit}
        setOpen={setOpenBankDepositEdit}
        setMethodesSelect={setMethodesSelect}
        methodesSelect={methodesSelect}
        defaultName=""
        title={t("Edit manual Bank Deposit")}
        value="bank-deposit"
        initData={methodesSelect.find(
          (item, index) => item.value == "bank-deposit"
        )}
      />

      {/*  */}

      <ManualPaymentMethodModal
        open={openMoneyOrder}
        setOpen={setOpenMoneyOrder}
        setMethodesSelect={setMethodesSelect}
        methodesSelect={methodesSelect}
        defaultName="Money Order"
        title={t("Set up Money Order")}
        value="money-order"
      />

      <ManualPaymentMethodModalEdit
        open={openMoneyOrderEdit}
        setOpen={setOpenMoneyOrderEdit}
        setMethodesSelect={setMethodesSelect}
        methodesSelect={methodesSelect}
        defaultName=""
        title={t("Edit Money Order")}
        value="money-order"
        initData={methodesSelect.find(
          (item, index) => item.value == "money-order"
        )}
      />

      {/*  */}

      <ManualPaymentMethodModal
        open={openCod}
        setOpen={setOpenCod}
        setMethodesSelect={setMethodesSelect}
        methodesSelect={methodesSelect}
        defaultName="Cash on Delivery (COD)"
        title={t("Set up Cash on Delivery (COD)")}
        value="cod"
      />

      <ManualPaymentMethodModalEdit
        open={openCodEdit}
        setOpen={setOpenCodEdit}
        setMethodesSelect={setMethodesSelect}
        methodesSelect={methodesSelect}
        defaultName=""
        title={t("Edit Cash on Delivery (COD)")}
        value="cod"
        initData={methodesSelect.find((item, index) => item.value == "cod")}
      />
      {/* <h1
          style={{
            color: bodyStyles?.title || "#808080",
          }}
          className="text-[#808080] text-[19px] font-[600] uppercase"
        >
          {t("Manual payment methods")}
        </h1>
        <div className="flex items-center justify-start w-full py-6 text-lg">
          <span
            style={{
              color: bodyStyles?.subTitle ? bodyStyles?.subTitle : "#888888",
            }}
            className="text-[#888888]"
          >
            {t(
              "Payments that are made outside your online store. When a customer selects a manual payment method such as cash on delivery, you'll need to approve their order before it can be fulfilled."
            )}
          </span>
        </div>
  
        <div className="flex flex-col w-full pb-4 pt-2">
          <Divider />
  
          {methodesSelect.map((item, index) => (
            <div className="flex flex-col gap-2 w-full pt-2" key={item?.value}>
              <div className="flex justify-between w-full  p-2 text-lg">
                <span>
                  {defaultDatat(item?.translationData, i18n.language, "name")}
                </span>
                <button
                  className="text-[#358DEB] cursor-pointer underline underline-offset-2"
                  onClick={() => {
                    openEditModal(item?.value);
                  }}
                >
                  Manage
                </button>
              </div>
              <Divider />
            </div>
          ))}
        </div> */}

      {/* <div className="flex items-start justify-start w-full flex-col min-h-[150px] gap-1  ">
          <AddmanualPaymentMethodMenu
            methodesSelect={methodesSelect}
            setMethodesSelect={setMethodesSelect}
            manualPaymentMethods={manualPaymentMethods}
          />
        </div> */}
    </div>
  );
};

export default ManualPaymentMethods;
