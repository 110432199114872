import { constants } from '../constants';
import { client } from '../global/apolloClient';
import {
  CREATE_CUSTOMER,
  DELETE_CUSTOMER,
  EDIT_CUSTOMER,
} from '../graphql/mutation/customer.mutation';
import {
  GET_ALL_CUSTOMERS,
  GET_CUSTOMER,
} from '../graphql/query/customer.query';

import {
  createCustomerFail,
  createCustomerStart,
  createCustomerSuccess,
  deleteCustomerFail,
  deleteCustomerStart,
  deleteCustomerSuccess,
  deleteCustomersFail,
  deleteCustomersStart,
  deleteCustomersSuccess,
  editCustomerFail,
  editCustomerStart,
  editCustomerSuccess,
  fetchCustomersFail,
  fetchCustomersStart,
  fetchCustomersSuccess,
  importFail,
  importStart,
  importSuccess,
  removeAll,
} from '../store/features/customer/customerSlicer';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation } from './executeMutation';
import { uploadImage } from './upload';

const token = getTheTokenFromStorage();

export const createCustomer = async (values, accessToken) => {
  return await executeMutation(
    CREATE_CUSTOMER,
    [GET_ALL_CUSTOMERS, GET_CUSTOMER],
    values,
    accessToken
  );
};

export const editCustomer = async (values, accessToken) => {
  return await executeMutation(
    EDIT_CUSTOMER,
    [GET_ALL_CUSTOMERS, GET_CUSTOMER],
    values,
    accessToken
  );
};

export const deleteCustomer = async (values, accessToken) => {
  return await executeMutation(
    DELETE_CUSTOMER,
    [GET_ALL_CUSTOMERS, GET_CUSTOMER],
    values,
    accessToken
  );
};

export const deleteManyCustomer = async (values, accessToken) => {
  return await executeMutation(
    DELETE_CUSTOMER,
    [GET_ALL_CUSTOMERS, GET_CUSTOMER],
    values,
    accessToken
  );
};

export const fetchCustomers = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCustomersStart());
      const { data } = await client.query({
        query: GET_ALL_CUSTOMERS,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchCustomersSuccess(data));
    } catch (error) {
      dispatch(fetchCustomersFail(error));
    }
  };
};

export const handleDeleteCustomer = (id) => {
  return async (dispatch) => {
    dispatch(deleteCustomerStart());
    const dataSent = {
      removeCustomerId: parseInt(id),
    };
    try {
      let res = await deleteCustomer(dataSent, token);
      if (res?.data?.removeCustomer) {
        dispatch(deleteCustomerSuccess(id));
        dispatch(showSuccessMessage('Customer deleted successfully'));
      } else {
        dispatch(deleteCustomerFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteCustomerFail(error));
    }
  };
};

export const handleDeleteManyCustomer = (ids) => {
  return async (dispatch) => {
    dispatch(deleteCustomersStart());
    const dataSent = {
      removeCustomerId: ids.map((id) => parseInt(id)),
    };
    try {
      let res = await deleteManyCustomer(dataSent, token);
      if (res?.data?.removeCustomers) {
        dispatch(deleteCustomersSuccess(ids));
        dispatch(showSuccessMessage('Customers deleted successfully'));
      } else {
        dispatch(deleteCustomersFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteCustomersFail(error));
    }
  };
};

export const handleCreateCustomer = (values, callback) => {
  return async (dispatch) => {
    dispatch(createCustomerStart());

    const dataSent = {
      input: {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        type: 'CUSTOMER',
        phoneNumber: values.phoneNumber,
      },
    };
    try {
      const res = await createCustomer(dataSent, token);
      if (res?.data?.createCustomer) {
        dispatch(createCustomerSuccess(res));
        dispatch(
          showSuccessMessage('this Customer has been successfully created')
        );
      } else {
        dispatch(createCustomerFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(createCustomerFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};



export const handleEditCustomer = (values, callback) => {
  return async (dispatch) => {
    dispatch(editCustomerStart());

    const dataSent = {
      input: {
        id: values.id,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
      },
    };
  
    try {
      let res = await editCustomer(dataSent, token);
      if (res?.data?.updateCustomer) {
        dispatch(editCustomerSuccess(res?.data?.updateCustomer));
        dispatch(
          showSuccessMessage('this Customer has been successfully updated')
        );
      } else {
        dispatch(editCustomerFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res)
    } catch (error) {
      dispatch(editCustomerFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error)
    }
  };
};

export const handleImportCustomers = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyCustomer(dataSent, token);
      if (res?.data?.removeCustomers) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Customers Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteCustomersFail(error));
      });
    }
  };
};

export const handleExportCustomers = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyCustomer(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllCustomers = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyCustomer(dataSent, token);
    } catch (error) {}
  };
};
