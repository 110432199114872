import React, { useContext, useEffect, useState } from 'react';
import LoadingSpinner from '../../../LoadingSpinner';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import { storeContext } from '../../../../global/store';
import { useFormik } from 'formik';
import ModalDialog from '../../../ModalDialog';
import Tabs from '../../../TabsLanguages';
import BasicInput from '../../../Inputs/BasicInput';
import CheckBox from '../../../Inputs/CheckBox';
import { useRoleValidation } from '../../../../validations/role.validation';
import { useDispatch, useSelector } from 'react-redux';
import {
  openModelCreate,
  openModelEdit,
} from '../../../../store/features/rolle/rolleSlicer';
import { regroupWithGroupName } from './helper';
import { handleCreateRole, handleEditRole } from '../../../../services/role';

const EditRoleModal = () => {
  const { store } = useContext(storeContext);
  const dispatch = useDispatch();

  const { rolle: data, permission: permissionState } = useSelector(
    (state) => state
  );

  const { t } = useTranslation();
  const { bodyStyles } = useStateContext();

  const { addRolechema } = useRoleValidation();

  const [rolesDesc, setRolesDesc] = useState([]);

  useEffect(() => {
    if (permissionState?.data && rolesDesc.length === 0) {
      setRolesDesc(
        regroupWithGroupName(permissionState?.data, data.selectedOne)
      );
    }
  }, [permissionState?.data, data.selectedOne]);

  useEffect(() => {
    if (rolesDesc.length && data.selectedOne) {
      setRolesDesc(
        regroupWithGroupName(permissionState?.data, data.selectedOne)
      );
    }
  }, [data.selectedOne]);

  const [fields, setFields] = useState({
    name: '',
    description: '',
  });

  useEffect(() => {
    if (data.selectedOne) {
      setFields({
        name: data.selectedOne?.name || '',
        description: data.selectedOne?.description || '',
      });
    }
  }, [data.selectedOne]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: +data?.selectedOne?.id,
      name: fields.name,
      description: fields.description,
      rolesDesc: rolesDesc,
    },
    validationSchema: addRolechema,
    onSubmit: async (values) => {
      dispatch(
        handleEditRole(values, (res) => {
          closeModal();
        })
      );
    },
  });

  const handlePermissionClick = (roleId, permissionId) => {
    setRolesDesc((prevState) => {
      const newRoles = [...prevState];
      const roleIndex = newRoles.findIndex((role) => role.id === roleId);
      const role = { ...newRoles[roleIndex] };
      const permissionIndex = role.permissions.findIndex(
        (permission) => permission.id === permissionId
      );
      const permission = { ...role.permissions[permissionIndex] };
      permission.status = !permission.status;
      role.permissions = [
        ...role.permissions.slice(0, permissionIndex),
        permission,
        ...role.permissions.slice(permissionIndex + 1),
      ];
      newRoles[roleIndex] = role;
      return newRoles;
    });
  };

  const toggleAllPermissions = (parentId) => {
    setRolesDesc((prevState) => {
      return prevState.map((role) => {
        if (role.id === parentId) {
          let newStatus = !role.status;
          const permissions = role.permissions.map((permission) => {
            return {
              ...permission,
              status: newStatus,
            };
          });
          return {
            ...role,
            status: newStatus,
            permissions,
          };
        }
        return role;
      });
    });
  };

  const toggleAll = () => {
    setRolesDesc((prevState) => {
      return prevState.map((role) => {
        const permissions = role.permissions.map((permission) => {
          return {
            ...permission,
            status: false,
          };
        });
        return {
          ...role,
          status: false,
          permissions,
        };
      });
    });
  };

  const closeModal = () => {
    dispatch(openModelEdit());
    setFields({
      name: '',
      description: '',
    });
    toggleAll();
  };

  return (
    <ModalDialog
      open={data?.editModal}
      setOpen={() => closeModal()}
      title={t('Edit Role')}
      cancelTitle={t('Cancel')}
      cancel={() => closeModal()}
      actionTitle={t('Edit Role')}
      action={() => formik.handleSubmit()}
      loadingAction={data?.loadingEdit}
    >
      <form
        id='add-discount-modal'
        className='flex flex-col gap-5 py-0 md:w-[550px]'
      >
        <div className='m-0 -mt-2 flex flex-col  gap-4 p-0 pb-0 font-Inter   '>
          <div className=''>
            <Tabs availableLanguages={store?.availableLanguages} small fixed>
              {store?.availableLanguages?.map((item, indexLanguages) => (
                <div key={indexLanguages} className='font-urbanist pt-3'>
                  <div className='w-[98%]'>
                    <div className='flex flex-col  gap-3 '>
                      <BasicInput
                        label={t('Role Name')}
                        placeholder={t('Admin')}
                        value={fields?.name}
                        onChange={(e) =>
                          setFields({ ...fields, name: e.target.value })
                        }
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />

                      <BasicInput
                        label={t('Description')}
                        placeholder='Tristique viverra risus mauris nunc arcu suspendisse id.
                              Urna ut nec integer dignissim nibh est, nunc feugiat. Bibendum in elementum leo fringilla venenatis nunc ac. '
                        rows={3}
                        value={fields?.description}
                        onChange={(e) =>
                          setFields({
                            ...fields,
                            description: e.target.value,
                          })
                        }
                        error={
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description &&
                          formik.errors.description
                        }
                      />

                      <div className='w-full'>
                        {
                          // loading
                          0 ? (
                            <div className=' flex h-[120px] w-full items-center justify-center'>
                              {' '}
                              <LoadingSpinner size='34px' />{' '}
                            </div>
                          ) : (
                            <>
                              {rolesDesc.map((role) => (
                                <div
                                  className='flex rounded-md pt-2 pb-0'
                                  key={role.id}
                                >
                                  <div className='flex w-[33%] flex-col gap-1 border bg-[#F2F3F5] p-1'>
                                    <h4 className='font-Inter text-sm font-[700]'>
                                      {role.name}
                                    </h4>
                                    <p className='font-Inter text-sm font-[400]'>
                                      {role.description}
                                    </p>

                                    <div
                                      className='text-start flex w-full cursor-pointer justify-start text-sm font-[700]'
                                      onClick={() => {
                                        toggleAllPermissions(role.id);
                                      }}
                                      style={{
                                        color: bodyStyles?.primary,
                                      }}
                                    >
                                      {t('Toggle All')}
                                    </div>
                                  </div>
                                  <div className='flex w-[67%] flex-wrap gap-2 border p-2'>
                                    {role.permissions.map(
                                      (permission, permissionIndex) => (
                                        <div
                                          className='flex h-min min-w-[160px] flex-wrap justify-start break-all bg-[#E8E8E8] px-2 py-1'
                                          key={permission.id}
                                          onClick={() =>
                                            handlePermissionClick(
                                              role.id,
                                              permission.id
                                            )
                                          }
                                        >
                                          <CheckBox
                                            checked={permission.status}
                                            label={permission.name}
                                            size={20}
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              ))}
                            </>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Tabs>
          </div>
        </div>
      </form>
    </ModalDialog>
  );
};

export default EditRoleModal;
