import { gql } from "@apollo/client";

export const GET_ALL_COLLECTIONS = gql`
  query Collections(
    $offset: Int
    $limit: Int
    $where: FilterCollectionsArgs
    $join: CollectionRelationsArgs
  ) {
    collections(offset: $offset, limit: $limit, where: $where, join: $join) {
      hasNextPage
      totalCount
      nodes {
        updatedAt
        translationData {
          createdAt
          description
          id
          languageCode
          name
          slug
          updatedAt
        }
        media {
          id
          createdAt
          updatedAt
          deletedAt
          original_name
          file_name
          path
          host
          full_url
          type
          alt
        }
        productsCount
        menus {
          createdAt
          deletedAt
          isRoot
          id
          updatedAt
          translationData {
            description
            createdAt
            id
            languageCode
            name
            slug
            updatedAt
          }
        }
        id
        deletedAt
        createdAt
      }
    }
  }
`;

export const GET_COLLECTION = gql`
  query Collection($collectionId: Int!) {
    collection(id: $collectionId) {
      id
      deletedAt
      createdAt
      menus {
        deletedAt
        id
        isRoot
        updatedAt
        translationData {
          id
          createdAt
          description
          slug
          name
          updatedAt
          languageCode
        }
        createdAt
      }
      media {
        id
        createdAt
        updatedAt
        deletedAt
        original_name
        file_name
        path
        host
        full_url
        type
        alt
      }
      productsCount
      translationData {
        createdAt
        description
        id
        languageCode
        name
        slug
        updatedAt
      }
      updatedAt
    }
  }
`;
