import React from 'react';
import SettingModal from '../../../components/Setting/SettingModal';

const EmailSetting = () => {
  return (
    <SettingModal page="EmailSetting" />
  );
};

export default EmailSetting;
