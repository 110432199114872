// import { client } from "../global/apolloClient";
import {
  CREATE_ZONE,
  DELETE_ZONE,
  EDIT_ZONE,
} from '../graphql/mutation/zone.mutation';
import { GET_ALL_ZONES } from '../graphql/query/zones.query';

import { constants } from '../constants';
import { client } from '../global/apolloClient';
import {
  CREATE_SHIPPING_METHODE,
  DELETE_SHIPPING_METHODE,
  EDIT_SHIPPING_METHODE,
} from '../graphql/mutation/shippingMethod.mutation';
import { GET_ALL_SHIPPINGMETHODES } from '../graphql/query/shippingMethods.query';
import {
  createZoneFail,
  createZoneStart,
  createZoneSuccess,
  deleteZoneFail,
  deleteZoneStart,
  deleteZoneSuccess,
  deleteZonesFail,
  deleteZonesStart,
  deleteZonesSuccess,
  editZoneFail,
  editZoneStart,
  editZoneSuccess,
  fetchZonesFail,
  fetchZonesStart,
  fetchZonesSuccess,
  importFail,
  importStart,
  importSuccess,
} from '../store/features/zone/zoneSlicer';
import {
  showErrorMessage,
  showErrorSettingMessage,
  showSuccessMessage,
  showSuccessSettingMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation, resteCache } from './executeMutation';

const token = getTheTokenFromStorage();

export const createZone = async (values, accessToken) => {
  return await executeMutation(
    CREATE_ZONE,
    [GET_ALL_ZONES],
    values,
    accessToken
  );
};

export const updateZone = async (values, accessToken) => {
  return await executeMutation(EDIT_ZONE, [GET_ALL_ZONES], values, accessToken);
};

export const deleteZone = async (values, accessToken) => {
  return await executeMutation(
    DELETE_ZONE,
    [GET_ALL_ZONES],
    values,
    accessToken
  );
};

export const deleteManyZone = async (values, accessToken) => {
  return await executeMutation(
    DELETE_ZONE,
    [GET_ALL_ZONES],
    values,
    accessToken
  );
};

export const fetchZones = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchZonesStart());
      const { data } = await client.query({
        query: GET_ALL_ZONES,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchZonesSuccess(data));
    } catch (error) {
      dispatch(fetchZonesFail(error));
    }
  };
};

export const handleDeleteZone = (id) => {
  return async (dispatch) => {
    dispatch(deleteZoneStart());
    const dataSent = {
      removeZoneId: parseInt(id),
    };
    try {
      let res = await deleteZone(dataSent, token);
      if (res?.data?.removeZone) {
        dispatch(deleteZoneSuccess(id));
        dispatch(showSuccessSettingMessage('Zone deleted successfully'));
        resteCache('zones');
      } else {
        dispatch(deleteZoneFail(res));
        dispatch(showErrorSettingMessage(res));
      }
    } catch (error) {
      dispatch(showErrorSettingMessage(error));
      dispatch(deleteZoneFail(error));
    }
  };
};

export const handleDeleteManyZone = (ids) => {
  return async (dispatch) => {
    dispatch(deleteZonesStart());
    const dataSent = {
      removeZoneId: ids.map((id) => parseInt(id)),
    };
    try {
      let res = await deleteManyZone(dataSent, token);
      if (res?.data?.removeZones) {
        dispatch(deleteZonesSuccess(ids));
        dispatch(showSuccessMessage('Zones deleted successfully'));
      } else {
        dispatch(deleteZonesFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteZonesFail(error));
    }
  };
};

export const handleCreateZone = (values, callback) => {
  return async (dispatch) => {
    dispatch(createZoneStart());
    let translationDataSent = values.translationData
      ? Object.keys(values.translationData).map((key) => {
          return {
            name: values.translationData[key].name,
            description: values.translationData[key].description,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      createZoneInput: {
        translationData: translationDataSent,
        countries: values?.checked,
        currencyCode: values?.currencyCode,
      },
    };

    try {
      let res = await createZone(dataSent, token);
      if (res?.data?.createZone) {
        dispatch(createZoneSuccess(res?.data?.createZone));
        dispatch(
          showSuccessSettingMessage('this Zone has been successfully created')
        );
        resteCache('zones');
      } else {
        dispatch(createZoneFail(res));
        dispatch(showErrorSettingMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(createZoneFail(error));
      dispatch(showErrorSettingMessage(error));
      callback && callback(error);
    }
  };
};

export const handleEditZone = (values, callback) => {
  return async (dispatch) => {
    dispatch(editZoneStart());
    let translationDataSent = values.translationData
      ? Object.keys(values.translationData).map((key) => {
          return {
            name: values.translationData[key].name,
            description: values.translationData[key].description,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      updateZoneInput: {
        id: +values?.id,
        translationData: translationDataSent,
        countries: values?.checked,
        currencyCode: values?.currencyCode,
      },
    };

    try {
      let res = await updateZone(dataSent, token);
      if (res?.data?.updateZone) {
        dispatch(editZoneSuccess(res?.data?.updateZone));
        dispatch(
          showSuccessSettingMessage('this Zone has been successfully updated')
        );
        resteCache('zones');
      } else {
        dispatch(editZoneFail(res));
        dispatch(showErrorSettingMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(editZoneFail(error));
      dispatch(showErrorSettingMessage(error));
      callback && callback(error);
    }
  };
};

export const handleImportZones = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyZone(dataSent, token);
      if (res?.data?.removeZones) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Zones Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteZonesFail(error));
      });
    }
  };
};

export const handleExportZones = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyZone(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllZones = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyZone(dataSent, token);
    } catch (error) {}
  };
};

// export const createZone = async (values, accessToken) => {
//   try {
//     const result = await client.mutate({
//       mutation: CREATE_ZONE,
//       variables: {
//         ...values,
//       },
//       context: { accessToken },
//       refetchQueries : [GET_ALL_ZONES]
//     });

//     return result;
//   } catch (error) {
//     return error.message;
//   }
// };

// dELETE AFTER
export const editzone = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: EDIT_ZONE,
      variables: {
        ...values,
      },
      context: { accessToken },
      refetchQueries: [GET_ALL_ZONES],
    });

    return result;
  } catch (error) {
    return error.message;
  }
};

// export const deleteZone = async (values, accessToken) => {
//   try {
//     const result = await client.mutate({
//       mutation: DELETE_ZONE,
//       variables: {
//         ...values,
//       },
//       context: { accessToken },
//       refetchQueries: [GET_ALL_ZONES],
//     });

//     return result;
//   } catch (error) {
//     return error.message;
//   }
// };
