import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ModalDialog from '../ModalDialog';
import { openModelEdit } from '../../store/features/contact/contactSlicer';
import { handleMarkUsReadMessage } from '../../services/contact';

const EditContact = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [first, setfirst] = useState(true)

  const { contact: data, zone: zoneState } = useSelector((state) => state);

  useEffect(() => {
    if (data?.editModal && data?.selectedOne ) {
      dispatch(handleMarkUsReadMessage(data?.selectedOne?.id, (res) => {}));
    }
  }, [data?.editModal]);

  const closeModal = () => {
    dispatch(openModelEdit());
  };

  return (
    <div>
      <ModalDialog
        open={data?.editModal}
        setOpen={() => closeModal()}
        title={t('Contact Information')}
        cancelTitle={t('Close')}
        cancel={() => closeModal()}
      >
        <div className='p-4'>
          <div className='flex mb-4 gap-2'>
            <span className='font-semibold min-w-[70px]'>{t('Name :')}</span>
            <span>{data?.selectedOne?.name}</span>
          </div>

          <div className='flex mb-4 gap-2'>
            <span className='font-semibold min-w-[70px]'>{t('Company :')}</span>
            <span>{data?.selectedOne?.company}</span>
          </div>
          <div className='flex mb-4 gap-2'>
            <span className='font-semibold min-w-[70px]'>{t('Email :')}</span>
            <span>{data?.selectedOne?.email}</span>
          </div>
          <div className='flex mb-4 gap-2'>
            <span className='font-semibold min-w-[70px]'>{t('Phone :')}</span>
            <span>{data?.selectedOne?.phone}</span>
          </div>

          <div className='flex flex-col mb-4 gap-2'>
            <span className='font-semibold min-w-[70px]'>{t('Message :')}</span>
            <span>{data?.selectedOne?.message}</span>
          </div>
        </div>
      </ModalDialog>
    </div>
  );
};

export default EditContact;
