import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingDelete: false,
  loadingDeleteAll: false,
  loadingCreate: false,
  loadingEdit: false,
  loadingImport: false,
  data: [],
  error: null,
  errorFetch: null,
  success: null,
  totalCount: 0,
  selectedOne: null,
  selectedMany: [],
  isSelectedMany: false,
  deleteModal: false,
  deleteAllModal: false,
  createModal: false,
  createAllModal: false,
  editModal: false,
  editAllModal: false,
  exportModal: false,
  exportSelectedModal: false,
  importModal: false,
};

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    // fetchInstance
    fetchAddresssStart: (state) => {
      state.loading = true;
    },
    fetchAddresssSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload?.addresss?.nodes;
      state.totalCount = action.payload?.addresss?.totalCount;
      state.errorFetch = false;
    },
    fetchAddresssFail: (state, action) => {
      state.loading = false;
      state.data = [];
      state.errorFetch = action.payload;
    },

    // createInstance
    createAddressStart: (state) => {
      state.loadingCreate = true;
    },
    createAddressSuccess: (state, action) => {
      state.loadingCreate = false;
      state.data.unshift(action.payload);
      state.success = 'Address created successfully.';
    },
    createAddressFail: (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    },

    // editInstance
    editAddressStart: (state) => {
      state.loadingEdit = true;
    },
    editAddressSuccess: (state, action) => {
      state.loadingEdit = false;
      state.data = state.data.map((address) =>
        address.id === action.payload.id ? action.payload : address
      );
      state.success = 'Address updated successfully.';
    },
    editAddressFail: (state, action) => {
      state.loadingEdit = false;
      state.error = action.payload;
    },

    // delete Instance
    deleteAddressStart: (state) => {
      state.loadingDelete = true;
    },
    deleteAddressSuccess: (state, action) => {
      state.loadingDelete = false;
      state.data = state.data.filter((address) => address.id !== action.payload);
      state.success = 'Address deleted successfully';
      state.deleteModal = false;
    },
    deleteAddressFail: (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
      state.deleteModal = false;
    },

    // delete Many Instances
    deleteAddresssStart: (state) => {
      state.loadingDeleteAll = true;
    },
    deleteAddresssSuccess: (state, action) => {
      state.loadingDeleteAll = false;
      const deletedIds = action.payload;
      state.data = state.data.filter((address) => !deletedIds.includes(address.id));
      state.success = 'Addresss deleted successfully';
      state.deleteAllModal = false;
    },
    deleteAddresssFail: (state, action) => {
      state.loadingDeleteAll = false;
      state.error = action.payload;
      state.deleteAllModal = false;
    },

    // selectInstance

    selectOne: (state, action) => {
      state.selectedOne = action.payload;
    },

    selectAll: (state, action) => {
      if (state.isSelectedMany) {
        state.selectedMany = [];
        state.isSelectedMany = false;
      } else {
        state?.data?.map((item) => {
          if (!state.selectedMany.includes(item.id)) {
            state.selectedMany = [...state.selectedMany, item.id];
          }
        });
        state.isSelectedMany = true;
      }
    },

    removeAll: (state, action) => {
      state.selectedMany = [];
      state.isSelectedMany = false;
    },

    updateSelected: (state, action) => {
      if (state.selectedMany.includes(action.payload)) {
        state.selectedMany = state.selectedMany.filter((item) => {
          return item !== action.payload;
        });
      } else {
        state.selectedMany = [...state.selectedMany, action.payload];
      }
    },

    // DELETEiNSTANCE

    openModelDelete: (state, action) => {
      state.selectedOne = action.payload;
      state.deleteModal = !state.deleteModal;
    },

    openModelDeleteAll: (state, action) => {
      state.deleteAllModal = !state.deleteAllModal;
    },


    openModelCreate: (state, action) => {
      state.createModal = !state.createModal;
    },

    openModelEdit: (state, action) => {
      state.selectedOne = action.payload;
      state.editModal = !state.editModal;
    },
    // ExportSelected

    openModeleExportAll: (state, action) => {
      state.exportModal = true;
    },

    openModeleExportSelected: (state, action) => {
      state.exportSelectedModal = true;
    },

    // Import

    openModeleImport: (state, action) => {
      state.importModal = !state.importModal;
    },

    importStart: (state) => {
      state.loadingImport = true;
    },
    importSuccess: (state, action) => {
      state.loadingImport = false;
      state.success = 'Addresss Imported successfully';
      state.importModal = false;
    },
    importFail: (state, action) => {
      state.loadingImport = false;
      state.error = action.payload;
      state.importModal = false;
    },

    // CLEAR
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  fetchAddresssStart,
  fetchAddresssSuccess,
  fetchAddresssFail,
  createAddressStart,
  createAddressSuccess,
  createAddressFail,
  editAddressStart,
  openModelEdit,
  openModelCreate,
  editAddressSuccess,
  editAddressFail,
  deleteAddressStart,
  deleteAddressSuccess,
  deleteAddressFail,
  clearMessages,
  updateSelected,
  selectAll,
  selectOne,
  openModelDelete,
  openModelDeleteAll,
  openModeleExportAll,
  openModeleExportSelected,
  deleteAddresssStart,
  deleteAddresssSuccess,
  deleteAddresssFail,
  openModeleImport,
  importStart,
  importSuccess,
  importFail,
  removeAll,
} = addressSlice.actions;

export default addressSlice.reducer;
