import { constants } from '../constants';
import { client } from '../global/apolloClient';
import { CREATE_CHECKOUT, SEND_RECOVRERY_EMAIL } from '../graphql/mutation/checkout.mutation';
import { GET_ALL_CHECKOUTS, GET_CHECKOUT } from '../graphql/query/checkouts.query';
import {
  createCheckoutFail,
  createCheckoutStart,
  createCheckoutSuccess,
  deleteCheckoutFail,
  deleteCheckoutStart,
  deleteCheckoutSuccess,
  deleteCheckoutsFail,
  deleteCheckoutsStart,
  deleteCheckoutsSuccess,
  editCheckoutFail,
  editCheckoutStart,
  editCheckoutSuccess,
  fetchCheckoutsFail,
  fetchCheckoutsStart,
  fetchCheckoutsSuccess,
  importFail,
  importStart,
  importSuccess,
  removeAll,
  sendRecoveryEmailFail,
  sendRecoveryEmailStart,
  sendRecoveryEmailSuccess,
} from '../store/features/checkout/checkoutSlicer';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation } from './executeMutation';
import { uploadImage } from './upload';
// import { CREATE_ORDER, DELETE_ORDER, EDIT_ORDER_PAYMENT_STATUS, EDIT_ORDER_SHIPPING_STATUS } from "../graphql/mutation/checkout.mutation";
// import { GET_ALL_ORDERS, GET_ORDER } from "../graphql/query/checkouts.query";

const token = getTheTokenFromStorage();



export const createCheckout = async (values, accessToken) => {
  return await executeMutation(
    CREATE_CHECKOUT,
    [GET_ALL_CHECKOUTS, GET_CHECKOUT],
    values,
    accessToken
  );
};


export const sendRecoveryEmail = async (values, accessToken) => {
  try {
    const result = await client.mutate({
      mutation: SEND_RECOVRERY_EMAIL,
      variables: {
        ...values,
      },
      context: { accessToken },
      refetchQueries : [GET_CHECKOUT]

    });

    return result;
  } catch (error) {
    return error.message;
  }
};

export const editCheckout = async (values, accessToken) => {
  // return await executeMutation(
  //   EDIT_MENU,
  //   [GET_ALL_MENUS, GET_MENU],
  //   values,
  //   accessToken
  // );
};




export const deleteCheckout = async (values, accessToken) => {
  // return await executeMutation(
  //   DELETE_CHECKOUTS,
  //   [GET_ALL_CHECKOUTS, GET_CHECKOUT],
  //   values,
  //   accessToken
  // );
};

export const deleteManyCheckouts = async (values, accessToken) => {
  // return await executeMutation(
  //   DELETE_CHECKOUTS,
  //   [GET_ALL_CHECKOUTS, GET_CHECKOUT],
  //   values,
  //   accessToken
  // );
};

export const fetchCheckouts = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCheckoutsStart());
      const { data } = await client.query({
        query: GET_ALL_CHECKOUTS,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchCheckoutsSuccess(data));
    } catch (error) {
      dispatch(fetchCheckoutsFail(error));
    }
  };
};



export const handleDeleteCheckout = (id) => {
  return async (dispatch) => {
    dispatch(deleteCheckoutStart());
    const dataSent = {
      removeCheckoutId: parseInt(id),
    };
    try {
      let res = await deleteCheckout(dataSent, token);
      if (res?.data?.removeCheckout) {
        dispatch(deleteCheckoutSuccess(id));
        dispatch(showSuccessMessage('Checkout deleted successfully'));
      } else {
        dispatch(deleteCheckoutFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteCheckoutFail(error));
    }
  };
};

export const handleDeleteManyCheckout = (ids) => {
  return async (dispatch) => {
    dispatch(deleteCheckoutsStart());
    const dataSent = {
      removeCheckoutId: ids.map((id) => parseInt(id)),
    };
    try {
      let res = await deleteManyCheckouts(dataSent, token);
      if (res?.data?.removeCheckouts) {
        dispatch(deleteCheckoutsSuccess(ids));
        dispatch(showSuccessMessage('Checkouts deleted successfully'));
      } else {
        dispatch(deleteCheckoutsFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteCheckoutsFail(error));
    }
  };
};


// Fix This
export const handleCreateCheckout = (values, callback) => {
  return async (dispatch) => {
    dispatch(createCheckoutStart());

    let imageSent = values.featuredAsset[0]
      ? await uploadImage(values.featuredAsset[0])
      : null;

    let translationDataSent = values.languages
      ? Object.keys(values.languages).map((key) => {
          return {
            name: values.languages[key].name,
            description: values.languages[key].description,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      input: {
        isRoot: values.isRoot,
        parent: values.parent?.id ? +values.parent.id : null,
        translationData: translationDataSent,
        collections: values.collections.map(function (obj) {
          return +obj.id;
        }),
        media: imageSent?.data?.id ? +imageSent?.data?.id : null,
      },
    };

    try {
      const res = await createCheckout(dataSent, token);
      if (res?.data?.createCheckout) {
        dispatch(createCheckoutSuccess(res?.data?.createCheckout));
        dispatch(showSuccessMessage('this Checkout has been successfully created'));

        if (callback) {
          callback(res?.data?.createCheckout.id);
        }
      } else {
        dispatch(createCheckoutFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(createCheckoutFail(error));
      dispatch(showErrorMessage(error));
    }
  };
};

export const handleEditCheckout = (values, callback) => {
  return async (dispatch) => {
    dispatch(editCheckoutStart());

    let imageSent = values.featuredAsset[0]
      ? await uploadImage(values.featuredAsset[0])
      : null;

    let translationDataSent = values.translationData
      ? Object.keys(values.translationData).map((key) => {
          return {
            name: values.translationData[key].name,
            description: values.translationData[key].description,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      input: {
        id: values.id,
        isRoot: values.isRoot,
        parent: values.parent?.id ? +values.parent.id : null,
        translationData: translationDataSent,
        collections: values.collections.map(function (obj) {
          return +obj.id;
        }),
        media: imageSent?.data?.id ? +imageSent?.data?.id : null,
      },
    };

    try {
      let res = await editCheckout(dataSent, token);
      if (res?.data?.updateCheckout) {
        dispatch(editCheckoutSuccess(res?.data?.updateCheckout));
        dispatch(showSuccessMessage('this Checkout has been successfully updated'));

        if (callback) {
          callback(res?.data?.updateCheckout.id);
        }
      } else {
        dispatch(editCheckoutFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(editCheckoutFail(error));
      dispatch(showErrorMessage(error));
    }
  };
};

export const handleImportCheckout = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyCheckouts(dataSent, token);
      if (res?.data?.removeCheckouts) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Checkouts Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteCheckoutsFail(error));
      });
    }
  };
};

export const handleExportCheckout = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyCheckouts(dataSent, token);
      // dispatch(removeAll())
    
    } catch (error) {
      // dispatch(removeAll())
     
    }
  };
};

export const handleExportAllCheckouts = () => {
  console.log('handleExportAllFiles =>' );
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyCheckouts(dataSent, token);
    } catch (error) {}
  };
};






// Fix This
export const handleSendRecovryEmail = (values, callback) => {
  return async (dispatch) => {
    dispatch(sendRecoveryEmailStart());

    const dataSent = {
      checkoutId: +values?.id,
    };
  
    try {
      let res = await sendRecoveryEmail(dataSent, token);
      if (res?.data) {
        dispatch(sendRecoveryEmailSuccess(res?.data));
        dispatch(showSuccessMessage('this Checkout has been successfully created'));
      } else {
        dispatch(sendRecoveryEmailFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(sendRecoveryEmailFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);

    }
  };
};



