import React from "react";
import { useStateContext } from "../../contexts/ContextProvider";

const GraphCard = ({ children, title, description }) => {
  const { bodyStyles } = useStateContext();
  return (
    <div
      className="bg-white pb-8 w-full rounded-[8px]"
      style={{
        boxShadow: "0px 2.56757px 12.8378px rgba(0, 0, 0, 0.1)",
        background: bodyStyles?.cardBackground,
        color: bodyStyles?.text,
        border: bodyStyles?.border,
      }}
    >
      <div
        className="flex flex-row items-center justify-between border-b-[2.56px] px-10 py-4"
        style={{
          boxShadow: "0px 2.56757px 12.8378px rgba(0, 0, 0, 0.01)",
          background: bodyStyles?.cardBackground,
          color: bodyStyles?.text,
          borderColor: "rgba(0, 0, 0, 0.0.02)",
        }}
      >
        <div className="flex flex-col w-full">
          {title && (
            <span className="text-xl font-[600] text-[#828282]">{title}</span>
          )}

          {description && (
            <span className="text-base font-[400] text-[#4F4F4F] mt-4">
              {description}
            </span>
          )}
        </div>
        {/* <div>
        <img
          src="/images/info.svg"
          alt="info"
          className=" block mx-auto cursor-pointer "
        />
      </div> */}
      </div>
      <div className="flex items-center justify-center ">
        {children}
      </div>
    </div>
  );
};

export default GraphCard;
