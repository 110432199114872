import * as Yup from "yup";

const customerValidation = () => {
  //regex var
  const regexLowerCase = /[a-z]+/;
  const regexUpperCase = /[A-Z]+/;
  const regexSpecialCharOrOneNumber =
    /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\d]+/;

  const phoneRegex =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

  const addCustomerSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, "Please enter more than 2 character")
      .required("This Field is required"),
    lastName: Yup.string()
      .min(3, "Please enter more than 2 character")
      .required("This Field is required"),
    // userName: Yup.string()
    //   .min(3, "Please enter more than 2 character")
    //   .required("This Field is required"),

    email: Yup.string()
      .email("Enter a valid email")
      .required("This field is required"),

    phoneNumber: Yup.string().matches(phoneRegex, "Enter a valid phone number"),

    // password: Yup.string()
    //   .required("This field is required")
    //   .min(3, "password to short, should be at least 3 characters long")
      // .matches(
      //   regexLowerCase,
      //   "Password must include at least One lowercase character"
      // )
      // .matches(
      //   regexUpperCase,
      //   "Password must include at least One uppercase character"
      // )
      // .matches(
      //   regexSpecialCharOrOneNumber,
      //   "Password must include at least One special character or One number"
      // ),
  });

  const editUserSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, "Please enter more than 2 character")
      .required("This Field is required"),
    lastName: Yup.string()
      .min(3, "Please enter more than 2 character")
      .required("This Field is required"),
    // userName: Yup.string()
    //   .min(3, "Please enter more than 2 character")
    //   .required("This Field is required"),

    email: Yup.string()
      .email("Enter a valid email")
      .required("This field is required"),

    phoneNumber: Yup.string().matches(phoneRegex, "Enter a valid phone number"),

    password: Yup.string()
    // .required("This field is required")
    // .min(3, "password to short, should be at least 3 characters long")
  });


  return {
    addCustomerSchema,
    editUserSchema
  };
};

export { customerValidation };
