import { client } from '../global/apolloClient';
import { GET_ALL_TAGS, GET_TAG } from '../graphql/query/tags.query';
import {
  createTagFail,
  createTagStart,
  createTagSuccess,
  deleteTagFail,
  deleteTagStart,
  deleteTagSuccess,
  deleteTagsFail,
  deleteTagsStart,
  deleteTagsSuccess,
  editTagFail,
  editTagStart,
  editTagSuccess,
  fetchTagsFail,
  fetchTagsStart,
  fetchTagsSuccess,
  importFail,
  importStart,
  importSuccess,
  removeAll,
} from '../store/features/tag/tagSlicer';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation } from './executeMutation';
import { uploadImage } from './upload';
import {
  CREATE_TAG,
  DELETE_TAG,
  EDIT_TAG,
} from '../graphql/mutation/tag.mutation';

const token = getTheTokenFromStorage();

export const createTag = async (values, accessToken) => {
  return await executeMutation(
    CREATE_TAG,
    [GET_ALL_TAGS, GET_TAG],
    values,
    accessToken
  );
};

export const editTag = async (values, accessToken) => {
  return await executeMutation(
    EDIT_TAG,
    [GET_ALL_TAGS, GET_TAG],
    values,
    accessToken
  );
};

export const deleteTag = async (values, accessToken) => {
  return await executeMutation(
    DELETE_TAG,
    [GET_ALL_TAGS, GET_TAG],
    values,
    accessToken
  );
};

export const deleteManyTag = async (values, accessToken) => {
  return await executeMutation(
    DELETE_TAG,
    [GET_ALL_TAGS, GET_TAG],
    values,
    accessToken
  );
};

export const fetchTags = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchTagsStart());
      const { data } = await client.query({
        query: GET_ALL_TAGS,
        variables,
        fetchPolicy: 'network-only',
      });
      dispatch(fetchTagsSuccess(data));
    } catch (error) {
      dispatch(fetchTagsFail(error));
    }
  };
};

export const handleDeleteTag = (id) => {
  return async (dispatch) => {
    dispatch(deleteTagStart());
    const dataSent = {
      removeTagId: parseInt(id),
    };
    try {
      let res = await deleteTag(dataSent, token);
      if (res?.data?.removeTag) {
        dispatch(deleteTagSuccess(id));
        dispatch(showSuccessMessage('Tag deleted successfully'));
      } else {
        dispatch(deleteTagFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteTagFail(error));
    }
  };
};

export const handleDeleteManyTag = (ids) => {
  return async (dispatch) => {
    dispatch(deleteTagsStart());
    const dataSent = {
      removeTagId: ids.map((id) => parseInt(id)),
    };
    try {
      let res = await deleteManyTag(dataSent, token);
      if (res?.data?.removeTags) {
        dispatch(deleteTagsSuccess(ids));
        dispatch(showSuccessMessage('Tags deleted successfully'));
      } else {
        dispatch(deleteTagsFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteTagsFail(error));
    }
  };
};

export const handleCreateTag = (values, callback) => {
  console.log('values', values)
  return async (dispatch) => {
    
    dispatch(createTagStart());
    let translationDataSent = values.translationData
      ? Object.keys(values.translationData).map((key) => {
          return {
            name: values.translationData[key].name,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      input: {
        translationData: translationDataSent,
      },
    };

    try {
      const res = await createTag(dataSent, token);
      if (res?.data?.createTag) {
        dispatch(createTagSuccess(res?.data?.createTag));
        dispatch(showSuccessMessage('this Tag has been successfully created'));

        if (callback) {
          callback(res?.data?.createTag.id);
        }
      } else {
        if (callback) {
          callback(res);
        }
        dispatch(createTagFail(res));
        dispatch(showErrorMessage(res));
        if (callback) {
          callback(res);
        }
      }
    } catch (error) {
      if (callback) {
        callback(error);
      }
      dispatch(createTagFail(error));
      dispatch(showErrorMessage(error));
      
    }
  };
};

export const handleEditTag = (values, callback) => {
  return async (dispatch) => {
    dispatch(editTagStart());

    let translationDataSent = values.translationData
      ? Object.keys(values.translationData).map((key) => {
          return {
            name: values.translationData[key].name,
            description: values.translationData[key].description,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      input: {
        id: values.id,
        translationData: translationDataSent,
      },
    };

    try {
      let res = await editTag(dataSent, token);
      if (res?.data?.updateTag) {
        dispatch(editTagSuccess(res?.data?.updateTag));
        dispatch(showSuccessMessage('this Tag has been successfully updated'));

        if (callback) {
          callback(res?.data?.updateTag.id);
        }
      } else {
        dispatch(editTagFail(res));
        dispatch(showErrorMessage(res));
        if (callback) {
          callback(res);
        }
      }
    } catch (error) {
      dispatch(editTagFail(error));
      dispatch(showErrorMessage(error));
      if (callback) {
        callback(error);
      }
    }
  };
};

export const handleImportTags = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyTag(dataSent, token);
      if (res?.data?.removeTags) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Tags Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteTagsFail(error));
      });
    }
  };
};

export const handleExportTags = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyTag(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllTags = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyTag(dataSent, token);
    } catch (error) {}
  };
};
