import React, { useContext, useState } from "react";

const mainContext = React.createContext();

export function useMapContext() {
  return useContext(mainContext);
}

export function MapContext({ children }) {
  const [eventData, setEventData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [reRenderMarks, setReRenderMarks] = useState(null);

  const value = {
    eventData,
    setEventData,
    selectedEvent,
    setSelectedEvent,
    reRenderMarks,
    setReRenderMarks,
  };

  return <mainContext.Provider value={value}>{children}</mainContext.Provider>;
}
