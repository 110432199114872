import React, { useState, useEffect, useRef } from 'react';
import { FormControl, ClickAwayListener } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Calendar from './Calendar';
import BasicButton from './Buttons/BasicButton';
import FiledButton from './Buttons/FiledButton';
import { useStateContext } from '../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import RtlConversion from './RtlConversion';
import BasicSelectMui from './Inputs/BasicSelectMui';

const DateRange = ({ dates, setDates, setInitPeriod, initialPeriod }) => {
  const { bodyStyles } = useStateContext();
  const { t, i18n } = useTranslation();
  let dateNow = new Date();
  const dateYesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  const lastWeek = new Date(new Date().setDate(new Date().getDate() - 6));
  const lastMonth = new Date(new Date().setDate(new Date().getDate() - 29));
  const lastThreeMonths = new Date(
    new Date().setDate(new Date().getDate() - 89)
  );
  const [show, setShow] = useState(false);
  const [label, setLabel] = useState(t('Today'));
  const [period, setPeriod] = useState({
    id: 1,
    label: t('Today'),
    startDate: new Date(),
    endDate: new Date(),
  });
  const dateRangeRef = useRef();

  const periods = [
    { id: 1, label: t('Today'), startDate: dateNow, endDate: dateNow },
    {
      id: 2,
      label: t('Yesterday'),
      startDate: dateYesterday,
      endDate: dateYesterday,
    },
    { id: 3, label: t('Last 7 days'), startDate: lastWeek, endDate: dateNow },
    { id: 4, label: t('Last 30 days'), startDate: lastMonth, endDate: dateNow },
    {
      id: 5,
      label: t('Last 90 days'),
      startDate: lastThreeMonths,
      endDate: dateNow,
    },
    {
      id: 6,
      label: t('Custom'),
      startDate: period.startDate,
      endDate: period.endDate,
    },
  ];

  useEffect(() => {
    if (initialPeriod) {
      setPeriod(initialPeriod);
      setLabel(initialPeriod.label);
    }
  }, [initialPeriod]);

  function handleClickOutside(event) {
    if (dateRangeRef.current && !dateRangeRef.current.contains(event.target)) {
      setShow(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dateRangeRef]);
  const showDataRange = () => {
    setShow(!show);
  };

  const handleOnApply = () => {
    if (period.label !== 'Custom') {
      setInitPeriod(period);
    } else {
      setInitPeriod({
        ...initialPeriod,
        id: 6,
        label: 'Custom',
        startDate: dates.startDate,
        endDate: dates.endDate,
      });
    }
    showDataRange();
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShow(false);
      }}
    >
      <RtlConversion rtl={i18n.language === 'ar' ? true : false}>
        <div className=' flex flex-row items-center justify-center'>
          <div className='relative'>
            <div
              className='flex transform cursor-pointer items-center justify-center
                gap-x-1 rounded border-[1px] 
               border-[#C0C0C0] px-2 shadow-sm drop-shadow-md transition-all hover:opacity-75 active:scale-95 '
              style={{
                width: 100,
                height: 40,
                borderColor: bodyStyles?.inputBorder,
              }}
              type='button'
              onClick={showDataRange}
            >
              <CalendarTodayIcon
                style={{ color: bodyStyles?.text || '#1672EC' }}
                className='ml-2 '
              />
              <span
                style={{ color: bodyStyles?.text || '#1672EC' }}
                className='ml-2 text-base font-[700] '
              >
                {t('Date')}
              </span>
            </div>

            {show ? (
              <div
                className={`absolute top-[50px] z-[2000] flex md:min-w-[600px]`}
              >
                <div
                  style={{
                    backgroundColor: bodyStyles?.background || '#fff',
                    borderColor: bodyStyles?.border || '#C0C0C0',
                  }}
                  className='flex min-h-[200px] rounded border-[1px] border-[#C0C0C0] bg-[#ffffff] shadow-sm '
                >
                  <div className='my-4 mx-4 flex flex-col justify-between space-y-3'>
                    <FormControl variant='outlined' className=''>
                      <BasicSelectMui
                        type='text'
                        name='select-period'
                        id='demo-customized-select-native'
                        label={t('Date Range')}
                        value={period.id}
                        onChange={(e) => {
                          setPeriod(
                            periods.find((item) => item.id === e.target.value)
                          );
                        }}
                        options={periods}
                      />
                    </FormControl>

                    <div className='grid grid-cols-2 gap-2'>
                      <Calendar
                        value={dates.startDate}
                        onChange={(e) =>
                          setDates({
                            ...dates,
                            startDate: e,
                          })
                        }
                        disabled={period.label !== 'Custom'}
                      />

                      <Calendar
                        value={dates.endDate}
                        onChange={(e) =>
                          setDates({
                            ...dates,
                            endDate: e,
                          })
                        }
                        disabled={period.label !== 'Custom'}
                      />
                    </div>

                    <div className='flex justify-between  gap-4'>
                      <BasicButton
                        text={t('Cancel')}
                        onClick={showDataRange}
                        textSmall
                      />

                      <FiledButton
                        text={t('Apply')}
                        type='button'
                        onClick={handleOnApply}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className='mx-8 hidden text-base font-[500] text-[#808080] md:block '>
            {t('Compared to') + 
              ` ${
                initialPeriod.label !== 'Custom'
                  ? t(initialPeriod.label)
                  : t('Custom')
              } ( ${initialPeriod?.startDate?.toDateString()} - ${initialPeriod?.endDate?.toDateString()} )`}
          </div>
        </div>
      </RtlConversion>
    </ClickAwayListener>
  );
};

export default DateRange;
