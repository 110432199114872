import { split, HttpLink, ApolloClient, InMemoryCache } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { generalData } from '../utils/general';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { getTheRefresh, getTheTokenFromStorage } from '../services/auth';

const backendApi = localStorage.getItem('api') || generalData.backendApi;

const link = createUploadLink({
  uri: localStorage.getItem('api')
    ? `${localStorage.getItem('api')}/graphql`
    : `https://${backendApi}/graphql`,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `wss://${backendApi}/graphql`,
    options: {
      reconnect: true,
    },
  })
);

const authLink = setContext((_, context) => {
  const token = getTheTokenFromStorage();
  const refreshToken = getTheRefresh();

  return {
    headers: {
      // authorization: token ? `Bearer ${token}` : "",
      authorization: token ? `Bearer ${token}` : '',
      'x-refresh-token': refreshToken ? `${refreshToken}` : '',
    },
  };
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(link)
);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink,
});
