import { useState } from "react";
import { FormControl } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { useTranslation } from "react-i18next";
import { customerValidation } from "../../validations/customer.validation";
import BasicInput from "../../components/Inputs/BasicInput";
import BottomBanner from "../../components/Actions/BottomBanner";
import PageModal from "../../components/PageModal";
import BasicSelectMui from "../../components/Inputs/BasicSelectMui";
import { useDispatch, useSelector } from "react-redux";
import { delay } from "../../utils/helpers";
import { handleCreateCustomer } from "../../services/customer";

const AddCustomer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const dispatch = useDispatch();
  const data = useSelector((state) => state.customer);

  const { bodyStyles } = useStateContext();

  const { addCustomerSchema } = customerValidation();

  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    phoneNumber: "",
    password: "",
    role: "user",
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: fields.firstName,
      lastName: fields.lastName,
      email: fields.email,
      phoneNumber: fields.phoneNumber,
      // password: fields.password,
      role: fields.role,
    },
    validationSchema: addCustomerSchema,
    onSubmit: async (values) => {
      dispatch(
        handleCreateCustomer(values, (res) => {
          delay(1000).then(async () => {
            navigate(`/customers/edit/${res?.data?.createCustomer?.id}`);
          });
        })
      );
    },
  });

  return (
    <PageModal
      pageName={t("Add Customer")}
      pagePrev={`/customers`}

    >
      <BottomBanner
        btn01="Cancel"
        btn01Action={() => navigate(`/customers`)}
        btn02="Add Customer"
        btn02Action={() => formik.handleSubmit()}
        loading={data.loadingCreate}
      />

      <form id="product" className=" font-Inter relative">
        <div className="min-h-[83vh] flex flex-col justify-between">
          <div>
            <div className="flex gap-5 flex-col md:flex-row  ">
              <div className="w-[100%]  space-y-6 ">
                <div className=" space-y-2  rounded-lg  ">
                  <div className="space-y-6 font-urbanist ">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-10 px-2 sm:px-0">
                      <div className="space-y-4 col-span-2  ">
                        <div
                          className="font-Inter text-xl pt-7 pb-5
                       uppercase font-bold flex items-center justify-start"
                          style={{
                            color: bodyStyles.subTitle,
                          }}
                        >
                          <span>{t("Customer Overview")}</span>
                        </div>
                        <div className="flex flex-col md:flex-row  gap-4">
                          <BasicInput
                            label={t("FirstName")}
                            placeholder="Amine"
                            value={fields?.firstName}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                firstName: e.target.value,
                              })
                            }
                            error={
                              formik.touched.firstName &&
                              Boolean(formik.errors.firstName)
                            }
                            helperText={
                              formik.touched.firstName &&
                              formik.errors.firstName
                            }
                          />

                          <BasicInput
                            label={t("LastName")}
                            placeholder="Amine"
                            value={fields?.lastName}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                lastName: e.target.value,
                              })
                            }
                            error={
                              formik.touched.lastName &&
                              Boolean(formik.errors.lastName)
                            }
                            helperText={
                              formik.touched.lastName && formik.errors.lastName
                            }
                          />
                        </div>

                        <BasicInput
                          label={t("email")}
                          placeholder="cust_name@email.com"
                          value={fields?.email}
                          onChange={(e) =>
                            setFields({ ...fields, email: e.target.value })
                          }
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                          inputProps={{
                            style: { fontSize: '12px' },
                            autocomplete: 'new-password',
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                        />

                        <BasicInput
                          label={t("Phone (optional)")}
                          type="tel"
                          placeholder="0554260312"
                          value={fields?.phoneNumber}
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              phoneNumber: e.target.value,
                            });
                          }}
                          error={
                            formik.touched.phoneNumber &&
                            Boolean(formik.errors.phoneNumber)
                          }
                          helperText={
                            formik.touched.phoneNumber &&
                            formik.errors.phoneNumber
                          }
                        />
{/* 
                        <BasicInput
                          label={t("password")}
                          type="password"
                          placeholder="******"
                          value={fields?.password}
                          onChange={(e) =>
                            setFields({ ...fields, password: e.target.value })
                          }
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                          inputProps={{
                            style: { fontSize: '12px' },
                            autocomplete: 'new-password',
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                        /> */}
                      </div>

                      {/* stauts Column */}
                      <div className="space-y-4 col-span-1 w-full  ">
                        <div
                          className="font-Inter text-xl pt-7 pb-5
                       uppercase font-bold flex items-center justify-start"
                          style={{
                            color: bodyStyles.subTitle,
                          }}
                        >
                          <span>{t("Customer Status")}</span>
                        </div>

                        <FormControl fullWidth>
                          <BasicSelectMui
                            type="text"
                            disabled={true}
                            name="role"
                            id="user-role-select"
                            label={t("Role")}
                            value={(fields.role)}
                            onChange={(e) =>
                              setFields({ ...fields, role: e.target.value })
                            }
                            error={
                              formik.touched.role && Boolean(formik.errors.role)
                            }
                            options={[
                              { value: "user", label: t("User") },
                              { value: "admin", label: t("Admin") },
                              { value: "staff", label: t("Staff") },
                              { value: "seller", label: t("Seller") },
                            ]}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </PageModal>
  );
};

export default AddCustomer;
