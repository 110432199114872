import React from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import BasicButton from "../Buttons/BasicButton";
import FiledButton from "../Buttons/FiledButton";
import { useTranslation } from "react-i18next";

const BottomBanner = ({ btn01, btn01Action, btn02, btn02Action, loading }) => {
  const { bodyStyles } = useStateContext();
  const { t } = useTranslation();

  return (
    <div className="relative w-full">
      <div
        className={`fixed bottom-0 z-[10]  flex  py-2 px-3  mt-1 border-t justify-end
                 fill-available duration-500  `}
        style={{
          backgroundColor: bodyStyles.background,
          borderColor: bodyStyles?.inputBorder,
          width: "-webkit-fill-available",
        }}
      >
        <div className="flex gap-2 h-[37px] ">
        {btn01 &&  <BasicButton text={t(btn01)} onClick={btn01Action} textSmall />} 
          {loading ? (
            <FiledButton loading />
          ) : (
            <FiledButton text={t(btn02)} minWidth="95px" onClick={btn02Action} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BottomBanner;
