import React, { useEffect, useState } from 'react';
import BasicInput from '../../../Inputs/BasicInput';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import RtlConversion from '../../../RtlConversion';
import { getTheTokenFromStorage } from '../../../../services/auth';
import { handleEditSeetingWithData } from '../../../../services/setting';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';

const FacebookPixels = ({ data }) => {
  const [disableAll, setdisableAll] = useState(false);

  const dispatch = useDispatch();

  const token = getTheTokenFromStorage();
  const { bodyStyles } = useStateContext();

  const [loading, setLoading] = useState({
    facebookSettings: false,
  });

  const { t, i18n } = useTranslation();
  const [fields, setFields] = useState({
    facebookPixels: '',
    fb_access_token: '',
    deliverabilityRate: '',
    conversionType: '',
  });

  useEffect(() => {
    if (data?.setting_online) {
      setFields((prevState) => ({
        ...prevState,

        facebookPixels: data?.setting_online?.fb_pixel,
        fb_access_token: data?.setting_online?.fb_access_token,
        // deliverabilityRate:
        //   data?.setting_online?.fb_deliverability_rate,
        // conversionType: data?.setting_online?.fb_conversion_type,
      }));
    }
  }, [data]);

  const addGoogleAnalyticsPixels = Yup.object().shape({
    facebookPixels: Yup.string().required(t('This Field is required')),
    fb_access_token: Yup.string().required(t('This Field is required')),
    // deliverabilityRate: Yup.string().required(t("This Field is required")),
    // conversionType: Yup.string().required(t("This Field is required")),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      facebookPixels: fields?.facebookPixels,
      fb_access_token: fields?.fb_access_token,
      // deliverabilityRate: fields?.deliverabilityRate,
      // conversionType: fields?.conversionType,
    },
    validationSchema: addGoogleAnalyticsPixels,
    onSubmit: async (values) => {
      addOrUpdateFacebookSettings(values);
    },
  });

  const addOrUpdateFacebookSettings = async (values) => {
    setLoading({ ...loading, facebookSettings: true });
    const dataSent = {
      updateSettingInput: {
        setting_online: {
          fb_pixel: values.facebookPixels,
          fb_access_token: values.fb_access_token,
        },
      },
    };

    dispatch(
      handleEditSeetingWithData(dataSent, (res) => {
        setLoading({ ...loading, facebookSettings: false });
      })
    );
  };
  return (
    <div className='flex w-full flex-col items-center justify-center'>
      <div className='flex w-full flex-col rounded-[4px] border-[3px] border-[#337FFF]'>
        <div className='flex h-[54px] flex-row items-center justify-start gap-x-2 bg-[#337FFF] px-2 text-white '>
          <img
            src='/icons/Facebook.svg'
            alt='facebook'
            className=' block cursor-pointer'
          />
          <span>{t('Facebook pixels')}</span>
        </div>
        <div className='flex w-full flex-row items-start justify-center'>
          <RtlConversion rtl={i18n.language === 'ar' ? true : false}>
            <div className='flex w-full flex-col items-center justify-center gap-y-4 py-8 px-4'>
              <BasicInput
                disabled={disableAll}
                label={t('Facebook pixels')}
                // placeholder=""
                value={fields?.facebookPixels}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    facebookPixels: e.target.value,
                  });
                }}
                error={
                  formik.touched.facebookPixels &&
                  Boolean(formik.errors.facebookPixels)
                }
                helperText={
                  formik.touched.facebookPixels && formik.errors.facebookPixels
                }
                InputProps={{
                  style: { fontSize: '12px' },
                }}
              />

              <BasicInput
                disabled={disableAll}
                label={t('Facebook access token')}
                // placeholder=""
                value={fields?.fb_access_token}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    fb_access_token: e.target.value,
                  });
                }}
                error={
                  formik.touched.fb_access_token &&
                  Boolean(formik.errors.fb_access_token)
                }
                helperText={
                  formik.touched.fb_access_token &&
                  formik.errors.fb_access_token
                }
                InputProps={{
                  style: { fontSize: '12px' },
                }}
              />

              {/* <BasicInput
                  disabled={disableAll}
                  label={t("Deliverability rate (COD only)")}
                  // placeholder=""
                  value={fields?.deliverabilityRate}
                  onChange={(e) => {
                    setFields({
                      ...fields,
                      deliverabilityRate: e.target.value,
                    });
                  }}
                  error={
                    formik.touched.deliverabilityRate &&
                    Boolean(formik.errors.deliverabilityRate)
                  }
                  helperText={
                    formik.touched.deliverabilityRate &&
                    formik.errors.deliverabilityRate
                  }
                  InputProps={{
                    style: { fontSize: "12px" },
                  }}
                />
                <BasicInput
                  disabled={disableAll}
                  label={t("Conversion type (COD only)")}
                  // placeholder=""
                  value={fields?.conversionType}
                  onChange={(e) => {
                    setFields({
                      ...fields,
                      conversionType: e.target.value,
                    });
                  }}
                  error={
                    formik.touched.conversionType &&
                    Boolean(formik.errors.conversionType)
                  }
                  helperText={
                    formik.touched.conversionType && formik.errors.conversionType
                  }
                  InputProps={{
                    style: { fontSize: "12px" },
                  }}
                /> */}
            </div>
          </RtlConversion>
          <div className='flex h-full w-[10%] items-start justify-end py-8 px-6'>
            <span
              className=' cursor-pointer text-xl text-[#358DEB] underline'
              onClick={() => formik.submitForm()}
            >
              {loading?.facebookSettings ? (
                <CircularProgress
                  style={{
                    width: 15,
                    height: 15,
                    color: '#358DEB',
                  }}
                />
              ) : (
                t('Save')
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacebookPixels;
