import React from "react";
import BasicInput from "../../../Inputs/BasicInput";
import { BagIcon } from "../../../Svg/MainIcons";
import { Box } from "@mui/material";
import FiledButton from "../../../Buttons/FiledButton";
import BasicSelecAutoComplete from "../../../Inputs/BasicSelecAutoComplete";
import BasicButton from "../../../Buttons/BasicButton";
import { countriesWithCode } from "../../../../utils/countries";
import { useTranslation } from "react-i18next";
import RtlConversion from "../../../RtlConversion";
import IconPlusText from "./IconPlusText";

const Address = ({
  edit,
  setEdit,
  addressInformation,
  setAddressInformation,
  formik,
  onSubmit,
  loading,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="border-t-1 border-color p-4 lg:p-7 font-Inter ">
      <div className="w-full flex justify-between text-sm">
        <p className="uppercase text-[#808080]">{t("Address")}</p>
        <p
          className="text-[#358DEB] cursor-pointer"
          onClick={() => setEdit(!edit)}
        >
          {t("Edit")}
        </p>
      </div>
      {edit ? (
        <RtlConversion rtl={i18n.language === "ar" ? true : false}>
          <div>
            <div className="flex flex-row gap-5 py-4 flex-wrap justify-between">
              <div className="w-full md:w-[49%] ">
                <BasicSelecAutoComplete
                  label={t("Country")}
                  placeholder={t("Search for Country")}
                  options={countriesWithCode}
                  getOptionLabel={(option) => option?.name}
                  isOptionEqualToValue={(option, value) =>
                    option?.code === (value?.code || null)
                  }
                  value={addressInformation?.country}
                  onChange={(event, newValue) => {
                    setAddressInformation({
                      ...addressInformation,
                      country: newValue,
                    });
                  }}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.name} ({option.code})
                    </Box>
                  )}
                />
              </div>

              <div className="w-full md:w-[49%] ">
                <BasicInput
                  label={t("Address")}
                  placeholder={t("Address")}
                  value={addressInformation?.address}
                  onChange={(e) =>
                    setAddressInformation({
                      ...addressInformation,
                      address: e.target.value,
                    })
                  }
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </div>

              <div className="w-full md:w-[49%] ">
                <BasicInput
                  label={t("Apartment, suite, etc.")}
                  placeholder={t("Apartment, suite, etc.")}
                  value={addressInformation?.apartment}
                  onChange={(e) =>
                    setAddressInformation({
                      ...addressInformation,
                      apartment: e.target.value,
                    })
                  }
                  error={
                    formik.touched.apartment && Boolean(formik.errors.apartment)
                  }
                  helperText={
                    formik.touched.apartment && formik.errors.apartment
                  }
                />
              </div>

              <div className="w-full md:w-[49%] ">
                <BasicInput
                  label={t("Postal Code")}
                  placeholder={t("Postal Code")}
                  value={addressInformation?.postalCode}
                  onChange={(e) =>
                    setAddressInformation({
                      ...addressInformation,
                      postalCode: e.target.value,
                    })
                  }
                  error={
                    formik.touched.postalCode &&
                    Boolean(formik.errors.postalCode)
                  }
                  helperText={
                    formik.touched.postalCode && formik.errors.postalCode
                  }
                />
              </div>

              <div className="w-full md:w-[49%] ">
                <BasicInput
                  label={t("City")}
                  placeholder={t("City")}
                  value={addressInformation?.city}
                  onChange={(e) =>
                    setAddressInformation({
                      ...addressInformation,
                      city: e.target.value,
                    })
                  }
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </div>
            </div>
            <div
              className=" items-center
           flex justify-between pt-8"
            >
              <div className="flex gap-2  justify-end w-full">
                <BasicButton
                  text={t("Cancel")}
                  onClick={() => setEdit(false)}
                  textSmall
                />
                {loading ? (
                  <FiledButton type="submit" loading />
                ) : (
                  <FiledButton
                    text={t("Save")}
                    onClick={() => onSubmit()}
                    minWidth="90px"
                  />
                )}
              </div>
            </div>
          </div>
        </RtlConversion>
      ) : (
        <>
          <p className="text-[#888888] text-sm pt-3 lg:pt-6 ">
            {t("Used on customer order confirmations.")}
          </p>
          <div className="py-4 lg:py-8 grid grid-cols-1 md:grid-cols-2 gap-4">
            <IconPlusText
              icon={<BagIcon />}
              title={`${
                addressInformation?.city ? addressInformation?.city : "-"
              }, ${
                addressInformation?.country?.name
                  ? addressInformation?.country?.name
                  : "-"
              }`}
              detail={`${
                addressInformation?.address ? addressInformation?.address : "-"
              } . ${
                addressInformation?.apartment
                  ? addressInformation?.apartment
                  : "-"
              }, ${
                addressInformation?.postalCode
                  ? addressInformation?.postalCode
                  : "-"
              }`}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Address;
