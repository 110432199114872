import React, { useEffect, useState } from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import PrivateRoute from "./components/Hoc/PrivateRoute";
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

// import { Navbar, Footer, Sidebar, ThemeSettings } from '';
import { useStateContext } from '../contexts/ContextProvider';
import Navbar from './Navbar';
import Footer from './Footer';
import Sidebar from './Sidebar';

// import ThemeSettings from "./ThemeSettings";
import { useTranslation } from 'react-i18next';
// import Settings from './Setting/Main';
import RtlConversion from './RtlConversion';

const Layout = ({ children }) => {
  const { t, i18n } = useTranslation();

  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    bodyStyles,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  const [openSetting, setOpenSetting] = useState();

  return (
    <div
      style={{
        background: bodyStyles?.background,
        color: bodyStyles?.text,
      }}
    >
      <div className='flex relative  '>
        <div
          className={`fixed  bottom-4 ${
            i18n.language === 'ar' ? 'left-4' : 'right-4'
          }`}
          style={{ zIndex: '1000' }}
        ></div>
        {activeMenu ? (
          <div
            className='w-72 fixed sidebar h-full'
            style={{
              zIndex: 999,
            }}
          >
            <Sidebar
              openSetting={themeSettings}
              setOpenSetting={setThemeSettings}
            />
          </div>
        ) : (
          <div
            className='w-[54px] fixed sidebar '
            style={{
              zIndex: '9',
            }}
          >
            <Sidebar
              openSetting={themeSettings}
              setOpenSetting={setThemeSettings}
            />
          </div>
        )}

        <div className='p-4 pt-0  pb-0'>
          <div
            className={`w-full fixed z-[1005] duration-[1s]  px-1 pt-1 h-screen ${
              themeSettings ? 'bottom-0' : '-bottom-[100%] '
            }  ${i18n.language === 'ar' ? 'right-0' : 'left-0'}`}
          >
            {/* {themeSettings && <Settings />} */}
          </div>
        </div>
        <div
          className={` overflow-scroll scroll-smooth fill-available h-[102vh] fixed flex-grow

            ${
              activeMenu
                ? i18n.language === 'ar'
                  ? 'md:mr-72'
                  : 'md:ml-72'
                : i18n.language === 'ar'
                ? 'mr-[52px]'
                : 'ml-[52px]'
            }
            `}
        >
          <div
            className=' fill-available sticky top-0 w-full z-10 '
            style={{
              backgroundColor: bodyStyles.background,
            }}
          >
            <Navbar />
          </div>

          <div className='p-4 pb-0 mt-1 w-full relative'>
            <RtlConversion rtl={i18n.language === 'ar' ? true : false}>
              {children}
            </RtlConversion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
