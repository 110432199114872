import React, { useState } from 'react';
import ModalDialog from '../ModalDialog';
import BasicInput from '../Inputs/BasicInput';
import { useStateContext } from '../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import { handleEditMedia } from '../../services/media';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  editMediaStart,
  editMediaSuccess,
} from '../../store/features/media/mediaSlicer';
import { showSuccessMessage } from '../../store/features/snackbar/snackbarSlice';
import { delay } from '../../utils/helpers';

const DescriptionModal = ({
  id,
  description: prevDescription,
  open,
  setOpen,
  value,
  images,
  setImages,
}) => {
  const { bodyStyles } = useStateContext();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.media);

  const [description, setDescription] = useState(prevDescription);
  const [loading, setLoading] = useState(false);

  const mediaSchema = Yup.object().shape({
    alt: Yup.string(),
  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: +id,
      alt: description,
    },
    validationSchema: mediaSchema,
    onSubmit: async (values) => {
      if (value?.imageUrl?.id) {
        dispatch(
          handleEditMedia(values, () => {
            setOpen(false);
          })
        );
      } else {
        dispatch(editMediaStart());

        delay(200).then(async () => {
          setOpen(false);
        });

        delay(300).then(async () => {
          updateAltById(value);
          dispatch(editMediaSuccess());
          dispatch(
            showSuccessMessage('This media has been successfully updated. To preserve the updates, please save your changes.')
          );
        });
      }
    },
  });

  const updateAltById = (id) => {
    setImages((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj === value.imageUrl) {
          let newObj = obj;
          newObj.alt = description;
          return newObj;
        }
        return obj;
      });
      return newState;
    });
    setOpen(false);
  };

  return (
    <ModalDialog
      open={open}
      setOpen={() => setOpen(false)}
      title={t('Edit Image Description')}
      cancelTitle={t('cancel')}
      cancel={() => setOpen(false)}
      actionTitle={t('Save Description')}
      action={() => formik.handleSubmit()}
      loadingAction={data?.loadingEdit}
    >
      <div
        className=' '
        style={{
          borderColor: bodyStyles?.text,
        }}
      >
        <div className='w-[98%]'>
          <div
            className='font-Inter text-sm pt-1 pb-4 uppercase font-bold flex items-center justify-start'
            style={{
              color: bodyStyles.subTitle,
            }}
          >
            <span>{`${t('Image Descripton')}`}</span>
          </div>

          <div className='flex flex-col  gap-6 '>
            <BasicInput
              label={t('Image Descripton')}
              placeholder={t('Descripton....')}
              rows={4}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};

export default DescriptionModal;
