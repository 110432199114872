import { useState, useContext } from 'react';
import { FormControl, InputAdornment } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import { discountValidation } from '../../../validations/discount.validation';
import { storeContext } from '../../../global/store';
import { useDispatch, useSelector } from 'react-redux';
import { handleCreateDiscount } from '../../../services/discount';
import { delay } from '../../../utils/helpers';
import BasicInput from '../../../components/Inputs/BasicInput';
import ReplayIcon from '@mui/icons-material/Replay';
import BasicSelectMui from '../../../components/Inputs/BasicSelectMui';
import RadioGroupType from '../../../components/Inputs/RadioGroupType';
import CheckBox from '../../../components/Inputs/CheckBox';
import Calendar from '../../../components/Calendar';
import BottomBanner from '../../../components/Actions/BottomBanner';
import PageModal from '../../../components/PageModal';

const AddDiscount = () => {
  const { store } = useContext(storeContext);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.discount);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { bodyStyles } = useStateContext();
  const [fields, setFields] = useState({
    discountMethod: 'discount code',
    discountCode: uuidv4().substring(0, 10),
    automaticDiscount: '',
    
    //VALUE
    valueMethod: 'percentage',
    percentage: '',
    fixedAmount: '',

    // Maximum discount uses
    limitNumberUses: false,
    numberUses: '',
    limitOneUse: false,
    startDate: new Date(),
    endDate: new Date(),
    //status
    status: true,
    minimumPurchaseAmount: 0,
  });

  const { addDiscountSchema } = discountValidation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      discountMethod: fields.discountMethod,
      discountCode: fields.discountCode,
      automaticDiscount: fields.automaticDiscount,
      valueMethod: fields.valueMethod,
      percentage: fields.percentage,
      fixedAmount: fields.fixedAmount,
      limitNumberUses: fields.limitNumberUses,
      numberUses: fields.numberUses,
      limitOneUse: fields.limitOneUse,
      startDate: fields.startDate,
      endDate: fields.endDate,
      status: fields.status,
      minimumPurchaseAmount: fields.minimumPurchaseAmount,
    },
    validationSchema: addDiscountSchema,
    onSubmit: async (values) => {
      dispatch(
        handleCreateDiscount(values, (res) => {
          delay(1000).then(async () => {
            navigate(`/discounts`);
          });
        })
      );
    },
  });

  const generatediscountCode = () => {
    setFields({
      ...fields,
      discountCode: uuidv4().substring(0, 10),
    });
  };

  return (
    <PageModal pageName={t('Create discount')} pagePrev={`/discounts`}>
      <BottomBanner
        btn01='Cancel'
        btn01Action={() => navigate(`/discounts`)}
        btn02='Add discount'
        btn02Action={() => formik.handleSubmit()}
        loading={data.loadingCreate}
      />

      <div className='flex gap-5 flex-col md:flex-row mb-[60px] '>
        <div className='w-[100%]  space-y-6 '>
          <div className=' space-y-2  rounded-lg  '>
            <div className='space-y-6 font-urbanist '>
              <div className='grid grid-cols-1 md:grid-cols-3 gap-10 px-2 sm:px-0'>
                <div className='space-y-4 col-span-2  '>
                  {/* Amount off products */}
                  <div
                    className='font-Inter text-xl pt-7 pb-5  uppercase font-bold flex items-center justify-start'
                    style={{
                      color: bodyStyles.subTitle,
                    }}
                  >
                    <span>{t('discount information')}</span>
                  </div>

                  <FormControl>
                    <RadioGroupType
                      value={fields?.discountMethod}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          discountMethod: e.target.value,
                        })
                      }
                      gapY={0}
                      options={[
                        {
                          value: 'discount code',
                          label: t('Discount code'),
                        },
                        {
                          value: 'automatic discount',
                          label: t('Automatic discount'),
                        },
                      ]}
                    />
                  </FormControl>

                  {fields?.discountMethod === 'discount code' ? (
                    <div className='flex flex-col gap-4 items-start w-full'>
                      <div className='flex gap-4 w-full'>
                        <BasicInput
                          label={t('Discount code')}
                          placeholder='aaa215698'
                          value={fields?.discountCode}
                          onChange={(e) =>
                            setFields({
                              ...fields,
                              discountCode: e.target.value,
                            })
                          }
                          error={
                            formik.touched.discountCode &&
                            Boolean(formik.errors.discountCode)
                          }
                          helperText={
                            formik.touched.discountCode &&
                            formik.errors.discountCode
                          }
                          withButton
                          InputProps={{
                            endAdornment: (
                              <span
                                title='generate'
                                onClick={() => generatediscountCode()}
                                className='text-xs cursor-pointer'
                              >
                                <ReplayIcon className='text-sm' />
                              </span>
                            ),
                            style: { fontSize: '12px' },
                          }}
                        />
                      </div>

                      <span>
                        {t('Customers must enter this code at checkout')}.
                      </span>
                    </div>
                  ) : (
                    <div className='flex flex-col gap-4'>
                      <BasicInput
                        label={t('Title')}
                        placeholder={t('• 10% off entire order')}
                        value={fields?.automaticDiscount}
                        onChange={(e) =>
                          setFields({
                            ...fields,
                            automaticDiscount: e.target.value,
                          })
                        }
                        error={
                          formik.touched.automaticDiscount &&
                          Boolean(formik.errors.automaticDiscount)
                        }
                        helperText={
                          formik.touched.automaticDiscount &&
                          formik.errors.automaticDiscount
                        }
                      />
                      <span>
                        {t(
                          'Customers will see this in their cart and at checkout'
                        )}
                        .
                      </span>
                    </div>
                  )}
                  <div
                    className='font-Inter text-xl pt-7 pb-3  uppercase font-bold flex items-center justify-start'
                    style={{
                      color: bodyStyles.subTitle,
                    }}
                  >
                    <span>{t('Value')}</span>
                  </div>

                  <FormControl>
                    <RadioGroupType
                      value={fields?.valueMethod}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          valueMethod: e.target.value,
                        })
                      }
                      gapY={0}
                      options={[
                        {
                          value: 'percentage',
                          label: t('Percentage'),
                        },
                        {
                          value: 'fixedAmount',
                          label: t('Fixed Amount'),
                        },
                      ]}
                    />
                  </FormControl>

                  {fields?.valueMethod === 'percentage' ? (
                    <div className='flex flex-col gap-4'>
                      <BasicInput
                        priceFormat
                        label={t('Percentage')}
                        placeholder='10'
                        value={fields?.percentage}
                        onChange={(e) =>
                          setFields({
                            ...fields,
                            percentage: e.target.value,
                          })
                        }
                        error={
                          formik.touched.percentage &&
                          Boolean(formik.errors.percentage)
                        }
                        helperText={
                          formik.touched.percentage && formik.errors.percentage
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              fontSize='small'
                              position='end'
                              sx={{ color: bodyStyles?.inputText }}
                            >
                              <span className='text-xs'>%</span>
                            </InputAdornment>
                          ),
                          style: { fontSize: '12px' },
                        }}
                      />
                    </div>
                  ) : (
                    <div className='flex flex-col gap-4'>
                      <BasicInput
                        priceFormatSepration
                        label={t('Amount')}
                        placeholder='250'
                        value={fields?.fixedAmount}
                        onChange={(e) =>
                          setFields({
                            ...fields,
                            fixedAmount: +e.target.value,
                          })
                        }
                        error={
                          formik.touched.fixedAmount &&
                          Boolean(formik.errors.fixedAmount)
                        }
                        helperText={
                          formik.touched.fixedAmount &&
                          formik.errors.fixedAmount
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              fontSize='small'
                              position='end'
                              sx={{ color: bodyStyles?.inputText }}
                            >
                              <span className='text-xs'>
                                {store?.currencyStore?.currencyCode}
                              </span>
                            </InputAdornment>
                          ),
                          style: { fontSize: '12px' },
                        }}
                        NumberFormatType
                      />
                    </div>
                  )}

                  <div
                    className='font-Inter text-lg pt-7 pb-3  uppercase font-bold flex items-center justify-start'
                    style={{
                      color: bodyStyles.subTitle,
                    }}
                  >
                    <span>{t('minimum Purchase Amount')}</span>
                  </div>
                  <BasicInput
                    priceFormatSepration
                    label={t('price')}
                    placeholder='250'
                    value={fields?.minimumPurchaseAmount}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        minimumPurchaseAmount: +e.target.value,
                      })
                    }
                    error={
                      formik.touched.minimumPurchaseAmount &&
                      Boolean(formik.errors.minimumPurchaseAmount)
                    }
                    helperText={
                      formik.touched.minimumPurchaseAmount &&
                      formik.errors.minimumPurchaseAmount
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          fontSize='small'
                          position='end'
                          sx={{ color: bodyStyles?.inputText }}
                        >
                          <span className='text-xs'>
                            {store?.currencyStore?.currencyCode}
                          </span>
                        </InputAdornment>
                      ),
                      style: { fontSize: '12px' },
                    }}
                    NumberFormatType
                  />
                  <div
                    className='font-Inter text-lg pt-7 pb-3  uppercase font-bold flex items-center justify-start'
                    style={{
                      color: bodyStyles.subTitle,
                    }}
                  >
                    <span>{t('Maximum discount uses')}</span>
                  </div>

                  <CheckBox
                    checked={fields?.limitNumberUses}
                    onClick={() =>
                      setFields({
                        ...fields,
                        limitNumberUses: !fields?.limitNumberUses,
                      })
                    }
                    label={t(
                      'Limit number of times this discount can be used in total'
                    )}
                  />

                  {fields?.limitNumberUses && (
                    <div className='flex flex-col gap-4'>
                      <BasicInput
                        number
                        label={t('limit usage of this discount')}
                        placeholder='3'
                        value={fields?.numberUses}
                        onChange={(e) =>
                          setFields({
                            ...fields,
                            numberUses: e.target.value,
                          })
                        }
                        error={
                          formik.touched.numberUses &&
                          Boolean(formik.errors.numberUses)
                        }
                        helperText={
                          formik.touched.numberUses && formik.errors.numberUses
                        }
                      />
                    </div>
                  )}

                  <CheckBox
                    checked={fields?.limitOneUse}
                    onClick={() =>
                      setFields({
                        ...fields,
                        limitOneUse: !fields?.limitOneUse,
                      })
                    }
                    label={t('Limit to one use per customer')}
                  />
                  {/* === */}
                </div>

                {/* stauts Column */}
                <div className='space-y-4 col-span-1 w-full  '>
                  {/* Maximum discount usesy */}
                  <div
                    className='font-Inter text-lg pt-7 pb-3  uppercase font-bold flex items-center justify-start'
                    style={{
                      color: bodyStyles.subTitle,
                    }}
                  >
                    <span> {t('discount Status')}</span>
                  </div>

                  <div className='flex flex-col gap-4'>
                    <BasicSelectMui
                      type='text'
                      name='isAvailable'
                      id='discount-status-select'
                      label={t('Status')}
                      value={fields.status}
                      onChange={(e) =>
                        setFields({ ...fields, status: e.target.value })
                      }
                      options={[
                        { value: true, label: t('active') },
                        { value: false, label: t('draft') },
                      ]}
                    />
                  </div>

                  {/* === */}
                  {/* Active dates */}
                  <div
                    className='font-Inter text-lg pt-7 pb-3  uppercase font-bold flex items-center justify-start'
                    style={{
                      color: bodyStyles.subTitle,
                    }}
                  >
                    <span>{t('Active dates')}</span>
                  </div>
                  <div className='flex flex-col gap-7'>
                    <Calendar
                      value={fields.startDate}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          startDate: e,
                        })
                      }
                      label={t('Start Date')}
                      minDate={new Date()}
                      error={
                        formik.touched.startDate &&
                        Boolean(formik.errors.startDate)
                      }
                      helperText={
                        formik.touched.startDate && formik.errors.startDate
                      }
                    />

                    <Calendar
                      value={fields.endDate}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          endDate: e,
                        })
                      }
                      label={t('End Date')}
                      minDate={new Date()}
                      error={
                        formik.touched.endDate && Boolean(formik.errors.endDate)
                      }
                      helperText={
                        formik.touched.endDate && formik.errors.endDate
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageModal>
  );
};

export default AddDiscount;
