import React, { useState } from 'react';

const PermissionsFiled = ({ item }) => {
  const [show, setShow] = useState(6);
  return (
    <div className=' flex w-full flex-wrap gap-3 py-1.5'>
      {item?.permissions?.length
        ? item?.permissions?.slice(0, show).map((permission, index) => (
            <div className='border p-1' key={permission.id}>
              {permission?.name}
            </div>
          ))
        : ''}

      {item?.permissions?.length > show ? (
        <div
          className='cursor-pointer border p-1'
          onClick={() => setShow(show + 10)}
        >
          +{item?.permissions.length - show}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PermissionsFiled;
