import { constants } from '../constants';
import { client } from '../global/apolloClient';
import {
  CREATE_COLLECTION,
  DELETE_COLLECTION,
  EDIT_COLLECTION,
} from '../graphql/mutation/collection.mutation';
import { MARK_AS_READ } from '../graphql/mutation/conatct.mutation';
import {
  GET_ALL_COLLECTIONS,
  GET_COLLECTION,
} from '../graphql/query/collections.query';

import {
  GET_ALL_CONTACTS,
  GET_UNREAD_MESSAGES,
} from '../graphql/query/contacts.query';

import {
  createContactFail,
  createContactStart,
  createContactSuccess,
  deleteContactFail,
  deleteContactStart,
  deleteContactSuccess,
  deleteContactsFail,
  deleteContactsStart,
  deleteContactsSuccess,
  editContactFail,
  editContactStart,
  editContactSuccess,
  fetchContactsFail,
  fetchContactsStart,
  fetchContactsSuccess,
  importFail,
  importStart,
  importSuccess,
  markMessageUsRead,
  removeAll,
} from '../store/features/contact/contactSlicer';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation, resteCache } from './executeMutation';
import { uploadImage } from './upload';

const token = getTheTokenFromStorage();

export const createContact = async (values, accessToken) => {
  return await executeMutation(
    CREATE_COLLECTION,
    [GET_ALL_COLLECTIONS, GET_COLLECTION],
    values,
    accessToken
  );
};

export const markUsRead = async (values, accessToken) => {
  return await executeMutation(
    MARK_AS_READ,
    [GET_UNREAD_MESSAGES, GET_ALL_CONTACTS],
    values,
    accessToken
  );
};

export const editContact = async (values, accessToken) => {
  return await executeMutation(
    EDIT_COLLECTION,
    [GET_ALL_COLLECTIONS, GET_COLLECTION],
    values,
    accessToken
  );
};

export const deleteContact = async (values, accessToken) => {
  return await executeMutation(
    DELETE_COLLECTION,
    [GET_ALL_COLLECTIONS, GET_COLLECTION],
    values,
    accessToken
  );
};

export const deleteManyContact = async (values, accessToken) => {
  return await executeMutation(
    DELETE_COLLECTION,
    [GET_ALL_COLLECTIONS, GET_COLLECTION],
    values,
    accessToken
  );
};

export const fetchContacts = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchContactsStart());
      const { data } = await client.query({
        query: GET_ALL_CONTACTS,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchContactsSuccess(data));
    } catch (error) {
      dispatch(fetchContactsFail(error));
    }
  };
};

export const handleDeleteContact = (id) => {
  return async (dispatch) => {
    dispatch(deleteContactStart());
    const dataSent = {
      removeContactId: parseInt(id),
    };
    try {
      let res = await deleteContact(dataSent, token);
      if (res?.data?.removeContact) {
        dispatch(deleteContactSuccess(id));
        dispatch(showSuccessMessage('Contact deleted successfully'));
      } else {
        dispatch(deleteContactFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteContactFail(error));
    }
  };
};

export const handleMarkUsReadMessage = (id) => {
  return async (dispatch) => {
    dispatch(deleteContactStart());
    const dataSent = {
      markAsReadId: parseInt(id),
    };
    try {
      let res = await markUsRead(dataSent, token);
      dispatch(markMessageUsRead(id))
    } catch (error) {}
  };
};


export const handleDeleteManyContact = (ids) => {
  return async (dispatch) => {
    dispatch(deleteContactsStart());
    const dataSent = {
      removeContactId: ids.map((id) => parseInt(id)),
    };
    try {
      let res = await deleteManyContact(dataSent, token);
      if (res?.data?.removeContacts) {
        dispatch(deleteContactsSuccess(ids));
        dispatch(showSuccessMessage('Contacts deleted successfully'));
      } else {
        dispatch(deleteContactsFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteContactsFail(error));
    }
  };
};

export const handleCreateContact = (values, callback) => {
  return async (dispatch) => {
    dispatch(createContactStart());

    let imageSent = values.featuredAsset[0]
      ? await uploadImage(values.featuredAsset[0])
      : null;

    let translationDataSent = values.languages
      ? Object.keys(values.languages).map((key) => {
          return {
            name: values.languages[key].name,
            description: values.languages[key].description,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      input: {
        translationData: translationDataSent,
        media: imageSent?.data?.id ? +imageSent?.data?.id : null,
      },
    };

    try {
      const res = await createContact(dataSent, token);
      if (res?.data?.createContact) {
        dispatch(createContactSuccess(res?.data?.createContact));
        dispatch(
          showSuccessMessage('this Contact has been successfully created')
        );
      } else {
        dispatch(createContactFail(res));
        dispatch(showErrorMessage(res));
      }

      callback && callback(res);
    } catch (error) {
      dispatch(createContactFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleEditContact = (values, callback) => {
  return async (dispatch) => {
    dispatch(editContactStart());

    let imageSent = values.featuredAsset[0]
      ? values.featuredAsset[0].id
        ? +values.featuredAsset[0].id
        : await uploadImage(values.featuredAsset[0])
      : null;

    let translationDataSent = values.translationData
      ? Object.keys(values.translationData).map((key) => {
          return {
            name: values.translationData[key].name,
            description: values.translationData[key].description,
            languageCode: key,
          };
        })
      : [];

    const dataSent = {
      input: {
        id: +values.id,

        translationData: translationDataSent,
        media: imageSent?.data?.id
          ? +imageSent?.data?.id
          : imageSent
          ? imageSent
          : null,
      },
    };

    try {
      const res = await editContact(dataSent, token);
      if (res?.data?.updateContact) {
        dispatch(editContactSuccess(res?.data?.updateContact));
        dispatch(
          showSuccessMessage('this Contact has been successfully updated')
        );
      } else {
        dispatch(editContactFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(editContactFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleImportContacts = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyContact(dataSent, token);
      if (res?.data?.removeContacts) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Contacts Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteContactsFail(error));
      });
    }
  };
};

export const handleExportContacts = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyContact(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllContacts = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyContact(dataSent, token);
    } catch (error) {}
  };
};
