import { constants } from '../constants';
import { client } from '../global/apolloClient';
import {
  CREATE_DISCOUNT,
  DELETE_DISCOUNT,
  EDIT_DISCOUNT,
} from '../graphql/mutation/discount.mutation';
import {
  GET_ALL_DISCOUNTS,
  GET_DISCOUNT,
} from '../graphql/query/discount.query';
import {
  createDiscountFail,
  createDiscountStart,
  createDiscountSuccess,
  deleteDiscountFail,
  deleteDiscountStart,
  deleteDiscountSuccess,
  deleteDiscountsFail,
  deleteDiscountsStart,
  deleteDiscountsSuccess,
  editDiscountFail,
  editDiscountStart,
  editDiscountSuccess,
  fetchDiscountsFail,
  fetchDiscountsStart,
  fetchDiscountsSuccess,
  importFail,
  importStart,
  importSuccess,
  removeAll,
} from '../store/features/discount/discountSlicer';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import { delay } from '../utils/helpers';
import { getTheTokenFromStorage } from './auth';
import { executeMutation } from './executeMutation';
import { uploadImage } from './upload';

const token = getTheTokenFromStorage();

export const createDiscount = async (values, accessToken) => {
  return await executeMutation(
    CREATE_DISCOUNT,
    [GET_ALL_DISCOUNTS, GET_DISCOUNT],
    values,
    accessToken
  );
};

export const editDiscount = async (values, accessToken) => {
  return await executeMutation(
    EDIT_DISCOUNT,
    [GET_ALL_DISCOUNTS, GET_DISCOUNT],
    values,
    accessToken
  );
};

export const deleteDiscount = async (values, accessToken) => {
  return await executeMutation(
    DELETE_DISCOUNT,
    [GET_ALL_DISCOUNTS, GET_DISCOUNT],
    values,
    accessToken
  );
};

export const deleteManyDiscount = async (values, accessToken) => {
  return await executeMutation(
    DELETE_DISCOUNT,
    [GET_ALL_DISCOUNTS, GET_DISCOUNT],
    values,
    accessToken
  );
};

export const fetchDiscounts = (variables) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDiscountsStart());
      const { data } = await client.query({
        query: GET_ALL_DISCOUNTS,
        variables,
        fetchPolicy: constants?.FETCH_POLICY,
      });
      dispatch(fetchDiscountsSuccess(data));
    } catch (error) {
      dispatch(fetchDiscountsFail(error));
    }
  };
};

export const handleDeleteDiscount = (id) => {
  return async (dispatch) => {
    dispatch(deleteDiscountStart());
    const dataSent = {
      removeDiscountId: parseInt(id),
    };
    try {
      let res = await deleteDiscount(dataSent, token);
      if (res?.data?.removeDiscount) {
        dispatch(deleteDiscountSuccess(id));
        dispatch(showSuccessMessage('Discount deleted successfully'));
      } else {
        dispatch(deleteDiscountFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteDiscountFail(error));
    }
  };
};

export const handleDeleteManyDiscount = (ids) => {
  return async (dispatch) => {
    dispatch(deleteDiscountsStart());
    const dataSent = {
      removeDiscountId: ids.map((id) => parseInt(id)),
    };
    try {
      let res = await deleteManyDiscount(dataSent, token);
      if (res?.data?.removeDiscounts) {
        dispatch(deleteDiscountsSuccess(ids));
        dispatch(showSuccessMessage('Discounts deleted successfully'));
      } else {
        dispatch(deleteDiscountsFail(res));
        dispatch(showErrorMessage(res));
      }
    } catch (error) {
      dispatch(showErrorMessage(error));
      dispatch(deleteDiscountsFail(error));
    }
  };
};

export const handleCreateDiscount = (values, callback) => {
  return async (dispatch) => {
    dispatch(createDiscountStart());

    let dataSent = {
      createDiscount: {
        couponCode:
          values.discountMethod == 'discount code' ? values?.discountCode : '',
        name:
          values.discountMethod != 'discount code'
            ? values.automaticDiscount
            : '',
        max_usage: values.numberUses ? +values.numberUses : -1,
        perCustomerUsageLimit: values.limitOneUse ? 1 : 0,
        percentage:
          values?.percentage && values.valueMethod == 'percentage'
            ? +values?.percentage
            : 0,
        fixedAmount:
          values?.fixedAmount && values.valueMethod == 'fixedAmount'
            ? +values?.fixedAmount
            : 0,
        startsAt: values.startDate,
        endsAt: values.endDate,
        status: values.status ? 'ACTIVE' : 'INACTIVE',
        minimumPurchaseAmount: values.minimumPurchaseAmount,
      },
    };

    try {
      let res = await createDiscount(dataSent, token);
      if (res?.data?.createDiscount) {
        dispatch(createDiscountSuccess(res?.data?.createDiscount));
        dispatch(
          showSuccessMessage('this Discount has been successfully created')
        );
      } else {
        dispatch(createDiscountFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(createDiscountFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};


export const handleEditDiscount = (values, callback) => {
  return async (dispatch) => {
    dispatch(editDiscountStart());

    let dataSent = {
      updateDiscount: {
        id: values.id,
        couponCode:
          values.discountMethod == 'discount code' ? values?.discountCode : '',
        name:
          values.discountMethod != 'discount code'
            ? values.automaticDiscount
            : '',
        max_usage: values.numberUses ? +values.numberUses : -1,
        perCustomerUsageLimit: values.limitOneUse ? 1 : 0,
        percentage:
          values?.percentage && values.valueMethod == 'percentage'
            ? +values?.percentage
            : 0,
        fixedAmount:
          values?.fixedAmount && values.valueMethod == 'fixedAmount'
            ? +values?.fixedAmount
            : 0,
        startsAt: values.startDate,
        endsAt: values.endDate,
        status: values.status ? 'ACTIVE' : 'INACTIVE',
        minimumPurchaseAmount: values.minimumPurchaseAmount
          ? values.minimumPurchaseAmount
          : 0,
      },
    };

    try {
      let res = await editDiscount(dataSent, token);
      if (res?.data?.updateDiscount) {
        dispatch(editDiscountSuccess(res?.data?.updateDiscount));
        dispatch(
          showSuccessMessage('this Discount has been successfully updated')
        );
      } else {
        dispatch(editDiscountFail(res));
        dispatch(showErrorMessage(res));
      }
      callback && callback(res);
    } catch (error) {
      dispatch(editDiscountFail(error));
      dispatch(showErrorMessage(error));
      callback && callback(error);
    }
  };
};

export const handleImportDiscounts = (data) => {
  console.log('data send service ==>', data);
  return async (dispatch) => {
    dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyDiscount(dataSent, token);
      if (res?.data?.removeDiscounts) {
        dispatch(importSuccess());
        dispatch(showSuccessMessage('Discounts Import Seuccefully'));
      } else {
        delay(1000).then(async () => {
          dispatch(importFail(res));
          dispatch(showErrorMessage(res));
        });
      }
    } catch (error) {
      delay(1000).then(async () => {
        dispatch(importFail(error));
        dispatch(deleteDiscountsFail(error));
      });
    }
  };
};

export const handleExportDiscounts = (ids) => {
  console.log('handleExportFiles :', ids);
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyDiscount(dataSent, token);
      // dispatch(removeAll())
    } catch (error) {
      // dispatch(removeAll())
    }
  };
};

export const handleExportAllDiscounts = () => {
  console.log('handleExportAllFiles =>');
  return async (dispatch) => {
    // dispatch(importStart());
    const dataSent = {
      file: 'File',
    };
    try {
      let res = await deleteManyDiscount(dataSent, token);
    } catch (error) {}
  };
};
