import React from "react";
import { Skeleton } from "@mui/material";
import { useStateContext } from "../contexts/ContextProvider";

const SkeletonTable = () => {
  return (
    <div className="flex flex-col gap-2 rounded-md overflow-hidden pt-6">
      <Skeleton
        sx={{ bgcolor: "grey.200" }}
        variant="rectangular"
        style={{
          width: "100%",
          height: "40px",
        }}
      />

      <Skeleton
        sx={{ bgcolor: "grey.100" }}
        variant="rectangular"
        style={{
          width: "100%",
          height: "32px",
        }}
      />

      <Skeleton
        sx={{ bgcolor: "grey.100" }}
        variant="rectangular"
        style={{
          width: "100%",
          height: "32px",
        }}
      />

      <Skeleton
        sx={{ bgcolor: "grey.100" }}
        variant="rectangular"
        style={{
          width: "100%",
          height: "32px",
        }}
      />

      <Skeleton
        sx={{ bgcolor: "grey.100" }}
        variant="rectangular"
        style={{
          width: "100%",
          height: "32px",
        }}
      />

      <Skeleton
        sx={{ bgcolor: "grey.100" }}
        variant="rectangular"
        style={{
          width: "100%",
          height: "32px",
        }}
      />

      <Skeleton
        sx={{ bgcolor: "grey.100" }}
        variant="rectangular"
        style={{
          width: "100%",
          height: "32px",
        }}
      />

      <Skeleton
        sx={{ bgcolor: "grey.100" }}
        variant="rectangular"
        style={{
          width: "100%",
          height: "32px",
        }}
      />

      <Skeleton
        sx={{ bgcolor: "grey.100" }}
        variant="rectangular"
        style={{
          width: "100%",
          height: "32px",
        }}
      />

      <Skeleton
        sx={{ bgcolor: "grey.100" }}
        variant="rectangular"
        style={{
          width: "100%",
          height: "32px",
        }}
      />
    </div>
  );
};

export default SkeletonTable;
