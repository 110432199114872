import { gql } from '@apollo/client';

export const GET_ALL_PAGES = gql`
query Pages($offset: Int!, $limit: Int!, $name: String, $categories: [Int!], $type: pageType) {
  pages(offset: $offset, limit: $limit, name: $name, categories: $categories, type: $type) {
    nodes {
      id
      name
      media {
        id
        original_name
        full_url
        file_name
        __typename
        alt
        host
        path
      }
      categories {
        id
        name
        description
        type
      }
      htmlBody
      description
      type
    }
    hasNextPage
    totalCount
  }
}
`;

export const GET_PAGE = gql`
query Page($pageId: Int!) {
  page(id: $pageId) {
    categories {
      id
      name
      type
      description
    }
    description
    htmlBody
    id
    name
    type
    media {
      id
      original_name
      full_url
      file_name
      __typename
      alt
      host
      path
    }
  }
}
`;
