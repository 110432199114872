import { useStateContext } from "../../../../contexts/ContextProvider";
import { useTranslation } from "react-i18next";
import RatioField from './RatioField';

const CustomerInformation = ({ fields, setFields }) => {
    const { t } = useTranslation();
    const { bodyStyles } = useStateContext();
  
    return (
      <div className="w-full flex flex-col items-start justify-start ">
        <h1
          style={{
            color: bodyStyles?.title || "#808080",
          }}
          className="text-[#808080] text-[19px] font-[600] uppercase"
        >
          {t("Customer information")}
        </h1>
  
        <div className="flex flex-col items-center justify-start w-full py-6 gap-y-4">
          <RatioField
            checked={fields.requiredLastName}
            onChange={() => {
              setFields((prevState) => ({
                ...prevState,
                requiredLastName: true,
                requiredFullName: false,
              }));
            }}
            value={fields.requiredLastName}
            name="radio-buttons-1"
            title={t("Only require last name")}
          />
          <RatioField
            checked={fields.requiredFullName}
            onChange={() => {
              setFields((prevState) => ({
                ...prevState,
                requiredLastName: false,
                requiredFullName: true,
              }));
            }}
            value={fields.requiredFullName}
            name="radio-buttons-2"
            title={t("Require first and last name")}
          />
        </div>
        <div className="flex flex-col items-center justify-start w-full py-6 gap-y-4">
          <h1
            style={{
              color: bodyStyles?.subTitle || "#888888",
            }}
            className="w-full text-[#888888] text-[18px] font-[400]"
          >
            {t("Company name")}
          </h1>
  
          <RatioField
            checked={fields.includeCompanyName}
            onChange={() => {
              setFields((prevState) => ({
                ...prevState,
                includeCompanyName: true,
                includeCompanyNameOptionel: false,
              }));
            }}
            value={fields.includeCompanyName}
            name="radio-buttons-3"
            title={t("Don't include")}
          />
          <RatioField
            checked={fields.includeCompanyNameOptionel}
            onChange={() => {
              setFields((prevState) => ({
                ...prevState,
                includeCompanyName: false,
                includeCompanyNameOptionel: true,
              }));
            }}
            value={fields.includeCompanyNameOptionel}
            name="radio-buttons-4"
            title={t("Optional")}
          />
        </div>
        <div className="flex flex-col items-center justify-start w-full py-6 gap-y-4">
          <h1
            style={{
              color: bodyStyles?.subTitle || "#888888",
            }}
            className="w-full text-[#888888] text-[18px] font-[400]"
          >
            {t("Address line 2 (apartment, unit, etc.)")}
          </h1>
  
          <RatioField
            checked={fields.includeAddress02}
            onChange={() => {
              setFields((prevState) => ({
                ...prevState,
                includeAddress02: true,
                includeAddress02Optionel: false,
              }));
            }}
            value={fields.includeAddress02}
            name="radio-buttons-3"
            title={t("Don't include")}
          />
          <RatioField
            checked={fields.includeAddress02Optionel}
            onChange={() => {
              setFields((prevState) => ({
                ...prevState,
                includeAddress02: false,
                includeAddress02Optionel: true,
              }));
            }}
            value={fields.includeAddress02Optionel}
            name="radio-buttons-4"
            title={t("Optional")}
          />
        </div>
        <div className="flex flex-col items-center justify-start w-full py-6 gap-y-4">
          <h1
            style={{
              color: bodyStyles?.subTitle || "#888888",
            }}
            className="w-full text-[#888888] text-[18px] font-[400]"
          >
            {t("Shipping address")}
          </h1>
  
          <RatioField
            checked={fields.includeShippingAddress}
            onChange={() => {
              setFields((prevState) => ({
                ...prevState,
                includeShippingAddress: true,
                includeShippingAddressOptionel: false,
              }));
            }}
            value={fields.includeShippingAddress}
            name="radio-buttons-3"
            title={t("Don't include")}
          />
          <RatioField
            checked={fields.includeShippingAddressOptionel}
            onChange={() => {
              setFields((prevState) => ({
                ...prevState,
                includeShippingAddress: false,
                includeShippingAddressOptionel: true,
              }));
            }}
            value={fields.includeShippingAddressOptionel}
            name="radio-buttons-4"
            title={t("Optional")}
          />
        </div>
      </div>
    );
  };

export default CustomerInformation