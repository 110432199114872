import Checkbox from "@mui/material/Checkbox";
import { useStateContext } from "../../contexts/ContextProvider";

export default function CheckBox({
  checked,
  onClick,
  size,
  color,
  label,
  disabled,
}) {
  const { bodyStyles } = useStateContext();
  return (
    <div
      className={`flex gap-3 items-center ${!disabled && "cursor-pointer"}`}
      onClick={onClick}
    >
      <Checkbox
        defaultChecked
        style={{
          padding: 0,
          margin: 0,
        }}
        sx={{
          color: color ? color : bodyStyles?.primary,
          "&.Mui-checked": {
            color: color ? color : bodyStyles?.primary,
          },

          "& .MuiSvgIcon-root": { fontSize: size ? size : 28 },
        }}
        checked={checked ? true : false}
        disabled={disabled}
      />

      <span className="text-md gap-2 font-[500] ">{label}</span>
    </div>
  );
}
