import CheckoutStatus from '../components/DataGrid/RowActions/CheckoutStatus';
import ImageWithTitle from '../components/DataGrid/RowActions/ImageWithTitle';
import InstanceNavigate from '../components/DataGrid/RowActions/InstanceNavigate';
import OrderStatus from '../components/DataGrid/RowActions/OrderStatus';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  fetchCheckouts,
  handleDeleteCheckout,
  handleDeleteManyCheckout,
  handleExportAllCheckouts,
  handleExportCheckout,
  handleImportCheckout,
} from '../services/checkout';
import {
  fetchMenus,
  handleDeleteManyMenu,
  handleDeleteMenu,
  handleExportAllFiles,
  handleExportFiles,
  handleImportFiles,
} from '../services/menu';
import {
  fetchOrders,
  handleDeleteManyOrder,
  handleDeleteOrder,
  handleExportAllOrders,
  handleExportOrder,
  handleImportOrder,
} from '../services/order';
import {
  clearMessages as clearMessagesMenu,
  openModelDelete,
  openModelDeleteAll,
  openModeleImport,
  updateSelected,
  selectAll,
} from '../store/features/menu/menuSlicer';

import {
  clearMessages as clearMessagesOrder,
  openModelDelete as openModelDeleteOrder,
  openModelDeleteAll as openModelDeleteAllOrder,
  openModeleImport as openModeleImportOrder,
  updateSelected as updateSelectedOrder,
  selectAll as selectAllOrder,
} from '../store/features/order/orderSlicer';

import {
  clearMessages as clearMessagesCheckout,
  openModelDelete as openModelDeleteCheckout,
  openModelDeleteAll as openModelDeleteAllCheckout,
  openModeleImport as openModeleImportCheckout,
  updateSelected as updateSelectedCheckout,
  selectAll as selectAllCheckout,
} from '../store/features/checkout/checkoutSlicer';

import {
  clearMessages as clearMessagesProduct,
  openModelDelete as openModelDeleteProduct,
  openModelDeleteAll as openModelDeleteAllProduct,
  openModeleImport as openModeleImportProduct,
  updateSelected as updateSelectedProduct,
  selectAll as selectAllProduct,
} from '../store/features/product/productSlicer';

import {
  clearMessages as clearMessagesService,
  openModelDelete as openModelDeleteService,
  openModelDeleteAll as openModelDeleteAllService,
  openModeleImport as openModeleImportService,
  updateSelected as updateSelectedService,
  selectAll as selectAllService,
} from '../store/features/service/serviceSlicer';

import {
  clearMessages as clearMessagesPlanning,
  openModelDelete as openModelDeletePlanning,
  openModelDeleteAll as openModelDeleteAllPlanning,
  openModeleImport as openModeleImportPlanning,
  updateSelected as updateSelectedPlanning,
  selectAll as selectAllPlanning,
} from '../store/features/planning/planningSlicer';

import {
  clearMessages as clearMessagesReference,
  openModelDelete as openModelDeleteReference,
  openModelDeleteAll as openModelDeleteAllReference,
  openModeleImport as openModeleImportReference,
  updateSelected as updateSelectedReference,
  selectAll as selectAllReference,
} from '../store/features/reference/referenceSlicer';

import {
  clearMessages as clearMessagesCategory,
  openModelDelete as openModelDeleteCategory,
  openModelDeleteAll as openModelDeleteAllCategory,
  openModeleImport as openModeleImportCategory,
  updateSelected as updateSelectedCategory,
  selectAll as selectAllCategory,
} from '../store/features/category/categorySlicer';

import {
  clearMessages as clearMessagesContact,
  openModelDelete as openModelDeleteContact,
  openModelCreate as openModelCreateContact,
  openModelEdit as openModelEditContact,
  openModelDeleteAll as openModelDeleteAllContact,
  openModeleImport as openModeleImportContact,
  updateSelected as updateSelectedContact,
  selectAll as selectAllContact,
} from '../store/features/contact/contactSlicer';

import {
  clearMessages as clearMessagesVariant,
  openModelDelete as openModelDeleteVariant,
  openModelDeleteAll as openModelDeleteAllVariant,
  openModeleImport as openModeleImportVariant,
  updateSelected as updateSelectedVariant,
  selectAll as selectAllVariant,
} from '../store/features/variant/variantSlicer';

import {
  clearMessages as clearMessagesCollection,
  openModelDelete as openModelDeleteCollection,
  openModelDeleteAll as openModelDeleteAllCollection,
  openModeleImport as openModeleImportCollection,
  updateSelected as updateSelectedCollection,
  selectAll as selectAllCollection,
} from '../store/features/collection/collectionSlicer';

import {
  clearMessages as clearMessagesBrand,
  openModelDelete as openModelDeleteBrand,
  openModelDeleteAll as openModelDeleteAllBrand,
  openModeleImport as openModeleImportBrand,
  updateSelected as updateSelectedBrand,
  selectAll as selectAllBrand,
} from '../store/features/brand/brandSlicer';

import {
  clearMessages as clearMessagesTag,
  openModelDelete as openModelDeleteTag,
  openModelCreate as openModelCreateTag,
  openModelEdit as openModelEditTag,
  openModelDeleteAll as openModelDeleteAllTag,
  openModeleImport as openModeleImportTag,
  updateSelected as updateSelectedTag,
  selectAll as selectAllTag,
} from '../store/features/tag/tagSlicer';

import {
  clearMessages as clearMessagesDiscount,
  openModelDelete as openModelDeleteDiscount,
  openModelDeleteAll as openModelDeleteAllDiscount,
  openModeleImport as openModeleImportDiscount,
  updateSelected as updateSelectedDiscount,
  selectAll as selectAllDiscount,
} from '../store/features/discount/discountSlicer';

import {
  clearMessages as clearMessagesCustomer,
  openModelDelete as openModelDeleteCustomer,
  openModelDeleteAll as openModelDeleteAllCustomer,
  openModeleImport as openModeleImportCustomer,
  updateSelected as updateSelectedCustomer,
  selectAll as selectAllCustomer,
} from '../store/features/customer/customerSlicer';

import {
  clearMessages as clearMessagesUser,
  openModelDelete as openModelDeleteUser,
  openModelCreate as openModelCreateUser,
  openModelEdit as openModelEditUser,
  openModelDeleteAll as openModelDeleteAllUser,
  openModeleImport as openModeleImportUser,
  updateSelected as updateSelectedUser,
  selectAll as selectAllUser,
} from '../store/features/user/userSlicer';

import {
  clearMessages as clearMessagesRole,
  openModelDelete as openModelDeleteRole,
  openModelCreate as openModelCreateRole,
  openModelEdit as openModelEditRole,
  openModelDeleteAll as openModelDeleteAllRole,
  openModeleImport as openModeleImportRole,
  updateSelected as updateSelectedRole,
  selectAll as selectAllRole,
} from '../store/features/rolle/rolleSlicer';

import {
  clearMessages as clearMessagesShippingMethode,
  openModelDelete as openModelDeleteShippingMethode,
  openModelCreate as openModelCreateShippingMethode,
  openModelEdit as openModelEditShippingMethode,
  openModelDeleteAll as openModelDeleteAllShippingMethode,
  openModeleImport as openModeleImportShippingMethode,
  updateSelected as updateSelectedShippingMethode,
  selectAll as selectAllShippingMethode,
} from '../store/features/shippingMethode/shippingMethodeSlicer';

import {
  clearMessages as clearMessagesZone,
  openModelDelete as openModelDeleteZone,
  openModelCreate as openModelCreateZone,
  openModelEdit as openModelEditZone,
  openModelDeleteAll as openModelDeleteAllZone,
  openModeleImport as openModeleImportZone,
  updateSelected as updateSelectedZone,
  selectAll as selectAllZone,
} from '../store/features/zone/zoneSlicer';

import { getCurrencyCharacter } from '../utils/currency';
import { defaultDatat } from '../utils/helpers';
import {
  menuPermissions,
  orderPermissions,
  checkoutPermissions,
  productPermissions,
  inventoryPermissions,
  collectionPermissions,
  brandPermissions,
  tagPermissions,
  discountPermissions,
  customerPermissions,
  accountSettings,
  roleSetting,
  shippingMethodSettings,
  zoneSetting,
} from '../utils/permissions';
import i18n from 'i18next';
import ProductStatus from '../components/DataGrid/RowActions/ProductStatus';
import ProductData from '../components/DataGrid/RowActions/ProductData';
import {
  fetchProducts,
  handleDeleteManyProduct,
  handleDeleteProduct,
  handleExportAllProducts,
  handleExportProduct,
  handleImportProduct,
} from '../services/product';
import { CircularProgress, Tooltip } from '@mui/material';
import BasicInput from '../components/Inputs/BasicInput';
import VariantData from '../components/DataGrid/RowActions/VariantData';
import {
  fetchVariants,
  handleDeleteManyVariant,
  handleDeleteVariant,
  handleEditManyVariantsStock,
  handleExportAllVariants,
  handleExportVariant,
  handleImportVariant,
} from '../services/variant';
import {
  fetchCollections,
  handleDeleteCollection,
  handleDeleteManyCollection,
  handleExportAllCollections,
  handleExportCollections,
  handleImportCollections,
} from '../services/collection';
import {
  fetchBrands,
  handleDeleteBrand,
  handleDeleteManyBrand,
  handleExportAllBrands,
  handleExportBrands,
  handleImportBrands,
} from '../services/brand';
import {
  fetchTags,
  handleDeleteManyTag,
  handleDeleteTag,
  handleExportAllTags,
  handleExportTags,
  handleImportTags,
} from '../services/tag';
import InstanceEditDelte from '../components/DataGrid/RowActions/InstanceEditDelte';
import EditModalTag from '../components/Tags/EditModalTag';
import CreateModalTag from '../components/Tags/CreateModalTag';
import DiscountStatus from '../components/DataGrid/RowActions/DiscountStatus';
import {
  fetchDiscounts,
  handleDeleteDiscount,
  handleDeleteManyDiscount,
  handleExportAllDiscounts,
  handleExportDiscounts,
  handleImportDiscounts,
} from '../services/discount';
import {
  fetchCustomers,
  handleDeleteCustomer,
  handleDeleteManyCustomer,
  handleExportAllCustomers,
  handleExportCustomers,
  handleImportCustomers,
} from '../services/customer';
import {
  fetchUsers,
  handleDeleteManyUser,
  handleDeleteUser,
  handleExportAllUsers,
  handleExportUsers,
  handleImportUsers,
} from '../services/user';
import RolesFiled from '../components/DataGrid/RowActions/RolesFiled';
import AddStaffModal from '../components/Setting/Tabs/Account/Staff/AddStaffModal';
import EditStaffModal from '../components/Setting/Tabs/Account/Staff/EditStaffModal';
import PermissionsFiled from '../components/DataGrid/RowActions/PermissionsFiled';
import {
  fetchRolles,
  handleDeleteManyRole,
  handleDeleteRole,
  handleExportAllRoles,
  handleExportRoles,
  handleImportRoles,
} from '../services/role';
import AddRoleModal from '../components/Setting/Tabs/Roles/AddRoleModal';
import EditRoleModal from '../components/Setting/Tabs/Roles/EditRoleModal';
import {
  fetchShippingMethodes,
  handleDeleteManyShippingMethode,
  handleDeleteShippingMethode,
  handleExportAllShippingMethodes,
  handleExportShippingMethodes,
  handleImportShippingMethodes,
} from '../services/shippingMethod';
import AddShippingAdressModal from '../components/Setting/Tabs/ShippingMethod/AddShippingAdressModal';
import EditShippingAdressModal from '../components/Setting/Tabs/ShippingMethod/EditShippingAdressModal';
import {
  fetchZones,
  handleDeleteManyZone,
  handleDeleteZone,
  handleExportAllZones,
  handleExportZones,
  handleImportZones,
} from '../services/zone';
import AddZoneModal from '../components/Setting/Tabs/Zones/AddZoneModal';
import EditZoneModal from '../components/Setting/Tabs/Zones/EditZoneModal';
import { generalData } from '../utils/general';
import {
  fetchCategories,
  handleDeleteCategory,
  handleDeleteManyCategory,
  handleExportAllCategories,
  handleExportCategory,
  handleImportCategory,
} from '../services/category';
import { fetchPages } from '../services/page';
import {
  fetchServices,
  handleDeleteManyService,
  handleDeleteService,
  handleExportAllServices,
  handleExportServices,
  handleImportServices,
} from '../services/service';
import {
  fetchPlannings,
  handleDeleteManyPlanning,
  handleDeletePlanning,
  handleExportAllPlannings,
  handleExportPlannings,
  handleImportPlannings,
} from '../services/planning';
import {
  fetchReferences,
  handleDeleteManyReference,
  handleDeleteReference,
  handleExportAllReferences,
  handleExportReferences,
  handleImportReferences,
} from '../services/reference';
import {
  fetchContacts,
  handleDeleteContact,
  handleDeleteManyContact,
  handleExportAllContacts,
  handleExportContacts,
  handleImportContacts,
} from '../services/contact';
import EditContact from '../components/Contacts/EditContact';
import InstanceEdit from '../components/DataGrid/RowActions/InstanceEdit';

export const pagesData = {
  menu: {
    main: {
      display: generalData?.pages?.menu,
      path: '/menus',
      pageName: 'Menus',
      addSectionLabel: 'Add Menu',
      addSectionUrl: '/menus/add',
      addInstanceAction: '/menus/add',
      addSection: true,
      filterSection: true,
      searchButton: true,
      filterButton: true,
      permissions: [menuPermissions['menu:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Menu Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Menu Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [{ value: 'all', label: 'all' }],
      rows: (data) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: i18n.t('ID'),
          key: 'id',
          width: '10%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2 text-xs font-semibold'>
                {item.id}
              </h2>
            );
          },
        },
        {
          title: i18n.t('Title'),
          key: 'Title',
          width: '25%',
          elemnt: function (item) {
            return <ImageWithTitle item={item} />;
          },
        },
        {
          title: i18n.t('Description'),
          key: 'description',
          width: '30%',

          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {defaultDatat(
                  item?.translationData,
                  i18n.language,
                  'description'
                )}
              </h2>
            );
          },
        },

        {
          title: i18n.t('Parent'),
          key: 'parent',
          width: '25%',

          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {defaultDatat(
                  item?.parent?.translationData,
                  i18n.language,
                  'description'
                )}
              </h2>
            );
          },
        },
        {
          title: '',
          key: 'action',
          width: '30px',
          elemnt: function (item) {
            return (
              <div className='flex gap-2 justify-end'>
                <InstanceNavigate link={`/menus/edit/${item?.id}`} />
              </div>
            );
          },
        },
      ],
      fetch: fetchMenus,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,
          where: {
            languageCode: null,
            search: search?.trim()?.replace(/\W{2,}/, ' '),
          },
          join: {
            children: true,
            collections: true,
            parent: true,
            translationData: true,
          },
          sortBy: order,
          // sortBy: 11,
        };
      },
      stateName: 'menu',
      clearMessages: clearMessagesMenu,
      selectAll: selectAll,
      updateSelected: updateSelected,
      openModelDelete: openModelDelete,
      openModelDeleteAll: openModelDeleteAll,
      handleDelete: handleDeleteMenu,
      handleDeleteMany: handleDeleteManyMenu,
      handleExportFiles: handleExportFiles,
      openModeleImport: openModeleImport,
      handleExportAllFiles: handleExportAllFiles,
      handleImportFiles: handleImportFiles,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Menu',
      deleteInstanceActionTitle: 'Delete Menu',
      deleteInstanceDescription:
        'Are you sure you want to delete this Menu with id',
      deleteAllInstanceTitle: 'Delete Menus',
      deleteAllInstanceActionTitle: 'Delete Menus',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Menus with IDS :',
      emptyDataTitle: 'No Menus Found!',
      emptyDataDescription:
        "It appears that no menus were. Please consider creating menus to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Menu',
      importInstanceMessage: 'Import Menus',
      importInstanceModelTitle: 'Import Menus by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      exportData: false,
      importData: false,
      selectMany: false,
      deleteMany: true,
      exportMany: true,
    },
  },
  order: {
    main: {
      display: generalData?.pages?.order,
      path: '/orders',
      pageName: 'Orders',
      addSectionLabel: 'Add Order',
      addSectionUrl: '/orders/add',
      addInstanceAction: '/orders/add',
      addSection: true,
      filterSection: true,
      searchButton: true,
      filterButton: true,
      permissions: [orderPermissions['order:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Order Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Order Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (newest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (oldest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [
        { value: 'ALL', label: 'All' },
        { value: 'COMPLETED', label: 'Completed' },
        { value: 'CANCELED', label: 'Canceled' },
        { value: 'REQUIRES_ACTION', label: 'Requires Action' },
      ],
      rows: (data) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        { title: i18n.t('Order'), key: 'id', width: '15%' },
        { title: i18n.t('Date'), key: 'createdAt', width: '20%', type: 'date' },
        {
          title: i18n.t('Customer'),
          key: 'userName',
          width: '20%',
          elemnt: function (item) {
            return (
              <div className='flex items-start justify-start '>
                {item.customer
                  ? `${item?.customer?.firstName} ${item?.customer?.lastName}`
                  : '-'}
              </div>
            );
          },
        },
        {
          title: i18n.t('Status'),
          key: 'status',
          width: '20%',
          elemnt: function (item) {
            return <OrderStatus item={item} />;
          },
        },
        {
          title: i18n.t('Total'),
          key: 'Total',
          width: '15%',
          elemnt: function (item) {
            return (
              <div className='flex items-start justify-start '>
                {item?.subTotal?.toFixed(2)}
                {item.subTotal ? (
                  <span className='px-1 font-bold'>
                    {item.currencyCode
                      ? getCurrencyCharacter(item.currencyCode)
                      : ''}
                  </span>
                ) : (
                  ''
                )}
              </div>
            );
          },
        },

        {
          title: '',
          key: 'action',
          width: '30px',
          elemnt: function (item) {
            return (
              <div className='flex gap-2 justify-end'>
                <InstanceNavigate link={`/orders/order/${item?.id}`} />
              </div>
            );
          },
        },
      ],
      fetch: fetchOrders,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,
          where: {
            search: search?.trim()?.replace(/\W{2,}/, ' '),
            status:
              status === 'PENDING' || status === 'ALL' || !status
                ? []
                : [status],
          },
          sortBy: order,
        };
      },
      stateName: 'order',
      clearMessages: clearMessagesOrder,
      selectAll: selectAllOrder,
      updateSelected: updateSelectedOrder,
      openModelDelete: openModelDeleteOrder,
      openModelDeleteAll: openModelDeleteAllOrder,

      handleDelete: handleDeleteOrder,
      handleDeleteMany: handleDeleteManyOrder,
      handleExportFiles: handleExportOrder,
      handleExportAllFiles: handleExportAllOrders,
      openModeleImport: openModeleImportOrder,
      handleImportFiles: handleImportOrder,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Order',
      deleteInstanceActionTitle: 'Delete Order',
      deleteInstanceDescription:
        'Are you sure you want to delete this Order with id',
      deleteAllInstanceTitle: 'Delete Orders',
      deleteAllInstanceActionTitle: 'Delete Orders',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Orders with IDS :',
      emptyDataTitle: 'No Orders Found!',
      emptyDataDescription:
        "It appears that no menus were. Please consider creating menus to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Orders',
      importInstanceMessage: 'Import Orders',
      importInstanceModelTitle: 'Import Orders by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      exportData: false,
      importData: false,
      selectMany: false,
      deleteMany: false,
      exportMany: true,
    },
  },

  'abandoned-checkout': {
    main: {
      display: generalData?.pages?.abandonedCheckout,
      path: '/abandoned-checkout',
      pageName: 'Abandoned Checkout',
      addSectionLabel: 'Abandoned Checkout',
      addSectionUrl: '/abandoned-checkout/add',
      addInstanceAction: '/abandoned-checkout/add',
      addSection: false,
      filterSection: true,
      searchButton: true,
      filterButton: true,
      permissions: [checkoutPermissions['checkout:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'abandoned-checkout Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'abandoned-checkout Id Z–A',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [],
      rows: (data) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        { title: i18n.t('Checkout'), key: 'id', width: '15%' },
        { title: i18n.t('Date'), key: 'createdAt', width: '20%', type: 'date' },
        {
          title: i18n.t('Customer'),
          key: 'email',
          width: '20%',
          elemnt: function (item) {
            return (
              <div className='flex items-start justify-start break-all'>
                {item.email ? item.email : '-'}
              </div>
            );
          },
        },
        {
          title: i18n.t('Status'),
          key: 'status',
          width: '20%',
          elemnt: function (item) {
            return <CheckoutStatus item={item} />;
          },
        },
        {
          title: i18n.t('Total'),
          key: 'Total',
          width: '15%',
          elemnt: function (item) {
            return (
              <div className='flex items-start justify-start '>
                {item.total}
                {item.total && item?.order?.currencyCode ? (
                  <span className='px-1 font-bold'>
                    {getCurrencyCharacter(item?.order?.currencyCode)}
                  </span>
                ) : (
                  ''
                )}
              </div>
            );
          },
        },

        {
          title: '',
          key: 'action',
          width: '30px',
          elemnt: function (item) {
            return (
              <div className='flex gap-2 justify-end'>
                <InstanceNavigate link={`/abandoned-checkouts/${item?.id}`} />
              </div>
            );
          },
        },
      ],
      fetch: fetchCheckouts,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,
          where: {
            paymentStatus: 'PENDING',
          },
          sortBy: order,
        };
      },
      stateName: 'checkout',
      clearMessages: clearMessagesCheckout,
      selectAll: selectAllCheckout,
      updateSelected: updateSelectedCheckout,
      openModelDelete: openModelDeleteCheckout,
      openModelDeleteAll: openModelDeleteAllCheckout,

      handleDelete: handleDeleteCheckout,
      handleDeleteMany: handleDeleteManyCheckout,
      handleExportFiles: handleExportCheckout,
      handleExportAllFiles: handleExportAllCheckouts,
      openModeleImport: openModeleImportCheckout,
      handleImportFiles: handleImportCheckout,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Checkout',
      deleteInstanceActionTitle: 'Delete Checkout',
      deleteInstanceDescription:
        'Are you sure you want to delete this Checkout with id',
      deleteAllInstanceTitle: 'Delete Checkouts',
      deleteAllInstanceActionTitle: 'Delete Checkouts',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Checkouts with IDS :',
      emptyDataTitle: 'No Checkouts Found!',
      emptyDataDescription:
        "It appears that no Checkouts were. Please consider creating menus to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Checkouts',
      importInstanceMessage: 'Import Checkouts',
      importInstanceModelTitle: 'Import Checkouts by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      exportData: false,
      importData: false,
      selectMany: false,
      deleteMany: true,
      exportMany: true,
    },
  },

  product: {
    main: {
      display: generalData?.pages?.product,
      path: '/products',
      pageName: 'Products',
      addSectionLabel: 'Add Product',
      addSectionUrl: '/products/add',
      addInstanceAction: '/products/add',
      addSection: true,
      filterSection: true,
      searchButton: true,
      filterButton: true,
      permissions: [productPermissions['product:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Product Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Product Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [
        // { value: 'ALL', label: 'all', url: '/products' },
        // { value: 'active', label: 'active', url: '/products' },
        // { value: 'draft', label: 'draft', url: '/products' },
      ],
      rows: (data) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: i18n.t('ID'),
          key: 'id',
          width: '10%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item.id}
              </h2>
            );
          },
        },
        {
          title: i18n.t('Product'),
          key: 'product',
          width: '35%',
          elemnt: function (item) {
            return <ProductData item={item} />;
          },
        },

        {
          title: i18n.t('Categories'),
          key: 'categories',
          width: '20%',
          elemnt: function (item) {
            return (
              <div className=' flex gap-2 flex-wrap'>
                {item?.categories?.length
                  ? item?.categories?.map((col, index) => (
                      <div key={index}>{`${col?.name} ${
                        index !== item?.categories?.length - 1 ? '-' : ''
                      }`}</div>
                    ))
                  : '-'}
              </div>
            );
          },
        },
        {
          title: '',
          key: 'action',
          width: '30px',
          elemnt: function (item) {
            return (
              <div className='flex gap-2 justify-end'>
                <InstanceNavigate link={`/products/edit/${item?.id}`} newTab />
              </div>
            );
          },
        },
      ],
      fetch: fetchProducts,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,

          where: {
            name: search?.trim()?.replace(/\W{2,}/, ' '),
          },

          sortBy: order,
        };
      },
      stateName: 'product',
      clearMessages: clearMessagesProduct,
      selectAll: selectAllProduct,
      updateSelected: updateSelectedProduct,
      openModelDelete: openModelDeleteProduct,
      openModelDeleteAll: openModelDeleteAllProduct,

      handleDelete: handleDeleteProduct,
      handleDeleteMany: handleDeleteManyProduct,
      handleExportFiles: handleExportProduct,
      handleExportAllFiles: handleExportAllProducts,
      openModeleImport: openModeleImportProduct,
      handleImportFiles: handleImportProduct,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Product',
      deleteInstanceActionTitle: 'Delete Product',
      deleteInstanceDescription:
        'Are you sure you want to delete this Product with id',
      deleteAllInstanceTitle: 'Delete Products',
      deleteAllInstanceActionTitle: 'Delete Products',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Products with IDS :',
      emptyDataTitle: 'No Products Found!',
      emptyDataDescription:
        "It appears that no menus were. Please consider creating products to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Products',
      importInstanceMessage: 'Import Products',
      importInstanceModelTitle: 'Import Products by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      exportData: false,
      importData: false,
      selectMany: true,
      deleteMany: true,
      exportMany: false,
    },
  },

  service: {
    main: {
      display: generalData?.pages?.product,
      path: '/services',
      pageName: 'Services',
      addSectionLabel: 'Add Service',
      addSectionUrl: '/services/add',
      addInstanceAction: '/services/add',
      addSection: true,
      filterSection: true,
      searchButton: true,
      filterButton: true,
      permissions: [productPermissions['product:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],

      tabs: [
        // { value: 'ALL', label: 'all', url: '/products' },
        // { value: 'active', label: 'active', url: '/products' },
        // { value: 'draft', label: 'draft', url: '/products' },
      ],
      rows: (data) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: i18n.t('ID'),
          key: 'id',
          width: '10%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item.id}
              </h2>
            );
          },
        },
        {
          title: i18n.t('Name'),
          key: 'name',
          width: '35%',
          elemnt: function (item) {
            return <ProductData item={item} />;
          },
        },

        {
          title: i18n.t('Description'),
          key: 'description',
          width: '15%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2 text-xs font-semibold pr-3'>
                {item?.description}
              </h2>
            );
          },
        },
        {
          title: i18n.t('Categories'),
          key: 'categories',
          width: '20%',
          elemnt: function (item) {
            return (
              <div className=' flex gap-2 flex-wrap'>
                {item?.categories?.length
                  ? item?.categories?.map((col, index) => (
                      <div key={index}>{`${col?.name} ${
                        index !== item?.categories?.length - 1 ? '-' : ''
                      }`}</div>
                    ))
                  : ''}
              </div>
            );
          },
        },
        {
          title: '',
          key: 'action',
          width: '30px',
          elemnt: function (item) {
            return (
              <div className='flex gap-2 justify-end'>
                <InstanceNavigate link={`/services/edit/${item?.id}`} newTab />
              </div>
            );
          },
        },
      ],
      fetch: fetchServices,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,
          type: 'SERVICE',
          name: search?.trim()?.replace(/\W{2,}/, ' '),

          // where: {
          //   search: search?.trim()?.replace(/\W{2,}/, ' '),
          //   isEnabled:
          //     status === 'active' ? true : status === 'draft' ? false : null,
          // },
          // join: {
          //   collections: true,
          //   variants: true,
          //   translationData: true,
          //   reviews: false,
          // },

          sortBy: order,
        };
      },
      stateName: 'service',
      clearMessages: clearMessagesService,
      selectAll: selectAllService,
      updateSelected: updateSelectedService,
      openModelDelete: openModelDeleteService,
      openModelDeleteAll: openModelDeleteAllService,

      handleDelete: handleDeleteService,
      handleDeleteMany: handleDeleteManyService,
      handleExportFiles: handleExportServices,
      handleExportAllFiles: handleExportAllServices,
      openModeleImport: openModeleImportService,
      handleImportFiles: handleImportServices,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Service',
      deleteInstanceActionTitle: 'Delete Service',
      deleteInstanceDescription:
        'Are you sure you want to delete this Service with id',
      deleteAllInstanceTitle: 'Delete Services',
      deleteAllInstanceActionTitle: 'Delete Services',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Services with IDS :',
      emptyDataTitle: 'No Services Found!',
      emptyDataDescription:
        "It appears that no services were. Please consider creating Services to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Services',
      importInstanceMessage: 'Import Services',
      importInstanceModelTitle: 'Import Services by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data.',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      exportData: false,
      importData: false,
      selectMany: true,
      deleteMany: true,
      exportMany: false,
    },
  },

  planning: {
    main: {
      display: generalData?.pages?.product,
      path: '/planning',
      pageName: 'Planning',
      addSectionLabel: 'Add planning',
      addSectionUrl: '/planning/add',
      addInstanceAction: '/planning/add',
      addSection: true,
      filterSection: true,
      searchButton: true,
      filterButton: true,
      permissions: [productPermissions['product:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],

      tabs: [
        // { value: 'ALL', label: 'all', url: '/products' },
        // { value: 'active', label: 'active', url: '/products' },
        // { value: 'draft', label: 'draft ', url: '/products' },
      ],
      rows: (data) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: i18n.t('ID'),
          key: 'id',
          width: '10%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item.id}
              </h2>
            );
          },
        },
        {
          title: i18n.t('Name'),
          key: 'name',
          width: '35%',
          elemnt: function (item) {
            return <ProductData item={item} />;
          },
        },

        {
          title: i18n.t('Description'),
          key: 'description',
          width: '15%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2 text-xs font-semibold pr-3'>
                {item?.description}
              </h2>
            );
          },
        },
        {
          title: i18n.t('Categories'),
          key: 'categories',
          width: '20%',
          elemnt: function (item) {
            return (
              <div className=' flex gap-2 flex-wrap'>
                {item?.categories?.length
                  ? item?.categories?.map((col, index) => (
                      <div key={index}>{`${col?.name} ${
                        index !== item?.categories?.length - 1 ? '-' : ''
                      }`}</div>
                    ))
                  : ''}
              </div>
            );
          },
        },
        {
          title: '',
          key: 'action',
          width: '30px',
          elemnt: function (item) {
            return (
              <div className='flex gap-2 justify-end'>
                <InstanceNavigate link={`/planning/edit/${item?.id}`} newTab />
              </div>
            );
          },
        },
      ],
      fetch: fetchPlannings,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,
          type: 'PLANNING',
          name: search?.trim()?.replace(/\W{2,}/, ' '),
          // type: 'SERVICE',

          // where: {
          //   search: search?.trim()?.replace(/\W{2,}/, ' '),
          //   isEnabled:
          //     status === 'active' ? true : status === 'draft' ? false : null,
          // },
          // join: {
          //   collections: true,
          //   variants: true,
          //   translationData: true,
          //   reviews: false,
          // },

          sortBy: order,
        };
      },
      stateName: 'planning',
      clearMessages: clearMessagesPlanning,
      selectAll: selectAllPlanning,
      updateSelected: updateSelectedPlanning,
      openModelDelete: openModelDeletePlanning,
      openModelDeleteAll: openModelDeleteAllPlanning,

      handleDelete: handleDeletePlanning,
      handleDeleteMany: handleDeleteManyPlanning,
      handleExportFiles: handleExportPlannings,
      handleExportAllFiles: handleExportAllPlannings,
      openModeleImport: openModeleImportPlanning,
      handleImportFiles: handleImportPlannings,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Planning',
      deleteInstanceActionTitle: 'Delete Planning',
      deleteInstanceDescription:
        'Are you sure you want to delete this Planning with id',
      deleteAllInstanceTitle: 'Delete Plannings',
      deleteAllInstanceActionTitle: 'Delete Plannings',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Plannings with IDS :',
      emptyDataTitle: 'No Plannings Found!',
      emptyDataDescription:
        "It appears that no Planning were. Please consider creating Plannings to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Planning',
      importInstanceMessage: 'Import Planning',
      importInstanceModelTitle: 'Import Plannings by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data.',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      exportData: false,
      importData: false,
      selectMany: true,
      deleteMany: true,
      exportMany: false,
    },
  },

  reference: {
    main: {
      display: generalData?.pages?.product,
      path: '/references',
      pageName: 'References',
      addSectionLabel: 'Add reference',
      addSectionUrl: '/references/add',
      addInstanceAction: '/references/add',
      addSection: true,
      filterSection: true,
      searchButton: true,
      filterButton: true,
      permissions: [productPermissions['product:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],

      tabs: [
        // { value: 'ALL', label: 'all', url: '/products' },
        // { value: 'active', label: 'active', url: '/products' },
        // { value: 'draft', label: 'draft ', url: '/products' },
      ],
      rows: (data) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: i18n.t('ID'),
          key: 'id',
          width: '10%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item.id}
              </h2>
            );
          },
        },

        {
          title: i18n.t('Media'),
          key: 'media',
          width: '10%',
          elemnt: function (item) {
            return <ProductData item={item} />;
          },
        },

        {
          title: i18n.t('Name'),
          key: 'name',
          width: '15%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2 text-xs font-semibold pr-3'>
                {item?.customerFullName}
              </h2>
            );
          },
        },
        {
          title: i18n.t('Description'),
          key: 'description',
          width: '15%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2 text-xs font-semibold pr-3'>
                {item?.description}
              </h2>
            );
          },
        },
        {
          title: i18n.t('Location'),
          key: 'location',
          width: '20%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2 text-xs font-semibold pr-3'>
                {item?.location}
              </h2>
            );
          },
        },
        {
          title: '',
          key: 'action',
          width: '30px',
          elemnt: function (item) {
            return (
              <div className='flex gap-2 justify-end'>
                <InstanceNavigate
                  link={`/references/edit/${item?.id}`}
                  newTab
                />
              </div>
            );
          },
        },
      ],
      fetch: fetchReferences,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,
          customerFullName: search
            ? search?.trim()?.replace(/\W{2,}/, ' ')
            : '',
          // type: 'PLANNING',
          // type: 'SERVICE',

          // where: {
          //   search: search?.trim()?.replace(/\W{2,}/, ' '),
          //   isEnabled:
          //     status === 'active' ? true : status === 'draft' ? false : null,
          // },
          // join: {
          //   collections: true,
          //   variants: true,
          //   translationData: true,
          //   reviews: false,
          // },

          // sortBy: order,
        };
      },
      stateName: 'reference',
      clearMessages: clearMessagesReference,
      selectAll: selectAllReference,
      updateSelected: updateSelectedReference,
      openModelDelete: openModelDeleteReference,
      openModelDeleteAll: openModelDeleteAllReference,

      handleDelete: handleDeleteReference,
      handleDeleteMany: handleDeleteManyReference,
      handleExportFiles: handleExportReferences,
      handleExportAllFiles: handleExportAllReferences,
      openModeleImport: openModeleImportReference,
      handleImportFiles: handleImportReferences,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Reference',
      deleteInstanceActionTitle: 'Delete Reference',
      deleteInstanceDescription:
        'Are you sure you want to delete this Reference with id',
      deleteAllInstanceTitle: 'Delete References',
      deleteAllInstanceActionTitle: 'Delete References',
      deleteAllInstanceDescription:
        'Are you sure you want to delete References with IDS :',
      emptyDataTitle: 'No References Found!',
      emptyDataDescription:
        "It appears that no References were. Please consider creating References to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Reference',
      importInstanceMessage: 'Import Reference',
      importInstanceModelTitle: 'Import References by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data.',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      exportData: false,
      importData: false,
      selectMany: true,
      deleteMany: true,
      exportMany: false,
    },
  },

  category: {
    main: {
      display: generalData?.pages?.category,
      path: '/categories',
      pageName: 'Categories',
      addSectionLabel: 'Add category',
      addSectionUrl: '/categories/add',
      addInstanceAction: '/categories/add',
      addSection: true,
      filterSection: true,
      searchButton: true,
      filterButton: true,
      permissions: [productPermissions['product:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [
        // { value: 'ALL', label: 'all', url: '/products' },
        // { value: 'active', label: 'active', url: '/products' },
        // { value: 'draft', label: 'draft', url: '/products' },
      ],
      rows: (data) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: i18n.t('ID'),
          key: 'id',
          width: '10%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item.id}
              </h2>
            );
          },
        },
        {
          title: i18n.t('Name'),
          key: 'name',
          width: '35%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item.name}
              </h2>
            );
          },
        },

        {
          title: i18n.t('Description'),
          key: 'description',
          width: '15%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2 text-xs font-semibold pr-3'>
                {item?.description}
              </h2>
            );
          },
        },
        {
          title: i18n.t('Type'),
          key: 'type',
          width: '15%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item.type}
              </h2>
            );
          },
        },
        {
          title: '',
          key: 'action',
          width: '30px',
          elemnt: function (item) {
            return (
              <div className='flex gap-2 justify-end'>
                <InstanceNavigate
                  link={`/categories/edit/${item?.id}`}
                  newTab
                />
              </div>
            );
          },
        },
      ],
      fetch: fetchCategories,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,
          name: search?.trim()?.replace(/\W{2,}/, ' '),
        };
      },
      stateName: 'category',
      clearMessages: clearMessagesCategory,
      selectAll: selectAllCategory,
      updateSelected: updateSelectedCategory,
      openModelDelete: openModelDeleteCategory,
      openModelDeleteAll: openModelDeleteAllCategory,

      handleDelete: handleDeleteCategory,
      handleDeleteMany: handleDeleteManyCategory,
      handleExportFiles: handleExportCategory,
      handleExportAllFiles: handleExportAllCategories,
      openModeleImport: openModeleImportCategory,
      handleImportFiles: handleImportCategory,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Category',
      deleteInstanceActionTitle: 'Delete Category',
      deleteInstanceDescription:
        'Are you sure you want to delete this Category with id',
      deleteAllInstanceTitle: 'Delete Categories',
      deleteAllInstanceActionTitle: 'Delete Categories',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Categories with IDS :',
      emptyDataTitle: 'No Categories Found!',
      emptyDataDescription:
        "It appears that no Categories were. Please consider creating Categories to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Categories',
      importInstanceMessage: 'Import Categories',
      importInstanceModelTitle: 'Import Categories by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      exportData: false,
      importData: false,
      selectMany: true,
      deleteMany: true,
      exportMany: false,
    },
  },

  contact: {
    main: {
      display: generalData?.pages?.contact,
      path: '/contacts',
      pageName: 'contacts',
      addSectionLabel: 'Add Contact',
      addSectionUrl: '/contacts/add',
      addInstanceAction: '/contacts/add',
      addInstanceModal: false,
      addSection: false,
      filterSection: false,
      searchButton: false,
      filterButton: true,

      permissions: [productPermissions['product:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [],
      rows: (
        data,
        updateNewData,
        handleOpenModalDelte,
        handleOpenModalEdit
      ) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },

        {
          title: i18n.t(''),
          key: 'read',
          width: '30px',
          elemnt: function (item) {
            return (
              <div className={`w-full`}>
                {item.read ? <CheckCircleOutlineIcon color='success' /> : ''}
              </div>
            );
          },
        },
        {
          title: i18n.t('Name'),
          key: 'name',
          width: '20%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item.name}
              </h2>
            );
          },
        },

        {
          title: i18n.t('Email'),
          key: 'email',
          width: '25%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2 text-xs font-semibold pr-3'>
                {item?.email}
              </h2>
            );
          },
        },
        {
          title: i18n.t('Phone'),
          key: 'phone',
          width: '20%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item.phone}
              </h2>
            );
          },
        },
        {
          title: i18n.t('Message'),
          key: 'message',
          width: '30%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item.message}
              </h2>
            );
          },
        },
        {
          title: '',
          key: 'action',
          width: '30px',
          elemnt: function (item) {
            return (
              <InstanceEdit
                openModalDelte={() => handleOpenModalDelte(item)}
                openModalEdit={() => handleOpenModalEdit(item)}
              />
            );
          },
        },
      ],
      fetch: fetchContacts,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,
        };
      },
      stateName: 'contact',

      clearMessages: clearMessagesContact,
      selectAll: selectAllContact,
      updateSelected: updateSelectedContact,

      openModelCreate: openModelCreateContact,
      openModelEdit: openModelEditContact,

      openModelDelete: openModelDeleteContact,
      openModelDeleteAll: openModelDeleteAllContact,
      handleDelete: handleDeleteContact,
      handleDeleteMany: handleDeleteManyContact,
      handleExportFiles: handleExportContacts,
      openModeleImport: openModeleImportContact,
      handleExportAllFiles: handleExportAllContacts,
      handleImportFiles: handleImportContacts,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Contact',
      deleteInstanceActionTitle: 'Delete Shipping Methode',
      deleteInstanceDescription:
        'Are you sure you want to delete this Shipping Methode with id',
      deleteAllInstanceTitle: 'Delete Shipping Methode',
      deleteAllInstanceActionTitle: 'Delete Shipping Methode',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Shipping Methode with IDS :',
      emptyDataTitle: 'No Shipping Methodes Found!',
      emptyDataDescription:
        "It appears that no Shipping Methode were. Please consider creating Shipping Methode to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Shipping Methode',
      importInstanceMessage: 'Import Shipping Methodes',
      importInstanceModelTitle: 'Import Shipping Methode by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      pageSize: 10,
      exportData: false,
      importData: false,
      selectMany: false,
      deleteMany: true,
      exportMany: true,

      components: [<EditContact />],
    },
  },

  variant: {
    main: {
      display: generalData?.pages?.variant,
      path: '/inventory',
      pageName: 'Inventory',
      addSectionLabel: 'View Products',
      addSectionUrl: '/products',
      addInstanceAction: '/products',
      addSection: true,
      filterSection: true,
      searchButton: true,
      filterButton: true,
      permissions: [inventoryPermissions['variant:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Variant Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Variant Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [
        { value: 'ALL', label: 'all', url: '/variants' },
        { value: 'active', label: 'active', url: '/variants' },
        { value: 'draft', label: 'draft', url: '/variants' },
      ],
      updateFildes: ['stockOnHand'],
      rows: (data, updateNewData) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: i18n.t('Product'),
          key: 'product',
          width: '25%',
          elemnt: function (item) {
            return <VariantData item={item} />;
          },
        },
        {
          title: i18n.t('Sku'),
          key: 'sku',
          width: '15%',
          elemnt: function (item) {
            return (
              <div className='text-xs rounded-sm font-Inter'>
                {item?.sku ? item?.sku : '-'}
              </div>
            );
          },
        },
        {
          title: i18n.t('Stock Allocated'),
          key: 'stockAllocated',
          width: '20%',
          elemnt: function (item) {
            return (
              <>
                {item?.stockAllocated > 0 ? (
                  <div className='font-Roboto text-xs font-semibold'>
                    {item?.stockAllocated}
                  </div>
                ) : (
                  <div className='font-Roboto text-xs font-semibold'>0</div>
                )}
              </>
            );
          },
        },
        {
          title: i18n.t('Threshold'),
          key: 'Threshold',
          width: '20%',
          elemnt: function (item) {
            return (
              <>
                {item?.outOfStockThreshold > 0 ? (
                  <div className='font-Roboto text-xs font-semibold'>
                    {item?.outOfStockThreshold}
                  </div>
                ) : (
                  <div className='font-Roboto text-xs font-semibold'>0</div>
                )}
              </>
            );
          },
        },
        {
          title: i18n.t('available'),
          key: 'available',
          width: '20% ',

          elemnt: function (item) {
            return (
              <div className='flex gap-5 justify-between items-center'>
                <div className='w-[80%] max-w-[150px] '>
                  <BasicInput
                    value={item.stockOnHand}
                    onChange={(e) => {
                      updateNewData(e, item, 'stockOnHand');
                    }}
                    size='small'
                    number
                  />
                </div>
              </div>
            );
          },
        },
      ],
      fetch: fetchVariants,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,

          where: {
            search: search?.trim()?.replace(/\W{2,}/, ' '),
            isEnabled:
              status === 'active' ? true : status === 'draft' ? false : null,
            languageCode: null,
          },
          join: {
            product: true,
            options: true,
          },

          sortBy: order,
        };
      },

      stateName: 'variant',
      clearMessages: clearMessagesVariant,
      selectAll: selectAllVariant,
      updateSelected: updateSelectedVariant,
      openModelDelete: openModelDeleteVariant,
      openModelDeleteAll: openModelDeleteAllVariant,

      handleDelete: handleDeleteVariant,
      handleDeleteMany: handleDeleteManyVariant,
      handleExportFiles: handleExportVariant,
      handleExportAllFiles: handleExportAllVariants,
      openModeleImport: openModeleImportProduct,
      handleImportFiles: handleImportVariant,
      handleUpdateMany: handleEditManyVariantsStock,

      // Static Data
      updateAllActionTitle: 'Save', //after update init data
      updateAllCancelTitle: 'discard', //after update init data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Variant',
      deleteInstanceActionTitle: 'Delete Variant',
      deleteInstanceDescription:
        'Are you sure you want to delete this Variant with id',
      deleteAllInstanceTitle: 'Delete Variants',
      deleteAllInstanceActionTitle: 'Delete Variants',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Variants with IDS :',
      emptyDataTitle: 'No Variants Found!',
      emptyDataDescription:
        "It appears that no Variants were. Please consider creating products to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Products',
      importInstanceMessage: 'Import Variant',
      importInstanceModelTitle: 'Import Variants by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      exportData: false,
      importData: false,
      selectMany: false,
      deleteMany: true,
      exportMany: true,
    },
  },

  collection: {
    main: {
      display: generalData?.pages?.collection,
      path: '/collections',
      pageName: 'collections',
      addSectionLabel: 'Add collection',
      addSectionUrl: '/collections/add',
      addInstanceAction: '/collections/add',
      addSection: true,
      filterSection: true,
      searchButton: true,
      filterButton: true,
      permissions: [collectionPermissions['collection:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Collection Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Collection Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [{ value: 'all', label: 'all' }],
      rows: (data) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: i18n.t('ID'),
          key: 'id',
          width: '10%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item.id}
              </h2>
            );
          },
        },
        {
          title: i18n.t('Title'),
          key: 'Title',
          width: '25%',
          elemnt: function (item) {
            return <ImageWithTitle item={item} />;
          },
        },
        {
          title: i18n.t('Description'),
          key: 'description',
          width: '30%',

          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2 text-xs font-semibold pr-3'>
                {defaultDatat(
                  item?.translationData,
                  i18n.language,
                  'description'
                )}
              </h2>
            );
          },
        },

        {
          title: i18n.t('products'),
          key: 'products',
          width: '25%',

          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item?.productsCount ? item?.productsCount : 0}
              </h2>
            );
          },
        },

        {
          title: i18n.t('Parent'),
          key: 'parent',
          width: '25%',

          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {defaultDatat(
                  item?.parent?.translationData,
                  i18n.language,
                  'description'
                )}
              </h2>
            );
          },
        },
        {
          title: '',
          key: 'action',
          width: '30px',
          elemnt: function (item) {
            return (
              <div className='flex gap-2 justify-end'>
                <InstanceNavigate link={`/collections/edit/${item?.id}`} />
              </div>
            );
          },
        },
      ],
      fetch: fetchCollections,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,
          where: {
            isEnabled: null,
            languageCode: null,
            search: search?.trim()?.replace(/\W{2,}/, ' '),
          },
          sortBy: order,
        };
      },
      stateName: 'collection',
      clearMessages: clearMessagesCollection,
      selectAll: selectAllCollection,
      updateSelected: updateSelectedCollection,
      openModelDelete: openModelDeleteCollection,
      openModelDeleteAll: openModelDeleteAllCollection,
      handleDelete: handleDeleteCollection,
      handleDeleteMany: handleDeleteManyCollection,
      handleExportFiles: handleExportCollections,
      openModeleImport: openModeleImportCollection,
      handleExportAllFiles: handleExportAllCollections,
      handleImportFiles: handleImportCollections,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Collection',
      deleteInstanceActionTitle: 'Delete Collection',
      deleteInstanceDescription:
        'Are you sure you want to delete this Collection with id',
      deleteAllInstanceTitle: 'Delete Collections',
      deleteAllInstanceActionTitle: 'Delete Collections',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Collections with IDS :',
      emptyDataTitle: 'No Collections Found!',
      emptyDataDescription:
        "It appears that no menus were. Please consider creating collections to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Collection',
      importInstanceMessage: 'Import Collections',
      importInstanceModelTitle: 'Import Collections by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      exportData: false,
      importData: false,
      selectMany: false,
      deleteMany: true,
      exportMany: true,
    },
  },

  brands: {
    main: {
      display: generalData?.pages?.brand,
      path: '/brands',
      pageName: 'brands',
      addSectionLabel: 'Add brand',
      addSectionUrl: '/brands/add',
      addInstanceAction: '/brands/add',
      addSection: true,
      filterSection: true,
      searchButton: true,
      filterButton: true,
      permissions: [brandPermissions['brand:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Brand Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Brand Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [{ value: 'all', label: 'all' }],
      rows: (data) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: i18n.t('ID'),
          key: 'id',
          width: '10%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item.id}
              </h2>
            );
          },
        },
        {
          title: i18n.t('Title'),
          key: 'Title',
          width: '25%',
          elemnt: function (item) {
            return <ImageWithTitle item={item} />;
          },
        },
        {
          title: i18n.t('Description'),
          key: 'description',
          width: '30%',

          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2 text-xs font-semibold pr-3'>
                {defaultDatat(
                  item?.translationData,
                  i18n.language,
                  'description'
                )}
              </h2>
            );
          },
        },

        {
          title: i18n.t('products'),
          key: 'products',
          width: '25%',

          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item?.productsCount ? item?.productsCount : 0}
              </h2>
            );
          },
        },

        {
          title: '',
          key: 'action',
          width: '30px',
          elemnt: function (item) {
            return (
              <div className='flex gap-2 justify-end'>
                <InstanceNavigate link={`/brands/edit/${item?.id}`} />
              </div>
            );
          },
        },
      ],
      fetch: fetchBrands,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,
          where: {
            search: search?.trim()?.replace(/\W{2,}/, ' '),
          },
          join: {
            translationData: true,
          },
          sortBy: order,
        };
      },
      stateName: 'brand',
      clearMessages: clearMessagesBrand,
      selectAll: selectAllBrand,
      updateSelected: updateSelectedBrand,
      openModelDelete: openModelDeleteBrand,
      openModelDeleteAll: openModelDeleteAllBrand,
      handleDelete: handleDeleteBrand,
      handleDeleteMany: handleDeleteManyBrand,
      handleExportFiles: handleExportBrands,
      openModeleImport: openModeleImportBrand,
      handleExportAllFiles: handleExportAllBrands,
      handleImportFiles: handleImportBrands,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Brands',
      deleteInstanceActionTitle: 'Delete Brand',
      deleteInstanceDescription:
        'Are you sure you want to delete this Brand with id',
      deleteAllInstanceTitle: 'Delete Brands',
      deleteAllInstanceActionTitle: 'Delete Brands',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Brands with IDS :',
      emptyDataTitle: 'No Brands Found!',
      emptyDataDescription:
        "It appears that no brands were. Please consider creating Brands to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Brand',
      importInstanceMessage: 'Import Brands',
      importInstanceModelTitle: 'Import Brands by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      exportData: false,
      importData: false,
      selectMany: false,
      deleteMany: true,
      exportMany: true,
    },
  },

  tags: {
    main: {
      display: generalData?.pages?.tag,
      path: '/tags',
      pageName: 'tags',
      addSectionLabel: 'Add tag',
      addSectionUrl: '/tags/add',
      addInstanceAction: '/tags/add',
      addInstanceModal: true,
      addSection: true,
      filterSection: true,
      searchButton: true,
      filterButton: true,
      permissions: [tagPermissions['tag:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'tag Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'tag Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [{ value: 'all', label: 'all' }],
      rows: (
        data,
        updateNewData,
        handleOpenModalDelte,
        handleOpenModalEdit
      ) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: i18n.t('ID'),
          key: 'id',
          width: '10%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item.id}
              </h2>
            );
          },
        },
        {
          title: i18n.t('Name'),
          key: 'tag',
          width: '10%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {defaultDatat(item?.translationData, i18n.language, 'name')}
              </h2>
            );
          },
        },

        {
          title: '',
          key: 'action',
          width: '70px',
          elemnt: function (item) {
            return (
              <InstanceEditDelte
                openModalDelte={() => handleOpenModalDelte(item)}
                openModalEdit={() => handleOpenModalEdit(item)}
              />
            );
          },
        },
      ],
      fetch: fetchTags,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,
          where: {
            search: search?.trim()?.replace(/\W{2,}/, ' '),
            isEnabled: null,
          },
          join: {
            translationData: true,
          },
          sortBy: order,
        };
      },
      stateName: 'tag',
      clearMessages: clearMessagesTag,
      selectAll: selectAllTag,
      updateSelected: updateSelectedTag,
      openModelCreate: openModelCreateTag,
      openModelEdit: openModelEditTag,
      openModelDelete: openModelDeleteTag,
      openModelDeleteAll: openModelDeleteAllTag,
      handleDelete: handleDeleteTag,
      handleDeleteMany: handleDeleteManyTag,
      handleExportFiles: handleExportTags,
      openModeleImport: openModeleImportTag,
      handleExportAllFiles: handleExportAllTags,
      handleImportFiles: handleImportTags,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Tags',
      deleteInstanceActionTitle: 'Delete Tag',
      deleteInstanceDescription:
        'Are you sure you want to delete this Tag with id',
      deleteAllInstanceTitle: 'Delete Tags',
      deleteAllInstanceActionTitle: 'Delete Tags',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Tags with IDS :',
      emptyDataTitle: 'No Tags Found!',
      emptyDataDescription:
        "It appears that no brands were. Please consider creating Tags to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Tag',
      importInstanceMessage: 'Import Tags',
      importInstanceModelTitle: 'Import Tag by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      exportData: false,
      importData: false,
      selectMany: false,
      deleteMany: true,
      exportMany: true,

      components: [<EditModalTag />, <CreateModalTag />],
    },
  },

  discounts: {
    main: {
      display: generalData?.pages?.discount,
      path: '/discounts',
      pageName: 'discounts',
      addSectionLabel: 'Add discount',
      addSectionUrl: '/discounts/add',
      addInstanceAction: '/discounts/add',
      addSection: true,
      filterSection: true,
      searchButton: true,
      filterButton: true,
      permissions: [discountPermissions['discount:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Discount Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Discount Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [
        { value: 'all', label: 'all' },
        { value: 'ACTIVE', label: 'active' },
        {
          value: 'INACTIVE',
          label: 'Inactive',
        },
        {
          value: 'FULLY_USED',
          label: 'Fully Used',
        },
        { value: 'EXPIRED', label: 'expired' },
      ],
      rows: (data) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: i18n.t('COED / TITLE'),
          key: 'title',
          width: '25%',
          elemnt: function (item) {
            return (
              <div>
                {item.name
                  ? item.name
                  : item?.couponCode
                  ? item.couponCode
                  : ''}
              </div>
            );
          },
        },
        {
          title: i18n.t('Status'),
          key: 'status',
          width: '15%',
          elemnt: function (item) {
            return <DiscountStatus item={item} />;
          },
        },
        {
          title: i18n.t('Amount'),
          key: 'amount',
          width: '15%',
          elemnt: function (item) {
            return <div>{item?.fixedAmount ? item?.fixedAmount : ''}</div>;
          },
        },

        {
          title: i18n.t('Percentage'),
          key: 'amount',
          width: '15%',
          elemnt: function (item) {
            return <div>{item?.percentage ? `${item?.percentage}%` : ''}</div>;
          },
        },

        {
          title: i18n.t('Max Usage'),
          key: 'max_usage',
          width: '15%',
          elemnt: function (item) {
            return (
              <div>
                {item?.max_usage
                  ? `${
                      item?.max_usage < 0
                        ? i18n.t('Unlimited')
                        : item?.max_usage
                    }`
                  : ''}
              </div>
            );
          },
        },

        {
          title: i18n.t('Usage Limit'),
          key: 'used',
          width: '10%',

          elemnt: function (item) {
            return (
              <div>
                {item?.perCustomerUsageLimit
                  ? `${item?.perCustomerUsageLimit}`
                  : ''}
              </div>
            );
          },
        },
        {
          title: i18n.t('products'),
          key: 'products',
          width: '25%',

          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {item?.productsCount ? item?.productsCount : 0}
              </h2>
            );
          },
        },

        {
          title: '',
          key: 'action',
          width: '30px',
          elemnt: function (item) {
            return (
              <div className='flex gap-2 justify-end'>
                <InstanceNavigate link={`/discounts/edit/${item?.id}`} />
              </div>
            );
          },
        },
      ],
      fetch: fetchDiscounts,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,
          where: {
            couponCode: '',
            status: status ? (status === 'all' ? null : status) : null,
          },
        };
      },
      stateName: 'discount',
      clearMessages: clearMessagesDiscount,
      selectAll: selectAllDiscount,
      updateSelected: updateSelectedDiscount,
      openModelDelete: openModelDeleteDiscount,
      openModelDeleteAll: openModelDeleteAllDiscount,
      handleDelete: handleDeleteDiscount,
      handleDeleteMany: handleDeleteManyDiscount,
      handleExportFiles: handleExportDiscounts,
      openModeleImport: openModeleImportDiscount,
      handleExportAllFiles: handleExportAllDiscounts,
      handleImportFiles: handleImportDiscounts,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Discounts',
      deleteInstanceActionTitle: 'Delete Discount',
      deleteInstanceDescription:
        'Are you sure you want to delete this Discounts with id',
      deleteAllInstanceTitle: 'Delete Discounts',
      deleteAllInstanceActionTitle: 'Delete Discounts',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Discounts with IDS :',
      emptyDataTitle: 'No Discounts Found!',
      emptyDataDescription:
        "It appears that no brands were. Please consider creating Discounts to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Discount',
      importInstanceMessage: 'Import Discounts',
      importInstanceModelTitle: 'Import Discounts by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      exportData: false,
      importData: false,
      selectMany: false,
      deleteMany: true,
      exportMany: true,
    },
  },
  customers: {
    main: {
      display: generalData?.pages?.customer,
      path: '/customers',
      pageName: 'customers',
      addSectionLabel: 'Add customer',
      addSectionUrl: '/customers/add',
      addInstanceAction: '/customers/add',
      addSection: true,
      filterSection: true,
      searchButton: true,
      filterButton: true,
      permissions: [customerPermissions['customer:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Customer Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Customer Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [{ value: 'all', label: 'all' }],
      rows: (data) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: i18n.t('full Name'),
          key: 'firstName',
          width: '25%',
          elemnt: function (item) {
            return (
              <div className='flex gap-1'>
                <span>{item.firstName}</span>
                <span>{item.lastName}</span>
              </div>
            );
          },
        },
        { title: i18n.t('Email'), key: 'email', width: '25%' },
        {
          title: i18n.t('Phone Number'),
          key: 'phoneNumber',
          width: '30%',
        },

        {
          title: i18n.t('Country'),
          key: 'address',
          width: '25%',
          elemnt: function (item) {
            return (
              <div className='flex gap-1'>
                <span>
                  {item.addresses[0]?.country?.translationData &&
                    defaultDatat(
                      item.addresses[0]?.country?.translationData,
                      i18n.language,
                      'name'
                    )}
                </span>
              </div>
            );
          },
        },

        {
          title: '',
          key: 'action',
          width: '30px',
          elemnt: function (item) {
            return (
              <div className='flex gap-2 justify-end'>
                <InstanceNavigate link={`/customers/edit/${item?.id}`} />
              </div>
            );
          },
        },
      ],
      fetch: fetchCustomers,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,
          search: search?.trim()?.replace(/\W{2,}/, ' '),
          sortBy: order,
        };
      },
      stateName: 'customer',
      clearMessages: clearMessagesCustomer,
      selectAll: selectAllCustomer,
      updateSelected: updateSelectedCustomer,
      openModelDelete: openModelDeleteCustomer,
      openModelDeleteAll: openModelDeleteAllCustomer,
      handleDelete: handleDeleteCustomer,
      handleDeleteMany: handleDeleteManyCustomer,
      handleExportFiles: handleExportCustomers,
      openModeleImport: openModeleImportCustomer,
      handleExportAllFiles: handleExportAllCustomers,
      handleImportFiles: handleImportCustomers,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Customers',
      deleteInstanceActionTitle: 'Delete Customers',
      deleteInstanceDescription:
        'Are you sure you want to delete this Customer with id',
      deleteAllInstanceTitle: 'Delete Customers',
      deleteAllInstanceActionTitle: 'Delete Customers',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Customers with IDS :',
      emptyDataTitle: 'No Customers Found!',
      emptyDataDescription:
        "It appears that no brands were. Please consider creating Customers to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Customer',
      importInstanceMessage: 'Import Customers',
      importInstanceModelTitle: 'Import Customers by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      exportData: false,
      importData: false,
      selectMany: false,
      deleteMany: true,
      exportMany: true,
    },
  },

  setting: {
    main: {
      display: generalData?.pages?.setting,
      path: '/setting',
      pageName: 'setting',
      addSectionLabel: 'Add staff',
      addSectionUrl: '/staff/add',
      addInstanceAction: '/staff/add',
      addInstanceModal: true,
      addSection: true,
      filterSection: false,
      searchButton: false,
      filterButton: true,

      permissions: [accountSettings['account-settings:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'User Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'User Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [],
      // rows: (
      //   data,
      //   updateNewData,
      //   handleOpenModalDelte,
      //   handleOpenModalEdit
      // ) => [
      //   data.selectMany &&
      //     (data.deleteMany || data.exportMany) && {
      //       title: 'check',
      //       key: 'check',
      //       width: '10%',
      //     },
      //   {
      //     title: 'ID',
      //     key: 'id',
      //     width: '10%',
      //   },
      //   {
      //     title: 'Full Name',
      //     key: 'title',
      //     width: '20%',
      //     elemnt: function (item) {
      //       return (
      //         <div>{`${item?.customer?.firstName} ${item?.customer?.lastName}`}</div>
      //       );
      //     },
      //   },
      //   {
      //     title: 'Email',
      //     key: 'title',
      //     width: '35%',
      //     elemnt: function (item) {
      //       return <div>{item.email}</div>;
      //     },
      //   },

      //   {
      //     title: 'Roles',
      //     key: 'roles',
      //     width: '30%',
      //     elemnt: function (item) {
      //       return <RolesFiled item={item} />;
      //     },
      //   },

      //   {
      //     title: '',
      //     key: 'action',
      //     width: '70px',
      //     elemnt: function (item) {
      //       return (
      //         <InstanceEditDelte
      //           openModalDelte={() => handleOpenModalDelte(item)}
      //           openModalEdit={() => handleOpenModalEdit(item)}
      //         />
      //       );
      //     },
      //   },
      // ],
      // fetch: fetchUsers,
      // variables: ({ pageSize, page, search, order, status }) => {
      //   return {
      //     offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
      //     limit: pageSize,
      //     type: 'STAFF',
      //   };
      // },
      stateName: 'user',
      clearMessages: clearMessagesUser,
      selectAll: selectAllUser,
      updateSelected: updateSelectedUser,

      openModelCreate: openModelCreateUser,
      openModelEdit: openModelEditUser,

      openModelDelete: openModelDeleteUser,
      openModelDeleteAll: openModelDeleteAllUser,
      handleDelete: handleDeleteUser,
      handleDeleteMany: handleDeleteManyUser,
      handleExportFiles: handleExportUsers,
      openModeleImport: openModeleImportUser,
      handleExportAllFiles: handleExportAllUsers,
      handleImportFiles: handleImportUsers,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Users',
      deleteInstanceActionTitle: 'Delete Users',
      deleteInstanceDescription:
        'Are you sure you want to delete this User with id',
      deleteAllInstanceTitle: 'Delete User',
      deleteAllInstanceActionTitle: 'Delete User',
      deleteAllInstanceDescription:
        'Are you sure you want to delete User with IDS :',
      emptyDataTitle: 'No User Found!',
      emptyDataDescription:
        "It appears that no brands were. Please consider creating Customers to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New User',
      importInstanceMessage: 'Import Users',
      importInstanceModelTitle: 'Import Users by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: true,
      exportData: false,
      importData: false,
      selectMany: false,
      deleteMany: true,
      exportMany: true,

      // components: [ <AddStaffModal />, <EditStaffModal /> ],
    },
  },
};

export const pagesSettingsData = {
  staff: {
    main: {
      display: generalData?.pages?.staffSetting,
      path: '/staff',
      pageName: 'staff',
      addSectionLabel: 'Add staff',
      addSectionUrl: '/staff/add',
      addInstanceAction: '/staff/add',
      addInstanceModal: true,
      addSection: true,
      filterSection: false,
      searchButton: false,
      filterButton: true,

      permissions: [accountSettings['account-settings:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'User Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'User Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [],
      rows: (
        data,
        updateNewData,
        handleOpenModalDelte,
        handleOpenModalEdit
      ) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: 'ID',
          key: 'id',
          width: '10%',
        },
        {
          title: 'Full Name',
          key: 'title',
          width: '20%',
          elemnt: function (item) {
            return (
              <div>{`${item?.customer?.firstName} ${item?.customer?.lastName}`}</div>
            );
          },
        },
        {
          title: 'Email',
          key: 'title',
          width: '35%',
          elemnt: function (item) {
            return <div>{item.email}</div>;
          },
        },

        {
          title: 'Roles',
          key: 'roles',
          width: '30%',
          elemnt: function (item) {
            return <RolesFiled item={item} />;
          },
        },

        {
          title: '',
          key: 'action',
          width: '70px',
          elemnt: function (item) {
            return (
              <InstanceEditDelte
                openModalDelte={() => handleOpenModalDelte(item)}
                openModalEdit={() => handleOpenModalEdit(item)}
              />
            );
          },
        },
      ],
      fetch: fetchUsers,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,
          type: 'STAFF',
        };
      },
      stateName: 'user',
      clearMessages: clearMessagesUser,
      selectAll: selectAllUser,
      updateSelected: updateSelectedUser,

      openModelCreate: openModelCreateUser,
      openModelEdit: openModelEditUser,

      openModelDelete: openModelDeleteUser,
      openModelDeleteAll: openModelDeleteAllUser,
      handleDelete: handleDeleteUser,
      handleDeleteMany: handleDeleteManyUser,
      handleExportFiles: handleExportUsers,
      openModeleImport: openModeleImportUser,
      handleExportAllFiles: handleExportAllUsers,
      handleImportFiles: handleImportUsers,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete User',
      deleteInstanceActionTitle: 'Delete User',
      deleteInstanceDescription:
        'Are you sure you want to delete this User with id',
      deleteAllInstanceTitle: 'Delete User',
      deleteAllInstanceActionTitle: 'Delete User',
      deleteAllInstanceDescription:
        'Are you sure you want to delete User with IDS :',
      emptyDataTitle: 'No User Found!',
      emptyDataDescription:
        "It appears that no brands were. Please consider creating Customers to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New User',
      importInstanceMessage: 'Import Users',
      importInstanceModelTitle: 'Import Users by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      pageSize: 7,
      exportData: false,
      importData: false,
      selectMany: false,
      deleteMany: true,
      exportMany: true,

      components: [<AddStaffModal />, <EditStaffModal />],
    },
  },

  roles: {
    main: {
      display: generalData?.pages?.roleSetting,
      path: '/roles',
      pageName: 'roles',
      addSectionLabel: 'Add Role',
      addSectionUrl: '/roles/add',
      addInstanceAction: '/roles/add',
      addInstanceModal: true,
      addSection: true,
      filterSection: false,
      searchButton: false,
      filterButton: true,

      permissions: [roleSetting['role-settings:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Role Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Role Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [],
      rows: (
        data,
        updateNewData,
        handleOpenModalDelte,
        handleOpenModalEdit
      ) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: 'ID',
          key: 'id',
          width: '10%',
        },
        {
          title: 'Name',
          key: 'name',
          width: '30%',
          elemnt: function (item) {
            return (
              <h2 className='text-main_text font-Roboto text-xs  font-semibold line-clamp-2'>
                {item.name}
              </h2>
            );
          },
        },

        {
          title: 'Permissions',
          key: 'permissions',
          width: '50%',
          elemnt: function (item) {
            return <PermissionsFiled item={item} />;
          },
        },
        {
          title: '',
          key: 'action',
          width: '70px',
          elemnt: function (item) {
            return (
              <InstanceEditDelte
                openModalDelte={() => handleOpenModalDelte(item)}
                openModalEdit={() => handleOpenModalEdit(item)}
              />
            );
          },
        },
      ],
      fetch: fetchRolles,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          offset: pageSize * (page - 1 > 0 ? page - 1 : 0),
          limit: pageSize,
          search: search?.trim()?.replace(/\W{2,}/, ' '),
        };
      },
      stateName: 'rolle',
      clearMessages: clearMessagesRole,
      selectAll: selectAllRole,
      updateSelected: updateSelectedRole,

      openModelCreate: openModelCreateRole,
      openModelEdit: openModelEditRole,

      openModelDelete: openModelDeleteRole,
      openModelDeleteAll: openModelDeleteAllRole,
      handleDelete: handleDeleteRole,
      handleDeleteMany: handleDeleteManyRole,
      handleExportFiles: handleExportRoles,
      openModeleImport: openModeleImportRole,
      handleExportAllFiles: handleExportAllRoles,
      handleImportFiles: handleImportRoles,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Role',
      deleteInstanceActionTitle: 'Delete Role',
      deleteInstanceDescription:
        'Are you sure you want to delete this Role with id',
      deleteAllInstanceTitle: 'Delete Role',
      deleteAllInstanceActionTitle: 'Delete Role',
      deleteAllInstanceDescription:
        'Are you sure you want to delete User with IDS :',
      emptyDataTitle: 'No Role Found!',
      emptyDataDescription:
        "It appears that no brands were. Please consider creating Roles to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Role',
      importInstanceMessage: 'Import Roles',
      importInstanceModelTitle: 'Import Roles by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      pageSize: 7,
      exportData: false,
      importData: false,
      selectMany: false,
      deleteMany: true,
      exportMany: true,

      components: [<AddRoleModal />, <EditRoleModal />],
    },
  },

  shippingMethod: {
    main: {
      display: generalData?.pages?.shippingMethodeSetting,
      path: '/shipping-method',
      pageName: 'shipping Method',
      addSectionLabel: 'Add Shipping Method',
      addSectionUrl: '/shipping-method/add',
      addInstanceAction: '/shipping-method/add',
      addInstanceModal: true,
      addSection: true,
      filterSection: false,
      searchButton: false,
      filterButton: true,

      permissions: [shippingMethodSettings['shipping-method:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Shipping Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Shipping Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [],
      rows: (
        data,
        updateNewData,
        handleOpenModalDelte,
        handleOpenModalEdit
      ) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: 'ID',
          key: 'id',
          width: '10%',
        },
        {
          title: 'Name',
          key: 'Name',
          width: '30%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {defaultDatat(item?.translationData, i18n.language, 'name')}
              </h2>
            );
          },
        },

        {
          title: 'Price',
          key: 'Price',
          width: '30%',
          elemnt: function (item) {
            return (
              <h2 className='text-xs font-semibold'>{item.default_price}</h2>
            );
          },
        },

        {
          title: 'Zones',
          key: 'zone',
          width: '30%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {defaultDatat(
                  item?.zone?.translationData,
                  i18n.language,
                  'name'
                )}
              </h2>
            );
          },
        },

        {
          title: '',
          key: 'action',
          width: '70px',
          elemnt: function (item) {
            return (
              <InstanceEditDelte
                openModalDelte={() => handleOpenModalDelte(item)}
                openModalEdit={() => handleOpenModalEdit(item)}
              />
            );
          },
        },
      ],
      fetch: fetchShippingMethodes,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          // country: true,
          // name: "",
          shippingMethodRelations: {
            zone: {
              countries: true,
            },
            shippingLines: true,
          },
        };
      },
      stateName: 'shippingMethode',
      clearMessages: clearMessagesShippingMethode,
      selectAll: selectAllShippingMethode,
      updateSelected: updateSelectedShippingMethode,

      openModelCreate: openModelCreateShippingMethode,
      openModelEdit: openModelEditShippingMethode,

      openModelDelete: openModelDeleteShippingMethode,
      openModelDeleteAll: openModelDeleteAllShippingMethode,
      handleDelete: handleDeleteShippingMethode,
      handleDeleteMany: handleDeleteManyShippingMethode,
      handleExportFiles: handleExportShippingMethodes,
      openModeleImport: openModeleImportShippingMethode,
      handleExportAllFiles: handleExportAllShippingMethodes,
      handleImportFiles: handleImportShippingMethodes,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Shipping Methode',
      deleteInstanceActionTitle: 'Delete Shipping Methode',
      deleteInstanceDescription:
        'Are you sure you want to delete this Shipping Methode with id',
      deleteAllInstanceTitle: 'Delete Shipping Methode',
      deleteAllInstanceActionTitle: 'Delete Shipping Methode',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Shipping Methode with IDS :',
      emptyDataTitle: 'No Shipping Methodes Found!',
      emptyDataDescription:
        "It appears that no Shipping Methode were. Please consider creating Shipping Methode to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Shipping Methode',
      importInstanceMessage: 'Import Shipping Methodes',
      importInstanceModelTitle: 'Import Shipping Methode by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      pageSize: 50,
      exportData: false,
      importData: false,
      selectMany: false,
      deleteMany: true,
      exportMany: true,

      components: [<AddShippingAdressModal />, <EditShippingAdressModal />],
    },
  },

  zone: {
    main: {
      display: generalData?.pages?.zoneSetting,
      path: '/zones',
      pageName: 'zones',
      addSectionLabel: 'Add zone',
      addSectionUrl: '/zone/add',
      addInstanceAction: '/zone/add',
      addInstanceModal: true,
      addSection: true,
      filterSection: false,
      searchButton: false,
      filterButton: true,

      permissions: [zoneSetting['zone:list']],
      defaultSortBy: 'createdAt-DESC',
      sort: [
        {
          value: 'id-ASC',
          label: 'Shipping Id A–Z',
        },
        {
          value: 'id-DESC',
          label: 'Shipping Id Z–A',
        },
        {
          value: 'createdAt-ASC',
          label: 'created (oldest first)',
        },
        {
          value: 'createdAt-DESC',
          label: 'created (newest first)',
        },
        {
          value: 'updatedAt-ASC',
          label: 'updated (oldest first)',
        },
        {
          value: 'updatedAt-DESC',
          label: 'updated (newest first)',
        },
      ],
      tabs: [],
      rows: (
        data,
        updateNewData,
        handleOpenModalDelte,
        handleOpenModalEdit
      ) => [
        data.selectMany &&
          (data.deleteMany || data.exportMany) && {
            title: 'check',
            key: 'check',
            width: '10%',
          },
        {
          title: 'ID',
          key: 'id',
          width: '10%',
        },
        {
          title: 'Name',
          key: 'Name',
          width: '30%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                {defaultDatat(item?.translationData, i18n.language, 'name')}
              </h2>
            );
          },
        },

        {
          title: 'Countries',
          key: 'Countries',
          width: '30%',
          elemnt: function (item) {
            return (
              <h2 className='font-Roboto text-main_text line-clamp-2  text-xs font-semibold'>
                <Tooltip
                  title={
                    <div className='text-sm text-bold flex gap-3 flex-wrap break-all'>
                      {item?.countries?.length &&
                        item?.countries?.map((item, index) => (
                          <span>{`${item.code}`}</span>
                        ))}
                    </div>
                  }
                >
                  <span className=''>{item?.countries?.length}</span>
                </Tooltip>
              </h2>
            );
          },
        },

        {
          title: '',
          key: 'action',
          width: '70px',
          elemnt: function (item) {
            return (
              <InstanceEditDelte
                openModalDelte={() => handleOpenModalDelte(item)}
                openModalEdit={() => handleOpenModalEdit(item)}
              />
            );
          },
        },
      ],
      fetch: fetchZones,
      variables: ({ pageSize, page, search, order, status }) => {
        return {
          country: true,
          name: '',
        };
      },
      stateName: 'zone',
      clearMessages: clearMessagesZone,
      selectAll: selectAllZone,
      updateSelected: updateSelectedZone,

      openModelCreate: openModelCreateZone,
      openModelEdit: openModelEditZone,

      openModelDelete: openModelDeleteZone,
      openModelDeleteAll: openModelDeleteAllZone,
      handleDelete: handleDeleteZone,
      handleDeleteMany: handleDeleteManyZone,
      handleExportFiles: handleExportZones,
      openModeleImport: openModeleImportZone,
      handleExportAllFiles: handleExportAllZones,
      handleImportFiles: handleImportZones,
      // Static Data
      ModalFetchErrorMessage: 'Go Back',
      ModalFetchErrorAction: null, //Function
      deleteInstanceTitle: 'Delete Zone',
      deleteInstanceActionTitle: 'Delete Zone',
      deleteInstanceDescription:
        'Are you sure you want to delete this Zone with id',
      deleteAllInstanceTitle: 'Delete Zone',
      deleteAllInstanceActionTitle: 'Delete Zone',
      deleteAllInstanceDescription:
        'Are you sure you want to delete Zone with IDS :',
      emptyDataTitle: 'No Zones Found!',
      emptyDataDescription:
        "It appears that no Zones were. Please consider creating Zones to help your customers navigate your website and find the products they are looking for more easily. If you need any assistance or have any questions, please don't hesitate to contact our support team.",
      addInstanceMessage: 'add New Zones',
      importInstanceMessage: 'Import Zoness',
      importInstanceModelTitle: 'Import Zones by CSV',
      importInstanceModelDescription:
        'Please note that when importing data, any existing files with the same names or IDs will be overridden. This means that the existing data will be replaced with the newly imported data. ',
      importOverrideLable: 'Overide Exisitin Files',

      // features
      localPagenation: false,
      pageSize: 50,
      exportData: false,
      importData: false,
      selectMany: false,
      deleteMany: true,
      exportMany: true,

      components: [<AddZoneModal />, <EditZoneModal />],
    },
  },
};
