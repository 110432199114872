import { useContext, useState, useEffect } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { useFormik } from 'formik';
import { login, storeTheToken } from '../services/auth';
import { storeContext } from '../global/store';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useStateContext } from '../contexts/ContextProvider';
import { initInstanceState } from '../utils/initStates';
import { createInstance } from '../helpers/instanceActions';
import { generalData } from '../utils/general';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_ALL_SETTING } from '../graphql/query/setting.query';
import { languages_list } from '../utils/languages';
import { getCurrencyCharacter } from '../utils/currency';
import SuccessModal from '../components/MessageModals/SuccessModal';
import ErrorModal from '../components/MessageModals/ErrorModal';
import jwt from 'jwt-decode';
import * as Yup from 'yup';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { store, setStore } = useContext(storeContext);
  const [showPassword, setShowPassword] = useState(false);

  const [instanceState, setInstanceState] = useState(initInstanceState);

  const { bodyStyles } = useStateContext();

  useEffect(() => {
    store.isLogged === true && navigate(`/`);
  }, [store]);

  const SignInSchema = Yup.object().shape({
    email: Yup.string().email().required('This Field is required'),
    password: Yup.string().min(6, 'Password is too short'),
  });

  useEffect(() => {}, [instanceState]);

  const {
    loadingSetting,
    error,
    refetch: refrechSetting,
  } = useQuery(GET_ALL_SETTING, {
    fetchPolicy: 'cache-and-network',
    skip: true,
  });

  const updateStoreSetting = (storeSetting) => {
    if (storeSetting?.setting?.setting_general?.available_language) {
      setStore((prevState) => ({
        ...prevState,
        preferredLanguage:
          storeSetting?.setting?.setting_general?.language_code,
        availableLanguages: storeSetting?.setting?.setting_general
          ?.available_language?.length
          ? storeSetting?.setting?.setting_general?.available_language.map(
              (item) => {
                return {
                  name: languages_list?.find((l) => l.code == item).name,
                  code: languages_list?.find((l) => l.code == item).code,
                };
              }
            )
          : [],
        currencyStore: {
          currencyCode: storeSetting?.setting?.setting_general?.currency_code,
          symbol:
            getCurrencyCharacter(
              storeSetting?.setting?.setting_general?.currency_code
            ) || storeSetting?.setting?.setting_general?.currency_code,
        },
      }));
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: SignInSchema,
    onSubmit: async (values) => {
      setInstanceState((prev) => {
        return { ...prev, loadingCreateInstance: true };
      });

      let res = await login({
        input: { email: values?.email, password: values?.password },
      });

      if (res?.data?.dashboardLogin) {
        try {
          const user = jwt(res?.data?.dashboardLogin?.accessToken);
          storeTheToken(
            res?.data?.dashboardLogin?.accessToken,
            res?.data?.dashboardLogin?.refreshToken
          );
          const exp = new Date(user.exp * 1000);
          const now = new Date();
          const isLogged = now < exp ? true : false;

          try {
            const { data } = await refrechSetting();
            if (data) {
              updateStoreSetting(data);
            }
          } catch (error) {}
          setStore((prevState) => ({
            ...prevState,
            isLogged,
            token: res?.data?.dashboardLogin?.accessToken,
            user,
          }));

          return navigate(`/`);
        } catch (err) {
          createInstance({
            setInstanceState: setInstanceState,
            res: false,
            successAction: () => {},
            successMessage: '',
            errorAction: () => {},
            errorMeesage: err,
          });
        }
      } else {
        createInstance({
          setInstanceState: setInstanceState,
          res: res?.data?.login,
          successAction: () => {},
          successMessage: '',
          errorAction: () => {},
          errorMeesage: res,
        });
      }

      setInstanceState((prev) => {
        return { ...prev, loadingCreateInstance: false };
      });
    },
  });

  return (
    <div className='flex-col'>
      <div className='flex mx-5 w-full '>
        <div className=' '>
          <SuccessModal
            open={instanceState.createInstance}
            setOpen={() =>
              setInstanceState((prev) => {
                return { ...prev, createInstance: true };
              })
            }
            message={instanceState.message}
            init={true}
          />

          <ErrorModal
            open={instanceState.errorCreatInstance}
            setOpen={() =>
              setInstanceState((prev) => {
                return { ...prev, errorCreatInstance: false };
              })
            }
            message={instanceState.message}
            init={true}
          />
        </div>
      </div>
      <div className='w-full flex text-[#000] bg-white  flex-col md:flex-row '>
        <div className='min-h-screen bg-slate-300 flex-grow p-5  justify-center items-center relative group   hidden md:flex'>
          <div className='min-h-screen bg-slate-300 flex-grow p-5  justify-center items-center relative group   hidden md:flex'>
            <a className='absolute top-1 left-1 p-4' href='/'>
              {/* <img src="/images/logo.svg" className="h-[65px] " /> */}
              <img src={generalData.logo} className='h-[65px] ' />
            </a>
            <div></div>
          </div>
          <div></div>
        </div>

        <div className='min-h-screen  bg-white w-full md:w-[38%]  flex flex-col justify-center items-center p-[5%] md:p-[50px] gap-8 '>
          <div>
            <h3 className='text-2xl font-Inter font-[600] '>
              {`${t('Welcome to')} ${generalData.name} ${t('Dashboard')}`}
            </h3>
          </div>

          <div className='flex flex-col gap-6 w-full'>
            <TextField
              id='email'
              type='email'
              size='medium'
              label={t('Email')}
              variant='outlined'
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{
                width: '100%',

                input: {
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: `0 0 0 100px #fff inset`,
                    WebkitTextFillColor: '#000000',
                  },
                },
              }}
            />

            <TextField
              size='medium'
              label={t('Password')}
              variant='outlined'
              type={showPassword ? 'text' : 'password'}
              id='password'
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      //   onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                width: '100%',

                input: {
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: `0 0 0 100px #fff inset`,
                    WebkitTextFillColor: '#000000',
                  },
                },
              }}
            />

            <div className=' flex w-full'>
              {instanceState.loadingCreateInstance ? (
                <button
                  onClick={() => formik.handleSubmit()}
                  className='w-full  min-h-[45px] rounded-lg border bg-primary 
              text-[#FFFFFF] font-Inter text-lg font-[500] hover:shadow-lg duration-300
               flex justify-center items-center
              '
                >
                  <svg
                    className='mr-3 h-7 w-7 animate-spin text-white'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidt='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                    ></path>
                  </svg>
                </button>
              ) : (
                <button
                  onClick={() => formik.handleSubmit()}
                  className='w-full  min-h-[45px] rounded-lg border 
              text-[#FFFFFF] font-Inter text-lg font-[500] hover:shadow-lg duration-300
              '
                  style={{
                    backgroundColor: bodyStyles?.primary,
                  }}
                >
                  {t("Login")}
                </button>
              )}
            </div>

            {generalData.emailDemo && generalData.passwordDemo && (
              <div className='bg-[#cafdf5] rounded-md p-2 flex flex-col gap-2 font-Roboto break-all'>
                <div className='flex gap-2 w-full mt-2'>
                  <span className=' text-lg  hover:drop-shadow-lg duration-300 font-bold min-w-[90px]'>
                    {t("Email")}
                  </span>

                  <span className='text-lg  font-semibold hover:drop-shadow-lg duration-300'>
                    {generalData?.emailDemo}
                  </span>
                </div>

                <div className='flex gap-2 w-full'>
                  <span className='text-md lg:text-lg  hover:drop-shadow-lg duration-300 font-bold min-w-[90px]'>
                    {t("Password")}
                  </span>

                  <span className='text-md lg:text-lg  font-semibold hover:drop-shadow-lg duration-300'>
                    {generalData?.passwordDemo}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
