import { getCurrencyCharacter, preferredCurrenciesCode } from './currency';

export async function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const hoverT = (color, type) => {
  return `hover:${type}-[${color}]`;
};

export const colorTi = (color, type) => {
  return `${type}-[${color}]`;
};

export const defaultDatat = (item, languageCode, filed) => {
  if (item) {
    if (Array.isArray(item)) {
      return item?.find(
        (x) => x.languageCode?.toLowerCase() === languageCode.toLowerCase()
      )?.[filed]
        ? item?.find(
            (x) => x.languageCode?.toLowerCase() === languageCode.toLowerCase()
          )?.[filed]
        : item?.find(
            (x) => x.languageCode?.toLowerCase() === 'en' || x.languageCode
          )?.[filed];
    } else {
      const res = Object.keys(item).map((k) => ({
        ...item[k],
        languageCode: k,
      }));
      return res?.find(
        (x) => x.languageCode?.toLowerCase() === languageCode.toLowerCase()
      )?.[filed]
        ? res?.find(
            (x) => x.languageCode?.toLowerCase() === languageCode.toLowerCase()
          )?.[filed]
        : res?.find(
            (x) => x.languageCode?.toLowerCase() === 'en' || x.languageCode
          )?.[filed];
    }
  } else {
    return '';
  }
};

export const defaultPrice = (item, filed = 'value') => {
  if (Array.isArray(item)) {
    return item?.find(
      (x) =>
        x.currencyCode?.toLowerCase() ===
        preferredCurrenciesCode().toLowerCase()
    )?.[filed]
      ? {
          value: item?.find(
            (x) =>
              x.currencyCode?.toLowerCase() ===
              preferredCurrenciesCode().toLowerCase()
          )?.[filed],
          currencyCode: getCurrencyCharacter(
            item?.find(
              (x) =>
                x.currencyCode?.toLowerCase() ===
                preferredCurrenciesCode().toLowerCase()
            )?.currencyCode
          ),
        }
      : {
          value: item[0]?.[filed],
          currencyCode: item[0]?.currencyCode,
          currencyCode: getCurrencyCharacter(item[0]?.currencyCode),
        };
  } else {
    const res = Object.keys(item).map((k) => ({ ...item[k], currencyCode: k }));
    return res?.find(
      (x) =>
        x.currencyCode?.toLowerCase() ===
        preferredCurrenciesCode().toLowerCase()
    )?.[filed]
      ? {
          value: res?.find(
            (x) =>
              x.currencyCode?.toLowerCase() ===
              preferredCurrenciesCode().toLowerCase()
          )?.[filed],
          currencyCode: getCurrencyCharacter(
            res?.find(
              (x) =>
                x.currencyCode?.toLowerCase() ===
                preferredCurrenciesCode().toLowerCase()
            )?.currencyCode
          ),
        }
      : {
          value: res[0]?.[filed],
          currencyCode: getCurrencyCharacter(res[0]?.currencyCode),
        };
  }
};

export function cartesian(...args) {
  var r = [],
    max = args.length - 1;
  function helper(arr, i) {
    for (var j = 0, l = args[i].length; j < l; j++) {
      var a = arr.slice(0); // clone arr
      a.push(args[i][j]);
      if (i == max) r.push(a);
      else helper(a, i + 1);
    }
  }
  helper([], 0);
  return r;
}

export const dateFormatter = (date) => {
  var newDate = new Date(date);

  return newDate.toDateString();
};

export const isObjectNotEmpty = (obj) => {
  return Object.keys(obj).length > 0;
};

export const checkParamsFilterExists = (params) => {
  for (let i = 0; i < params.length; i++) {
    if (
      params[i][0] === 'status' ||
      params[i][0] === 'type' ||
      params[i][0] === 'page'
    ) {
      return true;
    }
  }
  return false;
};
