import React, { useContext, useEffect, useState } from 'react';
import { InputAdornment } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Tabs from '../TabsLanguages';
import { useStateContext } from '../../contexts/ContextProvider';
import BasicInput from '../Inputs/BasicInput';
import { useTranslation } from 'react-i18next';
import ModalDialog from '../ModalDialog';
import { createTag, editTag, handleCreateTag, handleEditTag } from '../../services/tag.js';
import { getTheTokenFromStorage } from '../../services/auth';
import { storeContext } from '../../global/store';
import { useDispatch, useSelector } from 'react-redux';
import { openModelCreate, openModelEdit } from '../../store/features/tag/tagSlicer';
const CreateModalTag = ({}) => {
  const [tagData, setTagData] = useState({});

  const dispatch = useDispatch();

  const data = useSelector((state) => state.tag);

  // editModal
  const { store } = useContext(storeContext);

  const token = getTheTokenFromStorage();
  const { bodyStyles } = useStateContext();
  const { t } = useTranslation();

  const [translationData, setTranslationData] = useState({
    [store.preferredLanguage]: {
      name: '',
    },
  });

  useEffect(() => {
    setTranslationData({
      [store.preferredLanguage]: { name: '', description: '' },
    });
  }, [store.preferredLanguage]);

  useEffect(() => {
    if (tagData) {
      let prevTranslationData = {};
      tagData?.translationData?.map((item, index) => {
        prevTranslationData = {
          ...prevTranslationData,
          [item?.languageCode.toLowerCase()]: item,
        };
      });

      setTranslationData(prevTranslationData);
    }
  }, [tagData]);

  const addTagSchema = Yup.object().shape({
    translationData: Yup.object().shape({
      [store.preferredLanguage]: Yup.object().shape({
        name: Yup.string().required('This Field is required'),
      }),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      translationData: translationData,
    },
    validationSchema: addTagSchema,
    onSubmit: async (values) => {
      dispatch(
        handleCreateTag(values, () => {
          closeTagModal();
        })
      );
    },
  });

  const closeTagModal = () => {
    setTranslationData({
      [store.preferredLanguage]: {
        name: '',
      },
    });
    dispatch(openModelCreate());
  };

  return (
    <ModalDialog
      open={data?.createModal}
      setOpen={closeTagModal}
      title={t('Create Tag')}
      cancelTitle={t('Cancel')}
      cancel={() => closeTagModal()}
      actionTitle={t('Create')}
      action={() => formik.handleSubmit()}
      loadingAction={data?.loadingCreate}
    >
      <div
        className=' '
        style={{
          borderColor: bodyStyles?.text,
        }}
      >
        <Tabs availableLanguages={store?.availableLanguages} small>
          {store?.availableLanguages?.map((item, indexLanguages) => (
            <div
              key={indexLanguages}
              className='mt-4 font-urbanist 
                     overflow-scroll
                    '
            >
              <div className='w-[98%]'>
                <div
                  className='font-Inter text-sm pt-4 pb-4
                                    uppercase font-bold flex items-center justify-start'
                  style={{
                    color: bodyStyles.subTitle,
                  }}
                >
                  <span>{`${t('Tag information')}`}</span>
                </div>

                <div className='flex flex-col  gap-6 '>
                  <BasicInput
                    label={t('Tag Name')}
                    placeholder='Tag'
                    value={translationData[item.code]?.name}
                    onChange={(e) => {
                      setTranslationData({
                        ...translationData,
                        [item?.code]: {
                          ...translationData[item?.code],
                          name: e.target.value,
                        },
                      });
                    }}
                    error={
                      item?.code === store.preferredLanguage &&
                      formik?.touched?.translationData &&
                      Boolean(
                        formik?.errors?.translationData &&
                          formik?.errors?.translationData[
                            store.preferredLanguage
                          ]?.name
                      )
                    }
                    helperText={
                      item?.code === store.preferredLanguage &&
                      formik?.touched?.translationData &&
                      formik?.errors?.translationData &&
                      formik?.errors?.translationData[store.preferredLanguage]
                        ?.name
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          fontSize='small'
                          position='end'
                          sx={{ color: bodyStyles?.inputText }}
                        >
                          <span className='text-xs'>
                            {item?.code?.toUpperCase()}
                          </span>
                        </InputAdornment>
                      ),
                      style: { fontSize: '12px' },
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </Tabs>
      </div>
    </ModalDialog>
  );
};

export default CreateModalTag;
