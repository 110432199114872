import { EDIT_MEDIA } from '../graphql/mutation/media.mutation';
import { GET_REFERENCE } from '../graphql/query/reference.query';
import {
  editMediaFail,
  editMediaStart,
  editMediaSuccess,
} from '../store/features/media/mediaSlicer';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../store/features/snackbar/snackbarSlice';
import { getTheTokenFromStorage } from './auth';
import { executeMutation } from './executeMutation';

const token = getTheTokenFromStorage();

export const editMedia = async (values, accessToken) => {
  return await executeMutation(
    EDIT_MEDIA,
    [GET_REFERENCE],
    values,
    accessToken
  );
};

export const handleEditMedia = (values, callback) => {
  return async (dispatch) => {
    dispatch(editMediaStart());

    const dataSent = {
      updateMediaInput: {
        id: values.id,
        alt: values.alt,
      },
    };

    try {
      let res = await editMedia(dataSent, token);
      if (res?.data?.updateMedia) {
        dispatch(editMediaSuccess(res));

        console.log('updateMedia successfully', res);

        dispatch(
          showSuccessMessage('this Media has been successfully updated')
        );

        if (callback) {
          callback(res?.data?.updateMedia);
        }
      } else {
        console.log('else updateMedia');

        dispatch(editMediaFail(res));
        dispatch(showErrorMessage(res));
        if (callback) {
          callback(res);
        }
      }
    } catch (error) {
      console.log('error updateMedia', error);

      dispatch(editMediaFail(error));
      dispatch(showErrorMessage(error));
      if (callback) {
        callback(error);
      }
    }
  };
};
