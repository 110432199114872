import React from 'react'
import FetchPageSetting from '../../../fetchPages/FetchPageSetting'
import { pagesSettingsData } from '../../../../pages_data/pages_data'

const Roles = () => {
  return (
    <div className='flex w-full'>
      <FetchPageSetting data={pagesSettingsData?.roles?.main} headerStikyPostion={0}/>
    </div>
  )
}

export default Roles