import * as React from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import RtlConversion from "../RtlConversion";
import { useTranslation } from "react-i18next";

const BasicSelectMui = ({
  id,
  value,
  onChange,
  error,
  options,
  name,
  label,
  size,
  placeholder,
  fullHeight,
  optionLabel,
  optionValue,
  disabled
  
}) => {
  const { bodyStyles } = useStateContext();
  const { t, i18n } = useTranslation();

  return (
    <FormControl
      fullWidth
      style={{
        // height: "75px",
        // maxHeight: "50px",
        borderWidth: 0,
      }}
      // size="small"
    >
      <InputLabel
        id={id}
        sx={{
          color: bodyStyles?.text,
          backgroundColor: bodyStyles.background,
          paddingRight: "4px",

          "&.Mui-focused": {
            color: bodyStyles?.text,
          },
        }}
      >
        {label}
      </InputLabel>

      <Select
       disabled={disabled}
        type="text"
        labelId={id}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        label={label}
        sx={{
          height: fullHeight ? "100%" : size === "small" ? "34.5px" : "49.8px",
          maxHeight: fullHeight ? "55px" : "52.8px",

          color: bodyStyles?.inputText,
          input: {
            "&::placeholder": {
              color: bodyStyles?.subText,
            },
            color: bodyStyles?.subText,
          },

          "& label.Mui-focused": {
            color: bodyStyles?.text,
            backgroundColor: bodyStyles.background,
            paddingRight: "4px",
          },

          "& .MuiInputBase-input": {
            position: "relative",

            "&:focus": {
              borderColor: bodyStyles?.text,
            },
          },

          ".MuiOutlinedInput-notchedOutline": {
            borderColor: bodyStyles?.inputBorder,
            color: bodyStyles?.text,
            "&::placeholder": {
              textOverflow: "ellipsis !important",
              color: "blue",
            },
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: bodyStyles?.inputBorder,
            "&::placeholder": {
              textOverflow: "ellipsis !important",
              color: "blue",
            },
          },

          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: bodyStyles?.inputBorder,
          },

          "& .MuiOutlinedInput-root": {
            display: "flex",
            color: bodyStyles?.inputText,
            alignItems: "center",
            "& fieldset": {
              borderColor: bodyStyles?.inputBorder,
            },
            "&:hover fieldset": {
              borderColor: bodyStyles?.inputBorder,
              display: "flex",
              alignItems: "center",
            },
            "&.Mui-focused fieldset": {
              borderColor: bodyStyles?.inputBorderSelected,
            },
          },

          // ".MuiSvgIcon-root ": {
          //   fill: "white !important",
          // },
        }}
      >
        {options.map((item, index) => (
          <MenuItem
            key={index}
            value={item.id? item.id :   item.value || item.value == false ? item.value : item}
          >
            {item.label ? item.label : item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BasicSelectMui;
